import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useAtleastMDMediaQuery } from '../../../hooks/useAtleastMDMediaQuery';
import { Spinner } from '../../ui/common-ui';
import { AdesaButton, AdesaCheckbox, AdesaModal, AdesaTextInput } from '../../Common/ui-components';
import ForgotPasswordDialog from '../../Common/ForgotPasswordDialog';

import './LoginButton.less';

const Line = ({ children }) => (
    <Row className="line">
        <Col xs={{ size: 10, offset: 1 }}>{children}</Col>
    </Row>
);

function LoginButtonAndModal({ t, usernameLogin, currentLanguage, onLogin, onPreLogin, loading, companyOrContactBlocked }) {
    //using to verify md screen
    const isMDScreen = useAtleastMDMediaQuery();

    const [loginUsernameDialogOpen, setLoginUsernameDialogOpen] = useState(window.location.hash && window.location.hash.toLowerCase() === '#login');
    const [showPasswordField, setShowPasswordField] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [userErrorMsg, setuserErrorMsg] = useState(null);
    const [passwordErrorMsg, setPasswordErrorMsg] = useState(null);
    const [remeberUsername, setRemeberUsername] = useState(false);
    const [isForgotPassDialogOpen, setForgotPassDialogOpen] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (password !== '') {
            setPasswordErrorMsg(null);
        }
    }, [password]);

    useEffect(() => {
        if (username !== '') {
            setuserErrorMsg(null);
        }
    }, [username]);

    useEffect(() => {
        setLoginUsernameDialogOpen(companyOrContactBlocked ? false : window.location.hash && window.location.hash.toLowerCase() === '#login');
    }, [companyOrContactBlocked, window.location.hash]);

    const onLoginButtonClick = () => {
        onPreLogin(null);
        setUsername('');
        setPassword('');
        setShowPasswordField(false);
        setLoginUsernameDialogOpen(!loginUsernameDialogOpen);
    };
    //-------- Method for validating Okta Login (without password field) ---------
    const onOktaLoginClick = () => {
        if (username !== '') {
            onPreLogin(username, remeberUsername);
        } else if (username == '') {
            setuserErrorMsg(t('registerForm.personalDetailsValidation.username.error.required'));
        }
    };

    //-------Simple loging flow without validating Okta user(With Password Field)----------
    const onSimpleloginClick = () => {
        if (password !== '') {
            onLogin(username, password, remeberUsername);
        } else if (password == '') {
            setPasswordErrorMsg(t('registerForm.personalDetailsValidation.password.error.required'));
        }
    };
    const onKeyPress = evt => {
        if (evt.key === 'Enter') {
            if (showPasswordField) {
                onSimpleloginClick();
            } else {
                onOktaLoginClick();
            }
        }
    };

    const onClickForgotPass = () => {
        setLoginUsernameDialogOpen(false);
        setForgotPassDialogOpen(prop => !prop);
    };

    useEffect(() => {
        if (usernameLogin) {
            setShowPasswordField(!showPasswordField);
        }
    }, [usernameLogin]);

    return (
        <div className="rc-login-button">
            <AdesaButton id="loginButton2" className="login-button top-btn-style" size="secondary" color="ghost-blue" onClick={onLoginButtonClick}>
                {t('navigation.top.login')}
            </AdesaButton>
            <ForgotPasswordDialog t={t} isOpen={isForgotPassDialogOpen} setOpen={setForgotPassDialogOpen} currentLanguage={currentLanguage} />
            <AdesaModal is-open={loginUsernameDialogOpen} width={isMDScreen ? '32' : '90'} onChanged={onLoginButtonClick} className="login-form">
                <h3 slot="header">
                    <b>{t('login.headline')}</b>
                </h3>
                <div slot="body">
                    <Line>
                        <AdesaTextInput
                            label={t('login.username')}
                            type="text"
                            name="Email"
                            value={username}
                            onKeyPress={onKeyPress}
                            onChange={evt => setUsername(evt.target.value)}
                            readOnly={showPasswordField ? true : false}
                        />
                    </Line>
                    {userErrorMsg ? (
                        <Line>
                            <span className="errorMsg">{userErrorMsg}</span>
                        </Line>
                    ) : null}
                    {showPasswordField ? (
                        <>
                            <Line>
                                <AdesaTextInput
                                    label={t('login.password')}
                                    type={showPassword ? 'text' : 'password'}
                                    inputname="password"
                                    name="Password"
                                    value={password}
                                    onChange={evt => setPassword(evt.target.value)}
                                    onKeyPress={onKeyPress}
                                    autoFocus
                                    showPassword={showPassword}
                                    showPasswordHandler={() => setShowPassword(value => !value)}
                                />
                            </Line>
                            {passwordErrorMsg ? (
                                <Line>
                                    <span className="errorMsg">{passwordErrorMsg}</span>
                                </Line>
                            ) : null}
                            <Line>
                                <div className="mt-2">
                                    <AdesaButton id="loginButton3" size="secondary" color="positive" onClick={onSimpleloginClick} disabled={loading}>
                                        {!loading ? t('navigation.top.login') : <Spinner visible={loading} />}
                                    </AdesaButton>
                                </div>
                            </Line>
                        </>
                    ) : null}
                    {!showPasswordField ? (
                        <Line>
                            <div className="mt-2">
                                <AdesaButton id="loginButton4" size="secondary" color="positive" onClick={onOktaLoginClick} disabled={loading}>
                                    {!loading ? t('navigation.top.login') : <Spinner visible={loading} />}
                                </AdesaButton>
                            </div>
                        </Line>
                    ) : null}

                    <Line>
                        <p className="forgot-password-link1" onClick={onClickForgotPass}>
                            {t('login.link.forgotPassword')}
                        </p>
                    </Line>

                    <Line>
                        <hr />
                    </Line>

                    <Line>
                        <label>{t('login.noAccountYet')}</label> <a href={`/${currentLanguage}/register`}>{t('login.button.register')}</a>
                    </Line>
                </div>
            </AdesaModal>
        </div>
    );
}

export default LoginButtonAndModal;
