import React, { Component, Fragment, useState, useEffect } from 'react';
import { queryString, debounce } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { AdesaNumberInput } from '../Common/ui-components';
import { StarRating, InfoBanner, Divider } from '@ambiorix/adesa-web-kit';
import headingStyles from '@ambiorix/adesa-web-kit/dist/styles/heading.module.scss';
import PropTypes from 'prop-types';

import './common-ui.less';

const googleMapApiKey_GIBO = 'AIzaSyCJUjGuDyPm9ER2TWgf2Xmi4eY7uoxnVac';
const googleMapApiKey = 'AIzaSyBVihsiOZMXEvDMqct4mGARpOtErYCOCmM';

export const Spinner = ({ visible }) => (visible ? <span className="fa fa-refresh fa-spin"></span> : <span></span>);

function encode(address) {
    return address.replace(/\s/g, '+');
}

export const Information = ({ children }) => (
    <div className="info-box">
        <InfoBanner
            title={children}
            showTitleIcon
            titleIcon={<FontAwesomeIcon icon={faInfoCircle} size="sm" fixedWidth />}
            border
            showCloseIcon={false}
        />
    </div>
);

export const MapFake = () => (
    <iframe
        src={
            'https://www.google.com/maps/embed?' +
            'pb=!1m14!1m12!1m3!1d7098.94326104394!2d78.0430654485247!3d27' +
            '.172909818538997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1385710909804'
        }
        width="100%"
        height="500"
        frameBorder="0"
        style={{ border: 0 }}
    />
);

export function googleMapLink(address) {
    return 'https://www.google.com/maps/place/' + encode(address) + '/';
}

export const GoogleMapStatic = ({ address }) => (
    <a href={googleMapLink(address)} target="_blank" rel="noopener noreferrer">
        <img
            style={{ display: 'block', width: '100%' }}
            src={
                'https://maps.googleapis.com/maps/api/staticmap?center=' +
                encode(address) +
                '&zoom=9&scale=2&size=300x100&maptype=roadmap&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0xff0000%7Clabel:%7C' +
                encode(address) +
                '&key=' +
                googleMapApiKey
            }
            alt={address}
        />
    </a>
);

export const GoogleMap = ({ address }) => (
    // <iframe style={{width: "100%", height: "300px"}} src={`https://www.google.com/maps/embed/v1/place?key=${googleMapApiKey}&q=${encode(address)}&zoom=8`} frameBorder="0"></iframe>
    <iframe
        style={{ width: '100%', height: '200px' }}
        src={`https://maps.google.com/maps?q=${encode(address)}&t=&z=10&ie=UTF8&iwloc=&output=embed`}
        frameBorder="0"></iframe>
);

export const OSMap2 = ({ address }) => (
    <img
        style={{ display: 'block', width: '100%' }}
        src={'http://staticmap.openstreetmap.de/staticmap.php?center=50.80843175,4.94642921342942&zoom=14&size=300x100&maptype=mapnik'}
    />
);

/**
 * OpenStreetMap static map.
 *    https://wiki.openstreetmap.org/wiki/Static_map_images
 *    https://wiki.openstreetmap.org/wiki/StaticMapLite
 *    https://nominatim.openstreetmap.org/
 *    https://wiki.openstreetmap.org/wiki/Nominatim
 *
 */
export class OSMap extends Component {
    constructor(props) {
        super(props);
        this.state = { lat: null, lng: null };
    }

    componentDidMount() {
        const request = {
            format: 'json',
            q: this.props.address,
        };
        fetch('https://nominatim.openstreetmap.org/search?' + queryString(request))
            .then(a => a.json())
            .then(resp => {
                if (resp.length > 0) {
                    // take first response
                    const place = resp[0];
                    this.setState({ lat: place.lat, lng: place.lon });
                }
            });
    }

    render() {
        return this.state && this.state.lat && this.state.lng ? (
            <img
                style={{ display: 'block', width: '100%' }}
                src={`http://staticmap.openstreetmap.de/staticmap.php?center=${this.state.lat},${this.state.lng}&zoom=14&size=300x100&maptype=mapnik`}
            />
        ) : (
            <div></div>
        );
    }
}

export const Stars = ({ value }) => <StarRating rating={value} size={'md'} />;

// Devices size based on Bootstrap grid (https://getbootstrap.com/docs/4.1/layout/grid/)
const smWidth = 576;
const mdWidth = DESKTOP_BREAKPOINT;
const lgWidth = 992;
const xlWidth = 1200;

function screenSize(width) {
    if (width < smWidth) return 'xs';
    if (width < mdWidth) return 'sm';
    if (width < lgWidth) return 'md';
    if (width < xlWidth) return 'lg';
    return 'xl';
}

// https://stackoverflow.com/questions/19014250/rerender-view-on-browser-resize-with-react
export class WidthListener extends Component {
    render() {
        const { children, ...props } = this.props;
        const childrenWithProps = React.Children.map(children, child =>
            React.cloneElement(child, { screenSize: screenSize(this.state.width), ...props }),
        );
        return <Fragment>{childrenWithProps}</Fragment>;
    }
    updateDimensions() {
        //this.setState({width: window.innerWidth, height: window.innerHeight});
        this.setState({ width: window.innerWidth });
    }
    UNSAFE_componentWillMount() {
        this.updateDimensions();
    }
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions.bind(this));
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions.bind(this));
    }
}

export const StrikethroughTitleOld = ({ text, backgroundColor }) => (
    <div className="strikethrough-title">
        <p>
            <span style={{ backgroundColor: backgroundColor || '#fff' }}> {text} </span>
        </p>
    </div>
);

// TODO: [jordy] move to webkit
export const StrikethroughTitle = ({ children, className, size = 'md', text }) => {
    const sizeClasses = {
        sm: headingStyles.headingSM,
        md: headingStyles.headingMD,
        lg: headingStyles.headingLG,
    };
    return (
        <div className={'strikethrough-title' + (className ? ` ${className}` : '')}>
            <Divider className="divider" />
            <div className={cx('strikethrough-title__content', sizeClasses[size])}>{children || text}</div>
            <Divider className="divider" />
        </div>
    );
};

const buttonsStyle = {
    display: 'inline-block',
    backgroundColor: 'rgba(85, 90, 96, 0.54)',
    width: '48px',
    height: '48px',
    borderRadius: '4px',
    textAlign: 'center',
    color: 'white',
    fontWeight: 'bold',
    cursor: 'pointer',
    lineHeight: '48px',
    fontSize: '30px',
};

export const CarouselNextButton = ({ nextSlide }) => <i className="fa fa-angle-right carousel-next" style={buttonsStyle} onClick={nextSlide}></i>;

export const CarouselPreviousButton = ({ previousSlide }) => (
    <i className="fa fa-angle-left carousel-previous" style={buttonsStyle} onClick={previousSlide}></i>
);

const breakpoints = {
    sm: 576,
    md: DESKTOP_BREAKPOINT,
    lg: 992,
    xl: 1200,
};

function widthToBreakpoints(width) {
    return Object.keys(breakpoints)
        .filter(k => width >= breakpoints[k])
        .join(',');
}

/**
 * Based on the current screen width returns a string containing the current breakpoints "sm,md,lg,xl"
 *
 *      https://stackoverflow.com/questions/19014250/rerender-view-on-browser-resize-with-react
 *
 * usage:
 *      <GridBreakpointListener>{(bp) =>
 *            bp.indexOf("md") > -1 ? <Large></Large> : <Small></Small>
 *      }</GridBreakpointListener>
 *
 */
export class GridBreakpointListener extends Component {
    constructor(props) {
        super(props);
        this.state = { width: window.innerWidth };
    }
    render() {
        return <Fragment>{this.props.children(widthToBreakpoints(this.state.width))}</Fragment>;
    }
    updateDimensions() {
        this.setState({ width: window.innerWidth });
    }
    componentDidMount() {
        // https://medium.com/@photokandy/til-you-can-pass-an-object-instead-of-a-function-to-addeventlistener-7838a3c4ec62
        window.addEventListener('resize', (this._resizeHandler = () => this.updateDimensions()), false);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this._resizeHandler);
    }
}

/**
 * Based on the current screen width displays or not the children
 *
 *      https://stackoverflow.com/questions/19014250/rerender-view-on-browser-resize-with-react
 *
 * usage:
 *      <Breakpoint md up>
 *          content
 *      </Breakpoint>
 *
 *      <Breakpoint lg down>
 *          content
 *      </Breakpoint>
 *
 */
export class Breakpoint extends Component {
    constructor(props) {
        super(props);
        const bps = Object.keys(breakpoints).filter(bp => props[bp]);
        const bp = bps.length ? bps[0] : 'md';
        this.state = {
            width: window.innerWidth,
            refWidth: breakpoints[bp],
        };
        this.updateDimensionsDebounced = debounce(1000, this.updateDimensions.bind(this));
    }
    render() {
        const display = this.props.down ? this.state.width < this.state.refWidth : this.state.width >= this.state.refWidth;
        //return <Fragment>{display ? this.props.children : undefined}</Fragment>;
        return display ? this.props.children : null;
    }
    updateDimensions() {
        this.setState({ width: window.innerWidth });
    }
    componentDidMount() {
        // https://medium.com/@photokandy/til-you-can-pass-an-object-instead-of-a-function-to-addeventlistener-7838a3c4ec62
        window.addEventListener('resize', (this._resizeHandler = () => this.updateDimensionsDebounced()), false);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this._resizeHandler);
    }
}

/**
 *  React hook to track the window width.
 *  cfr https://gist.github.com/gaearon/cb5add26336003ed8c0004c4ba820eae
 *
 */
export function useWindowWidth() {
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });
    return width;
}

import Measure from 'react-measure';
import { cx } from 'class-variance-authority';
import { DESKTOP_BREAKPOINT } from '../../hooks/useAtleastMDMediaQuery';

/**
 * Adds a class to the parent div element if width is less than max width.
 *
 */
export class MaxWidthClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dimensions: { width: -1, height: -1 },
        };
    }

    render() {
        const { maxWidth, classNameToAdd, children, className } = this.props;
        const { width, height } = this.state.dimensions;
        return (
            <Measure bounds onResize={contentRect => this.setState({ dimensions: contentRect.bounds })}>
                {({ measureRef }) => (
                    <div ref={measureRef} className={className + (width <= maxWidth ? ` ${classNameToAdd}` : '')}>
                        {children}
                    </div>
                )}
            </Measure>
        );
    }
}

/**
 * <input type="number"> implementing the maxlength behaviour.
 * as maxlength is by default ignored when type is 'number'.
 * https://stackoverflow.com/questions/18510845/maxlength-ignored-for-input-type-number-in-chrome
 *
 *
 */
export function NumberInput(props) {
    const { onChange, maxLength, value, ...otherProps } = props;
    const onChangeHandler = value => {
        // 2. limit size.
        if (value && value.length > maxLength) value = value.slice(0, maxLength);
        onChange && onChange(value);
    };

    return (
        <AdesaNumberInput
            value={value}
            onValueChange={onChangeHandler}
            decimalScale={0}
            maxLength={maxLength}
            min="0"
            max={Array(maxLength).join('9')}
            {...otherProps}
        />
    );
}

NumberInput.propTypes = {
    maxLength: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
};

/**
 * useScroll React custom hook
 * Usage:
 *    const { scrollX, scrollY, scrollDirection } = useScroll();
 *
 * credit: https://gist.github.com/joshuacerbito/ea318a6a7ca4336e9fadb9ae5bbb87f4
 */

export function useScroll() {
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [bodyOffset, setBodyOffset] = useState(document.body.getBoundingClientRect());
    const [scrollY, setScrollY] = useState(bodyOffset.top);
    const [scrollX, setScrollX] = useState(bodyOffset.left);
    const [scrollDirection, setScrollDirection] = useState();

    const listener = e => {
        setBodyOffset(document.body.getBoundingClientRect());
        setScrollY(-bodyOffset.top);
        setScrollX(bodyOffset.left);
        setScrollDirection(lastScrollTop > -bodyOffset.top ? 'down' : 'up');
        setLastScrollTop(-bodyOffset.top);
    };

    useEffect(() => {
        window.addEventListener('scroll', listener);
        const timer = setInterval(listener, 500); // when scrolling rapidly to top we loose some "scroll" events (cfr #3092)
        return () => {
            window.removeEventListener('scroll', listener);
            clearTimeout(timer);
        };
    });

    return {
        scrollY,
        scrollX,
        scrollDirection,
    };
}
