import React, { ChangeEventHandler, ComponentProps, forwardRef } from 'react';
import { Checkbox, Override } from '@ambiorix/adesa-web-kit';
import { cx } from 'class-variance-authority';

import styles from './Checkbox.module.scss';

export type AdesaCheckboxProps = Override<
    ComponentProps<typeof Checkbox>,
    {
        disabled?: boolean | string;
        onChange?: (event: CustomEvent<{ checked: boolean }>) => void;
    }
>;

// map ui-components select props to web kit select
export const AdesaCheckbox = forwardRef<HTMLInputElement, AdesaCheckboxProps>(
    ({ className = '', disabled, onChange, variant = 'basic', ...props }: AdesaCheckboxProps, ref) => {
        const mappedDisabled = disabled !== 'false' && Boolean(disabled);

        const mappedOnChange: ChangeEventHandler<HTMLInputElement> = event => {
            const customEvent = new CustomEvent('changed', {
                bubbles: true,
                composed: true,
                detail: { checked: event.target.checked },
            });
            onChange?.(customEvent);
        };

        return (
            <Checkbox
                ref={ref}
                className={cx(styles.checkbox, className)}
                disabled={mappedDisabled}
                onChange={mappedOnChange}
                variant={variant}
                {...props}
            />
        );
    },
);
