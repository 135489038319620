// const supportedLanguages = {
//     bg: 'български',
//     cs: 'Česky',
//     de: 'Deutsch',
//     en: 'English',
//     es: 'Español',
//     'fr-be': 'Français (Belgique)',
//     'fr-fr': 'Français (France)',
//     'nl-be': 'Nederlands (België)',
//     'nl-nl': 'Nederlands (Nederland)',
//     hr: 'Hrvatski',
//     hu: 'Magyar',
//     it: 'Italiano',
//     lt: 'Lietuvių',
//     pl: 'Polski',
//     pt: 'Portuguese',
//     ro: 'Romanian',
//     ru: 'Русский',
//     sl: 'Slovenščina',
//     sk: 'Slovenský',
//     tr: 'Türkçe',
//     el: 'Ελληνικά',
// };

const supportedLanguages = [
    { id: 'bg', name: 'български' },
    { id: 'cs', name: 'Česky' },
    { id: 'de', name: 'Deutsch' },
    { id: 'en', name: 'English' },
    { id: 'es', name: 'Español' },
    { id: 'fr-be', name: 'Français (Belgique)' },
    { id: 'fr-fr', name: 'Français (France)' },
    { id: 'nl-be', name: 'Nederlands (België)' },
    { id: 'nl-nl', name: 'Nederlands (Nederland)' },
    { id: 'hr', name: 'Hrvatski' },
    { id: 'hu', name: 'Magyar' },
    { id: 'it', name: 'Italiano' },
    { id: 'lt', name: 'Lietuvių' },
    { id: 'pl', name: 'Polski' },
    { id: 'pt', name: 'Portuguese' },
    { id: 'ro', name: 'Romanian' },
    { id: 'ru', name: 'Русский' },
    { id: 'sl', name: 'Slovenščina' },
    { id: 'sk', name: 'Slovenský' },
    { id: 'fi', name: 'Suomi' },
    { id: 'tr', name: 'Türkçe' },
    { id: 'el', name: 'Ελληνικά' },
];

export { supportedLanguages };
