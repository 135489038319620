import React, { useRef, useEffect, useState, Component } from 'react';
import settings from './appsettings';

export * from './utils/observable';

/**
 * Pad a number with leading zeros ()
 *
 * see https://stackoverflow.com/questions/10073699/pad-a-number-with-leading-zeros-in-javascript
 *
 * @param {*} n      number to pad.
 * @param {*} width  target width
 * @param {*} z      padding character (default '0')
 */
export function pad(n, width, z) {
    const z1 = z || '0';
    const n1 = n + '';
    return n1.length >= width ? n1 : new Array(width - n1.length + 1).join(z1) + n;
}

/**
 * Returns a function that makes sure that as long as it continues to be invoked, "func" will not be triggered.
 * The "func" function will be called after it stops being called for "delay" milliseconds.
 *
 * @param {*} delay
 * @param {*} func
 */
export function debounce(delay, func) {
    let inDebounce;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(inDebounce);
        inDebounce = setTimeout(() => func.apply(context, args), delay);
    };
}

/**
 * Format a number to an amount.
 *      12345.67 will return 12.345,67
 *      1234     will return  1.234,00
 *
 * @param {*} n number to format.
 * @param {*} d number of decimals to keep.
 */

// Currency code formatter for EUR and GBP
export function currencyCodeFormatter(currency, currentLanguage) {
    if (currency && currentLanguage && currentLanguage == 'ro') return currency;
    if (currency && currency === 'GBP') return '£ ';
    if (currency && currency === 'EUR') return '€ ';
    return '';
}

const currentLanguage = currentLanguageFromUrl();
export function formatMoney(n, d = 2, currencyCode = 'EUR') {
    if (Number(n) !== n) return n;
    return n.toLocaleString(currentLanguage, {
        style: currencyCode ? 'currency' : 'decimal',
        currency: currencyCode || undefined,
        minimumFractionDigits: d,
        maximumFractionDigits: d,
    });
}

//Input money formatter
export function inputMoneyFormatter(n, d = 2) {
    if (Number(n) !== n) return n;
    return n.toLocaleString(currentLanguage, {
        minimumFractionDigits: d,
        maximumFractionDigits: d,
    });
}

export function formatNumber(n, d = 0) {
    if (Number(n) !== n) return n;
    return n.toLocaleString(currentLanguage, {
        minimumFractionDigits: d,
        maximumFractionDigits: d,
    });
}

// abuse Intl API to retrieve correct separators for a given locale
export function getNumberSeparators(language = currentLanguageFromUrl()) {
    const parts = Intl.NumberFormat(language).formatToParts(1000000.1);
    const decimalPart = parts.find(part => part.type === 'decimal');
    const groupPart = parts.find(part => part.type === 'group');
    const decimalSeparator = decimalPart ? decimalPart.value : '';
    const thousandSeparator = groupPart ? groupPart.value : '';
    return { decimalSeparator, thousandSeparator };
}

export function formatMoney_orig(n, d = 2, currenyCode) {
    if (Number(n) === n) {
        let result = currencyCodeFormatter(currenyCode) + n.toFixed(d);
        if (currenyCode === 'GBP') {
            result = result.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // 12,345.67
        } else {
            result = result.replace('.', ',').replace(/\d(?=(\d{3})+(,|$))/g, '$&.'); // 12.345,67
        }
        return result;
    } else {
        return '';
    }
}

Number.prototype.format = function (c, d, t) {
    var n = this;
    c = isNaN((c = Math.abs(c))) ? 2 : c;
    d = d === undefined ? ',' : d;
    t = t === undefined ? '.' : t;
    var s = n < 0 ? '-' : '',
        i = parseInt((n = Math.abs(+n || 0).toFixed(c))) + '',
        j = (j = i.length) > 3 ? j % 3 : 0;
    return (
        s +
        (j ? i.substr(0, j) + t : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
        (c
            ? d +
              Math.abs(n - i)
                  .toFixed(c)
                  .slice(2)
            : '')
    );
};

export function formatNumber_orig(number, decimal = 0) {
    if (number === undefined || number === null) return '';
    if (typeof number === 'string') {
        number = parseInt(number.replace(/[^\d]/g, ''), 10);
    }
    if (typeof number !== 'number') return number;
    return number.format(decimal);
}

export function currencyFormat(price, decimal = 0, currenyCode) {
    if (!price) return;

    let result = formatNumber(price, decimal);
    if (result !== '') result = currencyCodeFormatter(currenyCode) + result;
    return result;
}

export function mileageFormat(mileage, mileageTypeId) {
    if (typeof mileage !== 'number') return mileage;
    let result = formatNumber(mileage);
    if (result !== '') result = result + (mileageTypeId === 2 ? ' mi' : ' km');
    return result;
}

export function isArray(obj) {
    return typeof obj === 'object' && obj.constructor === Array;
}

export function queryString(obj) {
    const str = [];
    for (var p in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, p)) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
    }
    return str.join('&');
}

/**
 * Collection of functions allowing the management of cookies.
 *  See https://stackoverflow.com/questions/14573223/set-cookie-and-get-cookie-with-javascript
 */
export class Cookies {
    static set(name, value, days = 9999) {
        var expires = '';
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + (value || '') + expires + '; path=/';
    }

    static get(name) {
        var nameEQ = name + '=';
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    static remove(name) {
        document.cookie = name + '=; Max-Age=-99999999;';
    }
}

// extrapolate ("a{0}b{1}c{2}", 11,22,33); //  ==> should return "a11b22c33"
/*export function extrapolate(text) {
    const params = Array.prototype.slice.call(arguments, 1);
    return params.reduce((txt, p, i) => txt.replace(new RegExp(`\\{${i}\\}`, "g"), p), text);
}*/

//Modifying Extrapolate Function for handling currency {1|€} {0}
export const extrapolate = (text, ...params) =>
    Array(10)
        .fill(0)
        .reduce((txt, p, i) => txt.replace(new RegExp(`\\{${i}(\\|([^\\}]*))?\\}`, 'g'), (_, __, defaultVal) => params[i] || defaultVal), text);
// ---------------------------------------------------------------------------------------------------------------
//  Network related utils.

export function csrfToken() {
    const elem = document.querySelector('#__AjaxAntiForgeryForm input[name="__RequestVerificationToken"]');
    return elem ? elem.value : 'no-token-found';
    //__RequestVerificationToken: token
}

export const csrfTokenName = '__RequestVerificationToken';

function addCsrfToken(fetchOptions, token) {
    if (fetchOptions.body) {
        // Add CSRF Token.
        if (fetchOptions.body instanceof FormData) {
            fetchOptions.body.append(csrfTokenName, token);
        } else {
            // JSON payload... Doesn't work --> put token in header (https://nozzlegear.com/blog/send-and-validate-an-asp-net-antiforgerytoken-as-a-request-header)
            /*try {
                let data = JSON.parse(fetchOptions.body);
                data[csrfTokenName] = token;
                fetchOptions.body = JSON.stringify(data);
            } catch (e) {
                console.error("Cannot put CSRF token in POST request");
            }*/
        }
    }
}

const defaultHeaders = {
    'X-Requested-With': 'XMLHttpRequest', // makes sure that the request is considered as an ajax request by CarController and so avoid fetching data necessary for page display.
};

function handleFetchError(resp) {
    // 500, 401, ... errors are not going to "catch" directly, have to handle it manually (https://www.tjvantoll.com/2015/09/13/fetch-and-errors/)
    if (!resp.ok) {
        const err = Error(resp.statusText);
        err.status = resp.status;
        throw err; // => to be handled in "catch"
    }
    return resp;
}

function checkAndParseJsonResp(resp) {
    // //Reset localstorage for session timeout expiration
    window.idleTimerLastActivityTime = Date.now();

    const contentType = resp.headers.get('content-type');
    const isJsonResponse = contentType && contentType.indexOf('application/json') !== -1;
    return resp.text().then(respText => {
        // Read response as text and parse it "manually"
        if (isJsonResponse) {
            try {
                return JSON.parse(respText);
            } catch (err) {
                // DoBuyNowBid does return an empty response (not a JSON empty string) on success !!
                //console.error("Error: Cannot parse json response!", err);
                return respText;
            }
        } else {
            return respText;
        }
    });
}

export function getJson(url, options = {}) {
    // simulate response when on a test environment.
    if (window.__FETCH_MOCK__ && window.__FETCH_MOCK__[url]) {
        return Promise.resolve(window.__FETCH_MOCK__[url]);
    }
    const method = 'GET';
    return fetch(url, {
        method,
        ...options,
        headers: {
            ...options.headers,
            ...defaultHeaders,
        },
    })
        .then(handleFetchError)
        .then(checkAndParseJsonResp);
}

export function fetchJson(url, options = {}, method = 'GET') {
    // simulate response when on a test environment.
    if (window.__FETCH_MOCK__ && window.__FETCH_MOCK__[url]) {
        return Promise.resolve(window.__FETCH_MOCK__[url]);
    }
    const token = csrfToken();
    addCsrfToken(options, token);
    return fetch(url, {
        mode: 'cors',
        method,
        credentials: 'include',
        ...options,
        headers: {
            ...options.headers,
            ...defaultHeaders,
            [csrfTokenName]: token,
        },
    })
        .then(handleFetchError)
        .then(checkAndParseJsonResp)
        .catch(error => {
            if (error.status === 401) {
                goToLogin();
            } else {
                throw error;
            }
        });
}

export function fetchJson2(url, options = {}) {
    const token = csrfToken();
    addCsrfToken(options, token);
    return fetch(url, {
        mode: 'cors',
        credentials: 'same-origin',
        ...options,
        headers: {
            ...options.headers,
            ...defaultHeaders,
            [csrfTokenName]: token,
        },
    })
        .then(handleFetchError)
        .then(checkAndParseJsonResp);
}

export function toFormData(obj) {
    const result = new FormData();
    Object.keys(obj).forEach(k => result.append(k, obj[k]));
    return result;
}

/**
 * Build a "post" form (using data) and submit it (*No ajax*)
 */
export function postForm(url, data) {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = url;
    Object.keys(data).forEach(key => {
        const list = isArray(data[key]) ? data[key] : [data[key]];
        list.forEach(value => {
            const input = document.createElement('input');
            input.name = key;
            input.value = value;
            form.appendChild(input);
        });
    });

    // Add CSRF token
    const input = document.createElement('input');
    input.name = csrfTokenName;
    input.value = csrfToken();
    form.appendChild(input);

    document.body.appendChild(form); // form should be added to body otherwise: Getting Error “Form submission canceled because the form is not connected”
    form.submit();
}

// ---------------------------------------------------------------------------------------------------------------
//  Search data.

export const range = (start, end) => Array.apply(null, Array(end - start + 1)).map((_, i) => i + start);

export function namesToIdNameListWithDefault(names, defaultVal = 'Any') {
    return [defaultVal, ...names].map((name, id) => ({ id, name }));
}
export function namesToIdNameList(names) {
    return names.map((name, id) => ({ id: id + 1, name }));
}

// ---------------------------------------------------------------------------------------------------------------

/**
 * Cuts a text to a specified length by keeping entire words.
 *
 * @param {*} text
 * @param {*} length
 */
export function textAbstract(text, length) {
    if (text == null) {
        return '';
    }
    if (text.length <= length) {
        return text;
    }
    text = text.substring(0, length);
    const last = text.lastIndexOf(' ');
    text = text.substring(0, last);
    return text + ' (...)';
}

// ---------------------------------------------------------------------------------------------------------------

export function showLoginDialog() {
    let button = document.querySelector('.login-button');
    //check if class is for mobile view
    if (!button) button = document.querySelector('.rc-login-button button');
    if (button) {
        button.click();
    }
}

// ---------------------------------------------------------------------------------------------------------------

export function goToAuctionUrl(id, reference) {
    var match = /\/([^/]+)\//.exec(document.location.pathname);
    const lang = match ? match[1] : 'en';
    let url = `/${lang}/car/info?auctionId=${id}`;
    if (reference) {
        url = url + '&ref=' + reference;
    }
    return url;
}

export function goToAuction(id, reference) {
    document.location = goToAuctionUrl(id, reference);
}

// ---------------------------------------------------------------------------------------------------------------

export function scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}

export function scrollToBottom() {
    window.scrollTo(0, document.body.scrollHeight);
}

/**
 * Turns a query string like hello=1&another=2 into object {hello: 1, another: 2}.
 * From there, it's easy to extract the variable you need
 *
 * to avoid case issues: key always converted to lowercase.
 *
 * cfr https://stackoverflow.com/questions/2090551/parse-query-string-in-javascript
 *
 * @param {} queryString
 */
export function parsedQueryString(queryString) {
    const result = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');
        result[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        result[decodeURIComponent(pair[0]).toLowerCase()] = decodeURIComponent(pair[1] || '');
    }
    return result;
}

export function decodeURIComponentSafe(s) {
    if (!s) {
        return s;
    }
    return decodeURIComponent(s.replace(/%(?![0-9a-fA-F]{2})/g, '%25')); // converts % to %25 (why?) => skipped!
}

/**
 * Generate unique GUID
 *
 * https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
 *
 * !! crypto API not available on IE11
 *
 */

function uuidv4FallbackIE11() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

function uuidv4WithCrypto() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
    );
}

export function uuidv4() {
    return window.crypto !== undefined ? uuidv4WithCrypto() : uuidv4FallbackIE11();
}

/**
 * Checks if current device is handheld one.
 */
export function isHandheldDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

/**
 * given a list of values (numbers) returns the range (from, to) where a specific value is located.
 *
 * if  list = [50 ,5000 ,10000 ,20000 ,30000 ,40000 ,50000 ,60000 ,70000 ,80000 ,90000 ,100000 ,125000 ,140000, 150000 ,175000 ,200000];
 * and  value = 107000;
 * returns {from: 100000, to: 125000}
 *
 * @param {*} list
 * @param {*} value
 */
export function fromToInList(list, value) {
    const below = list.filter(a => a <= value);
    const above = list.filter(a => a > value);
    return {
        from: below.length ? below[below.length - 1] : 0,
        to: above.length ? above[0] : 0,
    };
}

/**
 * Utility function used to remove duplicates in list.
 * To be used like this: list.filter(distinct).
 * returns another list without duplicates.
 *
 * @param {*} value
 * @param {*} index
 * @param {*} self
 */
export const distinct = (value, index, self) => self.indexOf(value) === index;

/**
 * a react hooks version of setInterval
 *
 * https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 *
 * @param {*} callback
 * @param {*} delay
 */
export function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

/**
 * Component used to import bid amount.
 * The amount is formatted (dot separator for thousand) when typing.
 *
 * expected props: value, placeholder, className, onChange
 */
export class FormattedInput extends Component {
    // https://reactjs.org/docs/forms.html#controlled-components
    constructor(props) {
        super(props);
        this.state = { val: '' };
    }
    static getDerivedStateFromProps(props, currentState) {
        if (props.moneyFormatter) {
            return { val: props.value ? inputMoneyFormatter(props.value || 0, 0, props.currencyCode) : '' };
        }
        return { val: props.value ? formatMoney(props.value || 0, 0, props.currencyCode) : '' };
    }
    setInputFocus() {
        if (this.textInput) {
            this.textInput.focus();
        }
    }
    handleChange(evt) {
        this.props.onChange(parseInt(evt.target.value.replace(/[^\d]/g, ''), 10));
    }
    handleKeyEvent(evt) {
        // to fix issue: left/right key press has effect on pictures navigation.
        // https://dev.azure.com/CarsOnTheWeb/CarsOnTheWeb/_workitems/edit/1394
        evt.stopPropagation();
    }
    render() {
        return (
            <input
                ref={input => {
                    this.textInput = input;
                }}
                value={this.state.val}
                className={this.props.className}
                placeholder={this.props.placeholder}
                onChange={this.handleChange.bind(this)}
                onKeyPress={this.handleKeyEvent.bind(this)}
                onKeyDown={this.handleKeyEvent.bind(this)}
                onKeyUp={this.handleKeyEvent.bind(this)}
            />
        );
    }
}

export function currentLanguageFromUrl() {
    const match = /^\/([^/]+)/.exec(location.pathname);
    return match ? match[1] : 'en';
}

export function goToMyCotwForConfirmation(auctionId, currentLanguage = null) {
    window.location.href = `/${currentLanguage || currentLanguageFromUrl()}/auctionprocess/index?AuctionId=${auctionId}`;
}

export function goToMyCotwForWaitingAssignment(currentLanguage) {
    window.location.href = `/${currentLanguage}/myaccount/bids`;
}

export function goToHome(currentLanguage = null) {
    window.location.href = `/${currentLanguage || currentLanguageFromUrl()}/home`;
}

export function goToLogin() {
    // fix cfr https://karauctionservices.visualstudio.com/International/_workitems/edit/604529
    if (document.getElementById('loginButton2')) {
        // check if not logged in yet.
        localStorage.setItem('showLoginModal', true);
        goToHome();
    }
}

export function goToSearch(currentLanguage = null) {
    window.location.href = `/${currentLanguage || currentLanguageFromUrl()}/findcar`;
}
export function goToMyCotwDashboard(currentLanguage = null) {
    window.location.href = `/${currentLanguage || currentLanguageFromUrl()}/myaccount/dashboard`;
}
export function goToMyPurchases(currentLanguage = null) {
    window.location.href = `/${currentLanguage || currentLanguageFromUrl()}/my-account/dashboard`;
}

export function goToMyCotwAccount(currentLanguage = null) {
    window.location.href = `/${currentLanguage || currentLanguageFromUrl()}/myaccount/account`;
}
export function goToMyCotwAccountProfile(currentLanguage = null, showBusinesTypeForm = false) {
    window.location.href =
        `/${currentLanguage || currentLanguageFromUrl()}/my-account/settings/my-profile` + (showBusinesTypeForm ? `?showBusinesTypeForm=true` : '');
}

/**
 * classnames added when corresponding conditions are met.
 *
 * if input obj = {
 *      'classname1': condition1,
 *      'classname2': condition2
 * }
 *
 * converted to string 'classname1 classname2' when condition1 & condition2 met.
 *
 */
export const classnames = obj =>
    Object.keys(obj)
        .filter(k => !!obj[k])
        .join(' ');

/**
 * cfr https://usehooks.com/useHover/
 *
 * sets a flag on hover.
 *
 */
export function useHover() {
    const [value, setValue] = useState(false);

    const ref = useRef(null);

    const handleMouseOver = () => setValue(true);
    const handleMouseOut = () => setValue(false);

    useEffect(
        () => {
            const node = ref.current;
            if (node) {
                node.addEventListener('mouseover', handleMouseOver);
                node.addEventListener('mouseout', handleMouseOut);

                return () => {
                    node.removeEventListener('mouseover', handleMouseOver);
                    node.removeEventListener('mouseout', handleMouseOut);
                };
            }
        },
        [ref.current], // Recall only if ref changes
    );

    return [ref, value];
}

function url(urlSuffix, currentLanguage) {
    return settings.carsiteBaseUrl + '/' + currentLanguage + '/' + urlSuffix;
}

function getMobilePlatform() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
        return 'android';
    }

    if (/iPad|iPhone|iPod|iOS/i.test(userAgent)) {
        return 'ios';
    }

    return '';
}

// log analytics events (in table: Analytics, DB: MyCotwAnalytics via analyticsservice)
export function logAnalytics(category, action, currentLanguage, message1, message2, fromBuyerApp = false) {
    const analyticsUrl = url(`findcarv6/loganalytics`, currentLanguage || currentLanguageFromUrl());
    fetchJson(analyticsUrl, {
        method: 'POST',
        body: JSON.stringify({
            device: navigator.userAgent.substring(0, 100),
            application: fromBuyerApp ? `mobile ${getMobilePlatform()}` : 'carsiteUI',
            version: '1',
            category: category,
            action: action,
            message1: message1 ? message1 : null,
            message2: message2 ? message2 : null,
            message3: null,
            eventValue: 0,
        }),
    })
        .then(r => {})
        .catch(e => console.error('Cannot log analytics: ', e));
}

/**
 * Allows to generate a resized thumbnail at cloudflare (genarared on the fly + cached).
 * cfr https://karauctionservices.visualstudio.com/International/_workitems/edit/181496
 *
 */
//export const resizedImageUrlCloudflare = (imgUrl, targetWidth=100) => imgUrl.replace(/https?:\/\/images\.adesa\.eu/i, a => `${a}/cdn-cgi/image/width=${targetWidth}`);
// !!! disabled cloudflare resizing as number of requests are limited.
//export const resizedImageUrlCloudflare = (imgUrl, targetWidth=100) => imgUrl;

export const thumbnailImageUrl = (imgUrl, targetWidth = 100) => {
    try {
        //example:
        //  original url: https://dev-carsite.cotw.eu/carimgs\WAUZZZF49JA210567\general\5.jpg?t=637550436813701910
        //  target url: https://dev-thumbnails.cotw.eu/thumbnail/WAUZZZF49JA210567/5.jpg
        const [_, chassisNr, name] = /carimgs[\\/](\w+)[\\/]\w+[\\/](.+)($|\?)/i.exec(imgUrl);
        if (settings.thumbnailServiceUrlPrefix && chassisNr && name) {
            return `${settings.thumbnailServiceUrlPrefix}/thumbnail/${chassisNr}/${name}${name.includes('?') ? '&' : '?'}size=${targetWidth}`;
        }
        return imgUrl;
    } catch (ex) {
        console.error('thumbnailImageUrl', ex);
        return imgUrl;
    }
};

export function communityProfilePictureUrl(communityId) {
    ///return `${settings.dealerSalesServiceUrl}/public/community/${communityId}/profile-picture`;
    return `${settings.communityImagesBaseUrl}/${communityId}/profilePicture/profilePicture.jpeg`;
}

export const goToAnchor = name => {
    var target = document.querySelector(`a[name='${name}']`);
    if (target) {
        window.scrollTo(0, target.offsetTop);
    }
};

export const cmsUrl = (language, suffix) => (/^https?:\/\//i.test(suffix) ? suffix : `https://www.openlane.eu/${language}/${suffix}`);

export const actionLink = (suffix, language, t, label = '', lowerCase = false) => {
    let actionLabel = t(`footer.${label ? label : suffix}`);
    if (lowerCase) actionLabel = actionLabel.toLowerCase();

    return (
        <a id={suffix} rel="noreferrer" target="_blank" href={cmsUrl(language, t(`footer.${suffix}Link`))}>
            {actionLabel}
        </a>
    );
};

export function assert(condition, message) {
    try {
        if (!condition) throw message || 'Assertion failed';
    } catch (message) {
        console.log(message);
    }
}

export function decodeHTML(html) {
    if (!html) return html;
    const map = { gt: '>', lt: '<', nbsp: ' ' };
    return html.replace(/&(#(?:x[0-9a-f]+|\d+)|[a-z]+);?/gi, ($0, $1) => {
        if ($1[0] === '#') {
            return String.fromCharCode($1[1].toLowerCase() === 'x' ? parseInt($1.substr(2), 16) : parseInt($1.substr(1), 10));
        } else {
            return Object.prototype.hasOwnProperty.call(map, $1) ? map[$1] : $0;
        }
    });
}

export const vatRegimeLabel = (isMarginCar, isUKCountry, t) => {
    /*
    #720225 Margin  (used for EU/UK)
    Non-margin (used for if catalogue 1 is linked to the company)
    Qualifying (used for if catalogue 2 is linked to the company)\
    
    //Rules for EU vat regime
    EU + isMargin = true then => Margin 
    EU + isMargin = false then => Non-margin

    //Rules for UK vat regime
    UK + isMargin = true and isCommercial = true then => Margin
    UK + isMargin = true and isCommercial = false then => Margin
    UK + isMargin = false and isCommercial = true then => Qualifying
    UK + isMargin = false and isCommercial = false then  => Qualifying
*/
    let vatNonDeductableLabel = t('vatRegime.margin');
    let vatDeductablelabel = isUKCountry ? t('vatRegime.qualifying') : t('vatRegime.nonMargin');
    return isMarginCar ? vatNonDeductableLabel : vatDeductablelabel;
};

export const has = (value, prop) => value && prop in value;

export const isEmpty = value => {
    if (Array.isArray(value) || typeof value === 'string') {
        return value.length === 0;
    }

    if (value && typeof value === 'object') {
        return Object.keys(value) === 0;
    }

    if (value instanceof Set || value instanceof Map) {
        return value.size === 0;
    }

    // shouldn't pass non-array/string/object, but remain compatible with lodash by returning true for other types
    return true;
};

export const isWebViewInMobileApp = 'ReactNativeWebView' in window || location.hash === '#mobile-app'; // hash added by the mobile app when showing web page in web view (or used to test)

export const isEuCountry = (countryId, countries) => {
    if (!countries || countries.length === 0) {
        return false;
    }
    return (
        countries
            .filter(a => a.isEU)
            .map(a => a.id)
            .indexOf(countryId) > -1
    );
};
