import React from 'react';
import { NavbarItemLanguageSelect as NavbarItemLanguageSelectWebKit } from '@ambiorix/adesa-web-kit';
import { getSEOPage } from '../../../routing';
import { languageOptionsMeta } from '../../../state/cotw';
import { AdesaSelect } from '../../Common/ui-components';
import styles from './common.module.scss';

export const logout = currentLanguage => e => {
    e.preventDefault();
    window.localStorage.setItem('contactId', 0); // clear it to unregister firebase (see notification.js in cotwsource)
    document.location = `/${currentLanguage}/login/logout`;
};

export function changeLanguage(newLang) {
    const currentPath = document.location.pathname;
    var seoPage = getSEOPage();
    if (seoPage !== undefined) {
        document.location = seoPage.t.replace(/^\/[^/]+/, '/' + newLang);
    } else {
        document.location = currentPath.replace(/^\/[^/]+/, '/' + newLang) + document.location.search;
    }
}

export const LanguageSelector = ({ currentLanguage, Component = AdesaSelect }) => {
    return (
        <AdesaSelect
            id="COTWLanguages"
            key={currentLanguage}
            defaultValue={currentLanguage}
            value={currentLanguage}
            onChange={evt => {
                changeLanguage(evt.detail.value);
            }}>
            {languageOptionsMeta &&
                languageOptionsMeta.map(item => {
                    return (
                        <option key={item.value} value={item.value}>
                            {item.label}
                        </option>
                    );
                })}
        </AdesaSelect>
    );
};

export const NavbarItemLanguageSelect = ({ currentLanguage }) => {
    return (
        <NavbarItemLanguageSelectWebKit
            id="COTWLanguages"
            className={styles.navbarItemLanguageSelect}
            value={currentLanguage}
            onChange={event => changeLanguage(event.target.value)}>
            {languageOptionsMeta &&
                languageOptionsMeta.map(item => {
                    return (
                        <option key={item.value} value={item.value}>
                            {item.label}
                        </option>
                    );
                })}
        </NavbarItemLanguageSelectWebKit>
    );
};

export const isMyFavorites = currentUrl => /\/myaccount\/biddingdashboard\b/i.test(currentUrl) && /#following/.test(location.hash);
export const goToMyFavorites = onGoToPage => e => onGoToPage('myaccount/biddingdashboard#following');
export const isMyBids = currentUrl => /\/myaccount\/biddingdashboard\b/i.test(currentUrl) && !/#following/.test(location.hash);
export const goToMyBids = onGoToPage => e => onGoToPage('myaccount/biddingdashboard');
export const isSavedSearches = currentUrl => /\/findcar\/saved\b/i.test(currentUrl);
export const goToSavedSearches = onGoToPage => e => onGoToPage('findcar/saved');
export const isMyAccountBidders = currentUrl => /\/my-account\/bidders\b/i.test(currentUrl);
export const goToMyAccountBidders = onGoToPage => e => onGoToPage('my-account/bidders');
export const isMyAccountDashboard = currentUrl => /\/my-account\/dashboard\b/i.test(currentUrl);
export const goToMyAccountDashboard = onGoToPage => e => onGoToPage('my-account/dashboard');
export const isMyAccountDocuments = currentUrl => /\/my-account\/documents\b/i.test(currentUrl);
export const goToMyAccountDocuments = onGoToPage => e => onGoToPage('my-account/documents');
export const isMyAccountFin = currentUrl => /\/my-account\/fin\b/i.test(currentUrl);
export const goToMyAccountFin = onGoToPage => e => onGoToPage('my-account/fin');
export const isMyAccountOrders = currentUrl => /\/my-account\/orders\b/i.test(currentUrl);
export const goToMyAccountOrders = onGoToPage => e => onGoToPage('my-account/orders');
export const isMyAccountCommunityOrders = currentUrl => /\/my-account\/community-orders\b/i.test(currentUrl);
export const goToMyAccountCommunityOrders = onGoToPage => e => onGoToPage('my-account/community-orders');
export const isMyAccountRecommendations = currentUrl => /\/my-account\/recommendations\b/i.test(currentUrl);
export const goToMyAccountRecommendations = onGoToPage => e => onGoToPage('my-account/recommendations');
export const isMyAccountSettings = currentUrl => /\/my-account\/settings\b/i.test(currentUrl);
export const goToMyAccountSettings = onGoToPage => e => onGoToPage('my-account/settings');
export const isBidderAccountSettings = currentUrl => /\/my-account\/settings\b/i.test(currentUrl);
export const goToBidderAccountSettings = onGoToPage => e => onGoToPage('my-account/orders');
export const isMyDocuments = currentUrl => /\/my-account\/my-documents\b/i.test(currentUrl);
export const goToMyDocuments = onGoToPage => e => onGoToPage('my-account/my-documents');
export const isMyOrders = currentUrl => /\/my-account\/my-orders\b/i.test(currentUrl);
export const goToMyOrders = onGoToPage => e => onGoToPage('my-account/my-orders');

export const isMyAccountClaims = currentUrl => /\/my-account\/claims\b/i.test(currentUrl);
export const goToMyAccountClaims = onGoToPage => e => onGoToPage('my-account/claims');
export const isMyClaims = currentUrl => /\/my-account\/my-claims\b/i.test(currentUrl);
export const goToMyClaims = onGoToPage => e => onGoToPage('my-account/my-claims');
export const isMyAccountArchive = currentUrl => /\/my-account\/archive\b/i.test(currentUrl);
export const goToMyAccountArchive = onGoToPage => e => onGoToPage('my-account/archive');
export const isMyAccountLogistics = currentUrl => /\/my-account\/logistics\b/i.test(currentUrl);
export const goToMyAccountLogistics = onGoToPage => e => onGoToPage('my-account/logistics');

export const isCommunities = currentUrl => /\/communities\b/i.test(currentUrl);
export const goToCommunities = onGoToPage => e => onGoToPage('communities');
export const isMySelection = currentUrl => /\/my-recommendations\b/i.test(currentUrl);
export const goToMySelection = onGoToPage => e => onGoToPage('my-recommendations');

export const isMyAccountHelp = currentUrl => /\/my-account\/help\b/i.test(currentUrl);
export const goToMyAccountHelp = onGoToPage => e => onGoToPage('my-account/help');
