import React from 'react';
import { formatNumber, mileageFormat, vatRegimeLabel } from '../../utils';
import { formatDate } from '../../utils/date';
import { Information, Stars } from '../ui/common-ui';
import { LocationDialogAndLink } from '../ui/LocationDialog';
import { registrationDocumentAvailability } from '../../state/cotw';
import VehicleCondition from './VehicleCondition';
import { SellerScore } from './SellerScore';
import { Title, DetailLine, LinesGroup, styles, displayInventoryType } from './Common';

import { TyresSection } from './TyresSection';

import './CarProfile.less';

// messages that do not contain additional information than "yes" or "no"...
const messagesToHide = [
    'IsRegDocAvailable.Belgium',
    'IsRegDocAvailable.Luxemburg',
    'IsRegDocAvailable.No',
    'IsRegDocAvailable.Serbia',
    'IsRegDocAvailable.Yes',
    'IsCocAvailable.Italia',
    'IsCocAvailable.Yes',
];

export default ({
    carDetails,
    priceInfo,
    carDamages,
    sellerScores,
    importantGeneralMessages,
    importantFiscalMessages,
    importantDocMessages,
    importantOriginMessages,
    isCommunityAuction,
    isUserLoggedIn,
    isReportRequested,
    sellerGroups,
    currentUser,
    t,
}) => {
    // Convert some messages into fields: isCocAvailable + isRegDocAvailable.
    // guess field value based on available messages (see excel file received from Nick: "VeryImportantCountryInformation Redistribution.xlsx")
    const displayCoc = regDocAvailability !== ''; //  importantDocMessages.filter(a => a.startsWith("IsCoc")).length > 0;
    const isCocAvailable =
        carDetails.COCAvailable == 2
            ? carDetails.isBelgianLightTruck
                ? 'vehicleDetails.profile.onlyBelgiancoc'
                : 'common.value.available'
            : carDetails.COCAvailable == 0
            ? 'common.value.notavailable'
            : '';
    const cocAvailableWarning = carDetails.COCAvailable !== 2;

    /*
            carDetails.COCAvailable & RegistrationDocsAvailable
                0: missing
                1: incomplete
                2: complete
    */

    const displayRegDoc = importantDocMessages.filter(a => a.startsWith('IsRegDoc')).length > 0;
    const regDocAvailability = registrationDocumentAvailability(carDetails.RegistrationDocsAvailable);

    const sellerGroupName = sellerGroups ? Object.keys(sellerGroups).find(x => sellerGroups[x].indexOf(carDetails.sellerId) > -1) : '';

    if (carDetails.isUkCountryCar && !messagesToHide.includes('IsCocAvailable.No')) {
        messagesToHide.push('IsCocAvailable.No');
    }

    // remove RegDoc & IsCoc messages from the list as they are displayed as fields.
    let docMessages = importantDocMessages.filter(a => messagesToHide.indexOf(a) === -1);

    //Only show COC message for BE & DE when not available
    if (carDetails.COCAvailable == 2 && (carDetails.originCountryOfCarCountryId === 'be' || carDetails.originCountryOfCarCountryId === 'de')) {
        docMessages = docMessages.filter(a => a.indexOf('IsCocAvailable') === -1);
    }

    if (carDetails.RegistrationDocsAvailable == 1 && docMessages.indexOf('IsRegDocAvailable.Incomplete') === -1) {
        docMessages.unshift('IsRegDocAvailable.Incomplete');
    } else if (carDetails.RegistrationDocsAvailable == 0 && docMessages.indexOf('IsRegDocAvailable.Missing') === -1) {
        docMessages.unshift('IsRegDocAvailable.Missing');
    }
    const regDocAvailableWarning = carDetails.RegistrationDocsAvailable !== 2;

    const generalMessages = [];
    if (importantGeneralMessages) generalMessages.push(...importantGeneralMessages);
    if (carDetails.isElectricCarWithoutCables) {
        generalMessages.push('vehicleDetails.equipment.noChargingCables');
    }

    const fiscalMessages = [];
    if (importantFiscalMessages) fiscalMessages.push(...importantFiscalMessages);

    const isDrivableLabel = {
        yes: 'vehicleDetails.damages.drivableYes',
        no: 'vehicleDetails.damages.drivableNo',
        unknown: 'vehicleDetails.damages.drivableUndefined',
    };
    // TODO for dataAttr: "car-make", "car-model", "car-type" (trimline)

    const hasDamageReport = carDamages && carDamages.reports && carDamages.reports.some(x => x.TypeId != 3);
    const isDamageReportBannerVisible = carDamages && carDamages.reportsFetched && !carDetails.hasDamages && hasDamageReport;

    const priceDetails = priceInfo ? priceInfo.currentPriceDetails || {} : {};

    const formattedMileage = carDetails => (carDetails.mileage ? `${formatNumber(carDetails.mileage, 0)} ${carDetails.mileageUnit}` : '-');

    return (
        <div className="rc-CarProfile">
            <VehicleCondition t={t} carDetails={carDetails} isReportRequested={isReportRequested} />
            <section>
                <Title label={t('vehicleDetails.specifications.headline')} />
                {generalMessages && generalMessages.length > 0 ? (
                    <Information>
                        {generalMessages.length == 1 ? (
                            t(generalMessages[0])
                        ) : (
                            <ul>
                                {generalMessages.map(a => (
                                    <li key={a}>{t(a)}</li>
                                ))}
                            </ul>
                        )}
                    </Information>
                ) : null}

                <LinesGroup>
                    {carDetails.registration ? (
                        <DetailLine
                            label={t('vehicleDetails.profile.1stRegistration')}
                            dataAttr="car-first-registration"
                            value={carDetails.registration}
                        />
                    ) : null}
                    {carDetails.modelYear ? (
                        <DetailLine label={t('cardetail.modelyear')} dataAttr="car-model-year" value={carDetails.modelYear} />
                    ) : null}
                    {carDetails.production ? (
                        <DetailLine label={t('vehicleDetails.profile.production')} dataAttr="car-production" value={carDetails.production} />
                    ) : null}
                    {carDetails.retail ? (
                        <DetailLine label={t('vehicleDetails.profile.retail')} dataAttr="car-retail" value={carDetails.retail} />
                    ) : null}
                    <DetailLine label={t('vehicleDetails.profile.mileage')} dataAttr="car-mileage" value={formattedMileage(carDetails)} />
                    <DetailLine label={t('vehicleDetails.profile.fuelType')} dataAttr="car-fuel" value={carDetails.fuel ? carDetails.fuel : '-'} />

                    {carDetails.batteryCapacity ? (
                        <DetailLine
                            label={t('vehicleDetails.profile.batteryCapacity')}
                            dataAttr="car-batteryCapacity"
                            value={`${carDetails.batteryCapacity} ${t('common.units.kwh')}`}
                        />
                    ) : null}
                    {carDetails.electricRange ? (
                        <DetailLine
                            label={t('vehicleDetails.profile.electricRange')}
                            dataAttr="car-electricRange"
                            value={`${carDetails.electricRange} ${carDetails.mileageUnit}`}
                        />
                    ) : null}
                    {carDetails.batteryLeased ? ( // only display if "yes"
                        <DetailLine
                            label={t('vehicleDetails.profile.batteryLeased')}
                            dataAttr="car-batteryLeased"
                            value={t(carDetails.batteryLeased ? 'common.value.yes' : 'common.value.no')}
                        />
                    ) : null}
                    {carDetails.electricRangeExtender !== undefined && carDetails.electricRangeExtender !== null ? (
                        <DetailLine
                            label={t('vehicleDetails.profile.electricRangeExtender')}
                            dataAttr="car-electricRangeExtender"
                            value={t(carDetails.electricRangeExtender ? 'common.value.yes' : 'common.value.no')}
                        />
                    ) : null}
                    {carDetails.acChargePower ? (
                        <DetailLine
                            label={t('vehicleDetails.profile.ACChargePower')}
                            dataAttr="car-ACChargePower"
                            value={`${carDetails.acChargePower} ${t('common.units.kw')}`}
                        />
                    ) : null}
                    {carDetails.dcMaxChargePower ? (
                        <DetailLine
                            label={t('vehicleDetails.profile.DCMaxChargePower')}
                            dataAttr="car-DCMaxChargePower"
                            value={`${carDetails.dcMaxChargePower} ${t('common.units.kw')}`}
                        />
                    ) : null}
                    <DetailLine
                        label={t('vehicleDetails.profile.transmissionType')}
                        dataAttr="car-transmission"
                        value={carDetails.gearbox ? carDetails.gearbox : '-'}
                    />
                    {carDetails.fourWheelDrive ? (
                        <DetailLine label={t('vehicleDetails.profile.fourWheelDrive')} value={t('common.value.yes')} />
                    ) : null}
                    {carDetails.euNorm ? (
                        <DetailLine
                            label={t('vehicleDetails.profile.co2Standard')}
                            dataAttr="car-emission"
                            value={carDetails.euNorm ? carDetails.euNorm : '-'}
                        />
                    ) : null}
                    {carDetails.co2 ? (
                        <DetailLine
                            label={t(
                                carDetails.isCo2FromSeller ? 'vehicleDetails.profile.co2EmissionFromSeller' : 'vehicleDetails.profile.co2Emission',
                            )}
                            dataAttr="car-emission"
                            value={carDetails.co2 ? carDetails.co2 : '-'}
                        />
                    ) : null}
                    <DetailLine label={t('vehicleDetails.profile.horsepower')} dataAttr="car-kw" value={carDetails.kw ? carDetails.kw : '-'} />
                    {!carDetails.isElectricCar ? (
                        <DetailLine
                            label={t('vehicleDetails.profile.engineSize')}
                            dataAttr="car-engine"
                            value={carDetails.cc ? formatNumber(carDetails.cc) + ' ' + t('common.unit.cc') : '-'}
                        />
                    ) : null}
                    <DetailLine label={t('vehicleDetails.profile.bodyType')} dataAttr="car-body" value={carDetails.size ? carDetails.size : '-'} />
                    <DetailLine label={t('vehicleDetails.profile.doors')} dataAttr="car-doors" value={carDetails.door ? carDetails.door : '-'} />
                    <DetailLine
                        label={t('vehicleDetails.profile.placesNumber')}
                        dataAttr="car-places"
                        value={carDetails.places ? carDetails.places : '-'}
                    />
                    {carDetails.isUkCountryCar && isUserLoggedIn ? (
                        <DetailLine
                            label={t('reports.damage.vehicleDetails.licencePlate')}
                            dataAttr="car-licenseplate"
                            value={carDetails.licensePlace ? carDetails.licensePlace : '-'}
                        />
                    ) : null}
                    <DetailLine
                        label={t('vehicleDetails.profile.chassisNumber')}
                        dataAttr="car-chassisnumber"
                        value={carDetails.chassis ? carDetails.chassis : '-'}
                        className="uitest-profile-chassisnr"
                    />
                    <DetailLine label={t('vehicleDetails.profile.chassisNumberShort')} value={carDetails.chassisAbr ? carDetails.chassisAbr : '-'} />
                    <DetailLine label={t('vehicleDetails.profile.numberOfKeys')} value={carDetails.nbrKeys ? carDetails.nbrKeys : '-'} />
                    <DetailLine
                        label={t('vehicleDetails.profile.bodyPaint')}
                        dataAttr="car-paint"
                        value={carDetails.bodyColor ? carDetails.bodyColor : '-'}
                    />
                    {carDetails.equipColor ? (
                        <DetailLine
                            label={t('vehicleDetails.profile.interiorColour')}
                            dataAttr="car-interior"
                            value={carDetails.equipColor ? carDetails.equipColor : '-'}
                        />
                    ) : null}
                    {carDetails.inventoryType && !carDetails.isUkCountryCar && (
                        <DetailLine label={t('vehicleDetails.profile.inventoryType')} value={displayInventoryType(carDetails.inventoryType, t)} />
                    )}
                </LinesGroup>
            </section>
            <section>
                <Title label={t('vehicleDetails.profile.fiscalRegime')} />
                {fiscalMessages.length > 0 ? (
                    <Information>
                        {fiscalMessages.length == 1 ? (
                            t(fiscalMessages[0])
                        ) : (
                            <ul>
                                {fiscalMessages.map(a => (
                                    <li key={a}>{t(a)}</li>
                                ))}
                            </ul>
                        )}
                    </Information>
                ) : null}
                <LinesGroup>
                    <DetailLine
                        label={t('cardetail.VAT')}
                        value={vatRegimeLabel(priceDetails.isMarginCar || carDetails.isMarginCar, carDetails.isUkCountryCar, t)}
                    />
                    {carDetails.fiscalLightTruck ? (
                        <DetailLine label={t('vehicleDetails.profile.fiscalLightTruck')} value={t('common.value.yes')} />
                    ) : null}
                </LinesGroup>
            </section>
            {!isCommunityAuction ? (
                <>
                    <section>
                        <Title label={t('vehicleDetails.profile.documentsAndHistory')} />
                        {docMessages.length > 0 ? (
                            <Information>
                                {docMessages.length == 1 ? (
                                    t(docMessages[0])
                                ) : (
                                    <ul>
                                        {docMessages.map(a => (
                                            <li key={a}>{t(a)}</li>
                                        ))}
                                    </ul>
                                )}
                            </Information>
                        ) : null}

                        {isDamageReportBannerVisible ? <Information>{t('vehicleDetails.profile.damageReportBanner')}</Information> : null}

                        <LinesGroup>
                            {regDocAvailability !== '' ? (
                                <DetailLine
                                    label={t('vehicleDetails.profile.registrationDocuments')}
                                    value={t(regDocAvailability)}
                                    className={regDocAvailableWarning ? 'warning' : ''}
                                />
                            ) : null}
                            {displayCoc && isCocAvailable !== '' ? (
                                <DetailLine
                                    label={t('vehicleDetails.profile.coc')}
                                    value={t(isCocAvailable)}
                                    className={cocAvailableWarning ? 'warning' : ''}
                                />
                            ) : null}
                            {carDetails.hasMaintenanceBook ? (
                                <DetailLine
                                    label={t('vehicleDetails.profile.maintenanceRecords')}
                                    value={carDetails.hasMaintenanceBook ? t('common.value.available') : '-'}
                                />
                            ) : null}
                            {carDetails.warrantyExpDate || carDetails.warrantyExpMileage ? (
                                <DetailLine
                                    label={t('vehicleDetails.profile.warrantyRecord')}
                                    value={
                                        carDetails.warrantyExpDate
                                            ? formatDate(carDetails.warrantyExpDate, 'short') +
                                              ' ' +
                                              (carDetails.warrantyExpMileage ? '- ' + mileageFormat(carDetails.warrantyExpMileage) : '')
                                            : mileageFormat(carDetails.warrantyExpMileage)
                                    }
                                />
                            ) : null}
                            {carDetails.v5Present && <DetailLine label={t('vehicleDetails.profile.v5Present')} value={carDetails.v5Present || ''} />}
                            {carDetails.maintenanceRecordComment && (
                                <DetailLine
                                    label={t('vehicleDetails.profile.maintenanceRecordComment')}
                                    value={carDetails.maintenanceRecordComment}
                                />
                            )}
                        </LinesGroup>
                    </section>
                    <section>
                        <Title label={t('vehicleDetails.profile.vehicleOrigin')} />
                        {importantOriginMessages && importantOriginMessages.length > 0 ? (
                            <Information>
                                {importantOriginMessages.length == 1 ? (
                                    /\s/.test(importantOriginMessages[0]) ? (
                                        importantOriginMessages[0]
                                    ) : (
                                        t(importantOriginMessages[0])
                                    )
                                ) : (
                                    <ul>
                                        {importantOriginMessages.map(a => (
                                            <li key={a}>{/\s/.test(a) ? a : t(a)}</li>
                                        ))}
                                    </ul>
                                )}
                            </Information>
                        ) : null}

                        <LinesGroup>
                            <DetailLine label={t('vehicleDetails.profile.physicalLocation')}>
                                {carDetails.physicalLocationOfCarCountryId ? (
                                    <span className={'flag-sm ' + carDetails.physicalLocationOfCarCountryId.toLowerCase()}></span>
                                ) : null}
                                {carDetails.physicalLocationOfCar ? (
                                    // <a href={googleMapLink(carDetails.physicalLocationOfCar)} target="_blank" rel="noopener noreferrer" className="link">{carDetails.physicalLocationOfCar}</a>
                                    <LocationDialogAndLink
                                        address={carDetails.physicalLocationOfCar}
                                        linkText={carDetails.physicalLocationOfCar}
                                        t={t}
                                    />
                                ) : null}
                            </DetailLine>
                            <DetailLine label={t('vehicleDetails.profile.originCountry')}>
                                {carDetails.originCountryOfCarCountryId ? (
                                    <span className={'flag-sm ' + carDetails.originCountryOfCarCountryId.toLowerCase()}></span>
                                ) : null}
                                {carDetails.originCountryOfCar ? carDetails.originCountryOfCar : '-'}
                            </DetailLine>
                            <DetailLine label={t('vehicleDetails.profile.sellingOffice')}>
                                {carDetails.sellingOfficeCountryId ? (
                                    <span className={'flag-sm ' + carDetails.sellingOfficeCountryId.toLowerCase()}></span>
                                ) : null}
                                {carDetails.sellingOffice ? carDetails.sellingOffice : '-'}
                            </DetailLine>
                            {sellerGroupName ? <DetailLine label={t('common.seller')} value={sellerGroupName}></DetailLine> : null}

                            {carDetails.isCarReImported && currentUser.country === 'de' ? (
                                <DetailLine
                                    label={t('vehicleDetails.profile.isCarReImported')}
                                    value={t('vehicleDetails.isCarReImported.yes')}></DetailLine>
                            ) : null}

                            {sellerScores.GlobalScore ? (
                                <DetailLine
                                    label={t('vehicleDetails.sellerScore.headline')}
                                    info={t('vehicleDetails.sellerScore.explanation')}
                                    id="seller-score-info">
                                    <div className="seller-score-main">
                                        <Stars value={sellerScores.GlobalScore} />
                                        <span style={{ ...styles.scoreNumber, ...styles.bold }}>
                                            {formatNumber(sellerScores.GlobalScore, sellerScores.GlobalScore === 5 ? 0 : 1)}/5
                                        </span>
                                        <SellerScore scores={sellerScores} linkText={t('vehicleDetails.sellerScore.details')} id="score-link" t={t} />
                                    </div>
                                </DetailLine>
                            ) : null}
                            {/* #541744  Moved Inventory type under origin for UK only */}
                            {carDetails.inventoryType && carDetails.isUkCountryCar && (
                                <DetailLine
                                    label={t('vehicleDetails.profile.inventoryType')}
                                    value={displayInventoryType(carDetails.inventoryType, t)}
                                />
                            )}
                        </LinesGroup>
                    </section>
                    <section>
                        <TyresSection t={t} carDetails={carDetails} />
                    </section>
                </>
            ) : null}
        </div>
    );
};
