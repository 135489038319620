import React, { Children, ComponentProps, ReactElement, useMemo } from 'react';
import { Modal, Override, useDesktop } from '@ambiorix/adesa-web-kit';
import { cx } from 'class-variance-authority';

import styles from './Modal.module.scss';

export type AdesaModalProps = Override<
    Omit<ComponentProps<typeof Modal>, 'title'>,
    {
        isBackgroundClosable?: boolean | string;
        'is-open': boolean | string;
        onChanged?: (event: CustomEvent<{ value: boolean }>) => void;
    }
>;

// map ui-components modal props to web kit modal
export const AdesaModal = ({ children, className = '', isBackgroundClosable, 'is-open': isOpen, onChanged, width, ...props }: AdesaModalProps) => {
    const isDesktop = useDesktop();

    const mappedCloseOnOutsideClick = isBackgroundClosable === 'false' ? false : Boolean(isBackgroundClosable);
    const mappedIsOpen = isOpen === 'false' ? false : Boolean(isOpen);
    const mappedWidth = width ? `${width}%` : undefined;

    const { body, footer, header } = useMemo(() => {
        const slots = Children.toArray(children);

        const getSlot = (slot: string) => {
            return slots.find(child => (child as ReactElement).props.slot === slot);
        };

        return {
            body: getSlot('body'),
            footer: getSlot('footer'),
            header: getSlot('header'),
        };
    }, [children]);

    const close = () => {
        const customEvent = new CustomEvent('changed', {
            bubbles: true,
            composed: true,
            detail: { value: false },
        });
        onChanged?.(customEvent);
    };

    return (
        <Modal
            className={cx(styles.modal, className)}
            title={header}
            actions={footer}
            actionsOrientation={isDesktop ? 'row' : 'column'}
            closeOnOutsideClick={mappedCloseOnOutsideClick}
            isOpen={mappedIsOpen}
            onClose={close}
            width={mappedWidth}
            {...props}>
            {body}
        </Modal>
    );
};
