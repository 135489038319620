import { notify } from 'reapop';
import settings from '../../appsettings';
import { supportedLanguages } from '../../constants';
import { fetchJson, getJson } from '../../utils';
import {
    VAT_VALIDATED,
    COMPANY_DETAILS_VALIDATED,
    SUBMITTED,
    COUNTRIES_LOADED,
    INVOICING_OFFICES_LOADED,
    PHONE_COUNTRY_CODES_LOADED,
    LANGUAGES_LOADED,
    SAVE_FORM_DATA,
    ACCOUNT_TYPE_VALIDATED,
    SELLER_TYPES_LOADED,
    SAVE_BUSINESS_TYPE_DETAILS,
    EDIT_BUSINESS_TYPE_DETAILS,
} from './actions';
import i18n from '../../i18n';
import { cleanedPhoneNr } from '../cotw';

export { registerReducer } from './reducer';

function url(urlSuffix, currentLanguage) {
    return settings.carsiteBaseUrl + '/' + currentLanguage + '/' + urlSuffix;
}

export const registerMapStateToProps = globalState => ({
    step: globalState.register.step,
    registerInfo: globalState.register.registerInfo,
});

export const registerActionsCreator = {
    onValidateVat: data => (dispatch, getState) => validateVat(data, dispatch, getState()),
    onLoadCountries: () => dispatch => loadCountries(dispatch),
    onLoadInvoicingOffices: () => dispatch => loadInvoicingOffices(dispatch),
    onLoadPhoneCountryCodes: () => dispatch => loadPhoneCountryCodes(dispatch),
    onLoadLanguages: () => (dispatch, getState) => loadLanguages(dispatch, getState()),
    onLoadSellerTypes: () => (dispatch, getState) => loadSellerTypes(dispatch, getState()),
    onValidateAccountType: data => (dispatch, getState) => validateAccountType(data, dispatch),
    onValidateCompanyDetails: data => (dispatch, getState) => validateCompanyDetails(data, dispatch, getState()),
    onSaveBusinessTypeDetails: data => (dispatch, getState) => saveBusinessTypeDetails(data, dispatch),
    onEditBusinessTypeDetails: data => (dispatch, getState) => editBusinessTypeDetails(data, dispatch),
    onSubmit: data => (dispatch, getState) => submit(data, dispatch, getState()),
    onSendVerificationCode: data => (dispatch, getState) => sendVerificationCode(data, dispatch, getState()),
};

function validateVat(data, dispatch, state) {
    const vat = data.vat;
    const searchUrl = url('register/validatevatnumber?vatnumber=' + vat, state.common.currentLanguage);
    return fetchJson(searchUrl, {
        headers: { 'Content-Type': 'application/json' },
    })
        .then(response => {
            //1000+ error number -> should not proceed with register
            if (response.ErrorNumber < 1000) {
                dispatch({ type: VAT_VALIDATED, response });
            }
            return Promise.resolve({ isValidVatNumber: response.IsValidVatNumber, errorNumber: response.ErrorNumber });
        })
        .catch(e => dispatch(notify({ message: 'Error while validating vat ' + e.toString(), status: 'error' })));
}

function loadCountries(dispatch) {
    return getJson(`${settings.metadataServiceUrl}/api/metadata/countries`, {
        headers: { 'Content-Type': 'application/json' },
    }).then(response => {
        if (response) dispatch({ type: COUNTRIES_LOADED, countries: response });
    });
}

function loadInvoicingOffices(dispatch) {
    return getJson(`${settings.metadataServiceUrl}/api/invoicingoffice/GetInvoicingOfficesByCountry`, {
        headers: { 'Content-Type': 'application/json' },
    }).then(response => {
        if (response) dispatch({ type: INVOICING_OFFICES_LOADED, invoicingOffices: response });
    });
}

function loadPhoneCountryCodes(dispatch) {
    return getJson(`${settings.metadataServiceUrl}/api/Country/GetPhoneCountryCodes`, {
        headers: { 'Content-Type': 'application/json' },
    }).then(response => {
        if (response) dispatch({ type: PHONE_COUNTRY_CODES_LOADED, phoneCountryCodes: response });
    });
}

function loadSellerTypes(dispatch, state) {
    const apiUrl = url('register/getsellertypes', state.common.currentLanguage);
    return fetchJson(apiUrl, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    }).then(response => {
        if (response) dispatch({ type: SELLER_TYPES_LOADED, sellerTypes: response });
    });
}

function languageFromVat(vat) {
    if (/^\s*fi/i.test(vat)) return 'fi';
    // Should we do that for other languages too ??
    return null;
}

function loadLanguages(dispatch, state) {
    dispatch({
        type: LANGUAGES_LOADED,
        languages: supportedLanguages,
        currentLanguage: languageFromVat(state.register.registerInfo.vat) || state.common.currentLanguage,
    });
}

function validateAccountType(data, dispatch) {
    dispatch({ type: ACCOUNT_TYPE_VALIDATED, data });
}

function validateCompanyDetails(data, dispatch, state) {
    const currentLanguage = state.common.currentLanguage;
    dispatch({ type: COMPANY_DETAILS_VALIDATED, data, currentLanguage });
}

function saveBusinessTypeDetails(data, dispatch) {
    dispatch({ type: SAVE_BUSINESS_TYPE_DETAILS, data });
}

function editBusinessTypeDetails(data, dispatch) {
    dispatch({ type: EDIT_BUSINESS_TYPE_DETAILS, data });
}

function submit(data, dispatch, state) {
    const submitUrl = url('register/submit', state.common.currentLanguage);
    const phone = data.phone ? cleanedPhoneNr(data.phone) : data.phone;
    const codiceDestinatarioVal = data.codiceDestinatario.length === 0 ? '0000000' : data.codiceDestinatario;
    const sellerId = data.wantsToSell && data.businessTypeId ? data.businessTypeId.toString() : data.sellerType;

    const request = {
        vat: data.vat,
        companyName: data.companyName,
        companyAddressLine1: data.companyAddressLine1,
        companyAddressLine2: data.companyAddressLine2,
        postalCode: data.postalCode,
        stateProvince: data.stateProvince,
        city: data.city,
        invoicingOffice: data.invoicingOffice,
        postaElettronicaCertificata: data.postaElettronicaCertificata,
        codiceDestinatario: codiceDestinatarioVal,
        country: data.country,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        username: data.username,
        password: data.password,
        phone,
        phoneVerificationCode: data.phoneVerificationCode,
        language: data.language,
        termsAndConditions: data.termsAndConditions,
        professionalTrader: data.professionalTrader,
        subscribeNewsletter: data.subscribeNewsletter,
        wantsToSell: data.wantsToSell,
        proposedSellerTypeId: sellerId,
        businessTypeId: data.businessTypeId,
        isSecondaryActivity: data.isSecondaryActivity,
    };
    dispatch({ type: SAVE_FORM_DATA, data });

    return fetchJson(submitUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(request),
    })
        .then(() => {
            dispatch({ type: SUBMITTED });
            window.appHistory.push(`${window.appHistory.location.pathname}?success`);
            return Promise.resolve();
        })
        .catch(error => {
            if (error.status >= 500) dispatch(notify({ message: i18n.t('registerForm.validation.general.error'), status: 'error' }));
            throw error;
        });
}

function sendVerificationCode(data, dispatch, state) {
    const phoneCountryCode = data.phoneCountryCodes.find(x => x.id === data.phonePrefix).code;
    const phoneWithPrefix = `+${phoneCountryCode}${data.phone ? cleanedPhoneNr(data.phone) : data.phone}`;
    const apiUrl = url('register/SendVerificationCode', state.common.currentLanguage);

    return fetchJson(`${apiUrl}?phoneNr=${encodeURIComponent(phoneWithPrefix)}`, {
        method: 'GET',
    })
        .then(() => {
            dispatch(notify({ message: i18n.t('registerForm.phoneValidation.phoneVerificationCode.sent'), status: 'success' }));
        })
        .catch(error => {
            dispatch(notify({ message: i18n.t('registerForm.validation.general.error'), status: 'error' }));
            throw error;
        });
}
