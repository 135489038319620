import i18n from '../../i18n';
import {
    deliveryOptionsToState,
    summaryFromSelectedOptions,
    nextStepsToState,
    minMaxDateFormatted,
    paymentDetailsToState,
    transportPriceDetails,
    paymentStatusFromString,
} from './mappings';
import { ADDRESS_TYPE, PAYMENT_STATUS } from '../cotw';
import { differenceInDays, parseDate } from '../../utils/date';
import { getCurrentDate } from '../../utils/date';

import {
    INIT,
    SET_COUNTRIES,
    SELECT_DESTINATION_COUNTRY,
    SELECT_DELIVERY_ADDRESS,
    EDIT_DELIVERY_ADDRESS,
    SELECT_TRANSPORT_MODE,
    SELECT_FINANCIAL_SERVICE,
    SELECT_ADDITIONAL_SERVICES,
    SELECT_BUYER_PROMO,
    SELECT_NON_EU_OPTION,
    REMOVE_DELIVERY_ADDRESS,
    FETCHED_ADDRESSES,
    FETCHED_DELIVERY_OPTIONS,
    FETCHED_ADDITIONAL_SERVICES,
    FETCHED_DOCUMENTS,
    SHOW_OVERVIEW,
    SHOW_CONFIRMED_VIEW,
    SAVED_INVOICING_ADDRESS,
    CHANGE_NON_EU_FIELDS,
    GO_BACK,
    CONFIRMATION_BUSY,
    FETCHED_BUYER_PROMOS,
    SET_SELECTED_OPTIONS,
    SET_PAYMENT_STATUS,
    REDIRECTING_TO_OGONE,
    SELECT_PAYMENT_METHOD_UK,
    SET_SELLER,
    SET_NOT_ALLOWED_DESTINATION_COUNTRY,
    SET_NOT_ALLOWED_DELIVERY_ADDRESS_COUNTRY,
} from './actions';

const availableFinancialServices = {
    noService: {
        id: 'NONE',
        isOptimo: false,
        isFin: false,
        priceLines: [],
        title: 'orderCompletion.options.finService.none.title',
        detail: 'orderCompletion.options.finService.none.descr',
        price: 0, // FilledIn By Service.
        reduction: 0,
        reducedPrice: 0,
        savedPrice: 0,
    },
    optimo: {
        id: 'OPTIMO',
        isOptimo: true,
        isFin: false,
        title: 'orderCompletion.options.finService.optimo.title',
        detail: 'orderCompletion.options.finService.optimo.descr',
        price: 0,
        priceLines: [],
        reduction: 0,
        reducedPrice: 0,
        savedPrice: 0,
    },
    optimoFin: {
        id: 'OPTIMO_FIN',
        isOptimo: true,
        isFin: true,
        title: 'orderCompletion.options.finService.optimoFin.title',
        detail: 'orderCompletion.options.finService.optimoFin.descr',
        price: 0,
        priceLines: [],
        reduction: 0,
        reducedPrice: 0,
        savedPrice: 0,
    },
    fin: {
        id: 'FIN',
        isOptimo: false,
        isFin: true,
        title: 'orderCompletion.options.finService.fin.title',
        detail: 'orderCompletion.options.finService.fin.descr',
        price: 0,
        priceLines: [],
        reduction: 0,
        reducedPrice: 0,
        savedPrice: 0,
    },
};

const availableAdditionalServices = {
    PaperInvoice: {
        id: 'PaperInvoice',
        title: 'orderCompletion.options.additionalServices.PaperInvoice.title',
        detail: 'orderCompletion.options.additionalServices.PaperInvoice.descr',
        price: 0,
    },
    CopyCarDoc: {
        id: 'CopyCarDoc',
        title: 'orderCompletion.options.additionalServices.CopyCarDoc.title',
        detail: 'orderCompletion.options.additionalServices.CopyCarDoc.descr',
        price: 0,
    },
};

const options = {
    isEu: true,
    isUkOrderCompletion: false,
    isNonTransactionalCompletion: false,

    addresses: [], // {id: 1, title: 'aa', detail: 'aa', isDelivery: true}
    selectedAddress: null,

    transportModes: [], // {id: "LocalDelivery", title: "Delivery to your address", detail: "Estimated: 24-26 March"}
    selectedTransportMode: null,

    financialServices: [], // structure {id: "xx", title: 'Optimo', detail: 'blabla', price: 25}
    selectedFinancialService: null,

    additionalServices: [], // structure: {id: 'XXXX', title: "Car wash", detail: "Have your car washed before delivery", price: 0}
    selectedAdditionalServices: [], // multiple select

    buyerPromos: [], // structure: {id: 'XXXX', title: "VIP Discount", detail: "Valid until...", price: 0}
    selectedBuyerPromos: [], // multiple select

    paymentMethodsUk: [
        {
            id: 'NEXT-GEAR',
            title: 'orderCompletion.options.uk.paymentMethods.nextGear.title',
            linkText: 'orderCompletion.options.uk.paymentMethods.nextGear.linkText',
            linkUrl: 'orderCompletion.options.uk.paymentMethods.nextGear.linkUrl',
            logoUrl: '/v6/images/misc/logo-next-gear.png',
        },
        {
            id: 'SELF-PAY',
            title: 'orderCompletion.options.uk.paymentMethods.selfPay.title',
            linkText: null,
            linkUrl: null,
            logoUrl: null,
        },
    ],
    selectedPayementMethodUk: null,

    nonEuOptions: [
        {
            id: 'EX1',
            title: 'orderCompletion.options.nonEu.exDoc.title',
            detail: [
                'orderCompletion.options.nonEu.exDoc.detail.before',
                'orderCompletion.options.nonEu.exDoc.detail.tooltiped',
                'orderCompletion.options.nonEu.exDoc.detail.after',
                'orderCompletion.options.nonEu.exDoc.detail.tooltip',
            ],
            linkText: 'orderCompletion.options.nonEu.exDoc.linkText',
            linkUrl: 'orderCompletion.options.nonEu.exDoc.linkUrl',
            price: 110,
        },
        // {
        //     id: 2,
        //     title: "Order the EUR1 document",
        //     detail: "It is not an obligatory certificate.",
        //     linkText: "More information",
        //     linkUrl: "#",
        //     price: 50,
        // },
    ],
    selectedNonEuOptions: [],

    nonEuBorderCountry: null,
    nonEuBorderCity: null,

    showDestinationCountry: false,
    destinationCountry: null,
};

const initialState = {
    loading: false,
    isConfirmationBusy: false,
    currentStep: 0,
    countries: [],
    auctionToOrder: null,
    summary: {
        otherCosts: [],
        vehicleCost: 0,
        subTotal: 0,
        vatTotalAmount: 0,
        estimatedDeliveryPeriod: null,
        priceLines: [],
    },
    options,
    documents: {
        OrderFormUrl: '',
        ProformaInvoiceUrl: '',
        OptimoInvoiceUrl: '',
    },
    invoicingAddress: {
        canBeEdited: false,
    },
    nextSteps: {},
    paymentDetails: {
        showRedirectingModal: false,
        showWaitingModal: false,
        paymentStatus: PAYMENT_STATUS.NONE,
        paymentErrorMsg: null,
        // ... other payment details (bank name, iban, bic, ...) added by  SHOW_CONFIRMED_VIEW action
    },
};

const isEuCountry = (countryId, countries) =>
    countries
        .filter(a => a.isEu)
        .map(a => a.id)
        .indexOf(countryId) > -1;

// additional service selection are saved in local storage to re-apply them in future session.
// also: sometimes, copyCarDoc is mandatory, so it should be pre-selected by default.
const selectedAdditionalServicesStorageKey = 'ADESA:orderCompletion:selectedAdditionalServices';
const getSelectedAdditionalServices = (isCopyCarDocMandatory, toPreSelect = []) => {
    const result = JSON.parse(localStorage.getItem(selectedAdditionalServicesStorageKey) || '[]');
    result.push(...toPreSelect.filter(a => result.indexOf(a) === -1));
    if (isCopyCarDocMandatory && result.indexOf('CopyCarDoc') === -1) result.push('CopyCarDoc');
    return result;
};
const saveSelectedAdditionalServices = list => localStorage.setItem(selectedAdditionalServicesStorageKey, JSON.stringify(list));

/*
    cfr https://karauctionservices.visualstudio.com/International/_workitems/edit/105013
    Hide dropdown if all applies:
    * not export (so not invoicing outside Europe)
    * Transport mode: delivery to your address (not HUB or own pickup)
    * The delivery address (destination address) country differs from main address country 
*/
const showDestinationCountrySelector = options => {
    if (options.selectedAddress) {
        const mainAddress = options.addresses.filter(a => !a.isDelivery)[0];
        const selectedAddress = options.addresses.filter(a => a.id === options.selectedAddress)[0];
        //return selectedAddress.isDelivery && selectedAddress.countryId !== mainAddress.countryId;
        const hidden =
            !options.selectedTransportMode ||
            (options.isEu &&
                options.selectedTransportMode === 'LocalDelivery' &&
                selectedAddress.isDelivery &&
                selectedAddress.countryId !== mainAddress.countryId);
        return !hidden;
    } else {
        return false;
    }
};

const setDestinationCountryOptions = options => {
    const show = showDestinationCountrySelector(options);

    // cfr https://karauctionservices.visualstudio.com/International/_workitems/edit/105013
    if (!show) {
        // set destination country to selected address.
        const selectedAddress = options.addresses.filter(a => a.id === options.selectedAddress)[0];
        if (selectedAddress) options.destinationCountry = selectedAddress.countryId;
    }
    if (show && !options.showDestinationCountry) {
        // show again: clear country selection.
        options.destinationCountry = null;
    }
    options.showDestinationCountry = show;
};

const formattedAddress = address => [address.street1, `${address.zipCode} ${address.municipality}`, `[${address.country}]`].join('|');

export function orderCompletionReducer(state = initialState, action) {
    switch (action.type) {
        case INIT:
            return {
                ...state,
                isConfirmationBusy: false,
                options: {
                    ...state.options,
                    selectedAdditionalServices: getSelectedAdditionalServices(action.auction.isCopyCarDocMandatory),
                    isEu: action.isEu,
                    isUkOrderCompletion: action.isUkOrderCompletion,
                    isNonTransactionalCompletion: action.isNonTransactionalAuction,
                    showDestinationCountry: false,
                    destinationCountry: null,
                },
                currentStep: action.isNonTransactionalAuction ? 1 : 0,
                auctionToOrder: action.auction,
                invoicingAddress: {
                    ...state.invoicingAddress,
                    canBeEdited: action.invoicingAddressCanBeEdited,
                },
                summary: {
                    otherCosts: [],
                    vehiclePrice: action.auction.newBasePrice || action.auction.currentPrice,
                    countryCost: action.auction.countryCost,
                    auctionFee: action.auction.auctionFee,
                    sellerSpecificFee: action.auction.sellerSpecificFee,
                    dealerDiscount: action.auction.dealerDiscount,
                    countryCostDiscount: 0,
                    bpmFee: action.auction.bpmFee || 0,

                    vehicleCost: action.auction.vehicleCost, // included fees

                    subTotal: action.auction.vehicleCost,
                    vatTotalAmount: action.auction.vatAmount,
                    vatPercentage: action.auction.vatPercentage,
                    estimatedDeliveryPeriod: null, //'Est delivery aaaa - bbbb',
                    //   ...summaryFromSelectedOptions(state.options, state.summary),
                    auctionFeeDiscount: action.auction.auctionFeeDiscount,
                },
            };

        case SET_COUNTRIES:
            return {
                ...state,
                countries: action.countries.filter(a => a.id !== 'xz'), // remove "international waters"
            };
        case SET_SELLER:
            return {
                ...state,
                seller: action.seller,
            };

        case SELECT_TRANSPORT_MODE: {
            const financialServices = [availableFinancialServices.noService];

            const availableFinancialServiceToDeliveryOption = [
                {
                    availableFinancialService: availableFinancialServices.optimo,
                    deliveryOption: state.options.rawDeliveryOptions.find(a => a.Destination === action.selectedId && a.IsOptimo && !a.IsFin),
                },
                {
                    availableFinancialService: availableFinancialServices.fin,
                    deliveryOption: state.options.rawDeliveryOptions.find(a => a.Destination === action.selectedId && !a.IsOptimo && a.IsFin),
                },
                {
                    availableFinancialService: availableFinancialServices.optimoFin,
                    deliveryOption: state.options.rawDeliveryOptions.find(a => a.Destination === action.selectedId && a.IsOptimo && a.IsFin),
                },
            ];

            availableFinancialServiceToDeliveryOption
                .filter(f => f.deliveryOption)
                .forEach(financialServiceAndDeliveryOption => {
                    financialServices.push({
                        ...financialServiceAndDeliveryOption.availableFinancialService,
                        ...transportPriceDetails(
                            financialServiceAndDeliveryOption.deliveryOption.DisplayPrice,
                            financialServiceAndDeliveryOption.deliveryOption.PriceLines,
                            financialServiceAndDeliveryOption.deliveryOption.Reduction,
                        ),
                        deliveryDates: minMaxDateFormatted(
                            financialServiceAndDeliveryOption.deliveryOption.MinDate,
                            financialServiceAndDeliveryOption.deliveryOption.MaxDate,
                        ),
                        selectedFinPackageId: financialServiceAndDeliveryOption.deliveryOption.FinPackageId,
                        pricingTierId: financialServiceAndDeliveryOption.deliveryOption.PricingTierId,
                    });
                });
            const options = {
                ...state.options,
                selectedTransportMode: action.selectedId,
                financialServices,
                selectedFinancialService: financialServices.length === 1 || !state.options.isEu ? 'NONE' : null,
            };
            setDestinationCountryOptions(options);

            const selectedTransportMode = state.options.transportModes.filter(a => a.id === action.selectedId)[0];
            const summary = {
                ...state.summary,
                ...summaryFromSelectedOptions(options, state.summary, state.auctionToOrder),
                estimatedDeliveryPeriod: selectedTransportMode ? selectedTransportMode.detail : null,
            };
            return {
                ...state,
                options,
                summary,
            };
        }

        case SELECT_FINANCIAL_SERVICE: {
            const options = {
                ...state.options,
                selectedFinancialService: action.selectedId,
            };
            const selectedTransportMode = state.options.transportModes.filter(a => a.id === options.selectedTransportMode)[0];
            const selectedFinancialService = state.options.financialServices.filter(a => a.id === action.selectedId)[0];
            const selectedAdditionalServices = state.options.selectedAdditionalServices;

            if (action.selectedId === 'FIN' || action.selectedId === 'OPTIMO_FIN') {
                const copyIdx = selectedAdditionalServices.indexOf('CopyCarDoc');
                if (copyIdx > -1) {
                    selectedAdditionalServices.splice(copyIdx, 1);
                }
                saveSelectedAdditionalServices(selectedAdditionalServices);
            }
            const summary = {
                ...state.summary,
                ...summaryFromSelectedOptions(options, state.summary, state.auctionToOrder),
                estimatedDeliveryPeriod:
                    selectedFinancialService && selectedFinancialService.deliveryDates
                        ? selectedFinancialService.deliveryDates
                        : selectedTransportMode.detail,
            };
            return {
                ...state,
                options,
                summary,
            };
        }

        case SELECT_DELIVERY_ADDRESS: {
            const options = {
                ...state.options,
                selectedAddress: action.selectedId,
                //isEu: isEuCountry(state.options.addresses.filter(a => a.id === action.selectedId)[0].countryId, state.countries),
                transportModes: [],
                selectedTransportMode: null,
                selectedFinancialService: null,
                financialServices: [],
            };
            setDestinationCountryOptions(options);
            const selectedTransportMode = state.options.transportModes.filter(a => a.id === state.options.selectedTransportMode)[0];
            const summary = {
                ...state.summary,
                ...summaryFromSelectedOptions(options, state.summary, state.auctionToOrder),
                estimatedDeliveryPeriod: selectedTransportMode ? selectedTransportMode.detail : null,
            };
            return {
                ...state,
                options,
                summary,
            };
        }

        case REMOVE_DELIVERY_ADDRESS:
            return {
                ...state,
                options: {
                    ...state.options,
                    addresses: state.options.addresses.filter(a => a.id !== action.id),
                    selectedAddress: state.options.addresses[0].id,
                    //isEu: isEuCountry(state.options.addresses[0].countryId, state.countries),
                },
            };

        case SELECT_ADDITIONAL_SERVICES: {
            const selectedAdditionalServices =
                state.options.selectedAdditionalServices.indexOf(action.selectedId) > -1
                    ? state.options.selectedAdditionalServices.filter(a => a != action.selectedId)
                    : [...state.options.selectedAdditionalServices, action.selectedId];

            if (state.auctionToOrder.isCopyCarDocMandatory && selectedAdditionalServices.indexOf('CopyCarDoc') === -1) {
                selectedAdditionalServices.push('CopyCarDoc');
            }
            saveSelectedAdditionalServices(selectedAdditionalServices);

            const options = {
                ...state.options,
                selectedAdditionalServices,
            };
            const summary = {
                ...state.summary,
                ...summaryFromSelectedOptions(options, state.summary, state.auctionToOrder),
            };
            return {
                ...state,
                options,
                summary,
            };
        }

        case SELECT_BUYER_PROMO: {
            const selectedBuyerPromos =
                state.options.selectedBuyerPromos.indexOf(action.selectedId) > -1
                    ? state.options.selectedBuyerPromos.filter(a => a != action.selectedId)
                    : [...state.options.selectedBuyerPromos, action.selectedId];

            const options = {
                ...state.options,
                selectedBuyerPromos,
            };
            const summary = {
                ...state.summary,
                ...summaryFromSelectedOptions(options, state.summary, state.auctionToOrder),
            };
            return {
                ...state,
                options,
                summary,
            };
        }

        case SELECT_NON_EU_OPTION: {
            const selectedNonEuOptions =
                state.options.selectedNonEuOptions.indexOf(action.selectedId) > -1
                    ? state.options.selectedNonEuOptions.filter(a => a != action.selectedId)
                    : [...state.options.selectedNonEuOptions, action.selectedId];

            const options = {
                ...state.options,
                selectedNonEuOptions,
            };

            const summary = {
                ...state.summary,
                ...summaryFromSelectedOptions(options, state.summary, state.auctionToOrder),
            };

            return {
                ...state,
                summary,
                options,
            };
        }

        case SELECT_PAYMENT_METHOD_UK: {
            const options = {
                ...state.options,
                selectedPayementMethodUk: action.selectedId,
            };
            return {
                ...state,
                options,
            };
        }

        case FETCHED_ADDRESSES: {
            const address = action.invoicingAddress || action.addresses[0]; // if no invoicing we show main address.
            const invoicingAddress = address
                ? {
                      ...address,
                      title: i18n.t('orderCompletion.common.address.invoicingAddress'),
                      detail: formattedAddress(address),
                  }
                : {
                      detail: i18n.t('orderCompletion.common.address.noInvoicingAddress'),
                  };
            return {
                ...state,
                invoicingAddress: {
                    ...state.invoicingAddress,
                    ...invoicingAddress,
                },
                options: {
                    ...state.options,
                    addresses: action.addresses.map((a, i) => ({
                        ...a,

                        title:
                            a.addressTypeId === ADDRESS_TYPE.MAIN
                                ? i18n.t('orderCompletion.common.address.mainAddress')
                                : i18n.t('orderCompletion.common.address.deliveryAddress'),
                        detail: formattedAddress(a),
                        isDelivery: a.addressTypeId === ADDRESS_TYPE.DELIVERY,
                        countryId: a.country,
                    })),
                },
            };
        }

        case SAVED_INVOICING_ADDRESS: {
            return {
                ...state,
                invoicingAddress: {
                    ...action.address,
                    title: i18n.t('orderCompletion.common.address.invoicingAddress'),
                    detail: formattedAddress(action.address),
                    canBeEdited: false,
                },
            };
        }

        case FETCHED_DELIVERY_OPTIONS: {
            const options = {
                ...state.options,
                //isEu: action.data.isEu,
                selectedNonEuOptions: state.options.isEu || !state.auctionToOrder.isEx1Allowed ? [] : ['EX1'], // EX1 selected by default for non EU...
                transportModes: deliveryOptionsToState(action.data),
                rawDeliveryOptions: action.data,
            };
            const summary = {
                ...state.summary,
                ...summaryFromSelectedOptions(options, state.summary, state.auctionToOrder),
            };
            return {
                ...state,
                options,
                summary,
            };
        }

        case SET_SELECTED_OPTIONS: {
            const options = {
                ...state.options,
                ...action.options,
                // isEu: action.options.isEu,
                // selectedAddress: action.options.selectedAddress,
                // selectedTransportMode: action.options.selectedTransportMode,
                // selectedFinancialService: action.options.selectedFinancialService,
                // selectedAdditionalServices: action.options.selectedAdditionalServices,
                // selectedBuyerPromos: action.options.selectedBuyerPromos,
                // selectedNonEuOptions: action.options.selectedNonEuOptions,
                // nonEuBorderCountry: action.options.nonEuBorderCountry,
                // nonEuBorderCity: action.options.nonEuBorderCity,
            };
            const summary = {
                ...state.summary,
                ...summaryFromSelectedOptions(options, state.summary, state.auctionToOrder),
            };
            return {
                ...state,
                options,
                summary,
            };
        }

        case FETCHED_BUYER_PROMOS: {
            const options = {
                ...state.options,
                buyerPromos: action.data.map(a => ({
                    ...a,
                    id: a.PromotionId,
                    validityEnd: parseDate(a.ValidUntil),
                    validityWarning: differenceInDays(a.ValidUntil, getCurrentDate()) <= 14,
                    //detail: .format('DD/MM/YYYY'),
                    price: a.Amount,
                })),
                selectedBuyerPromos: [],
            };
            return {
                ...state,
                options,
            };
        }

        case FETCHED_ADDITIONAL_SERVICES: {
            // action.data =  [{CanBeChanged: true, IsSelected: false, Price: 25, ServiceName: "CopyCarDoc"}, ...]
            const selectedAdditionalServices = getSelectedAdditionalServices(
                state.auctionToOrder.isCopyCarDocMandatory /*, action.data.filter(a => a.IsSelected).map(a => a.ServiceName)*/,
            );
            return {
                ...state,
                options: {
                    ...state.options,
                    additionalServices: action.data.map(a => ({
                        ...availableAdditionalServices[a.ServiceName],
                        price: a.Price,
                    })),
                    selectedAdditionalServices,
                },
            };
        }

        case FETCHED_DOCUMENTS:
            return {
                ...state,
                documents: action.data,
                //documents: {"OrderFormUrl":"test1","ProformaInvoiceUrl":"test2","OptimoInvoiceUrl":"test3"},
            };

        case CHANGE_NON_EU_FIELDS:
            return {
                ...state,
                options: {
                    ...state.options,
                    nonEuBorderCountry: action.country,
                    nonEuBorderCity: action.city,
                },
            };

        case SHOW_OVERVIEW:
            return { ...state, currentStep: 1 };

        case SHOW_CONFIRMED_VIEW: {
            const selectedTransportMode = state.options.transportModes.filter(a => a.id === state.options.selectedTransportMode)[0];
            const summary = {
                ...state.summary,
                ...summaryFromSelectedOptions(state.options, state.summary, state.auctionToOrder),
                estimatedDeliveryPeriod: selectedTransportMode ? selectedTransportMode.detail : null,
            };

            return {
                ...state,
                summary,
                currentStep: 2,
                isConfirmationBusy: false,
                nextSteps: {
                    ...state.nexSteps,
                    ...nextStepsToState(action.nextStepsDetails),
                },
                paymentDetails: {
                    ...state.paymentDetails,
                    showWaitingModal: false,
                    ...paymentDetailsToState(action.paymentDetails, state.auctionToOrder.auctionId, state.summary),
                },
            };
        }

        case GO_BACK:
            return {
                ...state,
                currentStep: state.currentStep > 0 ? state.currentStep - 1 : 0,
            };

        case CONFIRMATION_BUSY:
            return { ...state, isConfirmationBusy: action.busy };

        case SET_PAYMENT_STATUS: {
            const paymentStatus = action.status;
            const paymentErrorMsg = action.message;
            const showWaitingModal = !!action.showWaitingModal;
            return {
                ...state,
                paymentDetails: {
                    ...state.paymentDetails,
                    paymentStatus,
                    paymentErrorMsg,
                    showWaitingModal,
                },
                currentStep: 1,
            };
        }

        case REDIRECTING_TO_OGONE:
            return {
                ...state,
                paymentDetails: {
                    ...state.paymentDetails,
                    showRedirectingModal: true,
                },
            };

        case SELECT_DESTINATION_COUNTRY:
            return {
                ...state,
                options: {
                    ...state.options,
                    destinationCountry: action.destinationCountry,
                },
            };

        case SET_NOT_ALLOWED_DESTINATION_COUNTRY:
            return {
                ...state,
                options: {
                    ...state.options,
                    notAllowedDestinationCountry: action.country,
                },
            };
        case SET_NOT_ALLOWED_DELIVERY_ADDRESS_COUNTRY:
            return {
                ...state,
                options: {
                    ...state.options,
                    notAllowedDeliveryAddressCountry: action.country,
                },
            };

        default:
            return { ...state };
    }
}
