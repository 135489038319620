import React from 'react';
import './Sticker.less';
import { classnames } from '../../utils';

export const Sticker = ({ children, className = '', position = 'absolute', size = 'md', variant = 'primary' }) => {
    return (
        <div
            className={classnames({
                [className]: Boolean(className),
                'rc-sticker': true,
                'rc-sticker--relative': position === 'relative',
                'rc-sticker--small': size === 'sm',
                'rc-sticker--large': size === 'lg',
                'rc-sticker--variantPrimary': variant === 'primary',
                'rc-sticker--variantSuccess': variant === 'success',
            })}>
            {children}
        </div>
    );
};
