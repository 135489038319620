import { useEffect, useRef } from 'react';

import { useDesktop } from '@ambiorix/adesa-web-kit';

const openSfChatbot = () => {
    const chatBotButton = document.getElementById('embeddedMessagingConversationButton');
    if (!!chatBotButton) {
        chatBotButton.click();
        return true;
    }
    return false;
};

export const useShowSalesforceChat = () => {
    const isOpen = useRef(false);
    const showChat = () => {
        if (document.getElementById('embedded-messaging')) {
            isOpen.current = openSfChatbot();
        } else {
            // widget not yet mounted => wait for it...
            const observer = new MutationObserver(() => {
                if (!document.getElementById('embedded-messaging')) return;
                if (!isOpen.current) {
                    isOpen.current = openSfChatbot();
                    observer.disconnect();
                }
            });
            observer.observe(document.body, { childList: true });
        }
    };
    return {
        showChat,
    };
};
