import { Spinner } from '@ambiorix/adesa-web-kit';
import React, { useState, useEffect, useRef } from 'react';

const ResizedIFrame = ({ url }) => {
    const iframe = useRef(null);
    const [loading, setLoading] = useState(true);
    const [height, setHeight] = useState('0');
    const onLoaded = evt => {
        setLoading(false);
        // resize iframe height based on current content.
        setHeight(iframe.current.contentWindow.document.body.scrollHeight + 20 + 'px');
    };
    useEffect(() => {
        setLoading(true);
        setHeight('0');
    }, [url]);
    useEffect(() => {
        // monitor content of iframe to resize height (better solution ?? tried ResizeObserver without success.)
        const id = setInterval(() => {
            setHeight(iframe.current.contentWindow.document.body.scrollHeight + 20 + 'px');
        }, 1000);
        //new ResizeObserver(() => console.log("--- RESIZE")).observe(iframe.current.contentWindow.document.body);
        return () => clearInterval(id);
    }, []);
    return (
        <>
            <iframe ref={iframe} frameBorder="0" src={url} width="100%" height={loading ? 0 : height} scrolling="no" onLoad={onLoaded}></iframe>
            {loading ? <Spinner /> : null}
        </>
    );
};

export default ResizedIFrame;
