import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './index.less';
import { Cookies } from '../../../utils';
import { AdesaButton } from '../../Common/ui-components';

const COOKIE_CONSENT_COOKIE_NAME = 'cookieBar';
const STORAGE_KEY = 'cookie-consent';

export default function ({ t }) {
    //const [ display, setDisplay ] = useState(!localStorage.getItem(STORAGE_KEY));
    const [display, setDisplay] = useState(!Cookies.get(COOKIE_CONSENT_COOKIE_NAME));

    const onAgree = () => {
        setDisplay(false);
        //localStorage.setItem(STORAGE_KEY, "true");
        Cookies.set(COOKIE_CONSENT_COOKIE_NAME, 'viewed', 30);
    };

    const onClose = () => {
        setDisplay(false);
    };

    return (
        display && (
            <div className="rc-CookieConsent">
                <Container>
                    <Row>
                        <Col className="container">
                            <i onClick={onClose} className={'cotw-icon-times close-icon'}></i>

                            <div className="flex-it">
                                <span>{t('common.cookie.disclaimer')}</span>
                                <AdesaButton id="actionAgreeBtn1" size="secondary" color="positive" onClick={onAgree}>
                                    {t('common.action.agree')}
                                </AdesaButton>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    );
}
