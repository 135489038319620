import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ResizedIFrame from '../../components/ui/ResizedIFrame';

import './MyAccountPurchases.less';

export default function MyAccountPurchases({ match, t, currentLanguage }) {
    return (
        <Container fluid={false}>
            <Row>
                <Col className="rc-myaccount-purchases">
                    <ResizedIFrame url={`/${currentLanguage}/myaccount/purchases?frameless`} />
                </Col>
            </Row>
        </Container>
    );
}
