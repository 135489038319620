import React, { ChangeEventHandler, ComponentProps } from 'react';
import { Override, Toggle } from '@ambiorix/adesa-web-kit';
import { cx } from 'class-variance-authority';
import styles from './Toggle.module.scss';

export type AdesaSwitchProps = Override<
    ComponentProps<typeof Toggle>,
    {
        checked?: boolean | string;
        disabled?: boolean | string;
        onChange?: (event: CustomEvent<{ checked: boolean }>) => void;
    }
>;

// map ui-components switch props to web kit toggle
export const AdesaSwitch = ({ className = '', checked, disabled, onChange, variant = 'basic', ...props }: AdesaSwitchProps) => {
    const mappedDisabled = disabled !== 'false' && Boolean(disabled);

    const mappedOnChange: ChangeEventHandler<HTMLInputElement> = event => {
        const customEvent = new CustomEvent('changed', {
            bubbles: true,
            composed: true,
            detail: { checked: event.target.checked },
        });
        onChange?.(customEvent);
    };

    const mappedChecked = checked === 'false' ? false : Boolean(checked);

    return (
        <Toggle
            className={cx(styles.toggle, className)}
            checked={mappedChecked}
            disabled={mappedDisabled}
            onChange={mappedOnChange}
            variant={variant}
            {...props}
        />
    );
};
