exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3_LakXH1E_1-yX7NLwP4H7{position:fixed;top:0;left:0;display:flex;align-items:center;justify-content:center;height:100vh;width:100vw;background-color:var(--neutral-50);z-index:1000}", ""]);

// exports
exports.locals = {
	"fullScreenSpinner": "_3_LakXH1E_1-yX7NLwP4H7"
};