import React, { useEffect } from 'react';
import CarProfile from '../components/CarDetail/CarProfile';
import CarEquipment from '../components/CarDetail/CarEquipment';

import './CarReport.less';
import DamageReport from './DamageReport';
import settings from '../appsettings';

const Notes = ({ t }) => (
    <section className="rc-notes title">
        <h3>{t('reports.carReport.yourNotes')}</h3>
        <div className="note-line"></div>
        <div className="note-line"></div>
        <div className="note-line"></div>
        <div className="note-line"></div>
    </section>
);
const Title = ({ t, carDetails }) => <h1 className="page-header">{carDetails.name}</h1>;
const CarReport = ({
    t,
    carDetails,
    auctionInfo,
    picturesList,
    sellerScores,
    importantMessages,
    carDamages,
    currentLanguage,
    allOptions,
    selectedOptionList,
    accessoryList,
    optionComment,
    currentUser,
}) => {
    const conditions = [];
    if (carDetails.isDamagedCar) conditions.push(t('vehicleDetails.vehicleCondition.damaged').toUpperCase());
    if (carDetails.isDrivable === 'no') conditions.push(t('vehicleDetails.vehicleCondition.nonDrivable').toUpperCase());

    useEffect(() => {
        document.body.style.backgroundColor = 'white';
    }, []);
    const isReportRequested = true;

    return (
        <>
            <div className="rc-car-report">
                <img className="openlane-logo" src="/v6/images/openlane-logo.svg" title="Openlane" alt="Openlane" />

                <header>
                    {(picturesList && picturesList[0] && <img className="primary-picture" src={picturesList[0].Url}></img>) || (
                        <img src="/v6/images/car-placeholder.png" className="primary-picture" alt="" />
                    )}

                    <div className="header-info">
                        <h1>{carDetails.name}</h1>

                        <p>
                            {t('reports.damage.vehicleDetails.licencePlate')}: {carDetails.licensePlace}
                        </p>
                        <p>
                            {t('vehicleDetails.profile.chassisNumber')}: {carDetails.chassis ? carDetails.chassis : '-'}
                        </p>
                        <p>{conditions.join(' - ')}</p>
                    </div>
                </header>

                <div className="report-pictures">
                    <ul>
                        {[1, 2, 3].map(
                            n =>
                                (picturesList && picturesList[n] && (
                                    <li>
                                        <img className="secondary-picture" src={picturesList[n].Url}></img>
                                    </li>
                                )) || (
                                    <li>
                                        <img src="/v6/images/car-placeholder.png" className="primary-picture" alt="" />
                                    </li>
                                ),
                        )}
                    </ul>
                </div>
                <section>
                    <CarProfile
                        t={t}
                        carDetails={carDetails}
                        sellerScores={sellerScores}
                        importantGeneralMessages={importantMessages.forCarGeneral}
                        importantFiscalMessages={importantMessages.forFiscalRegime}
                        importantOriginMessages={importantMessages.forCarOrigin}
                        importantDocMessages={importantMessages.forCarDocsAndHistory}
                        isReportRequested={isReportRequested}
                        currentUser={currentUser}></CarProfile>
                </section>
                <section>
                    <Title t={t} carDetails={carDetails}></Title>
                    <h1 className="page-header">{t('vehicleDetails.equipment.allEquipment')}</h1>
                    <CarEquipment
                        allOptions={allOptions}
                        selectedOptions={selectedOptionList}
                        accessories={accessoryList}
                        optionComment={optionComment}
                        t={t}
                    />
                    <Notes t={t}></Notes>
                </section>
                {picturesList && picturesList[0] && (
                    <section>
                        <Title t={t} carDetails={carDetails}></Title>
                        <div className="title">
                            <h3>{t('reports.carReport.gallery')}</h3>
                        </div>

                        <div className="all-pictures">
                            {picturesList.map((p, i) => (
                                <img src={p.Url} key={i}></img>
                            ))}
                        </div>
                    </section>
                )}
                <footer>{`${settings.carsiteBaseUrl}/${currentLanguage}/car/info?auctionId=${auctionInfo.auctionId}`}</footer>
            </div>
            <DamageReport carDamages={carDamages} carDetails={carDetails} t={t} auctionInfo={auctionInfo} picturesList={picturesList}></DamageReport>
        </>
    );
};

export default CarReport;
