exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._195rxBXX0b1vNS62-lmh1b{display:flex;flex-direction:column;align-items:center;gap:var(--spacing-3xs);padding:var(--spacing-sm) 0}._195rxBXX0b1vNS62-lmh1b>*{width:100%}@media(min-width: 768px){._195rxBXX0b1vNS62-lmh1b>*{max-width:720px}}@media(min-width: 992px){._195rxBXX0b1vNS62-lmh1b>*{max-width:960px}}@media(min-width: 1200px){._195rxBXX0b1vNS62-lmh1b>*{max-width:1140px}}._7AjvzbNHmxc8mVHAUEXiR{align-items:center}._7AjvzbNHmxc8mVHAUEXiR>*{width:100%}@media(min-width: 768px){._7AjvzbNHmxc8mVHAUEXiR>*{max-width:720px}}@media(min-width: 992px){._7AjvzbNHmxc8mVHAUEXiR>*{max-width:960px}}@media(min-width: 1200px){._7AjvzbNHmxc8mVHAUEXiR>*{max-width:1140px}}.PT0cjRwloeOPUXj-tIHDF{padding:0;margin:0}._3VI_ToXaHrpfvCjZhPxCsK{padding:0 var(--spacing-sm)}._2GhB5jD2xosH10ju2qLWv-{position:sticky;bottom:0;list-style:none;display:flex;margin-top:auto;padding:var(--spacing-sm);gap:var(--spacing-sm);background-color:var(--shades-0)}._2GhB5jD2xosH10ju2qLWv->*{flex:1}._2NeJ_1mNFijxd-h7ouiMlt{visibility:hidden !important;height:0px}", ""]);

// exports
exports.locals = {
	"bannerContainer": "_195rxBXX0b1vNS62-lmh1b",
	"navbar": "_7AjvzbNHmxc8mVHAUEXiR",
	"navbarLogo": "PT0cjRwloeOPUXj-tIHDF",
	"greeting": "_3VI_ToXaHrpfvCjZhPxCsK",
	"connectButtons": "_2GhB5jD2xosH10ju2qLWv-",
	"loginModalstyle": "_2NeJ_1mNFijxd-h7ouiMlt"
};