import React, { ComponentProps, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { cx } from 'class-variance-authority';
import { Override, FormText as WebKitFormText } from '@ambiorix/adesa-web-kit';

import styles from '../Input.module.scss';

type FormTextProps = Override<
    ComponentProps<typeof WebKitFormText>,
    {
        label?: string;
        warning?: string;
        showPasswordHandler?: () => void;
        showPassword?: boolean;
        showSuccess?: boolean;
    }
>;

const FormText = ({ className = '', label = '', name, warning, showPasswordHandler, showPassword, showSuccess, ...props }: FormTextProps) => {
    const suffix = useMemo(() => {
        if (['password', 'confirmPassword'].includes(name)) {
            return <FontAwesomeIcon className={cx(showPassword && styles.iconActive)} icon={showPassword ? faEye : faEyeSlash} fixedWidth />;
        }
        if (showSuccess) {
            return <FontAwesomeIcon className={styles.iconCheck} icon={faCheck} />;
        }
        return null;
    }, [name, showPassword, showSuccess]);

    return (
        <WebKitFormText
            name={name}
            className={cx(styles.input, styles.margin, className)}
            label={label}
            hasWarning={Boolean(warning)}
            warningText={`${warning}`}
            suffix={suffix}
            onSuffixClick={showPasswordHandler}
            {...props}
        />
    );
};

export default FormText;
