/*eslint max-len: ["off"]*/
import React from 'react';
import './BodyParts.less';

function classNameByLevel(idx, partsToHighlight) {
    let result = 'cls-2';
    //return partsToHighlight.indexOf(idx) !== -1 ? "highlighted" : "cls-2";
    if (partsToHighlight[idx]) {
        result = 'highlighted';
        if (partsToHighlight[idx].indexOf(6) !== -1) result = 'highlighted-small';
        if (partsToHighlight[idx].indexOf(7) !== -1) result = 'highlighted-medium';
        if (partsToHighlight[idx].indexOf(8) !== -1) result = 'highlighted-big';
    }
    return result;
}

// tool to edit SVG path: https://yqnn.github.io/svg-path-editor/

export default ({
    partsToHighlight, // list of part numbers:    { <part-nr1>: [<lvl1>, <lvl2>, ...], <part-nr2>: [<lvl3>, <lvl4>, ...], ....  }
    renumbering, // renumbers the parts only on damaged parts, starting from 1. [null, 3, null, null, 1, null, 2, null]
}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 229.33 403.27" className="rc-BodyParts">
            <defs />
            <title>Car damages</title>
            <g id="chassis">
                <path
                    id="chassis-main"
                    className="cls-1"
                    d="M225.92,210a438.68,438.68,0,0,0-16-98.35c-2.55-9.57,3.19-29.22,4.33-39.65s-4.33-29-28.33-35.67c-21.73-6-49.12-6-49.34-6s-27.6-.05-49.33,6C63.25,43,57.78,61.57,58.92,72s6.88,30.08,4.33,39.65a438.05,438.05,0,0,0-16,98.35c0,96.33,19.48,126.7,16.83,141.17-2.5,13.66-5,16.58-4.83,22.5,0,16.91,25.24,20.63,37,22.33,9.63,1.39,31.42,1.84,39,2v0l1.33,0,1.34,0v0c7.58-.12,29.36-.57,39-2,11.76-1.7,37-5.42,37-22.33.16-5.92-2.34-8.84-4.84-22.5C206.44,336.7,225.92,306.33,225.92,210Z"
                    transform="translate(-21.83 -15.17)"
                />
            </g>
            <g id="doors">
                <g id="doors-l">
                    <path
                        id="door-fl"
                        className="cls-1"
                        d="M75.8,208.46c-.32-15.05,2.52-39.06,4.27-52.19l-1.81-.72c-8-3.11-18.58-6.31-23.57-7.77a452.54,452.54,0,0,0-7.43,62.05H75.83Z"
                        transform="translate(-21.83 -15.17)"
                    />
                    <path
                        id="door-rl"
                        className="cls-1"
                        d="M47.26,209.83c0,.06,0,.11,0,.17,0,34.92,2.56,61.16,5.7,81.13,6.11-4.42,20.69-15.11,26.92-21l1.67-1.57S76.69,245,75.83,209.83Z"
                        transform="translate(-21.83 -15.17)"
                    />
                    <path
                        id="window-fl"
                        className="cls-1"
                        d="M75.8,208.46l0,1.37H95.35c-.09-20,1.38-45.51,1.38-45.51a130.07,130.07,0,0,0-16.66-8C78.32,169.4,75.48,193.41,75.8,208.46Z"
                        transform="translate(-21.83 -15.17)"
                    />
                    <path
                        id="window-rl"
                        className="cls-1"
                        d="M95.35,210.46v-.63H75.83c.86,35.12,5.71,58.76,5.71,58.76,7.92-7.53,18.59-18.15,18.59-18.15A199.12,199.12,0,0,1,95.35,210.46Z"
                        transform="translate(-21.83 -15.17)"
                    />
                </g>
                <g id="doors-r">
                    <path
                        id="door-fr"
                        className="cls-1"
                        d="M197.58,208.46c.32-15.05-2.52-39.06-4.27-52.19l1.81-.72c8-3.11,18.58-6.31,23.57-7.77a452.54,452.54,0,0,1,7.43,62.05H197.55Z"
                        transform="translate(-21.83 -15.17)"
                    />
                    <path
                        id="door-rr"
                        className="cls-1"
                        d="M226.12,209.83a1,1,0,0,1,0,.17c0,34.92-2.56,61.16-5.7,81.13-6.11-4.42-20.69-15.11-26.92-21l-1.67-1.57s4.85-23.64,5.71-58.76Z"
                        transform="translate(-21.83 -15.17)"
                    />
                    <path
                        id="window-fr"
                        className="cls-1"
                        d="M197.58,208.46l0,1.37H178c.09-20-1.37-45.51-1.37-45.51a129.46,129.46,0,0,1,16.65-8C195.06,169.4,197.9,193.41,197.58,208.46Z"
                        transform="translate(-21.83 -15.17)"
                    />
                    <path
                        id="window-rr"
                        className="cls-1"
                        d="M178,210.46v-.63h19.52c-.86,35.12-5.71,58.76-5.71,58.76-7.91-7.53-18.59-18.15-18.59-18.15A199.12,199.12,0,0,0,178,210.46Z"
                        transform="translate(-21.83 -15.17)"
                    />
                </g>
            </g>
            <g id="windshield">
                <path
                    id="windshield-r"
                    className="cls-1"
                    d="M136.5,296.83a83.17,83.17,0,0,0,38.84-10.07l.09.24c-5.77-16-9.66-27.8-9.66-27.8C157.12,262,141.13,262,137,262h-1c-4.13,0-20.12-.06-28.77-2.81,0,0-3.89,11.78-9.66,27.8l.11-.3A82.14,82.14,0,0,0,136.5,296.83Z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="windshield-f"
                    className="cls-1"
                    d="M190,127c-13.79-14.19-46.67-15.25-52.72-15.33h-1c-6,.08-38.93,1.14-52.72,15.33-9.71,10-3.74,18-.16,21.33,5.43,5,14.65,5.67,14.65,5.67s11.54-6.24,22.23-8a103.09,103.09,0,0,1,16-1.34h1a103.09,103.09,0,0,1,16,1.34c10.69,1.76,22.23,8,22.23,8s9.22-.67,14.65-5.67C193.74,145,199.71,137,190,127Z"
                    transform="translate(-21.83 -15.17)"
                />
            </g>
            <g id="trunk">
                <path
                    id="trunk-r"
                    className="cls-1"
                    d="M175.43,287l-.09-.24a83.17,83.17,0,0,1-38.84,10.07A82.14,82.14,0,0,1,97.68,286.7l-.11.3c-10.19,28.33-9.07,64.33-7.68,73.67,7.48,5.8,40,6.29,46.11,6.33h1c6.13,0,38.63-.53,46.11-6.33C184.5,351.33,185.62,315.33,175.43,287Z"
                    transform="translate(-21.83 -15.17)"
                />
            </g>
            <g id="bonnet">
                <path
                    id="bonnet-f"
                    className="cls-1"
                    d="M180.41,52.67c-12.2-6.59-38.22-7.26-43.41-7.33h-.93c-5.19.07-31.21.74-43.4,7.33-18.91,10.2-9,60.33-9,60.33s15.44-5.47,27.58-7.67a152.89,152.89,0,0,1,24.82-2H137a152.89,152.89,0,0,1,24.82,2c12.15,2.2,27.59,7.67,27.59,7.67S199.31,62.87,180.41,52.67Z"
                    transform="translate(-21.83 -15.17)"
                />
            </g>
            <g id="light">
                <path
                    id="light-rl"
                    className="cls-1"
                    d="M96.25,396c2.67.39,6.28.7,10.27.95l.07,0s.32-12-3-15.7S86.32,375.83,80,377.79c-3.43,1.07-6.23,7-8.07,12.15C80,393.63,90,395.1,96.25,396Z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="light-rr"
                    className="cls-1"
                    d="M166.65,397c4-.25,7.59-.56,10.27-.95,6.23-.9,16.24-2.37,24.28-6.06-1.83-5.16-4.63-11.08-8.06-12.15-6.3-2-20.21-.29-23.55,3.42s-3,15.7-3,15.7Z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="light-fl"
                    className="cls-1"
                    d="M60,63c.56,0,1.11,0,1.67,0,14.19,0,25.7-9.78,25.7-21.83a18.6,18.6,0,0,0-.57-4.55C69.47,41.76,62.11,53.2,60,63Z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="light-fr"
                    className="cls-1"
                    d="M214.15,63c-2.15-9.75-9.51-21.19-26.8-26.33a18.6,18.6,0,0,0-.57,4.55c0,12,11.51,21.83,25.7,21.83C213,63,213.59,63,214.15,63Z"
                    transform="translate(-21.83 -15.17)"
                />
            </g>
            <g id="bumper">
                <path
                    id="bumper-r"
                    className="cls-1"
                    d="M213.9,373.67c0,16.91-25.23,20.63-37,22.33-9.63,1.39-31.42,1.84-39,2v0l-1.33,0-1.33,0v0c-7.58-.12-29.37-.57-39-2-11.77-1.7-37-5.42-37-22.33A18.18,18.18,0,0,1,59.5,370c-2.21,3.38-8.83,14.47-7.15,23.68,1.51,8.34,13.32,23.52,83.73,23.66h1c70.41-.14,82.22-15.32,83.73-23.66,1.68-9.21-4.94-20.3-7.15-23.68A18.18,18.18,0,0,1,213.9,373.67Z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="bumper-f"
                    className="cls-1"
                    d="M58.77,72.4c0-.13,0-.27-.05-.4-1.14-10.43,4.33-29,28.33-35.67,21.73-6,49.12-6,49.33-6s27.61-.05,49.34,6c24,6.67,29.47,25.24,28.33,35.67,0,.13,0,.27-.05.4,5.33-2.7,14.38-10.51,4.07-30.32-12.88-24.74-73.64-26.31-81.29-26.41H136c-7.65.1-68.41,1.67-81.29,26.41C44.39,61.89,53.44,69.7,58.77,72.4Z"
                    transform="translate(-21.83 -15.17)"
                />
            </g>
            <g id="skirts">
                <path
                    className="cls-1"
                    d="M43,318.46c7.56,0,14.18,5.83,17.78,14.54h1C57,313.13,47.32,278,47.32,210a442.72,442.72,0,0,1,12.24-84.12c-3.77,7.23-9.78,11.91-16.56,11.91a14.1,14.1,0,0,1-2.44-.21c-3.15,12.14-7.33,35.09-8.34,73.42-1.16,44.3,5.17,86.31,9.09,107.56A15.65,15.65,0,0,1,43,318.46Z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    className="cls-1"
                    d="M212.22,333c3.6-8.71,10.22-14.54,17.78-14.54a14.57,14.57,0,0,1,2,.14c3.92-21.25,10.26-63.28,9.09-107.6-1-38.39-5.2-61.34-8.35-73.47a14.52,14.52,0,0,1-2.73.26c-6.45,0-12.22-4.25-16-10.9A443.2,443.2,0,0,1,226,210c0,68-9.71,103.13-14.49,123Z"
                    transform="translate(-21.83 -15.17)"
                />
            </g>
            <g id="wheel">
                <ellipse id="wheel-fl" className="cls-1" cx="21.17" cy="92.96" rx="20.67" ry="29.67" />
                <ellipse id="wheel-fr" className="cls-1" cx="208.17" cy="92.96" rx="20.67" ry="29.67" />
                <ellipse id="wheel-rl" className="cls-1" cx="21.17" cy="332.96" rx="20.67" ry="29.67" />
                <ellipse id="wheel-rr" className="cls-1" cx="208.17" cy="332.96" rx="20.67" ry="29.67" />
            </g>
            <g id="mirrors">
                <path id="mirror-left" className="cls-1" d="M42 104C46.6836 101.106 55 101.5 60 103V116H37C37 116 37.3164 106.894 42 104Z" />
                <path
                    id="mirror-right"
                    className="cls-1"
                    d="M 190 104 C 185.316 101.106 177 101.5 172 103 V 116 H 195 C 195 116 194.684 106.894 190 104 Z"
                />
            </g>

            <g id="roof-arcade">
                <rect id="roof-arcade-left" className="cls-1" x="80" y="149.5" width="9" height="85" rx="1.5" />
                <rect id="roof-arcade-right" className="cls-1" x="141" y="149.5" width="9" height="85" rx="1.5" />
            </g>

            <g id="damage-parts-colored">
                <path
                    id="part24"
                    className={classNameByLevel(24, partsToHighlight)}
                    d="M214.15,63c-2.15-9.75-9.51-21.19-26.8-26.33a18.6,18.6,0,0,0-.57,4.55c0,12,11.51,21.83,25.7,21.83C213,63,213.59,63,214.15,63Z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="part23"
                    className={classNameByLevel(23, partsToHighlight)}
                    d="M213.78,63.46c-.43,0-.87,0-1.3,0a27.93,27.93,0,0,1-20.82-8.8L187,59.4c6.39,11.24,5.42,31,4,43.13l18.72,8.07c-1.91-9.89,3.47-28.54,4.57-38.6A28.31,28.31,0,0,0,213.78,63.46Z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="part22"
                    className={classNameByLevel(22, partsToHighlight)}
                    d="M195.4,138c.11-3.91-1.71-7.83-5.42-11.64a17.5,17.5,0,0,0-2.7-2.43l2.28-11.23s.7-3.57,1.38-9l19.2,8.28c1.46,5.41,5,17.7,8.39,35.51-5,1.47-15.53,4.67-23.56,7.78l-1.82.72A133.09,133.09,0,0,0,177,163.73l-1.28-9.6c2.07-.22,9.62-1.32,14.43-5.74C192,146.63,195.26,142.94,195.4,138Z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="part21"
                    className={classNameByLevel(21, partsToHighlight)}
                    d="M218.69,147.78c-5,1.46-15.53,4.66-23.57,7.77l-1.81.72a129.46,129.46,0,0,0-16.65,8s1.46,25.5,1.37,45.51h48.09A452.54,452.54,0,0,0,218.69,147.78Z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="part20"
                    className={classNameByLevel(20, partsToHighlight)}
                    d="M212.22,333c3.6-8.71,10.22-14.54,17.78-14.54a14.57,14.57,0,0,1,2,.14c3.92-21.25,10.26-63.28,9.09-107.6-1-38.39-5.2-61.34-8.35-73.47a14.52,14.52,0,0,1-2.73.26c-6.45,0-12.22-4.25-16-10.9A443.2,443.2,0,0,1,226,210c0,68-9.71,103.13-14.49,123Z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="part19"
                    className={classNameByLevel(19, partsToHighlight)}
                    d="M226.12,209.83H178v.63a199.12,199.12,0,0,1-4.78,40s10.68,10.62,18.59,18.15l1.67,1.57c6.23,5.86,20.81,16.55,26.92,21,3.14-20,5.7-46.21,5.7-81.13A1,1,0,0,0,226.12,209.83Z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="part18"
                    className={classNameByLevel(18, partsToHighlight)}
                    d="M209,350.5c-1.3-9.3,5.8-24.9,11.2-59l0,0c-6-4.4-20.7-15.1-27-21c-0.3-0.3-0.7-0.6-1-1l0,0 l-0.7-0.6l-0.2-0.2l0,0c-7.4-7.1-17.3-16.8-18.3-17.9l-6.7,8.4c0.6,1.8,4.3,13,9.6,27.5c7.8,21.8,9,48.3,8.5,63.7h-0.1 c-0.1,1.8-0.2,5.5-0.3,6.9v19.1c3.6-0.2,7,0.1,9.3,0.8c2.9,0.9,5.8,5.1,8.4,12.4c6.9-3.3,12.3-8.3,12.3-16.1 c0.2-5.7-2.2-10.6-4.6-23.1H209z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="part17"
                    className={classNameByLevel(17, partsToHighlight)}
                    d="M166.65,397c4-.25,7.59-.56,10.27-.95,6.23-.9,16.24-2.37,24.28-6.06-1.83-5.16-4.63-11.08-8.06-12.15-6.3-2-20.21-.29-23.55,3.42s-3,15.7-3,15.7Z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="part16"
                    className={classNameByLevel(16, partsToHighlight)}
                    d="M98.11,154l-1.36,10.33s-1.38,26.92-1.38,45.56,4.51,39.34,4.51,39.34l8.3,10.26s-.9,2.51,28.62,2.51,29-2.81,29-2.81l7.48-9.7S178,225.76,178,209.9s-1.37-45.56-1.37-45.56l-1.21-10.88s-12.62-8.79-39.11-8.79S98.11,154,98.11,154Z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="part15"
                    className={classNameByLevel(15, partsToHighlight)}
                    d="M175.43,287c-5.77-16-9.66-27.8-9.66-27.8C157.12,262,141.13,262,137,262h-1c-4.13,0-20.12-.06-28.77-2.81,0,0-3.89,11.78-9.66,27.8-10.19,28.33-9.07,64.33-7.68,73.67,7.48,5.8,40,6.29,46.11,6.33h1c6.13,0,38.63-.53,46.11-6.33C184.5,351.33,185.62,315.33,175.43,287Z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="part14"
                    className={classNameByLevel(14, partsToHighlight)}
                    d="M137.5,367.49h-2c-7.38-.06-38-.68-45.72-6.29v15.38c5.89.38,12,1.9,14.17,4.29,3.42,3.82,3.15,15.55,3.13,16.05V397c10.27.64,22.79.89,28.17,1v0l1.33,0,1.34,0v0c5.37-.08,17.9-.33,28.16-1v-.07c0-.5-.29-12.23,3.14-16.05,2.1-2.33,8-3.84,13.76-4.27V361.36C174.89,366.82,144.8,367.43,137.5,367.49Z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="part13"
                    className={classNameByLevel(13, partsToHighlight)}
                    d="M213.9,373.67c0,16.91-25.23,20.63-37,22.33-9.63,1.39-31.42,1.84-39,2v0l-1.33,0-1.33,0v0c-7.58-.12-29.37-.57-39-2-11.77-1.7-37-5.42-37-22.33A18.18,18.18,0,0,1,59.5,370c-2.21,3.38-8.83,14.47-7.15,23.68,1.51,8.34,13.32,23.52,83.73,23.66h1c70.41-.14,82.22-15.32,83.73-23.66,1.68-9.21-4.94-20.3-7.15-23.68A18.18,18.18,0,0,1,213.9,373.67Z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="part12"
                    className={classNameByLevel(12, partsToHighlight)}
                    d="M96.25,396c2.67.39,6.28.7,10.27.95l.07,0s.32-12-3-15.7S86.32,375.83,80,377.79c-3.43,1.07-6.23,7-8.07,12.15C80,393.63,90,395.1,96.25,396Z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="part11"
                    className={classNameByLevel(11, partsToHighlight)}
                    d="M100.4,250.9c-0.9,0.9-10.8,10.8-18.3,17.9l0,0l-0.2,0.2l-0.7,0.6l0,0c-0.3,0.3-0.7,0.7-1,1 c-6.3,5.9-20.9,16.7-27,21l-0.2,0.1c5.4,34,12.4,49.6,11.1,58.9h-0.4c-2.4,12.5-4.7,17.4-4.6,23.1c0,7.8,5.3,12.8,12.3,16.1 c2.6-7.3,5.4-11.5,8.4-12.4c2.2-0.7,5.5-0.9,8.9-0.8v-22.3c0-0.5-0.1-3.1-0.1-3.7h-0.1c-0.5-15.4,0.7-41.9,8.5-63.7 c5.7-15.8,9.6-27.7,9.7-27.8l0.1-0.2L100.4,250.9z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="part10"
                    className={classNameByLevel(10, partsToHighlight)}
                    d="M95.35,210.46v-.63H47.26c0,.06,0,.11,0,.17,0,34.92,2.56,61.16,5.7,81.13,6.11-4.42,20.69-15.11,26.92-21l1.67-1.57c7.92-7.53,18.59-18.15,18.59-18.15A199.12,199.12,0,0,1,95.35,210.46Z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="part09"
                    className={classNameByLevel(9, partsToHighlight)}
                    d="M43,318.46c7.56,0,14.18,5.83,17.78,14.54h1C57,313.13,47.32,278,47.32,210a442.72,442.72,0,0,1,12.24-84.12c-3.77,7.23-9.78,11.91-16.56,11.91a14.1,14.1,0,0,1-2.44-.21c-3.15,12.14-7.33,35.09-8.34,73.42-1.16,44.3,5.17,86.31,9.09,107.56A15.65,15.65,0,0,1,43,318.46Z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="part08"
                    className={classNameByLevel(8, partsToHighlight)}
                    d="M96.73,164.32a130.07,130.07,0,0,0-16.66-8l-1.81-.72c-8-3.11-18.58-6.31-23.57-7.77a452.54,452.54,0,0,0-7.43,62.05H95.35C95.26,189.82,96.73,164.32,96.73,164.32Z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="part07"
                    className={classNameByLevel(7, partsToHighlight)}
                    d="M77.82,138c-.11-3.91,1.72-7.83,5.42-11.64a18,18,0,0,1,2.7-2.43l-2.27-11.23s-.71-3.57-1.39-9L63.08,112c-1.46,5.41-5,17.7-8.39,35.51,5,1.47,15.54,4.67,23.57,7.78l1.81.72a133.55,133.55,0,0,1,16.19,7.77l1.27-9.6c-2.07-.22-9.62-1.32-14.43-5.74C81.19,146.63,78,142.94,77.82,138Z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="part06"
                    className={classNameByLevel(6, partsToHighlight)}
                    d="M85.73,60.1l-4.31-4.32A28.24,28.24,0,0,1,61.63,63.5c-.57,0-1.13,0-1.69,0l-.54,0A28,28,0,0,0,58.92,72c1.11,10.21,6.64,29.28,4.47,39L82.16,103C80.76,91.11,79.7,71.58,85.73,60.1Z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="part05"
                    className={classNameByLevel(5, partsToHighlight)}
                    d="M60,63c.56,0,1.11,0,1.67,0,14.19,0,25.7-9.78,25.7-21.83a18.6,18.6,0,0,0-.57-4.55C69.47,41.76,62.11,53.2,60,63Z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="part04"
                    className={classNameByLevel(4, partsToHighlight)}
                    d="M190,127c-13.79-14.19-46.67-15.25-52.72-15.33h-1c-6,.08-38.93,1.14-52.72,15.33-9.71,10-3.74,18-.16,21.33,5.43,5,14.65,5.67,14.65,5.67s11.54-6.24,22.23-8a103.09,103.09,0,0,1,16-1.34h1a103.09,103.09,0,0,1,16,1.34c10.69,1.76,22.23,8,22.23,8s9.22-.67,14.65-5.67C193.74,145,199.71,137,190,127Z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="part04"
                    className={classNameByLevel(4, partsToHighlight)}
                    d="M190,127c-13.79-14.19-46.67-15.25-52.72-15.33h-1c-6,.08-38.93,1.14-52.72,15.33-9.71,10-3.74,18-.16,21.33,5.43,5,14.65,5.67,14.65,5.67s11.54-6.24,22.23-8a103.09,103.09,0,0,1,16-1.34h1a103.09,103.09,0,0,1,16,1.34c10.69,1.76,22.23,8,22.23,8s9.22-.67,14.65-5.67C193.74,145,199.71,137,190,127Z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="part03"
                    className={classNameByLevel(3, partsToHighlight)}
                    d="M180.41,52.67c-12.2-6.59-38.22-7.26-43.41-7.33h-.93c-5.19.07-31.21.74-43.4,7.33-18.91,10.2-9,60.33-9,60.33s15.44-5.47,27.58-7.67a152.89,152.89,0,0,1,24.82-2H137a152.89,152.89,0,0,1,24.82,2c12.15,2.2,27.59,7.67,27.59,7.67S199.31,62.87,180.41,52.67Z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="part02"
                    className={classNameByLevel(2, partsToHighlight)}
                    d="M86,59.64a17,17,0,0,1,6.69-7c12.19-6.59,38.21-7.26,43.4-7.33H137c5.19.07,31.21.74,43.41,7.33A16.54,16.54,0,0,1,186.69,59l5-5a19.65,19.65,0,0,1-4.89-12.8,18.51,18.51,0,0,1,.54-4.42l-1.4-.42c-21.73-6-49.12-6-49.34-6s-27.6-.05-49.33,6l-.64.2A18.84,18.84,0,0,1,87.15,41a19.86,19.86,0,0,1-5.9,13.92Z"
                    transform="translate(-21.83 -15.17)"
                />
                <path
                    id="part01"
                    className={classNameByLevel(1, partsToHighlight)}
                    d="M58.77,72.4c0-.13,0-.27-.05-.4-1.14-10.43,4.33-29,28.33-35.67,21.73-6,49.12-6,49.33-6s27.61-.05,49.34,6c24,6.67,29.47,25.24,28.33,35.67,0,.13,0,.27-.05.4,5.33-2.7,14.38-10.51,4.07-30.32-12.88-24.74-73.64-26.31-81.29-26.41H136c-7.65.1-68.41,1.67-81.29,26.41C44.39,61.89,53.44,69.7,58.77,72.4Z"
                    transform="translate(-21.83 -15.17)"
                />

                <ellipse id="part63" className={classNameByLevel(63, partsToHighlight)} cx="21.17" cy="92.96" rx="20.67" ry="29.67" />
                <ellipse id="part64" className={classNameByLevel(64, partsToHighlight)} cx="208.17" cy="92.96" rx="20.67" ry="29.67" />
                <ellipse id="part65" className={classNameByLevel(65, partsToHighlight)} cx="21.17" cy="332.96" rx="20.67" ry="29.67" />
                <ellipse id="part66" className={classNameByLevel(66, partsToHighlight)} cx="208.17" cy="332.96" rx="20.67" ry="29.67" />

                <rect id="part71" className={classNameByLevel(71, partsToHighlight)} x="80" y="149.5" width="9" height="85" rx="1.5" />
                <rect id="part72" className={classNameByLevel(72, partsToHighlight)} x="141" y="149.5" width="9" height="85" rx="1.5" />

                <path
                    id="part73"
                    className={classNameByLevel(73, partsToHighlight)}
                    d="M42 104C46.6836 101.106 55 101.5 60 103V116H37C37 116 37.3164 106.894 42 104Z"
                />
                <path
                    id="part74"
                    className={classNameByLevel(74, partsToHighlight)}
                    d="M 190 104 C 185.316 101.106 177 101.5 172 103 V 116 H 195 C 195 116 194.684 106.894 190 104 Z"
                />
            </g>

            <g id="numbers">
                <text className="cls-24" transform="translate(81 190)">
                    {renumbering[71]}
                </text>
                <text className="cls-24" transform="translate(142 190)">
                    {renumbering[72]}
                </text>
                <text className="cls-24" transform="translate(45 114)">
                    {renumbering[73]}
                </text>
                <text className="cls-24" transform="translate(180 114)">
                    {renumbering[74]}
                </text>

                <text className="cls-24" transform="translate(205 335)">
                    {renumbering[66]}
                </text>
                <text className="cls-24" transform="translate(14 335)">
                    {renumbering[65]}
                </text>
                <text className="cls-24" transform="translate(205 96)">
                    {renumbering[64]}
                </text>
                <text className="cls-24" transform="translate(14 96)">
                    {renumbering[63]}
                </text>

                <text className="cls-24" transform="translate(171.16 41.5)">
                    {renumbering[24]}
                </text>
                <text className="cls-24" transform="translate(173.16 78.5)">
                    {renumbering[23]}
                </text>
                <text className="cls-24" transform="translate(175.16 130)">
                    {renumbering[22]}
                </text>
                <text className="cls-24" transform="translate(182.16 173.5)">
                    {renumbering[21]}
                </text>
                <text className="cls-24" transform="translate(181.16 235.5)">
                    {renumbering[19]}
                </text>
                <text className="cls-24" transform="translate(205.16 202.5)">
                    {renumbering[20]}
                </text>
                <text className="cls-24" transform="translate(170.16 302.5)">
                    {renumbering[18]}
                </text>
                <text className="cls-24" transform="translate(155 375)">
                    {renumbering[17]}
                </text>
                <text className="cls-24" transform="translate(107.99 198.5)">
                    {renumbering[16]}
                </text>
                <text className="cls-24" transform="translate(107.99 318.5)">
                    {renumbering[15]}
                </text>
                <text className="cls-24" transform="translate(107.99 373.5)">
                    {renumbering[14]}
                </text>
                <text className="cls-24" transform="translate(107.99 397.5)">
                    {renumbering[13]}
                </text>
                <text className="cls-24" transform="translate(65 375)">
                    {renumbering[12]}
                </text>
                <text className="cls-24" transform="translate(44.6 302.5)">
                    {renumbering[11]}
                </text>
                <text className="cls-24" transform="translate(34.16 235.5)">
                    {renumbering[10]}
                </text>
                <text className="cls-24" transform="translate(14.5 202.5)">
                    {renumbering[9]}
                </text>
                <text className="cls-24" transform="translate(38.5 173.5)">
                    {renumbering[8]}
                </text>
                <text className="cls-24" transform="translate(44.5 130)">
                    {renumbering[7]}
                </text>
                <text className="cls-24" transform="translate(45.5 78.5)">
                    {renumbering[6]}
                </text>
                <text className="cls-24" transform="translate(47.5 41.5)">
                    {renumbering[5]}
                </text>
                <text className="cls-24" transform="translate(111.33 115.5)">
                    {renumbering[4]}
                </text>
                <text className="cls-24" transform="translate(111.33 64.5)">
                    {renumbering[3]}
                </text>
                <text className="cls-24" transform="translate(111.33 26.5)">
                    {renumbering[2]}
                </text>
                <text className="cls-24" transform="translate(111.33 12.5)">
                    {renumbering[1]}
                </text>
            </g>
        </svg>
    );
};
