import React, { Fragment } from 'react';
import BodyParts from './BodyParts';
import './CarDamages.less';
import { Row, Col } from 'reactstrap';
import { Information } from '../ui/common-ui';
import { damageLocationTranslationKey, damageKind, damageKindClassNames, damageCategories, damageCategory } from './CarDamageConstants';
import settings from '../../appsettings';
import { Spinner } from '@ambiorix/adesa-web-kit';

const Title = ({ label }) => (
    <div className="title">
        <Row>
            <Col xs={12}>
                <h3>{label}</h3>
            </Col>
        </Row>
    </div>
);
const DetailLine = ({ label, children, className = '' }) => (
    <div className={'line ' + className}>
        <Row>
            <Col xs={12}>{label ? label : children}</Col>
        </Row>
    </div>
);
const LinesGroup = ({ children }) => <div className="lines-group">{children}</div>;

const isDrivableLabel = {
    yes: 'vehicleDetails.damages.drivableYes',
    no: 'vehicleDetails.damages.drivableNo',
    unknown: 'vehicleDetails.damages.drivableUndefined',
};

export default ({
    carDamages, //  {damages: [{  kind: "Small",  kindId: 6,  location: "2",  locationId: 2,  isForBrokenCars: null, categoryId: 1 }, ... ], specialDamages: ["xyz", ...] }
    carDetails,
    t,
    picturesList,
}) => {
    if (carDamages === null || !carDamages.damagesFetched)
        return (
            <>
                <Spinner />
            </>
        );

    const isBodyPartLocation = a => a.categoryId === damageCategory.exterior;
    const isNormalKindOfDamage = a => a.kindId <= 5 || a.kindId == 9; // 9: Corrosion (added after)

    // [{kind: "Impact from stones", kindId: 1, location: "1", locationId: 1, isForBrokenCars: null}, ....]
    const normalDamages = carDamages.damages.filter(a => isBodyPartLocation(a) && isNormalKindOfDamage(a));
    const bodyDamages = carDamages.damages.filter(a => isBodyPartLocation(a) && !isNormalKindOfDamage(a));

    // renumbering parts to show numbers only on damages parts and starting at 1 (= first damage).
    const partsRenumbering = [];
    let index = 0;
    normalDamages.forEach(a => {
        if (partsRenumbering[a.locationId]) {
            // check if number already used before.
            a.index = partsRenumbering[a.locationId];
        } else {
            index = index + 1;
            partsRenumbering[a.locationId] = index;
            a.index = index;
        }
    });
    bodyDamages.forEach(a => {
        if (partsRenumbering[a.locationId]) {
            // check if number already used before.
            a.index = partsRenumbering[a.locationId];
        } else {
            index = index + 1;
            partsRenumbering[a.locationId] = index;
            a.index = index;
        }
    });

    // sort by index.
    normalDamages.sort((a, b) => a.index - b.index);
    bodyDamages.sort((a, b) => a.index - b.index);

    // partsToHighlight = {<locationId>: [<kindId1>, <kindId2>, ...], ... }
    const partsToHighlight = carDamages.damages.filter(isBodyPartLocation).reduce((map, val) => {
        if (!map[val.locationId]) {
            map[val.locationId] = [];
        }
        map[val.locationId].push(val.kindId);
        return map;
    }, {});

    const interiorDamages = carDamages.damages
        .filter(a => a.categoryId === damageCategory.accidental)
        .map(a => t(damageLocationTranslationKey[a.locationId]));
    const technicalDamages = carDamages.damages
        .filter(a => a.categoryId === damageCategory.technical)
        .map(a => t(damageLocationTranslationKey[a.locationId]));
    const specialDamages = carDamages.specialDamages.map(a => t(a));

    const displayCarFrog = (normalDamages.length > 0 || bodyDamages.length > 0) && !carDamages.onlyShowReports;

    const showLoginDialog = e => {
        e.preventDefault();
        const button = document.querySelector('.rc-login-button .cotw-btn, .login-form-container .button');
        if (button) {
            button.click();
        }
        return false;
    };

    const externalDamageReportBaseUrl = () => {
        return settings.damageBaseUrl
            ? settings.damageBaseUrl.replace(/\/damages\/?/i, '') + '/report/getfile'
            : 'https://damage.adesa.eu/report/getfile';
    };

    let reports = carDamages.reports;
    const types = [...new Set(reports.map(report => report.TypeId))];
    types.forEach(typeId => {
        var count = reports.filter(report => report.TypeId === typeId).length;
        var index = 1;
        reports.forEach(report => {
            if (report.TypeId === typeId) {
                let translation = '';
                switch (report.TypeId) {
                    case 1:
                        translation = t('vehicleDetails.reports.internalDamageReport');
                        break;
                    case 2:
                        translation = t('vehicleDetails.reports.externalDamageReport');
                        break;
                    case 3:
                        translation = t('vehicleDetails.reports.maintenanceRecords');
                        break;
                    case 4:
                        translation = t('vehicleDetails.reports.optionReport');
                        break;
                    default:
                        translation = 'report';
                }
                if (count > 1) {
                    report.LinkLabel = translation + ' (' + index + ')';
                } else {
                    report.LinkLabel = translation;
                }
                index++;
            }
        });
    });

    reports = reports.sort((a, b) => a.LinkLabel.localeCompare(b.LinkLabel));

    const displayDamageSection =
        (displayCarFrog && (normalDamages.length > 0 || bodyDamages.length > 0)) ||
        interiorDamages.length > 0 ||
        technicalDamages.length > 0 ||
        specialDamages.length > 0 ||
        (carDamages.damageComment && !carDamages.hideDamageComment);

    return (
        <div className="rc-CarDamages">
            {carDetails.isDrivable ? <Information>{t(isDrivableLabel[carDetails.isDrivable])}</Information> : undefined}
            {carDetails.isUkCountryCar && carDetails.damageCategoryId ? (
                <Information>{t('vehicleDetails.messages.damageCategoryMessage') + damageCategories[carDetails.damageCategoryId]}</Information>
            ) : undefined}
            {carDamages.reports.length || carDamages.hasReportsButLoggedOut ? (
                <Fragment>
                    <Title label={t('vehicleDetails.damages.documents')} />
                    <LinesGroup>
                        <DetailLine className="external-damage-reports">
                            {carDamages.hasReportsButLoggedOut ? (
                                <a href="#" onClick={showLoginDialog} className="link">
                                    {t('vehicleDetails.damages.loginToSeeDocuments')}
                                </a>
                            ) : (
                                <ul className="reports">
                                    {carDamages.reports.map(report => (
                                        <li key={'report-' + report.Id}>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={report.Url ? report.Url : `${externalDamageReportBaseUrl()}/${report.FileName}`}
                                                className="link">
                                                {report.LinkLabel}
                                            </a>{' '}
                                            <i className="fa fa-file-pdf-o"></i>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </DetailLine>
                    </LinesGroup>
                </Fragment>
            ) : undefined}

            {displayDamageSection && (
                <>
                    {displayCarFrog ? <Title label={t('vehicleDetails.damages.damageLocations')} /> : undefined}
                    <LinesGroup>
                        <DetailLine>
                            <Row>
                                {displayCarFrog ? (
                                    <Col style={{ textAlign: 'center' }}>
                                        {/* <img src="https://damage.carsontheweb.com/content/car-damage-zones.jpg" alt=""/> */}
                                        <BodyParts partsToHighlight={partsToHighlight} renumbering={partsRenumbering} />
                                    </Col>
                                ) : undefined}
                                <Col>
                                    <div className="damages-description">
                                        {normalDamages.length && displayCarFrog ? (
                                            <>
                                                <h3>{t('vehicleDetails.damages.normalDamage')}</h3>
                                                {normalDamages.map((bd, i) => (
                                                    <div key={bd.locationId} className="damage-item">
                                                        <span className="number">{bd.index + '.'}</span>{' '}
                                                        <span className="label">
                                                            {t(damageLocationTranslationKey[bd.locationId])}:{' '}
                                                            <span className={damageKindClassNames[bd.kindId]}>{t(damageKind[bd.kindId])}</span>
                                                        </span>
                                                    </div>
                                                ))}
                                            </>
                                        ) : undefined}

                                        {bodyDamages.length && displayCarFrog ? (
                                            <>
                                                <h3>{t('vehicleDetails.damages.bodyDamages')}</h3>
                                                {bodyDamages.map((bd, i) => (
                                                    <div key={bd.locationId} className="damage-item">
                                                        <span className="number">{bd.index + '.'}</span>{' '}
                                                        <span className="label">
                                                            {t(damageLocationTranslationKey[bd.locationId])}:{' '}
                                                            <span className={damageKindClassNames[bd.kindId]}>{t(damageKind[bd.kindId])}</span>
                                                        </span>
                                                    </div>
                                                ))}
                                            </>
                                        ) : undefined}

                                        {interiorDamages.length ? (
                                            <>
                                                <h3>{t('vehicleDetails.damages.internalDamages')}</h3>
                                                <ul>
                                                    {interiorDamages.map((a, i) => (
                                                        <li key={i}>{a}</li>
                                                    ))}
                                                </ul>
                                            </>
                                        ) : undefined}

                                        {technicalDamages.length ? (
                                            <>
                                                <h3>{t('vehicleDetails.damages.technicalDamages')}</h3>
                                                <ul>
                                                    {technicalDamages.map((a, i) => (
                                                        <li key={i}>{a}</li>
                                                    ))}
                                                </ul>
                                            </>
                                        ) : undefined}

                                        {specialDamages.length ? (
                                            <>
                                                <h3>{t('vehicleDetails.damages.specialDamages')}</h3>
                                                <ul>
                                                    {specialDamages.map((a, i) => (
                                                        <li key={i}>{a}</li>
                                                    ))}
                                                </ul>
                                            </>
                                        ) : undefined}

                                        {carDamages.damageComment && !carDamages.hideDamageComment ? (
                                            <>
                                                <h3>{t('vehicleDetails.damages.comments')}</h3>
                                                <p>{carDamages.damageComment}</p>
                                            </>
                                        ) : undefined}
                                    </div>
                                </Col>
                            </Row>
                        </DetailLine>
                    </LinesGroup>
                </>
            )}
        </div>
    );
};
