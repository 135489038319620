import { notify } from 'reapop';
import settings from '../appsettings';
import { queryString, Cookies, fetchJson, textAbstract, isArray, scrollToTop, decodeHTML } from '../utils';
import { formatDate } from '../utils/date';
import { replace, push } from 'connected-react-router';
import { followNotificationMessage } from './cotw.js';

function url(urlSuffix, currentLanguage) {
    return settings.carsiteBaseUrl + '/' + currentLanguage + '/' + urlSuffix;
}

function goBackToOldUI() {
    Cookies.set('new_ui', 'no');
    location.reload();
}

const articles = [
    // {
    //     title: "Italdesign delivers the first Zerouno in Belgium!",
    //     body: "The first Italdesign Automobili Speciali Zerouno has been delivered to the first client, a Belgian automotive (…)",
    //     articleUrl: "#",
    //     imgUrl: "/v6/images/image-default-article.png",
    // },
    // {
    //     title: "7 heights and lows from 70 years of Porsche history",
    //     body: "It is exactly 70 years ago this year that the very first Porsche, which was based on the on the Volkswagen Beetle 356, delivered its very (…)",
    //     articleUrl: "#",
    //     imgUrl: "/v6/images/image-default-article.png",
    // },
    // {
    //     title: "Connected vehicles: What if ... cars could talk?",
    //     body: "Today's cars are just brimming with technology, but communicating with other four wheelers and other road users, we (...)",
    //     articleUrl: "#",
    //     imgUrl: "/v6/images/image-default-article.png",
    // },
    // {
    //     title: "Ford Fiesta: destined to remain popular",
    //     body: "It all started in 1976, when the world met the Ford with the festive name, the Fiesta, for the very first time. Since the first (...)",
    //     articleUrl: "#",
    //     imgUrl: "/v6/images/image-default-article.png",
    // },
];

// ===== REDUX ACTIONS ====================================
const INIT = 'HOME:INIT';
const CAR_RECOMMENDED_SET = 'HOME:CAR_RECOMMENDED_SET';
const NEW_ARTICLES = 'HOME:NEW_ARTICLES';
const NEW_MAKE_IDS = 'HOME:NEW_MAKE_IDS';
const NEW_AUCTION_CHANNELS = 'HOME:NEW_AUCTION_CHANNELS';
const NEW_BODY_TYPES = 'HOME:NEW_BODY_TYPES';
const CURRENT_COUNTRY = 'HOME:CURRENT_COUNTRY';
const MY_SELECTION_METADATA = 'HOME:MY_SELECTION_METADATA';
const MY_SELECTION_CARS = 'HOME:MY_SELECTION_CARS';
const MY_SELECTION_RESET = 'HOME:MY_SELECTION_RESET';

const initialState = {
    currentCountry: 'be',
    newQuickSearch: false,

    articles: articles,
    orderedMakeIds: [47, 2, 40, 7, 3, 1, 4, 17, 5, 22, 6, 32, 46, 57, 52, 28, 45, 27, 42, 43],
    orderedAuctionChannels: [
        'ALD AUTOMOTIVE',
        'Alphabet',
        'Arval',
        'Athlon',
        'AVIS',
        'EUROPCAR',
        'GWListe',
        'Hertz',
        'LeasePlan',
        'Sixt Leasing',
        'Van Mossel Automotive Groep',
        'VW Leasing',
    ], // list of string.
    orderedBodyTypes: [],

    carRecommendationSets: {},

    mySelection: {
        metadata: [],
        sets: [],
    },
};

/***************************************************************************************************************
 *  REDUX REDUCER
 */
export function homeReducer(state = initialState, action) {
    switch (action.type) {
        case INIT: {
            return { ...state };
        }

        case CAR_RECOMMENDED_SET: {
            return {
                ...state,
                carRecommendationSets: {
                    ...state.carRecommendationSets,
                    [action.template]: carRecommendationToState(action.set), // action.set.length ? carRecommendationToState(action.set) : dummyCarsSet,
                },
            };
        }

        case NEW_ARTICLES: {
            return {
                ...state,
                articles: newsArticlesToState(action.articles),
            };
        }

        case NEW_MAKE_IDS: {
            return { ...state, orderedMakeIds: action.makeIds };
        }

        case NEW_AUCTION_CHANNELS: {
            return { ...state, orderedAuctionChannels: action.sellerNames };
        }

        case NEW_BODY_TYPES: {
            return { ...state, orderedBodyTypes: action.bodyTypes };
        }

        case CURRENT_COUNTRY: {
            return { ...state, currentCountry: action.country };
        }

        case MY_SELECTION_RESET: {
            return {
                ...state,
                mySelection: {
                    metadata: [],
                    sets: [],
                },
            };
        }
        case MY_SELECTION_METADATA: {
            return {
                ...state,
                mySelection: {
                    metadata: action.metadata,
                    sets: [],
                },
            };
        }
        case MY_SELECTION_CARS: {
            const sets = [...state.mySelection.sets];
            sets[action.index] = carRecommendationToState(action.auctions);
            return {
                ...state,
                mySelection: {
                    ...state.mySelection,
                    sets,
                },
            };
        }

        default:
            return { ...state };
    }
}

/***************************************************************************************************************
 *  REACT-REDUX state to properties map.
 */
export const homeMapStateToProps = globalState => ({
    isUserLoggedIn: globalState.common.isUserLoggedIn || false,
    currentLanguage: globalState.common.currentLanguage || 'en',
    currentUser: globalState.common.currentUser,

    newQuickSearch: globalState.home.newQuickSearch,
    articles: globalState.home.articles,
    //carRecommendationSets:          globalState.home.carRecommendationSets,
    carRecommendationSets: Object.assign(
        {},
        ...Object.keys(globalState.home.carRecommendationSets).map(k => ({
            [k]: globalState.home.carRecommendationSets[k].map(c => ({
                ...c,
                following: globalState.common.watches.indexOf(c.auctionId) > -1,
            })),
        })),
    ),
    mySelection: {
        ...globalState.home.mySelection,
        sets:
            globalState.home.mySelection.sets &&
            globalState.home.mySelection.sets.map(s => s && s.map(a => ({ ...a, following: globalState.common.watches.indexOf(a.auctionId) > -1 }))),
    },
    orderedMakeIds: globalState.home.orderedMakeIds,
    currentCountry: globalState.home.currentCountry,
    orderedAuctionChannels: globalState.home.orderedAuctionChannels,
    orderedBodyTypes: globalState.home.orderedBodyTypes,
});

/***************************************************************************************************************
 *  REACT-REDUX action creators (using redux-thunk: Redux Thunk middleware allows you to write action creators that return a function instead of an action)
 */

function notifyError(dispatch, message) {
    dispatch(notify({ message: message, status: 'error' }));
}

export const homeActionsCreator = {
    onInit: () => (dispatch, getState) => initializeState(dispatch, getState),
    onFetchMySelection: () => (dispatch, getState) => fetchMySelection(dispatch, getState),
    onGoToSearchView: () => dispatch => {
        dispatch(push('/en/findcar'));
        scrollToTop();
    },
    onGoToAdvancedSearchView: () => dispatch => dispatch(push('/en/findcar/advanced')),
    onShowAuction: (auctionId, ref) => (dispatch, getState) => {
        dispatch(push(`/${getState().common.currentLanguage}/car/info?auctionId=${auctionId}` + (ref ? `&ref=${ref}` : '')));
        scrollToTop();
    },
    onLoadCarsSet: index => (dispatch, getState) => {
        loadMySelectionSet(index, dispatch, getState);
    },
    //onFollowThisCar: (auctionId) => (dispatch, getState) => followThisCar(auctionId, dispatch, getState()),
};

function initializeState(dispatch, getState) {
    const state = getState();
    //dispatch ({type: INIT});
    const isUserLoggedIn = state.common.isUserLoggedIn;
    const recommendationsTemplates = isUserLoggedIn
        ? ['HighAssignment', 'LastViewed', 'Country', 'Activity', 'ImmediateCloseBy']
        : ['Country', '24_7Stock'];

    recommendationsTemplates.forEach(template => {
        fetchJson(url('homev6/recommendedauctions?template=' + template, state.common.currentLanguage)).then(set => {
            dispatch({ type: CAR_RECOMMENDED_SET, set, template });
        });
    });

    // read news
    const articlesUrl = url('homev6/newsarticles?language=' + state.common.currentLanguage, state.common.currentLanguage);
    fetchJson(articlesUrl).then(articles => {
        if (articles) {
            dispatch({ type: NEW_ARTICLES, articles });
        }
    });

    fetchJson(url('homev6/popularmakes', state.common.currentLanguage)).then(makeIds => {
        if (makeIds) {
            dispatch({ type: NEW_MAKE_IDS, makeIds });
        }
    });

    fetchJson(url('homev6/popularsellers', state.common.currentLanguage)).then(sellerNames => {
        if (sellerNames) {
            dispatch({
                type: NEW_AUCTION_CHANNELS,
                sellerNames: isArray(sellerNames) ? sellerNames : [],
            });
        }
    });

    // only for logged in user. keep fixed ordering for logged out users (cfr Tsan)
    fetchJson(url('homev6/popularbodytypes', state.common.currentLanguage)).then(bodyTypes => {
        if (bodyTypes) {
            dispatch({ type: NEW_BODY_TYPES, bodyTypes });
        }
    });

    fetchJson(url('homev6/currentcountry', state.common.currentLanguage)).then(country => {
        dispatch({ type: CURRENT_COUNTRY, country });
    });
}

function fetchMySelection(dispatch, getState) {
    const state = getState();
    //dispatch ({type: INIT});
    dispatch({ type: MY_SELECTION_RESET });
    fetchJson(url('homev6/MySelectionMetadata', state.common.currentLanguage)).then(metadata => {
        if (metadata) {
            dispatch({
                type: MY_SELECTION_METADATA,
                metadata: mySelectionMetadataToState(metadata),
            });
        }
    });
}

function loadMySelectionSet(index, dispatch, getState) {
    const state = getState();
    const metadata = state.home.mySelection.metadata[index];
    if (state.home.mySelection.sets[index]) return; // skip if data already loaded.
    fetchJson(url('homev6/MySelectionAuctions', state.common.currentLanguage) + '?' + queryString(metadata)).then(auctions =>
        dispatch({ type: MY_SELECTION_CARS, auctions, index }),
    );
}

/***************************************************************************************************************
 *  DATA - REDUX STATE MAPPINGS.
 */

function carRecommendationToState(set) {
    return isArray(set)
        ? set.map(a => ({
              ...a,
              tagLine: textAbstract(a.tagLine, 45),
              thumbnailUrl: a.thumbnailUrl || '/v6/images/car-placeholder.png',
              registrationDate: formatDate(a.registrationDate, 'short'),

              prices: {
                  isBuyNow: a.isBuyNow,
                  isTarget: a.isTarget,
                  isBlind: a.isBlind,
                  currentPrice: a.currentPrice,
                  recommendedPrice: a.recommendedPrice,
                  buyNowPrice: a.buyNowPrice,
                  targetPrice: a.targetPrice,
              },
          }))
        : [];
}

function newsArticlesToState(list) {
    const cleaned = text => text.replace(/\[[^\]]+\]/g, '');
    return isArray(list)
        ? list.map(a => ({
              title: decodeHTML(a.title),
              body: decodeHTML(textAbstract(cleaned(a.summary), 150)),
              articleUrl: a.url,
              imgUrl: a.imgUrl || '/v6/images/image-default-article.png', // fallback image when none present in article body.
          }))
        : [];
}

function mySelectionMetadataToState(list) {
    return isArray(list)
        ? list.map(a => ({
              templateName: a.TemplateName,
              templateOrder: a.TemplateOrder,
              title: a.Title,
              abMethod: a.AbMethod,
              jsonParameter: a.JsonParameter,
              reference: a.Reference,
          }))
        : [];
}
