import React from 'react';
import { useState } from 'react';
import { AdesaModal } from './Modal';
import { AdesaButton } from './Button';
import styles from './WebSocketErrorModal.module.scss';
import { useAtleastMDMediaQuery } from '../../hooks/useAtleastMDMediaQuery';

const WebSocketErrorModal = ({ t, className = '' }: { t: (key: string) => string; className?: string }) => {
    const [open, setOpen] = useState(true);
    const isMDScreen = useAtleastMDMediaQuery();

    return (
        <AdesaModal is-open={open} width={isMDScreen ? '35' : '90'} onChanged={() => window.location.reload()} className={className}>
            <h3 slot="header">
                <span className={styles.header}>{t('common.banner.webSocketError.title')}</span>
            </h3>
            <div slot="body">{t('common.banner.webSocketError.message')}</div>
            <div className="modal-footer" slot="footer">
                <AdesaButton size="secondary" color="positive" onClick={() => window.location.reload()}>
                    {t('common.banner.webSocketError.refreshButton')}
                </AdesaButton>
            </div>
        </AdesaModal>
    );
};

export default WebSocketErrorModal;
