export function getDefaultInvoicingOfficeId(countryId, invoicingOffices) {
    if (countryId && invoicingOffices && invoicingOffices.length > 0) {
        const invoicingOfficesByCountry = invoicingOffices.find(office => office.countryId == countryId);
        //return 'europe' if there is no specific value found
        return !invoicingOfficesByCountry ? 1 : invoicingOfficesByCountry.offices.find(office => office.officeId > 1).officeId;
    }
    return 0;
}

export function getPhonePrefix(countryId, phoneCountryCodes) {
    var countryCode = phoneCountryCodes.find(x => x.id === countryId);
    return countryCode ? `+${countryCode.code}` : undefined;
}
