import React from 'react';
import { Tooltip } from '@ambiorix/adesa-web-kit';
import { Score } from './Common';

export const SellerScore = ({ t, id, linkText, scores }) => {
    return (
        <>
            <Tooltip
                mode="click"
                content={
                    <>
                        <Score
                            label={t('vehicleDetails.sellerScore.headline')}
                            value={scores.GlobalScore}
                            focus={true}
                            decimal={scores.GlobalScore !== 5}
                        />
                        <hr />
                        <Score label={t('vehicleDetails.sellerScore.vehicleAsDescribed')} value={scores.VehicleAsDescribed} focus={false} />
                        <Score label={t('vehicleDetails.sellerScore.chancesOfGetting')} value={scores.ChanceOfAssignment} focus={false} />
                        <Score label={t('vehicleDetails.sellerScore.assignmentTime')} value={scores.CarAssignmentTime} focus={false} />
                        <Score label={t('vehicleDetails.sellerScore.releaseTime')} value={scores.CarReleaseTime} focus={false} />
                        <div className="disclaimer">{t('vehicleDetails.sellerScore.chancesExplanation')}</div>
                    </>
                }>
                <span className="score-link" id={id}>
                    {linkText}
                </span>
            </Tooltip>
        </>
    );
};

export default SellerScore;
