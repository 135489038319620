// Actions
export const INIT_METADATA = 'CARSEARCH:INIT_METADATA';
export const SEARCH = 'CARSEARCH:DO_SEARCH';
export const START_SEARCH = 'CARSEARCH:START_SEARCH';
export const NEW_SEARCH_RESULT = 'CARSEARCH:NEW_SEARCH_RESULT';
export const NEXT_SEARCH_RESULT = 'CARSEARCH:NEXT_SEARCH_RESULT';
export const NEW_SEARCH_COUNT = 'CARSEARCH:NEW_SEARCH_COUNT';
export const CHANGE_PAGE_SIZE = 'CARSEARCH:CHANGE_PAGE_SIZE';
export const CHANGE_SORTING = 'CARSEARCH:CHANGE_SORTING';
export const CLEAR_SEARCH_CRITERIA = 'CARSEARCH:CLEAR_SEARCH_CRITERIA';
export const CHANGE_SEARCH_CRITERIA = 'CARSEARCH:CHANGE_SEARCH_CRITERIA';
export const SIGNALR_BID = 'CARSEARCH:SIGNALR_BID';
export const AUCTION_EXTRA_DATA = 'CARSEARCH:AUCTION_EXTRA_DATA';
export const AUCTION_EXTENDED = 'CARSEARCH:AUCTION_EXTENDED';
export const AUCTION_CLOSE_EXTENDED = 'CARSEARCH:AUCTION_CLOSE_EXTENDED';
export const XTIME_MESSAGE = 'CARSEARCH:XTIME_MESSAGE';
export const SHOW_HIDE_FACET = 'CARSEARCH:SHOW_HIDE_FACET';
export const ADD_FACET_COUNTS = 'CARSEARCH:ADD_FACET_COUNTS';
export const CLOSE_AUCTION = 'CARSEARCH:CLOSE_AUCTION';
export const SAVED_SEARCHES_GET = 'CARSEARCH:SAVED_SEARCHES_GET';
export const SAVED_SEARCHES_CREATE = 'CARSEARCH:SAVED_SEARCHES_CREATE';
export const SAVED_SEARCHES_UPDATE = 'CARSEARCH:SAVED_SEARCHES_UPDATE';
export const SAVED_SEARCHES_DELETE = 'CARSEARCH:SAVED_SEARCHES_DELETE';
export const SAVED_SEARCHES_SELECT = 'CARSEARCH:SAVED_SEARCHES_SELECT';
export const CHANGE_POWER_UNIT = 'CARSEARCH:CHANGE_POWER_UNIT';
export const USER_PLACED_A_BID = 'CARSEARCH:USER_PLACED_A_BID';
export const SET_AUCTIONBATCH_TITLE = 'CARSEARCH:SET_AUCTIONBATCH_TITLE';
export const SET_SEO_TEXT = 'CARSEARCH:SET_SEO_TEXT';

export const START_FETCHING_COMMUNITY = 'CARSEARCH:START_FETCHING_COMMUNITY';
export const COMMUNITY_FECHED = 'CARSEARCH:COMMUNITY_FECHED';
export const SET_LOCATION_METADATA = 'CARSEARCH:SET_LOCATION_METADATA';
