exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Y1P31g6WAIEyCz1ySrbwB h3{margin-bottom:0}", ""]);

// exports
exports.locals = {
	"modal": "Y1P31g6WAIEyCz1ySrbwB"
};