import { formatDate, getYear, parseDate } from '../../utils/date';
import { mileageFormat, fromToInList, formatNumber } from '../../utils';
import { belgiumDateTimeToLocal, getFormat } from '../../utils/date';
import {
    batchTypeFromBatchAttributeId,
    STEPWISE_BID_METHOD,
    MAXIMUM_BID_METHOD,
    mileages,
    AUCTION_STATUS,
    isUkEcadisCountry,
    CONFIRMED_AUCTION_STATUSES,
} from '../cotw.js';
import settings from '../../appsettings';
import i18n from '../../i18n';

import { replace, push, goBack } from 'connected-react-router';

/***************************************************************************************************************
 *  DATA - REDUX STATE MAPPINGS.
 */

export function isNonFrenchMarginCarForFrenchBuyer(data) {
    return data.IsMargin && data.OriginalcarLocationCountryId !== 'fr' && data.BuyerCountry === 'fr';
}

export function isNonItalianMarginCarForItalianBuyer(data) {
    return data.IsMargin && data.OriginalcarLocationCountryId !== 'it' && data.BuyerCountry === 'it';
}
const UK_ECADIS_COUNTRY = 9;

export function auctionInfoToState(data, currentLanguage, watches) {
    const isXTime = data.ExtendedPhaseType > -1;
    const isAlphabetLikeXTime = data.ExtendedPhaseType === 2;
    const isLeasePlanLikeXTime = data.ExtendedPhaseType === 0;
    /*const endDate =  isXTime && data.EndDateExtendedPhase !== "0001-01-01T00:00:00"     
                            ? moment(belgiumDateTimeToLocal(data.EndDateExtendedPhase))
                            : moment(belgiumDateTimeToLocal(data.BatchEndDate));*/
    const isClosed = isAlphabetLikeXTime ? data.IsExtendedPhaseFinished : data.StatusId === AUCTION_STATUS.AUCTION_WITHDRAWN;

    let cap = null;
    if (data.CarValuations && data.CarValuations.length > 0) {
        const found = data.CarValuations.filter(a => a.ProviderName === 'CAP')[0];
        if (found && (!!found.Retail || !!found.Clean || !!found.Average || !!found.Below)) {
            cap = {
                retail: found.Retail || 0,
                clean: found.Clean || 0,
                average: found.Average || 0,
                below: found.Below || 0,
            };
        }
    }

    return {
        auctionId: data.AuctionId,
        carId: data.CarId,
        auctionBatchId: data.AuctionBatchId,

        ...batchTypeFromBatchAttributeId(data.IsBuyNow, data.BatchAttributeId),

        isVatNonDeductable: data.IsMargin /* && model.CarDetailmarginvat === "VAT non deductable" */, // M symbol
        isVatDeductable: !data.IsMargin, // data.CarDetailmarginvat === "VAT deductable",             // D symbol
        isCommercialCar: data.IsCommercialCar,
        isMarginCar: data.IsMargin,

        isSold: data.IsSold,
        showSoldAnyway: false,
        endDate: parseDate(belgiumDateTimeToLocal(data.EndDate)), // can be adapted by signalr in case of extended auction.
        isClosed, // changed when timer expire (cfr onCloseAuction action triggered by AuctionCountDown component)
        showClosedAnyway: false,

        //followingCar: data.IsCarWatch,  // is the current user currently following this car.
        followingCar: watches ? watches.indexOf(data.AuctionId) > -1 : false,
        carSavedAsQuery: data.IsFavorite, // has the current car already been saved as query.

        // -- Ultimo related attributes
        isUltimo: data.SupportsLastMinuteBid,
        isInUltimoPhase: data.ShowLastMinuteBid, // flag changed by signalr in case of ultimo auction.

        // -- XTime related attributes

        isXTime,
        isAlphabetLikeXTime,
        isLeasePlanLikeXTime,

        extendedPhaseType: data.ExtendedPhaseType,
        // -1 : no extended phase.
        //  0 : Normal (All auctions go to extended phase. The bach is extended at each bids.)
        //  1 : Only auctions with bids go to extended phase. Timer is based on auction
        //  2 : Like Alphabet. X auctions by x auctions. Time is extended auctions by auctions

        isInExtendedPhase: data.IsInExtendedPhase, // flag changed by signalr in case of extended auction.
        //useXTimeDashboard: model.PnlAuctionExtendedVisible,     // cfr _BidInfo.cshtml,L:104
        //xTimeDashboardUrl: model.LnkExtendedPhaseNavigateUrl,

        isExtendedPhaseFinished: data.IsExtendedPhaseFinished,

        useXTimeDashboard: data.ExtendedPhaseType === 2,
        xTimeDashboardUrl: data.ExtendedPhaseType === 2 ? `/${currentLanguage}/Car/ExtendedBatch/${data.AuctionBatchId}/3` : null,

        showBuyNowFinalizationDialog: false, // dialog to show link for car confirmation

        statusId: data.StatusId,
        isCommunityAuction: !!data.CommunityId,
        communityId: data.CommunityId,
        communityName: data.CommunityName,
        currencyCode: data.CurrencyCodeId || '',
        cap,
        isUKCountry: data.CarEcadisCountryId && data.CarEcadisCountryId == 9, //The seller is handled by the UK office (despite of the origin country of the seller)

        estimatedPrice: data.RequestedSalesPrice,
        is247StockSale: data.BatchAttributeId === 6,
        targetPrice: data.TargetPrice,
        isUserConfirmed: CONFIRMED_AUCTION_STATUSES.includes(data.StatusId),
        IsNonTransactional: !!data.IsNonTransactional,

        isCancelled: data.StatusId === AUCTION_STATUS.CANCELLED,
        maximumBid: data.MaximumBid,
        minimumBidForRspCheck: data.MinimumBidForRspCheck,
        isAggregated: data.IsAggregated,
    };
}

export function bidPriceInfoForCurrentPrice(data) {
    return {
        refreshingPrice: false,

        targetPrice: data.TargetPrice,
        currentPrice: data.CurrentPrice,

        inputPrice: data.NextMaxPrice,
        validInputPrice: true, // to be recalculated based on new inputPrice & inputMinimumPrice & inputIncrement.

        hasUserPlacedBid: data.HasUserPlacedABid, // value:  false,
        isUserHighestBidder: data.IsUserHighestBidder, // value:  false,
        isHighestBidder: data.IsUserHighestBidder, // at company level.
        currentUserMaxPrice: data.BidderHigherBidAmount,

        currentPriceDetails: {
            countryFee: data.ShowCountryCost ? data.CountryCost : null,
            auctionFee: data.ShowAuctionFee ? data.AuctionFee : null,
            mechanicalIndicatorFee: data.ShowMechanicalIndicatorFee ? data.MechanicalIndicatorFee : null,
            sellerSpecificFee: data.ShowSellerSpecificFee ? data.SellerSpecificFee : null,
            bpmAmount: data.ShowBpm ? data.BpmAmount : null,
            subTotal: data.SubTotal,
            vatAmount: data.ShowVat ? data.VatAmount : null,
            total: data.TotalPrice,
            showCountryFeeHint: data.ShowBpmDeregistrationToolTip || false,
            dealerDiscount: data.ShowDealerDiscounts ? data.DealerDiscount : null,
            countryCostDiscount: data.ShowCountryCostDiscount ? data.CountryCostDiscount : null,
            auctionFeeVat: data.ShowAuctionFeeVat ? data.AuctionFeeVat : null,
            mechanicalIndicatorFeeVat: data.ShowMechanicalIndicatorFeeVat ? data.MechanicalIndicatorFeeVat : null,
            vehicleVat: data.ShowVehicleVat ? data.VehicleVat : null,
            isMarginCar: data.IsMagrin,
            isCommercialCar: data.IsCommercial,
            vatRate: data.VatRate,
            baseAmount: data.CurrentPrice,
            isVatIncludedInBidAmount: data.IsVatIncludedInBidAmount, // true=> display "Incl VAT", false=> display "Excl VAT", undefined=> display nothing (EU cars)
            newBasePrice: data.NewBasePrice,
        },

        previousPrice: data.PreviousPrice,
    };
}

export function bidPriceInfoForInputPrice(data) {
    return {
        refreshingPrice: false,
        inputPriceDetails: {
            countryFee: data.ShowCountryCost ? data.CountryCost : null,
            auctionFee: data.ShowAuctionFee ? data.AuctionFee : null,
            mechanicalIndicatorFee: data.ShowMechanicalIndicatorFee ? data.MechanicalIndicatorFee : null,
            sellerSpecificFee: data.ShowSellerSpecificFee ? data.SellerSpecificFee : null,
            bpmAmount: data.ShowBpm ? data.BpmAmount : null,
            subTotal: data.SubTotal,
            vatAmount: data.ShowVat ? data.VatAmount : null,
            total: data.TotalPrice,
            showCountryFeeHint: data.ShowBpmDeregistrationToolTip || false,
            dealerDiscount: data.ShowDealerDiscounts ? data.DealerDiscount : null,
            countryCostDiscount: data.CountryCostDiscount ? data.CountryCostDiscount : null,
            auctionFeeVat: data.ShowAuctionFeeVat ? data.AuctionFeeVat : null,
            mechanicalIndicatorFeeVat: data.ShowMechanicalIndicatorFeeVat ? data.MechanicalIndicatorFeeVat : null,
            vehicleVat: data.ShowVehicleVat ? data.VehicleVat : null,
            isMarginCar: data.IsMagrin,
            isCommercialCar: data.IsCommercial,
            vatRate: data.VatRate,
            baseAmount: data.CurrentPrice,
            isVatIncludedInBidAmount: data.IsVatIncludedInBidAmount, // true=> display "Incl VAT", false=> display "Excl VAT", undefined=> display nothing (EU cars)
            newBasePrice: data.NewBasePrice,
            auctionFeeDiscount: data.ShowAuctionFeeDiscount ? data.AuctionFeeDiscount : null,
        },

        previousPrice: data.PreviousPrice,

        /*
        // Response of http://testcotwcarsite/en/car/bidpriceinfo

        xxx: data.CurrentPrice, // value:  0,
        xxx: data.Increment, // value:  100,
        xxx: data.NextMaxPrice, // value:  16500,
        xxx: data.TargetPrice, // value:  -1,
        xxx: data.BuyNowPrice, // value:  -1,
        xxx: data.IsMagrin, // value:  true,
        xxx: data.IsBlind, // value:  false,
        xxx: data.HasUserPlacedABid, // value:  false,
        xxx: data.IsUserHighestBidder, // value:  false,

        xxx: data.ShowCountryCost, // value:  true,
        xxx: data.CountryCost, // value:  100,
        xxx: data.ShowAuctionFee, // value:  true,
        xxx: data.AuctionFee, // value:  190,
        xxx: data.ShowBpm, // value:  false,
        xxx: data.BpmAmount, // value:  0,
        xxx: data.SubTotal, // value:  3290,
        xxx: data.ShowVat, // value:  true,
        xxx: data.VatAmount, // value:  60.9,
        xxx: data.TotalPrice, // value:  3350.9,

        xxx: data.CurrencyCode, // value:  "EUR",
        xxx: data.ShowConvertedInfo, // value:  false,
        xxx: data.ConvertedCurrentPrice, // value:  0,
        xxx: data.ConvertedCountryCost, // value:  0,
        xxx: data.ConvertedAuctionFee, // value:  0,
        xxx: data.ConvertedBpmAmount, // value:  0,
        xxx: data.ConvertedSubTotal, // value:  0,
        xxx: data.ConvertedVatAmount, // value:  0,
        xxx: data.ConvertedTotalPrice, // value:  0,
        xxx: data.ExchangeRate, // value:  0,

        xxx: data.IsBidWithdrawAllowed, // value:  false
        */
    };
}

export function priceInfoToState(data) {
    let is247StockSale = data.BatchAttributeId && data.BatchAttributeId === 6;
    return {
        hasUserPlacedBid: false,
        isUserHighestBidder: false,

        //currentPrice: model.DispalyPrice,   // Let CurrentPrice come by PriceInfoService
        minimumPrice: data.IsBlind ? 0 : data.CurrentPrice,

        countryFee: data.ShowCountryCost ? data.CountryCost : null,
        auctionFee: data.ShowAuctionFee ? data.AuctionFee : null,
        mechanicalIndicatorFee: data.ShowMechanicalIndicatorFee ? data.MechanicalIndicatorFee : null,
        sellerSpecificFee: data.ShowSellerSpecificFee ? data.SellerSpecificFee : null,
        dealerDiscount: data.ShowDealerDiscounts ? data.DealerDiscount : null,
        bpmAmount: data.ShowBpm ? data.BpmValue : null,
        subTotal: data.SubTotal,
        auctionFeeVat: data.ShowAuctionFeeVat ? data.AuctionFeeVat : null,
        mechanicalIndicatorFeeVat: data.ShowMechanicalIndicatorFeeVat ? data.MechanicalIndicatorFeeVat : null,
        vehicleVat: data.ShowVehicleVat ? data.VehicleVat : null,
        vatAmount: data.ShowVat ? data.VatAmount : null,
        total: data.TotalPrice,

        inputPrice: data.IsBlind ? 0 : is247StockSale ? data.BuyNowPrice : data.NextMaxPrice, // model.InpPrice || 3000,
        inputIncrement: data.Increment || 100,
        inputMinimumPrice: data.IsBlind ? 0 : data.CurrentPrice,
        nextBidPrice: data.IsBlind ? 0 : data.NextMaxPrice,
        bidMethod: data.DefaultBiddingMethod === 2 /* !!!! in db 1 = direct, 2 = stepwise */ ? STEPWISE_BID_METHOD : MAXIMUM_BID_METHOD, // 1: stepwise, 2: maximum (default: 1)

        buyNowVisible: data.IsBuyNow,
        buyNowDisabled: false, // model.BtnBuyNowDisabled,
        buyNowPrice: data.BuyNowPrice,
        //buyNowText: model.BtnBuyNowText,

        withdrawAllowed: data.BidWithdrawalAllowed,
        withdrawTooltip: data.LblWithdrawBidTooltip,

        requestedSalesPrice: data.RequestedSalesPrice,

        validInputPrice: true,
        currencyCode: data.CurrencyCodeId,

        isMarginCar: data.IsMagrin,
        isCommercialCar: data.IsCommercial,

        basePrice: data.NewBasePrice,
        isVatIncludedInBidAmount: data.IsVatIncludedInBidAmount, // true=> display "Incl VAT", false=> display "Excl VAT", undefined=> display nothing (EU cars)
        is247StockSale: is247StockSale,
    };
}

const marketPriceCountriesToShowDefault = ['es', 'pt', 'be', 'fr', 'it', 'lu', 'nl', 'hu', 'ro'];

export function biddingAdvisorToState(data) {
    const marketPriceCountriesToShow = settings.marketPricesCountriesToShow
        ? settings.marketPricesCountriesToShow.split(',')
        : marketPriceCountriesToShowDefault;
    return {
        barometerPrices: data.BarometerPrices || [], // [{"Price":17000,"YHat":0.2979},{"Price":17100,"YHat":0.2978}, .... ]}
        barometerPricesRecommended: data.BarometerRecommendedPrice, //  {MinIndex: 1, MaxIndex: 15, RecommendedIndex: 10}
        marketPricesByCountry: data.MarketPricesByCountry, // "{"be":1165,"es":1165,"pt":1165}"
        //marketPrice: model.MarketPriceForBuyerCountry,                  // specific for the buyer country.
        marketPrice: marketPriceCountriesToShow.indexOf(data.BuyerCountry) >= 0 ? data.MarketPriceForBuyerCountry : undefined,
    };
}

/**
 * Retrieve navigation information from the search state (list of auctions).
 *
 * @param {*} state
 */
export function pageNavigationFromState(state, auctionId) {
    const searchState = state.carSearch;
    const paging = searchState.paging;
    const lang = state.common.currentLanguage;
    const router = state.router;

    let nextAuctionId = null;
    let previousAuctionId = null;
    let indexCurrent = 0;

    if (searchState.auctionsList.length) {
        indexCurrent = searchState.auctionsList.findIndex(a => a.auctionId === auctionId);

        if (indexCurrent > 0) {
            previousAuctionId = searchState.auctionsList[indexCurrent - 1].auctionId;
        }
        if (indexCurrent < searchState.auctionsList.length - 1) {
            nextAuctionId = searchState.auctionsList[indexCurrent + 1].auctionId;
        }
    }
    return {
        // return router actions which will be dispatched to redux by the onNavigationAction action.
        //backToSearchAction:  goBack(),
        //backToSearchAction:  push(`/${lang}/findcar`),
        backToSearchAction: router.action === 'PUSH' ? goBack() : push(`/${lang}/findcar`),
        previousAuctionAction: previousAuctionId ? replace(`/${lang}/car/info?auctionId=${previousAuctionId}`) : null,
        nextAuctionAction: nextAuctionId ? replace(`/${lang}/car/info?auctionId=${nextAuctionId}`) : null,

        totalAuctionsInSearchResult: searchState.auctionsCount,
        indexCurrentAuctionInSearchResult: paging.pageIndex * paging.pageSize + (indexCurrent + 1),
    };
}

/**
 * returns "yes", "no", "unknown"
 * @param {*} data
 */
function isDrivable(data) {
    if (data.IsBroken === true || data.HasTechnicalDamage === true) {
        if (data.IsDriving === true) return 'yes';
        if (data.IsDriving === false) return 'no';
        return 'unknown';
    } else {
        return null;
    }
}

function formatFrontRear(front, rear, unit) {
    if (front || rear) {
        let list = [];
        if (front && typeof front === 'string') list.push(front.replace(/mm/gi, '').trim() + unit + ' (FRONT)');
        if (front && typeof front === 'number') list.push(front.toString() + unit + ' (FRONT)');
        if (rear && typeof rear === 'string') list.push(rear.replace(/mm/gi, '').trim() + unit + ' (REAR)');
        if (rear && typeof rear === 'number') list.push(rear.toString() + unit + ' (REAR)');
        return list.join(', ');
    } else {
        return null;
    }
}

function formatTyreDetails(tyreDetail, unit) {
    let tyresDetails = '';
    if (tyreDetail && typeof tyreDetail === 'string') {
        return (tyresDetails = tyreDetail.replace(/mm/gi, '').trim() + unit);
    }
    if (tyreDetail && typeof tyreDetail === 'number') {
        return (tyreDetail = formatNumber(tyreDetail, 1) + unit);
    }

    return tyresDetails;
}

export function carDetailsToState(data, currentLanguage) {
    return {
        name: data.CarTitleList[currentLanguage] ? data.CarTitleList[currentLanguage] : data.CarName,
        make: data.CotwMake,
        model: data.CotwModel,
        type: data.CotwType,
        registration: data.DateFirstRegistrationFormatted,
        size: data.Size,
        door: data.NumberOfDoors,
        places: data.Places && data.Places !== -1 ? data.Places : null,
        cc: data.CylinderCapacity && data.CylinderCapacity !== -1 ? data.CylinderCapacity : null,
        co2: data.Co2,
        isCo2FromSeller: !!data.IsCo2FromSeller,
        euNorm: data.EffluentStandard,
        chassis: data.ChassisNumber,
        chassisAbr: data.ChassisNumber.substr(data.ChassisNumber.length - 6),
        nbrKeys: data.NumberOfKeys,
        hasMaintenanceBook: data.HasMaintenanceRecord,
        maintenanceRecordComment: data.MaintenanceRecordComment,
        fuel: data.FuelGroup,
        fuelEtgCode: data.FuelTypeId, // cfr table EtgFuelTypes
        kw: data.Kw,
        gearbox: data.GearboxGroup,
        mileage: data.Mileage,
        mileageUnit: isUkEcadisCountry(data.CarEcadisCountryId) ? 'mi' : 'km',
        bodyColor: data.BodyColor,
        equipColor: data.EquipmentColor,
        licensePlace: data.LicensePlate,

        lastMaintenance: data.LastMaintenanceMileage && data.LastMaintenanceMileage !== -1 ? data.LastMaintenanceMileageFormatted : null,
        modelYear: data.ModelYear,
        trimline: data.Trimline,
        fourWheelDrive: !!data.FourWheelDrive,

        fiscalLightTruck: !!data.IsFiscalLightTruck, // display only if yes (TFS489)  contains: "Yes", "Oui"
        isBelgianLightTruck: (data.SizeId === 9 || !!data.IsFiscalLightTruck) && data.OriginalcarLocationCountryId === 'be',
        lastMaintenanceDate:
            data.LastMaintenanceDateFormatted && data.LastMaintenanceDateFormatted !== '01/01/0001' ? data.LastMaintenanceDateFormatted : null,
        production: data.ProductionDateFormatted,
        retail: data.RetailDateFormatted,

        isDamagedCar: data.IsBroken, // body damage ?
        isDrivable: isDrivable(data), // "yes", "no", "unknown"
        hasTechnicalDamage: data.HasTechnicalDamage, // Internal damage (engine, gearbox..)

        hadAccident: data.IsHarmedBefore, // null: "not set", false: "No", true: "Yes"
        inventoryType: data.InventoryTypeId,
        v5Present: data.IsV5Present === true ? 'Yes' : data.IsV5Present === false ? 'No' : null,

        // Tyre details.
        frontDim: data.TireDimensionFront,
        frontUsage: data.TireUsageFront,
        backDim: data.DataCInfoBackDim,
        backUsage: data.TireUsageBack,
        tireSize: formatFrontRear(data.TireDimensionFront, data.TireDimensionBack, 'INCH'),
        tireSizeFront: formatTyreDetails(data.TireDimensionFront, ''), //INCH
        tireSizeRear: formatTyreDetails(data.TireDimensionBack, ''), //INCH
        tireDepthFront: formatTyreDetails(data.TireUsageFront === -1 ? '' : data.TireUsageFront, ' MM'),
        tireDepthRear: formatTyreDetails(data.TireUsageBack === -1 ? '' : data.TireUsageBack, ' MM'),
        tireDepth: formatFrontRear(data.TireUsageFront === -1 ? '' : data.TireUsageFront, data.TireUsageBack === -1 ? '' : data.TireUsageBack, ' MM'),
        treadDepthFrontLeft: formatTyreDetails(data.TreadDepthFrontLeft, ' MM'),
        treadDepthFrontRight: formatTyreDetails(data.TreadDepthFrontRight, ' MM'),
        treadDepthRearLeft: formatTyreDetails(data.TreadDepthRearLeft, ' MM'),
        treadDepthRearRight: formatTyreDetails(data.TreadDepthRearRight, ' MM'),
        spareTireId: data.SpareTireId, //3

        vehicleGradeId: data.VehicleGradeId,
        mechanicalIndicatorId: data.MechanicalIndicatorId, //3
        damageCategoryId: data.DamageCategoryId, //1

        // Origin details:
        physicalLocationOfCar: data.LblPhysicalLocationValue, // Example: "BE Genk (Belgium)"
        originCountryOfCar: data.LblOriginalcarLocationValue, // Example: "France"
        physicalLocationOfCarCountryId: data.PhysicalLocationCountryId, // Example: "be"
        originCountryOfCarCountryId: data.OriginalcarLocationCountryId, // Example: "fr"
        isCarReImported: data.IsCarReImported,

        sellingOffice: data.LblEcadisOriginValue, // Example: "France"
        sellingOfficeCountryId: data.EcadisOriginCountryId, // Example: "fr"
        sellerId: data.Seller ? data.Seller.CompanyId : '',
        sellerName: data.Seller ? data.Seller.Name : '',

        ecadisCountryId: data.CarEcadisCountryId,

        isElectricCarWithoutCables: false,
        isElectricCar: data.FuelGroupSearch === 'Electric',
        COCAvailable: data.COCAvailable,
        RegistrationDocsAvailable: data.RegistrationDocsAvailable,
        warrantyExpDate: data.WarrantyExpirationDate ? data.WarrantyExpirationDate : null,
        warrantyExpMileage: data.WarrantyExpirationMileage ? data.WarrantyExpirationMileage : null,
        technicalInspectionValidityDate: data.TechnicalInspectionValidityDate ? data.TechnicalInspectionValidityDate : null,
        technicalInspectionDate: data.TechnicalInspectionDate ? data.TechnicalInspectionDate : null,

        //vat Regime
        isVatNonDeductable: data.IsMargin,
        hasDamages: data.HasDamages,
        currencyCode: data.CurrencyCodeId,
        isUkCountryCar: isUkEcadisCountry(data.CarEcadisCountryId),
        isCommercialCar: data.IsCommercialCar,
        isMarginCar: data.IsMargin,
        is247StockSale: data.BatchAttributeId === 6,

        //electric cars specific.
        batteryCapacity: data.BatteryCapacity,
        electricRange: data.ElectricRange,
        batteryLeased: data.BatteryLeased,
        electricRangeExtender: data.ElectricRangeExtender,
        acChargePower: data.ACChargePower,
        dcMaxChargePower: data.DCMaxChargePower,
    };
}

const BID_HISTORY_DATE_FORMAT = getFormat('shortDateTime');
const bidHistoryDateToIso = d => formatDate(parseDate(d, BID_HISTORY_DATE_FORMAT), 'iso');

export function bidHistoryToState(data) {
    return {
        date: formatDate(belgiumDateTimeToLocal(bidHistoryDateToIso(data.DateBidPlaced)), BID_HISTORY_DATE_FORMAT),
        price: data.MaxBidPrice,
        isBuyNow: data.IsBuyNowImg,
        user: data.User,
    };
}

export function sellerInfoToState(data) {
    return {
        carRegistrationDocumentsAvailable: data.SellerPickupDetail ? data.SellerPickupDetail.RegistrationCardAvailable > 0 : false,
        europeanCocAvailable: data.SellerPickupDetail ? data.SellerPickupDetail.CocAvailable > 0 : false,
    };
}

export function signalrAuctionDataToStatePriceInfo(data, currentUserContactId, isBlind) {
    /* data=
        AuctionBatchId : 0 
        AuctionId : 2915340
        BatchEndDate : "0001-01-01T00:00:00"
        CarId : 1919263
        ContactId : 19866
        CurrentPrice : 15100
        HighestBidderId : 19866
        LastUpdated : "2018-08-31T14:14:59.037+02:00"
        MaxPersonalPrice : 15100
        NextMaxPrice : 15200
     */
    return isBlind
        ? {
              // !!!! signalr is returning the current price for blind too !!!
              isUserHighestBidder: data.HighestBidderId === currentUserContactId,
              isHighestBidder: data.HighestBidderId === currentUserContactId,
          }
        : {
              currentPrice: data.CurrentPrice,
              isUserHighestBidder: data.HighestBidderId === currentUserContactId,
              isHighestBidder: data.HighestBidderId === currentUserContactId,
              inputPrice: data.NextMaxPrice,
              currentUserMaxPrice: data.MaxPersonalPrice,
          };
}

export function bidWithdrawInfoToStateAuctionInfo(data) {
    return {
        bidWithdrawalAllowed: data.allowed,
        bidWithdrawPopupLabel: data.linkLabel,
    };
}

export function similarCarsToState(data) {
    return data.map(d => ({
        auctionId: d.auctionId,
        thumbnailUrl: settings.imagesBaseUrl + d.carId + '/general/tn_thumbnail.jpg',
        tagLine: `${d.make} ${d.model} - ${d.fuelGroup}`,
        mileage: mileageFormat(d.mileage, d.mileageTypeId),
        registrationDate: formatDate(d.registrationDateTime, 'short'),
        reference: d.reference,
        prices: d.Prices,
        currencyCodeId: d.CurrencyCodeId,
        mileageTypeId: d.mileageTypeId,
    })) /*.slice(0,10).map((a,i) => ({...a, mileage: i+1}))*/;
    /*
        data = [
            {
                "auctionId": 3021186,
                "make": "Opel",
                "model": "Zafira",
                "chassisNumber": "W0LPD9E35E2060957",
                "mileage": 135800,
                "bodyType": "Multi Purpose Vehicle (MPV)",
                "fuelGroup": "Diesel",
                "endBatchDateTime": "2018-09-28T14:10:00",
                "registrationDateTime": "2014-03-21T00:00:00"
            },
            ....
            ]
    */
}

export function favouriteCarToState(data) {
    return {
        currentCarFuel: data.FuelGroup,
        currentCarMileage: data.Mileage,
        currentCarOrigin: data.CarCountryExtend,
        currentFirstRegistration: getYear(data.DateFirstRegistration),
        currentCarPrice: data.CurrentPrice,
    };
    // '@Model.FuelTypeId', '@Model.Mileage', '@Model.SizeName', '@Model.Make', '@Model.CarCountryExtend', '@Model.DateFirstRegistration.Year', '@(Model.CurrentPrice == 0 ? Model.MinimumPrice : Model.CurrentPrice)'
}

const importantMessagesForCarDocsAndHistory = [
    'DocDeliveryDelay.Spain',

    'IsCocAvailable.french',
    'IsCocAvailable.Germany',
    'IsCocAvailable.Italia',
    'IsCocAvailable.Belgium',
    'IsCocAvailable.Serbia',
    'IsCocAvailable.No',
    'IsCocAvailable.Yes',

    'IsDocDeliveryWithCar.7',
    'IsDocDeliverywithCar.French',
    'IsDocDeliveryWithCar.Italia',
    'IsDocDeliveryWithCar.No',

    'IsRegDocAvailable.Belgium',
    'IsRegDocAvailable.Italia',
    'IsRegDocAvailable.Luxemburg',
    'IsRegDocAvailable.Netherlands',
    'IsRegDocAvailable.No',
    'IsRegDocAvailable.Yes',
    'IsRegDocAvailable.Serbia',
    'IsRegDocAvailable.Spain',
    'LPG.Netherlands',
];

const importantMessagesForDeliveryOptions = ['ExtraDeliveryInfo.Spain', 'PickUP.italia', 'DeliveryDelay.7'];

// cfr table EtgFuelTypes
const isLPG = fuelEtgCode => [100006, 100007, 107006].indexOf(fuelEtgCode) > -1;
const isCNG = fuelEtgCode => [107003, 107004, 107005].indexOf(fuelEtgCode) > -1;

export function importantMessagesToState(data, fuelEtgCode) {
    const allKeys = data.map(a => a.name + '.' + a.value);
    // message will be displayed in 2 different sections: so separate them...
    const result = {
        forCarDocsAndHistory: allKeys.filter(a => importantMessagesForCarDocsAndHistory.indexOf(a) >= 0),
        forDeliveryOptions: allKeys.filter(a => importantMessagesForDeliveryOptions.indexOf(a) >= 0),
    };

    // cfr https://karauctionservices.visualstudio.com/International/_workitems/edit/76684
    const idxLPG = result.forCarDocsAndHistory.indexOf('LPG.Netherlands');
    if (idxLPG > -1) {
        if (isCNG(fuelEtgCode)) {
            // replace by CNG.
            result.forCarDocsAndHistory.splice(idxLPG, 1, 'CNG.Netherlands');
        } else {
            if (!isLPG(fuelEtgCode)) {
                // remove message if car is not LPG or CNG
                result.forCarDocsAndHistory.splice(idxLPG, 1);
            }
        }
    }
    return result;
}

export function transportOptionsToState(data) {
    /*if (data && data.ownTransport) {
        data.ownTransport.price = 40; // cfr DEVA-24114  (to be changed on server side.)
    }*/ // Added on server side...
    return data;
}

export function fixDamageReportsUrls(resp, currentLanguage) {
    const lang = currentLanguage.slice(0, 2);
    resp.ReportItems.forEach(ri => {
        if (ri.Url) {
            // https://gemini.carsontheweb.com/workspace/407/item/24185 (Macadam report contain url query param value with "=", to be escaped)
            ri.Url = ri.Url.replace(/assessmentFileId=([^&]+)/i, (_, val) => 'assessmentFileId=' + encodeURIComponent(val));

            // append language for multi-lingual reports.
            if (ri.IsMultiLanguage) {
                //ri.Url = ri.Url +  lang;
                // cannot check if Url exists for language (CORS issue) => quick fix: return at least english report.
                ri.Url = ri.Url + 'en';
            }
        }
    });
}

export function carAsSearchCriteriaToState(data) {
    const match = data.DateFirstRegistrationFormatted.match(/(\d+)$/);
    const registrationYear = match ? parseInt(match[1], 10) : 0;
    const mileagesList = mileages(i18n.t.bind(i18n)).map(a => a.id);

    const bodyTypes = [];
    const matchSize = /carsize.([A-zÀ-ÿ]+)/.exec(data.SizeName);
    if (matchSize) bodyTypes.push(matchSize[1]);

    return {
        makesModels: [{ make: data.Make, models: [data.CarIdentification.Model] }],
        prices: { from: 0, to: 0 },
        mileages: fromToInList(mileagesList, data.Mileage),
        registrationYears: { from: registrationYear, to: registrationYear },
        engineSizes: { from: 0, to: 0 },
        horsePowers: { from: 0, to: 0 },
        kWs: { from: 0, to: 0 },
        co2: { from: 0, to: 0 },
        bodyTypes,
        fuelTypes: [data.FuelGroupSearch],
        originCountries: [data.OriginalcarLocationCountryId],
        transmissionTypes: [data.CarIdentification.GearboxGroup],
        effluentStandards: [],
        bodyColors: [],
        vatRegimes: [],
        damageTypes: [],
        equipments: [],
        auctionTypes: [],
        sellers: [],
        sellerGroups: [],
        xTimes: [],
        isCarOnStock: [],
        assignment100pc: [],
        currentAuctionBatchId: null,
        auctionIds: [],
        currentCommunityId: null,
        premiumOffer: [],
        isLocalDealerCar: [],
        vehicleGrades: [],
        mechanicalIndicators: [],
        places: [],
        vehicleLocation: null,
        auctionsChannel: [],
    };
}
