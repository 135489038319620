import { useEffect, useState } from 'react';

import settings, { settingsObservable } from '../appsettings';

export const useAppSettings = () => {
    const [appSettings, setAppSettings] = useState(settings);

    useEffect(() => {
        settingsObservable.subscribe(value => {
            // delay updating local settings state until we're done loading
            if (value.isLoading) return;
            setAppSettings(value);
        });
        return () => settingsObservable.unsubscribe(setAppSettings);
    }, []);

    return appSettings;
};
