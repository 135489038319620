import React, { useState } from 'react';
import { parsedQueryString, extrapolate } from '../utils';
import './CommunityInvite.less';
import LoginButton from '../components/Layout/TopNavigation/LoginButton';
import { AdesaButton } from '../components/Common/ui-components';
import { Spinner } from '@ambiorix/adesa-web-kit';

const queryString = window.location.search;
const { guid = '' } = new parsedQueryString(queryString);

const communityInviteKey = 'ADESA:community:invitationGUID';

window.sessionStorage.setItem(communityInviteKey, guid);

export default function CommunityInvite({
    goToHome,
    communityInvite,
    isUserLoggedIn,
    currentLanguage,
    acceptInvite,
    currentUser,
    loadingCommunities,
    t,
}) {
    const [radioState, setRadioState] = useState(null);

    const onSubmit = e => {
        e.preventDefault();
        if (radioState === 'no') {
            goToHome();
            return;
        }
        acceptInvite();
    };

    if (loadingCommunities) {
        return (
            <div className="rc-community-invite">
                <Spinner />
            </div>
        );
    }

    if (!communityInvite) {
        return (
            <div className="rc-community-invite">
                <button className="header-logo-button" onClick={goToHome}>
                    <img className="openlane-logo" src="/v6/images/openlane-logo.svg" title="Openlane" alt="Openlane" />
                </button>
                <h1>Community not found</h1>
            </div>
        );
    }

    const goToRegistrationForm = evt => (document.location = `/${currentLanguage}/register`);

    return (
        <div className="rc-community-invite">
            <button className="header-logo-button" onClick={goToHome}>
                <img className="openlane-logo" src="/v6/images/openlane-logo.svg" title="Openlane" alt="Openlane" />
            </button>

            {isUserLoggedIn ? (
                <div className="container">
                    <h1>
                        {currentUser && currentUser.isMainContact
                            ? extrapolate(t('communityInvite.doYouAgreeToJoin'), communityInvite.name)
                            : t('communityInvite.sorryYouDoNot')}
                    </h1>
                    <div className="community-info">
                        <img className="banner" src={communityInvite.coverPictureUrl} alt={communityInvite.coverPictureName} />
                        <img className="avatar" src={communityInvite.profilePictureUrl} alt={communityInvite.profilePictureName} />
                        <div className="name">{communityInvite.name}</div>
                    </div>
                    {currentUser && currentUser.isMainContact ? (
                        <>
                            <h2>{extrapolate(t('communityInvite.byJoiningYouWill'), communityInvite.name)}</h2>
                            <form onSubmit={event => onSubmit(event)}>
                                <ul>
                                    <li>
                                        <input type="radio" id="yesJoin" name="communityAnswer" value="yes" onChange={() => setRadioState('yes')} />
                                        <label htmlFor="yesJoin">{t('communityInvite.yesIWantToJoin')}</label>
                                    </li>
                                    <li>
                                        <input type="radio" id="noLater" name="communityAnswer" value="no" onChange={() => setRadioState('no')} />
                                        <label htmlFor="noLater">{t('communityInvite.noMaybeLater')}</label>
                                    </li>
                                </ul>
                                <button type="submit" className="rc-button submit-button">
                                    {t('communityInvite.continue')}
                                </button>
                                {/* <AdesaButton id="sumbmitBtn4" size="secondary" type="submit" color="positive">
                                    {t('communityInvite.continue')}
                                </AdesaButton> */}
                            </form>
                        </>
                    ) : (
                        <h2>{t('communityInvite.pleaseContactAdmin')}</h2>
                    )}
                </div>
            ) : (
                <div className="container">
                    <h1>{extrapolate(t('communityInvite.loginOrRegister'), communityInvite.name)}</h1>
                    <div className="community-info">
                        <img className="banner" src={communityInvite.coverPictureUrl} alt={communityInvite.name} />
                        <img className="avatar" src={communityInvite.profilePictureUrl} alt={communityInvite.name} />
                        <div className="name">{communityInvite.name}</div>
                    </div>
                    <div className="registration-info">
                        <h2>{t('communityInvite.pleaseClick')}</h2>
                    </div>
                    <div className="rc-invite-options">
                        <div>
                            <h3>{extrapolate(t('communityInvite.alreadyMember'))}</h3>
                            <LoginButton currentLanguage={currentLanguage} t={t} />
                        </div>
                        <div>
                            <h3>{t('communityInvite.noAccount')}</h3>
                            <AdesaButton id="registerBtn4" size="secondary" color="positive" onClick={goToRegistrationForm}>
                                {t('login.button.register')}
                            </AdesaButton>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
