import React from 'react';
import { Helmet } from 'react-helmet';
import './PrivacyNotice.less';

function PrivacyNotice({ t }) {
    return (
        <div className="privacy-notice-page">
            <h1>{t('footer.privacyPolicy')}</h1>
            <div id="otnotice-45bb6f1f-634c-4539-98ac-a2ee9f6b3522" className="otnotice"></div>
            <Helmet>
                <script type="text/javascript" charSet="UTF-8">{`
                    OneTrust.NoticeApi.Initialized.then(function() {
                        OneTrust.NoticeApi.LoadNotices(["https://privacyportal-cdn.onetrust.com/220aba37-6609-45b9-bb38-d90c71628891/privacy-notices/45bb6f1f-634c-4539-98ac-a2ee9f6b3522.json"]);
                    });
                `}</script>
            </Helmet>
        </div>
    );
}

export default PrivacyNotice;
