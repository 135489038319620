// ===== REDUX ACTIONS ====================================
export const SHOW_AUCTION = 'CARDETAIL:SHOW_AUCTION';
export const REFRESH_AUCTION = 'CARDETAIL:REFRESH_AUCTION';
export const LOADING = 'CARDETAIL:LOADING';
export const NEW_EQUIPMENTS = 'CARDETAIL:NEW_EQUIPMENTS';
export const SELECT_DETAIL_TAB = 'CARDETAIL:SELECT_DETAIL_TAB';
export const NEW_BID_AMOUNT = 'CARDETAIL:NEW_BID_AMOUNT';
export const NEW_BID_METHOD = 'CARDETAIL:NEW_BID_METHOD';
export const DISMISS_MESSAGE = 'CARDETAIL:DISMISS_MESSAGE';
export const SUBMITTING_BID = 'CARDETAIL:SUBMITTING_BID';
export const SUBMIT_BID = 'CARDETAIL:SUBMIT_BID';
export const SUBMITTING_BUY_NOW_BID = 'CARDETAIL:SUBMITTING_BUY_NOW_BID';
export const SUBMIT_BUYNOW_BID = 'CARDETAIL:SUBMIT_BUYNOW_BID';
export const REFRESHING_PRICE_INFO = 'CARDETAIL:REFRESHING_PRICE_INFO';
export const NEW_PRICE_INFO = 'CARDETAIL:NEW_PRICE_INFO';
export const NEW_BID_HISTORY = 'CARDETAIL:NEW_BID_HISTORY';
export const NEW_SELLER_INFO = 'CARDETAIL:NEW_SELLER_INFO';
export const NEW_CAR_DAMAGES = 'CARDETAIL:NEW_CAR_DAMAGES';
export const NEW_SIMILAR_CARS = 'CARDETAIL:NEW_SIMILAR_CARS';
export const NEW_COUNTRY_MESSAGES = 'CARDETAIL:NEW_COUNTRY_MESSAGES';
export const NEW_SELLER_MESSAGES = 'CARDETAIL:NEW_SELLER_MESSAGES';
export const WITHDRAW_BID = 'CARDETAIL:WITHDRAW_BID';
export const NEW_TRANSPORT_OPTIONS = 'CARDETAIL:NEW_TRANSPORT_OPTIONS';
export const NEW_SELLER_SCORES = 'CARDETAIL:NEW_SELLER_SCORES';
export const SIGNALR_BID = 'CARDETAIL:SIGNALR_BID';
export const NEW_BID_WITHDRAW_INFO = 'CARDETAIL:NEW_BID_WITHDRAW_INFO';
export const CLOSE_AUCTION = 'CARDETAIL:CLOSE_AUCTION';
export const AUCTION_EXTENDED = 'CARDETAIL:AUCTION_EXTENDED';
export const AUCTION_CLOSE_EXTENDED = 'CARDETAIL:AUCTION_CLOSE_EXTENDED';
export const NEW_CAR_DAMAGE_REPORTS = 'CARDETAIL:NEW_CAR_DAMAGE_REPORTS';
export const SHOW_SOLD_CAR_DETAILS = 'CARDETAIL:SHOW_SOLD_CAR_DETAILS';
export const CAR_SAVED_AS_QUERY = 'CARDETAIL:CAR_SAVED_AS_QUERY';
export const DISMIS_BUYNOW_FINALIZATION_DIALOG = 'CARDETAIL:DISMIS_BUYNOW_FINALIZATION_DIALOG';
export const SHOW_CLOSED_AUCTION_DETAILS = 'CARDETAIL:SHOW_CLOSED_AUCTION_DETAILS';
export const NEW_SERVER_TIME = 'CARDETAIL:NEW_SERVER_TIME';
export const CHANGE_CAR_AS_SEARCH_CRITERIA = 'CARDETAIL:CHANGE_CAR_AS_SEARCH_CRITERIA';
export const XTIME_MESSAGE = 'CARDETAIL:XTIME_MESSAGE';
export const LOADING_TRANSPORT_OPTIONS = 'CARDETAIL:LOADING_TRANSPORT_OPTIONS';
