import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ResizedIFrame from '../components/ui/ResizedIFrame';

import './RegistrationForm.less';

export default function RegistrationForm({ match, t, currentLanguage }) {
    return (
        <Container fluid={false}>
            <Row>
                <Col className="rc-registration-form">
                    <h1>Registration form</h1>
                    <ResizedIFrame url={`/${currentLanguage}/signup/index?frameless`} />
                </Col>
            </Row>
        </Container>
    );
}
