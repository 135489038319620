import React from 'react';

import { FooterMini } from '@ambiorix/adesa-web-kit';

import styles from './Footer.module.scss';

import { actionLink } from '../../utils';

type FooterProps = {
    currentLanguage?: string;
    t: (key: string) => string;
};

const Footer = ({ currentLanguage, t }: FooterProps) => {
    const copyright = (
        <span>
            &copy; {new Date().getFullYear()} {t('common.brand.name')}. {t('common.rightsReserved')}
        </span>
    );

    const links = (
        <>
            {actionLink('termsAndConditions', currentLanguage, t)}
            {actionLink('privacyPolicy', currentLanguage, t)}
            {actionLink('howToRegister', currentLanguage, t, 'help')}
        </>
    );

    return <FooterMini className={styles.footer} variant="light" copyright={copyright} links={links} />;
};

export default Footer;
