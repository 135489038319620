import React from 'react';
import { Title, DetailLine, LinesGroup, translateFrontRear, displaySpareTyres } from './Common';

export const TyresSection = ({ t, carDetails }) => {
    let showGlobalTitle =
        carDetails &&
        (carDetails.treadDepthFrontLeft ||
            carDetails.treadDepthFrontRight ||
            carDetails.tireSize ||
            carDetails.tireDepth ||
            carDetails.treadDepthRearRight ||
            carDetails.treadDepthRearLeft);

    return (
        <div>
            {showGlobalTitle || carDetails.spareTireId ? <Title label={t('vehicleDetails.profile.tyres')} /> : ''}
            <LinesGroup>
                {carDetails.tireSize && (
                    <>
                        <Title label={t('vehicleDetails.profile.TyreSize.heading')} showColor />
                        <DetailLine label={t('vehicleDetails.profile.mainTyreSizeFront')} value={translateFrontRear(carDetails.tireSizeFront, t)} />
                        <DetailLine label={t('vehicleDetails.profile.mainTyreSizeRear')} value={translateFrontRear(carDetails.tireSizeRear, t)} />
                    </>
                )}
                {showGlobalTitle && <Title label={t('vehicleDetails.profile.tyreTreadDepth')} showColor />}
                {carDetails.treadDepthFrontLeft && (
                    <DetailLine
                        label={t('vehicleDetails.profile.treadDepthFrontLeft')}
                        value={translateFrontRear(carDetails.treadDepthFrontLeft, t)}
                    />
                )}
                {carDetails.treadDepthFrontRight && (
                    <DetailLine
                        label={t('vehicleDetails.profile.treadDepthFrontRight')}
                        value={translateFrontRear(carDetails.treadDepthFrontRight, t)}
                    />
                )}
                {carDetails.treadDepthRearLeft && (
                    <DetailLine label={t('vehicleDetails.profile.treadDepthRearLeft')} value={translateFrontRear(carDetails.treadDepthRearLeft, t)} />
                )}
                {carDetails.treadDepthRearRight && (
                    <DetailLine
                        label={t('vehicleDetails.profile.treadDepthRearRight')}
                        value={translateFrontRear(carDetails.treadDepthRearRight, t)}
                    />
                )}
                {carDetails.tireDepth && (
                    <>
                        <DetailLine label={t('vehicleDetails.profile.treadDepthFront')} value={translateFrontRear(carDetails.tireDepthFront, t)} />
                        <DetailLine label={t('vehicleDetails.profile.treadDepthRear')} value={translateFrontRear(carDetails.tireDepthRear, t)} />
                    </>
                )}
                {carDetails.spareTireId && (
                    <>
                        <Title label={t('vehicleDetails.profile.spareTyres.heading')} showColor />
                        <DetailLine label={t('vehicleDetails.profile.spareTyresType')} value={displaySpareTyres(carDetails.spareTireId, t)} />
                    </>
                )}
            </LinesGroup>
        </div>
    );
};
