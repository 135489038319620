/**
 * SEO routes mapped to "normal" search route
 */
export const seoRoutes = [
    { f: '/bg/body-type/kamion', t: '/bg/findcar?bodyTypes=Truck&cmsid=Truck' },
    {
        f: '/bg/car-auction/100-razpredelyane',
        t: '/bg/findcar?assignment100pc=true&cmsid=100assignment',
    },
    {
        f: '/bg/car-auction/dinamichen',
        t: '/bg/findcar?auctionTypes=1&cmsid=dynamic',
    },
    {
        f: '/bg/car-auction/kupi-sega',
        t: '/bg/findcar?auctionTypes=2&cmsid=buy-now',
    },
    {
        f: '/bg/car-auction/na-slyapo',
        t: '/bg/findcar?auctionTypes=4&cmsid=blind',
    },
    {
        f: '/bg/car-auction/tselevi',
        t: '/bg/findcar?auctionTypes=5&cmsid=target',
    },
    {
        f: '/bg/body-type/kombi',
        t: '/bg/findcar?bodyTypes=Berline&cmsid=SEO_Berline',
    },
    {
        f: '/bg/body-type/break',
        t: '/bg/findcar?bodyTypes=Break&cmsid=SEO_Break',
    },
    {
        f: '/bg/body-type/kabriolet',
        t: '/bg/findcar?bodyTypes=Cabriolet&cmsid=SEO_Cabrio',
    },
    {
        f: '/bg/body-type/kompakten',
        t: '/bg/findcar?bodyTypes=Compact&cmsid=SEO_Compact',
    },
    {
        f: '/bg/body-type/kupe',
        t: '/bg/findcar?bodyTypes=Coup%C3%A9&cmsid=SEO_Coup%C3%A9',
    },
    {
        f: '/bg/body-type/hechbek',
        t: '/bg/findcar?bodyTypes=Hatchback&cmsid=SEO_Hatchback',
    },
    {
        f: '/bg/body-type/lek-kamion',
        t: '/bg/findcar?bodyTypes=Lighttruck&cmsid=SEO_Light-truck',
    },
    {
        f: '/bg/body-type/minibus',
        t: '/bg/findcar?bodyTypes=Minibus&cmsid=Minibus',
    },
    {
        f: '/bg/body-type/motoretka',
        t: '/bg/findcar?bodyTypes=Motorbike&cmsid=Motorbike',
    },
    { f: '/bg/body-type/mpv', t: '/bg/findcar?bodyTypes=MPV&cmsid=SEO_MPV' },
    { f: '/bg/body-type/suv', t: '/bg/findcar?bodyTypes=SUV&cmsid=SEO_SUV' },
    {
        f: '/bg/car-auction/no-damages',
        t: '/bg/findcar?damageTypes=1&cmsid=no_specific-Damages',
    },
    {
        f: '/bg/car-auction/accident-cars',
        t: '/bg/findcar?damageTypes=2&cmsid=Body-damages',
    },
    {
        f: '/bg/car-auction/technically-damaged-cars',
        t: '/bg/findcar?damageTypes=3&cmsid=technical-damages',
    },
    {
        f: '/bg/car-auction/technical-and-body-damages',
        t: '/bg/findcar?damageTypes=4&cmsid=Technical-and-body-damages',
    },
    {
        f: '/bg/avtomobili-marka/abarth',
        t: '/bg/findcar?makesModels=Abarth&cmsid=SEO_Abarth',
    },
    {
        f: '/bg/avtomobili-marka/alfa-romeo',
        t: '/bg/findcar?makesModels=Alfa%20Romeo&cmsid=SEO_alfa-romeo',
    },
    {
        f: '/bg/avtomobili-marka/audi/a1',
        t: '/bg/findcar?makesModels=Audi%2CA1&cmsid=Audi_A1_Text',
    },
    {
        f: '/bg/avtomobili-marka/audi/a2',
        t: '/bg/findcar?makesModels=Audi%2CA2&cmsid=Audi_A2_Text',
    },
    {
        f: '/bg/avtomobili-marka/audi/a3',
        t: '/bg/findcar?makesModels=Audi%2CA3&cmsid=Audi_A3_Text',
    },
    {
        f: '/bg/avtomobili-marka/audi/a4',
        t: '/bg/findcar?makesModels=Audi%2CA4&cmsid=Audi_A4_Text',
    },
    {
        f: '/bg/avtomobili-marka/audi/a5',
        t: '/bg/findcar?makesModels=Audi%2CA5&cmsid=Audi_A5_Text',
    },
    {
        f: '/bg/avtomobili-marka/audi/a6',
        t: '/bg/findcar?makesModels=Audi%2CA6&cmsid=Audi_A6_Text',
    },
    {
        f: '/bg/avtomobili-marka/audi/q3',
        t: '/bg/findcar?makesModels=Audi%2CQ3&cmsid=Audi_Q3_Text',
    },
    {
        f: '/bg/avtomobili-marka/audi/q5',
        t: '/bg/findcar?makesModels=Audi%2CQ5&cmsid=Audi_Q5_Text',
    },
    {
        f: '/bg/avtomobili-marka/audi/q7',
        t: '/bg/findcar?makesModels=Audi%2CQ7&cmsid=Audi_Q7_Text',
    },
    {
        f: '/bg/avtomobili-marka/audi',
        t: '/bg/findcar?makesModels=Audi&cmsid=SEO_Audi',
    },
    {
        f: '/bg/avtomobili-marka/bmw/seriya-1',
        t: '/bg/findcar?makesModels=BMW%2C1%20Series&cmsid=BMW_1series_Text',
    },
    {
        f: '/bg/avtomobili-marka/bmw/seriya-2',
        t: '/bg/findcar?makesModels=BMW%2C2%20Series&cmsid=BMW_2series_Text',
    },
    {
        f: '/bg/avtomobili-marka/bmw/seriya-3',
        t: '/bg/findcar?makesModels=BMW%2C3%20Series&cmsid=BMW_3series_Text',
    },
    {
        f: '/bg/avtomobili-marka/bmw/seriya-4',
        t: '/bg/findcar?makesModels=BMW%2C4%20Series&cmsid=BMW_4series_Text',
    },
    {
        f: '/bg/avtomobili-marka/bmw/seriya-5',
        t: '/bg/findcar?makesModels=BMW%2C5%20Series&cmsid=BMW_5series_Text',
    },
    {
        f: '/bg/avtomobili-marka/bmw/x1',
        t: '/bg/findcar?makesModels=BMW%2CX1&cmsid=BMW_X1_Text',
    },
    {
        f: '/bg/avtomobili-marka/bmw/x3',
        t: '/bg/findcar?makesModels=BMW%2CX3&cmsid=BMW_X3_Text',
    },
    {
        f: '/bg/avtomobili-marka/bmw/x5',
        t: '/bg/findcar?makesModels=BMW%2CX5&cmsid=BMW_X5_Text',
    },
    {
        f: '/bg/avtomobili-marka/bmw/x6',
        t: '/bg/findcar?makesModels=BMW%2CX6&cmsid=BMW_X6_Text',
    },
    {
        f: '/bg/avtomobili-marka/bmw',
        t: '/bg/findcar?makesModels=BMW&cmsid=SEO_BMW',
    },
    {
        f: '/bg/avtomobili-marka/citroen',
        t: '/bg/findcar?makesModels=Citro%C3%ABn%2CBerlingo&cmsid=Citro%C3%ABn_Berlingo_text',
    },
    {
        f: '/bg/avtomobili-marka/citroen/c3',
        t: '/bg/findcar?makesModels=Citro%C3%ABn%2CC3&cmsid=Citro%C3%ABn_C3_text',
    },
    {
        f: '/bg/avtomobili-marka/citroen/c4',
        t: '/bg/findcar?makesModels=Citro%C3%ABn%2CC4&cmsid=Citro%C3%ABn_C4_text',
    },
    {
        f: '/bg/avtomobili-marka/citroen/c5',
        t: '/bg/findcar?makesModels=Citro%C3%ABn%2CC5&cmsid=Citro%C3%ABn_C5_text',
    },
    {
        f: '/bg/avtomobili-marka/citroen/ds3',
        t: '/bg/findcar?makesModels=Citro%C3%ABn%2CDS3&cmsid=Citro%C3%ABn_DS3_text',
    },
    {
        f: '/bg/avtomobili-marka/citroen/ds4',
        t: '/bg/findcar?makesModels=Citro%C3%ABn%2CDS4&cmsid=Citro%C3%ABn_DS4_text',
    },
    {
        f: '/bg/avtomobili-marka/citroen/dispatch',
        t: '/bg/findcar?makesModels=Citro%C3%ABn%2CJumpy&cmsid=Citro%C3%ABn_Dispatch_text',
    },
    {
        f: '/bg/avtomobili-marka/citroen',
        t: '/bg/findcar?makesModels=Citro%C3%ABn&cmsid=SEO_Citro%C3%ABn',
    },
    {
        f: '/bg/avtomobili-marka/fiat/500',
        t: '/bg/findcar?makesModels=Fiat%2C500&cmsid=Fiat_500_Text',
    },
    {
        f: '/bg/avtomobili-marka/fiat/bravo',
        t: '/bg/findcar?makesModels=Fiat%2CBravo&cmsid=Fiat_bravo_Text',
    },
    {
        f: '/bg/avtomobili-marka/fiat/doblo',
        t: '/bg/findcar?makesModels=Fiat%2CDoblo&cmsid=Fiat_doblo_Text',
    },
    {
        f: '/bg/avtomobili-marka/fiat/ducato',
        t: '/bg/findcar?makesModels=Fiat%2CDucato&cmsid=Fiat_ducato_Text',
    },
    {
        f: '/bg/avtomobili-marka/fiat/freemont',
        t: '/bg/findcar?makesModels=Fiat%2CFreemont&cmsid=Fiat_freemont_Text',
    },
    {
        f: '/bg/avtomobili-marka/fiat/panda',
        t: '/bg/findcar?makesModels=Fiat%2CPanda&cmsid=Fiat_panda_Text',
    },
    {
        f: '/bg/avtomobili-marka/fiat/punto',
        t: '/bg/findcar?makesModels=Fiat%2CPunto&cmsid=Fiat_punto_Text',
    },
    {
        f: '/bg/avtomobili-marka/fiat',
        t: '/bg/findcar?makesModels=Fiat&cmsid=SEO_Fiat',
    },
    {
        f: '/bg/avtomobili-marka/ford/fiesta',
        t: '/bg/findcar?makesModels=Ford%2CFiesta&cmsid=Ford_Fiesta_Text',
    },
    {
        f: '/bg/avtomobili-marka/ford/focus',
        t: '/bg/findcar?makesModels=Ford%2CFocus&cmsid=Ford_Focus_Text',
    },
    {
        f: '/bg/avtomobili-marka/ford/mondeo',
        t: '/bg/findcar?makesModels=Ford%2CMondeo&cmsid=Ford_Mondeo_Text',
    },
    {
        f: '/bg/avtomobili-marka/ford/transit',
        t: '/bg/findcar?makesModels=Ford%2CTransit&cmsid=Ford_Transit_Text',
    },
    {
        f: '/bg/avtomobili-marka/ford',
        t: '/bg/findcar?makesModels=Ford&cmsid=SEO_Ford',
    },
    {
        f: '/bg/avtomobili-marka/lancia',
        t: '/bg/findcar?makesModels=Lancia&cmsid=SEO_Lancia',
    },
    {
        f: '/bg/avtomobili-marka/mercedes/a-klasa',
        t: '/bg/findcar?makesModels=Mercedes-Benz%2CA&cmsid=Mercedes_A-class_Text',
    },
    {
        f: '/bg/avtomobili-marka/mercedes/b-klasa',
        t: '/bg/findcar?makesModels=Mercedes-Benz%2CB&cmsid=Mercedes_B-class_Text',
    },
    {
        f: '/bg/avtomobili-marka/mercedes/c-klasa',
        t: '/bg/findcar?makesModels=Mercedes-Benz%2CC&cmsid=Mercedes_C-class_Text',
    },
    {
        f: '/bg/avtomobili-marka/mercedes/e-klasa',
        t: '/bg/findcar?makesModels=Mercedes-Benz%2CE&cmsid=Mercedes_E-class_Text',
    },
    {
        f: '/bg/avtomobili-marka/mercedes/sprinter',
        t: '/bg/findcar?makesModels=Mercedes-Benz%2CSprinter&cmsid=Mercedes_Sprinter_Text',
    },
    {
        f: '/bg/avtomobili-marka/mercedes/vito',
        t: '/bg/findcar?makesModels=Mercedes-Benz%2CVito&cmsid=Mercedes_Vito_Text',
    },
    {
        f: '/bg/avtomobili-marka/mercedes',
        t: '/bg/findcar?makesModels=Mercedes-Benz&cmsid=SEO_Mercedes',
    },
    {
        f: '/bg/avtomobili-marka/nissan/juke',
        t: '/bg/findcar?makesModels=Nissan%2CJuke&cmsid=Nissan_Juke_Text',
    },
    {
        f: '/bg/avtomobili-marka/nissan/micra',
        t: '/bg/findcar?makesModels=Nissan%2CMicra&cmsid=Nissan_micra_Text',
    },
    {
        f: '/bg/avtomobili-marka/nissan/pathfinder',
        t: '/bg/findcar?makesModels=Nissan%2CPathfinder&cmsid=Nissan_Pathfinder_Text',
    },
    {
        f: '/bg/avtomobili-marka/nissan/qashqai',
        t: '/bg/findcar?makesModels=Nissan%2CQashqai&cmsid=Nissan_Qashqai_Text',
    },
    {
        f: '/bg/avtomobili-marka/nissan/x-trail',
        t: '/bg/findcar?makesModels=Nissan%2CX-Trail&cmsid=Nissan_x-trail_Text',
    },
    {
        f: '/bg/avtomobili-marka/nissan',
        t: '/bg/findcar?makesModels=Nissan&cmsid=SEO_Nissan',
    },
    {
        f: '/bg/avtomobili-marka/opel/astra',
        t: '/bg/findcar?makesModels=Opel%2CAstra&cmsid=Opel_Astra_Text',
    },
    {
        f: '/bg/avtomobili-marka/opel/combo',
        t: '/bg/findcar?makesModels=Opel%2CCombo&cmsid=Opel_Combo_Text',
    },
    {
        f: '/bg/avtomobili-marka/opel/corsa',
        t: '/bg/findcar?makesModels=Opel%2CCorsa&cmsid=Opel_Corsa_Text',
    },
    {
        f: '/bg/avtomobili-marka/opel/insignia',
        t: '/bg/findcar?makesModels=Opel%2CInsignia&cmsid=Opel_Insignia_Text',
    },
    {
        f: '/bg/avtomobili-marka/opel/meriva',
        t: '/bg/findcar?makesModels=Opel%2CMeriva&cmsid=Opel_Meriva_Text',
    },
    {
        f: '/bg/avtomobili-marka/opel/movano',
        t: '/bg/findcar?makesModels=Opel%2CMovano&cmsid=Opel_Movano_Text',
    },
    {
        f: '/bg/avtomobili-marka/opel/vivaro',
        t: '/bg/findcar?makesModels=Opel%2CVivaro&cmsid=Opel_Vivaro_Text',
    },
    {
        f: '/bg/avtomobili-marka/opel/zafira',
        t: '/bg/findcar?makesModels=Opel%2CZafira&cmsid=Opel_Zafira_Text',
    },
    {
        f: '/bg/avtomobili-marka/opel',
        t: '/bg/findcar?makesModels=Opel&cmsid=Seo_Opel',
    },
    {
        f: '/bg/avtomobili-marka/peugeot/407sw',
        t: '/bg/findcar?makesModels=Peugeot%2C40x&cmsid=Peugeot_407sw_Text',
    },
    {
        f: '/bg/avtomobili-marka/peugeot/5008',
        t: '/bg/findcar?makesModels=Peugeot%2C5008&cmsid=Peugeot_5008_Text',
    },
    {
        f: '/bg/avtomobili-marka/peugeot/expert',
        t: '/bg/findcar?makesModels=Peugeot%2CExpert&cmsid=Peugeot_Expert_Text',
    },
    {
        f: '/bg/avtomobili-marka/peugeot/partner',
        t: '/bg/findcar?makesModels=Peugeot%2CPartner&cmsid=Peugeot_Partner_Text',
    },
    {
        f: '/bg/avtomobili-marka/peugeot',
        t: '/bg/findcar?makesModels=Peugeot&cmsid=Seo_Peugeot',
    },
    {
        f: '/bg/avtomobili-marka/piaggio',
        t: '/bg/findcar?makesModels=Piaggio&cmsid=SEO_Piaggio',
    },
    {
        f: '/bg/avtomobili-marka/renault/clio',
        t: '/bg/findcar?makesModels=Renault%2CClio&cmsid=Renault_Clio_Text',
    },
    {
        f: '/bg/avtomobili-marka/renault/espace',
        t: '/bg/findcar?makesModels=Renault%2CEspace&cmsid=Renault_Espace_Text',
    },
    {
        f: '/bg/avtomobili-marka/renault/kangoo',
        t: '/bg/findcar?makesModels=Renault%2CKangoo&cmsid=Renault_Kangoo_Text',
    },
    {
        f: '/bg/avtomobili-marka/renault/megane',
        t: '/bg/findcar?makesModels=Renault%2CMegane&cmsid=Renault_Megane_Text',
    },
    {
        f: '/bg/avtomobili-marka/renault/scenic',
        t: '/bg/findcar?makesModels=Renault%2CScenic&cmsid=Renault_Scenic_Text',
    },
    {
        f: '/bg/avtomobili-marka/renault',
        t: '/bg/findcar?makesModels=Renault&cmsid=SEO_Renault',
    },
    {
        f: '/bg/avtomobili-marka/skoda/fabia',
        t: '/bg/findcar?makesModels=Skoda%2CFabia&cmsid=Skoda_Fabia_Text',
    },
    {
        f: '/bg/avtomobili-marka/skoda/octavia',
        t: '/bg/findcar?makesModels=Skoda%2COctavia&cmsid=Skoda_Octavia_Text',
    },
    {
        f: '/bg/avtomobili-marka/skoda/rapid',
        t: '/bg/findcar?makesModels=Skoda%2CRapid&cmsid=Skoda_Rapid_Text',
    },
    {
        f: '/bg/avtomobili-marka/skoda/superb',
        t: '/bg/findcar?makesModels=Skoda%2CSuperb&cmsid=Skoda_Superb_Text',
    },
    {
        f: '/bg/avtomobili-marka/skoda/yeti',
        t: '/bg/findcar?makesModels=Skoda%2CYeti&cmsid=Skoda_Yeti_Text',
    },
    {
        f: '/bg/avtomobili-marka/skoda',
        t: '/bg/findcar?makesModels=Skoda&cmsid=SEO_Skoda',
    },
    {
        f: '/bg/avtomobili-marka/toyota/auris',
        t: '/bg/findcar?makesModels=Toyota%2CAuris&cmsid=Toyota_Auris_Text',
    },
    {
        f: '/bg/avtomobili-marka/toyota/avensis',
        t: '/bg/findcar?makesModels=Toyota%2CAvensis&cmsid=Toyota_Avensis_Text',
    },
    {
        f: '/bg/avtomobili-marka/toyota/aygo',
        t: '/bg/findcar?makesModels=Toyota%2CAygo&cmsid=Toyota_Aygo_Text',
    },
    {
        f: '/bg/avtomobili-marka/toyota/prius',
        t: '/bg/findcar?makesModels=Toyota%2CPrius&cmsid=Toyota_Prius_Text',
    },
    {
        f: '/bg/avtomobili-marka/toyota/rav4',
        t: '/bg/findcar?makesModels=Toyota%2CRav4&cmsid=Toyota_Rav4_Text',
    },
    {
        f: '/bg/avtomobili-marka/toyota/yaris',
        t: '/bg/findcar?makesModels=Toyota%2CYaris&cmsid=Toyota_Yaris_Text',
    },
    {
        f: '/bg/avtomobili-marka/toyota',
        t: '/bg/findcar?makesModels=Toyota&cmsid=SEO_Toyota',
    },
    {
        f: '/bg/avtomobili-marka/volkswagen/caddy',
        t: '/bg/findcar?makesModels=Volkswagen%2CCaddy&cmsid=Volkswagen_Caddy_Text',
    },
    {
        f: '/bg/avtomobili-marka/volkswagen/golf',
        t: '/bg/findcar?makesModels=Volkswagen%2CGolf&cmsid=Volkswagen_Golf_Text',
    },
    {
        f: '/bg/avtomobili-marka/volkswagen/passat',
        t: '/bg/findcar?makesModels=Volkswagen%2CPassat&cmsid=Volkswagen_Passat_Text',
    },
    {
        f: '/bg/avtomobili-marka/volkswagen/polo',
        t: '/bg/findcar?makesModels=Volkswagen%2CPolo&cmsid=Volkswagen_Polo_Text',
    },
    {
        f: '/bg/avtomobili-marka/volkswagen/tiguan',
        t: '/bg/findcar?makesModels=Volkswagen%2CTiguan&cmsid=Volkswagen_Tiguan_Text',
    },
    {
        f: '/bg/avtomobili-marka/volkswagen/touareg',
        t: '/bg/findcar?makesModels=Volkswagen%2CTouareg&cmsid=Volkswagen_Touareg_Text',
    },
    {
        f: '/bg/avtomobili-marka/volkswagen/touran',
        t: '/bg/findcar?makesModels=Volkswagen%2CTouran&cmsid=Volkswagen_Touran_Text',
    },
    {
        f: '/bg/avtomobili-marka/volkswagen/transporter',
        t: '/bg/findcar?makesModels=Volkswagen%2CTransporter&cmsid=Volkswagen_Transporter_Text',
    },
    {
        f: '/bg/avtomobili-marka/volkswagen',
        t: '/bg/findcar?makesModels=Volkswagen&cmsid=SEO_Volkswagen',
    },
    {
        f: '/bg/avtomobili-marka/volvo/s60',
        t: '/bg/findcar?makesModels=Volvo%2CS60&cmsid=Volvo_S60_Text',
    },
    {
        f: '/bg/avtomobili-marka/volvo/s80',
        t: '/bg/findcar?makesModels=Volvo%2CS80&cmsid=Volvo_S80_Text',
    },
    {
        f: '/bg/avtomobili-marka/volvo/s90',
        t: '/bg/findcar?makesModels=Volvo%2CS90&cmsid=Volvo_S90_Text',
    },
    {
        f: '/bg/avtomobili-marka/volvo/v40',
        t: '/bg/findcar?makesModels=Volvo%2CV40&cmsid=Volvo_V40_Text',
    },
    {
        f: '/bg/avtomobili-marka/volvo/v60',
        t: '/bg/findcar?makesModels=Volvo%2CV60&cmsid=Volvo_V60_Text',
    },
    {
        f: '/bg/avtomobili-marka/volvo/v90',
        t: '/bg/findcar?makesModels=Volvo%2CV90&cmsid=Volvo_V90_Text',
    },
    {
        f: '/bg/avtomobili-marka/volvo/xc60',
        t: '/bg/findcar?makesModels=Volvo%2CXC60&cmsid=Volvo_XC60_Text',
    },
    {
        f: '/bg/avtomobili-marka/volvo/xc90',
        t: '/bg/findcar?makesModels=Volvo%2CXC90&cmsid=Volvo_XC90_Text',
    },
    {
        f: '/bg/avtomobili-marka/volvo',
        t: '/bg/findcar?makesModels=Volvo&cmsid=SEO_Volvo',
    },
    {
        f: '/bg/used-cars-belgium',
        t: '/bg/findcar?originCountries=be&cmsid=used-cars-belgium',
    },
    {
        f: '/bg/used-cars-germany',
        t: '/bg/findcar?originCountries=de&cmsid=used-cars-germany',
    },
    {
        f: '/bg/used-cars-spain',
        t: '/bg/findcar?originCountries=es&cmsid=used-cars-spain',
    },
    {
        f: '/bg/used-cars-france',
        t: '/bg/findcar?originCountries=fr&cmsid=used-cars-france',
    },
    {
        f: '/bg/used-cars-italy',
        t: '/bg/findcar?originCountries=it&cmsid=used-cars-italy',
    },
    {
        f: '/bg/used-cars-luxembourg',
        t: '/bg/findcar?originCountries=lu&cmsid=used-cars-luxembourg',
    },
    {
        f: '/bg/used-cars-holland',
        t: '/bg/findcar?originCountries=nl&cmsid=used-cars-holland',
    },
    {
        f: '/bg/used-cars-poland',
        t: '/bg/findcar?originCountries=pl&cmsid=used-cars-poland',
    },
    {
        f: '/bg/used-cars-sweden',
        t: '/bg/findcar?originCountries=se&cmsid=used-cars-sweden',
    },
    {
        f: '/bg/used-car-prices/cheap-used-cars',
        t: '/bg/findcar?prices=0%2C3000&cmsid=cheap_used_cars',
    },
    {
        f: '/bg/used-car-prices/middle-class',
        t: '/bg/findcar?prices=10000%2C20000&cmsid=Middle_class',
    },
    {
        f: '/bg/used-car-prices/luxury-cars',
        t: '/bg/findcar?prices=20000%2C0&cmsid=luxury_cars',
    },
    {
        f: '/bg/used-car-prices/economy-cars',
        t: '/bg/findcar?prices=3000%2C6000&cmsid=economy_cars',
    },
    {
        f: '/bg/used-car-prices/value-for-money',
        t: '/bg/findcar?prices=6000%2C10000&cmsid=Value_for_money',
    },
    {
        f: '/cs/karoserie/nakladni-automobily',
        t: '/cs/findcar?bodyTypes=Truck&cmsid=Truck',
    },
    {
        f: '/cs/aukce-ojetech-aut/100',
        t: '/cs/findcar?assignment100pc=true&cmsid=100assignment',
    },
    {
        f: '/cs/aukce-ojetech-aut/dynamicka',
        t: '/cs/findcar?auctionTypes=1&cmsid=dynamic',
    },
    {
        f: '/cs/aukce-ojetech-aut/kup-ted',
        t: '/cs/findcar?auctionTypes=2&cmsid=buy-now',
    },
    {
        f: '/cs/aukce-ojetech-aut/naslepo',
        t: '/cs/findcar?auctionTypes=4&cmsid=blind',
    },
    {
        f: '/cs/aukce-ojetech-aut/cilova',
        t: '/cs/findcar?auctionTypes=5&cmsid=target',
    },
    {
        f: '/cs/karoserie/sedany',
        t: '/cs/findcar?bodyTypes=Berline&cmsid=SEO_Berline',
    },
    {
        f: '/cs/karoserie/kombi',
        t: '/cs/findcar?bodyTypes=Break&cmsid=SEO_Break',
    },
    {
        f: '/cs/karoserie/kabriolety',
        t: '/cs/findcar?bodyTypes=Cabriolet&cmsid=SEO_Cabrio',
    },
    {
        f: '/cs/karoserie/mala-auta',
        t: '/cs/findcar?bodyTypes=Compact&cmsid=SEO_Compact',
    },
    {
        f: '/cs/karoserie/coupe',
        t: '/cs/findcar?bodyTypes=Coup%C3%A9&cmsid=SEO_Coup%C3%A9',
    },
    {
        f: '/cs/karoserie/hatchback',
        t: '/cs/findcar?bodyTypes=Hatchback&cmsid=SEO_Hatchback',
    },
    {
        f: '/cs/karoserie/dodavky',
        t: '/cs/findcar?bodyTypes=Lighttruck&cmsid=SEO_Light-truck',
    },
    {
        f: '/cs/karoserie/minibusy',
        t: '/cs/findcar?bodyTypes=Minibus&cmsid=Minibus',
    },
    {
        f: '/cs/karoserie/motorky',
        t: '/cs/findcar?bodyTypes=Motorbike&cmsid=Motorbike',
    },
    { f: '/cs/karoserie/mpv', t: '/cs/findcar?bodyTypes=MPV&cmsid=SEO_MPV' },
    { f: '/cs/karoserie/suv', t: '/cs/findcar?bodyTypes=SUV&cmsid=SEO_SUV' },
    {
        f: '/cs/aukce-ojetech-aut/neposkozena',
        t: '/cs/findcar?damageTypes=1&cmsid=no_specific-Damages',
    },
    {
        f: '/cs/aukce-ojetech-aut/bourana',
        t: '/cs/findcar?damageTypes=2&cmsid=Body-damages',
    },
    {
        f: '/cs/aukce-ojetech-aut/technicka-poskozeni',
        t: '/cs/findcar?damageTypes=3&cmsid=technical-damages',
    },
    {
        f: '/cs/aukce-ojetech-aut/technicka-poskozeni-karoserie',
        t: '/cs/findcar?damageTypes=4&cmsid=Technical-and-body-damages',
    },
    {
        f: '/cs/znacka-auta/abarth',
        t: '/cs/findcar?makesModels=Abarth&cmsid=SEO_Abarth',
    },
    {
        f: '/cs/znacka-auta/alfa-romeo',
        t: '/cs/findcar?makesModels=Alfa%20Romeo&cmsid=SEO_alfa-romeo',
    },
    {
        f: '/cs/znacka-auta/audi/a1',
        t: '/cs/findcar?makesModels=Audi%2CA1&cmsid=Audi_A1_Text',
    },
    {
        f: '/cs/znacka-auta/audi/a2',
        t: '/cs/findcar?makesModels=Audi%2CA2&cmsid=Audi_A2_Text',
    },
    {
        f: '/cs/znacka-auta/audi/a3',
        t: '/cs/findcar?makesModels=Audi%2CA3&cmsid=Audi_A3_Text',
    },
    {
        f: '/cs/znacka-auta/audi/a4',
        t: '/cs/findcar?makesModels=Audi%2CA4&cmsid=Audi_A4_Text',
    },
    {
        f: '/cs/znacka-auta/audi/a5',
        t: '/cs/findcar?makesModels=Audi%2CA5&cmsid=Audi_A5_Text',
    },
    {
        f: '/cs/znacka-auta/audi/a6',
        t: '/cs/findcar?makesModels=Audi%2CA6&cmsid=Audi_A6_Text',
    },
    {
        f: '/cs/znacka-auta/audi/q3',
        t: '/cs/findcar?makesModels=Audi%2CQ3&cmsid=Audi_Q3_Text',
    },
    {
        f: '/cs/znacka-auta/audi/q5',
        t: '/cs/findcar?makesModels=Audi%2CQ5&cmsid=Audi_Q5_Text',
    },
    {
        f: '/cs/znacka-auta/audi/q7',
        t: '/cs/findcar?makesModels=Audi%2CQ7&cmsid=Audi_Q7_Text',
    },
    {
        f: '/cs/znacka-auta/audi',
        t: '/cs/findcar?makesModels=Audi&cmsid=SEO_Audi',
    },
    {
        f: '/cs/znacka-auta/bmw/1',
        t: '/cs/findcar?makesModels=BMW%2C1%20Series&cmsid=BMW_1series_Text',
    },
    {
        f: '/cs/znacka-auta/bmw/2',
        t: '/cs/findcar?makesModels=BMW%2C2%20Series&cmsid=BMW_2series_Text',
    },
    {
        f: '/cs/znacka-auta/bmw/3',
        t: '/cs/findcar?makesModels=BMW%2C3%20Series&cmsid=BMW_3series_Text',
    },
    {
        f: '/cs/znacka-auta/bmw/4',
        t: '/cs/findcar?makesModels=BMW%2C4%20Series&cmsid=BMW_4series_Text',
    },
    {
        f: '/cs/znacka-auta/bmw/5',
        t: '/cs/findcar?makesModels=BMW%2C5%20Series&cmsid=BMW_5series_Text',
    },
    {
        f: '/cs/znacka-auta/bmw/x1',
        t: '/cs/findcar?makesModels=BMW%2CX1&cmsid=BMW_X1_Text',
    },
    {
        f: '/cs/znacka-auta/bmw/x3',
        t: '/cs/findcar?makesModels=BMW%2CX3&cmsid=BMW_X3_Text',
    },
    {
        f: '/cs/znacka-auta/bmw/x5',
        t: '/cs/findcar?makesModels=BMW%2CX5&cmsid=BMW_X5_Text',
    },
    {
        f: '/cs/znacka-auta/bmw/x6',
        t: '/cs/findcar?makesModels=BMW%2CX6&cmsid=BMW_X6_Text',
    },
    { f: '/cs/znacka-auta/bmw', t: '/cs/findcar?makesModels=BMW&cmsid=SEO_BMW' },
    {
        f: '/cs/znacka-auta/citroen/berlingo',
        t: '/cs/findcar?makesModels=Citro%C3%ABn%2CBerlingo&cmsid=Citro%C3%ABn_Berlingo_text',
    },
    {
        f: '/cs/znacka-auta/citroen/c3',
        t: '/cs/findcar?makesModels=Citro%C3%ABn%2CC3&cmsid=Citro%C3%ABn_C3_text',
    },
    {
        f: '/cs/znacka-auta/citroen/c4',
        t: '/cs/findcar?makesModels=Citro%C3%ABn%2CC4&cmsid=Citro%C3%ABn_C4_text',
    },
    {
        f: '/cs/znacka-auta/citroen/c5',
        t: '/cs/findcar?makesModels=Citro%C3%ABn%2CC5&cmsid=Citro%C3%ABn_C5_text',
    },
    {
        f: '/cs/znacka-auta/citroen/ds3',
        t: '/cs/findcar?makesModels=Citro%C3%ABn%2CDS3&cmsid=Citro%C3%ABn_DS3_text',
    },
    {
        f: '/cs/znacka-auta/citroen/ds4',
        t: '/cs/findcar?makesModels=Citro%C3%ABn%2CDS4&cmsid=Citro%C3%ABn_DS4_text',
    },
    {
        f: '/cs/znacka-auta/citroen/dispatch',
        t: '/cs/findcar?makesModels=Citro%C3%ABn%2CJumpy&cmsid=Citro%C3%ABn_Dispatch_text',
    },
    {
        f: '/cs/znacka-auta/citroen',
        t: '/cs/findcar?makesModels=Citro%C3%ABn&cmsid=SEO_Citro%C3%ABn',
    },
    {
        f: '/cs/znacka-auta/fiat/500',
        t: '/cs/findcar?makesModels=Fiat%2C500&cmsid=Fiat_500_Text',
    },
    {
        f: '/cs/znacka-auta/fiat/bravo',
        t: '/cs/findcar?makesModels=Fiat%2CBravo&cmsid=Fiat_bravo_Text',
    },
    {
        f: '/cs/znacka-auta/fiat/doblo',
        t: '/cs/findcar?makesModels=Fiat%2CDoblo&cmsid=Fiat_doblo_Text',
    },
    {
        f: '/cs/znacka-auta/fiat/ducato',
        t: '/cs/findcar?makesModels=Fiat%2CDucato&cmsid=Fiat_ducato_Text',
    },
    {
        f: '/cs/znacka-auta/fiat/freemont',
        t: '/cs/findcar?makesModels=Fiat%2CFreemont&cmsid=Fiat_freemont_Text',
    },
    {
        f: '/cs/znacka-auta/fiat/panda',
        t: '/cs/findcar?makesModels=Fiat%2CPanda&cmsid=Fiat_panda_Text',
    },
    {
        f: '/cs/znacka-auta/fiat/punto',
        t: '/cs/findcar?makesModels=Fiat%2CPunto&cmsid=Fiat_punto_Text',
    },
    {
        f: '/cs/znacka-auta/fiat',
        t: '/cs/findcar?makesModels=Fiat&cmsid=SEO_Fiat',
    },
    {
        f: '/cs/znacka-auta/ford/fiesta',
        t: '/cs/findcar?makesModels=Ford%2CFiesta&cmsid=Ford_Fiesta_Text',
    },
    {
        f: '/cs/znacka-auta/ford/focus',
        t: '/cs/findcar?makesModels=Ford%2CFocus&cmsid=Ford_Focus_Text',
    },
    {
        f: '/cs/znacka-auta/ford/mondeo',
        t: '/cs/findcar?makesModels=Ford%2CMondeo&cmsid=Ford_Mondeo_Text',
    },
    {
        f: '/cs/znacka-auta/ford/transit',
        t: '/cs/findcar?makesModels=Ford%2CTransit&cmsid=Ford_Transit_Text',
    },
    {
        f: '/cs/znacka-auta/ford',
        t: '/cs/findcar?makesModels=Ford&cmsid=SEO_Ford',
    },
    {
        f: '/cs/znacka-auta/lancia',
        t: '/cs/findcar?makesModels=Lancia&cmsid=SEO_Lancia',
    },
    {
        f: '/cs/znacka-auta/mercedes/a',
        t: '/cs/findcar?makesModels=Mercedes-Benz%2CA&cmsid=Mercedes_A-class_Text',
    },
    {
        f: '/cs/znacka-auta/mercedes/b',
        t: '/cs/findcar?makesModels=Mercedes-Benz%2CB&cmsid=Mercedes_B-class_Text',
    },
    {
        f: '/cs/znacka-auta/mercedes/c',
        t: '/cs/findcar?makesModels=Mercedes-Benz%2CC&cmsid=Mercedes_C-class_Text',
    },
    {
        f: '/cs/znacka-auta/mercedes/e',
        t: '/cs/findcar?makesModels=Mercedes-Benz%2CE&cmsid=Mercedes_E-class_Text',
    },
    {
        f: '/cs/znacka-auta/mercedes/sprinter',
        t: '/cs/findcar?makesModels=Mercedes-Benz%2CSprinter&cmsid=Mercedes_Sprinter_Text',
    },
    {
        f: '/cs/znacka-auta/mercedes/vito',
        t: '/cs/findcar?makesModels=Mercedes-Benz%2CVito&cmsid=Mercedes_Vito_Text',
    },
    {
        f: '/cs/znacka-auta/mercedes',
        t: '/cs/findcar?makesModels=Mercedes-Benz&cmsid=SEO_Mercedes',
    },
    {
        f: '/cs/znacka-auta/nissan/juke',
        t: '/cs/findcar?makesModels=Nissan%2CJuke&cmsid=Nissan_Juke_Text',
    },
    {
        f: '/cs/znacka-auta/nissan/micra',
        t: '/cs/findcar?makesModels=Nissan%2CMicra&cmsid=Nissan_micra_Text',
    },
    {
        f: '/cs/znacka-auta/nissan/pathfinder',
        t: '/cs/findcar?makesModels=Nissan%2CPathfinder&cmsid=Nissan_Pathfinder_Text',
    },
    {
        f: '/cs/znacka-auta/nissan/qashqai',
        t: '/cs/findcar?makesModels=Nissan%2CQashqai&cmsid=Nissan_Qashqai_Text',
    },
    {
        f: '/cs/znacka-auta/nissan/xtrail',
        t: '/cs/findcar?makesModels=Nissan%2CX-Trail&cmsid=Nissan_x-trail_Text',
    },
    {
        f: '/cs/znacka-auta/nissan',
        t: '/cs/findcar?makesModels=Nissan&cmsid=SEO_Nissan',
    },
    {
        f: '/cs/znacka-auta/opel/astra',
        t: '/cs/findcar?makesModels=Opel%2CAstra&cmsid=Opel_Astra_Text',
    },
    {
        f: '/cs/znacka-auta/opel/combo',
        t: '/cs/findcar?makesModels=Opel%2CCombo&cmsid=Opel_Combo_Text',
    },
    {
        f: '/cs/znacka-auta/opel/corsa',
        t: '/cs/findcar?makesModels=Opel%2CCorsa&cmsid=Opel_Corsa_Text',
    },
    {
        f: '/cs/znacka-auta/opel/insignia',
        t: '/cs/findcar?makesModels=Opel%2CInsignia&cmsid=Opel_Insignia_Text',
    },
    {
        f: '/cs/znacka-auta/opel/meriva',
        t: '/cs/findcar?makesModels=Opel%2CMeriva&cmsid=Opel_Meriva_Text',
    },
    {
        f: '/cs/znacka-auta/opel/movano',
        t: '/cs/findcar?makesModels=Opel%2CMovano&cmsid=Opel_Movano_Text',
    },
    {
        f: '/cs/znacka-auta/opel/vivaro',
        t: '/cs/findcar?makesModels=Opel%2CVivaro&cmsid=Opel_Vivaro_Text',
    },
    {
        f: '/cs/znacka-auta/opel/zafira',
        t: '/cs/findcar?makesModels=Opel%2CZafira&cmsid=Opel_Zafira_Text',
    },
    {
        f: '/cs/znacka-auta/opel',
        t: '/cs/findcar?makesModels=Opel&cmsid=Seo_Opel',
    },
    {
        f: '/cs/znacka-auta/peugeot/407sw',
        t: '/cs/findcar?makesModels=Peugeot%2C40x&cmsid=Peugeot_407sw_Text',
    },
    {
        f: '/cs/znacka-auta/peugeot/5008',
        t: '/cs/findcar?makesModels=Peugeot%2C5008&cmsid=Peugeot_5008_Text',
    },
    {
        f: '/cs/znacka-auta/peugeot/expert',
        t: '/cs/findcar?makesModels=Peugeot%2CExpert&cmsid=Peugeot_Expert_Text',
    },
    {
        f: '/cs/znacka-auta/peugeot/partner',
        t: '/cs/findcar?makesModels=Peugeot%2CPartner&cmsid=Peugeot_Partner_Text',
    },
    {
        f: '/cs/znacka-auta/peugeot',
        t: '/cs/findcar?makesModels=Peugeot&cmsid=Seo_Peugeot',
    },
    {
        f: '/cs/znacka-auta/piaggio',
        t: '/cs/findcar?makesModels=Piaggio&cmsid=SEO_Piaggio',
    },
    {
        f: '/cs/znacka-auta/renault/clio',
        t: '/cs/findcar?makesModels=Renault%2CClio&cmsid=Renault_Clio_Text',
    },
    {
        f: '/cs/znacka-auta/renault/espace',
        t: '/cs/findcar?makesModels=Renault%2CEspace&cmsid=Renault_Espace_Text',
    },
    {
        f: '/cs/znacka-auta/renault/kangoo',
        t: '/cs/findcar?makesModels=Renault%2CKangoo&cmsid=Renault_Kangoo_Text',
    },
    {
        f: '/cs/znacka-auta/renault/megane',
        t: '/cs/findcar?makesModels=Renault%2CMegane&cmsid=Renault_Megane_Text',
    },
    {
        f: '/cs/znacka-auta/renault/scenic',
        t: '/cs/findcar?makesModels=Renault%2CScenic&cmsid=Renault_Scenic_Text',
    },
    {
        f: '/cs/znacka-auta/renault',
        t: '/cs/findcar?makesModels=Renault&cmsid=SEO_Renault',
    },
    {
        f: '/cs/znacka-auta/skoda/fabia',
        t: '/cs/findcar?makesModels=Skoda%2CFabia&cmsid=Skoda_Fabia_Text',
    },
    {
        f: '/cs/znacka-auta/skoda/octavia',
        t: '/cs/findcar?makesModels=Skoda%2COctavia&cmsid=Skoda_Octavia_Text',
    },
    {
        f: '/cs/znacka-auta/skoda/rapid',
        t: '/cs/findcar?makesModels=Skoda%2CRapid&cmsid=Skoda_Rapid_Text',
    },
    {
        f: '/cs/znacka-auta/skoda/superb',
        t: '/cs/findcar?makesModels=Skoda%2CSuperb&cmsid=Skoda_Superb_Text',
    },
    {
        f: '/cs/znacka-auta/skoda/yeti',
        t: '/cs/findcar?makesModels=Skoda%2CYeti&cmsid=Skoda_Yeti_Text',
    },
    {
        f: '/cs/znacka-auta/skoda',
        t: '/cs/findcar?makesModels=Skoda&cmsid=SEO_Skoda',
    },
    {
        f: '/cs/znacka-auta/toyota/auris',
        t: '/cs/findcar?makesModels=Toyota%2CAuris&cmsid=Toyota_Auris_Text',
    },
    {
        f: '/cs/znacka-auta/toyota/avensis',
        t: '/cs/findcar?makesModels=Toyota%2CAvensis&cmsid=Toyota_Avensis_Text',
    },
    {
        f: '/cs/znacka-auta/toyota/aygo',
        t: '/cs/findcar?makesModels=Toyota%2CAygo&cmsid=Toyota_Aygo_Text',
    },
    {
        f: '/cs/znacka-auta/toyota/prius',
        t: '/cs/findcar?makesModels=Toyota%2CPrius&cmsid=Toyota_Prius_Text',
    },
    {
        f: '/cs/znacka-auta/toyota/rav4',
        t: '/cs/findcar?makesModels=Toyota%2CRav4&cmsid=Toyota_Rav4_Text',
    },
    {
        f: '/cs/znacka-auta/toyota/yaris',
        t: '/cs/findcar?makesModels=Toyota%2CYaris&cmsid=Toyota_Yaris_Text',
    },
    {
        f: '/cs/znacka-auta/toyota',
        t: '/cs/findcar?makesModels=Toyota&cmsid=SEO_Toyota',
    },
    {
        f: '/cs/znacka-auta/volkswagen/caddy',
        t: '/cs/findcar?makesModels=Volkswagen%2CCaddy&cmsid=Volkswagen_Caddy_Text',
    },
    {
        f: '/cs/znacka-auta/volkswagen/golf',
        t: '/cs/findcar?makesModels=Volkswagen%2CGolf&cmsid=Volkswagen_Golf_Text',
    },
    {
        f: '/cs/znacka-auta/volkswagen/passat',
        t: '/cs/findcar?makesModels=Volkswagen%2CPassat&cmsid=Volkswagen_Passat_Text',
    },
    {
        f: '/cs/znacka-auta/volkswagen/polo',
        t: '/cs/findcar?makesModels=Volkswagen%2CPolo&cmsid=Volkswagen_Polo_Text',
    },
    {
        f: '/cs/znacka-auta/volkswagen/tiguan',
        t: '/cs/findcar?makesModels=Volkswagen%2CTiguan&cmsid=Volkswagen_Tiguan_Text',
    },
    {
        f: '/cs/znacka-auta/volkswagen/touareg',
        t: '/cs/findcar?makesModels=Volkswagen%2CTouareg&cmsid=Volkswagen_Touareg_Text',
    },
    {
        f: '/cs/znacka-auta/volkswagen/touran',
        t: '/cs/findcar?makesModels=Volkswagen%2CTouran&cmsid=Volkswagen_Touran_Text',
    },
    {
        f: '/cs/znacka-auta/volkswagen/transporter',
        t: '/cs/findcar?makesModels=Volkswagen%2CTransporter&cmsid=Volkswagen_Transporter_Text',
    },
    {
        f: '/cs/znacka-auta/volkswagen',
        t: '/cs/findcar?makesModels=Volkswagen&cmsid=SEO_Volkswagen',
    },
    {
        f: '/cs/znacka-auta/volvo/s60',
        t: '/cs/findcar?makesModels=Volvo%2CS60&cmsid=Volvo_S60_Text',
    },
    {
        f: '/cs/znacka-auta/volvo/s80',
        t: '/cs/findcar?makesModels=Volvo%2CS80&cmsid=Volvo_S80_Text',
    },
    {
        f: '/cs/znacka-auta/volvo/s90',
        t: '/cs/findcar?makesModels=Volvo%2CS90&cmsid=Volvo_S90_Text',
    },
    {
        f: '/cs/znacka-auta/volvo/v40',
        t: '/cs/findcar?makesModels=Volvo%2CV40&cmsid=Volvo_V40_Text',
    },
    {
        f: '/cs/znacka-auta/volvo/v60',
        t: '/cs/findcar?makesModels=Volvo%2CV60&cmsid=Volvo_V60_Text',
    },
    {
        f: '/cs/znacka-auta/volvo/v90',
        t: '/cs/findcar?makesModels=Volvo%2CV90&cmsid=Volvo_V90_Text',
    },
    {
        f: '/cs/znacka-auta/volvo/xc60',
        t: '/cs/findcar?makesModels=Volvo%2CXC60&cmsid=Volvo_XC60_Text',
    },
    {
        f: '/cs/znacka-auta/volvo/xc90',
        t: '/cs/findcar?makesModels=Volvo%2CXC90&cmsid=Volvo_XC90_Text',
    },
    {
        f: '/cs/znacka-auta/volvo',
        t: '/cs/findcar?makesModels=Volvo&cmsid=SEO_Volvo',
    },
    {
        f: '/cs/belgie',
        t: '/cs/findcar?originCountries=be&cmsid=used-cars-belgium',
    },
    {
        f: '/cs/ojete-vozy-nemecko',
        t: '/cs/findcar?originCountries=de&cmsid=used-cars-germany',
    },
    {
        f: '/cs/spanelsko',
        t: '/cs/findcar?originCountries=es&cmsid=used-cars-spain',
    },
    {
        f: '/cs/ojete-vozy-francie',
        t: '/cs/findcar?originCountries=fr&cmsid=used-cars-france',
    },
    {
        f: '/cs/ojete-vozy-italie',
        t: '/cs/findcar?originCountries=it&cmsid=used-cars-italy',
    },
    {
        f: '/cs/ojete-vozy-lucembursko',
        t: '/cs/findcar?originCountries=lu&cmsid=used-cars-luxembourg',
    },
    {
        f: '/cs/holandsko',
        t: '/cs/findcar?originCountries=nl&cmsid=used-cars-holland',
    },
    {
        f: '/cs/ojete-vozy-polsko',
        t: '/cs/findcar?originCountries=pl&cmsid=used-cars-poland',
    },
    {
        f: '/cs/ojete-vozy-svedsko',
        t: '/cs/findcar?originCountries=se&cmsid=used-cars-sweden',
    },
    {
        f: '/cs/ceny-ojetych-vozu/levna-auta',
        t: '/cs/findcar?prices=0%2C3000&cmsid=cheap_used_cars',
    },
    {
        f: '/cs/ceny-ojetych-vozu/stredni-trida',
        t: '/cs/findcar?prices=10000%2C20000&cmsid=Middle_class',
    },
    {
        f: '/cs/ceny-ojetych-vozu/luxusni-vozy',
        t: '/cs/findcar?prices=20000%2C0&cmsid=luxury_cars',
    },
    {
        f: '/cs/ceny-ojetych-vozu/ekonomicka-auta',
        t: '/cs/findcar?prices=3000%2C6000&cmsid=economy_cars',
    },
    {
        f: '/cs/ceny-ojetych-vozu/auta-cena-vykon',
        t: '/cs/findcar?prices=6000%2C10000&cmsid=Value_for_money',
    },
    { f: '/de/fahrzeugtyp/lkw', t: '/de/findcar?bodyTypes=Truck&cmsid=Truck' },
    {
        f: '/de/gebrauchtwagen-auktion/100-ige-zuweisung',
        t: '/de/findcar?assignment100pc=true&cmsid=100assignment',
    },
    {
        f: '/de/gebrauchtwagen-auktion/dynamisch',
        t: '/de/findcar?auctionTypes=1&cmsid=dynamic',
    },
    {
        f: '/de/gebrauchtwagen-auktion/sofortkauf',
        t: '/de/findcar?auctionTypes=2&cmsid=buy-now',
    },
    {
        f: '/de/gebrauchtwagen-auktion/blind',
        t: '/de/findcar?auctionTypes=4&cmsid=blind',
    },
    {
        f: '/de/gebrauchtwagen-auktion/abgabepreis',
        t: '/de/findcar?auctionTypes=5&cmsid=target',
    },
    {
        f: '/de/fahrzeugtyp/limousine',
        t: '/de/findcar?bodyTypes=Berline&cmsid=SEO_Berline',
    },
    {
        f: '/de/fahrzeugtyp/sprinter',
        t: '/de/findcar?bodyTypes=Break&cmsid=SEO_Break',
    },
    {
        f: '/de/fahrzeugtyp/cabrio',
        t: '/de/findcar?bodyTypes=Cabriolet&cmsid=SEO_Cabrio',
    },
    {
        f: '/de/fahrzeugtyp/kompaktwagen',
        t: '/de/findcar?bodyTypes=Compact&cmsid=SEO_Compact',
    },
    {
        f: '/de/fahrzeugtyp/coupe',
        t: '/de/findcar?bodyTypes=Coup%C3%A9&cmsid=SEO_Coup%C3%A9',
    },
    {
        f: '/de/fahrzeugtyp/schraegheck',
        t: '/de/findcar?bodyTypes=Hatchback&cmsid=SEO_Hatchback',
    },
    {
        f: '/de/fahrzeugtyp/kleintransporter',
        t: '/de/findcar?bodyTypes=Lighttruck&cmsid=SEO_Light-truck',
    },
    {
        f: '/de/fahrzeugtyp/minibus',
        t: '/de/findcar?bodyTypes=Minibus&cmsid=Minibus',
    },
    {
        f: '/de/fahrzeugtyp/motorrad',
        t: '/de/findcar?bodyTypes=Motorbike&cmsid=Motorbike',
    },
    { f: '/de/fahrzeugtyp/mpv', t: '/de/findcar?bodyTypes=MPV&cmsid=SEO_MPV' },
    { f: '/de/fahrzeugtyp/suv', t: '/de/findcar?bodyTypes=SUV&cmsid=SEO_SUV' },
    {
        f: '/de/gebrauchtwagen-auktion/ohne-schaeden',
        t: '/de/findcar?damageTypes=1&cmsid=no_specific-Damages',
    },
    {
        f: '/de/gebrauchtwagen-auktion/unfallautos',
        t: '/de/findcar?damageTypes=2&cmsid=Body-damages',
    },
    {
        f: '/de/gebrauchtwagen-auktion/defekte-autos',
        t: '/de/findcar?damageTypes=3&cmsid=technical-damages',
    },
    {
        f: '/de/gebrauchtwagen-auktion/defekt-und-karosserieschaden',
        t: '/de/findcar?damageTypes=4&cmsid=Technical-and-body-damages',
    },
    {
        f: '/de/automarke/abarth',
        t: '/de/findcar?makesModels=Abarth&cmsid=SEO_Abarth',
    },
    {
        f: '/de/automarke/alfa-romeo',
        t: '/de/findcar?makesModels=Alfa%20Romeo&cmsid=SEO_alfa-romeo',
    },
    {
        f: '/de/automarke/audi/a1',
        t: '/de/findcar?makesModels=Audi%2CA1&cmsid=Audi_A1_Text',
    },
    {
        f: '/de/automarke/audi/a2',
        t: '/de/findcar?makesModels=Audi%2CA2&cmsid=Audi_A2_Text',
    },
    {
        f: '/de/automarke/audi/a3',
        t: '/de/findcar?makesModels=Audi%2CA3&cmsid=Audi_A3_Text',
    },
    {
        f: '/de/automarke/audi/a4',
        t: '/de/findcar?makesModels=Audi%2CA4&cmsid=Audi_A4_Text',
    },
    {
        f: '/de/automarke/audi/a5',
        t: '/de/findcar?makesModels=Audi%2CA5&cmsid=Audi_A5_Text',
    },
    {
        f: '/de/automarke/audi/a6',
        t: '/de/findcar?makesModels=Audi%2CA6&cmsid=Audi_A6_Text',
    },
    {
        f: '/de/automarke/audi/q3',
        t: '/de/findcar?makesModels=Audi%2CQ3&cmsid=Audi_Q3_Text',
    },
    {
        f: '/de/automarke/audi/q5',
        t: '/de/findcar?makesModels=Audi%2CQ5&cmsid=Audi_Q5_Text',
    },
    {
        f: '/de/automarke/audi/q7',
        t: '/de/findcar?makesModels=Audi%2CQ7&cmsid=Audi_Q7_Text',
    },
    { f: '/de/automarke/audi', t: '/de/findcar?makesModels=Audi&cmsid=SEO_Audi' },
    {
        f: '/de/automarke/bmw/1',
        t: '/de/findcar?makesModels=BMW%2C1%20Series&cmsid=BMW_1series_Text',
    },
    {
        f: '/de/automarke/bmw/2',
        t: '/de/findcar?makesModels=BMW%2C2%20Series&cmsid=BMW_2series_Text',
    },
    {
        f: '/de/automarke/bmw/3',
        t: '/de/findcar?makesModels=BMW%2C3%20Series&cmsid=BMW_3series_Text',
    },
    {
        f: '/de/automarke/bmw/4',
        t: '/de/findcar?makesModels=BMW%2C4%20Series&cmsid=BMW_4series_Text',
    },
    {
        f: '/de/automarke/bmw/5',
        t: '/de/findcar?makesModels=BMW%2C5%20Series&cmsid=BMW_5series_Text',
    },
    {
        f: '/de/automarke/bmw/x1',
        t: '/de/findcar?makesModels=BMW%2CX1&cmsid=BMW_X1_Text',
    },
    {
        f: '/de/automarke/bmw/x3',
        t: '/de/findcar?makesModels=BMW%2CX3&cmsid=BMW_X3_Text',
    },
    {
        f: '/de/automarke/bmw/x5',
        t: '/de/findcar?makesModels=BMW%2CX5&cmsid=BMW_X5_Text',
    },
    {
        f: '/de/automarke/bmw/x6',
        t: '/de/findcar?makesModels=BMW%2CX6&cmsid=BMW_X6_Text',
    },
    { f: '/de/automarke/bmw', t: '/de/findcar?makesModels=BMW&cmsid=SEO_BMW' },
    {
        f: '/de/automarke/citroen/berlingo',
        t: '/de/findcar?makesModels=Citro%C3%ABn%2CBerlingo&cmsid=Citro%C3%ABn_Berlingo_text',
    },
    {
        f: '/de/automarke/citroen/c3',
        t: '/de/findcar?makesModels=Citro%C3%ABn%2CC3&cmsid=Citro%C3%ABn_C3_text',
    },
    {
        f: '/de/automarke/citroen/c4',
        t: '/de/findcar?makesModels=Citro%C3%ABn%2CC4&cmsid=Citro%C3%ABn_C4_text',
    },
    {
        f: '/de/automarke/citroen/c5',
        t: '/de/findcar?makesModels=Citro%C3%ABn%2CC5&cmsid=Citro%C3%ABn_C5_text',
    },
    {
        f: '/de/automarke/citroen/ds3',
        t: '/de/findcar?makesModels=Citro%C3%ABn%2CDS3&cmsid=Citro%C3%ABn_DS3_text',
    },
    {
        f: '/de/automarke/citroen/ds4',
        t: '/de/findcar?makesModels=Citro%C3%ABn%2CDS4&cmsid=Citro%C3%ABn_DS4_text',
    },
    {
        f: '/de/automarke/citroen/dispatch',
        t: '/de/findcar?makesModels=Citro%C3%ABn%2CJumpy&cmsid=Citro%C3%ABn_Dispatch_text',
    },
    {
        f: '/de/automarke/citroen',
        t: '/de/findcar?makesModels=Citro%C3%ABn&cmsid=SEO_Citro%C3%ABn',
    },
    {
        f: '/de/automarke/fiat/500',
        t: '/de/findcar?makesModels=Fiat%2C500&cmsid=Fiat_500_Text',
    },
    {
        f: '/de/automarke/fiat/bravo',
        t: '/de/findcar?makesModels=Fiat%2CBravo&cmsid=Fiat_bravo_Text',
    },
    {
        f: '/de/automarke/fiat/doblo',
        t: '/de/findcar?makesModels=Fiat%2CDoblo&cmsid=Fiat_doblo_Text',
    },
    {
        f: '/de/automarke/fiat/ducato',
        t: '/de/findcar?makesModels=Fiat%2CDucato&cmsid=Fiat_ducato_Text',
    },
    {
        f: '/de/automarke/fiat/freemont',
        t: '/de/findcar?makesModels=Fiat%2CFreemont&cmsid=Fiat_freemont_Text',
    },
    {
        f: '/de/automarke/fiat/panda',
        t: '/de/findcar?makesModels=Fiat%2CPanda&cmsid=Fiat_panda_Text',
    },
    {
        f: '/de/automarke/fiat/punto',
        t: '/de/findcar?makesModels=Fiat%2CPunto&cmsid=Fiat_punto_Text',
    },
    { f: '/de/automarke/fiat', t: '/de/findcar?makesModels=Fiat&cmsid=SEO_Fiat' },
    {
        f: '/de/automarke/ford/fiesta',
        t: '/de/findcar?makesModels=Ford%2CFiesta&cmsid=Ford_Fiesta_Text',
    },
    {
        f: '/de/automarke/ford/focus',
        t: '/de/findcar?makesModels=Ford%2CFocus&cmsid=Ford_Focus_Text',
    },
    {
        f: '/de/automarke/ford/mondeo',
        t: '/de/findcar?makesModels=Ford%2CMondeo&cmsid=Ford_Mondeo_Text',
    },
    {
        f: '/de/automarke/ford/transit',
        t: '/de/findcar?makesModels=Ford%2CTransit&cmsid=Ford_Transit_Text',
    },
    { f: '/de/automarke/ford', t: '/de/findcar?makesModels=Ford&cmsid=SEO_Ford' },
    {
        f: '/de/automarke/lancia',
        t: '/de/findcar?makesModels=Lancia&cmsid=SEO_Lancia',
    },
    {
        f: '/de/automarke/mercedes/a-klasse',
        t: '/de/findcar?makesModels=Mercedes-Benz%2CA&cmsid=Mercedes_A-class_Text',
    },
    {
        f: '/de/automarke/mercedes/b-klasse',
        t: '/de/findcar?makesModels=Mercedes-Benz%2CB&cmsid=Mercedes_B-class_Text',
    },
    {
        f: '/de/automarke/mercedes/c-klasse',
        t: '/de/findcar?makesModels=Mercedes-Benz%2CC&cmsid=Mercedes_C-class_Text',
    },
    {
        f: '/de/automarke/mercedes/e-klasse',
        t: '/de/findcar?makesModels=Mercedes-Benz%2CE&cmsid=Mercedes_E-class_Text',
    },
    {
        f: '/de/automarke/mercedes/sprinter',
        t: '/de/findcar?makesModels=Mercedes-Benz%2CSprinter&cmsid=Mercedes_Sprinter_Text',
    },
    {
        f: '/de/automarke/mercedes/vito',
        t: '/de/findcar?makesModels=Mercedes-Benz%2CVito&cmsid=Mercedes_Vito_Text',
    },
    {
        f: '/de/automarke/mercedes',
        t: '/de/findcar?makesModels=Mercedes-Benz&cmsid=SEO_Mercedes',
    },
    {
        f: '/de/automarke/nissan/juke',
        t: '/de/findcar?makesModels=Nissan%2CJuke&cmsid=Nissan_Juke_Text',
    },
    {
        f: '/de/automarke/nissan/micra',
        t: '/de/findcar?makesModels=Nissan%2CMicra&cmsid=Nissan_micra_Text',
    },
    {
        f: '/de/automarke/nissan/pathfinder',
        t: '/de/findcar?makesModels=Nissan%2CPathfinder&cmsid=Nissan_Pathfinder_Text',
    },
    {
        f: '/de/automarke/nissan/qashqai',
        t: '/de/findcar?makesModels=Nissan%2CQashqai&cmsid=Nissan_Qashqai_Text',
    },
    {
        f: '/de/automarke/nissan/x-trail',
        t: '/de/findcar?makesModels=Nissan%2CX-Trail&cmsid=Nissan_x-trail_Text',
    },
    {
        f: '/de/automarke/nissan',
        t: '/de/findcar?makesModels=Nissan&cmsid=SEO_Nissan',
    },
    {
        f: '/de/automarke/opel/astra',
        t: '/de/findcar?makesModels=Opel%2CAstra&cmsid=Opel_Astra_Text',
    },
    {
        f: '/de/automarke/opel/combo',
        t: '/de/findcar?makesModels=Opel%2CCombo&cmsid=Opel_Combo_Text',
    },
    {
        f: '/de/automarke/opel/corsa',
        t: '/de/findcar?makesModels=Opel%2CCorsa&cmsid=Opel_Corsa_Text',
    },
    {
        f: '/de/automarke/opel/insignia',
        t: '/de/findcar?makesModels=Opel%2CInsignia&cmsid=Opel_Insignia_Text',
    },
    {
        f: '/de/automarke/opel/meriva',
        t: '/de/findcar?makesModels=Opel%2CMeriva&cmsid=Opel_Meriva_Text',
    },
    {
        f: '/de/automarke/opel/movano',
        t: '/de/findcar?makesModels=Opel%2CMovano&cmsid=Opel_Movano_Text',
    },
    {
        f: '/de/automarke/opel/vivaro',
        t: '/de/findcar?makesModels=Opel%2CVivaro&cmsid=Opel_Vivaro_Text',
    },
    {
        f: '/de/automarke/opel/zafira',
        t: '/de/findcar?makesModels=Opel%2CZafira&cmsid=Opel_Zafira_Text',
    },
    { f: '/de/automarke/opel', t: '/de/findcar?makesModels=Opel&cmsid=Seo_Opel' },
    {
        f: '/de/automarke/peugeot/407sw',
        t: '/de/findcar?makesModels=Peugeot%2C40x&cmsid=Peugeot_407sw_Text',
    },
    {
        f: '/de/automarke/peugeot/5008',
        t: '/de/findcar?makesModels=Peugeot%2C5008&cmsid=Peugeot_5008_Text',
    },
    {
        f: '/de/automarke/peugeot/expert',
        t: '/de/findcar?makesModels=Peugeot%2CExpert&cmsid=Peugeot_Expert_Text',
    },
    {
        f: '/de/automarke/peugeot/partner',
        t: '/de/findcar?makesModels=Peugeot%2CPartner&cmsid=Peugeot_Partner_Text',
    },
    {
        f: '/de/automarke/peugeot',
        t: '/de/findcar?makesModels=Peugeot&cmsid=Seo_Peugeot',
    },
    {
        f: '/de/automarke/piaggio',
        t: '/de/findcar?makesModels=Piaggio&cmsid=SEO_Piaggio',
    },
    {
        f: '/de/automarke/renault/clio',
        t: '/de/findcar?makesModels=Renault%2CClio&cmsid=Renault_Clio_Text',
    },
    {
        f: '/de/automarke/renault/espace',
        t: '/de/findcar?makesModels=Renault%2CEspace&cmsid=Renault_Espace_Text',
    },
    {
        f: '/de/automarke/renault/kangoo',
        t: '/de/findcar?makesModels=Renault%2CKangoo&cmsid=Renault_Kangoo_Text',
    },
    {
        f: '/de/automarke/renault/megane',
        t: '/de/findcar?makesModels=Renault%2CMegane&cmsid=Renault_Megane_Text',
    },
    {
        f: '/de/automarke/renault/scenic',
        t: '/de/findcar?makesModels=Renault%2CScenic&cmsid=Renault_Scenic_Text',
    },
    {
        f: '/de/automarke/renault',
        t: '/de/findcar?makesModels=Renault&cmsid=SEO_Renault',
    },
    {
        f: '/de/automarke/skoda/fabia',
        t: '/de/findcar?makesModels=Skoda%2CFabia&cmsid=Skoda_Fabia_Text',
    },
    {
        f: '/de/automarke/skoda/octavia',
        t: '/de/findcar?makesModels=Skoda%2COctavia&cmsid=Skoda_Octavia_Text',
    },
    {
        f: '/de/automarke/skoda/rapid',
        t: '/de/findcar?makesModels=Skoda%2CRapid&cmsid=Skoda_Rapid_Text',
    },
    {
        f: '/de/automarke/skoda/superb',
        t: '/de/findcar?makesModels=Skoda%2CSuperb&cmsid=Skoda_Superb_Text',
    },
    {
        f: '/de/automarke/skoda/yeti',
        t: '/de/findcar?makesModels=Skoda%2CYeti&cmsid=Skoda_Yeti_Text',
    },
    {
        f: '/de/automarke/skoda',
        t: '/de/findcar?makesModels=Skoda&cmsid=SEO_Skoda',
    },
    {
        f: '/de/automarke/toyota/auris',
        t: '/de/findcar?makesModels=Toyota%2CAuris&cmsid=Toyota_Auris_Text',
    },
    {
        f: '/de/automarke/toyota/avensis',
        t: '/de/findcar?makesModels=Toyota%2CAvensis&cmsid=Toyota_Avensis_Text',
    },
    {
        f: '/de/automarke/toyota/aygo',
        t: '/de/findcar?makesModels=Toyota%2CAygo&cmsid=Toyota_Aygo_Text',
    },
    {
        f: '/de/automarke/toyota/prius',
        t: '/de/findcar?makesModels=Toyota%2CPrius&cmsid=Toyota_Prius_Text',
    },
    {
        f: '/de/automarke/toyota/rav4',
        t: '/de/findcar?makesModels=Toyota%2CRav4&cmsid=Toyota_Rav4_Text',
    },
    {
        f: '/de/automarke/toyota/yaris',
        t: '/de/findcar?makesModels=Toyota%2CYaris&cmsid=Toyota_Yaris_Text',
    },
    {
        f: '/de/automarke/toyota',
        t: '/de/findcar?makesModels=Toyota&cmsid=SEO_Toyota',
    },
    {
        f: '/de/automarke/volkswagen/caddy',
        t: '/de/findcar?makesModels=Volkswagen%2CCaddy&cmsid=Volkswagen_Caddy_Text',
    },
    {
        f: '/de/automarke/volkswagen/golf',
        t: '/de/findcar?makesModels=Volkswagen%2CGolf&cmsid=Volkswagen_Golf_Text',
    },
    {
        f: '/de/automarke/volkswagen/passat',
        t: '/de/findcar?makesModels=Volkswagen%2CPassat&cmsid=Volkswagen_Passat_Text',
    },
    {
        f: '/de/automarke/volkswagen/polo',
        t: '/de/findcar?makesModels=Volkswagen%2CPolo&cmsid=Volkswagen_Polo_Text',
    },
    {
        f: '/de/automarke/volkswagen/tiguan',
        t: '/de/findcar?makesModels=Volkswagen%2CTiguan&cmsid=Volkswagen_Tiguan_Text',
    },
    {
        f: '/de/automarke/volkswagen/touareg',
        t: '/de/findcar?makesModels=Volkswagen%2CTouareg&cmsid=Volkswagen_Touareg_Text',
    },
    {
        f: '/de/automarke/volkswagen/touran',
        t: '/de/findcar?makesModels=Volkswagen%2CTouran&cmsid=Volkswagen_Touran_Text',
    },
    {
        f: '/de/automarke/volkswagen/transporter',
        t: '/de/findcar?makesModels=Volkswagen%2CTransporter&cmsid=Volkswagen_Transporter_Text',
    },
    {
        f: '/de/automarke/volkswagen',
        t: '/de/findcar?makesModels=Volkswagen&cmsid=SEO_Volkswagen',
    },
    {
        f: '/de/automarke/volvo/s60',
        t: '/de/findcar?makesModels=Volvo%2CS60&cmsid=Volvo_S60_Text',
    },
    {
        f: '/de/automarke/volvo/s80',
        t: '/de/findcar?makesModels=Volvo%2CS80&cmsid=Volvo_S80_Text',
    },
    {
        f: '/de/automarke/volvo/s90',
        t: '/de/findcar?makesModels=Volvo%2CS90&cmsid=Volvo_S90_Text',
    },
    {
        f: '/de/automarke/volvo/v40',
        t: '/de/findcar?makesModels=Volvo%2CV40&cmsid=Volvo_V40_Text',
    },
    {
        f: '/de/automarke/volvo/v60',
        t: '/de/findcar?makesModels=Volvo%2CV60&cmsid=Volvo_V60_Text',
    },
    {
        f: '/de/automarke/volvo/v90',
        t: '/de/findcar?makesModels=Volvo%2CV90&cmsid=Volvo_V90_Text',
    },
    {
        f: '/de/automarke/volvo/xc60',
        t: '/de/findcar?makesModels=Volvo%2CXC60&cmsid=Volvo_XC60_Text',
    },
    {
        f: '/de/automarke/volvo/xc90',
        t: '/de/findcar?makesModels=Volvo%2CXC90&cmsid=Volvo_XC90_Text',
    },
    {
        f: '/de/automarke/volvo',
        t: '/de/findcar?makesModels=Volvo&cmsid=SEO_Volvo',
    },
    {
        f: '/de/gebrauchtwagen-belgien',
        t: '/de/findcar?originCountries=be&cmsid=used-cars-belgium',
    },
    {
        f: '/de/gebrauchtwagen-deutschland',
        t: '/de/findcar?originCountries=de&cmsid=used-cars-germany',
    },
    {
        f: '/de/gebrauchtwagen-spanien',
        t: '/de/findcar?originCountries=es&cmsid=used-cars-spain',
    },
    {
        f: '/de/gebrauchtwagen-frankreich',
        t: '/de/findcar?originCountries=fr&cmsid=used-cars-france',
    },
    {
        f: '/de/gebrauchtwagen-italien',
        t: '/de/findcar?originCountries=it&cmsid=used-cars-italy',
    },
    {
        f: '/de/gebrauchtwagen-luxemburg',
        t: '/de/findcar?originCountries=lu&cmsid=used-cars-luxembourg',
    },
    {
        f: '/de/gebrauchtwagen-holland',
        t: '/de/findcar?originCountries=nl&cmsid=used-cars-holland',
    },
    {
        f: '/de/gebrauchtwagen-polen',
        t: '/de/findcar?originCountries=pl&cmsid=used-cars-poland',
    },
    {
        f: '/de/gebrauchtwagen-schweden',
        t: '/de/findcar?originCountries=se&cmsid=used-cars-sweden',
    },
    {
        f: '/de/gebrauchtwagenpreise/guenstige-gebrauchtwagen',
        t: '/de/findcar?prices=0%2C3000&cmsid=cheap_used_cars',
    },
    {
        f: '/de/gebrauchtwagenpreise/mittelklasse',
        t: '/de/findcar?prices=10000%2C20000&cmsid=Middle_class',
    },
    {
        f: '/de/gebrauchtwagenpreise/oberklasse',
        t: '/de/findcar?prices=20000%2C0&cmsid=luxury_cars',
    },
    {
        f: '/de/gebrauchtwagenpreise/kleinwagen',
        t: '/de/findcar?prices=3000%2C6000&cmsid=economy_cars',
    },
    {
        f: '/de/gebrauchtwagenpreise/preis-leistungs-verhaeltnis',
        t: '/de/findcar?prices=6000%2C10000&cmsid=Value_for_money',
    },
    { f: '/el/body-type/truck', t: '/el/findcar?bodyTypes=Truck&cmsid=Truck' },
    {
        f: '/el/car-auction/guaranteed-assignment',
        t: '/el/findcar?assignment100pc=true&cmsid=100assignment',
    },
    {
        f: '/el/car-auction/dynamic',
        t: '/el/findcar?auctionTypes=1&cmsid=dynamic',
    },
    {
        f: '/el/car-auction/buy-now',
        t: '/el/findcar?auctionTypes=2&cmsid=buy-now',
    },
    { f: '/el/car-auction/blind', t: '/el/findcar?auctionTypes=4&cmsid=blind' },
    { f: '/el/car-auction/target', t: '/el/findcar?auctionTypes=5&cmsid=target' },
    {
        f: '/el/body-type/berline',
        t: '/el/findcar?bodyTypes=Berline&cmsid=SEO_Berline',
    },
    {
        f: '/el/body-type/break',
        t: '/el/findcar?bodyTypes=Break&cmsid=SEO_Break',
    },
    {
        f: '/el/body-type/cabrio',
        t: '/el/findcar?bodyTypes=Cabriolet&cmsid=SEO_Cabrio',
    },
    {
        f: '/el/body-type/compact',
        t: '/el/findcar?bodyTypes=Compact&cmsid=SEO_Compact',
    },
    {
        f: '/el/body-type/coupe',
        t: '/el/findcar?bodyTypes=Coup%C3%A9&cmsid=SEO_Coup%C3%A9',
    },
    {
        f: '/el/body-type/hatchback',
        t: '/el/findcar?bodyTypes=Hatchback&cmsid=SEO_Hatchback',
    },
    {
        f: '/el/body-type/light-truck',
        t: '/el/findcar?bodyTypes=Lighttruck&cmsid=SEO_Light-truck',
    },
    {
        f: '/el/body-type/minibus',
        t: '/el/findcar?bodyTypes=Minibus&cmsid=Minibus',
    },
    {
        f: '/el/body-type/motorbike',
        t: '/el/findcar?bodyTypes=Motorbike&cmsid=Motorbike',
    },
    { f: '/el/body-type/mpv', t: '/el/findcar?bodyTypes=MPV&cmsid=SEO_MPV' },
    { f: '/el/body-type/suv', t: '/el/findcar?bodyTypes=SUV&cmsid=SEO_SUV' },
    {
        f: '/el/car-auction/no-damages',
        t: '/el/findcar?damageTypes=1&cmsid=no_specific-Damages',
    },
    { f: '/el/accident-cars', t: '/el/findcar?damageTypes=2&cmsid=Body-damages' },
    {
        f: '/el/technically-damaged-cars',
        t: '/el/findcar?damageTypes=3&cmsid=technical-damages',
    },
    {
        f: '/el/technical-and-body-damages',
        t: '/el/findcar?damageTypes=4&cmsid=Technical-and-body-damages',
    },
    {
        f: '/el/car-makes/abarth',
        t: '/el/findcar?makesModels=Abarth&cmsid=SEO_Abarth',
    },
    {
        f: '/el/car-makes/alfa-romeo',
        t: '/el/findcar?makesModels=Alfa%20Romeo&cmsid=SEO_alfa-romeo',
    },
    {
        f: '/el/car-makes/audi/a1',
        t: '/el/findcar?makesModels=Audi%2CA1&cmsid=Audi_A1_Text',
    },
    {
        f: '/el/car-makes/audi/a2',
        t: '/el/findcar?makesModels=Audi%2CA2&cmsid=Audi_A2_Text',
    },
    {
        f: '/el/car-makes/audi/a3',
        t: '/el/findcar?makesModels=Audi%2CA3&cmsid=Audi_A3_Text',
    },
    {
        f: '/el/car-makes/audi/a4',
        t: '/el/findcar?makesModels=Audi%2CA4&cmsid=Audi_A4_Text',
    },
    {
        f: '/el/car-makes/audi/a5',
        t: '/el/findcar?makesModels=Audi%2CA5&cmsid=Audi_A5_Text',
    },
    {
        f: '/el/car-makes/audi/a6',
        t: '/el/findcar?makesModels=Audi%2CA6&cmsid=Audi_A6_Text',
    },
    {
        f: '/el/car-makes/audi/q3',
        t: '/el/findcar?makesModels=Audi%2CQ3&cmsid=Audi_Q3_Text',
    },
    {
        f: '/el/car-makes/audi/q5',
        t: '/el/findcar?makesModels=Audi%2CQ5&cmsid=Audi_Q5_Text',
    },
    {
        f: '/el/car-makes/audi/q7',
        t: '/el/findcar?makesModels=Audi%2CQ7&cmsid=Audi_Q7_Text',
    },
    { f: '/el/car-makes/audi', t: '/el/findcar?makesModels=Audi&cmsid=SEO_Audi' },
    {
        f: '/el/car-makes/bmw/1-series',
        t: '/el/findcar?makesModels=BMW%2C1%20Series&cmsid=BMW_1series_Text',
    },
    {
        f: '/el/car-makes/bmw/2-series',
        t: '/el/findcar?makesModels=BMW%2C2%20Series&cmsid=BMW_2series_Text',
    },
    {
        f: '/el/car-makes/bmw/3-series',
        t: '/el/findcar?makesModels=BMW%2C3%20Series&cmsid=BMW_3series_Text',
    },
    {
        f: '/el/car-makes/bmw/4-series',
        t: '/el/findcar?makesModels=BMW%2C4%20Series&cmsid=BMW_4series_Text',
    },
    {
        f: '/el/car-makes/bmw/5-series',
        t: '/el/findcar?makesModels=BMW%2C5%20Series&cmsid=BMW_5series_Text',
    },
    {
        f: '/el/car-makes/bmw/x1',
        t: '/el/findcar?makesModels=BMW%2CX1&cmsid=BMW_X1_Text',
    },
    {
        f: '/el/car-makes/bmw/x3',
        t: '/el/findcar?makesModels=BMW%2CX3&cmsid=BMW_X3_Text',
    },
    {
        f: '/el/car-makes/bmw/x5',
        t: '/el/findcar?makesModels=BMW%2CX5&cmsid=BMW_X5_Text',
    },
    {
        f: '/el/car-makes/bmw/x6',
        t: '/el/findcar?makesModels=BMW%2CX6&cmsid=BMW_X6_Text',
    },
    { f: '/el/car-makes/bmw', t: '/el/findcar?makesModels=BMW&cmsid=SEO_BMW' },
    {
        f: '/el/car-makes/citroen/berlingo',
        t: '/el/findcar?makesModels=Citro%C3%ABn%2CBerlingo&cmsid=Citro%C3%ABn_Berlingo_text',
    },
    {
        f: '/el/car-makes/citroen/c3',
        t: '/el/findcar?makesModels=Citro%C3%ABn%2CC3&cmsid=Citro%C3%ABn_C3_text',
    },
    {
        f: '/el/car-makes/citroen/c4',
        t: '/el/findcar?makesModels=Citro%C3%ABn%2CC4&cmsid=Citro%C3%ABn_C4_text',
    },
    {
        f: '/el/car-makes/citroen/c5',
        t: '/el/findcar?makesModels=Citro%C3%ABn%2CC5&cmsid=Citro%C3%ABn_C5_text',
    },
    {
        f: '/el/car-makes/citroen/ds3',
        t: '/el/findcar?makesModels=Citro%C3%ABn%2CDS3&cmsid=Citro%C3%ABn_DS3_text',
    },
    {
        f: '/el/car-makes/citroen/ds4',
        t: '/el/findcar?makesModels=Citro%C3%ABn%2CDS4&cmsid=Citro%C3%ABn_DS4_text',
    },
    {
        f: '/el/car-makes/citroen/dispatch',
        t: '/el/findcar?makesModels=Citro%C3%ABn%2CJumpy&cmsid=Citro%C3%ABn_Dispatch_text',
    },
    {
        f: '/el/car-makes/citroen',
        t: '/el/findcar?makesModels=Citro%C3%ABn&cmsid=SEO_Citro%C3%ABn',
    },
    {
        f: '/el/car-makes/fiat/500',
        t: '/el/findcar?makesModels=Fiat%2C500&cmsid=Fiat_500_Text',
    },
    {
        f: '/el/car-makes/fiat/bravo',
        t: '/el/findcar?makesModels=Fiat%2CBravo&cmsid=Fiat_bravo_Text',
    },
    {
        f: '/el/car-makes/fiat/doblo',
        t: '/el/findcar?makesModels=Fiat%2CDoblo&cmsid=Fiat_doblo_Text',
    },
    {
        f: '/el/car-makes/fiat/ducato',
        t: '/el/findcar?makesModels=Fiat%2CDucato&cmsid=Fiat_ducato_Text',
    },
    {
        f: '/el/car-makes/fiat/freemont',
        t: '/el/findcar?makesModels=Fiat%2CFreemont&cmsid=Fiat_freemont_Text',
    },
    {
        f: '/el/car-makes/fiat/panda',
        t: '/el/findcar?makesModels=Fiat%2CPanda&cmsid=Fiat_panda_Text',
    },
    {
        f: '/el/car-makes/fiat/punto',
        t: '/el/findcar?makesModels=Fiat%2CPunto&cmsid=Fiat_punto_Text',
    },
    { f: '/el/car-makes/fiat', t: '/el/findcar?makesModels=Fiat&cmsid=SEO_Fiat' },
    {
        f: '/el/car-makes/ford/fiesta',
        t: '/el/findcar?makesModels=Ford%2CFiesta&cmsid=Ford_Fiesta_Text',
    },
    {
        f: '/el/car-makes/ford/focus',
        t: '/el/findcar?makesModels=Ford%2CFocus&cmsid=Ford_Focus_Text',
    },
    {
        f: '/el/car-makes/ford/mondeo',
        t: '/el/findcar?makesModels=Ford%2CMondeo&cmsid=Ford_Mondeo_Text',
    },
    {
        f: '/el/car-makes/ford/transit',
        t: '/el/findcar?makesModels=Ford%2CTransit&cmsid=Ford_Transit_Text',
    },
    { f: '/el/car-makes/ford', t: '/el/findcar?makesModels=Ford&cmsid=SEO_Ford' },
    {
        f: '/el/car-makes/lancia',
        t: '/el/findcar?makesModels=Lancia&cmsid=SEO_Lancia',
    },
    {
        f: '/el/car-makes/mercedes/a-class',
        t: '/el/findcar?makesModels=Mercedes-Benz%2CA&cmsid=Mercedes_A-class_Text',
    },
    {
        f: '/el/car-makes/mercedes/b-class',
        t: '/el/findcar?makesModels=Mercedes-Benz%2CB&cmsid=Mercedes_B-class_Text',
    },
    {
        f: '/el/car-makes/mercedes/c-class',
        t: '/el/findcar?makesModels=Mercedes-Benz%2CC&cmsid=Mercedes_C-class_Text',
    },
    {
        f: '/el/car-makes/mercedes/e-class',
        t: '/el/findcar?makesModels=Mercedes-Benz%2CE&cmsid=Mercedes_E-class_Text',
    },
    {
        f: '/el/car-makes/mercedes/sprinter',
        t: '/el/findcar?makesModels=Mercedes-Benz%2CSprinter&cmsid=Mercedes_Sprinter_Text',
    },
    {
        f: '/el/car-makes/mercedes/vito',
        t: '/el/findcar?makesModels=Mercedes-Benz%2CVito&cmsid=Mercedes_Vito_Text',
    },
    {
        f: '/el/car-makes/mercedes',
        t: '/el/findcar?makesModels=Mercedes-Benz&cmsid=SEO_Mercedes',
    },
    {
        f: '/el/car-makes/nissan/juke',
        t: '/el/findcar?makesModels=Nissan%2CJuke&cmsid=Nissan_Juke_Text',
    },
    {
        f: '/el/car-makes/nissan/micra',
        t: '/el/findcar?makesModels=Nissan%2CMicra&cmsid=Nissan_micra_Text',
    },
    {
        f: '/el/car-makes/nissan/pathfinder',
        t: '/el/findcar?makesModels=Nissan%2CPathfinder&cmsid=Nissan_Pathfinder_Text',
    },
    {
        f: '/el/car-makes/nissan/qashqai',
        t: '/el/findcar?makesModels=Nissan%2CQashqai&cmsid=Nissan_Qashqai_Text',
    },
    {
        f: '/el/car-makes/nissan/x-trail',
        t: '/el/findcar?makesModels=Nissan%2CX-Trail&cmsid=Nissan_x-trail_Text',
    },
    {
        f: '/el/car-makes/nissan',
        t: '/el/findcar?makesModels=Nissan&cmsid=SEO_Nissan',
    },
    {
        f: '/el/car-makes/opel/astra',
        t: '/el/findcar?makesModels=Opel%2CAstra&cmsid=Opel_Astra_Text',
    },
    {
        f: '/el/car-makes/opel/combo',
        t: '/el/findcar?makesModels=Opel%2CCombo&cmsid=Opel_Combo_Text',
    },
    {
        f: '/el/car-makes/opel/corsa',
        t: '/el/findcar?makesModels=Opel%2CCorsa&cmsid=Opel_Corsa_Text',
    },
    {
        f: '/el/car-makes/opel/insignia',
        t: '/el/findcar?makesModels=Opel%2CInsignia&cmsid=Opel_Insignia_Text',
    },
    {
        f: '/el/car-makes/opel/meriva',
        t: '/el/findcar?makesModels=Opel%2CMeriva&cmsid=Opel_Meriva_Text',
    },
    {
        f: '/el/car-makes/opel/movano',
        t: '/el/findcar?makesModels=Opel%2CMovano&cmsid=Opel_Movano_Text',
    },
    {
        f: '/el/car-makes/opel/vivaro',
        t: '/el/findcar?makesModels=Opel%2CVivaro&cmsid=Opel_Vivaro_Text',
    },
    {
        f: '/el/car-makes/opel/zafira',
        t: '/el/findcar?makesModels=Opel%2CZafira&cmsid=Opel_Zafira_Text',
    },
    { f: '/el/car-makes/opel', t: '/el/findcar?makesModels=Opel&cmsid=Seo_Opel' },
    {
        f: '/el/car-makes/peugeot/407sw',
        t: '/el/findcar?makesModels=Peugeot%2C40x&cmsid=Peugeot_407sw_Text',
    },
    {
        f: '/el/car-makes/peugeot/5008',
        t: '/el/findcar?makesModels=Peugeot%2C5008&cmsid=Peugeot_5008_Text',
    },
    {
        f: '/el/car-makes/peugeot/expert',
        t: '/el/findcar?makesModels=Peugeot%2CExpert&cmsid=Peugeot_Expert_Text',
    },
    {
        f: '/el/car-makes/peugeot/partner',
        t: '/el/findcar?makesModels=Peugeot%2CPartner&cmsid=Peugeot_Partner_Text',
    },
    {
        f: '/el/car-makes/peugeot',
        t: '/el/findcar?makesModels=Peugeot&cmsid=Seo_Peugeot',
    },
    {
        f: '/el/car-makes/piaggio',
        t: '/el/findcar?makesModels=Piaggio&cmsid=SEO_Piaggio',
    },
    {
        f: '/el/car-makes/renault/clio',
        t: '/el/findcar?makesModels=Renault%2CClio&cmsid=Renault_Clio_Text',
    },
    {
        f: '/el/car-makes/renault/espace',
        t: '/el/findcar?makesModels=Renault%2CEspace&cmsid=Renault_Espace_Text',
    },
    {
        f: '/el/car-makes/renault/kangoo',
        t: '/el/findcar?makesModels=Renault%2CKangoo&cmsid=Renault_Kangoo_Text',
    },
    {
        f: '/el/car-makes/renault/megane',
        t: '/el/findcar?makesModels=Renault%2CMegane&cmsid=Renault_Megane_Text',
    },
    {
        f: '/el/car-makes/renault/scenic',
        t: '/el/findcar?makesModels=Renault%2CScenic&cmsid=Renault_Scenic_Text',
    },
    {
        f: '/el/car-makes/renault',
        t: '/el/findcar?makesModels=Renault&cmsid=SEO_Renault',
    },
    {
        f: '/el/car-makes/skoda/fabia',
        t: '/el/findcar?makesModels=Skoda%2CFabia&cmsid=Skoda_Fabia_Text',
    },
    {
        f: '/el/car-makes/skoda/octavia',
        t: '/el/findcar?makesModels=Skoda%2COctavia&cmsid=Skoda_Octavia_Text',
    },
    {
        f: '/el/car-makes/skoda/rapid',
        t: '/el/findcar?makesModels=Skoda%2CRapid&cmsid=Skoda_Rapid_Text',
    },
    {
        f: '/el/car-makes/skoda/superb',
        t: '/el/findcar?makesModels=Skoda%2CSuperb&cmsid=Skoda_Superb_Text',
    },
    {
        f: '/el/car-makes/skoda/yeti',
        t: '/el/findcar?makesModels=Skoda%2CYeti&cmsid=Skoda_Yeti_Text',
    },
    {
        f: '/el/car-makes/skoda',
        t: '/el/findcar?makesModels=Skoda&cmsid=SEO_Skoda',
    },
    {
        f: '/el/car-makes/toyota/auris',
        t: '/el/findcar?makesModels=Toyota%2CAuris&cmsid=Toyota_Auris_Text',
    },
    {
        f: '/el/car-makes/toyota/avensis',
        t: '/el/findcar?makesModels=Toyota%2CAvensis&cmsid=Toyota_Avensis_Text',
    },
    {
        f: '/el/car-makes/toyota/aygo',
        t: '/el/findcar?makesModels=Toyota%2CAygo&cmsid=Toyota_Aygo_Text',
    },
    {
        f: '/el/car-makes/toyota/prius',
        t: '/el/findcar?makesModels=Toyota%2CPrius&cmsid=Toyota_Prius_Text',
    },
    {
        f: '/el/car-makes/toyota/rav4',
        t: '/el/findcar?makesModels=Toyota%2CRav4&cmsid=Toyota_Rav4_Text',
    },
    {
        f: '/el/car-makes/toyota/yaris',
        t: '/el/findcar?makesModels=Toyota%2CYaris&cmsid=Toyota_Yaris_Text',
    },
    {
        f: '/el/car-makes/toyota',
        t: '/el/findcar?makesModels=Toyota&cmsid=SEO_Toyota',
    },
    {
        f: '/el/car-makes/volkswagen/caddy',
        t: '/el/findcar?makesModels=Volkswagen%2CCaddy&cmsid=Volkswagen_Caddy_Text',
    },
    {
        f: '/el/car-makes/volkswagen/golf',
        t: '/el/findcar?makesModels=Volkswagen%2CGolf&cmsid=Volkswagen_Golf_Text',
    },
    {
        f: '/el/car-makes/volkswagen/passat',
        t: '/el/findcar?makesModels=Volkswagen%2CPassat&cmsid=Volkswagen_Passat_Text',
    },
    {
        f: '/el/car-makes/volkswagen/polo',
        t: '/el/findcar?makesModels=Volkswagen%2CPolo&cmsid=Volkswagen_Polo_Text',
    },
    {
        f: '/el/car-makes/volkswagen/tiguan',
        t: '/el/findcar?makesModels=Volkswagen%2CTiguan&cmsid=Volkswagen_Tiguan_Text',
    },
    {
        f: '/el/car-makes/volkswagen/touareg',
        t: '/el/findcar?makesModels=Volkswagen%2CTouareg&cmsid=Volkswagen_Touareg_Text',
    },
    {
        f: '/el/car-makes/volkswagen/touran',
        t: '/el/findcar?makesModels=Volkswagen%2CTouran&cmsid=Volkswagen_Touran_Text',
    },
    {
        f: '/el/car-makes/volkswagen/transporter',
        t: '/el/findcar?makesModels=Volkswagen%2CTransporter&cmsid=Volkswagen_Transporter_Text',
    },
    {
        f: '/el/car-makes/volkswagen',
        t: '/el/findcar?makesModels=Volkswagen&cmsid=SEO_Volkswagen',
    },
    {
        f: '/el/car-makes/volvo/s60',
        t: '/el/findcar?makesModels=Volvo%2CS60&cmsid=Volvo_S60_Text',
    },
    {
        f: '/el/car-makes/volvo/s80',
        t: '/el/findcar?makesModels=Volvo%2CS80&cmsid=Volvo_S80_Text',
    },
    {
        f: '/el/car-makes/volvo/s90',
        t: '/el/findcar?makesModels=Volvo%2CS90&cmsid=Volvo_S90_Text',
    },
    {
        f: '/el/car-makes/volvo/v40',
        t: '/el/findcar?makesModels=Volvo%2CV40&cmsid=Volvo_V40_Text',
    },
    {
        f: '/el/car-makes/volvo/v60',
        t: '/el/findcar?makesModels=Volvo%2CV60&cmsid=Volvo_V60_Text',
    },
    {
        f: '/el/car-makes/volvo/v90',
        t: '/el/findcar?makesModels=Volvo%2CV90&cmsid=Volvo_V90_Text',
    },
    {
        f: '/el/car-makes/volvo/xc60',
        t: '/el/findcar?makesModels=Volvo%2CXC60&cmsid=Volvo_XC60_Text',
    },
    {
        f: '/el/car-makes/volvo/xc90',
        t: '/el/findcar?makesModels=Volvo%2CXC90&cmsid=Volvo_XC90_Text',
    },
    {
        f: '/el/car-makes/volvo',
        t: '/el/findcar?makesModels=Volvo&cmsid=SEO_Volvo',
    },
    {
        f: '/el/origin/used-cars-belgium',
        t: '/el/findcar?originCountries=be&cmsid=used-cars-belgium',
    },
    {
        f: '/el/origin/used-cars-germany',
        t: '/el/findcar?originCountries=de&cmsid=used-cars-germany',
    },
    {
        f: '/el/origin/used-cars-spain',
        t: '/el/findcar?originCountries=es&cmsid=used-cars-spain',
    },
    {
        f: '/el/origin/used-cars-france',
        t: '/el/findcar?originCountries=fr&cmsid=used-cars-france',
    },
    {
        f: '/el/origin/used-cars-italy',
        t: '/el/findcar?originCountries=it&cmsid=used-cars-italy',
    },
    {
        f: '/el/origin/used-cars-luxembourg',
        t: '/el/findcar?originCountries=lu&cmsid=used-cars-luxembourg',
    },
    {
        f: '/el/origin/used-cars-holland',
        t: '/el/findcar?originCountries=nl&cmsid=used-cars-holland',
    },
    {
        f: '/el/origin/used-cars-poland',
        t: '/el/findcar?originCountries=pl&cmsid=used-cars-poland',
    },
    {
        f: '/el/origin/used-cars-sweden',
        t: '/el/findcar?originCountries=se&cmsid=used-cars-sweden',
    },
    {
        f: '/el/used-car-prices/cheap-used-cars',
        t: '/el/findcar?prices=0%2C3000&cmsid=cheap_used_cars',
    },
    {
        f: '/el/used-car-prices/middle-class',
        t: '/el/findcar?prices=10000%2C20000&cmsid=Middle_class',
    },
    {
        f: '/el/used-car-prices/luxury-cars',
        t: '/el/findcar?prices=20000%2C0&cmsid=luxury_cars',
    },
    {
        f: '/el/used-car-prices/economy-cars',
        t: '/el/findcar?prices=3000%2C6000&cmsid=economy_cars',
    },
    {
        f: '/el/used-car-prices/value-for-money',
        t: '/el/findcar?prices=6000%2C10000&cmsid=Value_for_money',
    },
    { f: '/en/body-type/truck', t: '/en/findcar?bodyTypes=Truck&cmsid=Truck' },
    {
        f: '/en/car-auction/guaranteed-assignment',
        t: '/en/findcar?assignment100pc=true&cmsid=100assignment',
    },
    {
        f: '/en/car-auction/dynamic',
        t: '/en/findcar?auctionTypes=1&cmsid=dynamic',
    },
    {
        f: '/en/car-auction/buy-now',
        t: '/en/findcar?auctionTypes=2&cmsid=buy-now',
    },
    { f: '/en/car-auction/blind', t: '/en/findcar?auctionTypes=4&cmsid=blind' },
    { f: '/en/car-auction/target', t: '/en/findcar?auctionTypes=5&cmsid=target' },
    {
        f: '/en/body-type/berline',
        t: '/en/findcar?bodyTypes=Berline&cmsid=SEO_Berline',
    },
    {
        f: '/en/body-type/break',
        t: '/en/findcar?bodyTypes=Break&cmsid=SEO_Break',
    },
    {
        f: '/en/body-type/cabrio',
        t: '/en/findcar?bodyTypes=Cabriolet&cmsid=SEO_Cabrio',
    },
    {
        f: '/en/body-type/compact',
        t: '/en/findcar?bodyTypes=Compact&cmsid=SEO_Compact',
    },
    {
        f: '/en/body-type/coupe',
        t: '/en/findcar?bodyTypes=Coup%C3%A9&cmsid=SEO_Coup%C3%A9',
    },
    {
        f: '/en/body-type/hatchback',
        t: '/en/findcar?bodyTypes=Hatchback&cmsid=SEO_Hatchback',
    },
    {
        f: '/en/body-type/light-truck',
        t: '/en/findcar?bodyTypes=Lighttruck&cmsid=SEO_Light-truck',
    },
    {
        f: '/en/body-type/minibus',
        t: '/en/findcar?bodyTypes=Minibus&cmsid=Minibus',
    },
    {
        f: '/en/body-type/motorbike',
        t: '/en/findcar?bodyTypes=Motorbike&cmsid=Motorbike',
    },
    { f: '/en/body-type/mpv', t: '/en/findcar?bodyTypes=MPV&cmsid=SEO_MPV' },
    { f: '/en/body-type/suv', t: '/en/findcar?bodyTypes=SUV&cmsid=SEO_SUV' },
    {
        f: '/en/car-auction/no-damages',
        t: '/en/findcar?damageTypes=1&cmsid=no_specific-Damages',
    },
    { f: '/en/accident-cars', t: '/en/findcar?damageTypes=2&cmsid=Body-damages' },
    {
        f: '/en/technically-damaged-cars',
        t: '/en/findcar?damageTypes=3&cmsid=technical-damages',
    },
    {
        f: '/en/technical-and-body-damages',
        t: '/en/findcar?damageTypes=4&cmsid=Technical-and-body-damages',
    },
    {
        f: '/en/car-makes/abarth',
        t: '/en/findcar?makesModels=Abarth&cmsid=SEO_Abarth',
    },
    {
        f: '/en/car-makes/alfa-romeo',
        t: '/en/findcar?makesModels=Alfa%20Romeo&cmsid=SEO_alfa-romeo',
    },
    {
        f: '/en/car-makes/audi/a1',
        t: '/en/findcar?makesModels=Audi%2CA1&cmsid=Audi_A1_Text',
    },
    {
        f: '/en/car-makes/audi/a2',
        t: '/en/findcar?makesModels=Audi%2CA2&cmsid=Audi_A2_Text',
    },
    {
        f: '/en/car-makes/audi/a3',
        t: '/en/findcar?makesModels=Audi%2CA3&cmsid=Audi_A3_Text',
    },
    {
        f: '/en/car-makes/audi/a4',
        t: '/en/findcar?makesModels=Audi%2CA4&cmsid=Audi_A4_Text',
    },
    {
        f: '/en/car-makes/audi/a5',
        t: '/en/findcar?makesModels=Audi%2CA5&cmsid=Audi_A5_Text',
    },
    {
        f: '/en/car-makes/audi/a6',
        t: '/en/findcar?makesModels=Audi%2CA6&cmsid=Audi_A6_Text',
    },
    {
        f: '/en/car-makes/audi/q3',
        t: '/en/findcar?makesModels=Audi%2CQ3&cmsid=Audi_Q3_Text',
    },
    {
        f: '/en/car-makes/audi/q5',
        t: '/en/findcar?makesModels=Audi%2CQ5&cmsid=Audi_Q5_Text',
    },
    {
        f: '/en/car-makes/audi/q7',
        t: '/en/findcar?makesModels=Audi%2CQ7&cmsid=Audi_Q7_Text',
    },
    { f: '/en/car-makes/audi', t: '/en/findcar?makesModels=Audi&cmsid=SEO_Audi' },
    {
        f: '/en/car-makes/bmw/1-series',
        t: '/en/findcar?makesModels=BMW%2C1%20Series&cmsid=BMW_1series_Text',
    },
    {
        f: '/en/car-makes/bmw/2-series',
        t: '/en/findcar?makesModels=BMW%2C2%20Series&cmsid=BMW_2series_Text',
    },
    {
        f: '/en/car-makes/bmw/3-series',
        t: '/en/findcar?makesModels=BMW%2C3%20Series&cmsid=BMW_3series_Text',
    },
    {
        f: '/en/car-makes/bmw/4-series',
        t: '/en/findcar?makesModels=BMW%2C4%20Series&cmsid=BMW_4series_Text',
    },
    {
        f: '/en/car-makes/bmw/5-series',
        t: '/en/findcar?makesModels=BMW%2C5%20Series&cmsid=BMW_5series_Text',
    },
    {
        f: '/en/car-makes/bmw/x1',
        t: '/en/findcar?makesModels=BMW%2CX1&cmsid=BMW_X1_Text',
    },
    {
        f: '/en/car-makes/bmw/x3',
        t: '/en/findcar?makesModels=BMW%2CX3&cmsid=BMW_X3_Text',
    },
    {
        f: '/en/car-makes/bmw/x5',
        t: '/en/findcar?makesModels=BMW%2CX5&cmsid=BMW_X5_Text',
    },
    {
        f: '/en/car-makes/bmw/x6',
        t: '/en/findcar?makesModels=BMW%2CX6&cmsid=BMW_X6_Text',
    },
    { f: '/en/car-makes/bmw', t: '/en/findcar?makesModels=BMW&cmsid=SEO_BMW' },
    {
        f: '/en/car-makes/citroen/berlingo',
        t: '/en/findcar?makesModels=Citro%C3%ABn%2CBerlingo&cmsid=Citro%C3%ABn_Berlingo_text',
    },
    {
        f: '/en/car-makes/citroen/c3',
        t: '/en/findcar?makesModels=Citro%C3%ABn%2CC3&cmsid=Citro%C3%ABn_C3_text',
    },
    {
        f: '/en/car-makes/citroen/c4',
        t: '/en/findcar?makesModels=Citro%C3%ABn%2CC4&cmsid=Citro%C3%ABn_C4_text',
    },
    {
        f: '/en/car-makes/citroen/c5',
        t: '/en/findcar?makesModels=Citro%C3%ABn%2CC5&cmsid=Citro%C3%ABn_C5_text',
    },
    {
        f: '/en/car-makes/citroen/ds3',
        t: '/en/findcar?makesModels=Citro%C3%ABn%2CDS3&cmsid=Citro%C3%ABn_DS3_text',
    },
    {
        f: '/en/car-makes/citroen/ds4',
        t: '/en/findcar?makesModels=Citro%C3%ABn%2CDS4&cmsid=Citro%C3%ABn_DS4_text',
    },
    {
        f: '/en/car-makes/citroen/dispatch',
        t: '/en/findcar?makesModels=Citro%C3%ABn%2CJumpy&cmsid=Citro%C3%ABn_Dispatch_text',
    },
    {
        f: '/en/car-makes/citroen',
        t: '/en/findcar?makesModels=Citro%C3%ABn&cmsid=SEO_Citro%C3%ABn',
    },
    {
        f: '/en/car-makes/fiat/500',
        t: '/en/findcar?makesModels=Fiat%2C500&cmsid=Fiat_500_Text',
    },
    {
        f: '/en/car-makes/fiat/bravo',
        t: '/en/findcar?makesModels=Fiat%2CBravo&cmsid=Fiat_bravo_Text',
    },
    {
        f: '/en/car-makes/fiat/doblo',
        t: '/en/findcar?makesModels=Fiat%2CDoblo&cmsid=Fiat_doblo_Text',
    },
    {
        f: '/en/car-makes/fiat/ducato',
        t: '/en/findcar?makesModels=Fiat%2CDucato&cmsid=Fiat_ducato_Text',
    },
    {
        f: '/en/car-makes/fiat/freemont',
        t: '/en/findcar?makesModels=Fiat%2CFreemont&cmsid=Fiat_freemont_Text',
    },
    {
        f: '/en/car-makes/fiat/panda',
        t: '/en/findcar?makesModels=Fiat%2CPanda&cmsid=Fiat_panda_Text',
    },
    {
        f: '/en/car-makes/fiat/punto',
        t: '/en/findcar?makesModels=Fiat%2CPunto&cmsid=Fiat_punto_Text',
    },
    { f: '/en/car-makes/fiat', t: '/en/findcar?makesModels=Fiat&cmsid=SEO_Fiat' },
    {
        f: '/en/car-makes/ford/fiesta',
        t: '/en/findcar?makesModels=Ford%2CFiesta&cmsid=Ford_Fiesta_Text',
    },
    {
        f: '/en/car-makes/ford/focus',
        t: '/en/findcar?makesModels=Ford%2CFocus&cmsid=Ford_Focus_Text',
    },
    {
        f: '/en/car-makes/ford/mondeo',
        t: '/en/findcar?makesModels=Ford%2CMondeo&cmsid=Ford_Mondeo_Text',
    },
    {
        f: '/en/car-makes/ford/transit',
        t: '/en/findcar?makesModels=Ford%2CTransit&cmsid=Ford_Transit_Text',
    },
    { f: '/en/car-makes/ford', t: '/en/findcar?makesModels=Ford&cmsid=SEO_Ford' },
    {
        f: '/en/car-makes/lancia',
        t: '/en/findcar?makesModels=Lancia&cmsid=SEO_Lancia',
    },
    {
        f: '/en/car-makes/mercedes/a-class',
        t: '/en/findcar?makesModels=Mercedes-Benz%2CA&cmsid=Mercedes_A-class_Text',
    },
    {
        f: '/en/car-makes/mercedes/b-class',
        t: '/en/findcar?makesModels=Mercedes-Benz%2CB&cmsid=Mercedes_B-class_Text',
    },
    {
        f: '/en/car-makes/mercedes/c-class',
        t: '/en/findcar?makesModels=Mercedes-Benz%2CC&cmsid=Mercedes_C-class_Text',
    },
    {
        f: '/en/car-makes/mercedes/e-class',
        t: '/en/findcar?makesModels=Mercedes-Benz%2CE&cmsid=Mercedes_E-class_Text',
    },
    {
        f: '/en/car-makes/mercedes/sprinter',
        t: '/en/findcar?makesModels=Mercedes-Benz%2CSprinter&cmsid=Mercedes_Sprinter_Text',
    },
    {
        f: '/en/car-makes/mercedes/vito',
        t: '/en/findcar?makesModels=Mercedes-Benz%2CVito&cmsid=Mercedes_Vito_Text',
    },
    {
        f: '/en/car-makes/mercedes',
        t: '/en/findcar?makesModels=Mercedes-Benz&cmsid=SEO_Mercedes',
    },
    {
        f: '/en/car-makes/nissan/juke',
        t: '/en/findcar?makesModels=Nissan%2CJuke&cmsid=Nissan_Juke_Text',
    },
    {
        f: '/en/car-makes/nissan/micra',
        t: '/en/findcar?makesModels=Nissan%2CMicra&cmsid=Nissan_micra_Text',
    },
    {
        f: '/en/car-makes/nissan/pathfinder',
        t: '/en/findcar?makesModels=Nissan%2CPathfinder&cmsid=Nissan_Pathfinder_Text',
    },
    {
        f: '/en/car-makes/nissan/qashqai',
        t: '/en/findcar?makesModels=Nissan%2CQashqai&cmsid=Nissan_Qashqai_Text',
    },
    {
        f: '/en/car-makes/nissan/x-trail',
        t: '/en/findcar?makesModels=Nissan%2CX-Trail&cmsid=Nissan_x-trail_Text',
    },
    {
        f: '/en/car-makes/nissan',
        t: '/en/findcar?makesModels=Nissan&cmsid=SEO_Nissan',
    },
    {
        f: '/en/car-makes/opel/astra',
        t: '/en/findcar?makesModels=Opel%2CAstra&cmsid=Opel_Astra_Text',
    },
    {
        f: '/en/car-makes/opel/combo',
        t: '/en/findcar?makesModels=Opel%2CCombo&cmsid=Opel_Combo_Text',
    },
    {
        f: '/en/car-makes/opel/corsa',
        t: '/en/findcar?makesModels=Opel%2CCorsa&cmsid=Opel_Corsa_Text',
    },
    {
        f: '/en/car-makes/opel/insignia',
        t: '/en/findcar?makesModels=Opel%2CInsignia&cmsid=Opel_Insignia_Text',
    },
    {
        f: '/en/car-makes/opel/meriva',
        t: '/en/findcar?makesModels=Opel%2CMeriva&cmsid=Opel_Meriva_Text',
    },
    {
        f: '/en/car-makes/opel/movano',
        t: '/en/findcar?makesModels=Opel%2CMovano&cmsid=Opel_Movano_Text',
    },
    {
        f: '/en/car-makes/opel/vivaro',
        t: '/en/findcar?makesModels=Opel%2CVivaro&cmsid=Opel_Vivaro_Text',
    },
    {
        f: '/en/car-makes/opel/zafira',
        t: '/en/findcar?makesModels=Opel%2CZafira&cmsid=Opel_Zafira_Text',
    },
    { f: '/en/car-makes/opel', t: '/en/findcar?makesModels=Opel&cmsid=Seo_Opel' },
    {
        f: '/en/car-makes/peugeot/407sw',
        t: '/en/findcar?makesModels=Peugeot%2C40x&cmsid=Peugeot_407sw_Text',
    },
    {
        f: '/en/car-makes/peugeot/5008',
        t: '/en/findcar?makesModels=Peugeot%2C5008&cmsid=Peugeot_5008_Text',
    },
    {
        f: '/en/car-makes/peugeot/expert',
        t: '/en/findcar?makesModels=Peugeot%2CExpert&cmsid=Peugeot_Expert_Text',
    },
    {
        f: '/en/car-makes/peugeot/partner',
        t: '/en/findcar?makesModels=Peugeot%2CPartner&cmsid=Peugeot_Partner_Text',
    },
    {
        f: '/en/car-makes/peugeot',
        t: '/en/findcar?makesModels=Peugeot&cmsid=Seo_Peugeot',
    },
    {
        f: '/en/car-makes/piaggio',
        t: '/en/findcar?makesModels=Piaggio&cmsid=SEO_Piaggio',
    },
    {
        f: '/en/car-makes/renault/clio',
        t: '/en/findcar?makesModels=Renault%2CClio&cmsid=Renault_Clio_Text',
    },
    {
        f: '/en/car-makes/renault/espace',
        t: '/en/findcar?makesModels=Renault%2CEspace&cmsid=Renault_Espace_Text',
    },
    {
        f: '/en/car-makes/renault/kangoo',
        t: '/en/findcar?makesModels=Renault%2CKangoo&cmsid=Renault_Kangoo_Text',
    },
    {
        f: '/en/car-makes/renault/megane',
        t: '/en/findcar?makesModels=Renault%2CMegane&cmsid=Renault_Megane_Text',
    },
    {
        f: '/en/car-makes/renault/scenic',
        t: '/en/findcar?makesModels=Renault%2CScenic&cmsid=Renault_Scenic_Text',
    },
    {
        f: '/en/car-makes/renault',
        t: '/en/findcar?makesModels=Renault&cmsid=SEO_Renault',
    },
    {
        f: '/en/car-makes/skoda/fabia',
        t: '/en/findcar?makesModels=Skoda%2CFabia&cmsid=Skoda_Fabia_Text',
    },
    {
        f: '/en/car-makes/skoda/octavia',
        t: '/en/findcar?makesModels=Skoda%2COctavia&cmsid=Skoda_Octavia_Text',
    },
    {
        f: '/en/car-makes/skoda/rapid',
        t: '/en/findcar?makesModels=Skoda%2CRapid&cmsid=Skoda_Rapid_Text',
    },
    {
        f: '/en/car-makes/skoda/superb',
        t: '/en/findcar?makesModels=Skoda%2CSuperb&cmsid=Skoda_Superb_Text',
    },
    {
        f: '/en/car-makes/skoda/yeti',
        t: '/en/findcar?makesModels=Skoda%2CYeti&cmsid=Skoda_Yeti_Text',
    },
    {
        f: '/en/car-makes/skoda',
        t: '/en/findcar?makesModels=Skoda&cmsid=SEO_Skoda',
    },
    {
        f: '/en/car-makes/toyota/auris',
        t: '/en/findcar?makesModels=Toyota%2CAuris&cmsid=Toyota_Auris_Text',
    },
    {
        f: '/en/car-makes/toyota/avensis',
        t: '/en/findcar?makesModels=Toyota%2CAvensis&cmsid=Toyota_Avensis_Text',
    },
    {
        f: '/en/car-makes/toyota/aygo',
        t: '/en/findcar?makesModels=Toyota%2CAygo&cmsid=Toyota_Aygo_Text',
    },
    {
        f: '/en/car-makes/toyota/prius',
        t: '/en/findcar?makesModels=Toyota%2CPrius&cmsid=Toyota_Prius_Text',
    },
    {
        f: '/en/car-makes/toyota/rav4',
        t: '/en/findcar?makesModels=Toyota%2CRav4&cmsid=Toyota_Rav4_Text',
    },
    {
        f: '/en/car-makes/toyota/yaris',
        t: '/en/findcar?makesModels=Toyota%2CYaris&cmsid=Toyota_Yaris_Text',
    },
    {
        f: '/en/car-makes/toyota',
        t: '/en/findcar?makesModels=Toyota&cmsid=SEO_Toyota',
    },
    {
        f: '/en/car-makes/volkswagen/caddy',
        t: '/en/findcar?makesModels=Volkswagen%2CCaddy&cmsid=Volkswagen_Caddy_Text',
    },
    {
        f: '/en/car-makes/volkswagen/golf',
        t: '/en/findcar?makesModels=Volkswagen%2CGolf&cmsid=Volkswagen_Golf_Text',
    },
    {
        f: '/en/car-makes/volkswagen/passat',
        t: '/en/findcar?makesModels=Volkswagen%2CPassat&cmsid=Volkswagen_Passat_Text',
    },
    {
        f: '/en/car-makes/volkswagen/polo',
        t: '/en/findcar?makesModels=Volkswagen%2CPolo&cmsid=Volkswagen_Polo_Text',
    },
    {
        f: '/en/car-makes/volkswagen/tiguan',
        t: '/en/findcar?makesModels=Volkswagen%2CTiguan&cmsid=Volkswagen_Tiguan_Text',
    },
    {
        f: '/en/car-makes/volkswagen/touareg',
        t: '/en/findcar?makesModels=Volkswagen%2CTouareg&cmsid=Volkswagen_Touareg_Text',
    },
    {
        f: '/en/car-makes/volkswagen/touran',
        t: '/en/findcar?makesModels=Volkswagen%2CTouran&cmsid=Volkswagen_Touran_Text',
    },
    {
        f: '/en/car-makes/volkswagen/transporter',
        t: '/en/findcar?makesModels=Volkswagen%2CTransporter&cmsid=Volkswagen_Transporter_Text',
    },
    {
        f: '/en/car-makes/volkswagen',
        t: '/en/findcar?makesModels=Volkswagen&cmsid=SEO_Volkswagen',
    },
    {
        f: '/en/car-makes/volvo/s60',
        t: '/en/findcar?makesModels=Volvo%2CS60&cmsid=Volvo_S60_Text',
    },
    {
        f: '/en/car-makes/volvo/s80',
        t: '/en/findcar?makesModels=Volvo%2CS80&cmsid=Volvo_S80_Text',
    },
    {
        f: '/en/car-makes/volvo/s90',
        t: '/en/findcar?makesModels=Volvo%2CS90&cmsid=Volvo_S90_Text',
    },
    {
        f: '/en/car-makes/volvo/v40',
        t: '/en/findcar?makesModels=Volvo%2CV40&cmsid=Volvo_V40_Text',
    },
    {
        f: '/en/car-makes/volvo/v60',
        t: '/en/findcar?makesModels=Volvo%2CV60&cmsid=Volvo_V60_Text',
    },
    {
        f: '/en/car-makes/volvo/v90',
        t: '/en/findcar?makesModels=Volvo%2CV90&cmsid=Volvo_V90_Text',
    },
    {
        f: '/en/car-makes/volvo/xc60',
        t: '/en/findcar?makesModels=Volvo%2CXC60&cmsid=Volvo_XC60_Text',
    },
    {
        f: '/en/car-makes/volvo/xc90',
        t: '/en/findcar?makesModels=Volvo%2CXC90&cmsid=Volvo_XC90_Text',
    },
    {
        f: '/en/car-makes/volvo',
        t: '/en/findcar?makesModels=Volvo&cmsid=SEO_Volvo',
    },
    {
        f: '/en/origin/used-cars-belgium',
        t: '/en/findcar?originCountries=be&cmsid=used-cars-belgium',
    },
    {
        f: '/en/origin/used-cars-germany',
        t: '/en/findcar?originCountries=de&cmsid=used-cars-germany',
    },
    {
        f: '/en/origin/used-cars-spain',
        t: '/en/findcar?originCountries=es&cmsid=used-cars-spain',
    },
    {
        f: '/en/origin/used-cars-france',
        t: '/en/findcar?originCountries=fr&cmsid=used-cars-france',
    },
    {
        f: '/en/origin/used-cars-italy',
        t: '/en/findcar?originCountries=it&cmsid=used-cars-italy',
    },
    {
        f: '/en/origin/used-cars-luxembourg',
        t: '/en/findcar?originCountries=lu&cmsid=used-cars-luxembourg',
    },
    {
        f: '/en/origin/used-cars-holland',
        t: '/en/findcar?originCountries=nl&cmsid=used-cars-holland',
    },
    {
        f: '/en/origin/used-cars-poland',
        t: '/en/findcar?originCountries=pl&cmsid=used-cars-poland',
    },
    {
        f: '/en/origin/used-cars-sweden',
        t: '/en/findcar?originCountries=se&cmsid=used-cars-sweden',
    },
    {
        f: '/en/used-car-prices/cheap-used-cars',
        t: '/en/findcar?prices=0%2C3000&cmsid=cheap_used_cars',
    },
    {
        f: '/en/used-car-prices/middle-class',
        t: '/en/findcar?prices=10000%2C20000&cmsid=Middle_class',
    },
    {
        f: '/en/used-car-prices/luxury-cars',
        t: '/en/findcar?prices=20000%2C0&cmsid=luxury_cars',
    },
    {
        f: '/en/used-car-prices/economy-cars',
        t: '/en/findcar?prices=3000%2C6000&cmsid=economy_cars',
    },
    {
        f: '/en/used-car-prices/value-for-money',
        t: '/en/findcar?prices=6000%2C10000&cmsid=Value_for_money',
    },
    { f: '/es/carroceria/camion', t: '/es/findcar?bodyTypes=Truck&cmsid=Truck' },
    {
        f: '/es/subasta-automoviles/reserva-garantizada',
        t: '/es/findcar?assignment100pc=true&cmsid=100assignment',
    },
    {
        f: '/es/subasta-automoviles/dinamicas',
        t: '/es/findcar?auctionTypes=1&cmsid=dynamic',
    },
    {
        f: '/es/subasta-automoviles/comprar-ahora',
        t: '/es/findcar?auctionTypes=2&cmsid=buy-now',
    },
    {
        f: '/es/subasta-automoviles/ciega',
        t: '/es/findcar?auctionTypes=4&cmsid=blind',
    },
    {
        f: '/es/subasta-automoviles/meta',
        t: '/es/findcar?auctionTypes=5&cmsid=target',
    },
    {
        f: '/es/carroceria/klasa-sedan',
        t: '/es/findcar?bodyTypes=Berline&cmsid=SEO_Berline',
    },
    {
        f: '/es/carroceria/combi',
        t: '/es/findcar?bodyTypes=Break&cmsid=SEO_Break',
    },
    {
        f: '/es/carroceria/cabrio',
        t: '/es/findcar?bodyTypes=Cabriolet&cmsid=SEO_Cabrio',
    },
    {
        f: '/es/carroceria/coche-compacto',
        t: '/es/findcar?bodyTypes=Compact&cmsid=SEO_Compact',
    },
    {
        f: '/es/carroceria/coupe',
        t: '/es/findcar?bodyTypes=Coup%C3%A9&cmsid=SEO_Coup%C3%A9',
    },
    {
        f: '/es/carroceria/hatchback',
        t: '/es/findcar?bodyTypes=Hatchback&cmsid=SEO_Hatchback',
    },
    {
        f: '/es/carroceria/furgoneta',
        t: '/es/findcar?bodyTypes=Lighttruck&cmsid=SEO_Light-truck',
    },
    {
        f: '/es/carroceria/minibus',
        t: '/es/findcar?bodyTypes=Minibus&cmsid=Minibus',
    },
    {
        f: '/es/carroceria/motos',
        t: '/es/findcar?bodyTypes=Motorbike&cmsid=Motorbike',
    },
    {
        f: '/es/carroceria/monovolumen',
        t: '/es/findcar?bodyTypes=MPV&cmsid=SEO_MPV',
    },
    {
        f: '/es/carroceria/todoterreno',
        t: '/es/findcar?bodyTypes=SUV&cmsid=SEO_SUV',
    },
    {
        f: '/es/subasta-automoviles/sin-danos',
        t: '/es/findcar?damageTypes=1&cmsid=no_specific-Damages',
    },
    {
        f: '/es/subasta-automoviles/coches-accidentados',
        t: '/es/findcar?damageTypes=2&cmsid=Body-damages',
    },
    {
        f: '/es/subasta-automoviles/coches-averiados',
        t: '/es/findcar?damageTypes=3&cmsid=technical-damages',
    },
    {
        f: '/es/subasta-automoviles/carrocaria-avaria-tecnica-estragos',
        t: '/es/findcar?damageTypes=4&cmsid=Technical-and-body-damages',
    },
    {
        f: '/es/marca-coche/abarth',
        t: '/es/findcar?makesModels=Abarth&cmsid=SEO_Abarth',
    },
    {
        f: '/es/marca-coche/alfa-romeo',
        t: '/es/findcar?makesModels=Alfa%20Romeo&cmsid=SEO_alfa-romeo',
    },
    {
        f: '/es/marca-coche/audi/a1',
        t: '/es/findcar?makesModels=Audi%2CA1&cmsid=Audi_A1_Text',
    },
    {
        f: '/es/marca-coche/audi/a2',
        t: '/es/findcar?makesModels=Audi%2CA2&cmsid=Audi_A2_Text',
    },
    {
        f: '/es/marca-coche/audi/a3',
        t: '/es/findcar?makesModels=Audi%2CA3&cmsid=Audi_A3_Text',
    },
    {
        f: '/es/marca-coche/audi/a4',
        t: '/es/findcar?makesModels=Audi%2CA4&cmsid=Audi_A4_Text',
    },
    {
        f: '/es/marca-coche/audi/a5',
        t: '/es/findcar?makesModels=Audi%2CA5&cmsid=Audi_A5_Text',
    },
    {
        f: '/es/marca-coche/audi/a6',
        t: '/es/findcar?makesModels=Audi%2CA6&cmsid=Audi_A6_Text',
    },
    {
        f: '/es/marca-coche/audi/q3',
        t: '/es/findcar?makesModels=Audi%2CQ3&cmsid=Audi_Q3_Text',
    },
    {
        f: '/es/marca-coche/audi/q5',
        t: '/es/findcar?makesModels=Audi%2CQ5&cmsid=Audi_Q5_Text',
    },
    {
        f: '/es/marca-coche/audi/q7',
        t: '/es/findcar?makesModels=Audi%2CQ7&cmsid=Audi_Q7_Text',
    },
    {
        f: '/es/marca-coche/audi',
        t: '/es/findcar?makesModels=Audi&cmsid=SEO_Audi',
    },
    {
        f: '/es/marca-coche/bmw/1',
        t: '/es/findcar?makesModels=BMW%2C1%20Series&cmsid=BMW_1series_Text',
    },
    {
        f: '/es/marca-coche/bmw/2',
        t: '/es/findcar?makesModels=BMW%2C2%20Series&cmsid=BMW_2series_Text',
    },
    {
        f: '/es/marca-coche/bmw/3',
        t: '/es/findcar?makesModels=BMW%2C3%20Series&cmsid=BMW_3series_Text',
    },
    {
        f: '/es/marca-coche/bmw/4',
        t: '/es/findcar?makesModels=BMW%2C4%20Series&cmsid=BMW_4series_Text',
    },
    {
        f: '/es/marca-coche/bmw/5',
        t: '/es/findcar?makesModels=BMW%2C5%20Series&cmsid=BMW_5series_Text',
    },
    {
        f: '/es/marca-coche/bmw/x1',
        t: '/es/findcar?makesModels=BMW%2CX1&cmsid=BMW_X1_Text',
    },
    {
        f: '/es/marca-coche/bmw/x3',
        t: '/es/findcar?makesModels=BMW%2CX3&cmsid=BMW_X3_Text',
    },
    {
        f: '/es/marca-coche/bmw/x5',
        t: '/es/findcar?makesModels=BMW%2CX5&cmsid=BMW_X5_Text',
    },
    {
        f: '/es/marca-coche/bmw/x6',
        t: '/es/findcar?makesModels=BMW%2CX6&cmsid=BMW_X6_Text',
    },
    { f: '/es/marca-coche/bmw', t: '/es/findcar?makesModels=BMW&cmsid=SEO_BMW' },
    {
        f: '/es/marca-coche/citroen/berlingo',
        t: '/es/findcar?makesModels=Citro%C3%ABn%2CBerlingo&cmsid=Citro%C3%ABn_Berlingo_text',
    },
    {
        f: '/es/marca-coche/citroen/c3',
        t: '/es/findcar?makesModels=Citro%C3%ABn%2CC3&cmsid=Citro%C3%ABn_C3_text',
    },
    {
        f: '/es/marca-coche/citroen/c4',
        t: '/es/findcar?makesModels=Citro%C3%ABn%2CC4&cmsid=Citro%C3%ABn_C4_text',
    },
    {
        f: '/es/marca-coche/citroen/c5',
        t: '/es/findcar?makesModels=Citro%C3%ABn%2CC5&cmsid=Citro%C3%ABn_C5_text',
    },
    {
        f: '/es/marca-coche/citroen/ds3',
        t: '/es/findcar?makesModels=Citro%C3%ABn%2CDS3&cmsid=Citro%C3%ABn_DS3_text',
    },
    {
        f: '/es/marca-coche/citroen/ds4',
        t: '/es/findcar?makesModels=Citro%C3%ABn%2CDS4&cmsid=Citro%C3%ABn_DS4_text',
    },
    {
        f: '/es/marca-coche/citroen/dispatch',
        t: '/es/findcar?makesModels=Citro%C3%ABn%2CJumpy&cmsid=Citro%C3%ABn_Dispatch_text',
    },
    {
        f: '/es/marca-coche/citroen',
        t: '/es/findcar?makesModels=Citro%C3%ABn&cmsid=SEO_Citro%C3%ABn',
    },
    {
        f: '/es/marca-coche/fiat/500',
        t: '/es/findcar?makesModels=Fiat%2C500&cmsid=Fiat_500_Text',
    },
    {
        f: '/es/marca-coche/fiat/bravo',
        t: '/es/findcar?makesModels=Fiat%2CBravo&cmsid=Fiat_bravo_Text',
    },
    {
        f: '/es/marca-coche/fiat/doblo',
        t: '/es/findcar?makesModels=Fiat%2CDoblo&cmsid=Fiat_doblo_Text',
    },
    {
        f: '/es/marca-coche/fiat/ducato',
        t: '/es/findcar?makesModels=Fiat%2CDucato&cmsid=Fiat_ducato_Text',
    },
    {
        f: '/es/marca-coche/fiat/freemont',
        t: '/es/findcar?makesModels=Fiat%2CFreemont&cmsid=Fiat_freemont_Text',
    },
    {
        f: '/es/marca-coche/fiat/panda',
        t: '/es/findcar?makesModels=Fiat%2CPanda&cmsid=Fiat_panda_Text',
    },
    {
        f: '/es/marca-coche/fiat/punto',
        t: '/es/findcar?makesModels=Fiat%2CPunto&cmsid=Fiat_punto_Text',
    },
    {
        f: '/es/marca-coche/fiat',
        t: '/es/findcar?makesModels=Fiat&cmsid=SEO_Fiat',
    },
    {
        f: '/es/marca-coche/ford/fiesta',
        t: '/es/findcar?makesModels=Ford%2CFiesta&cmsid=Ford_Fiesta_Text',
    },
    {
        f: '/es/marca-coche/ford/focus',
        t: '/es/findcar?makesModels=Ford%2CFocus&cmsid=Ford_Focus_Text',
    },
    {
        f: '/es/marca-coche/ford/mondeo',
        t: '/es/findcar?makesModels=Ford%2CMondeo&cmsid=Ford_Mondeo_Text',
    },
    {
        f: '/es/marca-coche/ford/transit',
        t: '/es/findcar?makesModels=Ford%2CTransit&cmsid=Ford_Transit_Text',
    },
    {
        f: '/es/marca-coche/ford',
        t: '/es/findcar?makesModels=Ford&cmsid=SEO_Ford',
    },
    {
        f: '/es/marca-coche/lancia',
        t: '/es/findcar?makesModels=Lancia&cmsid=SEO_Lancia',
    },
    {
        f: '/es/marca-coche/mercedes/clase-a',
        t: '/es/findcar?makesModels=Mercedes-Benz%2CA&cmsid=Mercedes_A-class_Text',
    },
    {
        f: '/es/marca-coche/mercedes/clase-b',
        t: '/es/findcar?makesModels=Mercedes-Benz%2CB&cmsid=Mercedes_B-class_Text',
    },
    {
        f: '/es/marca-coche/mercedes/clase-c',
        t: '/es/findcar?makesModels=Mercedes-Benz%2CC&cmsid=Mercedes_C-class_Text',
    },
    {
        f: '/es/marca-coche/mercedes/clase-e',
        t: '/es/findcar?makesModels=Mercedes-Benz%2CE&cmsid=Mercedes_E-class_Text',
    },
    {
        f: '/es/marca-coche/mercedes/sprinter',
        t: '/es/findcar?makesModels=Mercedes-Benz%2CSprinter&cmsid=Mercedes_Sprinter_Text',
    },
    {
        f: '/es/marca-coche/mercedes/vito',
        t: '/es/findcar?makesModels=Mercedes-Benz%2CVito&cmsid=Mercedes_Vito_Text',
    },
    {
        f: '/es/marca-coche/mercedes',
        t: '/es/findcar?makesModels=Mercedes-Benz&cmsid=SEO_Mercedes',
    },
    {
        f: '/es/marca-coche/nissan/juke',
        t: '/es/findcar?makesModels=Nissan%2CJuke&cmsid=Nissan_Juke_Text',
    },
    {
        f: '/es/marca-coche/nissan/micra',
        t: '/es/findcar?makesModels=Nissan%2CMicra&cmsid=Nissan_micra_Text',
    },
    {
        f: '/es/marca-coche/nissan/pathfinder',
        t: '/es/findcar?makesModels=Nissan%2CPathfinder&cmsid=Nissan_Pathfinder_Text',
    },
    {
        f: '/es/marca-coche/nissan/qashqai',
        t: '/es/findcar?makesModels=Nissan%2CQashqai&cmsid=Nissan_Qashqai_Text',
    },
    {
        f: '/es/marca-coche/nissan/x-trail',
        t: '/es/findcar?makesModels=Nissan%2CX-Trail&cmsid=Nissan_x-trail_Text',
    },
    {
        f: '/es/marca-coche/nissan',
        t: '/es/findcar?makesModels=Nissan&cmsid=SEO_Nissan',
    },
    {
        f: '/es/marca-coche/opel/astra',
        t: '/es/findcar?makesModels=Opel%2CAstra&cmsid=Opel_Astra_Text',
    },
    {
        f: '/es/marca-coche/opel/combo',
        t: '/es/findcar?makesModels=Opel%2CCombo&cmsid=Opel_Combo_Text',
    },
    {
        f: '/es/marca-coche/opel/corsa',
        t: '/es/findcar?makesModels=Opel%2CCorsa&cmsid=Opel_Corsa_Text',
    },
    {
        f: '/es/marca-coche/opel/insignia',
        t: '/es/findcar?makesModels=Opel%2CInsignia&cmsid=Opel_Insignia_Text',
    },
    {
        f: '/es/marca-coche/opel/meriva',
        t: '/es/findcar?makesModels=Opel%2CMeriva&cmsid=Opel_Meriva_Text',
    },
    {
        f: '/es/marca-coche/opel/movano',
        t: '/es/findcar?makesModels=Opel%2CMovano&cmsid=Opel_Movano_Text',
    },
    {
        f: '/es/marca-coche/opel/vivaro',
        t: '/es/findcar?makesModels=Opel%2CVivaro&cmsid=Opel_Vivaro_Text',
    },
    {
        f: '/es/marca-coche/opel/zafira',
        t: '/es/findcar?makesModels=Opel%2CZafira&cmsid=Opel_Zafira_Text',
    },
    {
        f: '/es/marca-coche/opel',
        t: '/es/findcar?makesModels=Opel&cmsid=Seo_Opel',
    },
    {
        f: '/es/marca-coche/peugeot/407sw',
        t: '/es/findcar?makesModels=Peugeot%2C40x&cmsid=Peugeot_407sw_Text',
    },
    {
        f: '/es/marca-coche/peugeot/5008',
        t: '/es/findcar?makesModels=Peugeot%2C5008&cmsid=Peugeot_5008_Text',
    },
    {
        f: '/es/marca-coche/peugeot/expert',
        t: '/es/findcar?makesModels=Peugeot%2CExpert&cmsid=Peugeot_Expert_Text',
    },
    {
        f: '/es/marca-coche/peugeot/partner',
        t: '/es/findcar?makesModels=Peugeot%2CPartner&cmsid=Peugeot_Partner_Text',
    },
    {
        f: '/es/marca-coche/peugeot',
        t: '/es/findcar?makesModels=Peugeot&cmsid=Seo_Peugeot',
    },
    {
        f: '/es/marca-coche/piaggio',
        t: '/es/findcar?makesModels=Piaggio&cmsid=SEO_Piaggio',
    },
    {
        f: '/es/marca-coche/renault/clio',
        t: '/es/findcar?makesModels=Renault%2CClio&cmsid=Renault_Clio_Text',
    },
    {
        f: '/es/marca-coche/renault/espace',
        t: '/es/findcar?makesModels=Renault%2CEspace&cmsid=Renault_Espace_Text',
    },
    {
        f: '/es/marca-coche/renault/kangoo',
        t: '/es/findcar?makesModels=Renault%2CKangoo&cmsid=Renault_Kangoo_Text',
    },
    {
        f: '/es/marca-coche/renault/megane',
        t: '/es/findcar?makesModels=Renault%2CMegane&cmsid=Renault_Megane_Text',
    },
    {
        f: '/es/marca-coche/renault/scenic',
        t: '/es/findcar?makesModels=Renault%2CScenic&cmsid=Renault_Scenic_Text',
    },
    {
        f: '/es/marca-coche/renault',
        t: '/es/findcar?makesModels=Renault&cmsid=SEO_Renault',
    },
    {
        f: '/es/marca-coche/skoda/fabia',
        t: '/es/findcar?makesModels=Skoda%2CFabia&cmsid=Skoda_Fabia_Text',
    },
    {
        f: '/es/marca-coche/skoda/octavia',
        t: '/es/findcar?makesModels=Skoda%2COctavia&cmsid=Skoda_Octavia_Text',
    },
    {
        f: '/es/marca-coche/skoda/rapid',
        t: '/es/findcar?makesModels=Skoda%2CRapid&cmsid=Skoda_Rapid_Text',
    },
    {
        f: '/es/marca-coche/skoda/superb',
        t: '/es/findcar?makesModels=Skoda%2CSuperb&cmsid=Skoda_Superb_Text',
    },
    {
        f: '/es/marca-coche/skoda/yeti',
        t: '/es/findcar?makesModels=Skoda%2CYeti&cmsid=Skoda_Yeti_Text',
    },
    {
        f: '/es/marca-coche/skoda',
        t: '/es/findcar?makesModels=Skoda&cmsid=SEO_Skoda',
    },
    {
        f: '/es/marca-coche/toyota/auris',
        t: '/es/findcar?makesModels=Toyota%2CAuris&cmsid=Toyota_Auris_Text',
    },
    {
        f: '/es/marca-coche/toyota/avensis',
        t: '/es/findcar?makesModels=Toyota%2CAvensis&cmsid=Toyota_Avensis_Text',
    },
    {
        f: '/es/marca-coche/toyota/aygo',
        t: '/es/findcar?makesModels=Toyota%2CAygo&cmsid=Toyota_Aygo_Text',
    },
    {
        f: '/es/marca-coche/toyota/prius',
        t: '/es/findcar?makesModels=Toyota%2CPrius&cmsid=Toyota_Prius_Text',
    },
    {
        f: '/es/marca-coche/toyota/rav4',
        t: '/es/findcar?makesModels=Toyota%2CRav4&cmsid=Toyota_Rav4_Text',
    },
    {
        f: '/es/marca-coche/toyota/yaris',
        t: '/es/findcar?makesModels=Toyota%2CYaris&cmsid=Toyota_Yaris_Text',
    },
    {
        f: '/es/marca-coche/toyota',
        t: '/es/findcar?makesModels=Toyota&cmsid=SEO_Toyota',
    },
    {
        f: '/es/marca-coche/volkswagen/caddy',
        t: '/es/findcar?makesModels=Volkswagen%2CCaddy&cmsid=Volkswagen_Caddy_Text',
    },
    {
        f: '/es/marca-coche/volkswagen/golf',
        t: '/es/findcar?makesModels=Volkswagen%2CGolf&cmsid=Volkswagen_Golf_Text',
    },
    {
        f: '/es/marca-coche/volkswagen/passat',
        t: '/es/findcar?makesModels=Volkswagen%2CPassat&cmsid=Volkswagen_Passat_Text',
    },
    {
        f: '/es/marca-coche/volkswagen/polo',
        t: '/es/findcar?makesModels=Volkswagen%2CPolo&cmsid=Volkswagen_Polo_Text',
    },
    {
        f: '/es/marca-coche/volkswagen/tiguan',
        t: '/es/findcar?makesModels=Volkswagen%2CTiguan&cmsid=Volkswagen_Tiguan_Text',
    },
    {
        f: '/es/marca-coche/volkswagen/touareg',
        t: '/es/findcar?makesModels=Volkswagen%2CTouareg&cmsid=Volkswagen_Touareg_Text',
    },
    {
        f: '/es/marca-coche/volkswagen/touran',
        t: '/es/findcar?makesModels=Volkswagen%2CTouran&cmsid=Volkswagen_Touran_Text',
    },
    {
        f: '/es/marca-coche/volkswagen/transporter',
        t: '/es/findcar?makesModels=Volkswagen%2CTransporter&cmsid=Volkswagen_Transporter_Text',
    },
    {
        f: '/es/marca-coche/volkswagen',
        t: '/es/findcar?makesModels=Volkswagen&cmsid=SEO_Volkswagen',
    },
    {
        f: '/es/marca-coche/volvo/s60',
        t: '/es/findcar?makesModels=Volvo%2CS60&cmsid=Volvo_S60_Text',
    },
    {
        f: '/es/marca-coche/volvo/s80',
        t: '/es/findcar?makesModels=Volvo%2CS80&cmsid=Volvo_S80_Text',
    },
    {
        f: '/es/marca-coche/volvo/s90',
        t: '/es/findcar?makesModels=Volvo%2CS90&cmsid=Volvo_S90_Text',
    },
    {
        f: '/es/marca-coche/volvo/v40',
        t: '/es/findcar?makesModels=Volvo%2CV40&cmsid=Volvo_V40_Text',
    },
    {
        f: '/es/marca-coche/volvo/v60',
        t: '/es/findcar?makesModels=Volvo%2CV60&cmsid=Volvo_V60_Text',
    },
    {
        f: '/es/marca-coche/volvo/v90',
        t: '/es/findcar?makesModels=Volvo%2CV90&cmsid=Volvo_V90_Text',
    },
    {
        f: '/es/marca-coche/volvo/xc60',
        t: '/es/findcar?makesModels=Volvo%2CXC60&cmsid=Volvo_XC60_Text',
    },
    {
        f: '/es/marca-coche/volvo/xc90',
        t: '/es/findcar?makesModels=Volvo%2CXC90&cmsid=Volvo_XC90_Text',
    },
    {
        f: '/es/marca-coche/volvo',
        t: '/es/findcar?makesModels=Volvo&cmsid=SEO_Volvo',
    },
    {
        f: '/es/coches-segunda-mano-belgia',
        t: '/es/findcar?originCountries=be&cmsid=used-cars-belgium',
    },
    {
        f: '/es/coches-alemanes',
        t: '/es/findcar?originCountries=de&cmsid=used-cars-germany',
    },
    {
        f: '/es/coches-usados-espana',
        t: '/es/findcar?originCountries=es&cmsid=used-cars-spain',
    },
    {
        f: '/es/coches-segunda-mano-francia',
        t: '/es/findcar?originCountries=fr&cmsid=used-cars-france',
    },
    {
        f: '/es/coches-italianos',
        t: '/es/findcar?originCountries=it&cmsid=used-cars-italy',
    },
    {
        f: '/es/coches-luxemburgo',
        t: '/es/findcar?originCountries=lu&cmsid=used-cars-luxembourg',
    },
    {
        f: '/es/coches-holandeses',
        t: '/es/findcar?originCountries=nl&cmsid=used-cars-holland',
    },
    {
        f: '/es/coches-polonia',
        t: '/es/findcar?originCountries=pl&cmsid=used-cars-poland',
    },
    {
        f: '/es/coches-suencia',
        t: '/es/findcar?originCountries=se&cmsid=used-cars-sweden',
    },
    {
        f: '/es/precios-segunda-mano/coches-baratos',
        t: '/es/findcar?prices=0%2C3000&cmsid=cheap_used_cars',
    },
    {
        f: '/es/precios-segunda-mano/clase-media',
        t: '/es/findcar?prices=10000%2C20000&cmsid=Middle_class',
    },
    {
        f: '/es/precios-segunda-mano/lujo',
        t: '/es/findcar?prices=20000%2C0&cmsid=luxury_cars',
    },
    {
        f: '/es/precios-segunda-mano/automoviles-economicos',
        t: '/es/findcar?prices=3000%2C6000&cmsid=economy_cars',
    },
    {
        f: '/es/precios-segunda-mano/precio',
        t: '/es/findcar?prices=6000%2C10000&cmsid=Value_for_money',
    },
    {
        f: '/fr-be/type-de-carrosserie/camion',
        t: '/fr-be/findcar?bodyTypes=Truck&cmsid=Truck',
    },
    {
        f: '/fr-be/encheres-voitures/100-pourcent-d-attribution',
        t: '/fr-be/findcar?assignment100pc=true&cmsid=100assignment',
    },
    {
        f: '/fr-be/encheres-voitures/dynamique',
        t: '/fr-be/findcar?auctionTypes=1&cmsid=dynamic',
    },
    {
        f: '/fr-be/encheres-voitures/voitures-occasion-achat-immediat',
        t: '/fr-be/findcar?auctionTypes=2&cmsid=buy-now',
    },
    {
        f: '/fr-be/encheres-voitures/unique',
        t: '/fr-be/findcar?auctionTypes=4&cmsid=blind',
    },
    {
        f: '/fr-be/encheres-voitures/voitures-encheres-prix-de-reserve',
        t: '/fr-be/findcar?auctionTypes=5&cmsid=target',
    },
    {
        f: '/fr-be/type-de-carrosserie/berline',
        t: '/fr-be/findcar?bodyTypes=Berline&cmsid=SEO_Berline',
    },
    {
        f: '/fr-be/type-de-carrosserie/break',
        t: '/fr-be/findcar?bodyTypes=Break&cmsid=SEO_Break',
    },
    {
        f: '/fr-be/type-de-carrosserie/cabriolet',
        t: '/fr-be/findcar?bodyTypes=Cabriolet&cmsid=SEO_Cabrio',
    },
    {
        f: '/fr-be/type-de-carrosserie/compact',
        t: '/fr-be/findcar?bodyTypes=Compact&cmsid=SEO_Compact',
    },
    {
        f: '/fr-be/type-de-carrosserie/coupe',
        t: '/fr-be/findcar?bodyTypes=Coup%C3%A9&cmsid=SEO_Coup%C3%A9',
    },
    {
        f: '/fr-be/type-de-carrosserie/bicorps',
        t: '/fr-be/findcar?bodyTypes=Hatchback&cmsid=SEO_Hatchback',
    },
    {
        f: '/fr-be/type-de-carrosserie/camionnette',
        t: '/fr-be/findcar?bodyTypes=Lighttruck&cmsid=SEO_Light-truck',
    },
    {
        f: '/fr-be/type-de-carrosserie/minibus',
        t: '/fr-be/findcar?bodyTypes=Minibus&cmsid=Minibus',
    },
    {
        f: '/fr-be/type-de-carrosserie/moto',
        t: '/fr-be/findcar?bodyTypes=Motorbike&cmsid=Motorbike',
    },
    {
        f: '/fr-be/type-de-carrosserie/monospace',
        t: '/fr-be/findcar?bodyTypes=MPV&cmsid=SEO_MPV',
    },
    {
        f: '/fr-be/type-de-carrosserie/suv',
        t: '/fr-be/findcar?bodyTypes=SUV&cmsid=SEO_SUV',
    },
    {
        f: '/fr-be/encheres-voitures/voitures-occasion-bon-etat',
        t: '/fr-be/findcar?damageTypes=1&cmsid=no_specific-Damages',
    },
    {
        f: '/fr-be/encheres-voitures/vehicules-accidentes-occasion-a-vendre',
        t: '/fr-be/findcar?damageTypes=2&cmsid=Body-damages',
    },
    {
        f: '/fr-be/encheres-voitures/achat-voitures-dommages-techniques',
        t: '/fr-be/findcar?damageTypes=3&cmsid=technical-damages',
    },
    {
        f: '/fr-be/encheres-voitures/vehicules-avec-degats-techniques-et-de-carrosserie',
        t: '/fr-be/findcar?damageTypes=4&cmsid=Technical-and-body-damages',
    },
    {
        f: '/fr-be/voiture-marque/abarth',
        t: '/fr-be/findcar?makesModels=Abarth&cmsid=SEO_Abarth',
    },
    {
        f: '/fr-be/voiture-marque/alfa-romeo',
        t: '/fr-be/findcar?makesModels=Alfa%20Romeo&cmsid=SEO_alfa-romeo',
    },
    {
        f: '/fr-be/voiture-marque/audi/a1',
        t: '/fr-be/findcar?makesModels=Audi%2CA1&cmsid=Audi_A1_Text',
    },
    {
        f: '/fr-be/voiture-marque/audi/a2',
        t: '/fr-be/findcar?makesModels=Audi%2CA2&cmsid=Audi_A2_Text',
    },
    {
        f: '/fr-be/voiture-marque/audi/a3',
        t: '/fr-be/findcar?makesModels=Audi%2CA3&cmsid=Audi_A3_Text',
    },
    {
        f: '/fr-be/voiture-marque/audi/a4',
        t: '/fr-be/findcar?makesModels=Audi%2CA4&cmsid=Audi_A4_Text',
    },
    {
        f: '/fr-be/voiture-marque/audi/a5',
        t: '/fr-be/findcar?makesModels=Audi%2CA5&cmsid=Audi_A5_Text',
    },
    {
        f: '/fr-be/voiture-marque/audi/a6',
        t: '/fr-be/findcar?makesModels=Audi%2CA6&cmsid=Audi_A6_Text',
    },
    {
        f: '/fr-be/voiture-marque/audi/q3',
        t: '/fr-be/findcar?makesModels=Audi%2CQ3&cmsid=Audi_Q3_Text',
    },
    {
        f: '/fr-be/voiture-marque/audi/q5',
        t: '/fr-be/findcar?makesModels=Audi%2CQ5&cmsid=Audi_Q5_Text',
    },
    {
        f: '/fr-be/voiture-marque/audi/q7',
        t: '/fr-be/findcar?makesModels=Audi%2CQ7&cmsid=Audi_Q7_Text',
    },
    {
        f: '/fr-be/voiture-marque/audi',
        t: '/fr-be/findcar?makesModels=Audi&cmsid=SEO_Audi',
    },
    {
        f: '/fr-be/voiture-marque/bmw/serie-1',
        t: '/fr-be/findcar?makesModels=BMW%2C1%20Series&cmsid=BMW_1series_Text',
    },
    {
        f: '/fr-be/voiture-marque/bmw/serie-2',
        t: '/fr-be/findcar?makesModels=BMW%2C2%20Series&cmsid=BMW_2series_Text',
    },
    {
        f: '/fr-be/voiture-marque/bmw/serie-3',
        t: '/fr-be/findcar?makesModels=BMW%2C3%20Series&cmsid=BMW_3series_Text',
    },
    {
        f: '/fr-be/voiture-marque/bmw/serie-4',
        t: '/fr-be/findcar?makesModels=BMW%2C4%20Series&cmsid=BMW_4series_Text',
    },
    {
        f: '/fr-be/voiture-marque/bmw/serie-5',
        t: '/fr-be/findcar?makesModels=BMW%2C5%20Series&cmsid=BMW_5series_Text',
    },
    {
        f: '/fr-be/voiture-marque/bmw/x1',
        t: '/fr-be/findcar?makesModels=BMW%2CX1&cmsid=BMW_X1_Text',
    },
    {
        f: '/fr-be/voiture-marque/bmw/x3',
        t: '/fr-be/findcar?makesModels=BMW%2CX3&cmsid=BMW_X3_Text',
    },
    {
        f: '/fr-be/voiture-marque/bmw/x5',
        t: '/fr-be/findcar?makesModels=BMW%2CX5&cmsid=BMW_X5_Text',
    },
    {
        f: '/fr-be/voiture-marque/bmw/x6',
        t: '/fr-be/findcar?makesModels=BMW%2CX6&cmsid=BMW_X6_Text',
    },
    {
        f: '/fr-be/voiture-marque/bmw',
        t: '/fr-be/findcar?makesModels=BMW&cmsid=SEO_BMW',
    },
    {
        f: '/fr-be/voiture-marque/citroen/berlingo',
        t: '/fr-be/findcar?makesModels=Citro%C3%ABn%2CBerlingo&cmsid=Citro%C3%ABn_Berlingo_text',
    },
    {
        f: '/fr-be/voiture-marque/citroen/c3',
        t: '/fr-be/findcar?makesModels=Citro%C3%ABn%2CC3&cmsid=Citro%C3%ABn_C3_text',
    },
    {
        f: '/fr-be/voiture-marque/citroen/c4',
        t: '/fr-be/findcar?makesModels=Citro%C3%ABn%2CC4&cmsid=Citro%C3%ABn_C4_text',
    },
    {
        f: '/fr-be/voiture-marque/citroen/c5',
        t: '/fr-be/findcar?makesModels=Citro%C3%ABn%2CC5&cmsid=Citro%C3%ABn_C5_text',
    },
    {
        f: '/fr-be/voiture-marque/citroen/ds3',
        t: '/fr-be/findcar?makesModels=Citro%C3%ABn%2CDS3&cmsid=Citro%C3%ABn_DS3_text',
    },
    {
        f: '/fr-be/voiture-marque/citroen/ds4',
        t: '/fr-be/findcar?makesModels=Citro%C3%ABn%2CDS4&cmsid=Citro%C3%ABn_DS4_text',
    },
    {
        f: '/fr-be/voiture-marque/citroen/dispatch',
        t: '/fr-be/findcar?makesModels=Citro%C3%ABn%2CJumpy&cmsid=Citro%C3%ABn_Dispatch_text',
    },
    {
        f: '/fr-be/voiture-marque/citroen',
        t: '/fr-be/findcar?makesModels=Citro%C3%ABn&cmsid=SEO_Citro%C3%ABn',
    },
    {
        f: '/fr-be/voiture-marque/fiat/500',
        t: '/fr-be/findcar?makesModels=Fiat%2C500&cmsid=Fiat_500_Text',
    },
    {
        f: '/fr-be/voiture-marque/fiat/bravo',
        t: '/fr-be/findcar?makesModels=Fiat%2CBravo&cmsid=Fiat_bravo_Text',
    },
    {
        f: '/fr-be/voiture-marque/fiat/doblo',
        t: '/fr-be/findcar?makesModels=Fiat%2CDoblo&cmsid=Fiat_doblo_Text',
    },
    {
        f: '/fr-be/voiture-marque/fiat/ducato',
        t: '/fr-be/findcar?makesModels=Fiat%2CDucato&cmsid=Fiat_ducato_Text',
    },
    {
        f: '/fr-be/voiture-marque/fiat/freemont',
        t: '/fr-be/findcar?makesModels=Fiat%2CFreemont&cmsid=Fiat_freemont_Text',
    },
    {
        f: '/fr-be/voiture-marque/fiat/panda',
        t: '/fr-be/findcar?makesModels=Fiat%2CPanda&cmsid=Fiat_panda_Text',
    },
    {
        f: '/fr-be/voiture-marque/fiat/punto',
        t: '/fr-be/findcar?makesModels=Fiat%2CPunto&cmsid=Fiat_punto_Text',
    },
    {
        f: '/fr-be/voiture-marque/fiat',
        t: '/fr-be/findcar?makesModels=Fiat&cmsid=SEO_Fiat',
    },
    {
        f: '/fr-be/voiture-marque/ford/focus',
        t: '/fr-be/findcar?makesModels=Ford%2CFiesta&cmsid=Ford_Fiesta_Text',
    },
    {
        f: '/fr-be/voiture-marque/ford/fiesta',
        t: '/fr-be/findcar?makesModels=Ford%2CFocus&cmsid=Ford_Focus_Text',
    },
    {
        f: '/fr-be/voiture-marque/ford/mondeo',
        t: '/fr-be/findcar?makesModels=Ford%2CMondeo&cmsid=Ford_Mondeo_Text',
    },
    {
        f: '/fr-be/voiture-marque/ford/transit',
        t: '/fr-be/findcar?makesModels=Ford%2CTransit&cmsid=Ford_Transit_Text',
    },
    {
        f: '/fr-be/voiture-marque/ford',
        t: '/fr-be/findcar?makesModels=Ford&cmsid=SEO_Ford',
    },
    {
        f: '/fr-be/voiture-marque/lancia',
        t: '/fr-be/findcar?makesModels=Lancia&cmsid=SEO_Lancia',
    },
    {
        f: '/fr-be/voiture-marque/mercedes/classe-a',
        t: '/fr-be/findcar?makesModels=Mercedes-Benz%2CA&cmsid=Mercedes_A-class_Text',
    },
    {
        f: '/fr-be/voiture-marque/mercedes/classe-b',
        t: '/fr-be/findcar?makesModels=Mercedes-Benz%2CB&cmsid=Mercedes_B-class_Text',
    },
    {
        f: '/fr-be/voiture-marque/mercedes/classe-c',
        t: '/fr-be/findcar?makesModels=Mercedes-Benz%2CC&cmsid=Mercedes_C-class_Text',
    },
    {
        f: '/fr-be/voiture-marque/mercedes/classe-e',
        t: '/fr-be/findcar?makesModels=Mercedes-Benz%2CE&cmsid=Mercedes_E-class_Text',
    },
    {
        f: '/fr-be/voiture-marque/mercedes/sprinter',
        t: '/fr-be/findcar?makesModels=Mercedes-Benz%2CSprinter&cmsid=Mercedes_Sprinter_Text',
    },
    {
        f: '/fr-be/voiture-marque/mercedes/vito',
        t: '/fr-be/findcar?makesModels=Mercedes-Benz%2CVito&cmsid=Mercedes_Vito_Text',
    },
    {
        f: '/fr-be/voiture-marque/mercedes',
        t: '/fr-be/findcar?makesModels=Mercedes-Benz&cmsid=SEO_Mercedes',
    },
    {
        f: '/fr-be/voiture-marque/nissan/juke',
        t: '/fr-be/findcar?makesModels=Nissan%2CJuke&cmsid=Nissan_Juke_Text',
    },
    {
        f: '/fr-be/voiture-marque/nissan/micra',
        t: '/fr-be/findcar?makesModels=Nissan%2CMicra&cmsid=Nissan_micra_Text',
    },
    {
        f: '/fr-be/voiture-marque/nissan/pathfinder',
        t: '/fr-be/findcar?makesModels=Nissan%2CPathfinder&cmsid=Nissan_Pathfinder_Text',
    },
    {
        f: '/fr-be/voiture-marque/nissan/qashqai',
        t: '/fr-be/findcar?makesModels=Nissan%2CQashqai&cmsid=Nissan_Qashqai_Text',
    },
    {
        f: '/fr-be/voiture-marque/nissan/x-trail',
        t: '/fr-be/findcar?makesModels=Nissan%2CX-Trail&cmsid=Nissan_x-trail_Text',
    },
    {
        f: '/fr-be/voiture-marque/nissan',
        t: '/fr-be/findcar?makesModels=Nissan&cmsid=SEO_Nissan',
    },
    {
        f: '/fr-be/voiture-marque/opel/astra',
        t: '/fr-be/findcar?makesModels=Opel%2CAstra&cmsid=Opel_Astra_Text',
    },
    {
        f: '/fr-be/voiture-marque/opel/combo',
        t: '/fr-be/findcar?makesModels=Opel%2CCombo&cmsid=Opel_Combo_Text',
    },
    {
        f: '/fr-be/voiture-marque/opel/corsa',
        t: '/fr-be/findcar?makesModels=Opel%2CCorsa&cmsid=Opel_Corsa_Text',
    },
    {
        f: '/fr-be/voiture-marque/opel/insignia',
        t: '/fr-be/findcar?makesModels=Opel%2CInsignia&cmsid=Opel_Insignia_Text',
    },
    {
        f: '/fr-be/voiture-marque/opel/meriva',
        t: '/fr-be/findcar?makesModels=Opel%2CMeriva&cmsid=Opel_Meriva_Text',
    },
    {
        f: '/fr-be/voiture-marque/opel/movano',
        t: '/fr-be/findcar?makesModels=Opel%2CMovano&cmsid=Opel_Movano_Text',
    },
    {
        f: '/fr-be/voiture-marque/opel/vivaro',
        t: '/fr-be/findcar?makesModels=Opel%2CVivaro&cmsid=Opel_Vivaro_Text',
    },
    {
        f: '/fr-be/voiture-marque/opel/zafira',
        t: '/fr-be/findcar?makesModels=Opel%2CZafira&cmsid=Opel_Zafira_Text',
    },
    {
        f: '/fr-be/voiture-marque/opel',
        t: '/fr-be/findcar?makesModels=Opel&cmsid=Seo_Opel',
    },
    {
        f: '/fr-be/voiture-marque/peugeot/407sw',
        t: '/fr-be/findcar?makesModels=Peugeot%2C40x&cmsid=Peugeot_407sw_Text',
    },
    {
        f: '/fr-be/voiture-marque/peugeot/5008',
        t: '/fr-be/findcar?makesModels=Peugeot%2C5008&cmsid=Peugeot_5008_Text',
    },
    {
        f: '/fr-be/voiture-marque/peugeot/expert',
        t: '/fr-be/findcar?makesModels=Peugeot%2CExpert&cmsid=Peugeot_Expert_Text',
    },
    {
        f: '/fr-be/voiture-marque/peugeot/partner',
        t: '/fr-be/findcar?makesModels=Peugeot%2CPartner&cmsid=Peugeot_Partner_Text',
    },
    {
        f: '/fr-be/voiture-marque/peugeot',
        t: '/fr-be/findcar?makesModels=Peugeot&cmsid=Seo_Peugeot',
    },
    {
        f: '/fr-be/voiture-marque/piaggio',
        t: '/fr-be/findcar?makesModels=Piaggio&cmsid=SEO_Piaggio',
    },
    {
        f: '/fr-be/voiture-marque/renault/clio',
        t: '/fr-be/findcar?makesModels=Renault%2CClio&cmsid=Renault_Clio_Text',
    },
    {
        f: '/fr-be/voiture-marque/renault/espace',
        t: '/fr-be/findcar?makesModels=Renault%2CEspace&cmsid=Renault_Espace_Text',
    },
    {
        f: '/fr-be/voiture-marque/renault/kangoo',
        t: '/fr-be/findcar?makesModels=Renault%2CKangoo&cmsid=Renault_Kangoo_Text',
    },
    {
        f: '/fr-be/voiture-marque/renault/megane',
        t: '/fr-be/findcar?makesModels=Renault%2CMegane&cmsid=Renault_Megane_Text',
    },
    {
        f: '/fr-be/voiture-marque/renault/scenic',
        t: '/fr-be/findcar?makesModels=Renault%2CScenic&cmsid=Renault_Scenic_Text',
    },
    {
        f: '/fr-be/voiture-marque/renault',
        t: '/fr-be/findcar?makesModels=Renault&cmsid=SEO_Renault',
    },
    {
        f: '/fr-be/voiture-marque/skoda/fabia',
        t: '/fr-be/findcar?makesModels=Skoda%2CFabia&cmsid=Skoda_Fabia_Text',
    },
    {
        f: '/fr-be/voiture-marque/skoda/octavia',
        t: '/fr-be/findcar?makesModels=Skoda%2COctavia&cmsid=Skoda_Octavia_Text',
    },
    {
        f: '/fr-be/voiture-marque/skoda/rapid',
        t: '/fr-be/findcar?makesModels=Skoda%2CRapid&cmsid=Skoda_Rapid_Text',
    },
    {
        f: '/fr-be/voiture-marque/skoda/superb',
        t: '/fr-be/findcar?makesModels=Skoda%2CSuperb&cmsid=Skoda_Superb_Text',
    },
    {
        f: '/fr-be/voiture-marque/skoda/yeti',
        t: '/fr-be/findcar?makesModels=Skoda%2CYeti&cmsid=Skoda_Yeti_Text',
    },
    {
        f: '/fr-be/voiture-marque/skoda',
        t: '/fr-be/findcar?makesModels=Skoda&cmsid=SEO_Skoda',
    },
    {
        f: '/fr-be/voiture-marque/toyota/auris',
        t: '/fr-be/findcar?makesModels=Toyota%2CAuris&cmsid=Toyota_Auris_Text',
    },
    {
        f: '/fr-be/voiture-marque/toyota/avensis',
        t: '/fr-be/findcar?makesModels=Toyota%2CAvensis&cmsid=Toyota_Avensis_Text',
    },
    {
        f: '/fr-be/voiture-marque/toyota/aygo',
        t: '/fr-be/findcar?makesModels=Toyota%2CAygo&cmsid=Toyota_Aygo_Text',
    },
    {
        f: '/fr-be/voiture-marque/toyota/prius',
        t: '/fr-be/findcar?makesModels=Toyota%2CPrius&cmsid=Toyota_Prius_Text',
    },
    {
        f: '/fr-be/voiture-marque/toyota/rav4',
        t: '/fr-be/findcar?makesModels=Toyota%2CRav4&cmsid=Toyota_Rav4_Text',
    },
    {
        f: '/fr-be/voiture-marque/toyota/yaris',
        t: '/fr-be/findcar?makesModels=Toyota%2CYaris&cmsid=Toyota_Yaris_Text',
    },
    {
        f: '/fr-be/voiture-marque/toyota',
        t: '/fr-be/findcar?makesModels=Toyota&cmsid=SEO_Toyota',
    },
    {
        f: '/fr-be/voiture-marque/volkswagen/caddy',
        t: '/fr-be/findcar?makesModels=Volkswagen%2CCaddy&cmsid=Volkswagen_Caddy_Text',
    },
    {
        f: '/fr-be/voiture-marque/volkswagen/golf',
        t: '/fr-be/findcar?makesModels=Volkswagen%2CGolf&cmsid=Volkswagen_Golf_Text',
    },
    {
        f: '/fr-be/voiture-marque/volkswagen/passat',
        t: '/fr-be/findcar?makesModels=Volkswagen%2CPassat&cmsid=Volkswagen_Passat_Text',
    },
    {
        f: '/fr-be/voiture-marque/volkswagen/polo',
        t: '/fr-be/findcar?makesModels=Volkswagen%2CPolo&cmsid=Volkswagen_Polo_Text',
    },
    {
        f: '/fr-be/voiture-marque/volkswagen/tiguan',
        t: '/fr-be/findcar?makesModels=Volkswagen%2CTiguan&cmsid=Volkswagen_Tiguan_Text',
    },
    {
        f: '/fr-be/voiture-marque/volkswagen/touareg',
        t: '/fr-be/findcar?makesModels=Volkswagen%2CTouareg&cmsid=Volkswagen_Touareg_Text',
    },
    {
        f: '/fr-be/voiture-marque/volkswagen/touran',
        t: '/fr-be/findcar?makesModels=Volkswagen%2CTouran&cmsid=Volkswagen_Touran_Text',
    },
    {
        f: '/fr-be/voiture-marque/volkswagen/transporter',
        t: '/fr-be/findcar?makesModels=Volkswagen%2CTransporter&cmsid=Volkswagen_Transporter_Text',
    },
    {
        f: '/fr-be/voiture-marque/volkswagen',
        t: '/fr-be/findcar?makesModels=Volkswagen&cmsid=SEO_Volkswagen',
    },
    {
        f: '/fr-be/voiture-marque/volvo/s60',
        t: '/fr-be/findcar?makesModels=Volvo%2CS60&cmsid=Volvo_S60_Text',
    },
    {
        f: '/fr-be/voiture-marque/volvo/s80',
        t: '/fr-be/findcar?makesModels=Volvo%2CS80&cmsid=Volvo_S80_Text',
    },
    {
        f: '/fr-be/voiture-marque/volvo/s90',
        t: '/fr-be/findcar?makesModels=Volvo%2CS90&cmsid=Volvo_S90_Text',
    },
    {
        f: '/fr-be/voiture-marque/volvo/v40',
        t: '/fr-be/findcar?makesModels=Volvo%2CV40&cmsid=Volvo_V40_Text',
    },
    {
        f: '/fr-be/voiture-marque/volvo/v60',
        t: '/fr-be/findcar?makesModels=Volvo%2CV60&cmsid=Volvo_V60_Text',
    },
    {
        f: '/fr-be/voiture-marque/volvo/v90',
        t: '/fr-be/findcar?makesModels=Volvo%2CV90&cmsid=Volvo_V90_Text',
    },
    {
        f: '/fr-be/voiture-marque/volvo/xc60',
        t: '/fr-be/findcar?makesModels=Volvo%2CXC60&cmsid=Volvo_XC60_Text',
    },
    {
        f: '/fr-be/voiture-marque/volvo/xc90',
        t: '/fr-be/findcar?makesModels=Volvo%2CXC90&cmsid=Volvo_XC90_Text',
    },
    {
        f: '/fr-be/voiture-marque/volvo',
        t: '/fr-be/findcar?makesModels=Volvo&cmsid=SEO_Volvo',
    },
    {
        f: '/fr-be/voitures-occasion-belgique',
        t: '/fr-be/findcar?originCountries=be&cmsid=used-cars-belgium',
    },
    {
        f: '/fr-be/voitures-occasion-allemagne',
        t: '/fr-be/findcar?originCountries=de&cmsid=used-cars-germany',
    },
    {
        f: '/fr-be/voitures-occasion-espagne',
        t: '/fr-be/findcar?originCountries=es&cmsid=used-cars-spain',
    },
    {
        f: '/fr-be/voitures-occasion-france',
        t: '/fr-be/findcar?originCountries=fr&cmsid=used-cars-france',
    },
    {
        f: '/fr-be/voitures-occasion-italie',
        t: '/fr-be/findcar?originCountries=it&cmsid=used-cars-italy',
    },
    {
        f: '/fr-be/voitures-occasion-luxembourg',
        t: '/fr-be/findcar?originCountries=lu&cmsid=used-cars-luxembourg',
    },
    {
        f: '/fr-be/voitures-occasion-pays-bas',
        t: '/fr-be/findcar?originCountries=nl&cmsid=used-cars-holland',
    },
    {
        f: '/fr-be/voitures-occasion-pologne',
        t: '/fr-be/findcar?originCountries=pl&cmsid=used-cars-poland',
    },
    {
        f: '/fr-be/voitures-occasion-suede',
        t: '/fr-be/findcar?originCountries=se&cmsid=used-cars-sweden',
    },
    {
        f: '/fr-be/prix-voitures-occasion/voitures-occasion-pas-cher',
        t: '/fr-be/findcar?prices=0%2C3000&cmsid=cheap_used_cars',
    },
    {
        f: '/fr-be/prix-voitures-occasion/vehicules-occasion-milieu-de-gamme',
        t: '/fr-be/findcar?prices=10000%2C20000&cmsid=Middle_class',
    },
    {
        f: '/fr-be/prix-voitures-occasion/voiture-de-luxe',
        t: '/fr-be/findcar?prices=20000%2C0&cmsid=luxury_cars',
    },
    {
        f: '/fr-be/prix-voitures-occasion/voitures-economiques',
        t: '/fr-be/findcar?prices=3000%2C6000&cmsid=economy_cars',
    },
    {
        f: '/fr-be/prix-voitures-occasion/voitures-occasion-bon-rapport-qualite-prix',
        t: '/fr-be/findcar?prices=6000%2C10000&cmsid=Value_for_money',
    },
    {
        f: '/fr-fr/type-de-carrosserie/camion',
        t: '/fr-fr/findcar?bodyTypes=Truck&cmsid=Truck',
    },
    {
        f: '/fr-fr/encheres-voitures/100-pourcent-d-attribution',
        t: '/fr-fr/findcar?assignment100pc=true&cmsid=100assignment',
    },
    {
        f: '/fr-fr/encheres-voitures/dynamique',
        t: '/fr-fr/findcar?auctionTypes=1&cmsid=dynamic',
    },
    {
        f: '/fr-fr/encheres-voitures/voitures-occasion-achat-immediat',
        t: '/fr-fr/findcar?auctionTypes=2&cmsid=buy-now',
    },
    {
        f: '/fr-fr/encheres-voitures/unique',
        t: '/fr-fr/findcar?auctionTypes=4&cmsid=blind',
    },
    {
        f: '/fr-fr/encheres-voitures/voitures-encheres-prix-de-reserve',
        t: '/fr-fr/findcar?auctionTypes=5&cmsid=target',
    },
    {
        f: '/fr-fr/type-de-carrosserie/berline',
        t: '/fr-fr/findcar?bodyTypes=Berline&cmsid=SEO_Berline',
    },
    {
        f: '/fr-fr/type-de-carrosserie/break',
        t: '/fr-fr/findcar?bodyTypes=Break&cmsid=SEO_Break',
    },
    {
        f: '/fr-fr/type-de-carrosserie/cabriolet',
        t: '/fr-fr/findcar?bodyTypes=Cabriolet&cmsid=SEO_Cabrio',
    },
    {
        f: '/fr-fr/type-de-carrosserie/compact',
        t: '/fr-fr/findcar?bodyTypes=Compact&cmsid=SEO_Compact',
    },
    {
        f: '/fr-fr/type-de-carrosserie/coupe',
        t: '/fr-fr/findcar?bodyTypes=Coup%C3%A9&cmsid=SEO_Coup%C3%A9',
    },
    {
        f: '/fr-fr/type-de-carrosserie/bicorps',
        t: '/fr-fr/findcar?bodyTypes=Hatchback&cmsid=SEO_Hatchback',
    },
    {
        f: '/fr-fr/type-de-carrosserie/camionnette',
        t: '/fr-fr/findcar?bodyTypes=Lighttruck&cmsid=SEO_Light-truck',
    },
    {
        f: '/fr-fr/type-de-carrosserie/minibus',
        t: '/fr-fr/findcar?bodyTypes=Minibus&cmsid=Minibus',
    },
    {
        f: '/fr-fr/type-de-carrosserie/moto',
        t: '/fr-fr/findcar?bodyTypes=Motorbike&cmsid=Motorbike',
    },
    {
        f: '/fr-fr/type-de-carrosserie/monospace',
        t: '/fr-fr/findcar?bodyTypes=MPV&cmsid=SEO_MPV',
    },
    {
        f: '/fr-fr/type-de-carrosserie/suv',
        t: '/fr-fr/findcar?bodyTypes=SUV&cmsid=SEO_SUV',
    },
    {
        f: '/fr-fr/encheres-voitures/voitures-occasion-bon-etat',
        t: '/fr-fr/findcar?damageTypes=1&cmsid=no_specific-Damages',
    },
    {
        f: '/fr-fr/encheres-voitures/vehicules-accidentes-occasion-a-vendre',
        t: '/fr-fr/findcar?damageTypes=2&cmsid=Body-damages',
    },
    {
        f: '/fr-fr/encheres-voitures/achat-voitures-dommages-techniques',
        t: '/fr-fr/findcar?damageTypes=3&cmsid=technical-damages',
    },
    {
        f: '/fr-fr/encheres-voitures/vehicules-avec-degats-techniques-et-de-carrosserie',
        t: '/fr-fr/findcar?damageTypes=4&cmsid=Technical-and-body-damages',
    },
    {
        f: '/fr-fr/voiture-marque/abarth',
        t: '/fr-fr/findcar?makesModels=Abarth&cmsid=SEO_Abarth',
    },
    {
        f: '/fr-fr/voiture-marque/alfa-romeo',
        t: '/fr-fr/findcar?makesModels=Alfa%20Romeo&cmsid=SEO_alfa-romeo',
    },
    {
        f: '/fr-fr/voiture-marque/audi/a1',
        t: '/fr-fr/findcar?makesModels=Audi%2CA1&cmsid=Audi_A1_Text',
    },
    {
        f: '/fr-fr/voiture-marque/audi/a2',
        t: '/fr-fr/findcar?makesModels=Audi%2CA2&cmsid=Audi_A2_Text',
    },
    {
        f: '/fr-fr/voiture-marque/audi/a3',
        t: '/fr-fr/findcar?makesModels=Audi%2CA3&cmsid=Audi_A3_Text',
    },
    {
        f: '/fr-fr/voiture-marque/audi/a4',
        t: '/fr-fr/findcar?makesModels=Audi%2CA4&cmsid=Audi_A4_Text',
    },
    {
        f: '/fr-fr/voiture-marque/audi/a5',
        t: '/fr-fr/findcar?makesModels=Audi%2CA5&cmsid=Audi_A5_Text',
    },
    {
        f: '/fr-fr/voiture-marque/audi/a6',
        t: '/fr-fr/findcar?makesModels=Audi%2CA6&cmsid=Audi_A6_Text',
    },
    {
        f: '/fr-fr/voiture-marque/audi/q3',
        t: '/fr-fr/findcar?makesModels=Audi%2CQ3&cmsid=Audi_Q3_Text',
    },
    {
        f: '/fr-fr/voiture-marque/audi/q5',
        t: '/fr-fr/findcar?makesModels=Audi%2CQ5&cmsid=Audi_Q5_Text',
    },
    {
        f: '/fr-fr/voiture-marque/audi/q7',
        t: '/fr-fr/findcar?makesModels=Audi%2CQ7&cmsid=Audi_Q7_Text',
    },
    {
        f: '/fr-fr/voiture-marque/audi',
        t: '/fr-fr/findcar?makesModels=Audi&cmsid=SEO_Audi',
    },
    {
        f: '/fr-fr/voiture-marque/bmw/serie-1',
        t: '/fr-fr/findcar?makesModels=BMW%2C1%20Series&cmsid=BMW_1series_Text',
    },
    {
        f: '/fr-fr/voiture-marque/bmw/serie-2',
        t: '/fr-fr/findcar?makesModels=BMW%2C2%20Series&cmsid=BMW_2series_Text',
    },
    {
        f: '/fr-fr/voiture-marque/bmw/serie-3',
        t: '/fr-fr/findcar?makesModels=BMW%2C3%20Series&cmsid=BMW_3series_Text',
    },
    {
        f: '/fr-fr/voiture-marque/bmw/serie-4',
        t: '/fr-fr/findcar?makesModels=BMW%2C4%20Series&cmsid=BMW_4series_Text',
    },
    {
        f: '/fr-fr/voiture-marque/bmw/serie-5',
        t: '/fr-fr/findcar?makesModels=BMW%2C5%20Series&cmsid=BMW_5series_Text',
    },
    {
        f: '/fr-fr/voiture-marque/bmw/x1',
        t: '/fr-fr/findcar?makesModels=BMW%2CX1&cmsid=BMW_X1_Text',
    },
    {
        f: '/fr-fr/voiture-marque/bmw/x3',
        t: '/fr-fr/findcar?makesModels=BMW%2CX3&cmsid=BMW_X3_Text',
    },
    {
        f: '/fr-fr/voiture-marque/bmw/x5',
        t: '/fr-fr/findcar?makesModels=BMW%2CX5&cmsid=BMW_X5_Text',
    },
    {
        f: '/fr-fr/voiture-marque/bmw/x6',
        t: '/fr-fr/findcar?makesModels=BMW%2CX6&cmsid=BMW_X6_Text',
    },
    {
        f: '/fr-fr/voiture-marque/bmw',
        t: '/fr-fr/findcar?makesModels=BMW&cmsid=SEO_BMW',
    },
    {
        f: '/fr-fr/voiture-marque/citroen/berlingo',
        t: '/fr-fr/findcar?makesModels=Citro%C3%ABn%2CBerlingo&cmsid=Citro%C3%ABn_Berlingo_text',
    },
    {
        f: '/fr-fr/voiture-marque/citroen/c3',
        t: '/fr-fr/findcar?makesModels=Citro%C3%ABn%2CC3&cmsid=Citro%C3%ABn_C3_text',
    },
    {
        f: '/fr-fr/voiture-marque/citroen/c4',
        t: '/fr-fr/findcar?makesModels=Citro%C3%ABn%2CC4&cmsid=Citro%C3%ABn_C4_text',
    },
    {
        f: '/fr-fr/voiture-marque/citroen/c5',
        t: '/fr-fr/findcar?makesModels=Citro%C3%ABn%2CC5&cmsid=Citro%C3%ABn_C5_text',
    },
    {
        f: '/fr-fr/voiture-marque/citroen/ds3',
        t: '/fr-fr/findcar?makesModels=Citro%C3%ABn%2CDS3&cmsid=Citro%C3%ABn_DS3_text',
    },
    {
        f: '/fr-fr/voiture-marque/citroen/ds4',
        t: '/fr-fr/findcar?makesModels=Citro%C3%ABn%2CDS4&cmsid=Citro%C3%ABn_DS4_text',
    },
    {
        f: '/fr-fr/voiture-marque/citroen/dispatch',
        t: '/fr-fr/findcar?makesModels=Citro%C3%ABn%2CJumpy&cmsid=Citro%C3%ABn_Dispatch_text',
    },
    {
        f: '/fr-fr/voiture-marque/citroen',
        t: '/fr-fr/findcar?makesModels=Citro%C3%ABn&cmsid=SEO_Citro%C3%ABn',
    },
    {
        f: '/fr-fr/voiture-marque/fiat/499',
        t: '/fr-fr/findcar?makesModels=Fiat%2C500&cmsid=Fiat_500_Text',
    },
    {
        f: '/fr-fr/voiture-marque/fiat/bravo',
        t: '/fr-fr/findcar?makesModels=Fiat%2CBravo&cmsid=Fiat_bravo_Text',
    },
    {
        f: '/fr-fr/voiture-marque/fiat/doblo',
        t: '/fr-fr/findcar?makesModels=Fiat%2CDoblo&cmsid=Fiat_doblo_Text',
    },
    {
        f: '/fr-fr/voiture-marque/fiat/ducato',
        t: '/fr-fr/findcar?makesModels=Fiat%2CDucato&cmsid=Fiat_ducato_Text',
    },
    {
        f: '/fr-fr/voiture-marque/fiat/freemont',
        t: '/fr-fr/findcar?makesModels=Fiat%2CFreemont&cmsid=Fiat_freemont_Text',
    },
    {
        f: '/fr-fr/voiture-marque/fiat/panda',
        t: '/fr-fr/findcar?makesModels=Fiat%2CPanda&cmsid=Fiat_panda_Text',
    },
    {
        f: '/fr-fr/voiture-marque/fiat/punto',
        t: '/fr-fr/findcar?makesModels=Fiat%2CPunto&cmsid=Fiat_punto_Text',
    },
    {
        f: '/fr-fr/voiture-marque/fiat',
        t: '/fr-fr/findcar?makesModels=Fiat&cmsid=SEO_Fiat',
    },
    {
        f: '/fr-fr/voiture-marque/ford/focus',
        t: '/fr-fr/findcar?makesModels=Ford%2CFiesta&cmsid=Ford_Fiesta_Text',
    },
    {
        f: '/fr-fr/voiture-marque/ford/fiesta',
        t: '/fr-fr/findcar?makesModels=Ford%2CFocus&cmsid=Ford_Focus_Text',
    },
    {
        f: '/fr-fr/voiture-marque/ford/mondeo',
        t: '/fr-fr/findcar?makesModels=Ford%2CMondeo&cmsid=Ford_Mondeo_Text',
    },
    {
        f: '/fr-fr/voiture-marque/ford/transit',
        t: '/fr-fr/findcar?makesModels=Ford%2CTransit&cmsid=Ford_Transit_Text',
    },
    {
        f: '/fr-fr/voiture-marque/ford',
        t: '/fr-fr/findcar?makesModels=Ford&cmsid=SEO_Ford',
    },
    {
        f: '/fr-fr/voiture-marque/lancia',
        t: '/fr-fr/findcar?makesModels=Lancia&cmsid=SEO_Lancia',
    },
    {
        f: '/fr-fr/voiture-marque/mercedes/classe-a',
        t: '/fr-fr/findcar?makesModels=Mercedes-Benz%2CA&cmsid=Mercedes_A-class_Text',
    },
    {
        f: '/fr-fr/voiture-marque/mercedes/classe-b',
        t: '/fr-fr/findcar?makesModels=Mercedes-Benz%2CB&cmsid=Mercedes_B-class_Text',
    },
    {
        f: '/fr-fr/voiture-marque/mercedes/classe-c',
        t: '/fr-fr/findcar?makesModels=Mercedes-Benz%2CC&cmsid=Mercedes_C-class_Text',
    },
    {
        f: '/fr-fr/voiture-marque/mercedes/classe-e',
        t: '/fr-fr/findcar?makesModels=Mercedes-Benz%2CE&cmsid=Mercedes_E-class_Text',
    },
    {
        f: '/fr-fr/voiture-marque/mercedes/sprinter',
        t: '/fr-fr/findcar?makesModels=Mercedes-Benz%2CSprinter&cmsid=Mercedes_Sprinter_Text',
    },
    {
        f: '/fr-fr/voiture-marque/mercedes/vito',
        t: '/fr-fr/findcar?makesModels=Mercedes-Benz%2CVito&cmsid=Mercedes_Vito_Text',
    },
    {
        f: '/fr-fr/voiture-marque/mercedes',
        t: '/fr-fr/findcar?makesModels=Mercedes-Benz&cmsid=SEO_Mercedes',
    },
    {
        f: '/fr-fr/voiture-marque/nissan/juke',
        t: '/fr-fr/findcar?makesModels=Nissan%2CJuke&cmsid=Nissan_Juke_Text',
    },
    {
        f: '/fr-fr/voiture-marque/nissan/micra',
        t: '/fr-fr/findcar?makesModels=Nissan%2CMicra&cmsid=Nissan_micra_Text',
    },
    {
        f: '/fr-fr/voiture-marque/nissan/pathfinder',
        t: '/fr-fr/findcar?makesModels=Nissan%2CPathfinder&cmsid=Nissan_Pathfinder_Text',
    },
    {
        f: '/fr-fr/voiture-marque/nissan/qashqai',
        t: '/fr-fr/findcar?makesModels=Nissan%2CQashqai&cmsid=Nissan_Qashqai_Text',
    },
    {
        f: '/fr-fr/voiture-marque/nissan/x-trail',
        t: '/fr-fr/findcar?makesModels=Nissan%2CX-Trail&cmsid=Nissan_x-trail_Text',
    },
    {
        f: '/fr-fr/voiture-marque/nissan',
        t: '/fr-fr/findcar?makesModels=Nissan&cmsid=SEO_Nissan',
    },
    {
        f: '/fr-fr/voiture-marque/opel/astra',
        t: '/fr-fr/findcar?makesModels=Opel%2CAstra&cmsid=Opel_Astra_Text',
    },
    {
        f: '/fr-fr/voiture-marque/opel/combo',
        t: '/fr-fr/findcar?makesModels=Opel%2CCombo&cmsid=Opel_Combo_Text',
    },
    {
        f: '/fr-fr/voiture-marque/opel/corsa',
        t: '/fr-fr/findcar?makesModels=Opel%2CCorsa&cmsid=Opel_Corsa_Text',
    },
    {
        f: '/fr-fr/voiture-marque/opel/insignia',
        t: '/fr-fr/findcar?makesModels=Opel%2CInsignia&cmsid=Opel_Insignia_Text',
    },
    {
        f: '/fr-fr/voiture-marque/opel/meriva',
        t: '/fr-fr/findcar?makesModels=Opel%2CMeriva&cmsid=Opel_Meriva_Text',
    },
    {
        f: '/fr-fr/voiture-marque/opel/movano',
        t: '/fr-fr/findcar?makesModels=Opel%2CMovano&cmsid=Opel_Movano_Text',
    },
    {
        f: '/fr-fr/voiture-marque/opel/vivaro',
        t: '/fr-fr/findcar?makesModels=Opel%2CVivaro&cmsid=Opel_Vivaro_Text',
    },
    {
        f: '/fr-fr/voiture-marque/opel/zafira',
        t: '/fr-fr/findcar?makesModels=Opel%2CZafira&cmsid=Opel_Zafira_Text',
    },
    {
        f: '/fr-fr/voiture-marque/opel',
        t: '/fr-fr/findcar?makesModels=Opel&cmsid=Seo_Opel',
    },
    {
        f: '/fr-fr/voiture-marque/peugeot/407sw',
        t: '/fr-fr/findcar?makesModels=Peugeot%2C40x&cmsid=Peugeot_407sw_Text',
    },
    {
        f: '/fr-fr/voiture-marque/peugeot/5008',
        t: '/fr-fr/findcar?makesModels=Peugeot%2C5008&cmsid=Peugeot_5008_Text',
    },
    {
        f: '/fr-fr/voiture-marque/peugeot/expert',
        t: '/fr-fr/findcar?makesModels=Peugeot%2CExpert&cmsid=Peugeot_Expert_Text',
    },
    {
        f: '/fr-fr/voiture-marque/peugeot/partner',
        t: '/fr-fr/findcar?makesModels=Peugeot%2CPartner&cmsid=Peugeot_Partner_Text',
    },
    {
        f: '/fr-fr/voiture-marque/peugeot',
        t: '/fr-fr/findcar?makesModels=Peugeot&cmsid=Seo_Peugeot',
    },
    {
        f: '/fr-fr/voiture-marque/piaggio',
        t: '/fr-fr/findcar?makesModels=Piaggio&cmsid=SEO_Piaggio',
    },
    {
        f: '/fr-fr/voiture-marque/renault/clio',
        t: '/fr-fr/findcar?makesModels=Renault%2CClio&cmsid=Renault_Clio_Text',
    },
    {
        f: '/fr-fr/voiture-marque/renault/espace',
        t: '/fr-fr/findcar?makesModels=Renault%2CEspace&cmsid=Renault_Espace_Text',
    },
    {
        f: '/fr-fr/voiture-marque/renault/kangoo',
        t: '/fr-fr/findcar?makesModels=Renault%2CKangoo&cmsid=Renault_Kangoo_Text',
    },
    {
        f: '/fr-fr/voiture-marque/renault/megane',
        t: '/fr-fr/findcar?makesModels=Renault%2CMegane&cmsid=Renault_Megane_Text',
    },
    {
        f: '/fr-fr/voiture-marque/renault/scenic',
        t: '/fr-fr/findcar?makesModels=Renault%2CScenic&cmsid=Renault_Scenic_Text',
    },
    {
        f: '/fr-fr/voiture-marque/renault',
        t: '/fr-fr/findcar?makesModels=Renault&cmsid=SEO_Renault',
    },
    {
        f: '/fr-fr/voiture-marque/skoda/fabia',
        t: '/fr-fr/findcar?makesModels=Skoda%2CFabia&cmsid=Skoda_Fabia_Text',
    },
    {
        f: '/fr-fr/voiture-marque/skoda/octavia',
        t: '/fr-fr/findcar?makesModels=Skoda%2COctavia&cmsid=Skoda_Octavia_Text',
    },
    {
        f: '/fr-fr/voiture-marque/skoda/rapid',
        t: '/fr-fr/findcar?makesModels=Skoda%2CRapid&cmsid=Skoda_Rapid_Text',
    },
    {
        f: '/fr-fr/voiture-marque/skoda/superb',
        t: '/fr-fr/findcar?makesModels=Skoda%2CSuperb&cmsid=Skoda_Superb_Text',
    },
    {
        f: '/fr-fr/voiture-marque/skoda/yeti',
        t: '/fr-fr/findcar?makesModels=Skoda%2CYeti&cmsid=Skoda_Yeti_Text',
    },
    {
        f: '/fr-fr/voiture-marque/skoda',
        t: '/fr-fr/findcar?makesModels=Skoda&cmsid=SEO_Skoda',
    },
    {
        f: '/fr-fr/voiture-marque/toyota/auris',
        t: '/fr-fr/findcar?makesModels=Toyota%2CAuris&cmsid=Toyota_Auris_Text',
    },
    {
        f: '/fr-fr/voiture-marque/toyota/avensis',
        t: '/fr-fr/findcar?makesModels=Toyota%2CAvensis&cmsid=Toyota_Avensis_Text',
    },
    {
        f: '/fr-fr/voiture-marque/toyota/aygo',
        t: '/fr-fr/findcar?makesModels=Toyota%2CAygo&cmsid=Toyota_Aygo_Text',
    },
    {
        f: '/fr-fr/voiture-marque/toyota/prius',
        t: '/fr-fr/findcar?makesModels=Toyota%2CPrius&cmsid=Toyota_Prius_Text',
    },
    {
        f: '/fr-fr/voiture-marque/toyota/rav3',
        t: '/fr-fr/findcar?makesModels=Toyota%2CRav4&cmsid=Toyota_Rav4_Text',
    },
    {
        f: '/fr-fr/voiture-marque/toyota/yaris',
        t: '/fr-fr/findcar?makesModels=Toyota%2CYaris&cmsid=Toyota_Yaris_Text',
    },
    {
        f: '/fr-fr/voiture-marque/toyota',
        t: '/fr-fr/findcar?makesModels=Toyota&cmsid=SEO_Toyota',
    },
    {
        f: '/fr-fr/voiture-marque/volkswagen/caddy',
        t: '/fr-fr/findcar?makesModels=Volkswagen%2CCaddy&cmsid=Volkswagen_Caddy_Text',
    },
    {
        f: '/fr-fr/voiture-marque/volkswagen/golf',
        t: '/fr-fr/findcar?makesModels=Volkswagen%2CGolf&cmsid=Volkswagen_Golf_Text',
    },
    {
        f: '/fr-fr/voiture-marque/volkswagen/passat',
        t: '/fr-fr/findcar?makesModels=Volkswagen%2CPassat&cmsid=Volkswagen_Passat_Text',
    },
    {
        f: '/fr-fr/voiture-marque/volkswagen/polo',
        t: '/fr-fr/findcar?makesModels=Volkswagen%2CPolo&cmsid=Volkswagen_Polo_Text',
    },
    {
        f: '/fr-fr/voiture-marque/volkswagen/tiguan',
        t: '/fr-fr/findcar?makesModels=Volkswagen%2CTiguan&cmsid=Volkswagen_Tiguan_Text',
    },
    {
        f: '/fr-fr/voiture-marque/volkswagen/touareg',
        t: '/fr-fr/findcar?makesModels=Volkswagen%2CTouareg&cmsid=Volkswagen_Touareg_Text',
    },
    {
        f: '/fr-fr/voiture-marque/volkswagen/touran',
        t: '/fr-fr/findcar?makesModels=Volkswagen%2CTouran&cmsid=Volkswagen_Touran_Text',
    },
    {
        f: '/fr-fr/voiture-marque/volkswagen/transporter',
        t: '/fr-fr/findcar?makesModels=Volkswagen%2CTransporter&cmsid=Volkswagen_Transporter_Text',
    },
    {
        f: '/fr-fr/voiture-marque/volkswagen',
        t: '/fr-fr/findcar?makesModels=Volkswagen&cmsid=SEO_Volkswagen',
    },
    {
        f: '/fr-fr/voiture-marque/volvo/s60',
        t: '/fr-fr/findcar?makesModels=Volvo%2CS60&cmsid=Volvo_S60_Text',
    },
    {
        f: '/fr-fr/voiture-marque/volvo/s80',
        t: '/fr-fr/findcar?makesModels=Volvo%2CS80&cmsid=Volvo_S80_Text',
    },
    {
        f: '/fr-fr/voiture-marque/volvo/s90',
        t: '/fr-fr/findcar?makesModels=Volvo%2CS90&cmsid=Volvo_S90_Text',
    },
    {
        f: '/fr-fr/voiture-marque/volvo/v40',
        t: '/fr-fr/findcar?makesModels=Volvo%2CV40&cmsid=Volvo_V40_Text',
    },
    {
        f: '/fr-fr/voiture-marque/volvo/v60',
        t: '/fr-fr/findcar?makesModels=Volvo%2CV60&cmsid=Volvo_V60_Text',
    },
    {
        f: '/fr-fr/voiture-marque/volvo/v90',
        t: '/fr-fr/findcar?makesModels=Volvo%2CV90&cmsid=Volvo_V90_Text',
    },
    {
        f: '/fr-fr/voiture-marque/volvo/xc60',
        t: '/fr-fr/findcar?makesModels=Volvo%2CXC60&cmsid=Volvo_XC60_Text',
    },
    {
        f: '/fr-fr/voiture-marque/volvo/xc90',
        t: '/fr-fr/findcar?makesModels=Volvo%2CXC90&cmsid=Volvo_XC90_Text',
    },
    {
        f: '/fr-fr/voiture-marque/volvo',
        t: '/fr-fr/findcar?makesModels=Volvo&cmsid=SEO_Volvo',
    },
    {
        f: '/fr-fr/voitures-occasion-belgique',
        t: '/fr-fr/findcar?originCountries=be&cmsid=used-cars-belgium',
    },
    {
        f: '/fr-fr/voitures-occasion-allemagne',
        t: '/fr-fr/findcar?originCountries=de&cmsid=used-cars-germany',
    },
    {
        f: '/fr-fr/voitures-occasion-espagne',
        t: '/fr-fr/findcar?originCountries=es&cmsid=used-cars-spain',
    },
    {
        f: '/fr-fr/voitures-occasion-france',
        t: '/fr-fr/findcar?originCountries=fr&cmsid=used-cars-france',
    },
    {
        f: '/fr-fr/voitures-occasion-italie',
        t: '/fr-fr/findcar?originCountries=it&cmsid=used-cars-italy',
    },
    {
        f: '/fr-fr/voitures-occasion-luxembourg',
        t: '/fr-fr/findcar?originCountries=lu&cmsid=used-cars-luxembourg',
    },
    {
        f: '/fr-fr/voitures-occasion-pays-bas',
        t: '/fr-fr/findcar?originCountries=nl&cmsid=used-cars-holland',
    },
    {
        f: '/fr-fr/voitures-occasion-pologne',
        t: '/fr-fr/findcar?originCountries=pl&cmsid=used-cars-poland',
    },
    {
        f: '/fr-fr/voitures-occasion-suede',
        t: '/fr-fr/findcar?originCountries=se&cmsid=used-cars-sweden',
    },
    {
        f: '/fr-fr/prix-voitures-occasion/voitures-occasion-pas-cher',
        t: '/fr-fr/findcar?prices=0%2C3000&cmsid=cheap_used_cars',
    },
    {
        f: '/fr-fr/prix-voitures-occasion/vehicules-occasion-milieu-de-gamme',
        t: '/fr-fr/findcar?prices=10000%2C20000&cmsid=Middle_class',
    },
    {
        f: '/fr-fr/prix-voitures-occasion/voiture-de-luxe',
        t: '/fr-fr/findcar?prices=20000%2C0&cmsid=luxury_cars',
    },
    {
        f: '/fr-fr/prix-voitures-occasion/voitures-economiques',
        t: '/fr-fr/findcar?prices=3000%2C6000&cmsid=economy_cars',
    },
    {
        f: '/fr-fr/prix-voitures-occasion/voitures-occasion-bon-rapport-qualite-prix',
        t: '/fr-fr/findcar?prices=6000%2C10000&cmsid=Value_for_money',
    },
    { f: '/hr/body-type/kamion', t: '/hr/findcar?bodyTypes=Truck&cmsid=Truck' },
    {
        f: '/hr/car-auction/100-sigurna-dodjela',
        t: '/hr/findcar?assignment100pc=true&cmsid=100assignment',
    },
    {
        f: '/hr/car-auction/dinamicna',
        t: '/hr/findcar?auctionTypes=1&cmsid=dynamic',
    },
    {
        f: '/hr/car-auction/kupite-sada',
        t: '/hr/findcar?auctionTypes=2&cmsid=buy-now',
    },
    { f: '/hr/car-auction/slijepa', t: '/hr/findcar?auctionTypes=4&cmsid=blind' },
    { f: '/hr/car-auction/ciljna', t: '/hr/findcar?auctionTypes=5&cmsid=target' },
    {
        f: '/hr/body-type/sedan',
        t: '/hr/findcar?bodyTypes=Berline&cmsid=SEO_Berline',
    },
    {
        f: '/hr/body-type/karavan',
        t: '/hr/findcar?bodyTypes=Break&cmsid=SEO_Break',
    },
    {
        f: '/hr/body-type/kabriolet',
        t: '/hr/findcar?bodyTypes=Cabriolet&cmsid=SEO_Cabrio',
    },
    {
        f: '/hr/body-type/kompakt',
        t: '/hr/findcar?bodyTypes=Compact&cmsid=SEO_Compact',
    },
    {
        f: '/hr/body-type/coupe',
        t: '/hr/findcar?bodyTypes=Coup%C3%A9&cmsid=SEO_Coup%C3%A9',
    },
    {
        f: '/hr/body-type/hatchback',
        t: '/hr/findcar?bodyTypes=Hatchback&cmsid=SEO_Hatchback',
    },
    {
        f: '/hr/body-type/mali-kamion',
        t: '/hr/findcar?bodyTypes=Lighttruck&cmsid=SEO_Light-truck',
    },
    {
        f: '/hr/body-type/minibus',
        t: '/hr/findcar?bodyTypes=Minibus&cmsid=Minibus',
    },
    {
        f: '/hr/body-type/motorcikl',
        t: '/hr/findcar?bodyTypes=Motorbike&cmsid=Motorbike',
    },
    { f: '/hr/body-type/mpv', t: '/hr/findcar?bodyTypes=MPV&cmsid=SEO_MPV' },
    { f: '/hr/body-type/suv', t: '/hr/findcar?bodyTypes=SUV&cmsid=SEO_SUV' },
    {
        f: '/hr/car-auction/bez-ostecenja',
        t: '/hr/findcar?damageTypes=1&cmsid=no_specific-Damages',
    },
    {
        f: '/hr/car-auction/automobili-osteceni-nesrecama',
        t: '/hr/findcar?damageTypes=2&cmsid=Body-damages',
    },
    {
        f: '/hr/car-auction/tehnicki-osteceni-automobili',
        t: '/hr/findcar?damageTypes=3&cmsid=technical-damages',
    },
    {
        f: '/hr/car-auction/tehnicki-nedostatci-i-ostecenja-karoserije',
        t: '/hr/findcar?damageTypes=4&cmsid=Technical-and-body-damages',
    },
    {
        f: '/hr/proizvodac-automobila/abarth',
        t: '/hr/findcar?makesModels=Abarth&cmsid=SEO_Abarth',
    },
    {
        f: '/hr/proizvodac-automobila/alfa-romeo',
        t: '/hr/findcar?makesModels=Alfa%20Romeo&cmsid=SEO_alfa-romeo',
    },
    {
        f: '/hr/proizvodac-automobila/audi/a1',
        t: '/hr/findcar?makesModels=Audi%2CA1&cmsid=Audi_A1_Text',
    },
    {
        f: '/hr/proizvodac-automobila/audi/a2',
        t: '/hr/findcar?makesModels=Audi%2CA2&cmsid=Audi_A2_Text',
    },
    {
        f: '/hr/proizvodac-automobila/audi/a3',
        t: '/hr/findcar?makesModels=Audi%2CA3&cmsid=Audi_A3_Text',
    },
    {
        f: '/hr/proizvodac-automobila/audi/a4',
        t: '/hr/findcar?makesModels=Audi%2CA4&cmsid=Audi_A4_Text',
    },
    {
        f: '/hr/proizvodac-automobila/audi/a5',
        t: '/hr/findcar?makesModels=Audi%2CA5&cmsid=Audi_A5_Text',
    },
    {
        f: '/hr/proizvodac-automobila/audi/a6',
        t: '/hr/findcar?makesModels=Audi%2CA6&cmsid=Audi_A6_Text',
    },
    {
        f: '/hr/proizvodac-automobila/audi/q3',
        t: '/hr/findcar?makesModels=Audi%2CQ3&cmsid=Audi_Q3_Text',
    },
    {
        f: '/hr/proizvodac-automobila/audi/q5',
        t: '/hr/findcar?makesModels=Audi%2CQ5&cmsid=Audi_Q5_Text',
    },
    {
        f: '/hr/proizvodac-automobila/audi/q7',
        t: '/hr/findcar?makesModels=Audi%2CQ7&cmsid=Audi_Q7_Text',
    },
    {
        f: '/hr/proizvodac-automobila/audi',
        t: '/hr/findcar?makesModels=Audi&cmsid=SEO_Audi',
    },
    {
        f: '/hr/proizvodac-automobila/bmw/serije-1',
        t: '/hr/findcar?makesModels=BMW%2C1%20Series&cmsid=BMW_1series_Text',
    },
    {
        f: '/hr/proizvodac-automobila/bmw/serije-2',
        t: '/hr/findcar?makesModels=BMW%2C2%20Series&cmsid=BMW_2series_Text',
    },
    {
        f: '/hr/proizvodac-automobila/bmw/serije-3',
        t: '/hr/findcar?makesModels=BMW%2C3%20Series&cmsid=BMW_3series_Text',
    },
    {
        f: '/hr/proizvodac-automobila/bmw/serije-4',
        t: '/hr/findcar?makesModels=BMW%2C4%20Series&cmsid=BMW_4series_Text',
    },
    {
        f: '/hr/proizvodac-automobila/bmw/serije-5',
        t: '/hr/findcar?makesModels=BMW%2C5%20Series&cmsid=BMW_5series_Text',
    },
    {
        f: '/hr/proizvodac-automobila/bmw/x1',
        t: '/hr/findcar?makesModels=BMW%2CX1&cmsid=BMW_X1_Text',
    },
    {
        f: '/hr/proizvodac-automobila/bmw/x3',
        t: '/hr/findcar?makesModels=BMW%2CX3&cmsid=BMW_X3_Text',
    },
    {
        f: '/hr/proizvodac-automobila/bmw/x5',
        t: '/hr/findcar?makesModels=BMW%2CX5&cmsid=BMW_X5_Text',
    },
    {
        f: '/hr/proizvodac-automobila/bmw/x6',
        t: '/hr/findcar?makesModels=BMW%2CX6&cmsid=BMW_X6_Text',
    },
    {
        f: '/hr/proizvodac-automobila/bmw',
        t: '/hr/findcar?makesModels=BMW&cmsid=SEO_BMW',
    },
    {
        f: '/hr/proizvodac-automobila/citroen',
        t: '/hr/findcar?makesModels=Citro%C3%ABn%2CBerlingo&cmsid=Citro%C3%ABn_Berlingo_text',
    },
    {
        f: '/hr/proizvodac-automobila/citroen/c3',
        t: '/hr/findcar?makesModels=Citro%C3%ABn%2CC3&cmsid=Citro%C3%ABn_C3_text',
    },
    {
        f: '/hr/proizvodac-automobila/citroen/c4',
        t: '/hr/findcar?makesModels=Citro%C3%ABn%2CC4&cmsid=Citro%C3%ABn_C4_text',
    },
    {
        f: '/hr/proizvodac-automobila/citroen/c5',
        t: '/hr/findcar?makesModels=Citro%C3%ABn%2CC5&cmsid=Citro%C3%ABn_C5_text',
    },
    {
        f: '/hr/proizvodac-automobila/citroen/ds3',
        t: '/hr/findcar?makesModels=Citro%C3%ABn%2CDS3&cmsid=Citro%C3%ABn_DS3_text',
    },
    {
        f: '/hr/proizvodac-automobila/citroen/ds4',
        t: '/hr/findcar?makesModels=Citro%C3%ABn%2CDS4&cmsid=Citro%C3%ABn_DS4_text',
    },
    {
        f: '/hr/proizvodac-automobila/citroen',
        t: '/hr/findcar?makesModels=Citro%C3%ABn%2CJumpy&cmsid=Citro%C3%ABn_Dispatch_text',
    },
    {
        f: '/hr/proizvodac-automobila/citroen',
        t: '/hr/findcar?makesModels=Citro%C3%ABn&cmsid=SEO_Citro%C3%ABn',
    },
    {
        f: '/hr/proizvodac-automobila/fiat/500',
        t: '/hr/findcar?makesModels=Fiat%2C500&cmsid=Fiat_500_Text',
    },
    {
        f: '/hr/proizvodac-automobila/fiat/bravo',
        t: '/hr/findcar?makesModels=Fiat%2CBravo&cmsid=Fiat_bravo_Text',
    },
    {
        f: '/hr/proizvodac-automobila/fiat/doblo',
        t: '/hr/findcar?makesModels=Fiat%2CDoblo&cmsid=Fiat_doblo_Text',
    },
    {
        f: '/hr/proizvodac-automobila/fiat/ducato',
        t: '/hr/findcar?makesModels=Fiat%2CDucato&cmsid=Fiat_ducato_Text',
    },
    {
        f: '/hr/proizvodac-automobila/fiat/freemont',
        t: '/hr/findcar?makesModels=Fiat%2CFreemont&cmsid=Fiat_freemont_Text',
    },
    {
        f: '/hr/proizvodac-automobila/fiat/panda',
        t: '/hr/findcar?makesModels=Fiat%2CPanda&cmsid=Fiat_panda_Text',
    },
    {
        f: '/hr/proizvodac-automobila/fiat/punto',
        t: '/hr/findcar?makesModels=Fiat%2CPunto&cmsid=Fiat_punto_Text',
    },
    {
        f: '/hr/proizvodac-automobila/fiat',
        t: '/hr/findcar?makesModels=Fiat&cmsid=SEO_Fiat',
    },
    {
        f: '/hr/proizvodac-automobila/ford/fiesta',
        t: '/hr/findcar?makesModels=Ford%2CFiesta&cmsid=Ford_Fiesta_Text',
    },
    {
        f: '/hr/proizvodac-automobila/ford/focus',
        t: '/hr/findcar?makesModels=Ford%2CFocus&cmsid=Ford_Focus_Text',
    },
    {
        f: '/hr/proizvodac-automobila/ford/mondeo',
        t: '/hr/findcar?makesModels=Ford%2CMondeo&cmsid=Ford_Mondeo_Text',
    },
    {
        f: '/hr/proizvodac-automobila/ford/transit',
        t: '/hr/findcar?makesModels=Ford%2CTransit&cmsid=Ford_Transit_Text',
    },
    {
        f: '/hr/proizvodac-automobila/ford',
        t: '/hr/findcar?makesModels=Ford&cmsid=SEO_Ford',
    },
    {
        f: '/hr/proizvodac-automobila/lancia',
        t: '/hr/findcar?makesModels=Lancia&cmsid=SEO_Lancia',
    },
    {
        f: '/hr/proizvodac-automobila/mercedes/a-klasa',
        t: '/hr/findcar?makesModels=Mercedes-Benz%2CA&cmsid=Mercedes_A-class_Text',
    },
    {
        f: '/hr/proizvodac-automobila/mercedes/b-klasa',
        t: '/hr/findcar?makesModels=Mercedes-Benz%2CB&cmsid=Mercedes_B-class_Text',
    },
    {
        f: '/hr/proizvodac-automobila/mercedes/c-klasa',
        t: '/hr/findcar?makesModels=Mercedes-Benz%2CC&cmsid=Mercedes_C-class_Text',
    },
    {
        f: '/hr/proizvodac-automobila/mercedes/e-klasa',
        t: '/hr/findcar?makesModels=Mercedes-Benz%2CE&cmsid=Mercedes_E-class_Text',
    },
    {
        f: '/hr/proizvodac-automobila/mercedes/sprinter',
        t: '/hr/findcar?makesModels=Mercedes-Benz%2CSprinter&cmsid=Mercedes_Sprinter_Text',
    },
    {
        f: '/hr/proizvodac-automobila/mercedes/vito',
        t: '/hr/findcar?makesModels=Mercedes-Benz%2CVito&cmsid=Mercedes_Vito_Text',
    },
    {
        f: '/hr/proizvodac-automobila/mercedes',
        t: '/hr/findcar?makesModels=Mercedes-Benz&cmsid=SEO_Mercedes',
    },
    {
        f: '/hr/proizvodac-automobila/nissan/juke',
        t: '/hr/findcar?makesModels=Nissan%2CJuke&cmsid=Nissan_Juke_Text',
    },
    {
        f: '/hr/proizvodac-automobila/nissan/micra',
        t: '/hr/findcar?makesModels=Nissan%2CMicra&cmsid=Nissan_micra_Text',
    },
    {
        f: '/hr/proizvodac-automobila/nissan/pathfinder',
        t: '/hr/findcar?makesModels=Nissan%2CPathfinder&cmsid=Nissan_Pathfinder_Text',
    },
    {
        f: '/hr/proizvodac-automobila/nissan/qashqai',
        t: '/hr/findcar?makesModels=Nissan%2CQashqai&cmsid=Nissan_Qashqai_Text',
    },
    {
        f: '/hr/proizvodac-automobila/nissan/x-trail',
        t: '/hr/findcar?makesModels=Nissan%2CX-Trail&cmsid=Nissan_x-trail_Text',
    },
    {
        f: '/hr/proizvodac-automobila/nissan',
        t: '/hr/findcar?makesModels=Nissan&cmsid=SEO_Nissan',
    },
    {
        f: '/hr/proizvodac-automobila/opel/astra',
        t: '/hr/findcar?makesModels=Opel%2CAstra&cmsid=Opel_Astra_Text',
    },
    {
        f: '/hr/proizvodac-automobila/opel/combo',
        t: '/hr/findcar?makesModels=Opel%2CCombo&cmsid=Opel_Combo_Text',
    },
    {
        f: '/hr/proizvodac-automobila/opel/corsa',
        t: '/hr/findcar?makesModels=Opel%2CCorsa&cmsid=Opel_Corsa_Text',
    },
    {
        f: '/hr/proizvodac-automobila/opel/insignia',
        t: '/hr/findcar?makesModels=Opel%2CInsignia&cmsid=Opel_Insignia_Text',
    },
    {
        f: '/hr/proizvodac-automobila/opel/meriva',
        t: '/hr/findcar?makesModels=Opel%2CMeriva&cmsid=Opel_Meriva_Text',
    },
    {
        f: '/hr/proizvodac-automobila/opel/movano',
        t: '/hr/findcar?makesModels=Opel%2CMovano&cmsid=Opel_Movano_Text',
    },
    {
        f: '/hr/proizvodac-automobila/opel/vivaro',
        t: '/hr/findcar?makesModels=Opel%2CVivaro&cmsid=Opel_Vivaro_Text',
    },
    {
        f: '/hr/proizvodac-automobila/opel/zafira',
        t: '/hr/findcar?makesModels=Opel%2CZafira&cmsid=Opel_Zafira_Text',
    },
    {
        f: '/hr/proizvodac-automobila/opel',
        t: '/hr/findcar?makesModels=Opel&cmsid=Seo_Opel',
    },
    {
        f: '/hr/proizvodac-automobila/peugeot/407sw',
        t: '/hr/findcar?makesModels=Peugeot%2C40x&cmsid=Peugeot_407sw_Text',
    },
    {
        f: '/hr/proizvodac-automobila/peugeot/5008',
        t: '/hr/findcar?makesModels=Peugeot%2C5008&cmsid=Peugeot_5008_Text',
    },
    {
        f: '/hr/proizvodac-automobila/peugeot/expert',
        t: '/hr/findcar?makesModels=Peugeot%2CExpert&cmsid=Peugeot_Expert_Text',
    },
    {
        f: '/hr/proizvodac-automobila/peugeot/partner',
        t: '/hr/findcar?makesModels=Peugeot%2CPartner&cmsid=Peugeot_Partner_Text',
    },
    {
        f: '/hr/proizvodac-automobila/peugeot',
        t: '/hr/findcar?makesModels=Peugeot&cmsid=Seo_Peugeot',
    },
    {
        f: '/hr/proizvodac-automobila/piaggio',
        t: '/hr/findcar?makesModels=Piaggio&cmsid=SEO_Piaggio',
    },
    {
        f: '/hr/proizvodac-automobila/renault/clio',
        t: '/hr/findcar?makesModels=Renault%2CClio&cmsid=Renault_Clio_Text',
    },
    {
        f: '/hr/proizvodac-automobila/renault/espace',
        t: '/hr/findcar?makesModels=Renault%2CEspace&cmsid=Renault_Espace_Text',
    },
    {
        f: '/hr/proizvodac-automobila/renault/kangoo',
        t: '/hr/findcar?makesModels=Renault%2CKangoo&cmsid=Renault_Kangoo_Text',
    },
    {
        f: '/hr/proizvodac-automobila/renault/megane',
        t: '/hr/findcar?makesModels=Renault%2CMegane&cmsid=Renault_Megane_Text',
    },
    {
        f: '/hr/proizvodac-automobila/renault/scenic',
        t: '/hr/findcar?makesModels=Renault%2CScenic&cmsid=Renault_Scenic_Text',
    },
    {
        f: '/hr/proizvodac-automobila/renault',
        t: '/hr/findcar?makesModels=Renault&cmsid=SEO_Renault',
    },
    {
        f: '/hr/proizvodac-automobila/skoda/fabia',
        t: '/hr/findcar?makesModels=Skoda%2CFabia&cmsid=Skoda_Fabia_Text',
    },
    {
        f: '/hr/proizvodac-automobila/skoda/octavia',
        t: '/hr/findcar?makesModels=Skoda%2COctavia&cmsid=Skoda_Octavia_Text',
    },
    {
        f: '/hr/proizvodac-automobila/skoda/rapid',
        t: '/hr/findcar?makesModels=Skoda%2CRapid&cmsid=Skoda_Rapid_Text',
    },
    {
        f: '/hr/proizvodac-automobila/skoda/superb',
        t: '/hr/findcar?makesModels=Skoda%2CSuperb&cmsid=Skoda_Superb_Text',
    },
    {
        f: '/hr/proizvodac-automobila/skoda/yeti',
        t: '/hr/findcar?makesModels=Skoda%2CYeti&cmsid=Skoda_Yeti_Text',
    },
    {
        f: '/hr/proizvodac-automobila/skoda',
        t: '/hr/findcar?makesModels=Skoda&cmsid=SEO_Skoda',
    },
    {
        f: '/hr/proizvodac-automobila/toyota/auris',
        t: '/hr/findcar?makesModels=Toyota%2CAuris&cmsid=Toyota_Auris_Text',
    },
    {
        f: '/hr/proizvodac-automobila/toyota/avensis',
        t: '/hr/findcar?makesModels=Toyota%2CAvensis&cmsid=Toyota_Avensis_Text',
    },
    {
        f: '/hr/proizvodac-automobila/toyota/aygo',
        t: '/hr/findcar?makesModels=Toyota%2CAygo&cmsid=Toyota_Aygo_Text',
    },
    {
        f: '/hr/proizvodac-automobila/toyota/prius',
        t: '/hr/findcar?makesModels=Toyota%2CPrius&cmsid=Toyota_Prius_Text',
    },
    {
        f: '/hr/proizvodac-automobila/toyota/rav4',
        t: '/hr/findcar?makesModels=Toyota%2CRav4&cmsid=Toyota_Rav4_Text',
    },
    {
        f: '/hr/proizvodac-automobila/toyota/yaris',
        t: '/hr/findcar?makesModels=Toyota%2CYaris&cmsid=Toyota_Yaris_Text',
    },
    {
        f: '/hr/proizvodac-automobila/toyota',
        t: '/hr/findcar?makesModels=Toyota&cmsid=SEO_Toyota',
    },
    {
        f: '/hr/proizvodac-automobila/volkswagen/caddy',
        t: '/hr/findcar?makesModels=Volkswagen%2CCaddy&cmsid=Volkswagen_Caddy_Text',
    },
    {
        f: '/hr/proizvodac-automobila/volkswagen/golf',
        t: '/hr/findcar?makesModels=Volkswagen%2CGolf&cmsid=Volkswagen_Golf_Text',
    },
    {
        f: '/hr/proizvodac-automobila/volkswagen/passat',
        t: '/hr/findcar?makesModels=Volkswagen%2CPassat&cmsid=Volkswagen_Passat_Text',
    },
    {
        f: '/hr/proizvodac-automobila/volkswagen/polo',
        t: '/hr/findcar?makesModels=Volkswagen%2CPolo&cmsid=Volkswagen_Polo_Text',
    },
    {
        f: '/hr/proizvodac-automobila/volkswagen/tiguan',
        t: '/hr/findcar?makesModels=Volkswagen%2CTiguan&cmsid=Volkswagen_Tiguan_Text',
    },
    {
        f: '/hr/proizvodac-automobila/volkswagen/touareg',
        t: '/hr/findcar?makesModels=Volkswagen%2CTouareg&cmsid=Volkswagen_Touareg_Text',
    },
    {
        f: '/hr/proizvodac-automobila/volkswagen/touran',
        t: '/hr/findcar?makesModels=Volkswagen%2CTouran&cmsid=Volkswagen_Touran_Text',
    },
    {
        f: '/hr/proizvodac-automobila/volkswagen/transporter',
        t: '/hr/findcar?makesModels=Volkswagen%2CTransporter&cmsid=Volkswagen_Transporter_Text',
    },
    {
        f: '/hr/proizvodac-automobila/volkswagen',
        t: '/hr/findcar?makesModels=Volkswagen&cmsid=SEO_Volkswagen',
    },
    {
        f: '/hr/proizvodac-automobila/volvo/s60',
        t: '/hr/findcar?makesModels=Volvo%2CS60&cmsid=Volvo_S60_Text',
    },
    {
        f: '/hr/proizvodac-automobila/volvo/s80',
        t: '/hr/findcar?makesModels=Volvo%2CS80&cmsid=Volvo_S80_Text',
    },
    {
        f: '/hr/proizvodac-automobila/volvo/s90',
        t: '/hr/findcar?makesModels=Volvo%2CS90&cmsid=Volvo_S90_Text',
    },
    {
        f: '/hr/proizvodac-automobila/volvo/v40',
        t: '/hr/findcar?makesModels=Volvo%2CV40&cmsid=Volvo_V40_Text',
    },
    {
        f: '/hr/proizvodac-automobila/volvo/v60',
        t: '/hr/findcar?makesModels=Volvo%2CV60&cmsid=Volvo_V60_Text',
    },
    {
        f: '/hr/proizvodac-automobila/volvo/v90',
        t: '/hr/findcar?makesModels=Volvo%2CV90&cmsid=Volvo_V90_Text',
    },
    {
        f: '/hr/proizvodac-automobila/volvo/xc60',
        t: '/hr/findcar?makesModels=Volvo%2CXC60&cmsid=Volvo_XC60_Text',
    },
    {
        f: '/hr/proizvodac-automobila/volvo/xc90',
        t: '/hr/findcar?makesModels=Volvo%2CXC90&cmsid=Volvo_XC90_Text',
    },
    {
        f: '/hr/proizvodac-automobila/volvo',
        t: '/hr/findcar?makesModels=Volvo&cmsid=SEO_Volvo',
    },
    {
        f: '/hr/rabljeni-automobili-belgije',
        t: '/hr/findcar?originCountries=be&cmsid=used-cars-belgium',
    },
    {
        f: '/hr/rabljeni-automobili-njemacke',
        t: '/hr/findcar?originCountries=de&cmsid=used-cars-germany',
    },
    {
        f: '/hr/rabljeni-automobili-spanjolske',
        t: '/hr/findcar?originCountries=es&cmsid=used-cars-spain',
    },
    {
        f: '/hr/rabljeni-automobili-francuske',
        t: '/hr/findcar?originCountries=fr&cmsid=used-cars-france',
    },
    {
        f: '/hr/rabljeni-automobili-italije',
        t: '/hr/findcar?originCountries=it&cmsid=used-cars-italy',
    },
    {
        f: '/hr/rabljeni-automobili-luksemburg',
        t: '/hr/findcar?originCountries=lu&cmsid=used-cars-luxembourg',
    },
    {
        f: '/hr/rabljeni-automobili-nizozemske',
        t: '/hr/findcar?originCountries=nl&cmsid=used-cars-holland',
    },
    {
        f: '/hr/rabljeni-automobili-poljska',
        t: '/hr/findcar?originCountries=pl&cmsid=used-cars-poland',
    },
    {
        f: '/hr/rabljeni-automobili-svedska',
        t: '/hr/findcar?originCountries=se&cmsid=used-cars-sweden',
    },
    {
        f: '/hr/cijene-rabljenih-automobila/povoljni-rabljeni-automobili',
        t: '/hr/findcar?prices=0%2C3000&cmsid=cheap_used_cars',
    },
    {
        f: '/hr/cijene-rabljenih-automobila/middle-class',
        t: '/hr/findcar?prices=10000%2C20000&cmsid=Middle_class',
    },
    {
        f: '/hr/cijene-rabljenih-automobila/luxury-cars',
        t: '/hr/findcar?prices=20000%2C0&cmsid=luxury_cars',
    },
    {
        f: '/hr/cijene-rabljenih-automobila/economy-cars',
        t: '/hr/findcar?prices=3000%2C6000&cmsid=economy_cars',
    },
    {
        f: '/hr/cijene-rabljenih-automobila/value-for-money',
        t: '/hr/findcar?prices=6000%2C10000&cmsid=Value_for_money',
    },
    {
        f: '/hu/body-type/teherauto',
        t: '/hu/findcar?bodyTypes=Truck&cmsid=Truck',
    },
    {
        f: '/hu/hasznaltauto-arveres/100-os-aukcio',
        t: '/hu/findcar?assignment100pc=true&cmsid=100assignment',
    },
    {
        f: '/hu/hasznaltauto-arveres/dinamikus',
        t: '/hu/findcar?auctionTypes=1&cmsid=dynamic',
    },
    {
        f: '/hu/hasznaltauto-arveres/megveszem-most',
        t: '/hu/findcar?auctionTypes=2&cmsid=buy-now',
    },
    {
        f: '/hu/hasznaltauto-arveres/vak',
        t: '/hu/findcar?auctionTypes=4&cmsid=blind',
    },
    {
        f: '/hu/hasznaltauto-arveres/cilova',
        t: '/hu/findcar?auctionTypes=5&cmsid=target',
    },
    {
        f: '/hu/body-type/berline',
        t: '/hu/findcar?bodyTypes=Berline&cmsid=SEO_Berline',
    },
    {
        f: '/hu/body-type/break',
        t: '/hu/findcar?bodyTypes=Break&cmsid=SEO_Break',
    },
    {
        f: '/hu/body-type/cabrio',
        t: '/hu/findcar?bodyTypes=Cabriolet&cmsid=SEO_Cabrio',
    },
    {
        f: '/hu/body-type/kismeretu',
        t: '/hu/findcar?bodyTypes=Compact&cmsid=SEO_Compact',
    },
    {
        f: '/hu/body-type/coupe',
        t: '/hu/findcar?bodyTypes=Coup%C3%A9&cmsid=SEO_Coup%C3%A9',
    },
    {
        f: '/hu/body-type/ferdehatu',
        t: '/hu/findcar?bodyTypes=Hatchback&cmsid=SEO_Hatchback',
    },
    {
        f: '/hu/body-type/kisteherauto',
        t: '/hu/findcar?bodyTypes=Lighttruck&cmsid=SEO_Light-truck',
    },
    {
        f: '/hu/body-type/minibus',
        t: '/hu/findcar?bodyTypes=Minibus&cmsid=Minibus',
    },
    {
        f: '/hu/body-type/motorkerekpar',
        t: '/hu/findcar?bodyTypes=Motorbike&cmsid=Motorbike',
    },
    { f: '/hu/body-type/mpv', t: '/hu/findcar?bodyTypes=MPV&cmsid=SEO_MPV' },
    { f: '/hu/body-type/suv', t: '/hu/findcar?bodyTypes=SUV&cmsid=SEO_SUV' },
    {
        f: '/hu/hasznaltauto-arveres/hibatlan-allapot',
        t: '/hu/findcar?damageTypes=1&cmsid=no_specific-Damages',
    },
    {
        f: '/hu/hasznaltauto-arveres/balesetes-autok',
        t: '/hu/findcar?damageTypes=2&cmsid=Body-damages',
    },
    {
        f: '/hu/hasznaltauto-arveres/muszaki-serult-autok',
        t: '/hu/findcar?damageTypes=3&cmsid=technical-damages',
    },
    {
        f: '/hu/hasznaltauto-arveres/muszaki-es-karosszeriaserulesek',
        t: '/hu/findcar?damageTypes=4&cmsid=Technical-and-body-damages',
    },
    {
        f: '/hu/auto-marka/abarth',
        t: '/hu/findcar?makesModels=Abarth&cmsid=SEO_Abarth',
    },
    {
        f: '/hu/auto-marka/alfa-romeo',
        t: '/hu/findcar?makesModels=Alfa%20Romeo&cmsid=SEO_alfa-romeo',
    },
    {
        f: '/hu/auto-marka/audi/a1',
        t: '/hu/findcar?makesModels=Audi%2CA1&cmsid=Audi_A1_Text',
    },
    {
        f: '/hu/auto-marka/audi/a2',
        t: '/hu/findcar?makesModels=Audi%2CA2&cmsid=Audi_A2_Text',
    },
    {
        f: '/hu/auto-marka/audi/a3',
        t: '/hu/findcar?makesModels=Audi%2CA3&cmsid=Audi_A3_Text',
    },
    {
        f: '/hu/auto-marka/audi/a4',
        t: '/hu/findcar?makesModels=Audi%2CA4&cmsid=Audi_A4_Text',
    },
    {
        f: '/hu/auto-marka/audi/a5',
        t: '/hu/findcar?makesModels=Audi%2CA5&cmsid=Audi_A5_Text',
    },
    {
        f: '/hu/auto-marka/audi/a6',
        t: '/hu/findcar?makesModels=Audi%2CA6&cmsid=Audi_A6_Text',
    },
    {
        f: '/hu/auto-marka/audi/q3',
        t: '/hu/findcar?makesModels=Audi%2CQ3&cmsid=Audi_Q3_Text',
    },
    {
        f: '/hu/auto-marka/audi/q5',
        t: '/hu/findcar?makesModels=Audi%2CQ5&cmsid=Audi_Q5_Text',
    },
    {
        f: '/hu/auto-marka/audi/q7',
        t: '/hu/findcar?makesModels=Audi%2CQ7&cmsid=Audi_Q7_Text',
    },
    {
        f: '/hu/auto-marka/audi',
        t: '/hu/findcar?makesModels=Audi&cmsid=SEO_Audi',
    },
    {
        f: '/hu/auto-marka/bmw/1',
        t: '/hu/findcar?makesModels=BMW%2C1%20Series&cmsid=BMW_1series_Text',
    },
    {
        f: '/hu/auto-marka/bmw/2',
        t: '/hu/findcar?makesModels=BMW%2C2%20Series&cmsid=BMW_2series_Text',
    },
    {
        f: '/hu/auto-marka/bmw/3',
        t: '/hu/findcar?makesModels=BMW%2C3%20Series&cmsid=BMW_3series_Text',
    },
    {
        f: '/hu/auto-marka/bmw/4',
        t: '/hu/findcar?makesModels=BMW%2C4%20Series&cmsid=BMW_4series_Text',
    },
    {
        f: '/hu/auto-marka/bmw/5',
        t: '/hu/findcar?makesModels=BMW%2C5%20Series&cmsid=BMW_5series_Text',
    },
    {
        f: '/hu/auto-marka/bmw/x1',
        t: '/hu/findcar?makesModels=BMW%2CX1&cmsid=BMW_X1_Text',
    },
    {
        f: '/hu/auto-marka/bmw/x3',
        t: '/hu/findcar?makesModels=BMW%2CX3&cmsid=BMW_X3_Text',
    },
    {
        f: '/hu/auto-marka/bmw/x5',
        t: '/hu/findcar?makesModels=BMW%2CX5&cmsid=BMW_X5_Text',
    },
    {
        f: '/hu/auto-marka/bmw/x6',
        t: '/hu/findcar?makesModels=BMW%2CX6&cmsid=BMW_X6_Text',
    },
    { f: '/hu/auto-marka/bmw', t: '/hu/findcar?makesModels=BMW&cmsid=SEO_BMW' },
    {
        f: '/hu/auto-marka/citroen/berlingo',
        t: '/hu/findcar?makesModels=Citro%C3%ABn%2CBerlingo&cmsid=Citro%C3%ABn_Berlingo_text',
    },
    {
        f: '/hu/auto-marka/citroen/c3',
        t: '/hu/findcar?makesModels=Citro%C3%ABn%2CC3&cmsid=Citro%C3%ABn_C3_text',
    },
    {
        f: '/hu/auto-marka/citroen/c4',
        t: '/hu/findcar?makesModels=Citro%C3%ABn%2CC4&cmsid=Citro%C3%ABn_C4_text',
    },
    {
        f: '/hu/auto-marka/citroen/c5',
        t: '/hu/findcar?makesModels=Citro%C3%ABn%2CC5&cmsid=Citro%C3%ABn_C5_text',
    },
    {
        f: '/hu/auto-marka/citroen/ds3',
        t: '/hu/findcar?makesModels=Citro%C3%ABn%2CDS3&cmsid=Citro%C3%ABn_DS3_text',
    },
    {
        f: '/hu/auto-marka/citroen/ds4',
        t: '/hu/findcar?makesModels=Citro%C3%ABn%2CDS4&cmsid=Citro%C3%ABn_DS4_text',
    },
    {
        f: '/hu/auto-marka/citroen/dispatch',
        t: '/hu/findcar?makesModels=Citro%C3%ABn%2CJumpy&cmsid=Citro%C3%ABn_Dispatch_text',
    },
    {
        f: '/hu/auto-marka/citroen',
        t: '/hu/findcar?makesModels=Citro%C3%ABn&cmsid=SEO_Citro%C3%ABn',
    },
    {
        f: '/hu/auto-marka/fiat/500',
        t: '/hu/findcar?makesModels=Fiat%2C500&cmsid=Fiat_500_Text',
    },
    {
        f: '/hu/auto-marka/fiat/bravo',
        t: '/hu/findcar?makesModels=Fiat%2CBravo&cmsid=Fiat_bravo_Text',
    },
    {
        f: '/hu/auto-marka/fiat/doblo',
        t: '/hu/findcar?makesModels=Fiat%2CDoblo&cmsid=Fiat_doblo_Text',
    },
    {
        f: '/hu/auto-marka/fiat/ducato',
        t: '/hu/findcar?makesModels=Fiat%2CDucato&cmsid=Fiat_ducato_Text',
    },
    {
        f: '/hu/auto-marka/fiat/freemont',
        t: '/hu/findcar?makesModels=Fiat%2CFreemont&cmsid=Fiat_freemont_Text',
    },
    {
        f: '/hu/auto-marka/fiat/panda',
        t: '/hu/findcar?makesModels=Fiat%2CPanda&cmsid=Fiat_panda_Text',
    },
    {
        f: '/hu/auto-marka/fiat/punto',
        t: '/hu/findcar?makesModels=Fiat%2CPunto&cmsid=Fiat_punto_Text',
    },
    {
        f: '/hu/auto-marka/fiat',
        t: '/hu/findcar?makesModels=Fiat&cmsid=SEO_Fiat',
    },
    {
        f: '/hu/auto-marka/ford/fiesta',
        t: '/hu/findcar?makesModels=Ford%2CFiesta&cmsid=Ford_Fiesta_Text',
    },
    {
        f: '/hu/auto-marka/ford/focus',
        t: '/hu/findcar?makesModels=Ford%2CFocus&cmsid=Ford_Focus_Text',
    },
    {
        f: '/hu/auto-marka/ford/mondeo',
        t: '/hu/findcar?makesModels=Ford%2CMondeo&cmsid=Ford_Mondeo_Text',
    },
    {
        f: '/hu/auto-marka/ford/transit',
        t: '/hu/findcar?makesModels=Ford%2CTransit&cmsid=Ford_Transit_Text',
    },
    {
        f: '/hu/auto-marka/ford',
        t: '/hu/findcar?makesModels=Ford&cmsid=SEO_Ford',
    },
    {
        f: '/hu/auto-marka/lancia',
        t: '/hu/findcar?makesModels=Lancia&cmsid=SEO_Lancia',
    },
    {
        f: '/hu/auto-marka/mercedes/klasa-a',
        t: '/hu/findcar?makesModels=Mercedes-Benz%2CA&cmsid=Mercedes_A-class_Text',
    },
    {
        f: '/hu/auto-marka/mercedes/klasa-b',
        t: '/hu/findcar?makesModels=Mercedes-Benz%2CB&cmsid=Mercedes_B-class_Text',
    },
    {
        f: '/hu/auto-marka/mercedes/klasa-c',
        t: '/hu/findcar?makesModels=Mercedes-Benz%2CC&cmsid=Mercedes_C-class_Text',
    },
    {
        f: '/hu/auto-marka/mercedes/klasa-e',
        t: '/hu/findcar?makesModels=Mercedes-Benz%2CE&cmsid=Mercedes_E-class_Text',
    },
    {
        f: '/hu/auto-marka/mercedes/sprinter',
        t: '/hu/findcar?makesModels=Mercedes-Benz%2CSprinter&cmsid=Mercedes_Sprinter_Text',
    },
    {
        f: '/hu/auto-marka/mercedes/vito',
        t: '/hu/findcar?makesModels=Mercedes-Benz%2CVito&cmsid=Mercedes_Vito_Text',
    },
    {
        f: '/hu/auto-marka/mercedes',
        t: '/hu/findcar?makesModels=Mercedes-Benz&cmsid=SEO_Mercedes',
    },
    {
        f: '/hu/auto-marka/nissan/juke',
        t: '/hu/findcar?makesModels=Nissan%2CJuke&cmsid=Nissan_Juke_Text',
    },
    {
        f: '/hu/auto-marka/nissan/micra',
        t: '/hu/findcar?makesModels=Nissan%2CMicra&cmsid=Nissan_micra_Text',
    },
    {
        f: '/hu/auto-marka/nissan/pathfinder',
        t: '/hu/findcar?makesModels=Nissan%2CPathfinder&cmsid=Nissan_Pathfinder_Text',
    },
    {
        f: '/hu/auto-marka/nissan/qashqai',
        t: '/hu/findcar?makesModels=Nissan%2CQashqai&cmsid=Nissan_Qashqai_Text',
    },
    {
        f: '/hu/auto-marka/nissan/x-trail',
        t: '/hu/findcar?makesModels=Nissan%2CX-Trail&cmsid=Nissan_x-trail_Text',
    },
    {
        f: '/hu/auto-marka/nissan',
        t: '/hu/findcar?makesModels=Nissan&cmsid=SEO_Nissan',
    },
    {
        f: '/hu/auto-marka/opel/astra',
        t: '/hu/findcar?makesModels=Opel%2CAstra&cmsid=Opel_Astra_Text',
    },
    {
        f: '/hu/auto-marka/opel/combo',
        t: '/hu/findcar?makesModels=Opel%2CCombo&cmsid=Opel_Combo_Text',
    },
    {
        f: '/hu/auto-marka/opel/corsa',
        t: '/hu/findcar?makesModels=Opel%2CCorsa&cmsid=Opel_Corsa_Text',
    },
    {
        f: '/hu/auto-marka/opel/insignia',
        t: '/hu/findcar?makesModels=Opel%2CInsignia&cmsid=Opel_Insignia_Text',
    },
    {
        f: '/hu/auto-marka/opel/meriva',
        t: '/hu/findcar?makesModels=Opel%2CMeriva&cmsid=Opel_Meriva_Text',
    },
    {
        f: '/hu/auto-marka/opel/movano',
        t: '/hu/findcar?makesModels=Opel%2CMovano&cmsid=Opel_Movano_Text',
    },
    {
        f: '/hu/auto-marka/opel/vivaro',
        t: '/hu/findcar?makesModels=Opel%2CVivaro&cmsid=Opel_Vivaro_Text',
    },
    {
        f: '/hu/auto-marka/opel/zafira',
        t: '/hu/findcar?makesModels=Opel%2CZafira&cmsid=Opel_Zafira_Text',
    },
    {
        f: '/hu/auto-marka/opel',
        t: '/hu/findcar?makesModels=Opel&cmsid=Seo_Opel',
    },
    {
        f: '/hu/auto-marka/peugeot/407sw',
        t: '/hu/findcar?makesModels=Peugeot%2C40x&cmsid=Peugeot_407sw_Text',
    },
    {
        f: '/hu/auto-marka/peugeot/5008',
        t: '/hu/findcar?makesModels=Peugeot%2C5008&cmsid=Peugeot_5008_Text',
    },
    {
        f: '/hu/auto-marka/peugeot/expert',
        t: '/hu/findcar?makesModels=Peugeot%2CExpert&cmsid=Peugeot_Expert_Text',
    },
    {
        f: '/hu/auto-marka/peugeot/partner',
        t: '/hu/findcar?makesModels=Peugeot%2CPartner&cmsid=Peugeot_Partner_Text',
    },
    {
        f: '/hu/auto-marka/peugeot',
        t: '/hu/findcar?makesModels=Peugeot&cmsid=Seo_Peugeot',
    },
    {
        f: '/hu/auto-marka/piaggio',
        t: '/hu/findcar?makesModels=Piaggio&cmsid=SEO_Piaggio',
    },
    {
        f: '/hu/auto-marka/renault/clio',
        t: '/hu/findcar?makesModels=Renault%2CClio&cmsid=Renault_Clio_Text',
    },
    {
        f: '/hu/auto-marka/renault/espace',
        t: '/hu/findcar?makesModels=Renault%2CEspace&cmsid=Renault_Espace_Text',
    },
    {
        f: '/hu/auto-marka/renault/kangoo',
        t: '/hu/findcar?makesModels=Renault%2CKangoo&cmsid=Renault_Kangoo_Text',
    },
    {
        f: '/hu/auto-marka/renault/megane',
        t: '/hu/findcar?makesModels=Renault%2CMegane&cmsid=Renault_Megane_Text',
    },
    {
        f: '/hu/auto-marka/renault/scenic',
        t: '/hu/findcar?makesModels=Renault%2CScenic&cmsid=Renault_Scenic_Text',
    },
    {
        f: '/hu/auto-marka/renault',
        t: '/hu/findcar?makesModels=Renault&cmsid=SEO_Renault',
    },
    {
        f: '/hu/auto-marka/skoda/fabia',
        t: '/hu/findcar?makesModels=Skoda%2CFabia&cmsid=Skoda_Fabia_Text',
    },
    {
        f: '/hu/auto-marka/skoda/octavia',
        t: '/hu/findcar?makesModels=Skoda%2COctavia&cmsid=Skoda_Octavia_Text',
    },
    {
        f: '/hu/auto-marka/skoda/rapid',
        t: '/hu/findcar?makesModels=Skoda%2CRapid&cmsid=Skoda_Rapid_Text',
    },
    {
        f: '/hu/auto-marka/skoda/superb',
        t: '/hu/findcar?makesModels=Skoda%2CSuperb&cmsid=Skoda_Superb_Text',
    },
    {
        f: '/hu/auto-marka/skoda/yeti',
        t: '/hu/findcar?makesModels=Skoda%2CYeti&cmsid=Skoda_Yeti_Text',
    },
    {
        f: '/hu/auto-marka/skoda',
        t: '/hu/findcar?makesModels=Skoda&cmsid=SEO_Skoda',
    },
    {
        f: '/hu/auto-marka/toyota/auris',
        t: '/hu/findcar?makesModels=Toyota%2CAuris&cmsid=Toyota_Auris_Text',
    },
    {
        f: '/hu/auto-marka/toyota/avensis',
        t: '/hu/findcar?makesModels=Toyota%2CAvensis&cmsid=Toyota_Avensis_Text',
    },
    {
        f: '/hu/auto-marka/toyota/aygo',
        t: '/hu/findcar?makesModels=Toyota%2CAygo&cmsid=Toyota_Aygo_Text',
    },
    {
        f: '/hu/auto-marka/toyota/prius',
        t: '/hu/findcar?makesModels=Toyota%2CPrius&cmsid=Toyota_Prius_Text',
    },
    {
        f: '/hu/auto-marka/toyota/rav4',
        t: '/hu/findcar?makesModels=Toyota%2CRav4&cmsid=Toyota_Rav4_Text',
    },
    {
        f: '/hu/auto-marka/toyota/yaris',
        t: '/hu/findcar?makesModels=Toyota%2CYaris&cmsid=Toyota_Yaris_Text',
    },
    {
        f: '/hu/auto-marka/toyota',
        t: '/hu/findcar?makesModels=Toyota&cmsid=SEO_Toyota',
    },
    {
        f: '/hu/auto-marka/volkswagen/caddy',
        t: '/hu/findcar?makesModels=Volkswagen%2CCaddy&cmsid=Volkswagen_Caddy_Text',
    },
    {
        f: '/hu/auto-marka/volkswagen/golf',
        t: '/hu/findcar?makesModels=Volkswagen%2CGolf&cmsid=Volkswagen_Golf_Text',
    },
    {
        f: '/hu/auto-marka/volkswagen/passat',
        t: '/hu/findcar?makesModels=Volkswagen%2CPassat&cmsid=Volkswagen_Passat_Text',
    },
    {
        f: '/hu/auto-marka/volkswagen/polo',
        t: '/hu/findcar?makesModels=Volkswagen%2CPolo&cmsid=Volkswagen_Polo_Text',
    },
    {
        f: '/hu/auto-marka/volkswagen/tiguan',
        t: '/hu/findcar?makesModels=Volkswagen%2CTiguan&cmsid=Volkswagen_Tiguan_Text',
    },
    {
        f: '/hu/auto-marka/volkswagen/touareg',
        t: '/hu/findcar?makesModels=Volkswagen%2CTouareg&cmsid=Volkswagen_Touareg_Text',
    },
    {
        f: '/hu/auto-marka/volkswagen/touran',
        t: '/hu/findcar?makesModels=Volkswagen%2CTouran&cmsid=Volkswagen_Touran_Text',
    },
    {
        f: '/hu/auto-marka/volkswagen/transporter',
        t: '/hu/findcar?makesModels=Volkswagen%2CTransporter&cmsid=Volkswagen_Transporter_Text',
    },
    {
        f: '/hu/auto-marka/volkswagen',
        t: '/hu/findcar?makesModels=Volkswagen&cmsid=SEO_Volkswagen',
    },
    {
        f: '/hu/auto-marka/volvo/s60',
        t: '/hu/findcar?makesModels=Volvo%2CS60&cmsid=Volvo_S60_Text',
    },
    {
        f: '/hu/auto-marka/volvo/s80',
        t: '/hu/findcar?makesModels=Volvo%2CS80&cmsid=Volvo_S80_Text',
    },
    {
        f: '/hu/auto-marka/volvo/s90',
        t: '/hu/findcar?makesModels=Volvo%2CS90&cmsid=Volvo_S90_Text',
    },
    {
        f: '/hu/auto-marka/volvo/v40',
        t: '/hu/findcar?makesModels=Volvo%2CV40&cmsid=Volvo_V40_Text',
    },
    {
        f: '/hu/auto-marka/volvo/v60',
        t: '/hu/findcar?makesModels=Volvo%2CV60&cmsid=Volvo_V60_Text',
    },
    {
        f: '/hu/auto-marka/volvo/v90',
        t: '/hu/findcar?makesModels=Volvo%2CV90&cmsid=Volvo_V90_Text',
    },
    {
        f: '/hu/auto-marka/volvo/xc60',
        t: '/hu/findcar?makesModels=Volvo%2CXC60&cmsid=Volvo_XC60_Text',
    },
    {
        f: '/hu/auto-marka/volvo/xc90',
        t: '/hu/findcar?makesModels=Volvo%2CXC90&cmsid=Volvo_XC90_Text',
    },
    {
        f: '/hu/auto-marka/volvo',
        t: '/hu/findcar?makesModels=Volvo&cmsid=SEO_Volvo',
    },
    {
        f: '/hu/hasznalt-autok-belgium',
        t: '/hu/findcar?originCountries=be&cmsid=used-cars-belgium',
    },
    {
        f: '/hu/hasznalt-autok-nemetorszag',
        t: '/hu/findcar?originCountries=de&cmsid=used-cars-germany',
    },
    {
        f: '/hu/hasznalt-autok-spanyolorszag',
        t: '/hu/findcar?originCountries=es&cmsid=used-cars-spain',
    },
    {
        f: '/hu/hasznalt-autok-franciaorszag',
        t: '/hu/findcar?originCountries=fr&cmsid=used-cars-france',
    },
    {
        f: '/hu/hasznalt-autok-olaszorszag',
        t: '/hu/findcar?originCountries=it&cmsid=used-cars-italy',
    },
    {
        f: '/hu/hasznalt-autok-luxemburg',
        t: '/hu/findcar?originCountries=lu&cmsid=used-cars-luxembourg',
    },
    {
        f: '/hu/hasznalt-autok-hollandia',
        t: '/hu/findcar?originCountries=nl&cmsid=used-cars-holland',
    },
    {
        f: '/hu/hasznalt-autok-lengyelorszag',
        t: '/hu/findcar?originCountries=pl&cmsid=used-cars-poland',
    },
    {
        f: '/hu/hasznalt-autok-svedorszag',
        t: '/hu/findcar?originCountries=se&cmsid=used-cars-sweden',
    },
    {
        f: '/hu/hasznalt-auto-arak/olcso-hasznalt-autok',
        t: '/hu/findcar?prices=0%2C3000&cmsid=cheap_used_cars',
    },
    {
        f: '/hu/hasznalt-auto-arak/middle-class',
        t: '/hu/findcar?prices=10000%2C20000&cmsid=Middle_class',
    },
    {
        f: '/hu/hasznalt-auto-arak/luxury-cars',
        t: '/hu/findcar?prices=20000%2C0&cmsid=luxury_cars',
    },
    {
        f: '/hu/hasznalt-auto-arak/gazdasagos-autok',
        t: '/hu/findcar?prices=3000%2C6000&cmsid=economy_cars',
    },
    {
        f: '/hu/hasznalt-auto-arak/value-for-money',
        t: '/hu/findcar?prices=6000%2C10000&cmsid=Value_for_money',
    },
    { f: '/it/body-type/camion', t: '/it/findcar?bodyTypes=Truck&cmsid=Truck' },
    {
        f: '/it/asta-auto-usate/100-delle-assegnazioni',
        t: '/it/findcar?assignment100pc=true&cmsid=100assignment',
    },
    {
        f: '/it/asta-auto-usate/dinamica',
        t: '/it/findcar?auctionTypes=1&cmsid=dynamic',
    },
    {
        f: '/it/asta-auto-usate/compralo-subito',
        t: '/it/findcar?auctionTypes=2&cmsid=buy-now',
    },
    {
        f: '/it/asta-auto-usate/al-buio',
        t: '/it/findcar?auctionTypes=4&cmsid=blind',
    },
    {
        f: '/it/asta-auto-usate/target',
        t: '/it/findcar?auctionTypes=5&cmsid=target',
    },
    {
        f: '/it/body-type/berlina',
        t: '/it/findcar?bodyTypes=Berline&cmsid=SEO_Berline',
    },
    {
        f: '/it/body-type/station-wagen',
        t: '/it/findcar?bodyTypes=Break&cmsid=SEO_Break',
    },
    {
        f: '/it/body-type/cabriolet',
        t: '/it/findcar?bodyTypes=Cabriolet&cmsid=SEO_Cabrio',
    },
    {
        f: '/it/body-type/compatta',
        t: '/it/findcar?bodyTypes=Compact&cmsid=SEO_Compact',
    },
    {
        f: '/it/body-type/coupe',
        t: '/it/findcar?bodyTypes=Coup%C3%A9&cmsid=SEO_Coup%C3%A9',
    },
    {
        f: '/it/body-type/hatchback',
        t: '/it/findcar?bodyTypes=Hatchback&cmsid=SEO_Hatchback',
    },
    {
        f: '/it/body-type/furgoncino',
        t: '/it/findcar?bodyTypes=Lighttruck&cmsid=SEO_Light-truck',
    },
    {
        f: '/it/body-type/minibus',
        t: '/it/findcar?bodyTypes=Minibus&cmsid=Minibus',
    },
    {
        f: '/it/body-type/motocicletta',
        t: '/it/findcar?bodyTypes=Motorbike&cmsid=Motorbike',
    },
    { f: '/it/body-type/mpv', t: '/it/findcar?bodyTypes=MPV&cmsid=SEO_MPV' },
    { f: '/it/body-type/suv', t: '/it/findcar?bodyTypes=SUV&cmsid=SEO_SUV' },
    {
        f: '/it/asta-auto-usate/senza-danni',
        t: '/it/findcar?damageTypes=1&cmsid=no_specific-Damages',
    },
    {
        f: '/it/asta-auto-usate/auto-incidentate',
        t: '/it/findcar?damageTypes=2&cmsid=Body-damages',
    },
    {
        f: '/it/asta-auto-usate/auto-con-danni-strutturali',
        t: '/it/findcar?damageTypes=3&cmsid=technical-damages',
    },
    {
        f: '/it/asta-auto-usate/danni-strutturali-e-alla-carrozzeria',
        t: '/it/findcar?damageTypes=4&cmsid=Technical-and-body-damages',
    },
    {
        f: '/it/marche-auto/abarth',
        t: '/it/findcar?makesModels=Abarth&cmsid=SEO_Abarth',
    },
    {
        f: '/it/marche-auto/alfa-romeo',
        t: '/it/findcar?makesModels=Alfa%20Romeo&cmsid=SEO_alfa-romeo',
    },
    {
        f: '/it/marche-auto/audi/a1',
        t: '/it/findcar?makesModels=Audi%2CA1&cmsid=Audi_A1_Text',
    },
    {
        f: '/it/marche-auto/audi/a2',
        t: '/it/findcar?makesModels=Audi%2CA2&cmsid=Audi_A2_Text',
    },
    {
        f: '/it/marche-auto/audi/a3',
        t: '/it/findcar?makesModels=Audi%2CA3&cmsid=Audi_A3_Text',
    },
    {
        f: '/it/marche-auto/audi/a4',
        t: '/it/findcar?makesModels=Audi%2CA4&cmsid=Audi_A4_Text',
    },
    {
        f: '/it/marche-auto/audi/a5',
        t: '/it/findcar?makesModels=Audi%2CA5&cmsid=Audi_A5_Text',
    },
    {
        f: '/it/marche-auto/audi/a6',
        t: '/it/findcar?makesModels=Audi%2CA6&cmsid=Audi_A6_Text',
    },
    {
        f: '/it/marche-auto/audi/q3',
        t: '/it/findcar?makesModels=Audi%2CQ3&cmsid=Audi_Q3_Text',
    },
    {
        f: '/it/marche-auto/audi/q5',
        t: '/it/findcar?makesModels=Audi%2CQ5&cmsid=Audi_Q5_Text',
    },
    {
        f: '/it/marche-auto/audi/q7',
        t: '/it/findcar?makesModels=Audi%2CQ7&cmsid=Audi_Q7_Text',
    },
    {
        f: '/it/marche-auto/audi',
        t: '/it/findcar?makesModels=Audi&cmsid=SEO_Audi',
    },
    {
        f: '/it/marche-auto/bmw/serie-1',
        t: '/it/findcar?makesModels=BMW%2C1%20Series&cmsid=BMW_1series_Text',
    },
    {
        f: '/it/marche-auto/bmw/serie-2',
        t: '/it/findcar?makesModels=BMW%2C2%20Series&cmsid=BMW_2series_Text',
    },
    {
        f: '/it/marche-auto/bmw/serie-3',
        t: '/it/findcar?makesModels=BMW%2C3%20Series&cmsid=BMW_3series_Text',
    },
    {
        f: '/it/marche-auto/bmw/serie-4',
        t: '/it/findcar?makesModels=BMW%2C4%20Series&cmsid=BMW_4series_Text',
    },
    {
        f: '/it/marche-auto/bmw/serie-5',
        t: '/it/findcar?makesModels=BMW%2C5%20Series&cmsid=BMW_5series_Text',
    },
    {
        f: '/it/marche-auto/bmw/x1',
        t: '/it/findcar?makesModels=BMW%2CX1&cmsid=BMW_X1_Text',
    },
    {
        f: '/it/marche-auto/bmw/x3',
        t: '/it/findcar?makesModels=BMW%2CX3&cmsid=BMW_X3_Text',
    },
    {
        f: '/it/marche-auto/bmw/x5',
        t: '/it/findcar?makesModels=BMW%2CX5&cmsid=BMW_X5_Text',
    },
    {
        f: '/it/marche-auto/bmw/x6',
        t: '/it/findcar?makesModels=BMW%2CX6&cmsid=BMW_X6_Text',
    },
    { f: '/it/marche-auto/bmw', t: '/it/findcar?makesModels=BMW&cmsid=SEO_BMW' },
    {
        f: '/it/marche-auto/citroen/berlingo',
        t: '/it/findcar?makesModels=Citro%C3%ABn%2CBerlingo&cmsid=Citro%C3%ABn_Berlingo_text',
    },
    {
        f: '/it/marche-auto/citroen/c3',
        t: '/it/findcar?makesModels=Citro%C3%ABn%2CC3&cmsid=Citro%C3%ABn_C3_text',
    },
    {
        f: '/it/marche-auto/citroen/c4',
        t: '/it/findcar?makesModels=Citro%C3%ABn%2CC4&cmsid=Citro%C3%ABn_C4_text',
    },
    {
        f: '/it/marche-auto/citroen/c5',
        t: '/it/findcar?makesModels=Citro%C3%ABn%2CC5&cmsid=Citro%C3%ABn_C5_text',
    },
    {
        f: '/it/marche-auto/citroen/ds3',
        t: '/it/findcar?makesModels=Citro%C3%ABn%2CDS3&cmsid=Citro%C3%ABn_DS3_text',
    },
    {
        f: '/it/marche-auto/citroen/ds4',
        t: '/it/findcar?makesModels=Citro%C3%ABn%2CDS4&cmsid=Citro%C3%ABn_DS4_text',
    },
    {
        f: '/it/marche-auto/citroen/dispatch',
        t: '/it/findcar?makesModels=Citro%C3%ABn%2CJumpy&cmsid=Citro%C3%ABn_Dispatch_text',
    },
    {
        f: '/it/marche-auto/citroen',
        t: '/it/findcar?makesModels=Citro%C3%ABn&cmsid=SEO_Citro%C3%ABn',
    },
    {
        f: '/it/marche-auto/fiat/500',
        t: '/it/findcar?makesModels=Fiat%2C500&cmsid=Fiat_500_Text',
    },
    {
        f: '/it/marche-auto/fiat/bravo',
        t: '/it/findcar?makesModels=Fiat%2CBravo&cmsid=Fiat_bravo_Text',
    },
    {
        f: '/it/marche-auto/fiat/doblo',
        t: '/it/findcar?makesModels=Fiat%2CDoblo&cmsid=Fiat_doblo_Text',
    },
    {
        f: '/it/marche-auto/fiat/ducato',
        t: '/it/findcar?makesModels=Fiat%2CDucato&cmsid=Fiat_ducato_Text',
    },
    {
        f: '/it/marche-auto/fiat/freemont',
        t: '/it/findcar?makesModels=Fiat%2CFreemont&cmsid=Fiat_freemont_Text',
    },
    {
        f: '/it/marche-auto/fiat/panda',
        t: '/it/findcar?makesModels=Fiat%2CPanda&cmsid=Fiat_panda_Text',
    },
    {
        f: '/it/marche-auto/fiat/punto',
        t: '/it/findcar?makesModels=Fiat%2CPunto&cmsid=Fiat_punto_Text',
    },
    {
        f: '/it/marche-auto/fiat',
        t: '/it/findcar?makesModels=Fiat&cmsid=SEO_Fiat',
    },
    {
        f: '/it/marche-auto/ford/fiesta',
        t: '/it/findcar?makesModels=Ford%2CFiesta&cmsid=Ford_Fiesta_Text',
    },
    {
        f: '/it/marche-auto/ford/focus',
        t: '/it/findcar?makesModels=Ford%2CFocus&cmsid=Ford_Focus_Text',
    },
    {
        f: '/it/marche-auto/ford/mondeo',
        t: '/it/findcar?makesModels=Ford%2CMondeo&cmsid=Ford_Mondeo_Text',
    },
    {
        f: '/it/marche-auto/ford/transit',
        t: '/it/findcar?makesModels=Ford%2CTransit&cmsid=Ford_Transit_Text',
    },
    {
        f: '/it/marche-auto/ford',
        t: '/it/findcar?makesModels=Ford&cmsid=SEO_Ford',
    },
    {
        f: '/it/marche-auto/lancia',
        t: '/it/findcar?makesModels=Lancia&cmsid=SEO_Lancia',
    },
    {
        f: '/it/marche-auto/mercedes/classe-a',
        t: '/it/findcar?makesModels=Mercedes-Benz%2CA&cmsid=Mercedes_A-class_Text',
    },
    {
        f: '/it/marche-auto/mercedes/classe-b',
        t: '/it/findcar?makesModels=Mercedes-Benz%2CB&cmsid=Mercedes_B-class_Text',
    },
    {
        f: '/it/marche-auto/mercedes/classe-c',
        t: '/it/findcar?makesModels=Mercedes-Benz%2CC&cmsid=Mercedes_C-class_Text',
    },
    {
        f: '/it/marche-auto/mercedes/classe-e',
        t: '/it/findcar?makesModels=Mercedes-Benz%2CE&cmsid=Mercedes_E-class_Text',
    },
    {
        f: '/it/marche-auto/mercedes/sprinter',
        t: '/it/findcar?makesModels=Mercedes-Benz%2CSprinter&cmsid=Mercedes_Sprinter_Text',
    },
    {
        f: '/it/marche-auto/mercedes/vito',
        t: '/it/findcar?makesModels=Mercedes-Benz%2CVito&cmsid=Mercedes_Vito_Text',
    },
    {
        f: '/it/marche-auto/mercedes',
        t: '/it/findcar?makesModels=Mercedes-Benz&cmsid=SEO_Mercedes',
    },
    {
        f: '/it/marche-auto/nissan/juke',
        t: '/it/findcar?makesModels=Nissan%2CJuke&cmsid=Nissan_Juke_Text',
    },
    {
        f: '/it/marche-auto/nissan/micra',
        t: '/it/findcar?makesModels=Nissan%2CMicra&cmsid=Nissan_micra_Text',
    },
    {
        f: '/it/marche-auto/nissan/pathfinder',
        t: '/it/findcar?makesModels=Nissan%2CPathfinder&cmsid=Nissan_Pathfinder_Text',
    },
    {
        f: '/it/marche-auto/nissan/qashqai',
        t: '/it/findcar?makesModels=Nissan%2CQashqai&cmsid=Nissan_Qashqai_Text',
    },
    {
        f: '/it/marche-auto/nissan/x-trail',
        t: '/it/findcar?makesModels=Nissan%2CX-Trail&cmsid=Nissan_x-trail_Text',
    },
    {
        f: '/it/marche-auto/nissan',
        t: '/it/findcar?makesModels=Nissan&cmsid=SEO_Nissan',
    },
    {
        f: '/it/marche-auto/opel/astra',
        t: '/it/findcar?makesModels=Opel%2CAstra&cmsid=Opel_Astra_Text',
    },
    {
        f: '/it/marche-auto/opel/combo',
        t: '/it/findcar?makesModels=Opel%2CCombo&cmsid=Opel_Combo_Text',
    },
    {
        f: '/it/marche-auto/opel/corsa',
        t: '/it/findcar?makesModels=Opel%2CCorsa&cmsid=Opel_Corsa_Text',
    },
    {
        f: '/it/marche-auto/opel/insignia',
        t: '/it/findcar?makesModels=Opel%2CInsignia&cmsid=Opel_Insignia_Text',
    },
    {
        f: '/it/marche-auto/opel/meriva',
        t: '/it/findcar?makesModels=Opel%2CMeriva&cmsid=Opel_Meriva_Text',
    },
    {
        f: '/it/marche-auto/opel/movano',
        t: '/it/findcar?makesModels=Opel%2CMovano&cmsid=Opel_Movano_Text',
    },
    {
        f: '/it/marche-auto/opel/vivaro',
        t: '/it/findcar?makesModels=Opel%2CVivaro&cmsid=Opel_Vivaro_Text',
    },
    {
        f: '/it/marche-auto/opel/zafira',
        t: '/it/findcar?makesModels=Opel%2CZafira&cmsid=Opel_Zafira_Text',
    },
    {
        f: '/it/marche-auto/opel',
        t: '/it/findcar?makesModels=Opel&cmsid=Seo_Opel',
    },
    {
        f: '/it/marche-auto/peugeot/407sw',
        t: '/it/findcar?makesModels=Peugeot%2C40x&cmsid=Peugeot_407sw_Text',
    },
    {
        f: '/it/marche-auto/peugeot/5008',
        t: '/it/findcar?makesModels=Peugeot%2C5008&cmsid=Peugeot_5008_Text',
    },
    {
        f: '/it/marche-auto/peugeot/expert',
        t: '/it/findcar?makesModels=Peugeot%2CExpert&cmsid=Peugeot_Expert_Text',
    },
    {
        f: '/it/marche-auto/peugeot/partner',
        t: '/it/findcar?makesModels=Peugeot%2CPartner&cmsid=Peugeot_Partner_Text',
    },
    {
        f: '/it/marche-auto/peugeot',
        t: '/it/findcar?makesModels=Peugeot&cmsid=Seo_Peugeot',
    },
    {
        f: '/it/marche-auto/piaggio',
        t: '/it/findcar?makesModels=Piaggio&cmsid=SEO_Piaggio',
    },
    {
        f: '/it/marche-auto/renault/clio',
        t: '/it/findcar?makesModels=Renault%2CClio&cmsid=Renault_Clio_Text',
    },
    {
        f: '/it/marche-auto/renault/espace',
        t: '/it/findcar?makesModels=Renault%2CEspace&cmsid=Renault_Espace_Text',
    },
    {
        f: '/it/marche-auto/renault/kangoo',
        t: '/it/findcar?makesModels=Renault%2CKangoo&cmsid=Renault_Kangoo_Text',
    },
    {
        f: '/it/marche-auto/renault/megane',
        t: '/it/findcar?makesModels=Renault%2CMegane&cmsid=Renault_Megane_Text',
    },
    {
        f: '/it/marche-auto/renault/scenic',
        t: '/it/findcar?makesModels=Renault%2CScenic&cmsid=Renault_Scenic_Text',
    },
    {
        f: '/it/marche-auto/renault',
        t: '/it/findcar?makesModels=Renault&cmsid=SEO_Renault',
    },
    {
        f: '/it/marche-auto/skoda/fabia',
        t: '/it/findcar?makesModels=Skoda%2CFabia&cmsid=Skoda_Fabia_Text',
    },
    {
        f: '/it/marche-auto/skoda/octavia',
        t: '/it/findcar?makesModels=Skoda%2COctavia&cmsid=Skoda_Octavia_Text',
    },
    {
        f: '/it/marche-auto/skoda/rapid',
        t: '/it/findcar?makesModels=Skoda%2CRapid&cmsid=Skoda_Rapid_Text',
    },
    {
        f: '/it/marche-auto/skoda/superb',
        t: '/it/findcar?makesModels=Skoda%2CSuperb&cmsid=Skoda_Superb_Text',
    },
    {
        f: '/it/marche-auto/skoda/yeti',
        t: '/it/findcar?makesModels=Skoda%2CYeti&cmsid=Skoda_Yeti_Text',
    },
    {
        f: '/it/marche-auto/skoda',
        t: '/it/findcar?makesModels=Skoda&cmsid=SEO_Skoda',
    },
    {
        f: '/it/marche-auto/toyota/auris',
        t: '/it/findcar?makesModels=Toyota%2CAuris&cmsid=Toyota_Auris_Text',
    },
    {
        f: '/it/marche-auto/toyota/avensis',
        t: '/it/findcar?makesModels=Toyota%2CAvensis&cmsid=Toyota_Avensis_Text',
    },
    {
        f: '/it/marche-auto/toyota/aygo',
        t: '/it/findcar?makesModels=Toyota%2CAygo&cmsid=Toyota_Aygo_Text',
    },
    {
        f: '/it/marche-auto/toyota/prius',
        t: '/it/findcar?makesModels=Toyota%2CPrius&cmsid=Toyota_Prius_Text',
    },
    {
        f: '/it/marche-auto/toyota/rav4',
        t: '/it/findcar?makesModels=Toyota%2CRav4&cmsid=Toyota_Rav4_Text',
    },
    {
        f: '/it/marche-auto/toyota/yaris',
        t: '/it/findcar?makesModels=Toyota%2CYaris&cmsid=Toyota_Yaris_Text',
    },
    {
        f: '/it/marche-auto/toyota',
        t: '/it/findcar?makesModels=Toyota&cmsid=SEO_Toyota',
    },
    {
        f: '/it/marche-auto/volkswagen/caddy',
        t: '/it/findcar?makesModels=Volkswagen%2CCaddy&cmsid=Volkswagen_Caddy_Text',
    },
    {
        f: '/it/marche-auto/volkswagen/golf',
        t: '/it/findcar?makesModels=Volkswagen%2CGolf&cmsid=Volkswagen_Golf_Text',
    },
    {
        f: '/it/marche-auto/volkswagen/passat',
        t: '/it/findcar?makesModels=Volkswagen%2CPassat&cmsid=Volkswagen_Passat_Text',
    },
    {
        f: '/it/marche-auto/volkswagen/polo',
        t: '/it/findcar?makesModels=Volkswagen%2CPolo&cmsid=Volkswagen_Polo_Text',
    },
    {
        f: '/it/marche-auto/volkswagen/tiguan',
        t: '/it/findcar?makesModels=Volkswagen%2CTiguan&cmsid=Volkswagen_Tiguan_Text',
    },
    {
        f: '/it/marche-auto/volkswagen/touareg',
        t: '/it/findcar?makesModels=Volkswagen%2CTouareg&cmsid=Volkswagen_Touareg_Text',
    },
    {
        f: '/it/marche-auto/volkswagen/touran',
        t: '/it/findcar?makesModels=Volkswagen%2CTouran&cmsid=Volkswagen_Touran_Text',
    },
    {
        f: '/it/marche-auto/volkswagen/transporter',
        t: '/it/findcar?makesModels=Volkswagen%2CTransporter&cmsid=Volkswagen_Transporter_Text',
    },
    {
        f: '/it/marche-auto/volkswagen',
        t: '/it/findcar?makesModels=Volkswagen&cmsid=SEO_Volkswagen',
    },
    {
        f: '/it/marche-auto/volvo/s60',
        t: '/it/findcar?makesModels=Volvo%2CS60&cmsid=Volvo_S60_Text',
    },
    {
        f: '/it/marche-auto/volvo/s80',
        t: '/it/findcar?makesModels=Volvo%2CS80&cmsid=Volvo_S80_Text',
    },
    {
        f: '/it/marche-auto/volvo/s90',
        t: '/it/findcar?makesModels=Volvo%2CS90&cmsid=Volvo_S90_Text',
    },
    {
        f: '/it/marche-auto/volvo/v40',
        t: '/it/findcar?makesModels=Volvo%2CV40&cmsid=Volvo_V40_Text',
    },
    {
        f: '/it/marche-auto/volvo/v60',
        t: '/it/findcar?makesModels=Volvo%2CV60&cmsid=Volvo_V60_Text',
    },
    {
        f: '/it/marche-auto/volvo/v90',
        t: '/it/findcar?makesModels=Volvo%2CV90&cmsid=Volvo_V90_Text',
    },
    {
        f: '/it/marche-auto/volvo/xc60',
        t: '/it/findcar?makesModels=Volvo%2CXC60&cmsid=Volvo_XC60_Text',
    },
    {
        f: '/it/marche-auto/volvo/xc90',
        t: '/it/findcar?makesModels=Volvo%2CXC90&cmsid=Volvo_XC90_Text',
    },
    {
        f: '/it/marche-auto/volvo',
        t: '/it/findcar?makesModels=Volvo&cmsid=SEO_Volvo',
    },
    {
        f: '/it/auto-usate-belgio',
        t: '/it/findcar?originCountries=be&cmsid=used-cars-belgium',
    },
    {
        f: '/it/auto-usate-germania',
        t: '/it/findcar?originCountries=de&cmsid=used-cars-germany',
    },
    {
        f: '/it/auto-usate-spagna',
        t: '/it/findcar?originCountries=es&cmsid=used-cars-spain',
    },
    {
        f: '/it/auto-usate-francia',
        t: '/it/findcar?originCountries=fr&cmsid=used-cars-france',
    },
    {
        f: '/it/auto-usate-italia',
        t: '/it/findcar?originCountries=it&cmsid=used-cars-italy',
    },
    {
        f: '/it/auto-usate-lussemburgo',
        t: '/it/findcar?originCountries=lu&cmsid=used-cars-luxembourg',
    },
    {
        f: '/it/auto-usate-olanda',
        t: '/it/findcar?originCountries=nl&cmsid=used-cars-holland',
    },
    {
        f: '/it/auto-usate-polonia',
        t: '/it/findcar?originCountries=pl&cmsid=used-cars-poland',
    },
    {
        f: '/it/auto-usate-svezia',
        t: '/it/findcar?originCountries=se&cmsid=used-cars-sweden',
    },
    {
        f: '/it/quotazioni-auto-usate/auto-usate-a-poco-prezzo',
        t: '/it/findcar?prices=0%2C3000&cmsid=cheap_used_cars',
    },
    {
        f: '/it/quotazioni-auto-usate/middle-class',
        t: '/it/findcar?prices=10000%2C20000&cmsid=Middle_class',
    },
    {
        f: '/it/quotazioni-auto-usate/luxury-cars',
        t: '/it/findcar?prices=20000%2C0&cmsid=luxury_cars',
    },
    {
        f: '/it/quotazioni-auto-usate/utilitarie',
        t: '/it/findcar?prices=3000%2C6000&cmsid=economy_cars',
    },
    {
        f: '/it/quotazioni-auto-usate/value-for-money',
        t: '/it/findcar?prices=6000%2C10000&cmsid=Value_for_money',
    },
    {
        f: '/lt/body-type/sunkvezimis',
        t: '/lt/findcar?bodyTypes=Truck&cmsid=Truck',
    },
    {
        f: '/lt/car-auction/uztikrintas-priskyrimas',
        t: '/lt/findcar?assignment100pc=true&cmsid=100assignment',
    },
    {
        f: '/lt/car-auction/dinaminis',
        t: '/lt/findcar?auctionTypes=1&cmsid=dynamic',
    },
    {
        f: '/lt/car-auction/pirk-dabar',
        t: '/lt/findcar?auctionTypes=2&cmsid=buy-now',
    },
    {
        f: '/lt/car-auction/slaptasis',
        t: '/lt/findcar?auctionTypes=4&cmsid=blind',
    },
    {
        f: '/lt/car-auction/tikslinis',
        t: '/lt/findcar?auctionTypes=5&cmsid=target',
    },
    {
        f: '/lt/body-type/sedanas',
        t: '/lt/findcar?bodyTypes=Berline&cmsid=SEO_Berline',
    },
    {
        f: '/lt/body-type/minivenas',
        t: '/lt/findcar?bodyTypes=Break&cmsid=SEO_Break',
    },
    {
        f: '/lt/body-type/kabrioletas',
        t: '/lt/findcar?bodyTypes=Cabriolet&cmsid=SEO_Cabrio',
    },
    {
        f: '/lt/body-type/kompaktinis-automobilis',
        t: '/lt/findcar?bodyTypes=Compact&cmsid=SEO_Compact',
    },
    {
        f: '/lt/body-type/kupe',
        t: '/lt/findcar?bodyTypes=Coup%C3%A9&cmsid=SEO_Coup%C3%A9',
    },
    {
        f: '/lt/body-type/hecbekas',
        t: '/lt/findcar?bodyTypes=Hatchback&cmsid=SEO_Hatchback',
    },
    {
        f: '/lt/body-type/furgonas',
        t: '/lt/findcar?bodyTypes=Lighttruck&cmsid=SEO_Light-truck',
    },
    {
        f: '/lt/body-type/mikroautobusas',
        t: '/lt/findcar?bodyTypes=Minibus&cmsid=Minibus',
    },
    {
        f: '/lt/body-type/motociklas',
        t: '/lt/findcar?bodyTypes=Motorbike&cmsid=Motorbike',
    },
    {
        f: '/lt/body-type/universalas',
        t: '/lt/findcar?bodyTypes=MPV&cmsid=SEO_MPV',
    },
    { f: '/lt/body-type/suv', t: '/lt/findcar?bodyTypes=SUV&cmsid=SEO_SUV' },
    {
        f: '/lt/car-auction/be-pazeidimu',
        t: '/lt/findcar?damageTypes=1&cmsid=no_specific-Damages',
    },
    {
        f: '/lt/car-auction/dauzti-automobiliai',
        t: '/lt/findcar?damageTypes=2&cmsid=Body-damages',
    },
    {
        f: '/lt/car-auction/automobiliai-su-techniniais-gedimais',
        t: '/lt/findcar?damageTypes=3&cmsid=technical-damages',
    },
    {
        f: '/lt/car-auction/techniniai-gedimai-ir-kebulo-pazeidimai',
        t: '/lt/findcar?damageTypes=4&cmsid=Technical-and-body-damages',
    },
    {
        f: '/lt/automobiliu-markes/abarth',
        t: '/lt/findcar?makesModels=Abarth&cmsid=SEO_Abarth',
    },
    {
        f: '/lt/automobiliu-markes/alfa-romeo',
        t: '/lt/findcar?makesModels=Alfa%20Romeo&cmsid=SEO_alfa-romeo',
    },
    {
        f: '/lt/automobiliu-markes/audi/a1',
        t: '/lt/findcar?makesModels=Audi%2CA1&cmsid=Audi_A1_Text',
    },
    {
        f: '/lt/automobiliu-markes/audi/a2',
        t: '/lt/findcar?makesModels=Audi%2CA2&cmsid=Audi_A2_Text',
    },
    {
        f: '/lt/automobiliu-markes/audi/a3',
        t: '/lt/findcar?makesModels=Audi%2CA3&cmsid=Audi_A3_Text',
    },
    {
        f: '/lt/automobiliu-markes/audi/a4',
        t: '/lt/findcar?makesModels=Audi%2CA4&cmsid=Audi_A4_Text',
    },
    {
        f: '/lt/automobiliu-markes/audi/a5',
        t: '/lt/findcar?makesModels=Audi%2CA5&cmsid=Audi_A5_Text',
    },
    {
        f: '/lt/automobiliu-markes/audi/a6',
        t: '/lt/findcar?makesModels=Audi%2CA6&cmsid=Audi_A6_Text',
    },
    {
        f: '/lt/automobiliu-markes/audi/q3',
        t: '/lt/findcar?makesModels=Audi%2CQ3&cmsid=Audi_Q3_Text',
    },
    {
        f: '/lt/automobiliu-markes/audi/q5',
        t: '/lt/findcar?makesModels=Audi%2CQ5&cmsid=Audi_Q5_Text',
    },
    {
        f: '/lt/automobiliu-markes/audi/q7',
        t: '/lt/findcar?makesModels=Audi%2CQ7&cmsid=Audi_Q7_Text',
    },
    {
        f: '/lt/automobiliu-markes/audi',
        t: '/lt/findcar?makesModels=Audi&cmsid=SEO_Audi',
    },
    {
        f: '/lt/automobiliu-markes/bmw/1-serija',
        t: '/lt/findcar?makesModels=BMW%2C1%20Series&cmsid=BMW_1series_Text',
    },
    {
        f: '/lt/automobiliu-markes/bmw/2-serija',
        t: '/lt/findcar?makesModels=BMW%2C2%20Series&cmsid=BMW_2series_Text',
    },
    {
        f: '/lt/automobiliu-markes/bmw/3-serija',
        t: '/lt/findcar?makesModels=BMW%2C3%20Series&cmsid=BMW_3series_Text',
    },
    {
        f: '/lt/automobiliu-markes/bmw/4-serija',
        t: '/lt/findcar?makesModels=BMW%2C4%20Series&cmsid=BMW_4series_Text',
    },
    {
        f: '/lt/automobiliu-markes/bmw/5-serija',
        t: '/lt/findcar?makesModels=BMW%2C5%20Series&cmsid=BMW_5series_Text',
    },
    {
        f: '/lt/automobiliu-markes/bmw/x1',
        t: '/lt/findcar?makesModels=BMW%2CX1&cmsid=BMW_X1_Text',
    },
    {
        f: '/lt/automobiliu-markes/bmw/x3',
        t: '/lt/findcar?makesModels=BMW%2CX3&cmsid=BMW_X3_Text',
    },
    {
        f: '/lt/automobiliu-markes/bmw/x5',
        t: '/lt/findcar?makesModels=BMW%2CX5&cmsid=BMW_X5_Text',
    },
    {
        f: '/lt/automobiliu-markes/bmw/x6',
        t: '/lt/findcar?makesModels=BMW%2CX6&cmsid=BMW_X6_Text',
    },
    {
        f: '/lt/automobiliu-markes/bmw',
        t: '/lt/findcar?makesModels=BMW&cmsid=SEO_BMW',
    },
    {
        f: '/lt/automobiliu-markes/citroen/berlingo',
        t: '/lt/findcar?makesModels=Citro%C3%ABn%2CBerlingo&cmsid=Citro%C3%ABn_Berlingo_text',
    },
    {
        f: '/lt/automobiliu-markes/citroen/c3',
        t: '/lt/findcar?makesModels=Citro%C3%ABn%2CC3&cmsid=Citro%C3%ABn_C3_text',
    },
    {
        f: '/lt/automobiliu-markes/citroen/c4',
        t: '/lt/findcar?makesModels=Citro%C3%ABn%2CC4&cmsid=Citro%C3%ABn_C4_text',
    },
    {
        f: '/lt/automobiliu-markes/citroen/c5',
        t: '/lt/findcar?makesModels=Citro%C3%ABn%2CC5&cmsid=Citro%C3%ABn_C5_text',
    },
    {
        f: '/lt/automobiliu-markes/citroen/ds3',
        t: '/lt/findcar?makesModels=Citro%C3%ABn%2CDS3&cmsid=Citro%C3%ABn_DS3_text',
    },
    {
        f: '/lt/automobiliu-markes/citroen/ds4',
        t: '/lt/findcar?makesModels=Citro%C3%ABn%2CDS4&cmsid=Citro%C3%ABn_DS4_text',
    },
    {
        f: '/lt/automobiliu-markes/citroen/dispatch',
        t: '/lt/findcar?makesModels=Citro%C3%ABn%2CJumpy&cmsid=Citro%C3%ABn_Dispatch_text',
    },
    {
        f: '/lt/automobiliu-markes/citroen',
        t: '/lt/findcar?makesModels=Citro%C3%ABn&cmsid=SEO_Citro%C3%ABn',
    },
    {
        f: '/lt/automobiliu-markes/fiat/500',
        t: '/lt/findcar?makesModels=Fiat%2C500&cmsid=Fiat_500_Text',
    },
    {
        f: '/lt/automobiliu-markes/fiat/bravo',
        t: '/lt/findcar?makesModels=Fiat%2CBravo&cmsid=Fiat_bravo_Text',
    },
    {
        f: '/lt/automobiliu-markes/fiat/doblo',
        t: '/lt/findcar?makesModels=Fiat%2CDoblo&cmsid=Fiat_doblo_Text',
    },
    {
        f: '/lt/automobiliu-markes/fiat/ducato',
        t: '/lt/findcar?makesModels=Fiat%2CDucato&cmsid=Fiat_ducato_Text',
    },
    {
        f: '/lt/automobiliu-markes/fiat/freemont',
        t: '/lt/findcar?makesModels=Fiat%2CFreemont&cmsid=Fiat_freemont_Text',
    },
    {
        f: '/lt/automobiliu-markes/fiat/panda',
        t: '/lt/findcar?makesModels=Fiat%2CPanda&cmsid=Fiat_panda_Text',
    },
    {
        f: '/lt/automobiliu-markes/fiat/punto',
        t: '/lt/findcar?makesModels=Fiat%2CPunto&cmsid=Fiat_punto_Text',
    },
    {
        f: '/lt/automobiliu-markes/fiat',
        t: '/lt/findcar?makesModels=Fiat&cmsid=SEO_Fiat',
    },
    {
        f: '/lt/automobiliu-markes/ford/fiesta',
        t: '/lt/findcar?makesModels=Ford%2CFiesta&cmsid=Ford_Fiesta_Text',
    },
    {
        f: '/lt/automobiliu-markes/ford/focus',
        t: '/lt/findcar?makesModels=Ford%2CFocus&cmsid=Ford_Focus_Text',
    },
    {
        f: '/lt/automobiliu-markes/ford/mondeo',
        t: '/lt/findcar?makesModels=Ford%2CMondeo&cmsid=Ford_Mondeo_Text',
    },
    {
        f: '/lt/automobiliu-markes/ford/transit',
        t: '/lt/findcar?makesModels=Ford%2CTransit&cmsid=Ford_Transit_Text',
    },
    {
        f: '/lt/automobiliu-markes/ford',
        t: '/lt/findcar?makesModels=Ford&cmsid=SEO_Ford',
    },
    {
        f: '/lt/automobiliu-markes/lancia',
        t: '/lt/findcar?makesModels=Lancia&cmsid=SEO_Lancia',
    },
    {
        f: '/lt/automobiliu-markes/mercedes/a-klase',
        t: '/lt/findcar?makesModels=Mercedes-Benz%2CA&cmsid=Mercedes_A-class_Text',
    },
    {
        f: '/lt/automobiliu-markes/mercedes/b-klase',
        t: '/lt/findcar?makesModels=Mercedes-Benz%2CB&cmsid=Mercedes_B-class_Text',
    },
    {
        f: '/lt/automobiliu-markes/mercedes/c-klase',
        t: '/lt/findcar?makesModels=Mercedes-Benz%2CC&cmsid=Mercedes_C-class_Text',
    },
    {
        f: '/lt/automobiliu-markes/mercedes/e-klase',
        t: '/lt/findcar?makesModels=Mercedes-Benz%2CE&cmsid=Mercedes_E-class_Text',
    },
    {
        f: '/lt/automobiliu-markes/mercedes/sprinter',
        t: '/lt/findcar?makesModels=Mercedes-Benz%2CSprinter&cmsid=Mercedes_Sprinter_Text',
    },
    {
        f: '/lt/automobiliu-markes/mercedes/vito',
        t: '/lt/findcar?makesModels=Mercedes-Benz%2CVito&cmsid=Mercedes_Vito_Text',
    },
    {
        f: '/lt/automobiliu-markes/mercedes',
        t: '/lt/findcar?makesModels=Mercedes-Benz&cmsid=SEO_Mercedes',
    },
    {
        f: '/lt/automobiliu-markes/nissan/juke',
        t: '/lt/findcar?makesModels=Nissan%2CJuke&cmsid=Nissan_Juke_Text',
    },
    {
        f: '/lt/automobiliu-markes/nissan/micra',
        t: '/lt/findcar?makesModels=Nissan%2CMicra&cmsid=Nissan_micra_Text',
    },
    {
        f: '/lt/automobiliu-markes/nissan/pathfinder',
        t: '/lt/findcar?makesModels=Nissan%2CPathfinder&cmsid=Nissan_Pathfinder_Text',
    },
    {
        f: '/lt/automobiliu-markes/nissan/qashqai',
        t: '/lt/findcar?makesModels=Nissan%2CQashqai&cmsid=Nissan_Qashqai_Text',
    },
    {
        f: '/lt/automobiliu-markes/nissan/x-trail',
        t: '/lt/findcar?makesModels=Nissan%2CX-Trail&cmsid=Nissan_x-trail_Text',
    },
    {
        f: '/lt/automobiliu-markes/nissan',
        t: '/lt/findcar?makesModels=Nissan&cmsid=SEO_Nissan',
    },
    {
        f: '/lt/automobiliu-markes/opel/astra',
        t: '/lt/findcar?makesModels=Opel%2CAstra&cmsid=Opel_Astra_Text',
    },
    {
        f: '/lt/automobiliu-markes/opel/combo',
        t: '/lt/findcar?makesModels=Opel%2CCombo&cmsid=Opel_Combo_Text',
    },
    {
        f: '/lt/automobiliu-markes/opel/corsa',
        t: '/lt/findcar?makesModels=Opel%2CCorsa&cmsid=Opel_Corsa_Text',
    },
    {
        f: '/lt/automobiliu-markes/opel/insignia',
        t: '/lt/findcar?makesModels=Opel%2CInsignia&cmsid=Opel_Insignia_Text',
    },
    {
        f: '/lt/automobiliu-markes/opel/meriva',
        t: '/lt/findcar?makesModels=Opel%2CMeriva&cmsid=Opel_Meriva_Text',
    },
    {
        f: '/lt/automobiliu-markes/opel/movano',
        t: '/lt/findcar?makesModels=Opel%2CMovano&cmsid=Opel_Movano_Text',
    },
    {
        f: '/lt/automobiliu-markes/opel/vivaro',
        t: '/lt/findcar?makesModels=Opel%2CVivaro&cmsid=Opel_Vivaro_Text',
    },
    {
        f: '/lt/automobiliu-markes/opel/zafira',
        t: '/lt/findcar?makesModels=Opel%2CZafira&cmsid=Opel_Zafira_Text',
    },
    {
        f: '/lt/automobiliu-markes/opel',
        t: '/lt/findcar?makesModels=Opel&cmsid=Seo_Opel',
    },
    {
        f: '/lt/automobiliu-markes/peugeot/407sw',
        t: '/lt/findcar?makesModels=Peugeot%2C40x&cmsid=Peugeot_407sw_Text',
    },
    {
        f: '/lt/automobiliu-markes/peugeot/5008',
        t: '/lt/findcar?makesModels=Peugeot%2C5008&cmsid=Peugeot_5008_Text',
    },
    {
        f: '/lt/automobiliu-markes/peugeot/expert',
        t: '/lt/findcar?makesModels=Peugeot%2CExpert&cmsid=Peugeot_Expert_Text',
    },
    {
        f: '/lt/automobiliu-markes/peugeot/partner',
        t: '/lt/findcar?makesModels=Peugeot%2CPartner&cmsid=Peugeot_Partner_Text',
    },
    {
        f: '/lt/automobiliu-markes/peugeot',
        t: '/lt/findcar?makesModels=Peugeot&cmsid=Seo_Peugeot',
    },
    {
        f: '/lt/automobiliu-markes/piaggio',
        t: '/lt/findcar?makesModels=Piaggio&cmsid=SEO_Piaggio',
    },
    {
        f: '/lt/automobiliu-markes/renault/clio',
        t: '/lt/findcar?makesModels=Renault%2CClio&cmsid=Renault_Clio_Text',
    },
    {
        f: '/lt/automobiliu-markes/renault/espace',
        t: '/lt/findcar?makesModels=Renault%2CEspace&cmsid=Renault_Espace_Text',
    },
    {
        f: '/lt/automobiliu-markes/renault/kangoo',
        t: '/lt/findcar?makesModels=Renault%2CKangoo&cmsid=Renault_Kangoo_Text',
    },
    {
        f: '/lt/automobiliu-markes/renault/megane',
        t: '/lt/findcar?makesModels=Renault%2CMegane&cmsid=Renault_Megane_Text',
    },
    {
        f: '/lt/automobiliu-markes/renault/scenic',
        t: '/lt/findcar?makesModels=Renault%2CScenic&cmsid=Renault_Scenic_Text',
    },
    {
        f: '/lt/automobiliu-markes/renault',
        t: '/lt/findcar?makesModels=Renault&cmsid=SEO_Renault',
    },
    {
        f: '/lt/automobiliu-markes/skoda/fabia',
        t: '/lt/findcar?makesModels=Skoda%2CFabia&cmsid=Skoda_Fabia_Text',
    },
    {
        f: '/lt/automobiliu-markes/skoda/octavia',
        t: '/lt/findcar?makesModels=Skoda%2COctavia&cmsid=Skoda_Octavia_Text',
    },
    {
        f: '/lt/automobiliu-markes/skoda/rapid',
        t: '/lt/findcar?makesModels=Skoda%2CRapid&cmsid=Skoda_Rapid_Text',
    },
    {
        f: '/lt/automobiliu-markes/skoda/superb',
        t: '/lt/findcar?makesModels=Skoda%2CSuperb&cmsid=Skoda_Superb_Text',
    },
    {
        f: '/lt/automobiliu-markes/skoda/yeti',
        t: '/lt/findcar?makesModels=Skoda%2CYeti&cmsid=Skoda_Yeti_Text',
    },
    {
        f: '/lt/automobiliu-markes/skoda',
        t: '/lt/findcar?makesModels=Skoda&cmsid=SEO_Skoda',
    },
    {
        f: '/lt/automobiliu-markes/toyota/auris',
        t: '/lt/findcar?makesModels=Toyota%2CAuris&cmsid=Toyota_Auris_Text',
    },
    {
        f: '/lt/automobiliu-markes/toyota/avensis',
        t: '/lt/findcar?makesModels=Toyota%2CAvensis&cmsid=Toyota_Avensis_Text',
    },
    {
        f: '/lt/automobiliu-markes/toyota/aygo',
        t: '/lt/findcar?makesModels=Toyota%2CAygo&cmsid=Toyota_Aygo_Text',
    },
    {
        f: '/lt/automobiliu-markes/toyota/prius',
        t: '/lt/findcar?makesModels=Toyota%2CPrius&cmsid=Toyota_Prius_Text',
    },
    {
        f: '/lt/automobiliu-markes/toyota/rav4',
        t: '/lt/findcar?makesModels=Toyota%2CRav4&cmsid=Toyota_Rav4_Text',
    },
    {
        f: '/lt/automobiliu-markes/toyota/yaris',
        t: '/lt/findcar?makesModels=Toyota%2CYaris&cmsid=Toyota_Yaris_Text',
    },
    {
        f: '/lt/automobiliu-markes/toyota',
        t: '/lt/findcar?makesModels=Toyota&cmsid=SEO_Toyota',
    },
    {
        f: '/lt/automobiliu-markes/volkswagen/caddy',
        t: '/lt/findcar?makesModels=Volkswagen%2CCaddy&cmsid=Volkswagen_Caddy_Text',
    },
    {
        f: '/lt/automobiliu-markes/volkswagen/golf',
        t: '/lt/findcar?makesModels=Volkswagen%2CGolf&cmsid=Volkswagen_Golf_Text',
    },
    {
        f: '/lt/automobiliu-markes/volkswagen/passat',
        t: '/lt/findcar?makesModels=Volkswagen%2CPassat&cmsid=Volkswagen_Passat_Text',
    },
    {
        f: '/lt/automobiliu-markes/volkswagen/polo',
        t: '/lt/findcar?makesModels=Volkswagen%2CPolo&cmsid=Volkswagen_Polo_Text',
    },
    {
        f: '/lt/automobiliu-markes/volkswagen/tiguan',
        t: '/lt/findcar?makesModels=Volkswagen%2CTiguan&cmsid=Volkswagen_Tiguan_Text',
    },
    {
        f: '/lt/automobiliu-markes/volkswagen/touareg',
        t: '/lt/findcar?makesModels=Volkswagen%2CTouareg&cmsid=Volkswagen_Touareg_Text',
    },
    {
        f: '/lt/automobiliu-markes/volkswagen/touran',
        t: '/lt/findcar?makesModels=Volkswagen%2CTouran&cmsid=Volkswagen_Touran_Text',
    },
    {
        f: '/lt/automobiliu-markes/volkswagen/transporter',
        t: '/lt/findcar?makesModels=Volkswagen%2CTransporter&cmsid=Volkswagen_Transporter_Text',
    },
    {
        f: '/lt/automobiliu-markes/volkswagen',
        t: '/lt/findcar?makesModels=Volkswagen&cmsid=SEO_Volkswagen',
    },
    {
        f: '/lt/automobiliu-markes/volvo/s60',
        t: '/lt/findcar?makesModels=Volvo%2CS60&cmsid=Volvo_S60_Text',
    },
    {
        f: '/lt/automobiliu-markes/volvo/s80',
        t: '/lt/findcar?makesModels=Volvo%2CS80&cmsid=Volvo_S80_Text',
    },
    {
        f: '/lt/automobiliu-markes/volvo/s90',
        t: '/lt/findcar?makesModels=Volvo%2CS90&cmsid=Volvo_S90_Text',
    },
    {
        f: '/lt/automobiliu-markes/volvo/v40',
        t: '/lt/findcar?makesModels=Volvo%2CV40&cmsid=Volvo_V40_Text',
    },
    {
        f: '/lt/automobiliu-markes/volvo/v60',
        t: '/lt/findcar?makesModels=Volvo%2CV60&cmsid=Volvo_V60_Text',
    },
    {
        f: '/lt/automobiliu-markes/volvo/v90',
        t: '/lt/findcar?makesModels=Volvo%2CV90&cmsid=Volvo_V90_Text',
    },
    {
        f: '/lt/automobiliu-markes/volvo/xc60',
        t: '/lt/findcar?makesModels=Volvo%2CXC60&cmsid=Volvo_XC60_Text',
    },
    {
        f: '/lt/automobiliu-markes/volvo/xc90',
        t: '/lt/findcar?makesModels=Volvo%2CXC90&cmsid=Volvo_XC90_Text',
    },
    {
        f: '/lt/automobiliu-markes/volvo',
        t: '/lt/findcar?makesModels=Volvo&cmsid=SEO_Volvo',
    },
    {
        f: '/lt/naudoti-automobiliai-belgijos',
        t: '/lt/findcar?originCountries=be&cmsid=used-cars-belgium',
    },
    {
        f: '/lt/naudoti-automobiliai-vokietijos',
        t: '/lt/findcar?originCountries=de&cmsid=used-cars-germany',
    },
    {
        f: '/lt/naudoti-automobiliai-ispanijos',
        t: '/lt/findcar?originCountries=es&cmsid=used-cars-spain',
    },
    {
        f: '/lt/naudoti-automobiliai-prancuzijos',
        t: '/lt/findcar?originCountries=fr&cmsid=used-cars-france',
    },
    {
        f: '/lt/naudoti-automobiliai-Italijoje',
        t: '/lt/findcar?originCountries=it&cmsid=used-cars-italy',
    },
    {
        f: '/lt/naudoti-automobiliai-liuksemburgas',
        t: '/lt/findcar?originCountries=lu&cmsid=used-cars-luxembourg',
    },
    {
        f: '/lt/naudoti-automobiliai-olandijos',
        t: '/lt/findcar?originCountries=nl&cmsid=used-cars-holland',
    },
    {
        f: '/lt/naudoti-automobiliai-lenkija',
        t: '/lt/findcar?originCountries=pl&cmsid=used-cars-poland',
    },
    {
        f: '/lt/naudoti-automobiliai-svedai',
        t: '/lt/findcar?originCountries=se&cmsid=used-cars-sweden',
    },
    {
        f: '/lt/naudotu-automobiliu-kainos/pigus-naudoti-automobiliai',
        t: '/lt/findcar?prices=0%2C3000&cmsid=cheap_used_cars',
    },
    {
        f: '/lt/naudotu-automobiliu-kainos/middle-class',
        t: '/lt/findcar?prices=10000%2C20000&cmsid=Middle_class',
    },
    {
        f: '/lt/naudotu-automobiliu-kainos/luxury-cars',
        t: '/lt/findcar?prices=20000%2C0&cmsid=luxury_cars',
    },
    {
        f: '/lt/naudotu-automobiliu-kainos/economy-cars',
        t: '/lt/findcar?prices=3000%2C6000&cmsid=economy_cars',
    },
    {
        f: '/lt/naudotu-automobiliu-kainos/value-for-money',
        t: '/lt/findcar?prices=6000%2C10000&cmsid=Value_for_money',
    },
    {
        f: '/nl-be/body-type/vrachtwagen',
        t: '/nl-be/findcar?bodyTypes=Truck&cmsid=Truck',
    },
    {
        f: '/nl-be/autoveilingen/100-toewijzing',
        t: '/nl-be/findcar?assignment100pc=true&cmsid=100assignment',
    },
    {
        f: '/nl-be/autoveilingen/dynamisch',
        t: '/nl-be/findcar?auctionTypes=1&cmsid=dynamic',
    },
    {
        f: '/nl-be/autoveilingen/koop-nu',
        t: '/nl-be/findcar?auctionTypes=2&cmsid=buy-now',
    },
    {
        f: '/nl-be/autoveilingen/blind',
        t: '/nl-be/findcar?auctionTypes=4&cmsid=blind',
    },
    {
        f: '/nl-be/autoveilingen/target',
        t: '/nl-be/findcar?auctionTypes=5&cmsid=target',
    },
    {
        f: '/nl-be/body-type/sedan',
        t: '/nl-be/findcar?bodyTypes=Berline&cmsid=SEO_Berline',
    },
    {
        f: '/nl-be/body-type/station',
        t: '/nl-be/findcar?bodyTypes=Break&cmsid=SEO_Break',
    },
    {
        f: '/nl-be/body-type/cabrio',
        t: '/nl-be/findcar?bodyTypes=Cabriolet&cmsid=SEO_Cabrio',
    },
    {
        f: '/nl-be/body-type/compact',
        t: '/nl-be/findcar?bodyTypes=Compact&cmsid=SEO_Compact',
    },
    {
        f: '/nl-be/body-type/coupe',
        t: '/nl-be/findcar?bodyTypes=Coup%C3%A9&cmsid=SEO_Coup%C3%A9',
    },
    {
        f: '/nl-be/body-type/hatchback',
        t: '/nl-be/findcar?bodyTypes=Hatchback&cmsid=SEO_Hatchback',
    },
    {
        f: '/nl-be/body-type/lichte-bedrijfswagen',
        t: '/nl-be/findcar?bodyTypes=Lighttruck&cmsid=SEO_Light-truck',
    },
    {
        f: '/nl-be/body-type/minibus',
        t: '/nl-be/findcar?bodyTypes=Minibus&cmsid=Minibus',
    },
    {
        f: '/nl-be/body-type/motorbike',
        t: '/nl-be/findcar?bodyTypes=Motorbike&cmsid=Motorbike',
    },
    {
        f: '/nl-be/body-type/mpv',
        t: '/nl-be/findcar?bodyTypes=MPV&cmsid=SEO_MPV',
    },
    {
        f: '/nl-be/body-type/suv',
        t: '/nl-be/findcar?bodyTypes=SUV&cmsid=SEO_SUV',
    },
    {
        f: '/nl-be/autoveilingen/zonder-schade',
        t: '/nl-be/findcar?damageTypes=1&cmsid=no_specific-Damages',
    },
    {
        f: '/nl-be/autoveilingen/ongevalwagens',
        t: '/nl-be/findcar?damageTypes=2&cmsid=Body-damages',
    },
    {
        f: '/nl-be/autoveilingen/autos-met-technische-schade',
        t: '/nl-be/findcar?damageTypes=3&cmsid=technical-damages',
    },
    {
        f: '/nl-be/autoveilingen/technische-en-carrosserieschade',
        t: '/nl-be/findcar?damageTypes=4&cmsid=Technical-and-body-damages',
    },
    {
        f: '/nl-be/auto-merk/abarth',
        t: '/nl-be/findcar?makesModels=Abarth&cmsid=SEO_Abarth',
    },
    {
        f: '/nl-be/auto-merk/alfa-romeo',
        t: '/nl-be/findcar?makesModels=Alfa%20Romeo&cmsid=SEO_alfa-romeo',
    },
    {
        f: '/nl-be/auto-merk/audi/a1',
        t: '/nl-be/findcar?makesModels=Audi%2CA1&cmsid=Audi_A1_Text',
    },
    {
        f: '/nl-be/auto-merk/audi/a2',
        t: '/nl-be/findcar?makesModels=Audi%2CA2&cmsid=Audi_A2_Text',
    },
    {
        f: '/nl-be/auto-merk/audi/a3',
        t: '/nl-be/findcar?makesModels=Audi%2CA3&cmsid=Audi_A3_Text',
    },
    {
        f: '/nl-be/auto-merk/audi/a4',
        t: '/nl-be/findcar?makesModels=Audi%2CA4&cmsid=Audi_A4_Text',
    },
    {
        f: '/nl-be/auto-merk/audi/a5',
        t: '/nl-be/findcar?makesModels=Audi%2CA5&cmsid=Audi_A5_Text',
    },
    {
        f: '/nl-be/auto-merk/audi/a6',
        t: '/nl-be/findcar?makesModels=Audi%2CA6&cmsid=Audi_A6_Text',
    },
    {
        f: '/nl-be/auto-merk/audi/q3',
        t: '/nl-be/findcar?makesModels=Audi%2CQ3&cmsid=Audi_Q3_Text',
    },
    {
        f: '/nl-be/auto-merk/audi/q5',
        t: '/nl-be/findcar?makesModels=Audi%2CQ5&cmsid=Audi_Q5_Text',
    },
    {
        f: '/nl-be/auto-merk/audi/q7',
        t: '/nl-be/findcar?makesModels=Audi%2CQ7&cmsid=Audi_Q7_Text',
    },
    {
        f: '/nl-be/auto-merk/audi',
        t: '/nl-be/findcar?makesModels=Audi&cmsid=SEO_Audi',
    },
    {
        f: '/nl-be/auto-merk/bmw/1-reeks',
        t: '/nl-be/findcar?makesModels=BMW%2C1%20Series&cmsid=BMW_1series_Text',
    },
    {
        f: '/nl-be/auto-merk/bmw/2-reeks',
        t: '/nl-be/findcar?makesModels=BMW%2C2%20Series&cmsid=BMW_2series_Text',
    },
    {
        f: '/nl-be/auto-merk/bmw/3-reeks',
        t: '/nl-be/findcar?makesModels=BMW%2C3%20Series&cmsid=BMW_3series_Text',
    },
    {
        f: '/nl-be/auto-merk/bmw/4-reeks',
        t: '/nl-be/findcar?makesModels=BMW%2C4%20Series&cmsid=BMW_4series_Text',
    },
    {
        f: '/nl-be/auto-merk/bmw/5-reeks',
        t: '/nl-be/findcar?makesModels=BMW%2C5%20Series&cmsid=BMW_5series_Text',
    },
    {
        f: '/nl-be/auto-merk/bmw/x1',
        t: '/nl-be/findcar?makesModels=BMW%2CX1&cmsid=BMW_X1_Text',
    },
    {
        f: '/nl-be/auto-merk/bmw/x3',
        t: '/nl-be/findcar?makesModels=BMW%2CX3&cmsid=BMW_X3_Text',
    },
    {
        f: '/nl-be/auto-merk/bmw/x5',
        t: '/nl-be/findcar?makesModels=BMW%2CX5&cmsid=BMW_X5_Text',
    },
    {
        f: '/nl-be/auto-merk/bmw/x6',
        t: '/nl-be/findcar?makesModels=BMW%2CX6&cmsid=BMW_X6_Text',
    },
    {
        f: '/nl-be/auto-merk/bmw',
        t: '/nl-be/findcar?makesModels=BMW&cmsid=SEO_BMW',
    },
    {
        f: '/nl-be/auto-merk/citroen/berlingo',
        t: '/nl-be/findcar?makesModels=Citro%C3%ABn%2CBerlingo&cmsid=Citro%C3%ABn_Berlingo_text',
    },
    {
        f: '/nl-be/auto-merk/citroen/c3',
        t: '/nl-be/findcar?makesModels=Citro%C3%ABn%2CC3&cmsid=Citro%C3%ABn_C3_text',
    },
    {
        f: '/nl-be/auto-merk/citroen/c4',
        t: '/nl-be/findcar?makesModels=Citro%C3%ABn%2CC4&cmsid=Citro%C3%ABn_C4_text',
    },
    {
        f: '/nl-be/auto-merk/citroen/c5',
        t: '/nl-be/findcar?makesModels=Citro%C3%ABn%2CC5&cmsid=Citro%C3%ABn_C5_text',
    },
    {
        f: '/nl-be/auto-merk/citroen/ds3',
        t: '/nl-be/findcar?makesModels=Citro%C3%ABn%2CDS3&cmsid=Citro%C3%ABn_DS3_text',
    },
    {
        f: '/nl-be/auto-merk/citroen/ds4',
        t: '/nl-be/findcar?makesModels=Citro%C3%ABn%2CDS4&cmsid=Citro%C3%ABn_DS4_text',
    },
    {
        f: '/nl-be/auto-merk/citroen/dispatch',
        t: '/nl-be/findcar?makesModels=Citro%C3%ABn%2CJumpy&cmsid=Citro%C3%ABn_Dispatch_text',
    },
    {
        f: '/nl-be/auto-merk/citroen',
        t: '/nl-be/findcar?makesModels=Citro%C3%ABn&cmsid=SEO_Citro%C3%ABn',
    },
    {
        f: '/nl-be/auto-merk/fiat/501',
        t: '/nl-be/findcar?makesModels=Fiat%2C500&cmsid=Fiat_500_Text',
    },
    {
        f: '/nl-be/auto-merk/fiat/bravo',
        t: '/nl-be/findcar?makesModels=Fiat%2CBravo&cmsid=Fiat_bravo_Text',
    },
    {
        f: '/nl-be/auto-merk/fiat/doblo',
        t: '/nl-be/findcar?makesModels=Fiat%2CDoblo&cmsid=Fiat_doblo_Text',
    },
    {
        f: '/nl-be/auto-merk/fiat/ducato',
        t: '/nl-be/findcar?makesModels=Fiat%2CDucato&cmsid=Fiat_ducato_Text',
    },
    {
        f: '/nl-be/auto-merk/fiat/freemont',
        t: '/nl-be/findcar?makesModels=Fiat%2CFreemont&cmsid=Fiat_freemont_Text',
    },
    {
        f: '/nl-be/auto-merk/fiat/panda',
        t: '/nl-be/findcar?makesModels=Fiat%2CPanda&cmsid=Fiat_panda_Text',
    },
    {
        f: '/nl-be/auto-merk/fiat/punto',
        t: '/nl-be/findcar?makesModels=Fiat%2CPunto&cmsid=Fiat_punto_Text',
    },
    {
        f: '/nl-be/auto-merk/fiat',
        t: '/nl-be/findcar?makesModels=Fiat&cmsid=SEO_Fiat',
    },
    {
        f: '/nl-be/auto-merk/ford/fiesta',
        t: '/nl-be/findcar?makesModels=Ford%2CFiesta&cmsid=Ford_Fiesta_Text',
    },
    {
        f: '/nl-be/auto-merk/ford/focus',
        t: '/nl-be/findcar?makesModels=Ford%2CFocus&cmsid=Ford_Focus_Text',
    },
    {
        f: '/nl-be/auto-merk/ford/mondeo',
        t: '/nl-be/findcar?makesModels=Ford%2CMondeo&cmsid=Ford_Mondeo_Text',
    },
    {
        f: '/nl-be/auto-merk/ford/transit',
        t: '/nl-be/findcar?makesModels=Ford%2CTransit&cmsid=Ford_Transit_Text',
    },
    {
        f: '/nl-be/auto-merk/ford',
        t: '/nl-be/findcar?makesModels=Ford&cmsid=SEO_Ford',
    },
    {
        f: '/nl-be/auto-merk/lancia',
        t: '/nl-be/findcar?makesModels=Lancia&cmsid=SEO_Lancia',
    },
    {
        f: '/nl-be/auto-merk/mercedes/a-klasse',
        t: '/nl-be/findcar?makesModels=Mercedes-Benz%2CA&cmsid=Mercedes_A-class_Text',
    },
    {
        f: '/nl-be/auto-merk/mercedes/b-klasse',
        t: '/nl-be/findcar?makesModels=Mercedes-Benz%2CB&cmsid=Mercedes_B-class_Text',
    },
    {
        f: '/nl-be/auto-merk/mercedes/c-klasse',
        t: '/nl-be/findcar?makesModels=Mercedes-Benz%2CC&cmsid=Mercedes_C-class_Text',
    },
    {
        f: '/nl-be/auto-merk/mercedes/e-klasse',
        t: '/nl-be/findcar?makesModels=Mercedes-Benz%2CE&cmsid=Mercedes_E-class_Text',
    },
    {
        f: '/nl-be/auto-merk/mercedes/sprinter',
        t: '/nl-be/findcar?makesModels=Mercedes-Benz%2CSprinter&cmsid=Mercedes_Sprinter_Text',
    },
    {
        f: '/nl-be/auto-merk/mercedes/vito',
        t: '/nl-be/findcar?makesModels=Mercedes-Benz%2CVito&cmsid=Mercedes_Vito_Text',
    },
    {
        f: '/nl-be/auto-merk/mercedes',
        t: '/nl-be/findcar?makesModels=Mercedes-Benz&cmsid=SEO_Mercedes',
    },
    {
        f: '/nl-be/auto-merk/nissan/juke',
        t: '/nl-be/findcar?makesModels=Nissan%2CJuke&cmsid=Nissan_Juke_Text',
    },
    {
        f: '/nl-be/auto-merk/nissan/micra',
        t: '/nl-be/findcar?makesModels=Nissan%2CMicra&cmsid=Nissan_micra_Text',
    },
    {
        f: '/nl-be/auto-merk/nissan/pathfinder',
        t: '/nl-be/findcar?makesModels=Nissan%2CPathfinder&cmsid=Nissan_Pathfinder_Text',
    },
    {
        f: '/nl-be/auto-merk/nissan/qashqai',
        t: '/nl-be/findcar?makesModels=Nissan%2CQashqai&cmsid=Nissan_Qashqai_Text',
    },
    {
        f: '/nl-be/auto-merk/nissan/x-trail',
        t: '/nl-be/findcar?makesModels=Nissan%2CX-Trail&cmsid=Nissan_x-trail_Text',
    },
    {
        f: '/nl-be/auto-merk/nissan',
        t: '/nl-be/findcar?makesModels=Nissan&cmsid=SEO_Nissan',
    },
    {
        f: '/nl-be/auto-merk/opel/astra',
        t: '/nl-be/findcar?makesModels=Opel%2CAstra&cmsid=Opel_Astra_Text',
    },
    {
        f: '/nl-be/auto-merk/opel/combo',
        t: '/nl-be/findcar?makesModels=Opel%2CCombo&cmsid=Opel_Combo_Text',
    },
    {
        f: '/nl-be/auto-merk/opel/corsa',
        t: '/nl-be/findcar?makesModels=Opel%2CCorsa&cmsid=Opel_Corsa_Text',
    },
    {
        f: '/nl-be/auto-merk/opel/insignia',
        t: '/nl-be/findcar?makesModels=Opel%2CInsignia&cmsid=Opel_Insignia_Text',
    },
    {
        f: '/nl-be/auto-merk/opel/meriva',
        t: '/nl-be/findcar?makesModels=Opel%2CMeriva&cmsid=Opel_Meriva_Text',
    },
    {
        f: '/nl-be/auto-merk/opel/movano',
        t: '/nl-be/findcar?makesModels=Opel%2CMovano&cmsid=Opel_Movano_Text',
    },
    {
        f: '/nl-be/auto-merk/opel/vivaro',
        t: '/nl-be/findcar?makesModels=Opel%2CVivaro&cmsid=Opel_Vivaro_Text',
    },
    {
        f: '/nl-be/auto-merk/opel/zafira',
        t: '/nl-be/findcar?makesModels=Opel%2CZafira&cmsid=Opel_Zafira_Text',
    },
    {
        f: '/nl-be/auto-merk/opel',
        t: '/nl-be/findcar?makesModels=Opel&cmsid=Seo_Opel',
    },
    {
        f: '/nl-be/auto-merk/peugeot/407sw',
        t: '/nl-be/findcar?makesModels=Peugeot%2C40x&cmsid=Peugeot_407sw_Text',
    },
    {
        f: '/nl-be/auto-merk/peugeot/5008',
        t: '/nl-be/findcar?makesModels=Peugeot%2C5008&cmsid=Peugeot_5008_Text',
    },
    {
        f: '/nl-be/auto-merk/peugeot/expert',
        t: '/nl-be/findcar?makesModels=Peugeot%2CExpert&cmsid=Peugeot_Expert_Text',
    },
    {
        f: '/nl-be/auto-merk/peugeot/partner',
        t: '/nl-be/findcar?makesModels=Peugeot%2CPartner&cmsid=Peugeot_Partner_Text',
    },
    {
        f: '/nl-be/auto-merk/peugeot',
        t: '/nl-be/findcar?makesModels=Peugeot&cmsid=Seo_Peugeot',
    },
    {
        f: '/nl-be/auto-merk/piaggio',
        t: '/nl-be/findcar?makesModels=Piaggio&cmsid=SEO_Piaggio',
    },
    {
        f: '/nl-be/auto-merk/renault/clio',
        t: '/nl-be/findcar?makesModels=Renault%2CClio&cmsid=Renault_Clio_Text',
    },
    {
        f: '/nl-be/auto-merk/renault/espace',
        t: '/nl-be/findcar?makesModels=Renault%2CEspace&cmsid=Renault_Espace_Text',
    },
    {
        f: '/nl-be/auto-merk/renault/kangoo',
        t: '/nl-be/findcar?makesModels=Renault%2CKangoo&cmsid=Renault_Kangoo_Text',
    },
    {
        f: '/nl-be/auto-merk/renault/megane',
        t: '/nl-be/findcar?makesModels=Renault%2CMegane&cmsid=Renault_Megane_Text',
    },
    {
        f: '/nl-be/auto-merk/renault/scenic',
        t: '/nl-be/findcar?makesModels=Renault%2CScenic&cmsid=Renault_Scenic_Text',
    },
    {
        f: '/nl-be/auto-merk/renault',
        t: '/nl-be/findcar?makesModels=Renault&cmsid=SEO_Renault',
    },
    {
        f: '/nl-be/auto-merk/skoda/fabia',
        t: '/nl-be/findcar?makesModels=Skoda%2CFabia&cmsid=Skoda_Fabia_Text',
    },
    {
        f: '/nl-be/auto-merk/skoda/octavia',
        t: '/nl-be/findcar?makesModels=Skoda%2COctavia&cmsid=Skoda_Octavia_Text',
    },
    {
        f: '/nl-be/auto-merk/skoda/rapid',
        t: '/nl-be/findcar?makesModels=Skoda%2CRapid&cmsid=Skoda_Rapid_Text',
    },
    {
        f: '/nl-be/auto-merk/skoda/superb',
        t: '/nl-be/findcar?makesModels=Skoda%2CSuperb&cmsid=Skoda_Superb_Text',
    },
    {
        f: '/nl-be/auto-merk/skoda/yeti',
        t: '/nl-be/findcar?makesModels=Skoda%2CYeti&cmsid=Skoda_Yeti_Text',
    },
    {
        f: '/nl-be/auto-merk/skoda',
        t: '/nl-be/findcar?makesModels=Skoda&cmsid=SEO_Skoda',
    },
    {
        f: '/nl-be/auto-merk/toyota/auris',
        t: '/nl-be/findcar?makesModels=Toyota%2CAuris&cmsid=Toyota_Auris_Text',
    },
    {
        f: '/nl-be/auto-merk/toyota/avensis',
        t: '/nl-be/findcar?makesModels=Toyota%2CAvensis&cmsid=Toyota_Avensis_Text',
    },
    {
        f: '/nl-be/auto-merk/toyota/aygo',
        t: '/nl-be/findcar?makesModels=Toyota%2CAygo&cmsid=Toyota_Aygo_Text',
    },
    {
        f: '/nl-be/auto-merk/toyota/prius',
        t: '/nl-be/findcar?makesModels=Toyota%2CPrius&cmsid=Toyota_Prius_Text',
    },
    {
        f: '/nl-be/auto-merk/toyota/rav5',
        t: '/nl-be/findcar?makesModels=Toyota%2CRav4&cmsid=Toyota_Rav4_Text',
    },
    {
        f: '/nl-be/auto-merk/toyota/yaris',
        t: '/nl-be/findcar?makesModels=Toyota%2CYaris&cmsid=Toyota_Yaris_Text',
    },
    {
        f: '/nl-be/auto-merk/toyota',
        t: '/nl-be/findcar?makesModels=Toyota&cmsid=SEO_Toyota',
    },
    {
        f: '/nl-be/auto-merk/volkswagen/caddy',
        t: '/nl-be/findcar?makesModels=Volkswagen%2CCaddy&cmsid=Volkswagen_Caddy_Text',
    },
    {
        f: '/nl-be/auto-merk/volkswagen/golf',
        t: '/nl-be/findcar?makesModels=Volkswagen%2CGolf&cmsid=Volkswagen_Golf_Text',
    },
    {
        f: '/nl-be/auto-merk/volkswagen/passat',
        t: '/nl-be/findcar?makesModels=Volkswagen%2CPassat&cmsid=Volkswagen_Passat_Text',
    },
    {
        f: '/nl-be/auto-merk/volkswagen/polo',
        t: '/nl-be/findcar?makesModels=Volkswagen%2CPolo&cmsid=Volkswagen_Polo_Text',
    },
    {
        f: '/nl-be/auto-merk/volkswagen/tiguan',
        t: '/nl-be/findcar?makesModels=Volkswagen%2CTiguan&cmsid=Volkswagen_Tiguan_Text',
    },
    {
        f: '/nl-be/auto-merk/volkswagen/touareg',
        t: '/nl-be/findcar?makesModels=Volkswagen%2CTouareg&cmsid=Volkswagen_Touareg_Text',
    },
    {
        f: '/nl-be/auto-merk/volkswagen/touran',
        t: '/nl-be/findcar?makesModels=Volkswagen%2CTouran&cmsid=Volkswagen_Touran_Text',
    },
    {
        f: '/nl-be/auto-merk/volkswagen/transporter',
        t: '/nl-be/findcar?makesModels=Volkswagen%2CTransporter&cmsid=Volkswagen_Transporter_Text',
    },
    {
        f: '/nl-be/auto-merk/volkswagen',
        t: '/nl-be/findcar?makesModels=Volkswagen&cmsid=SEO_Volkswagen',
    },
    {
        f: '/nl-be/auto-merk/volvo/s61',
        t: '/nl-be/findcar?makesModels=Volvo%2CS60&cmsid=Volvo_S60_Text',
    },
    {
        f: '/nl-be/auto-merk/volvo/s80',
        t: '/nl-be/findcar?makesModels=Volvo%2CS80&cmsid=Volvo_S80_Text',
    },
    {
        f: '/nl-be/auto-merk/volvo/s90',
        t: '/nl-be/findcar?makesModels=Volvo%2CS90&cmsid=Volvo_S90_Text',
    },
    {
        f: '/nl-be/auto-merk/volvo/v41',
        t: '/nl-be/findcar?makesModels=Volvo%2CV40&cmsid=Volvo_V40_Text',
    },
    {
        f: '/nl-be/auto-merk/volvo/v60',
        t: '/nl-be/findcar?makesModels=Volvo%2CV60&cmsid=Volvo_V60_Text',
    },
    {
        f: '/nl-be/auto-merk/volvo/v90',
        t: '/nl-be/findcar?makesModels=Volvo%2CV90&cmsid=Volvo_V90_Text',
    },
    {
        f: '/nl-be/auto-merk/volvo/xc60',
        t: '/nl-be/findcar?makesModels=Volvo%2CXC60&cmsid=Volvo_XC60_Text',
    },
    {
        f: '/nl-be/auto-merk/volvo/xc90',
        t: '/nl-be/findcar?makesModels=Volvo%2CXC90&cmsid=Volvo_XC90_Text',
    },
    {
        f: '/nl-be/auto-merk/volvo',
        t: '/nl-be/findcar?makesModels=Volvo&cmsid=SEO_Volvo',
    },
    {
        f: '/nl-be/occasions-belgie',
        t: '/nl-be/findcar?originCountries=be&cmsid=used-cars-belgium',
    },
    {
        f: '/nl-be/occasions-duitsland',
        t: '/nl-be/findcar?originCountries=de&cmsid=used-cars-germany',
    },
    {
        f: '/nl-be/occasions-spanje',
        t: '/nl-be/findcar?originCountries=es&cmsid=used-cars-spain',
    },
    {
        f: '/nl-be/occasions-frankrijk',
        t: '/nl-be/findcar?originCountries=fr&cmsid=used-cars-france',
    },
    {
        f: '/nl-be/occasions-italie',
        t: '/nl-be/findcar?originCountries=it&cmsid=used-cars-italy',
    },
    {
        f: '/nl-be/occasions-luxemburg',
        t: '/nl-be/findcar?originCountries=lu&cmsid=used-cars-luxembourg',
    },
    {
        f: '/nl-be/occasions-nederland',
        t: '/nl-be/findcar?originCountries=nl&cmsid=used-cars-holland',
    },
    {
        f: '/nl-be/occasions-polen',
        t: '/nl-be/findcar?originCountries=pl&cmsid=used-cars-poland',
    },
    {
        f: '/nl-be/occasions-zweden',
        t: '/nl-be/findcar?originCountries=se&cmsid=used-cars-sweden',
    },
    {
        f: '/nl-be/prijzen-tweedehands-autos/goedkope-tweedehands-autos',
        t: '/nl-be/findcar?prices=0%2C3000&cmsid=cheap_used_cars',
    },
    {
        f: '/nl-be/prijzen-tweedehands-autos/middle-class',
        t: '/nl-be/findcar?prices=10000%2C20000&cmsid=Middle_class',
    },
    {
        f: '/nl-be/prijzen-tweedehands-autos/luxury-cars',
        t: '/nl-be/findcar?prices=20000%2C0&cmsid=luxury_cars',
    },
    {
        f: '/nl-be/prijzen-tweedehands-autos/budget-autos',
        t: '/nl-be/findcar?prices=3000%2C6000&cmsid=economy_cars',
    },
    {
        f: '/nl-be/prijzen-tweedehands-autos/value-for-money',
        t: '/nl-be/findcar?prices=6000%2C10000&cmsid=Value_for_money',
    },
    {
        f: '/nl-nl/body-type/vrachtwagen',
        t: '/nl-nl/findcar?bodyTypes=Truck&cmsid=Truck',
    },
    {
        f: '/nl-nl/autoveilingen/100-toewijzing',
        t: '/nl-nl/findcar?assignment100pc=true&cmsid=100assignment',
    },
    {
        f: '/nl-nl/autoveilingen/dynamisch',
        t: '/nl-nl/findcar?auctionTypes=1&cmsid=dynamic',
    },
    {
        f: '/nl-nl/autoveilingen/koop-nu',
        t: '/nl-nl/findcar?auctionTypes=2&cmsid=buy-now',
    },
    {
        f: '/nl-nl/autoveilingen/blind',
        t: '/nl-nl/findcar?auctionTypes=4&cmsid=blind',
    },
    {
        f: '/nl-nl/autoveilingen/target',
        t: '/nl-nl/findcar?auctionTypes=5&cmsid=target',
    },
    {
        f: '/nl-nl/body-type/sedan',
        t: '/nl-nl/findcar?bodyTypes=Berline&cmsid=SEO_Berline',
    },
    {
        f: '/nl-nl/body-type/station',
        t: '/nl-nl/findcar?bodyTypes=Break&cmsid=SEO_Break',
    },
    {
        f: '/nl-nl/body-type/cabrio',
        t: '/nl-nl/findcar?bodyTypes=Cabriolet&cmsid=SEO_Cabrio',
    },
    {
        f: '/nl-nl/body-type/compact',
        t: '/nl-nl/findcar?bodyTypes=Compact&cmsid=SEO_Compact',
    },
    {
        f: '/nl-nl/body-type/coupe',
        t: '/nl-nl/findcar?bodyTypes=Coup%C3%A9&cmsid=SEO_Coup%C3%A9',
    },
    {
        f: '/nl-nl/body-type/hatchback',
        t: '/nl-nl/findcar?bodyTypes=Hatchback&cmsid=SEO_Hatchback',
    },
    {
        f: '/nl-nl/body-type/lichte-bedrijfswagen',
        t: '/nl-nl/findcar?bodyTypes=Lighttruck&cmsid=SEO_Light-truck',
    },
    {
        f: '/nl-nl/body-type/minibus',
        t: '/nl-nl/findcar?bodyTypes=Minibus&cmsid=Minibus',
    },
    {
        f: '/nl-nl/body-type/motorbike',
        t: '/nl-nl/findcar?bodyTypes=Motorbike&cmsid=Motorbike',
    },
    {
        f: '/nl-nl/body-type/mpv',
        t: '/nl-nl/findcar?bodyTypes=MPV&cmsid=SEO_MPV',
    },
    {
        f: '/nl-nl/body-type/suv',
        t: '/nl-nl/findcar?bodyTypes=SUV&cmsid=SEO_SUV',
    },
    {
        f: '/nl-nl/autoveilingen/zonder-schade',
        t: '/nl-nl/findcar?damageTypes=1&cmsid=no_specific-Damages',
    },
    {
        f: '/nl-nl/autoveilingen/ongevalwagens',
        t: '/nl-nl/findcar?damageTypes=2&cmsid=Body-damages',
    },
    {
        f: '/nl-nl/autoveilingen/autos-met-technische-schade',
        t: '/nl-nl/findcar?damageTypes=3&cmsid=technical-damages',
    },
    {
        f: '/nl-nl/autoveilingen/technische-en-carrosserieschade',
        t: '/nl-nl/findcar?damageTypes=4&cmsid=Technical-and-body-damages',
    },
    {
        f: '/nl-nl/auto-merk/abarth',
        t: '/nl-nl/findcar?makesModels=Abarth&cmsid=SEO_Abarth',
    },
    {
        f: '/nl-nl/auto-merk/alfa-romeo',
        t: '/nl-nl/findcar?makesModels=Alfa%20Romeo&cmsid=SEO_alfa-romeo',
    },
    {
        f: '/nl-nl/auto-merk/audi/a1',
        t: '/nl-nl/findcar?makesModels=Audi%2CA1&cmsid=Audi_A1_Text',
    },
    {
        f: '/nl-nl/auto-merk/audi/a2',
        t: '/nl-nl/findcar?makesModels=Audi%2CA2&cmsid=Audi_A2_Text',
    },
    {
        f: '/nl-nl/auto-merk/audi/a3',
        t: '/nl-nl/findcar?makesModels=Audi%2CA3&cmsid=Audi_A3_Text',
    },
    {
        f: '/nl-nl/auto-merk/audi/a4',
        t: '/nl-nl/findcar?makesModels=Audi%2CA4&cmsid=Audi_A4_Text',
    },
    {
        f: '/nl-nl/auto-merk/audi/a5',
        t: '/nl-nl/findcar?makesModels=Audi%2CA5&cmsid=Audi_A5_Text',
    },
    {
        f: '/nl-nl/auto-merk/audi/a6',
        t: '/nl-nl/findcar?makesModels=Audi%2CA6&cmsid=Audi_A6_Text',
    },
    {
        f: '/nl-nl/auto-merk/audi/q3',
        t: '/nl-nl/findcar?makesModels=Audi%2CQ3&cmsid=Audi_Q3_Text',
    },
    {
        f: '/nl-nl/auto-merk/audi/q5',
        t: '/nl-nl/findcar?makesModels=Audi%2CQ5&cmsid=Audi_Q5_Text',
    },
    {
        f: '/nl-nl/auto-merk/audi/q7',
        t: '/nl-nl/findcar?makesModels=Audi%2CQ7&cmsid=Audi_Q7_Text',
    },
    {
        f: '/nl-nl/auto-merk/audi',
        t: '/nl-nl/findcar?makesModels=Audi&cmsid=SEO_Audi',
    },
    {
        f: '/nl-nl/auto-merk/bmw/1-reeks',
        t: '/nl-nl/findcar?makesModels=BMW%2C1%20Series&cmsid=BMW_1series_Text',
    },
    {
        f: '/nl-nl/auto-merk/bmw/2-reeks',
        t: '/nl-nl/findcar?makesModels=BMW%2C2%20Series&cmsid=BMW_2series_Text',
    },
    {
        f: '/nl-nl/auto-merk/bmw/3-reeks',
        t: '/nl-nl/findcar?makesModels=BMW%2C3%20Series&cmsid=BMW_3series_Text',
    },
    {
        f: '/nl-nl/auto-merk/bmw/4-reeks',
        t: '/nl-nl/findcar?makesModels=BMW%2C4%20Series&cmsid=BMW_4series_Text',
    },
    {
        f: '/nl-nl/auto-merk/bmw/5-reeks',
        t: '/nl-nl/findcar?makesModels=BMW%2C5%20Series&cmsid=BMW_5series_Text',
    },
    {
        f: '/nl-nl/auto-merk/bmw/x1',
        t: '/nl-nl/findcar?makesModels=BMW%2CX1&cmsid=BMW_X1_Text',
    },
    {
        f: '/nl-nl/auto-merk/bmw/x3',
        t: '/nl-nl/findcar?makesModels=BMW%2CX3&cmsid=BMW_X3_Text',
    },
    {
        f: '/nl-nl/auto-merk/bmw/x5',
        t: '/nl-nl/findcar?makesModels=BMW%2CX5&cmsid=BMW_X5_Text',
    },
    {
        f: '/nl-nl/auto-merk/bmw/x6',
        t: '/nl-nl/findcar?makesModels=BMW%2CX6&cmsid=BMW_X6_Text',
    },
    {
        f: '/nl-nl/auto-merk/bmw',
        t: '/nl-nl/findcar?makesModels=BMW&cmsid=SEO_BMW',
    },
    {
        f: '/nl-nl/auto-merk/citroen/berlingo',
        t: '/nl-nl/findcar?makesModels=Citro%C3%ABn%2CBerlingo&cmsid=Citro%C3%ABn_Berlingo_text',
    },
    {
        f: '/nl-nl/auto-merk/citroen/c3',
        t: '/nl-nl/findcar?makesModels=Citro%C3%ABn%2CC3&cmsid=Citro%C3%ABn_C3_text',
    },
    {
        f: '/nl-nl/auto-merk/citroen/c4',
        t: '/nl-nl/findcar?makesModels=Citro%C3%ABn%2CC4&cmsid=Citro%C3%ABn_C4_text',
    },
    {
        f: '/nl-nl/auto-merk/citroen/c5',
        t: '/nl-nl/findcar?makesModels=Citro%C3%ABn%2CC5&cmsid=Citro%C3%ABn_C5_text',
    },
    {
        f: '/nl-nl/auto-merk/citroen/ds3',
        t: '/nl-nl/findcar?makesModels=Citro%C3%ABn%2CDS3&cmsid=Citro%C3%ABn_DS3_text',
    },
    {
        f: '/nl-nl/auto-merk/citroen/ds4',
        t: '/nl-nl/findcar?makesModels=Citro%C3%ABn%2CDS4&cmsid=Citro%C3%ABn_DS4_text',
    },
    {
        f: '/nl-nl/auto-merk/citroen/dispatch',
        t: '/nl-nl/findcar?makesModels=Citro%C3%ABn%2CJumpy&cmsid=Citro%C3%ABn_Dispatch_text',
    },
    {
        f: '/nl-nl/auto-merk/citroen',
        t: '/nl-nl/findcar?makesModels=Citro%C3%ABn&cmsid=SEO_Citro%C3%ABn',
    },
    {
        f: '/nl-nl/auto-merk/fiat/500',
        t: '/nl-nl/findcar?makesModels=Fiat%2C500&cmsid=Fiat_500_Text',
    },
    {
        f: '/nl-nl/auto-merk/fiat/bravo',
        t: '/nl-nl/findcar?makesModels=Fiat%2CBravo&cmsid=Fiat_bravo_Text',
    },
    {
        f: '/nl-nl/auto-merk/fiat/doblo',
        t: '/nl-nl/findcar?makesModels=Fiat%2CDoblo&cmsid=Fiat_doblo_Text',
    },
    {
        f: '/nl-nl/auto-merk/fiat/ducato',
        t: '/nl-nl/findcar?makesModels=Fiat%2CDucato&cmsid=Fiat_ducato_Text',
    },
    {
        f: '/nl-nl/auto-merk/fiat/freemont',
        t: '/nl-nl/findcar?makesModels=Fiat%2CFreemont&cmsid=Fiat_freemont_Text',
    },
    {
        f: '/nl-nl/auto-merk/fiat/panda',
        t: '/nl-nl/findcar?makesModels=Fiat%2CPanda&cmsid=Fiat_panda_Text',
    },
    {
        f: '/nl-nl/auto-merk/fiat/punto',
        t: '/nl-nl/findcar?makesModels=Fiat%2CPunto&cmsid=Fiat_punto_Text',
    },
    {
        f: '/nl-nl/auto-merk/fiat',
        t: '/nl-nl/findcar?makesModels=Fiat&cmsid=SEO_Fiat',
    },
    {
        f: '/nl-nl/auto-merk/ford/fiesta',
        t: '/nl-nl/findcar?makesModels=Ford%2CFiesta&cmsid=Ford_Fiesta_Text',
    },
    {
        f: '/nl-nl/auto-merk/ford/focus',
        t: '/nl-nl/findcar?makesModels=Ford%2CFocus&cmsid=Ford_Focus_Text',
    },
    {
        f: '/nl-nl/auto-merk/ford/mondeo',
        t: '/nl-nl/findcar?makesModels=Ford%2CMondeo&cmsid=Ford_Mondeo_Text',
    },
    {
        f: '/nl-nl/auto-merk/ford/transit',
        t: '/nl-nl/findcar?makesModels=Ford%2CTransit&cmsid=Ford_Transit_Text',
    },
    {
        f: '/nl-nl/auto-merk/ford',
        t: '/nl-nl/findcar?makesModels=Ford&cmsid=SEO_Ford',
    },
    {
        f: '/nl-nl/auto-merk/lancia',
        t: '/nl-nl/findcar?makesModels=Lancia&cmsid=SEO_Lancia',
    },
    {
        f: '/nl-nl/auto-merk/mercedes/a-klasse',
        t: '/nl-nl/findcar?makesModels=Mercedes-Benz%2CA&cmsid=Mercedes_A-class_Text',
    },
    {
        f: '/nl-nl/auto-merk/mercedes/b-klasse',
        t: '/nl-nl/findcar?makesModels=Mercedes-Benz%2CB&cmsid=Mercedes_B-class_Text',
    },
    {
        f: '/nl-nl/auto-merk/mercedes/c-klasse',
        t: '/nl-nl/findcar?makesModels=Mercedes-Benz%2CC&cmsid=Mercedes_C-class_Text',
    },
    {
        f: '/nl-nl/auto-merk/mercedes/e-klasse',
        t: '/nl-nl/findcar?makesModels=Mercedes-Benz%2CE&cmsid=Mercedes_E-class_Text',
    },
    {
        f: '/nl-nl/auto-merk/mercedes/sprinter',
        t: '/nl-nl/findcar?makesModels=Mercedes-Benz%2CSprinter&cmsid=Mercedes_Sprinter_Text',
    },
    {
        f: '/nl-nl/auto-merk/mercedes/vito',
        t: '/nl-nl/findcar?makesModels=Mercedes-Benz%2CVito&cmsid=Mercedes_Vito_Text',
    },
    {
        f: '/nl-nl/auto-merk/mercedes',
        t: '/nl-nl/findcar?makesModels=Mercedes-Benz&cmsid=SEO_Mercedes',
    },
    {
        f: '/nl-nl/auto-merk/nissan/juke',
        t: '/nl-nl/findcar?makesModels=Nissan%2CJuke&cmsid=Nissan_Juke_Text',
    },
    {
        f: '/nl-nl/auto-merk/nissan/micra',
        t: '/nl-nl/findcar?makesModels=Nissan%2CMicra&cmsid=Nissan_micra_Text',
    },
    {
        f: '/nl-nl/auto-merk/nissan/pathfinder',
        t: '/nl-nl/findcar?makesModels=Nissan%2CPathfinder&cmsid=Nissan_Pathfinder_Text',
    },
    {
        f: '/nl-nl/auto-merk/nissan/qashqai',
        t: '/nl-nl/findcar?makesModels=Nissan%2CQashqai&cmsid=Nissan_Qashqai_Text',
    },
    {
        f: '/nl-nl/auto-merk/nissan/x-trail',
        t: '/nl-nl/findcar?makesModels=Nissan%2CX-Trail&cmsid=Nissan_x-trail_Text',
    },
    {
        f: '/nl-nl/auto-merk/nissan',
        t: '/nl-nl/findcar?makesModels=Nissan&cmsid=SEO_Nissan',
    },
    {
        f: '/nl-nl/auto-merk/opel/astra',
        t: '/nl-nl/findcar?makesModels=Opel%2CAstra&cmsid=Opel_Astra_Text',
    },
    {
        f: '/nl-nl/auto-merk/opel/combo',
        t: '/nl-nl/findcar?makesModels=Opel%2CCombo&cmsid=Opel_Combo_Text',
    },
    {
        f: '/nl-nl/auto-merk/opel/corsa',
        t: '/nl-nl/findcar?makesModels=Opel%2CCorsa&cmsid=Opel_Corsa_Text',
    },
    {
        f: '/nl-nl/auto-merk/opel/insignia',
        t: '/nl-nl/findcar?makesModels=Opel%2CInsignia&cmsid=Opel_Insignia_Text',
    },
    {
        f: '/nl-nl/auto-merk/opel/meriva',
        t: '/nl-nl/findcar?makesModels=Opel%2CMeriva&cmsid=Opel_Meriva_Text',
    },
    {
        f: '/nl-nl/auto-merk/opel/movano',
        t: '/nl-nl/findcar?makesModels=Opel%2CMovano&cmsid=Opel_Movano_Text',
    },
    {
        f: '/nl-nl/auto-merk/opel/vivaro',
        t: '/nl-nl/findcar?makesModels=Opel%2CVivaro&cmsid=Opel_Vivaro_Text',
    },
    {
        f: '/nl-nl/auto-merk/opel/zafira',
        t: '/nl-nl/findcar?makesModels=Opel%2CZafira&cmsid=Opel_Zafira_Text',
    },
    {
        f: '/nl-nl/auto-merk/opel',
        t: '/nl-nl/findcar?makesModels=Opel&cmsid=Seo_Opel',
    },
    {
        f: '/nl-nl/auto-merk/peugeot/407sw',
        t: '/nl-nl/findcar?makesModels=Peugeot%2C40x&cmsid=Peugeot_407sw_Text',
    },
    {
        f: '/nl-nl/auto-merk/peugeot/5008',
        t: '/nl-nl/findcar?makesModels=Peugeot%2C5008&cmsid=Peugeot_5008_Text',
    },
    {
        f: '/nl-nl/auto-merk/peugeot/expert',
        t: '/nl-nl/findcar?makesModels=Peugeot%2CExpert&cmsid=Peugeot_Expert_Text',
    },
    {
        f: '/nl-nl/auto-merk/peugeot/partner',
        t: '/nl-nl/findcar?makesModels=Peugeot%2CPartner&cmsid=Peugeot_Partner_Text',
    },
    {
        f: '/nl-nl/auto-merk/peugeot',
        t: '/nl-nl/findcar?makesModels=Peugeot&cmsid=Seo_Peugeot',
    },
    {
        f: '/nl-nl/auto-merk/piaggio',
        t: '/nl-nl/findcar?makesModels=Piaggio&cmsid=SEO_Piaggio',
    },
    {
        f: '/nl-nl/auto-merk/renault/clio',
        t: '/nl-nl/findcar?makesModels=Renault%2CClio&cmsid=Renault_Clio_Text',
    },
    {
        f: '/nl-nl/auto-merk/renault/espace',
        t: '/nl-nl/findcar?makesModels=Renault%2CEspace&cmsid=Renault_Espace_Text',
    },
    {
        f: '/nl-nl/auto-merk/renault/kangoo',
        t: '/nl-nl/findcar?makesModels=Renault%2CKangoo&cmsid=Renault_Kangoo_Text',
    },
    {
        f: '/nl-nl/auto-merk/renault/megane',
        t: '/nl-nl/findcar?makesModels=Renault%2CMegane&cmsid=Renault_Megane_Text',
    },
    {
        f: '/nl-nl/auto-merk/renault/scenic',
        t: '/nl-nl/findcar?makesModels=Renault%2CScenic&cmsid=Renault_Scenic_Text',
    },
    {
        f: '/nl-nl/auto-merk/renault',
        t: '/nl-nl/findcar?makesModels=Renault&cmsid=SEO_Renault',
    },
    {
        f: '/nl-nl/auto-merk/skoda/fabia',
        t: '/nl-nl/findcar?makesModels=Skoda%2CFabia&cmsid=Skoda_Fabia_Text',
    },
    {
        f: '/nl-nl/auto-merk/skoda/octavia',
        t: '/nl-nl/findcar?makesModels=Skoda%2COctavia&cmsid=Skoda_Octavia_Text',
    },
    {
        f: '/nl-nl/auto-merk/skoda/rapid',
        t: '/nl-nl/findcar?makesModels=Skoda%2CRapid&cmsid=Skoda_Rapid_Text',
    },
    {
        f: '/nl-nl/auto-merk/skoda/superb',
        t: '/nl-nl/findcar?makesModels=Skoda%2CSuperb&cmsid=Skoda_Superb_Text',
    },
    {
        f: '/nl-nl/auto-merk/skoda/yeti',
        t: '/nl-nl/findcar?makesModels=Skoda%2CYeti&cmsid=Skoda_Yeti_Text',
    },
    {
        f: '/nl-nl/auto-merk/skoda',
        t: '/nl-nl/findcar?makesModels=Skoda&cmsid=SEO_Skoda',
    },
    {
        f: '/nl-nl/auto-merk/toyota/auris',
        t: '/nl-nl/findcar?makesModels=Toyota%2CAuris&cmsid=Toyota_Auris_Text',
    },
    {
        f: '/nl-nl/auto-merk/toyota/avensis',
        t: '/nl-nl/findcar?makesModels=Toyota%2CAvensis&cmsid=Toyota_Avensis_Text',
    },
    {
        f: '/nl-nl/auto-merk/toyota/aygo',
        t: '/nl-nl/findcar?makesModels=Toyota%2CAygo&cmsid=Toyota_Aygo_Text',
    },
    {
        f: '/nl-nl/auto-merk/toyota/prius',
        t: '/nl-nl/findcar?makesModels=Toyota%2CPrius&cmsid=Toyota_Prius_Text',
    },
    {
        f: '/nl-nl/auto-merk/toyota/rav4',
        t: '/nl-nl/findcar?makesModels=Toyota%2CRav4&cmsid=Toyota_Rav4_Text',
    },
    {
        f: '/nl-nl/auto-merk/toyota/yaris',
        t: '/nl-nl/findcar?makesModels=Toyota%2CYaris&cmsid=Toyota_Yaris_Text',
    },
    {
        f: '/nl-nl/auto-merk/toyota',
        t: '/nl-nl/findcar?makesModels=Toyota&cmsid=SEO_Toyota',
    },
    {
        f: '/nl-nl/auto-merk/volkswagen/caddy',
        t: '/nl-nl/findcar?makesModels=Volkswagen%2CCaddy&cmsid=Volkswagen_Caddy_Text',
    },
    {
        f: '/nl-nl/auto-merk/volkswagen/golf',
        t: '/nl-nl/findcar?makesModels=Volkswagen%2CGolf&cmsid=Volkswagen_Golf_Text',
    },
    {
        f: '/nl-nl/auto-merk/volkswagen/passat',
        t: '/nl-nl/findcar?makesModels=Volkswagen%2CPassat&cmsid=Volkswagen_Passat_Text',
    },
    {
        f: '/nl-nl/auto-merk/volkswagen/polo',
        t: '/nl-nl/findcar?makesModels=Volkswagen%2CPolo&cmsid=Volkswagen_Polo_Text',
    },
    {
        f: '/nl-nl/auto-merk/volkswagen/tiguan',
        t: '/nl-nl/findcar?makesModels=Volkswagen%2CTiguan&cmsid=Volkswagen_Tiguan_Text',
    },
    {
        f: '/nl-nl/auto-merk/volkswagen/touareg',
        t: '/nl-nl/findcar?makesModels=Volkswagen%2CTouareg&cmsid=Volkswagen_Touareg_Text',
    },
    {
        f: '/nl-nl/auto-merk/volkswagen/touran',
        t: '/nl-nl/findcar?makesModels=Volkswagen%2CTouran&cmsid=Volkswagen_Touran_Text',
    },
    {
        f: '/nl-nl/auto-merk/volkswagen/transporter',
        t: '/nl-nl/findcar?makesModels=Volkswagen%2CTransporter&cmsid=Volkswagen_Transporter_Text',
    },
    {
        f: '/nl-nl/auto-merk/volkswagen',
        t: '/nl-nl/findcar?makesModels=Volkswagen&cmsid=SEO_Volkswagen',
    },
    {
        f: '/nl-nl/auto-merk/volvo/s60',
        t: '/nl-nl/findcar?makesModels=Volvo%2CS60&cmsid=Volvo_S60_Text',
    },
    {
        f: '/nl-nl/auto-merk/volvo/s80',
        t: '/nl-nl/findcar?makesModels=Volvo%2CS80&cmsid=Volvo_S80_Text',
    },
    {
        f: '/nl-nl/auto-merk/volvo/s90',
        t: '/nl-nl/findcar?makesModels=Volvo%2CS90&cmsid=Volvo_S90_Text',
    },
    {
        f: '/nl-nl/auto-merk/volvo/v40',
        t: '/nl-nl/findcar?makesModels=Volvo%2CV40&cmsid=Volvo_V40_Text',
    },
    {
        f: '/nl-nl/auto-merk/volvo/v60',
        t: '/nl-nl/findcar?makesModels=Volvo%2CV60&cmsid=Volvo_V60_Text',
    },
    {
        f: '/nl-nl/auto-merk/volvo/v90',
        t: '/nl-nl/findcar?makesModels=Volvo%2CV90&cmsid=Volvo_V90_Text',
    },
    {
        f: '/nl-nl/auto-merk/volvo/xc60',
        t: '/nl-nl/findcar?makesModels=Volvo%2CXC60&cmsid=Volvo_XC60_Text',
    },
    {
        f: '/nl-nl/auto-merk/volvo/xc90',
        t: '/nl-nl/findcar?makesModels=Volvo%2CXC90&cmsid=Volvo_XC90_Text',
    },
    {
        f: '/nl-nl/auto-merk/volvo',
        t: '/nl-nl/findcar?makesModels=Volvo&cmsid=SEO_Volvo',
    },
    {
        f: '/nl-nl/occasions-belgie',
        t: '/nl-nl/findcar?originCountries=be&cmsid=used-cars-belgium',
    },
    {
        f: '/nl-nl/occasions-duitsland',
        t: '/nl-nl/findcar?originCountries=de&cmsid=used-cars-germany',
    },
    {
        f: '/nl-nl/occasions-spanje',
        t: '/nl-nl/findcar?originCountries=es&cmsid=used-cars-spain',
    },
    {
        f: '/nl-nl/occasions-frankrijk',
        t: '/nl-nl/findcar?originCountries=fr&cmsid=used-cars-france',
    },
    {
        f: '/nl-nl/occasions-italie',
        t: '/nl-nl/findcar?originCountries=it&cmsid=used-cars-italy',
    },
    {
        f: '/nl-nl/occasions-luxemburg',
        t: '/nl-nl/findcar?originCountries=lu&cmsid=used-cars-luxembourg',
    },
    {
        f: '/nl-nl/occasions-nederland',
        t: '/nl-nl/findcar?originCountries=nl&cmsid=used-cars-holland',
    },
    {
        f: '/nl-nl/occasions-polen',
        t: '/nl-nl/findcar?originCountries=pl&cmsid=used-cars-poland',
    },
    {
        f: '/nl-nl/occasions-zweden',
        t: '/nl-nl/findcar?originCountries=se&cmsid=used-cars-sweden',
    },
    {
        f: '/nl-nl/prijzen-tweedehands-autos/goedkope-tweedehands-autos',
        t: '/nl-nl/findcar?prices=0%2C3000&cmsid=cheap_used_cars',
    },
    {
        f: '/nl-nl/prijzen-tweedehands-autos/middle-class',
        t: '/nl-nl/findcar?prices=10000%2C20000&cmsid=Middle_class',
    },
    {
        f: '/nl-nl/prijzen-tweedehands-autos/luxury-cars',
        t: '/nl-nl/findcar?prices=20000%2C0&cmsid=luxury_cars',
    },
    {
        f: '/nl-nl/prijzen-tweedehands-autos/budget-autos',
        t: '/nl-nl/findcar?prices=3000%2C6000&cmsid=economy_cars',
    },
    {
        f: '/nl-nl/prijzen-tweedehands-autos/value-for-money',
        t: '/nl-nl/findcar?prices=6000%2C10000&cmsid=Value_for_money',
    },
    {
        f: '/pl/body-type/ciezarowy',
        t: '/pl/findcar?bodyTypes=Truck&cmsid=Truck',
    },
    {
        f: '/pl/aukcje-samochodow/rezerwacja-gwarantowana',
        t: '/pl/findcar?assignment100pc=true&cmsid=100assignment',
    },
    {
        f: '/pl/aukcje-samochodow/dynamiczna',
        t: '/pl/findcar?auctionTypes=1&cmsid=dynamic',
    },
    {
        f: '/pl/aukcje-samochodow/kup-teraz',
        t: '/pl/findcar?auctionTypes=2&cmsid=buy-now',
    },
    {
        f: '/pl/aukcje-samochodow/slepa',
        t: '/pl/findcar?auctionTypes=4&cmsid=blind',
    },
    {
        f: '/pl/aukcje-samochodow/cel',
        t: '/pl/findcar?auctionTypes=5&cmsid=target',
    },
    {
        f: '/pl/body-type/klasa-sedan',
        t: '/pl/findcar?bodyTypes=Berline&cmsid=SEO_Berline',
    },
    {
        f: '/pl/body-type/klasa-break',
        t: '/pl/findcar?bodyTypes=Break&cmsid=SEO_Break',
    },
    {
        f: '/pl/body-type/kabriolet',
        t: '/pl/findcar?bodyTypes=Cabriolet&cmsid=SEO_Cabrio',
    },
    {
        f: '/pl/body-type/klasa-compact',
        t: '/pl/findcar?bodyTypes=Compact&cmsid=SEO_Compact',
    },
    {
        f: '/pl/body-type/dwuosobowy-coupe',
        t: '/pl/findcar?bodyTypes=Coup%C3%A9&cmsid=SEO_Coup%C3%A9',
    },
    {
        f: '/pl/body-type/hatchback',
        t: '/pl/findcar?bodyTypes=Hatchback&cmsid=SEO_Hatchback',
    },
    {
        f: '/pl/body-type/furgonetka',
        t: '/pl/findcar?bodyTypes=Lighttruck&cmsid=SEO_Light-truck',
    },
    {
        f: '/pl/body-type/minibus',
        t: '/pl/findcar?bodyTypes=Minibus&cmsid=Minibus',
    },
    {
        f: '/pl/body-type/motocykl',
        t: '/pl/findcar?bodyTypes=Motorbike&cmsid=Motorbike',
    },
    { f: '/pl/body-type/mpv', t: '/pl/findcar?bodyTypes=MPV&cmsid=SEO_MPV' },
    { f: '/pl/body-type/suv', t: '/pl/findcar?bodyTypes=SUV&cmsid=SEO_SUV' },
    {
        f: '/pl/aukcje-samochodow/auta-bezwypadkowe',
        t: '/pl/findcar?damageTypes=1&cmsid=no_specific-Damages',
    },
    {
        f: '/pl/aukcje-samochodow/samochody-powypadkowe',
        t: '/pl/findcar?damageTypes=2&cmsid=Body-damages',
    },
    {
        f: '/pl/aukcje-samochodow/samochody-uszkodzone',
        t: '/pl/findcar?damageTypes=3&cmsid=technical-damages',
    },
    {
        f: '/pl/aukcje-samochodow/uszkodzenia',
        t: '/pl/findcar?damageTypes=4&cmsid=Technical-and-body-damages',
    },
    {
        f: '/pl/marka-samochodu/abarth',
        t: '/pl/findcar?makesModels=Abarth&cmsid=SEO_Abarth',
    },
    {
        f: '/pl/marka-samochodu/alfa-romeo',
        t: '/pl/findcar?makesModels=Alfa%20Romeo&cmsid=SEO_alfa-romeo',
    },
    {
        f: '/pl/marka-samochodu/audi/a1',
        t: '/pl/findcar?makesModels=Audi%2CA1&cmsid=Audi_A1_Text',
    },
    {
        f: '/pl/marka-samochodu/audi/a2',
        t: '/pl/findcar?makesModels=Audi%2CA2&cmsid=Audi_A2_Text',
    },
    {
        f: '/pl/marka-samochodu/audi/a3',
        t: '/pl/findcar?makesModels=Audi%2CA3&cmsid=Audi_A3_Text',
    },
    {
        f: '/pl/marka-samochodu/audi/a4',
        t: '/pl/findcar?makesModels=Audi%2CA4&cmsid=Audi_A4_Text',
    },
    {
        f: '/pl/marka-samochodu/audi/a5',
        t: '/pl/findcar?makesModels=Audi%2CA5&cmsid=Audi_A5_Text',
    },
    {
        f: '/pl/marka-samochodu/audi/a6',
        t: '/pl/findcar?makesModels=Audi%2CA6&cmsid=Audi_A6_Text',
    },
    {
        f: '/pl/marka-samochodu/audi/q3',
        t: '/pl/findcar?makesModels=Audi%2CQ3&cmsid=Audi_Q3_Text',
    },
    {
        f: '/pl/marka-samochodu/audi/q5',
        t: '/pl/findcar?makesModels=Audi%2CQ5&cmsid=Audi_Q5_Text',
    },
    {
        f: '/pl/marka-samochodu/audi/q7',
        t: '/pl/findcar?makesModels=Audi%2CQ7&cmsid=Audi_Q7_Text',
    },
    {
        f: '/pl/marka-samochodu/audi',
        t: '/pl/findcar?makesModels=Audi&cmsid=SEO_Audi',
    },
    {
        f: '/pl/marka-samochodu/bmw/1',
        t: '/pl/findcar?makesModels=BMW%2C1%20Series&cmsid=BMW_1series_Text',
    },
    {
        f: '/pl/marka-samochodu/bmw/2',
        t: '/pl/findcar?makesModels=BMW%2C2%20Series&cmsid=BMW_2series_Text',
    },
    {
        f: '/pl/marka-samochodu/bmw/3',
        t: '/pl/findcar?makesModels=BMW%2C3%20Series&cmsid=BMW_3series_Text',
    },
    {
        f: '/pl/marka-samochodu/bmw/4',
        t: '/pl/findcar?makesModels=BMW%2C4%20Series&cmsid=BMW_4series_Text',
    },
    {
        f: '/pl/marka-samochodu/bmw/5',
        t: '/pl/findcar?makesModels=BMW%2C5%20Series&cmsid=BMW_5series_Text',
    },
    {
        f: '/pl/marka-samochodu/bmw/x1',
        t: '/pl/findcar?makesModels=BMW%2CX1&cmsid=BMW_X1_Text',
    },
    {
        f: '/pl/marka-samochodu/bmw/x3',
        t: '/pl/findcar?makesModels=BMW%2CX3&cmsid=BMW_X3_Text',
    },
    {
        f: '/pl/marka-samochodu/bmw/x5',
        t: '/pl/findcar?makesModels=BMW%2CX5&cmsid=BMW_X5_Text',
    },
    {
        f: '/pl/marka-samochodu/bmw/x6',
        t: '/pl/findcar?makesModels=BMW%2CX6&cmsid=BMW_X6_Text',
    },
    {
        f: '/pl/marka-samochodu/bmw',
        t: '/pl/findcar?makesModels=BMW&cmsid=SEO_BMW',
    },
    {
        f: '/pl/marka-samochodu/citroen/berlingo',
        t: '/pl/findcar?makesModels=Citro%C3%ABn%2CBerlingo&cmsid=Citro%C3%ABn_Berlingo_text',
    },
    {
        f: '/pl/marka-samochodu/citroen/c3',
        t: '/pl/findcar?makesModels=Citro%C3%ABn%2CC3&cmsid=Citro%C3%ABn_C3_text',
    },
    {
        f: '/pl/marka-samochodu/citroen/c4',
        t: '/pl/findcar?makesModels=Citro%C3%ABn%2CC4&cmsid=Citro%C3%ABn_C4_text',
    },
    {
        f: '/pl/marka-samochodu/citroen/c5',
        t: '/pl/findcar?makesModels=Citro%C3%ABn%2CC5&cmsid=Citro%C3%ABn_C5_text',
    },
    {
        f: '/pl/marka-samochodu/citroen/ds3',
        t: '/pl/findcar?makesModels=Citro%C3%ABn%2CDS3&cmsid=Citro%C3%ABn_DS3_text',
    },
    {
        f: '/pl/marka-samochodu/citroen/ds4',
        t: '/pl/findcar?makesModels=Citro%C3%ABn%2CDS4&cmsid=Citro%C3%ABn_DS4_text',
    },
    {
        f: '/pl/marka-samochodu/citroen/dispatch',
        t: '/pl/findcar?makesModels=Citro%C3%ABn%2CJumpy&cmsid=Citro%C3%ABn_Dispatch_text',
    },
    {
        f: '/pl/marka-samochodu/citroen',
        t: '/pl/findcar?makesModels=Citro%C3%ABn&cmsid=SEO_Citro%C3%ABn',
    },
    {
        f: '/pl/marka-samochodu/fiat/500',
        t: '/pl/findcar?makesModels=Fiat%2C500&cmsid=Fiat_500_Text',
    },
    {
        f: '/pl/marka-samochodu/fiat/bravo',
        t: '/pl/findcar?makesModels=Fiat%2CBravo&cmsid=Fiat_bravo_Text',
    },
    {
        f: '/pl/marka-samochodu/fiat/doblo',
        t: '/pl/findcar?makesModels=Fiat%2CDoblo&cmsid=Fiat_doblo_Text',
    },
    {
        f: '/pl/marka-samochodu/fiat/ducato',
        t: '/pl/findcar?makesModels=Fiat%2CDucato&cmsid=Fiat_ducato_Text',
    },
    {
        f: '/pl/marka-samochodu/fiat/freemont',
        t: '/pl/findcar?makesModels=Fiat%2CFreemont&cmsid=Fiat_freemont_Text',
    },
    {
        f: '/pl/marka-samochodu/fiat/panda',
        t: '/pl/findcar?makesModels=Fiat%2CPanda&cmsid=Fiat_panda_Text',
    },
    {
        f: '/pl/marka-samochodu/fiat/punto',
        t: '/pl/findcar?makesModels=Fiat%2CPunto&cmsid=Fiat_punto_Text',
    },
    {
        f: '/pl/marka-samochodu/fiat',
        t: '/pl/findcar?makesModels=Fiat&cmsid=SEO_Fiat',
    },
    {
        f: '/pl/marka-samochodu/ford/fiesta',
        t: '/pl/findcar?makesModels=Ford%2CFiesta&cmsid=Ford_Fiesta_Text',
    },
    {
        f: '/pl/marka-samochodu/ford/focus',
        t: '/pl/findcar?makesModels=Ford%2CFocus&cmsid=Ford_Focus_Text',
    },
    {
        f: '/pl/marka-samochodu/ford/mondeo',
        t: '/pl/findcar?makesModels=Ford%2CMondeo&cmsid=Ford_Mondeo_Text',
    },
    {
        f: '/pl/marka-samochodu/ford/transit',
        t: '/pl/findcar?makesModels=Ford%2CTransit&cmsid=Ford_Transit_Text',
    },
    {
        f: '/pl/marka-samochodu/ford',
        t: '/pl/findcar?makesModels=Ford&cmsid=SEO_Ford',
    },
    {
        f: '/pl/marka-samochodu/lancia',
        t: '/pl/findcar?makesModels=Lancia&cmsid=SEO_Lancia',
    },
    {
        f: '/pl/marka-samochodu/mercedes/klasa-a',
        t: '/pl/findcar?makesModels=Mercedes-Benz%2CA&cmsid=Mercedes_A-class_Text',
    },
    {
        f: '/pl/marka-samochodu/mercedes/klasa-b',
        t: '/pl/findcar?makesModels=Mercedes-Benz%2CB&cmsid=Mercedes_B-class_Text',
    },
    {
        f: '/pl/marka-samochodu/mercedes/klasa-c',
        t: '/pl/findcar?makesModels=Mercedes-Benz%2CC&cmsid=Mercedes_C-class_Text',
    },
    {
        f: '/pl/marka-samochodu/mercedes/klasa-e',
        t: '/pl/findcar?makesModels=Mercedes-Benz%2CE&cmsid=Mercedes_E-class_Text',
    },
    {
        f: '/pl/marka-samochodu/mercedes/sprinter',
        t: '/pl/findcar?makesModels=Mercedes-Benz%2CSprinter&cmsid=Mercedes_Sprinter_Text',
    },
    {
        f: '/pl/marka-samochodu/mercedes/vito',
        t: '/pl/findcar?makesModels=Mercedes-Benz%2CVito&cmsid=Mercedes_Vito_Text',
    },
    {
        f: '/pl/marka-samochodu/mercedes',
        t: '/pl/findcar?makesModels=Mercedes-Benz&cmsid=SEO_Mercedes',
    },
    {
        f: '/pl/marka-samochodu/nissan/juke',
        t: '/pl/findcar?makesModels=Nissan%2CJuke&cmsid=Nissan_Juke_Text',
    },
    {
        f: '/pl/marka-samochodu/nissan/micra',
        t: '/pl/findcar?makesModels=Nissan%2CMicra&cmsid=Nissan_micra_Text',
    },
    {
        f: '/pl/marka-samochodu/nissan/pathfinder',
        t: '/pl/findcar?makesModels=Nissan%2CPathfinder&cmsid=Nissan_Pathfinder_Text',
    },
    {
        f: '/pl/marka-samochodu/nissan/qashqai',
        t: '/pl/findcar?makesModels=Nissan%2CQashqai&cmsid=Nissan_Qashqai_Text',
    },
    {
        f: '/pl/marka-samochodu/nissan/x-trail',
        t: '/pl/findcar?makesModels=Nissan%2CX-Trail&cmsid=Nissan_x-trail_Text',
    },
    {
        f: '/pl/marka-samochodu/nissan',
        t: '/pl/findcar?makesModels=Nissan&cmsid=SEO_Nissan',
    },
    {
        f: '/pl/marka-samochodu/opel/astra',
        t: '/pl/findcar?makesModels=Opel%2CAstra&cmsid=Opel_Astra_Text',
    },
    {
        f: '/pl/marka-samochodu/opel/combo',
        t: '/pl/findcar?makesModels=Opel%2CCombo&cmsid=Opel_Combo_Text',
    },
    {
        f: '/pl/marka-samochodu/opel/corsa',
        t: '/pl/findcar?makesModels=Opel%2CCorsa&cmsid=Opel_Corsa_Text',
    },
    {
        f: '/pl/marka-samochodu/opel/insignia',
        t: '/pl/findcar?makesModels=Opel%2CInsignia&cmsid=Opel_Insignia_Text',
    },
    {
        f: '/pl/marka-samochodu/opel/meriva',
        t: '/pl/findcar?makesModels=Opel%2CMeriva&cmsid=Opel_Meriva_Text',
    },
    {
        f: '/pl/marka-samochodu/opel/movano',
        t: '/pl/findcar?makesModels=Opel%2CMovano&cmsid=Opel_Movano_Text',
    },
    {
        f: '/pl/marka-samochodu/opel/vivaro',
        t: '/pl/findcar?makesModels=Opel%2CVivaro&cmsid=Opel_Vivaro_Text',
    },
    {
        f: '/pl/marka-samochodu/opel/zafira',
        t: '/pl/findcar?makesModels=Opel%2CZafira&cmsid=Opel_Zafira_Text',
    },
    {
        f: '/pl/marka-samochodu/opel',
        t: '/pl/findcar?makesModels=Opel&cmsid=Seo_Opel',
    },
    {
        f: '/pl/marka-samochodu/peugeot/407sw',
        t: '/pl/findcar?makesModels=Peugeot%2C40x&cmsid=Peugeot_407sw_Text',
    },
    {
        f: '/pl/marka-samochodu/peugeot/5008',
        t: '/pl/findcar?makesModels=Peugeot%2C5008&cmsid=Peugeot_5008_Text',
    },
    {
        f: '/pl/marka-samochodu/peugeot/expert',
        t: '/pl/findcar?makesModels=Peugeot%2CExpert&cmsid=Peugeot_Expert_Text',
    },
    {
        f: '/pl/marka-samochodu/peugeot/partner',
        t: '/pl/findcar?makesModels=Peugeot%2CPartner&cmsid=Peugeot_Partner_Text',
    },
    {
        f: '/pl/marka-samochodu/peugeot',
        t: '/pl/findcar?makesModels=Peugeot&cmsid=Seo_Peugeot',
    },
    {
        f: '/pl/marka-samochodu/piaggio',
        t: '/pl/findcar?makesModels=Piaggio&cmsid=SEO_Piaggio',
    },
    {
        f: '/pl/marka-samochodu/renault/clio',
        t: '/pl/findcar?makesModels=Renault%2CClio&cmsid=Renault_Clio_Text',
    },
    {
        f: '/pl/marka-samochodu/renault/espace',
        t: '/pl/findcar?makesModels=Renault%2CEspace&cmsid=Renault_Espace_Text',
    },
    {
        f: '/pl/marka-samochodu/renault/kangoo',
        t: '/pl/findcar?makesModels=Renault%2CKangoo&cmsid=Renault_Kangoo_Text',
    },
    {
        f: '/pl/marka-samochodu/renault/megane',
        t: '/pl/findcar?makesModels=Renault%2CMegane&cmsid=Renault_Megane_Text',
    },
    {
        f: '/pl/marka-samochodu/renault/scenic',
        t: '/pl/findcar?makesModels=Renault%2CScenic&cmsid=Renault_Scenic_Text',
    },
    {
        f: '/pl/marka-samochodu/renault',
        t: '/pl/findcar?makesModels=Renault&cmsid=SEO_Renault',
    },
    {
        f: '/pl/marka-samochodu/skoda/fabia',
        t: '/pl/findcar?makesModels=Skoda%2CFabia&cmsid=Skoda_Fabia_Text',
    },
    {
        f: '/pl/marka-samochodu/skoda/octavia',
        t: '/pl/findcar?makesModels=Skoda%2COctavia&cmsid=Skoda_Octavia_Text',
    },
    {
        f: '/pl/marka-samochodu/skoda/rapid',
        t: '/pl/findcar?makesModels=Skoda%2CRapid&cmsid=Skoda_Rapid_Text',
    },
    {
        f: '/pl/marka-samochodu/skoda/superb',
        t: '/pl/findcar?makesModels=Skoda%2CSuperb&cmsid=Skoda_Superb_Text',
    },
    {
        f: '/pl/marka-samochodu/skoda/yeti',
        t: '/pl/findcar?makesModels=Skoda%2CYeti&cmsid=Skoda_Yeti_Text',
    },
    {
        f: '/pl/marka-samochodu/skoda',
        t: '/pl/findcar?makesModels=Skoda&cmsid=SEO_Skoda',
    },
    {
        f: '/pl/marka-samochodu/toyota/auris',
        t: '/pl/findcar?makesModels=Toyota%2CAuris&cmsid=Toyota_Auris_Text',
    },
    {
        f: '/pl/marka-samochodu/toyota/avensis',
        t: '/pl/findcar?makesModels=Toyota%2CAvensis&cmsid=Toyota_Avensis_Text',
    },
    {
        f: '/pl/marka-samochodu/toyota/aygo',
        t: '/pl/findcar?makesModels=Toyota%2CAygo&cmsid=Toyota_Aygo_Text',
    },
    {
        f: '/pl/marka-samochodu/toyota/prius',
        t: '/pl/findcar?makesModels=Toyota%2CPrius&cmsid=Toyota_Prius_Text',
    },
    {
        f: '/pl/marka-samochodu/toyota/rav4',
        t: '/pl/findcar?makesModels=Toyota%2CRav4&cmsid=Toyota_Rav4_Text',
    },
    {
        f: '/pl/marka-samochodu/toyota/yaris',
        t: '/pl/findcar?makesModels=Toyota%2CYaris&cmsid=Toyota_Yaris_Text',
    },
    {
        f: '/pl/marka-samochodu/toyota',
        t: '/pl/findcar?makesModels=Toyota&cmsid=SEO_Toyota',
    },
    {
        f: '/pl/marka-samochodu/volkswagen/caddy',
        t: '/pl/findcar?makesModels=Volkswagen%2CCaddy&cmsid=Volkswagen_Caddy_Text',
    },
    {
        f: '/pl/marka-samochodu/volkswagen/golf',
        t: '/pl/findcar?makesModels=Volkswagen%2CGolf&cmsid=Volkswagen_Golf_Text',
    },
    {
        f: '/pl/marka-samochodu/volkswagen/passat',
        t: '/pl/findcar?makesModels=Volkswagen%2CPassat&cmsid=Volkswagen_Passat_Text',
    },
    {
        f: '/pl/marka-samochodu/volkswagen/polo',
        t: '/pl/findcar?makesModels=Volkswagen%2CPolo&cmsid=Volkswagen_Polo_Text',
    },
    {
        f: '/pl/marka-samochodu/volkswagen/tiguan',
        t: '/pl/findcar?makesModels=Volkswagen%2CTiguan&cmsid=Volkswagen_Tiguan_Text',
    },
    {
        f: '/pl/marka-samochodu/volkswagen/touareg',
        t: '/pl/findcar?makesModels=Volkswagen%2CTouareg&cmsid=Volkswagen_Touareg_Text',
    },
    {
        f: '/pl/marka-samochodu/volkswagen/touran',
        t: '/pl/findcar?makesModels=Volkswagen%2CTouran&cmsid=Volkswagen_Touran_Text',
    },
    {
        f: '/pl/marka-samochodu/volkswagen/transporter',
        t: '/pl/findcar?makesModels=Volkswagen%2CTransporter&cmsid=Volkswagen_Transporter_Text',
    },
    {
        f: '/pl/marka-samochodu/volkswagen',
        t: '/pl/findcar?makesModels=Volkswagen&cmsid=SEO_Volkswagen',
    },
    {
        f: '/pl/marka-samochodu/volvo/s60',
        t: '/pl/findcar?makesModels=Volvo%2CS60&cmsid=Volvo_S60_Text',
    },
    {
        f: '/pl/marka-samochodu/volvo/s80',
        t: '/pl/findcar?makesModels=Volvo%2CS80&cmsid=Volvo_S80_Text',
    },
    {
        f: '/pl/marka-samochodu/volvo/s90',
        t: '/pl/findcar?makesModels=Volvo%2CS90&cmsid=Volvo_S90_Text',
    },
    {
        f: '/pl/marka-samochodu/volvo/v40',
        t: '/pl/findcar?makesModels=Volvo%2CV40&cmsid=Volvo_V40_Text',
    },
    {
        f: '/pl/marka-samochodu/volvo/v60',
        t: '/pl/findcar?makesModels=Volvo%2CV60&cmsid=Volvo_V60_Text',
    },
    {
        f: '/pl/marka-samochodu/volvo/v90',
        t: '/pl/findcar?makesModels=Volvo%2CV90&cmsid=Volvo_V90_Text',
    },
    {
        f: '/pl/marka-samochodu/volvo/xc60',
        t: '/pl/findcar?makesModels=Volvo%2CXC60&cmsid=Volvo_XC60_Text',
    },
    {
        f: '/pl/marka-samochodu/volvo/xc90',
        t: '/pl/findcar?makesModels=Volvo%2CXC90&cmsid=Volvo_XC90_Text',
    },
    {
        f: '/pl/marka-samochodu/volvo',
        t: '/pl/findcar?makesModels=Volvo&cmsid=SEO_Volvo',
    },
    {
        f: '/pl/samochody-uzywane-belgia',
        t: '/pl/findcar?originCountries=be&cmsid=used-cars-belgium',
    },
    {
        f: '/pl/samochody-uzywane-niemcy',
        t: '/pl/findcar?originCountries=de&cmsid=used-cars-germany',
    },
    {
        f: '/pl/hiszpanskie-samochody',
        t: '/pl/findcar?originCountries=es&cmsid=used-cars-spain',
    },
    {
        f: '/pl/uzywane-samochody-francji',
        t: '/pl/findcar?originCountries=fr&cmsid=used-cars-france',
    },
    {
        f: '/pl/wloskie-samochody',
        t: '/pl/findcar?originCountries=it&cmsid=used-cars-italy',
    },
    {
        f: '/pl/uzywane-samochody-luksemburg',
        t: '/pl/findcar?originCountries=lu&cmsid=used-cars-luxembourg',
    },
    {
        f: '/pl/uzywane-samochody-holandii',
        t: '/pl/findcar?originCountries=nl&cmsid=used-cars-holland',
    },
    {
        f: '/pl/uzywane-samochody-polska',
        t: '/pl/findcar?originCountries=pl&cmsid=used-cars-poland',
    },
    {
        f: '/pl/uzywane-samochody-szwecja',
        t: '/pl/findcar?originCountries=se&cmsid=used-cars-sweden',
    },
    {
        f: '/pl/ceny-samochodow-uzywanych/tanie-samochody-uzywane',
        t: '/pl/findcar?prices=0%2C3000&cmsid=cheap_used_cars',
    },
    {
        f: '/pl/ceny-samochodow-uzywanych/middle-class',
        t: '/pl/findcar?prices=10000%2C20000&cmsid=Middle_class',
    },
    {
        f: '/pl/ceny-samochodow-uzywanych/luxury-cars',
        t: '/pl/findcar?prices=20000%2C0&cmsid=luxury_cars',
    },
    {
        f: '/pl/ceny-samochodow-uzywanych/auta-ekonomiczne',
        t: '/pl/findcar?prices=3000%2C6000&cmsid=economy_cars',
    },
    {
        f: '/pl/ceny-samochodow-uzywanych/value-for-money',
        t: '/pl/findcar?prices=6000%2C10000&cmsid=Value_for_money',
    },
    {
        f: '/pt/versao-carro/camiao',
        t: '/pt/findcar?bodyTypes=Truck&cmsid=Truck',
    },
    {
        f: '/pt/leilao/asignacao-garantizada',
        t: '/pt/findcar?assignment100pc=true&cmsid=100assignment',
    },
    { f: '/pt/leilao/dynamica', t: '/pt/findcar?auctionTypes=1&cmsid=dynamic' },
    {
        f: '/pt/leilao/comprar-neste-momento',
        t: '/pt/findcar?auctionTypes=2&cmsid=buy-now',
    },
    { f: '/pt/leilao/cego', t: '/pt/findcar?auctionTypes=4&cmsid=blind' },
    { f: '/pt/leilao/alvo', t: '/pt/findcar?auctionTypes=5&cmsid=target' },
    {
        f: '/pt/versao-carro/klasa-sedan',
        t: '/pt/findcar?bodyTypes=Berline&cmsid=SEO_Berline',
    },
    {
        f: '/pt/versao-carro/classe-kombi',
        t: '/pt/findcar?bodyTypes=Break&cmsid=SEO_Break',
    },
    {
        f: '/pt/versao-carro/classe-cabriolet',
        t: '/pt/findcar?bodyTypes=Cabriolet&cmsid=SEO_Cabrio',
    },
    {
        f: '/pt/versao-carro/carros-compactos',
        t: '/pt/findcar?bodyTypes=Compact&cmsid=SEO_Compact',
    },
    {
        f: '/pt/versao-carro/coupe',
        t: '/pt/findcar?bodyTypes=Coup%C3%A9&cmsid=SEO_Coup%C3%A9',
    },
    {
        f: '/pt/versao-carro/hatchback',
        t: '/pt/findcar?bodyTypes=Hatchback&cmsid=SEO_Hatchback',
    },
    {
        f: '/pt/versao-carro/light-truck',
        t: '/pt/findcar?bodyTypes=Lighttruck&cmsid=SEO_Light-truck',
    },
    {
        f: '/pt/versao-carro/miniautocarro',
        t: '/pt/findcar?bodyTypes=Minibus&cmsid=Minibus',
    },
    {
        f: '/pt/versao-carro/motas-scooter',
        t: '/pt/findcar?bodyTypes=Motorbike&cmsid=Motorbike',
    },
    {
        f: '/pt/versao-carro/monovolume',
        t: '/pt/findcar?bodyTypes=MPV&cmsid=SEO_MPV',
    },
    { f: '/pt/versao-carro/suv', t: '/pt/findcar?bodyTypes=SUV&cmsid=SEO_SUV' },
    {
        f: '/pt/leilao/carros-sem-estragos',
        t: '/pt/findcar?damageTypes=1&cmsid=no_specific-Damages',
    },
    {
        f: '/pt/leilao/carros-com-estragos',
        t: '/pt/findcar?damageTypes=2&cmsid=Body-damages',
    },
    {
        f: '/pt/leilao/carros-com-avaria-tecnica',
        t: '/pt/findcar?damageTypes=3&cmsid=technical-damages',
    },
    {
        f: '/pt/leilao/carrocaria-avaria-tecnica-estragos',
        t: '/pt/findcar?damageTypes=4&cmsid=Technical-and-body-damages',
    },
    {
        f: '/pt/marcas-carros/abarth',
        t: '/pt/findcar?makesModels=Abarth&cmsid=SEO_Abarth',
    },
    {
        f: '/pt/marcas-carros/alfa-romeo',
        t: '/pt/findcar?makesModels=Alfa%20Romeo&cmsid=SEO_alfa-romeo',
    },
    {
        f: '/pt/marcas-carros/audi/a1',
        t: '/pt/findcar?makesModels=Audi%2CA1&cmsid=Audi_A1_Text',
    },
    {
        f: '/pt/marcas-carros/audi/a2',
        t: '/pt/findcar?makesModels=Audi%2CA2&cmsid=Audi_A2_Text',
    },
    {
        f: '/pt/marcas-carros/audi/a3',
        t: '/pt/findcar?makesModels=Audi%2CA3&cmsid=Audi_A3_Text',
    },
    {
        f: '/pt/marcas-carros/audi/a4',
        t: '/pt/findcar?makesModels=Audi%2CA4&cmsid=Audi_A4_Text',
    },
    {
        f: '/pt/marcas-carros/audi/a5',
        t: '/pt/findcar?makesModels=Audi%2CA5&cmsid=Audi_A5_Text',
    },
    {
        f: '/pt/marcas-carros/audi/a6',
        t: '/pt/findcar?makesModels=Audi%2CA6&cmsid=Audi_A6_Text',
    },
    {
        f: '/pt/marcas-carros/audi/q3',
        t: '/pt/findcar?makesModels=Audi%2CQ3&cmsid=Audi_Q3_Text',
    },
    {
        f: '/pt/marcas-carros/audi/q5',
        t: '/pt/findcar?makesModels=Audi%2CQ5&cmsid=Audi_Q5_Text',
    },
    {
        f: '/pt/marcas-carros/audi/q7',
        t: '/pt/findcar?makesModels=Audi%2CQ7&cmsid=Audi_Q7_Text',
    },
    {
        f: '/pt/marcas-carros/audi',
        t: '/pt/findcar?makesModels=Audi&cmsid=SEO_Audi',
    },
    {
        f: '/pt/marcas-carros/bmw/1',
        t: '/pt/findcar?makesModels=BMW%2C1%20Series&cmsid=BMW_1series_Text',
    },
    {
        f: '/pt/marcas-carros/bmw/2',
        t: '/pt/findcar?makesModels=BMW%2C2%20Series&cmsid=BMW_2series_Text',
    },
    {
        f: '/pt/marcas-carros/bmw/3',
        t: '/pt/findcar?makesModels=BMW%2C3%20Series&cmsid=BMW_3series_Text',
    },
    {
        f: '/pt/marcas-carros/bmw/4',
        t: '/pt/findcar?makesModels=BMW%2C4%20Series&cmsid=BMW_4series_Text',
    },
    {
        f: '/pt/marcas-carros/bmw/5',
        t: '/pt/findcar?makesModels=BMW%2C5%20Series&cmsid=BMW_5series_Text',
    },
    {
        f: '/pt/marcas-carros/bmw/x1',
        t: '/pt/findcar?makesModels=BMW%2CX1&cmsid=BMW_X1_Text',
    },
    {
        f: '/pt/marcas-carros/bmw/x3',
        t: '/pt/findcar?makesModels=BMW%2CX3&cmsid=BMW_X3_Text',
    },
    {
        f: '/pt/marcas-carros/bmw/x5',
        t: '/pt/findcar?makesModels=BMW%2CX5&cmsid=BMW_X5_Text',
    },
    {
        f: '/pt/marcas-carros/bmw/x6',
        t: '/pt/findcar?makesModels=BMW%2CX6&cmsid=BMW_X6_Text',
    },
    {
        f: '/pt/marcas-carros/bmw',
        t: '/pt/findcar?makesModels=BMW&cmsid=SEO_BMW',
    },
    {
        f: '/pt/marcas-carros/citroen/berlingo',
        t: '/pt/findcar?makesModels=Citro%C3%ABn%2CBerlingo&cmsid=Citro%C3%ABn_Berlingo_text',
    },
    {
        f: '/pt/marcas-carros/citroen/c3',
        t: '/pt/findcar?makesModels=Citro%C3%ABn%2CC3&cmsid=Citro%C3%ABn_C3_text',
    },
    {
        f: '/pt/marcas-carros/citroen/c4',
        t: '/pt/findcar?makesModels=Citro%C3%ABn%2CC4&cmsid=Citro%C3%ABn_C4_text',
    },
    {
        f: '/pt/marcas-carros/citroen/c5',
        t: '/pt/findcar?makesModels=Citro%C3%ABn%2CC5&cmsid=Citro%C3%ABn_C5_text',
    },
    {
        f: '/pt/marcas-carros/citroen/ds3',
        t: '/pt/findcar?makesModels=Citro%C3%ABn%2CDS3&cmsid=Citro%C3%ABn_DS3_text',
    },
    {
        f: '/pt/marcas-carros/citroen/ds4',
        t: '/pt/findcar?makesModels=Citro%C3%ABn%2CDS4&cmsid=Citro%C3%ABn_DS4_text',
    },
    {
        f: '/pt/marcas-carros/citroen/dispatch',
        t: '/pt/findcar?makesModels=Citro%C3%ABn%2CJumpy&cmsid=Citro%C3%ABn_Dispatch_text',
    },
    {
        f: '/pt/marcas-carros/citroen',
        t: '/pt/findcar?makesModels=Citro%C3%ABn&cmsid=SEO_Citro%C3%ABn',
    },
    {
        f: '/pt/marcas-carros/fiat/500',
        t: '/pt/findcar?makesModels=Fiat%2C500&cmsid=Fiat_500_Text',
    },
    {
        f: '/pt/marcas-carros/fiat/bravo',
        t: '/pt/findcar?makesModels=Fiat%2CBravo&cmsid=Fiat_bravo_Text',
    },
    {
        f: '/pt/marcas-carros/fiat/doblo',
        t: '/pt/findcar?makesModels=Fiat%2CDoblo&cmsid=Fiat_doblo_Text',
    },
    {
        f: '/pt/marcas-carros/fiat/ducato',
        t: '/pt/findcar?makesModels=Fiat%2CDucato&cmsid=Fiat_ducato_Text',
    },
    {
        f: '/pt/marcas-carros/fiat/freemont',
        t: '/pt/findcar?makesModels=Fiat%2CFreemont&cmsid=Fiat_freemont_Text',
    },
    {
        f: '/pt/marcas-carros/fiat/panda',
        t: '/pt/findcar?makesModels=Fiat%2CPanda&cmsid=Fiat_panda_Text',
    },
    {
        f: '/pt/marcas-carros/fiat/punto',
        t: '/pt/findcar?makesModels=Fiat%2CPunto&cmsid=Fiat_punto_Text',
    },
    {
        f: '/pt/marcas-carros/fiat',
        t: '/pt/findcar?makesModels=Fiat&cmsid=SEO_Fiat',
    },
    {
        f: '/pt/marcas-carros/ford/fiesta',
        t: '/pt/findcar?makesModels=Ford%2CFiesta&cmsid=Ford_Fiesta_Text',
    },
    {
        f: '/pt/marcas-carros/ford/focus',
        t: '/pt/findcar?makesModels=Ford%2CFocus&cmsid=Ford_Focus_Text',
    },
    {
        f: '/pt/marcas-carros/ford/mondeo',
        t: '/pt/findcar?makesModels=Ford%2CMondeo&cmsid=Ford_Mondeo_Text',
    },
    {
        f: '/pt/marcas-carros/ford/transit',
        t: '/pt/findcar?makesModels=Ford%2CTransit&cmsid=Ford_Transit_Text',
    },
    {
        f: '/pt/marcas-carros/ford',
        t: '/pt/findcar?makesModels=Ford&cmsid=SEO_Ford',
    },
    {
        f: '/pt/marcas-carros/lancia',
        t: '/pt/findcar?makesModels=Lancia&cmsid=SEO_Lancia',
    },
    {
        f: '/pt/marcas-carros/mercedes/clase-a',
        t: '/pt/findcar?makesModels=Mercedes-Benz%2CA&cmsid=Mercedes_A-class_Text',
    },
    {
        f: '/pt/marcas-carros/mercedes/clase-b',
        t: '/pt/findcar?makesModels=Mercedes-Benz%2CB&cmsid=Mercedes_B-class_Text',
    },
    {
        f: '/pt/marcas-carros/mercedes/clase-c',
        t: '/pt/findcar?makesModels=Mercedes-Benz%2CC&cmsid=Mercedes_C-class_Text',
    },
    {
        f: '/pt/marcas-carros/mercedes/clase-e',
        t: '/pt/findcar?makesModels=Mercedes-Benz%2CE&cmsid=Mercedes_E-class_Text',
    },
    {
        f: '/pt/marcas-carros/mercedes/sprinter',
        t: '/pt/findcar?makesModels=Mercedes-Benz%2CSprinter&cmsid=Mercedes_Sprinter_Text',
    },
    {
        f: '/pt/marcas-carros/mercedes/vito',
        t: '/pt/findcar?makesModels=Mercedes-Benz%2CVito&cmsid=Mercedes_Vito_Text',
    },
    {
        f: '/pt/marcas-carros/mercedes',
        t: '/pt/findcar?makesModels=Mercedes-Benz&cmsid=SEO_Mercedes',
    },
    {
        f: '/pt/marcas-carros/nissan/juke',
        t: '/pt/findcar?makesModels=Nissan%2CJuke&cmsid=Nissan_Juke_Text',
    },
    {
        f: '/pt/marcas-carros/nissan/micra',
        t: '/pt/findcar?makesModels=Nissan%2CMicra&cmsid=Nissan_micra_Text',
    },
    {
        f: '/pt/marcas-carros/nissan/pathfinder',
        t: '/pt/findcar?makesModels=Nissan%2CPathfinder&cmsid=Nissan_Pathfinder_Text',
    },
    {
        f: '/pt/marcas-carros/nissan/qashqai',
        t: '/pt/findcar?makesModels=Nissan%2CQashqai&cmsid=Nissan_Qashqai_Text',
    },
    {
        f: '/pt/marcas-carros/nissan/x-trail',
        t: '/pt/findcar?makesModels=Nissan%2CX-Trail&cmsid=Nissan_x-trail_Text',
    },
    {
        f: '/pt/marcas-carros/nissan',
        t: '/pt/findcar?makesModels=Nissan&cmsid=SEO_Nissan',
    },
    {
        f: '/pt/marcas-carros/opel/astra',
        t: '/pt/findcar?makesModels=Opel%2CAstra&cmsid=Opel_Astra_Text',
    },
    {
        f: '/pt/marcas-carros/opel/combo',
        t: '/pt/findcar?makesModels=Opel%2CCombo&cmsid=Opel_Combo_Text',
    },
    {
        f: '/pt/marcas-carros/opel/corsa',
        t: '/pt/findcar?makesModels=Opel%2CCorsa&cmsid=Opel_Corsa_Text',
    },
    {
        f: '/pt/marcas-carros/opel/insignia',
        t: '/pt/findcar?makesModels=Opel%2CInsignia&cmsid=Opel_Insignia_Text',
    },
    {
        f: '/pt/marcas-carros/opel/meriva',
        t: '/pt/findcar?makesModels=Opel%2CMeriva&cmsid=Opel_Meriva_Text',
    },
    {
        f: '/pt/marcas-carros/opel/movano',
        t: '/pt/findcar?makesModels=Opel%2CMovano&cmsid=Opel_Movano_Text',
    },
    {
        f: '/pt/marcas-carros/opel/vivaro',
        t: '/pt/findcar?makesModels=Opel%2CVivaro&cmsid=Opel_Vivaro_Text',
    },
    {
        f: '/pt/marcas-carros/opel/zafira',
        t: '/pt/findcar?makesModels=Opel%2CZafira&cmsid=Opel_Zafira_Text',
    },
    {
        f: '/pt/marcas-carros/opel',
        t: '/pt/findcar?makesModels=Opel&cmsid=Seo_Opel',
    },
    {
        f: '/pt/marcas-carros/peugeot/407sw',
        t: '/pt/findcar?makesModels=Peugeot%2C40x&cmsid=Peugeot_407sw_Text',
    },
    {
        f: '/pt/marcas-carros/peugeot/5008',
        t: '/pt/findcar?makesModels=Peugeot%2C5008&cmsid=Peugeot_5008_Text',
    },
    {
        f: '/pt/marcas-carros/peugeot/expert',
        t: '/pt/findcar?makesModels=Peugeot%2CExpert&cmsid=Peugeot_Expert_Text',
    },
    {
        f: '/pt/marcas-carros/peugeot/partner',
        t: '/pt/findcar?makesModels=Peugeot%2CPartner&cmsid=Peugeot_Partner_Text',
    },
    {
        f: '/pt/marcas-carros/peugeot',
        t: '/pt/findcar?makesModels=Peugeot&cmsid=Seo_Peugeot',
    },
    {
        f: '/pt/marcas-carros/piaggio',
        t: '/pt/findcar?makesModels=Piaggio&cmsid=SEO_Piaggio',
    },
    {
        f: '/pt/marcas-carros/renault/clio',
        t: '/pt/findcar?makesModels=Renault%2CClio&cmsid=Renault_Clio_Text',
    },
    {
        f: '/pt/marcas-carros/renault/espace',
        t: '/pt/findcar?makesModels=Renault%2CEspace&cmsid=Renault_Espace_Text',
    },
    {
        f: '/pt/marcas-carros/renault/kangoo',
        t: '/pt/findcar?makesModels=Renault%2CKangoo&cmsid=Renault_Kangoo_Text',
    },
    {
        f: '/pt/marcas-carros/renault/megane',
        t: '/pt/findcar?makesModels=Renault%2CMegane&cmsid=Renault_Megane_Text',
    },
    {
        f: '/pt/marcas-carros/renault/scenic',
        t: '/pt/findcar?makesModels=Renault%2CScenic&cmsid=Renault_Scenic_Text',
    },
    {
        f: '/pt/marcas-carros/renault',
        t: '/pt/findcar?makesModels=Renault&cmsid=SEO_Renault',
    },
    {
        f: '/pt/marcas-carros/skoda/fabia',
        t: '/pt/findcar?makesModels=Skoda%2CFabia&cmsid=Skoda_Fabia_Text',
    },
    {
        f: '/pt/marcas-carros/skoda/octavia',
        t: '/pt/findcar?makesModels=Skoda%2COctavia&cmsid=Skoda_Octavia_Text',
    },
    {
        f: '/pt/marcas-carros/skoda/rapid',
        t: '/pt/findcar?makesModels=Skoda%2CRapid&cmsid=Skoda_Rapid_Text',
    },
    {
        f: '/pt/marcas-carros/skoda/superb',
        t: '/pt/findcar?makesModels=Skoda%2CSuperb&cmsid=Skoda_Superb_Text',
    },
    {
        f: '/pt/marcas-carros/skoda/yeti',
        t: '/pt/findcar?makesModels=Skoda%2CYeti&cmsid=Skoda_Yeti_Text',
    },
    {
        f: '/pt/marcas-carros/skoda',
        t: '/pt/findcar?makesModels=Skoda&cmsid=SEO_Skoda',
    },
    {
        f: '/pt/marcas-carros/toyota/auris',
        t: '/pt/findcar?makesModels=Toyota%2CAuris&cmsid=Toyota_Auris_Text',
    },
    {
        f: '/pt/marcas-carros/toyota/avensis',
        t: '/pt/findcar?makesModels=Toyota%2CAvensis&cmsid=Toyota_Avensis_Text',
    },
    {
        f: '/pt/marcas-carros/toyota/aygo',
        t: '/pt/findcar?makesModels=Toyota%2CAygo&cmsid=Toyota_Aygo_Text',
    },
    {
        f: '/pt/marcas-carros/toyota/prius',
        t: '/pt/findcar?makesModels=Toyota%2CPrius&cmsid=Toyota_Prius_Text',
    },
    {
        f: '/pt/marcas-carros/toyota/rav4',
        t: '/pt/findcar?makesModels=Toyota%2CRav4&cmsid=Toyota_Rav4_Text',
    },
    {
        f: '/pt/marcas-carros/toyota/yaris',
        t: '/pt/findcar?makesModels=Toyota%2CYaris&cmsid=Toyota_Yaris_Text',
    },
    {
        f: '/pt/marcas-carros/toyota',
        t: '/pt/findcar?makesModels=Toyota&cmsid=SEO_Toyota',
    },
    {
        f: '/pt/marcas-carros/volkswagen/caddy',
        t: '/pt/findcar?makesModels=Volkswagen%2CCaddy&cmsid=Volkswagen_Caddy_Text',
    },
    {
        f: '/pt/marcas-carros/volkswagen/golf',
        t: '/pt/findcar?makesModels=Volkswagen%2CGolf&cmsid=Volkswagen_Golf_Text',
    },
    {
        f: '/pt/marcas-carros/volkswagen/passat',
        t: '/pt/findcar?makesModels=Volkswagen%2CPassat&cmsid=Volkswagen_Passat_Text',
    },
    {
        f: '/pt/marcas-carros/volkswagen/polo',
        t: '/pt/findcar?makesModels=Volkswagen%2CPolo&cmsid=Volkswagen_Polo_Text',
    },
    {
        f: '/pt/marcas-carros/volkswagen/tiguan',
        t: '/pt/findcar?makesModels=Volkswagen%2CTiguan&cmsid=Volkswagen_Tiguan_Text',
    },
    {
        f: '/pt/marcas-carros/volkswagen/touareg',
        t: '/pt/findcar?makesModels=Volkswagen%2CTouareg&cmsid=Volkswagen_Touareg_Text',
    },
    {
        f: '/pt/marcas-carros/volkswagen/touran',
        t: '/pt/findcar?makesModels=Volkswagen%2CTouran&cmsid=Volkswagen_Touran_Text',
    },
    {
        f: '/pt/marcas-carros/volkswagen/transporter',
        t: '/pt/findcar?makesModels=Volkswagen%2CTransporter&cmsid=Volkswagen_Transporter_Text',
    },
    {
        f: '/pt/marcas-carros/volkswagen',
        t: '/pt/findcar?makesModels=Volkswagen&cmsid=SEO_Volkswagen',
    },
    {
        f: '/pt/marcas-carros/volvo/s60',
        t: '/pt/findcar?makesModels=Volvo%2CS60&cmsid=Volvo_S60_Text',
    },
    {
        f: '/pt/marcas-carros/volvo/s80',
        t: '/pt/findcar?makesModels=Volvo%2CS80&cmsid=Volvo_S80_Text',
    },
    {
        f: '/pt/marcas-carros/volvo/s90',
        t: '/pt/findcar?makesModels=Volvo%2CS90&cmsid=Volvo_S90_Text',
    },
    {
        f: '/pt/marcas-carros/volvo/v40',
        t: '/pt/findcar?makesModels=Volvo%2CV40&cmsid=Volvo_V40_Text',
    },
    {
        f: '/pt/marcas-carros/volvo/v60',
        t: '/pt/findcar?makesModels=Volvo%2CV60&cmsid=Volvo_V60_Text',
    },
    {
        f: '/pt/marcas-carros/volvo/v90',
        t: '/pt/findcar?makesModels=Volvo%2CV90&cmsid=Volvo_V90_Text',
    },
    {
        f: '/pt/marcas-carros/volvo/xc60',
        t: '/pt/findcar?makesModels=Volvo%2CXC60&cmsid=Volvo_XC60_Text',
    },
    {
        f: '/pt/marcas-carros/volvo/xc90',
        t: '/pt/findcar?makesModels=Volvo%2CXC90&cmsid=Volvo_XC90_Text',
    },
    {
        f: '/pt/marcas-carros/volvo',
        t: '/pt/findcar?makesModels=Volvo&cmsid=SEO_Volvo',
    },
    {
        f: '/pt/carros-usados-belgica',
        t: '/pt/findcar?originCountries=be&cmsid=used-cars-belgium',
    },
    {
        f: '/pt/carros-alemanha',
        t: '/pt/findcar?originCountries=de&cmsid=used-cars-germany',
    },
    {
        f: '/pt/carros-espanha',
        t: '/pt/findcar?originCountries=es&cmsid=used-cars-spain',
    },
    {
        f: '/pt/carros-usados-franca',
        t: '/pt/findcar?originCountries=fr&cmsid=used-cars-france',
    },
    {
        f: '/pt/carros-italia',
        t: '/pt/findcar?originCountries=it&cmsid=used-cars-italy',
    },
    {
        f: '/pt/carros-usados-luxemburgo',
        t: '/pt/findcar?originCountries=lu&cmsid=used-cars-luxembourg',
    },
    {
        f: '/pt/carros-holanda',
        t: '/pt/findcar?originCountries=nl&cmsid=used-cars-holland',
    },
    {
        f: '/pt/carros-usados-polonia',
        t: '/pt/findcar?originCountries=pl&cmsid=used-cars-poland',
    },
    {
        f: '/pt/carros-usados-suecia',
        t: '/pt/findcar?originCountries=se&cmsid=used-cars-sweden',
    },
    {
        f: '/pt/precos-auto-segunda-mao/carros-baratos',
        t: '/pt/findcar?prices=0%2C3000&cmsid=cheap_used_cars',
    },
    {
        f: '/pt/precos-auto-segunda-mao/classe-media',
        t: '/pt/findcar?prices=10000%2C20000&cmsid=Middle_class',
    },
    {
        f: '/pt/precos-auto-segunda-mao/luxury-carscarros-luxo',
        t: '/pt/findcar?prices=20000%2C0&cmsid=luxury_cars',
    },
    {
        f: '/pt/precos-auto-segunda-mao/venda-carros-baratos',
        t: '/pt/findcar?prices=3000%2C6000&cmsid=economy_cars',
    },
    {
        f: '/pt/precos-auto-segunda-mao/dinheiro',
        t: '/pt/findcar?prices=6000%2C10000&cmsid=Value_for_money',
    },
    {
        f: '/ro/tip-caroserie/camion',
        t: '/ro/findcar?bodyTypes=Truck&cmsid=Truck',
    },
    {
        f: '/ro/licitatie-auto/licitatie-garantata',
        t: '/ro/findcar?assignment100pc=true&cmsid=100assignment',
    },
    {
        f: '/ro/licitatie-auto/licitatii-dinamice',
        t: '/ro/findcar?auctionTypes=1&cmsid=dynamic',
    },
    {
        f: '/ro/licitatie-auto/cumpara-acum',
        t: '/ro/findcar?auctionTypes=2&cmsid=buy-now',
    },
    {
        f: '/ro/licitatie-auto/oarba',
        t: '/ro/findcar?auctionTypes=4&cmsid=blind',
    },
    {
        f: '/ro/licitatie-auto/tinta',
        t: '/ro/findcar?auctionTypes=5&cmsid=target',
    },
    {
        f: '/ro/tip-caroserie/berlina',
        t: '/ro/findcar?bodyTypes=Berline&cmsid=SEO_Berline',
    },
    {
        f: '/ro/tip-caroserie/break',
        t: '/ro/findcar?bodyTypes=Break&cmsid=SEO_Break',
    },
    {
        f: '/ro/tip-caroserie/cabrio',
        t: '/ro/findcar?bodyTypes=Cabriolet&cmsid=SEO_Cabrio',
    },
    {
        f: '/ro/tip-caroserie/compact',
        t: '/ro/findcar?bodyTypes=Compact&cmsid=SEO_Compact',
    },
    {
        f: '/ro/tip-caroserie/coupe',
        t: '/ro/findcar?bodyTypes=Coup%C3%A9&cmsid=SEO_Coup%C3%A9',
    },
    {
        f: '/ro/tip-caroserie/hatchback',
        t: '/ro/findcar?bodyTypes=Hatchback&cmsid=SEO_Hatchback',
    },
    {
        f: '/ro/tip-caroserie/autoutilitare-camionete-furgonete',
        t: '/ro/findcar?bodyTypes=Lighttruck&cmsid=SEO_Light-truck',
    },
    {
        f: '/ro/tip-caroserie/minibus',
        t: '/ro/findcar?bodyTypes=Minibus&cmsid=Minibus',
    },
    {
        f: '/ro/tip-caroserie/motociclete',
        t: '/ro/findcar?bodyTypes=Motorbike&cmsid=Motorbike',
    },
    { f: '/ro/tip-caroserie/mpv', t: '/ro/findcar?bodyTypes=MPV&cmsid=SEO_MPV' },
    {
        f: '/ro/tip-caroserie/suv-crossover-offroad',
        t: '/ro/findcar?bodyTypes=SUV&cmsid=SEO_SUV',
    },
    {
        f: '/ro/licitatie-auto/fara-accidente',
        t: '/ro/findcar?damageTypes=1&cmsid=no_specific-Damages',
    },
    {
        f: '/ro/licitatie-auto/masini-accidentate',
        t: '/ro/findcar?damageTypes=2&cmsid=Body-damages',
    },
    {
        f: '/ro/licitatie-auto/masini-defecte-tehnice',
        t: '/ro/findcar?damageTypes=3&cmsid=technical-damages',
    },
    {
        f: '/ro/licitatie-auto/defectiuni-tehnice-caroserie',
        t: '/ro/findcar?damageTypes=4&cmsid=Technical-and-body-damages',
    },
    {
        f: '/ro/model-auto/abarth',
        t: '/ro/findcar?makesModels=Abarth&cmsid=SEO_Abarth',
    },
    {
        f: '/ro/model-auto/alfa-romeo',
        t: '/ro/findcar?makesModels=Alfa%20Romeo&cmsid=SEO_alfa-romeo',
    },
    {
        f: '/ro/model-auto/audi/a1',
        t: '/ro/findcar?makesModels=Audi%2CA1&cmsid=Audi_A1_Text',
    },
    {
        f: '/ro/model-auto/audi/a2',
        t: '/ro/findcar?makesModels=Audi%2CA2&cmsid=Audi_A2_Text',
    },
    {
        f: '/ro/model-auto/audi/a3',
        t: '/ro/findcar?makesModels=Audi%2CA3&cmsid=Audi_A3_Text',
    },
    {
        f: '/ro/model-auto/audi/a4',
        t: '/ro/findcar?makesModels=Audi%2CA4&cmsid=Audi_A4_Text',
    },
    {
        f: '/ro/model-auto/audi/a5',
        t: '/ro/findcar?makesModels=Audi%2CA5&cmsid=Audi_A5_Text',
    },
    {
        f: '/ro/model-auto/audi/a6',
        t: '/ro/findcar?makesModels=Audi%2CA6&cmsid=Audi_A6_Text',
    },
    {
        f: '/ro/model-auto/audi/q3',
        t: '/ro/findcar?makesModels=Audi%2CQ3&cmsid=Audi_Q3_Text',
    },
    {
        f: '/ro/model-auto/audi/q5',
        t: '/ro/findcar?makesModels=Audi%2CQ5&cmsid=Audi_Q5_Text',
    },
    {
        f: '/ro/model-auto/audi/q7',
        t: '/ro/findcar?makesModels=Audi%2CQ7&cmsid=Audi_Q7_Text',
    },
    {
        f: '/ro/model-auto/audi',
        t: '/ro/findcar?makesModels=Audi&cmsid=SEO_Audi',
    },
    {
        f: '/ro/model-auto/bmw/1',
        t: '/ro/findcar?makesModels=BMW%2C1%20Series&cmsid=BMW_1series_Text',
    },
    {
        f: '/ro/model-auto/bmw/2',
        t: '/ro/findcar?makesModels=BMW%2C2%20Series&cmsid=BMW_2series_Text',
    },
    {
        f: '/ro/model-auto/bmw/3',
        t: '/ro/findcar?makesModels=BMW%2C3%20Series&cmsid=BMW_3series_Text',
    },
    {
        f: '/ro/model-auto/bmw/4',
        t: '/ro/findcar?makesModels=BMW%2C4%20Series&cmsid=BMW_4series_Text',
    },
    {
        f: '/ro/model-auto/bmw/5',
        t: '/ro/findcar?makesModels=BMW%2C5%20Series&cmsid=BMW_5series_Text',
    },
    {
        f: '/ro/model-auto/bmw/x1',
        t: '/ro/findcar?makesModels=BMW%2CX1&cmsid=BMW_X1_Text',
    },
    {
        f: '/ro/model-auto/bmw/x3',
        t: '/ro/findcar?makesModels=BMW%2CX3&cmsid=BMW_X3_Text',
    },
    {
        f: '/ro/model-auto/bmw/x5',
        t: '/ro/findcar?makesModels=BMW%2CX5&cmsid=BMW_X5_Text',
    },
    {
        f: '/ro/model-auto/bmw/x6',
        t: '/ro/findcar?makesModels=BMW%2CX6&cmsid=BMW_X6_Text',
    },
    { f: '/ro/model-auto/bmw', t: '/ro/findcar?makesModels=BMW&cmsid=SEO_BMW' },
    {
        f: '/ro/model-auto/citroen/berlingo',
        t: '/ro/findcar?makesModels=Citro%C3%ABn%2CBerlingo&cmsid=Citro%C3%ABn_Berlingo_text',
    },
    {
        f: '/ro/model-auto/citroen/c3',
        t: '/ro/findcar?makesModels=Citro%C3%ABn%2CC3&cmsid=Citro%C3%ABn_C3_text',
    },
    {
        f: '/ro/model-auto/citroen/c4',
        t: '/ro/findcar?makesModels=Citro%C3%ABn%2CC4&cmsid=Citro%C3%ABn_C4_text',
    },
    {
        f: '/ro/model-auto/citroen/c5',
        t: '/ro/findcar?makesModels=Citro%C3%ABn%2CC5&cmsid=Citro%C3%ABn_C5_text',
    },
    {
        f: '/ro/model-auto/citroen/ds3',
        t: '/ro/findcar?makesModels=Citro%C3%ABn%2CDS3&cmsid=Citro%C3%ABn_DS3_text',
    },
    {
        f: '/ro/model-auto/citroen/ds4',
        t: '/ro/findcar?makesModels=Citro%C3%ABn%2CDS4&cmsid=Citro%C3%ABn_DS4_text',
    },
    {
        f: '/ro/model-auto/citroen/dispatch',
        t: '/ro/findcar?makesModels=Citro%C3%ABn%2CJumpy&cmsid=Citro%C3%ABn_Dispatch_text',
    },
    {
        f: '/ro/model-auto/citroen',
        t: '/ro/findcar?makesModels=Citro%C3%ABn&cmsid=SEO_Citro%C3%ABn',
    },
    {
        f: '/ro/model-auto/fiat/500',
        t: '/ro/findcar?makesModels=Fiat%2C500&cmsid=Fiat_500_Text',
    },
    {
        f: '/ro/model-auto/fiat/bravo',
        t: '/ro/findcar?makesModels=Fiat%2CBravo&cmsid=Fiat_bravo_Text',
    },
    {
        f: '/ro/model-auto/fiat/doblo',
        t: '/ro/findcar?makesModels=Fiat%2CDoblo&cmsid=Fiat_doblo_Text',
    },
    {
        f: '/ro/model-auto/fiat/ducato',
        t: '/ro/findcar?makesModels=Fiat%2CDucato&cmsid=Fiat_ducato_Text',
    },
    {
        f: '/ro/model-auto/fiat/freemont',
        t: '/ro/findcar?makesModels=Fiat%2CFreemont&cmsid=Fiat_freemont_Text',
    },
    {
        f: '/ro/model-auto/fiat/panda',
        t: '/ro/findcar?makesModels=Fiat%2CPanda&cmsid=Fiat_panda_Text',
    },
    {
        f: '/ro/model-auto/fiat/punto',
        t: '/ro/findcar?makesModels=Fiat%2CPunto&cmsid=Fiat_punto_Text',
    },
    {
        f: '/ro/model-auto/fiat',
        t: '/ro/findcar?makesModels=Fiat&cmsid=SEO_Fiat',
    },
    {
        f: '/ro/model-auto/ford/fiesta',
        t: '/ro/findcar?makesModels=Ford%2CFiesta&cmsid=Ford_Fiesta_Text',
    },
    {
        f: '/ro/model-auto/ford/focus',
        t: '/ro/findcar?makesModels=Ford%2CFocus&cmsid=Ford_Focus_Text',
    },
    {
        f: '/ro/model-auto/ford/mondeo',
        t: '/ro/findcar?makesModels=Ford%2CMondeo&cmsid=Ford_Mondeo_Text',
    },
    {
        f: '/ro/model-auto/ford/transit',
        t: '/ro/findcar?makesModels=Ford%2CTransit&cmsid=Ford_Transit_Text',
    },
    {
        f: '/ro/model-auto/ford',
        t: '/ro/findcar?makesModels=Ford&cmsid=SEO_Ford',
    },
    {
        f: '/ro/model-auto/lancia',
        t: '/ro/findcar?makesModels=Lancia&cmsid=SEO_Lancia',
    },
    {
        f: '/ro/model-auto/mercedes/clasa-a',
        t: '/ro/findcar?makesModels=Mercedes-Benz%2CA&cmsid=Mercedes_A-class_Text',
    },
    {
        f: '/ro/model-auto/mercedes/clasa-b',
        t: '/ro/findcar?makesModels=Mercedes-Benz%2CB&cmsid=Mercedes_B-class_Text',
    },
    {
        f: '/ro/model-auto/mercedes/clasa-c',
        t: '/ro/findcar?makesModels=Mercedes-Benz%2CC&cmsid=Mercedes_C-class_Text',
    },
    {
        f: '/ro/model-auto/mercedes/clasa-e',
        t: '/ro/findcar?makesModels=Mercedes-Benz%2CE&cmsid=Mercedes_E-class_Text',
    },
    {
        f: '/ro/model-auto/mercedes/sprinter',
        t: '/ro/findcar?makesModels=Mercedes-Benz%2CSprinter&cmsid=Mercedes_Sprinter_Text',
    },
    {
        f: '/ro/model-auto/mercedes/vito',
        t: '/ro/findcar?makesModels=Mercedes-Benz%2CVito&cmsid=Mercedes_Vito_Text',
    },
    {
        f: '/ro/model-auto/mercedes',
        t: '/ro/findcar?makesModels=Mercedes-Benz&cmsid=SEO_Mercedes',
    },
    {
        f: '/ro/model-auto/nissan/juke',
        t: '/ro/findcar?makesModels=Nissan%2CJuke&cmsid=Nissan_Juke_Text',
    },
    {
        f: '/ro/model-auto/nissan/micra',
        t: '/ro/findcar?makesModels=Nissan%2CMicra&cmsid=Nissan_micra_Text',
    },
    {
        f: '/ro/model-auto/nissan/pathfinder',
        t: '/ro/findcar?makesModels=Nissan%2CPathfinder&cmsid=Nissan_Pathfinder_Text',
    },
    {
        f: '/ro/model-auto/nissan/qashqai',
        t: '/ro/findcar?makesModels=Nissan%2CQashqai&cmsid=Nissan_Qashqai_Text',
    },
    {
        f: '/ro/model-auto/nissan/x-trail',
        t: '/ro/findcar?makesModels=Nissan%2CX-Trail&cmsid=Nissan_x-trail_Text',
    },
    {
        f: '/ro/model-auto/nissan',
        t: '/ro/findcar?makesModels=Nissan&cmsid=SEO_Nissan',
    },
    {
        f: '/ro/model-auto/opel/astra',
        t: '/ro/findcar?makesModels=Opel%2CAstra&cmsid=Opel_Astra_Text',
    },
    {
        f: '/ro/model-auto/opel/combo',
        t: '/ro/findcar?makesModels=Opel%2CCombo&cmsid=Opel_Combo_Text',
    },
    {
        f: '/ro/model-auto/opel/corsa',
        t: '/ro/findcar?makesModels=Opel%2CCorsa&cmsid=Opel_Corsa_Text',
    },
    {
        f: '/ro/model-auto/opel/insignia',
        t: '/ro/findcar?makesModels=Opel%2CInsignia&cmsid=Opel_Insignia_Text',
    },
    {
        f: '/ro/model-auto/opel/meriva',
        t: '/ro/findcar?makesModels=Opel%2CMeriva&cmsid=Opel_Meriva_Text',
    },
    {
        f: '/ro/model-auto/opel/movano',
        t: '/ro/findcar?makesModels=Opel%2CMovano&cmsid=Opel_Movano_Text',
    },
    {
        f: '/ro/model-auto/opel/vivaro',
        t: '/ro/findcar?makesModels=Opel%2CVivaro&cmsid=Opel_Vivaro_Text',
    },
    {
        f: '/ro/model-auto/opel/zafira',
        t: '/ro/findcar?makesModels=Opel%2CZafira&cmsid=Opel_Zafira_Text',
    },
    {
        f: '/ro/model-auto/opel',
        t: '/ro/findcar?makesModels=Opel&cmsid=Seo_Opel',
    },
    {
        f: '/ro/model-auto/peugeot/407sw',
        t: '/ro/findcar?makesModels=Peugeot%2C40x&cmsid=Peugeot_407sw_Text',
    },
    {
        f: '/ro/model-auto/peugeot/5008',
        t: '/ro/findcar?makesModels=Peugeot%2C5008&cmsid=Peugeot_5008_Text',
    },
    {
        f: '/ro/model-auto/peugeot/expert',
        t: '/ro/findcar?makesModels=Peugeot%2CExpert&cmsid=Peugeot_Expert_Text',
    },
    {
        f: '/ro/model-auto/peugeot/partner',
        t: '/ro/findcar?makesModels=Peugeot%2CPartner&cmsid=Peugeot_Partner_Text',
    },
    {
        f: '/ro/model-auto/peugeot',
        t: '/ro/findcar?makesModels=Peugeot&cmsid=Seo_Peugeot',
    },
    {
        f: '/ro/model-auto/piaggio',
        t: '/ro/findcar?makesModels=Piaggio&cmsid=SEO_Piaggio',
    },
    {
        f: '/ro/model-auto/renault/clio',
        t: '/ro/findcar?makesModels=Renault%2CClio&cmsid=Renault_Clio_Text',
    },
    {
        f: '/ro/model-auto/renault/espace',
        t: '/ro/findcar?makesModels=Renault%2CEspace&cmsid=Renault_Espace_Text',
    },
    {
        f: '/ro/model-auto/renault/kangoo',
        t: '/ro/findcar?makesModels=Renault%2CKangoo&cmsid=Renault_Kangoo_Text',
    },
    {
        f: '/ro/model-auto/renault/megane',
        t: '/ro/findcar?makesModels=Renault%2CMegane&cmsid=Renault_Megane_Text',
    },
    {
        f: '/ro/model-auto/renault/scenic',
        t: '/ro/findcar?makesModels=Renault%2CScenic&cmsid=Renault_Scenic_Text',
    },
    {
        f: '/ro/model-auto/renault',
        t: '/ro/findcar?makesModels=Renault&cmsid=SEO_Renault',
    },
    {
        f: '/ro/model-auto/skoda/fabia',
        t: '/ro/findcar?makesModels=Skoda%2CFabia&cmsid=Skoda_Fabia_Text',
    },
    {
        f: '/ro/model-auto/skoda/octavia',
        t: '/ro/findcar?makesModels=Skoda%2COctavia&cmsid=Skoda_Octavia_Text',
    },
    {
        f: '/ro/model-auto/skoda/rapid',
        t: '/ro/findcar?makesModels=Skoda%2CRapid&cmsid=Skoda_Rapid_Text',
    },
    {
        f: '/ro/model-auto/skoda/superb',
        t: '/ro/findcar?makesModels=Skoda%2CSuperb&cmsid=Skoda_Superb_Text',
    },
    {
        f: '/ro/model-auto/skoda/yeti',
        t: '/ro/findcar?makesModels=Skoda%2CYeti&cmsid=Skoda_Yeti_Text',
    },
    {
        f: '/ro/model-auto/skoda',
        t: '/ro/findcar?makesModels=Skoda&cmsid=SEO_Skoda',
    },
    {
        f: '/ro/model-auto/toyota/auris',
        t: '/ro/findcar?makesModels=Toyota%2CAuris&cmsid=Toyota_Auris_Text',
    },
    {
        f: '/ro/model-auto/toyota/avensis',
        t: '/ro/findcar?makesModels=Toyota%2CAvensis&cmsid=Toyota_Avensis_Text',
    },
    {
        f: '/ro/model-auto/toyota/aygo',
        t: '/ro/findcar?makesModels=Toyota%2CAygo&cmsid=Toyota_Aygo_Text',
    },
    {
        f: '/ro/model-auto/toyota/prius',
        t: '/ro/findcar?makesModels=Toyota%2CPrius&cmsid=Toyota_Prius_Text',
    },
    {
        f: '/ro/model-auto/toyota/rav4',
        t: '/ro/findcar?makesModels=Toyota%2CRav4&cmsid=Toyota_Rav4_Text',
    },
    {
        f: '/ro/model-auto/toyota/yaris',
        t: '/ro/findcar?makesModels=Toyota%2CYaris&cmsid=Toyota_Yaris_Text',
    },
    {
        f: '/ro/model-auto/toyota',
        t: '/ro/findcar?makesModels=Toyota&cmsid=SEO_Toyota',
    },
    {
        f: '/ro/model-auto/volkswagen/caddy',
        t: '/ro/findcar?makesModels=Volkswagen%2CCaddy&cmsid=Volkswagen_Caddy_Text',
    },
    {
        f: '/ro/model-auto/volkswagen/golf',
        t: '/ro/findcar?makesModels=Volkswagen%2CGolf&cmsid=Volkswagen_Golf_Text',
    },
    {
        f: '/ro/model-auto/volkswagen/passat',
        t: '/ro/findcar?makesModels=Volkswagen%2CPassat&cmsid=Volkswagen_Passat_Text',
    },
    {
        f: '/ro/model-auto/volkswagen/polo',
        t: '/ro/findcar?makesModels=Volkswagen%2CPolo&cmsid=Volkswagen_Polo_Text',
    },
    {
        f: '/ro/model-auto/volkswagen/tiguan',
        t: '/ro/findcar?makesModels=Volkswagen%2CTiguan&cmsid=Volkswagen_Tiguan_Text',
    },
    {
        f: '/ro/model-auto/volkswagen/touareg',
        t: '/ro/findcar?makesModels=Volkswagen%2CTouareg&cmsid=Volkswagen_Touareg_Text',
    },
    {
        f: '/ro/model-auto/volkswagen/touran',
        t: '/ro/findcar?makesModels=Volkswagen%2CTouran&cmsid=Volkswagen_Touran_Text',
    },
    {
        f: '/ro/model-auto/volkswagen/transporter',
        t: '/ro/findcar?makesModels=Volkswagen%2CTransporter&cmsid=Volkswagen_Transporter_Text',
    },
    {
        f: '/ro/model-auto/volkswagen',
        t: '/ro/findcar?makesModels=Volkswagen&cmsid=SEO_Volkswagen',
    },
    {
        f: '/ro/model-auto/volvo/s60',
        t: '/ro/findcar?makesModels=Volvo%2CS60&cmsid=Volvo_S60_Text',
    },
    {
        f: '/ro/model-auto/volvo/s80',
        t: '/ro/findcar?makesModels=Volvo%2CS80&cmsid=Volvo_S80_Text',
    },
    {
        f: '/ro/model-auto/volvo/s90',
        t: '/ro/findcar?makesModels=Volvo%2CS90&cmsid=Volvo_S90_Text',
    },
    {
        f: '/ro/model-auto/volvo/v40',
        t: '/ro/findcar?makesModels=Volvo%2CV40&cmsid=Volvo_V40_Text',
    },
    {
        f: '/ro/model-auto/volvo/v60',
        t: '/ro/findcar?makesModels=Volvo%2CV60&cmsid=Volvo_V60_Text',
    },
    {
        f: '/ro/model-auto/volvo/v90',
        t: '/ro/findcar?makesModels=Volvo%2CV90&cmsid=Volvo_V90_Text',
    },
    {
        f: '/ro/model-auto/volvo/xc60',
        t: '/ro/findcar?makesModels=Volvo%2CXC60&cmsid=Volvo_XC60_Text',
    },
    {
        f: '/ro/model-auto/volvo/xc90',
        t: '/ro/findcar?makesModels=Volvo%2CXC90&cmsid=Volvo_XC90_Text',
    },
    {
        f: '/ro/model-auto/volvo',
        t: '/ro/findcar?makesModels=Volvo&cmsid=SEO_Volvo',
    },
    {
        f: '/ro/masini-second-hand-belgia',
        t: '/ro/findcar?originCountries=be&cmsid=used-cars-belgium',
    },
    {
        f: '/ro/masini-second-hand-germania',
        t: '/ro/findcar?originCountries=de&cmsid=used-cars-germany',
    },
    {
        f: '/ro/masini-second-hand-spania',
        t: '/ro/findcar?originCountries=es&cmsid=used-cars-spain',
    },
    {
        f: '/ro/masini-second-hand-franta',
        t: '/ro/findcar?originCountries=fr&cmsid=used-cars-france',
    },
    {
        f: '/ro/masini-second-hand-italia',
        t: '/ro/findcar?originCountries=it&cmsid=used-cars-italy',
    },
    {
        f: '/ro/masini-second-hand-luxemburg',
        t: '/ro/findcar?originCountries=lu&cmsid=used-cars-luxembourg',
    },
    {
        f: '/ro/masini-second-hand-olanda',
        t: '/ro/findcar?originCountries=nl&cmsid=used-cars-holland',
    },
    {
        f: '/ro/masini-second-hand-polonia',
        t: '/ro/findcar?originCountries=pl&cmsid=used-cars-poland',
    },
    {
        f: '/ro/masini-second-hand-suedia',
        t: '/ro/findcar?originCountries=se&cmsid=used-cars-sweden',
    },
    {
        f: '/ro/preturi-masini-second-hand/masini-ieftine',
        t: '/ro/findcar?prices=0%2C3000&cmsid=cheap_used_cars',
    },
    {
        f: '/ro/preturi-masini-second-hand/masini-scumpe',
        t: '/ro/findcar?prices=10000%2C20000&cmsid=Middle_class',
    },
    {
        f: '/ro/preturi-masini-second-hand/autoturisme-lux',
        t: '/ro/findcar?prices=20000%2C0&cmsid=luxury_cars',
    },
    {
        f: '/ro/preturi-masini-second-hand/masini-accesibile',
        t: '/ro/findcar?prices=3000%2C6000&cmsid=economy_cars',
    },
    {
        f: '/ro/preturi-masini-second-hand/masini-pana-in-10000-euro',
        t: '/ro/findcar?prices=6000%2C10000&cmsid=Value_for_money',
    },
    { f: '/ru/body-type/gruzovik', t: '/ru/findcar?bodyTypes=Truck&cmsid=Truck' },
    {
        f: '/ru/car-auction/stoprocentnoe-raspredeleni',
        t: '/ru/findcar?assignment100pc=true&cmsid=100assignment',
    },
    {
        f: '/ru/car-auction/dinamiceskij',
        t: '/ru/findcar?auctionTypes=1&cmsid=dynamic',
    },
    {
        f: '/ru/car-auction/kupit-srazu',
        t: '/ru/findcar?auctionTypes=2&cmsid=buy-now',
    },
    { f: '/ru/car-auction/vslepuu', t: '/ru/findcar?auctionTypes=4&cmsid=blind' },
    { f: '/ru/car-auction/target', t: '/ru/findcar?auctionTypes=5&cmsid=target' },
    {
        f: '/ru/body-type/berlin',
        t: '/ru/findcar?bodyTypes=Berline&cmsid=SEO_Berline',
    },
    {
        f: '/ru/body-type/break',
        t: '/ru/findcar?bodyTypes=Break&cmsid=SEO_Break',
    },
    {
        f: '/ru/body-type/cabrio',
        t: '/ru/findcar?bodyTypes=Cabriolet&cmsid=SEO_Cabrio',
    },
    {
        f: '/ru/body-type/kompakt',
        t: '/ru/findcar?bodyTypes=Compact&cmsid=SEO_Compact',
    },
    {
        f: '/ru/body-type/kupe',
        t: '/ru/findcar?bodyTypes=Coup%C3%A9&cmsid=SEO_Coup%C3%A9',
    },
    {
        f: '/ru/body-type/hetcbek',
        t: '/ru/findcar?bodyTypes=Hatchback&cmsid=SEO_Hatchback',
    },
    {
        f: '/ru/body-type/legkij-gruzovik',
        t: '/ru/findcar?bodyTypes=Lighttruck&cmsid=SEO_Light-truck',
    },
    {
        f: '/ru/body-type/minibus',
        t: '/ru/findcar?bodyTypes=Minibus&cmsid=Minibus',
    },
    {
        f: '/ru/body-type/motocikl',
        t: '/ru/findcar?bodyTypes=Motorbike&cmsid=Motorbike',
    },
    { f: '/ru/body-type/mpv', t: '/ru/findcar?bodyTypes=MPV&cmsid=SEO_MPV' },
    {
        f: '/ru/body-type/avtomobil-vnedoroznik',
        t: '/ru/findcar?bodyTypes=SUV&cmsid=SEO_SUV',
    },
    {
        f: '/ru/car-auction/no-damages',
        t: '/ru/findcar?damageTypes=1&cmsid=no_specific-Damages',
    },
    {
        f: '/ru/car-auction/accident-cars',
        t: '/ru/findcar?damageTypes=2&cmsid=Body-damages',
    },
    {
        f: '/ru/car-auction/technically-damaged-cars',
        t: '/ru/findcar?damageTypes=3&cmsid=technical-damages',
    },
    {
        f: '/ru/car-auction/technical-and-body-damages',
        t: '/ru/findcar?damageTypes=4&cmsid=Technical-and-body-damages',
    },
    {
        f: '/ru/marka/abarth',
        t: '/ru/findcar?makesModels=Abarth&cmsid=SEO_Abarth',
    },
    {
        f: '/ru/marka/alfa-romeo',
        t: '/ru/findcar?makesModels=Alfa%20Romeo&cmsid=SEO_alfa-romeo',
    },
    {
        f: '/ru/marka/audi/a1',
        t: '/ru/findcar?makesModels=Audi%2CA1&cmsid=Audi_A1_Text',
    },
    {
        f: '/ru/marka/audi/a2',
        t: '/ru/findcar?makesModels=Audi%2CA2&cmsid=Audi_A2_Text',
    },
    {
        f: '/ru/marka/audi/a3',
        t: '/ru/findcar?makesModels=Audi%2CA3&cmsid=Audi_A3_Text',
    },
    {
        f: '/ru/marka/audi/a4',
        t: '/ru/findcar?makesModels=Audi%2CA4&cmsid=Audi_A4_Text',
    },
    {
        f: '/ru/marka/audi/a5',
        t: '/ru/findcar?makesModels=Audi%2CA5&cmsid=Audi_A5_Text',
    },
    {
        f: '/ru/marka/audi/a6',
        t: '/ru/findcar?makesModels=Audi%2CA6&cmsid=Audi_A6_Text',
    },
    {
        f: '/ru/marka/audi/q3',
        t: '/ru/findcar?makesModels=Audi%2CQ3&cmsid=Audi_Q3_Text',
    },
    {
        f: '/ru/marka/audi/q5',
        t: '/ru/findcar?makesModels=Audi%2CQ5&cmsid=Audi_Q5_Text',
    },
    {
        f: '/ru/marka/audi/q7',
        t: '/ru/findcar?makesModels=Audi%2CQ7&cmsid=Audi_Q7_Text',
    },
    { f: '/ru/marka/audi', t: '/ru/findcar?makesModels=Audi&cmsid=SEO_Audi' },
    {
        f: '/ru/marka/bmw/1-seria',
        t: '/ru/findcar?makesModels=BMW%2C1%20Series&cmsid=BMW_1series_Text',
    },
    {
        f: '/ru/marka/bmw/2-seria',
        t: '/ru/findcar?makesModels=BMW%2C2%20Series&cmsid=BMW_2series_Text',
    },
    {
        f: '/ru/marka/bmw/3-seria',
        t: '/ru/findcar?makesModels=BMW%2C3%20Series&cmsid=BMW_3series_Text',
    },
    {
        f: '/ru/marka/bmw/4-seria',
        t: '/ru/findcar?makesModels=BMW%2C4%20Series&cmsid=BMW_4series_Text',
    },
    {
        f: '/ru/marka/bmw/5-seria',
        t: '/ru/findcar?makesModels=BMW%2C5%20Series&cmsid=BMW_5series_Text',
    },
    {
        f: '/ru/marka/bmw/x1',
        t: '/ru/findcar?makesModels=BMW%2CX1&cmsid=BMW_X1_Text',
    },
    {
        f: '/ru/marka/bmw/x3',
        t: '/ru/findcar?makesModels=BMW%2CX3&cmsid=BMW_X3_Text',
    },
    {
        f: '/ru/marka/bmw/x5',
        t: '/ru/findcar?makesModels=BMW%2CX5&cmsid=BMW_X5_Text',
    },
    {
        f: '/ru/marka/bmw/x6',
        t: '/ru/findcar?makesModels=BMW%2CX6&cmsid=BMW_X6_Text',
    },
    { f: '/ru/marka/bmw', t: '/ru/findcar?makesModels=BMW&cmsid=SEO_BMW' },
    {
        f: '/ru/marka/citroen/berlingo',
        t: '/ru/findcar?makesModels=Citro%C3%ABn%2CBerlingo&cmsid=Citro%C3%ABn_Berlingo_text',
    },
    {
        f: '/ru/marka/citroen/c3',
        t: '/ru/findcar?makesModels=Citro%C3%ABn%2CC3&cmsid=Citro%C3%ABn_C3_text',
    },
    {
        f: '/ru/marka/citroen/c4',
        t: '/ru/findcar?makesModels=Citro%C3%ABn%2CC4&cmsid=Citro%C3%ABn_C4_text',
    },
    {
        f: '/ru/marka/citroen/c5',
        t: '/ru/findcar?makesModels=Citro%C3%ABn%2CC5&cmsid=Citro%C3%ABn_C5_text',
    },
    {
        f: '/ru/marka/citroen/ds3',
        t: '/ru/findcar?makesModels=Citro%C3%ABn%2CDS3&cmsid=Citro%C3%ABn_DS3_text',
    },
    {
        f: '/ru/marka/citroen/ds4',
        t: '/ru/findcar?makesModels=Citro%C3%ABn%2CDS4&cmsid=Citro%C3%ABn_DS4_text',
    },
    {
        f: '/ru/marka/citroen/dispatch',
        t: '/ru/findcar?makesModels=Citro%C3%ABn%2CJumpy&cmsid=Citro%C3%ABn_Dispatch_text',
    },
    {
        f: '/ru/marka/citroen',
        t: '/ru/findcar?makesModels=Citro%C3%ABn&cmsid=SEO_Citro%C3%ABn',
    },
    {
        f: '/ru/marka/fiat/500',
        t: '/ru/findcar?makesModels=Fiat%2C500&cmsid=Fiat_500_Text',
    },
    {
        f: '/ru/marka/fiat/bravo',
        t: '/ru/findcar?makesModels=Fiat%2CBravo&cmsid=Fiat_bravo_Text',
    },
    {
        f: '/ru/marka/fiat/doblo',
        t: '/ru/findcar?makesModels=Fiat%2CDoblo&cmsid=Fiat_doblo_Text',
    },
    {
        f: '/ru/marka/fiat/ducato',
        t: '/ru/findcar?makesModels=Fiat%2CDucato&cmsid=Fiat_ducato_Text',
    },
    {
        f: '/ru/marka/fiat/freemont',
        t: '/ru/findcar?makesModels=Fiat%2CFreemont&cmsid=Fiat_freemont_Text',
    },
    {
        f: '/ru/marka/fiat/panda',
        t: '/ru/findcar?makesModels=Fiat%2CPanda&cmsid=Fiat_panda_Text',
    },
    {
        f: '/ru/marka/fiat/punto',
        t: '/ru/findcar?makesModels=Fiat%2CPunto&cmsid=Fiat_punto_Text',
    },
    { f: '/ru/marka/fiat', t: '/ru/findcar?makesModels=Fiat&cmsid=SEO_Fiat' },
    {
        f: '/ru/marka/ford/fiesta',
        t: '/ru/findcar?makesModels=Ford%2CFiesta&cmsid=Ford_Fiesta_Text',
    },
    {
        f: '/ru/marka/ford/focus',
        t: '/ru/findcar?makesModels=Ford%2CFocus&cmsid=Ford_Focus_Text',
    },
    {
        f: '/ru/marka/ford/mondeo',
        t: '/ru/findcar?makesModels=Ford%2CMondeo&cmsid=Ford_Mondeo_Text',
    },
    {
        f: '/ru/marka/ford/transit',
        t: '/ru/findcar?makesModels=Ford%2CTransit&cmsid=Ford_Transit_Text',
    },
    { f: '/ru/marka/ford', t: '/ru/findcar?makesModels=Ford&cmsid=SEO_Ford' },
    {
        f: '/ru/marka/lancia',
        t: '/ru/findcar?makesModels=Lancia&cmsid=SEO_Lancia',
    },
    {
        f: '/ru/marka/mercedes/a-klass',
        t: '/ru/findcar?makesModels=Mercedes-Benz%2CA&cmsid=Mercedes_A-class_Text',
    },
    {
        f: '/ru/marka/mercedes/b-klass',
        t: '/ru/findcar?makesModels=Mercedes-Benz%2CB&cmsid=Mercedes_B-class_Text',
    },
    {
        f: '/ru/marka/mercedes/c-klass',
        t: '/ru/findcar?makesModels=Mercedes-Benz%2CC&cmsid=Mercedes_C-class_Text',
    },
    {
        f: '/ru/marka/mercedes/e-klass',
        t: '/ru/findcar?makesModels=Mercedes-Benz%2CE&cmsid=Mercedes_E-class_Text',
    },
    {
        f: '/ru/marka/mercedes/sprinter',
        t: '/ru/findcar?makesModels=Mercedes-Benz%2CSprinter&cmsid=Mercedes_Sprinter_Text',
    },
    {
        f: '/ru/marka/mercedes/vito',
        t: '/ru/findcar?makesModels=Mercedes-Benz%2CVito&cmsid=Mercedes_Vito_Text',
    },
    {
        f: '/ru/marka/mercedes',
        t: '/ru/findcar?makesModels=Mercedes-Benz&cmsid=SEO_Mercedes',
    },
    {
        f: '/ru/marka/nissan/juke',
        t: '/ru/findcar?makesModels=Nissan%2CJuke&cmsid=Nissan_Juke_Text',
    },
    {
        f: '/ru/marka/nissan/micra',
        t: '/ru/findcar?makesModels=Nissan%2CMicra&cmsid=Nissan_micra_Text',
    },
    {
        f: '/ru/marka/nissan/pathfinder',
        t: '/ru/findcar?makesModels=Nissan%2CPathfinder&cmsid=Nissan_Pathfinder_Text',
    },
    {
        f: '/ru/marka/nissan/qashqai',
        t: '/ru/findcar?makesModels=Nissan%2CQashqai&cmsid=Nissan_Qashqai_Text',
    },
    {
        f: '/ru/marka/nissan/x-trail',
        t: '/ru/findcar?makesModels=Nissan%2CX-Trail&cmsid=Nissan_x-trail_Text',
    },
    {
        f: '/ru/marka/nissan',
        t: '/ru/findcar?makesModels=Nissan&cmsid=SEO_Nissan',
    },
    {
        f: '/ru/marka/opel/astra',
        t: '/ru/findcar?makesModels=Opel%2CAstra&cmsid=Opel_Astra_Text',
    },
    {
        f: '/ru/marka/opel/combo',
        t: '/ru/findcar?makesModels=Opel%2CCombo&cmsid=Opel_Combo_Text',
    },
    {
        f: '/ru/marka/opel/corsa',
        t: '/ru/findcar?makesModels=Opel%2CCorsa&cmsid=Opel_Corsa_Text',
    },
    {
        f: '/ru/,marka/opel/insignia',
        t: '/ru/findcar?makesModels=Opel%2CInsignia&cmsid=Opel_Insignia_Text',
    },
    {
        f: '/ru/marka/opel/meriva',
        t: '/ru/findcar?makesModels=Opel%2CMeriva&cmsid=Opel_Meriva_Text',
    },
    {
        f: '/ru/marka/opel/movano',
        t: '/ru/findcar?makesModels=Opel%2CMovano&cmsid=Opel_Movano_Text',
    },
    {
        f: '/ru/marka/opel/vivaro',
        t: '/ru/findcar?makesModels=Opel%2CVivaro&cmsid=Opel_Vivaro_Text',
    },
    {
        f: '/ru/marka/opel/zafira',
        t: '/ru/findcar?makesModels=Opel%2CZafira&cmsid=Opel_Zafira_Text',
    },
    { f: '/ru/marka/opel', t: '/ru/findcar?makesModels=Opel&cmsid=Seo_Opel' },
    {
        f: '/ru/marka/peugeot/407sw',
        t: '/ru/findcar?makesModels=Peugeot%2C40x&cmsid=Peugeot_407sw_Text',
    },
    {
        f: '/ru/marka/peugeot/5008',
        t: '/ru/findcar?makesModels=Peugeot%2C5008&cmsid=Peugeot_5008_Text',
    },
    {
        f: '/ru/marka/peugeot/expert',
        t: '/ru/findcar?makesModels=Peugeot%2CExpert&cmsid=Peugeot_Expert_Text',
    },
    {
        f: '/ru/marka/peugeot/partner',
        t: '/ru/findcar?makesModels=Peugeot%2CPartner&cmsid=Peugeot_Partner_Text',
    },
    {
        f: '/ru/marka/peugeot',
        t: '/ru/findcar?makesModels=Peugeot&cmsid=Seo_Peugeot',
    },
    {
        f: '/ru/marka/piaggio',
        t: '/ru/findcar?makesModels=Piaggio&cmsid=SEO_Piaggio',
    },
    {
        f: '/ru/marka/renault/clio',
        t: '/ru/findcar?makesModels=Renault%2CClio&cmsid=Renault_Clio_Text',
    },
    {
        f: '/ru/marka/renault/espace',
        t: '/ru/findcar?makesModels=Renault%2CEspace&cmsid=Renault_Espace_Text',
    },
    {
        f: '/ru/marka/renault/kangoo',
        t: '/ru/findcar?makesModels=Renault%2CKangoo&cmsid=Renault_Kangoo_Text',
    },
    {
        f: '/ru/marka/renault/megane',
        t: '/ru/findcar?makesModels=Renault%2CMegane&cmsid=Renault_Megane_Text',
    },
    {
        f: '/ru/marka/renault/scenic',
        t: '/ru/findcar?makesModels=Renault%2CScenic&cmsid=Renault_Scenic_Text',
    },
    {
        f: '/ru/marka/renault',
        t: '/ru/findcar?makesModels=Renault&cmsid=SEO_Renault',
    },
    {
        f: '/ru/marka/skoda/fabia',
        t: '/ru/findcar?makesModels=Skoda%2CFabia&cmsid=Skoda_Fabia_Text',
    },
    {
        f: '/ru/marka/skoda/octavia',
        t: '/ru/findcar?makesModels=Skoda%2COctavia&cmsid=Skoda_Octavia_Text',
    },
    {
        f: '/ru/marka/skoda/rapid',
        t: '/ru/findcar?makesModels=Skoda%2CRapid&cmsid=Skoda_Rapid_Text',
    },
    {
        f: '/ru/marka/skoda/superb',
        t: '/ru/findcar?makesModels=Skoda%2CSuperb&cmsid=Skoda_Superb_Text',
    },
    {
        f: '/ru/marka/skoda/yeti',
        t: '/ru/findcar?makesModels=Skoda%2CYeti&cmsid=Skoda_Yeti_Text',
    },
    { f: '/ru/marka/skoda', t: '/ru/findcar?makesModels=Skoda&cmsid=SEO_Skoda' },
    {
        f: '/ru/marka/toyota/auris',
        t: '/ru/findcar?makesModels=Toyota%2CAuris&cmsid=Toyota_Auris_Text',
    },
    {
        f: '/ru/marka/toyota/avensis',
        t: '/ru/findcar?makesModels=Toyota%2CAvensis&cmsid=Toyota_Avensis_Text',
    },
    {
        f: '/ru/marka/toyota/aygo',
        t: '/ru/findcar?makesModels=Toyota%2CAygo&cmsid=Toyota_Aygo_Text',
    },
    {
        f: '/ru/marka/toyota/prius',
        t: '/ru/findcar?makesModels=Toyota%2CPrius&cmsid=Toyota_Prius_Text',
    },
    {
        f: '/ru/marka/toyota/rav4',
        t: '/ru/findcar?makesModels=Toyota%2CRav4&cmsid=Toyota_Rav4_Text',
    },
    {
        f: '/ru/marka/toyota/yaris',
        t: '/ru/findcar?makesModels=Toyota%2CYaris&cmsid=Toyota_Yaris_Text',
    },
    {
        f: '/ru/marka/toyota',
        t: '/ru/findcar?makesModels=Toyota&cmsid=SEO_Toyota',
    },
    {
        f: '/ru/marka/volkswagen/caddy',
        t: '/ru/findcar?makesModels=Volkswagen%2CCaddy&cmsid=Volkswagen_Caddy_Text',
    },
    {
        f: '/ru/marka/volkswagen/golf',
        t: '/ru/findcar?makesModels=Volkswagen%2CGolf&cmsid=Volkswagen_Golf_Text',
    },
    {
        f: '/ru/marka/volkswagen/passat',
        t: '/ru/findcar?makesModels=Volkswagen%2CPassat&cmsid=Volkswagen_Passat_Text',
    },
    {
        f: '/ru/marka/volkswagen/polo',
        t: '/ru/findcar?makesModels=Volkswagen%2CPolo&cmsid=Volkswagen_Polo_Text',
    },
    {
        f: '/ru/marka/volkswagen/tiguan',
        t: '/ru/findcar?makesModels=Volkswagen%2CTiguan&cmsid=Volkswagen_Tiguan_Text',
    },
    {
        f: '/ru/marka/volkswagen/touareg',
        t: '/ru/findcar?makesModels=Volkswagen%2CTouareg&cmsid=Volkswagen_Touareg_Text',
    },
    {
        f: '/ru/marka/volkswagen/touran',
        t: '/ru/findcar?makesModels=Volkswagen%2CTouran&cmsid=Volkswagen_Touran_Text',
    },
    {
        f: '/ru/marka/volkswagen/transporter',
        t: '/ru/findcar?makesModels=Volkswagen%2CTransporter&cmsid=Volkswagen_Transporter_Text',
    },
    {
        f: '/ru/marka/volkswagen',
        t: '/ru/findcar?makesModels=Volkswagen&cmsid=SEO_Volkswagen',
    },
    {
        f: '/ru/marka/volvo/s60',
        t: '/ru/findcar?makesModels=Volvo%2CS60&cmsid=Volvo_S60_Text',
    },
    {
        f: '/ru/marka/volvo/s80',
        t: '/ru/findcar?makesModels=Volvo%2CS80&cmsid=Volvo_S80_Text',
    },
    {
        f: '/ru/marka/volvo/s90',
        t: '/ru/findcar?makesModels=Volvo%2CS90&cmsid=Volvo_S90_Text',
    },
    {
        f: '/ru/marka/volvo/v40',
        t: '/ru/findcar?makesModels=Volvo%2CV40&cmsid=Volvo_V40_Text',
    },
    {
        f: '/ru/marka/volvo/v60',
        t: '/ru/findcar?makesModels=Volvo%2CV60&cmsid=Volvo_V60_Text',
    },
    {
        f: '/ru/marka/volvo/v90',
        t: '/ru/findcar?makesModels=Volvo%2CV90&cmsid=Volvo_V90_Text',
    },
    {
        f: '/ru/marka/volvo/xc60',
        t: '/ru/findcar?makesModels=Volvo%2CXC60&cmsid=Volvo_XC60_Text',
    },
    {
        f: '/ru/marka/volvo/xc90',
        t: '/ru/findcar?makesModels=Volvo%2CXC90&cmsid=Volvo_XC90_Text',
    },
    { f: '/ru/marka/volvo', t: '/ru/findcar?makesModels=Volvo&cmsid=SEO_Volvo' },
    {
        f: '/ru/used-cars-belgium',
        t: '/ru/findcar?originCountries=be&cmsid=used-cars-belgium',
    },
    {
        f: '/ru/used-cars-germany',
        t: '/ru/findcar?originCountries=de&cmsid=used-cars-germany',
    },
    {
        f: '/ru/used-cars-spain',
        t: '/ru/findcar?originCountries=es&cmsid=used-cars-spain',
    },
    {
        f: '/ru/used-cars-france',
        t: '/ru/findcar?originCountries=fr&cmsid=used-cars-france',
    },
    {
        f: '/ru/used-cars-italy',
        t: '/ru/findcar?originCountries=it&cmsid=used-cars-italy',
    },
    {
        f: '/ru/used-cars-luxembourg',
        t: '/ru/findcar?originCountries=lu&cmsid=used-cars-luxembourg',
    },
    {
        f: '/ru/used-cars-holland',
        t: '/ru/findcar?originCountries=nl&cmsid=used-cars-holland',
    },
    {
        f: '/ru/used-cars-poland',
        t: '/ru/findcar?originCountries=pl&cmsid=used-cars-poland',
    },
    {
        f: '/ru/used-cars-sweden',
        t: '/ru/findcar?originCountries=se&cmsid=used-cars-sweden',
    },
    {
        f: '/ru/used-car-prices/cheap-used-cars',
        t: '/ru/findcar?prices=0%2C3000&cmsid=cheap_used_cars',
    },
    {
        f: '/ru/used-car-prices/middle-class',
        t: '/ru/findcar?prices=10000%2C20000&cmsid=Middle_class',
    },
    {
        f: '/ru/used-car-prices/luxury-cars',
        t: '/ru/findcar?prices=20000%2C0&cmsid=luxury_cars',
    },
    {
        f: '/ru/used-car-prices/avtomobili-ekonom-klassa',
        t: '/ru/findcar?prices=3000%2C6000&cmsid=economy_cars',
    },
    {
        f: '/ru/used-car-prices/value-for-money',
        t: '/ru/findcar?prices=6000%2C10000&cmsid=Value_for_money',
    },
    {
        f: '/sk/body-type/nakladne-auta',
        t: '/sk/findcar?bodyTypes=Truck&cmsid=Truck',
    },
    {
        f: '/sk/aukcie-aut/100-zadanie',
        t: '/sk/findcar?assignment100pc=true&cmsid=100assignment',
    },
    {
        f: '/sk/aukcie-aut/dynamicke',
        t: '/sk/findcar?auctionTypes=1&cmsid=dynamic',
    },
    {
        f: '/sk/aukcie-aut/kupa-auta',
        t: '/sk/findcar?auctionTypes=2&cmsid=buy-now',
    },
    { f: '/sk/aukcie-aut/slepa', t: '/sk/findcar?auctionTypes=4&cmsid=blind' },
    {
        f: '/sk/aukcie-aut/cielova-aukcia',
        t: '/sk/findcar?auctionTypes=5&cmsid=target',
    },
    {
        f: '/sk/body-type/sedan',
        t: '/sk/findcar?bodyTypes=Berline&cmsid=SEO_Berline',
    },
    {
        f: '/sk/body-type/combi',
        t: '/sk/findcar?bodyTypes=Break&cmsid=SEO_Break',
    },
    {
        f: '/sk/body-type/cabrio',
        t: '/sk/findcar?bodyTypes=Cabriolet&cmsid=SEO_Cabrio',
    },
    {
        f: '/sk/body-type/kompaktne-vozidlo',
        t: '/sk/findcar?bodyTypes=Compact&cmsid=SEO_Compact',
    },
    {
        f: '/sk/body-type/coupe',
        t: '/sk/findcar?bodyTypes=Coup%C3%A9&cmsid=SEO_Coup%C3%A9',
    },
    {
        f: '/sk/body-type/hatchback',
        t: '/sk/findcar?bodyTypes=Hatchback&cmsid=SEO_Hatchback',
    },
    {
        f: '/sk/body-type/uzitkove-vozidla',
        t: '/sk/findcar?bodyTypes=Lighttruck&cmsid=SEO_Light-truck',
    },
    {
        f: '/sk/body-type/minibus',
        t: '/sk/findcar?bodyTypes=Minibus&cmsid=Minibus',
    },
    {
        f: '/sk/body-type/motorky',
        t: '/sk/findcar?bodyTypes=Motorbike&cmsid=Motorbike',
    },
    { f: '/sk/body-type/mpv', t: '/sk/findcar?bodyTypes=MPV&cmsid=SEO_MPV' },
    { f: '/sk/body-type/suv', t: '/sk/findcar?bodyTypes=SUV&cmsid=SEO_SUV' },
    {
        f: '/sk/aukcie-aut/spolahlive-auta',
        t: '/sk/findcar?damageTypes=1&cmsid=no_specific-Damages',
    },
    {
        f: '/sk/aukcie-aut/havarovane-auta',
        t: '/sk/findcar?damageTypes=2&cmsid=Body-damages',
    },
    {
        f: '/sk/aukcie-aut/auta-s-technickym-poskodenim',
        t: '/sk/findcar?damageTypes=3&cmsid=technical-damages',
    },
    {
        f: '/sk/aukcie-aut/autovraky',
        t: '/sk/findcar?damageTypes=4&cmsid=Technical-and-body-damages',
    },
    {
        f: '/sk/znacky-aut/abarth',
        t: '/sk/findcar?makesModels=Abarth&cmsid=SEO_Abarth',
    },
    {
        f: '/sk/znacky-aut/alfa-romeo',
        t: '/sk/findcar?makesModels=Alfa%20Romeo&cmsid=SEO_alfa-romeo',
    },
    {
        f: '/sk/znacky-aut/audi/a1',
        t: '/sk/findcar?makesModels=Audi%2CA1&cmsid=Audi_A1_Text',
    },
    {
        f: '/sk/znacky-aut/audi/a2',
        t: '/sk/findcar?makesModels=Audi%2CA2&cmsid=Audi_A2_Text',
    },
    {
        f: '/sk/znacky-aut/audi/a3',
        t: '/sk/findcar?makesModels=Audi%2CA3&cmsid=Audi_A3_Text',
    },
    {
        f: '/sk/znacky-aut/audi/a4',
        t: '/sk/findcar?makesModels=Audi%2CA4&cmsid=Audi_A4_Text',
    },
    {
        f: '/sk/znacky-aut/audi/a5',
        t: '/sk/findcar?makesModels=Audi%2CA5&cmsid=Audi_A5_Text',
    },
    {
        f: '/sk/znacky-aut/audi/a6',
        t: '/sk/findcar?makesModels=Audi%2CA6&cmsid=Audi_A6_Text',
    },
    {
        f: '/sk/znacky-aut/audi/q3',
        t: '/sk/findcar?makesModels=Audi%2CQ3&cmsid=Audi_Q3_Text',
    },
    {
        f: '/sk/znacky-aut/audi/q5',
        t: '/sk/findcar?makesModels=Audi%2CQ5&cmsid=Audi_Q5_Text',
    },
    {
        f: '/sk/znacky-aut/audi/q7',
        t: '/sk/findcar?makesModels=Audi%2CQ7&cmsid=Audi_Q7_Text',
    },
    {
        f: '/sk/znacky-aut/audi',
        t: '/sk/findcar?makesModels=Audi&cmsid=SEO_Audi',
    },
    {
        f: '/sk/znacky-aut/bmw/1',
        t: '/sk/findcar?makesModels=BMW%2C1%20Series&cmsid=BMW_1series_Text',
    },
    {
        f: '/sk/znacky-aut/bmw/2',
        t: '/sk/findcar?makesModels=BMW%2C2%20Series&cmsid=BMW_2series_Text',
    },
    {
        f: '/sk/znacky-aut/bmw/3',
        t: '/sk/findcar?makesModels=BMW%2C3%20Series&cmsid=BMW_3series_Text',
    },
    {
        f: '/sk/znacky-aut/bmw/4',
        t: '/sk/findcar?makesModels=BMW%2C4%20Series&cmsid=BMW_4series_Text',
    },
    {
        f: '/sk/znacky-aut/bmw/5',
        t: '/sk/findcar?makesModels=BMW%2C5%20Series&cmsid=BMW_5series_Text',
    },
    {
        f: '/sk/znacky-aut/bmw/x1',
        t: '/sk/findcar?makesModels=BMW%2CX1&cmsid=BMW_X1_Text',
    },
    {
        f: '/sk/znacky-aut/bmw/x3',
        t: '/sk/findcar?makesModels=BMW%2CX3&cmsid=BMW_X3_Text',
    },
    {
        f: '/sk/znacky-aut/bmw/x5',
        t: '/sk/findcar?makesModels=BMW%2CX5&cmsid=BMW_X5_Text',
    },
    {
        f: '/sk/znacky-aut/bmw/x6',
        t: '/sk/findcar?makesModels=BMW%2CX6&cmsid=BMW_X6_Text',
    },
    { f: '/sk/znacky-aut/bmw', t: '/sk/findcar?makesModels=BMW&cmsid=SEO_BMW' },
    {
        f: '/sk/znacky-aut/citroen/berlingo',
        t: '/sk/findcar?makesModels=Citro%C3%ABn%2CBerlingo&cmsid=Citro%C3%ABn_Berlingo_text',
    },
    {
        f: '/sk/znacky-aut/citroen/c3',
        t: '/sk/findcar?makesModels=Citro%C3%ABn%2CC3&cmsid=Citro%C3%ABn_C3_text',
    },
    {
        f: '/sk/znacky-aut/citroen/c4',
        t: '/sk/findcar?makesModels=Citro%C3%ABn%2CC4&cmsid=Citro%C3%ABn_C4_text',
    },
    {
        f: '/sk/znacky-aut/citroen/c5',
        t: '/sk/findcar?makesModels=Citro%C3%ABn%2CC5&cmsid=Citro%C3%ABn_C5_text',
    },
    {
        f: '/sk/znacky-aut/citroen/ds3',
        t: '/sk/findcar?makesModels=Citro%C3%ABn%2CDS3&cmsid=Citro%C3%ABn_DS3_text',
    },
    {
        f: '/sk/znacky-aut/citroen/ds4',
        t: '/sk/findcar?makesModels=Citro%C3%ABn%2CDS4&cmsid=Citro%C3%ABn_DS4_text',
    },
    {
        f: '/sk/znacky-aut/citroen/dispatch',
        t: '/sk/findcar?makesModels=Citro%C3%ABn%2CJumpy&cmsid=Citro%C3%ABn_Dispatch_text',
    },
    {
        f: '/sk/znacky-aut/citroen',
        t: '/sk/findcar?makesModels=Citro%C3%ABn&cmsid=SEO_Citro%C3%ABn',
    },
    {
        f: '/sk/znacky-aut/fiat/500',
        t: '/sk/findcar?makesModels=Fiat%2C500&cmsid=Fiat_500_Text',
    },
    {
        f: '/sk/znacky-aut/fiat/bravo',
        t: '/sk/findcar?makesModels=Fiat%2CBravo&cmsid=Fiat_bravo_Text',
    },
    {
        f: '/sk/znacky-aut/fiat/doblo',
        t: '/sk/findcar?makesModels=Fiat%2CDoblo&cmsid=Fiat_doblo_Text',
    },
    {
        f: '/sk/znacky-aut/fiat/ducato',
        t: '/sk/findcar?makesModels=Fiat%2CDucato&cmsid=Fiat_ducato_Text',
    },
    {
        f: '/sk/znacky-aut/fiat/freemont',
        t: '/sk/findcar?makesModels=Fiat%2CFreemont&cmsid=Fiat_freemont_Text',
    },
    {
        f: '/sk/znacky-aut/fiat/panda',
        t: '/sk/findcar?makesModels=Fiat%2CPanda&cmsid=Fiat_panda_Text',
    },
    {
        f: '/sk/znacky-aut/fiat/punto',
        t: '/sk/findcar?makesModels=Fiat%2CPunto&cmsid=Fiat_punto_Text',
    },
    {
        f: '/sk/znacky-aut/fiat',
        t: '/sk/findcar?makesModels=Fiat&cmsid=SEO_Fiat',
    },
    {
        f: '/sk/znacky-aut/ford/fiesta',
        t: '/sk/findcar?makesModels=Ford%2CFiesta&cmsid=Ford_Fiesta_Text',
    },
    {
        f: '/sk/znacky-aut/ford/focus',
        t: '/sk/findcar?makesModels=Ford%2CFocus&cmsid=Ford_Focus_Text',
    },
    {
        f: '/sk/znacky-aut/ford/mondeo',
        t: '/sk/findcar?makesModels=Ford%2CMondeo&cmsid=Ford_Mondeo_Text',
    },
    {
        f: '/sk/znacky-aut/ford/transit',
        t: '/sk/findcar?makesModels=Ford%2CTransit&cmsid=Ford_Transit_Text',
    },
    {
        f: '/sk/znacky-aut/ford',
        t: '/sk/findcar?makesModels=Ford&cmsid=SEO_Ford',
    },
    {
        f: '/sk/znacky-aut/lancia',
        t: '/sk/findcar?makesModels=Lancia&cmsid=SEO_Lancia',
    },
    {
        f: '/sk/znacky-aut/mercedes/trieda-a',
        t: '/sk/findcar?makesModels=Mercedes-Benz%2CA&cmsid=Mercedes_A-class_Text',
    },
    {
        f: '/sk/znacky-aut/mercedes/trieda-b',
        t: '/sk/findcar?makesModels=Mercedes-Benz%2CB&cmsid=Mercedes_B-class_Text',
    },
    {
        f: '/sk/znacky-aut/mercedes/trieda-c',
        t: '/sk/findcar?makesModels=Mercedes-Benz%2CC&cmsid=Mercedes_C-class_Text',
    },
    {
        f: '/sk/znacky-aut/mercedes/trieda-e',
        t: '/sk/findcar?makesModels=Mercedes-Benz%2CE&cmsid=Mercedes_E-class_Text',
    },
    {
        f: '/sk/znacky-aut/mercedes/sprinter',
        t: '/sk/findcar?makesModels=Mercedes-Benz%2CSprinter&cmsid=Mercedes_Sprinter_Text',
    },
    {
        f: '/sk/znacky-aut/mercedes/vito',
        t: '/sk/findcar?makesModels=Mercedes-Benz%2CVito&cmsid=Mercedes_Vito_Text',
    },
    {
        f: '/sk/znacky-aut/mercedes',
        t: '/sk/findcar?makesModels=Mercedes-Benz&cmsid=SEO_Mercedes',
    },
    {
        f: '/sk/znacky-aut/nissan/juke',
        t: '/sk/findcar?makesModels=Nissan%2CJuke&cmsid=Nissan_Juke_Text',
    },
    {
        f: '/sk/znacky-aut/nissan/micra',
        t: '/sk/findcar?makesModels=Nissan%2CMicra&cmsid=Nissan_micra_Text',
    },
    {
        f: '/sk/znacky-aut/nissan/pathfinder',
        t: '/sk/findcar?makesModels=Nissan%2CPathfinder&cmsid=Nissan_Pathfinder_Text',
    },
    {
        f: '/sk/znacky-aut/nissan/qashqai',
        t: '/sk/findcar?makesModels=Nissan%2CQashqai&cmsid=Nissan_Qashqai_Text',
    },
    {
        f: '/sk/znacky-aut/nissan/x-trail',
        t: '/sk/findcar?makesModels=Nissan%2CX-Trail&cmsid=Nissan_x-trail_Text',
    },
    {
        f: '/sk/znacky-aut/nissan',
        t: '/sk/findcar?makesModels=Nissan&cmsid=SEO_Nissan',
    },
    {
        f: '/sk/znacky-aut/opel/astra',
        t: '/sk/findcar?makesModels=Opel%2CAstra&cmsid=Opel_Astra_Text',
    },
    {
        f: '/sk/znacky-aut/opel/combo',
        t: '/sk/findcar?makesModels=Opel%2CCombo&cmsid=Opel_Combo_Text',
    },
    {
        f: '/sk/znacky-aut/opel/corsa',
        t: '/sk/findcar?makesModels=Opel%2CCorsa&cmsid=Opel_Corsa_Text',
    },
    {
        f: '/sk/znacky-aut/opel/insignia',
        t: '/sk/findcar?makesModels=Opel%2CInsignia&cmsid=Opel_Insignia_Text',
    },
    {
        f: '/sk/znacky-aut/opel/meriva',
        t: '/sk/findcar?makesModels=Opel%2CMeriva&cmsid=Opel_Meriva_Text',
    },
    {
        f: '/sk/znacky-aut/opel/movano',
        t: '/sk/findcar?makesModels=Opel%2CMovano&cmsid=Opel_Movano_Text',
    },
    {
        f: '/sk/znacky-aut/opel/vivaro',
        t: '/sk/findcar?makesModels=Opel%2CVivaro&cmsid=Opel_Vivaro_Text',
    },
    {
        f: '/sk/znacky-aut/opel/zafira',
        t: '/sk/findcar?makesModels=Opel%2CZafira&cmsid=Opel_Zafira_Text',
    },
    {
        f: '/sk/znacky-aut/opel',
        t: '/sk/findcar?makesModels=Opel&cmsid=Seo_Opel',
    },
    {
        f: '/sk/znacky-aut/peugeot/407sw',
        t: '/sk/findcar?makesModels=Peugeot%2C40x&cmsid=Peugeot_407sw_Text',
    },
    {
        f: '/sk/znacky-aut/peugeot/5008',
        t: '/sk/findcar?makesModels=Peugeot%2C5008&cmsid=Peugeot_5008_Text',
    },
    {
        f: '/sk/znacky-aut/peugeot/expert',
        t: '/sk/findcar?makesModels=Peugeot%2CExpert&cmsid=Peugeot_Expert_Text',
    },
    {
        f: '/sk/znacky-aut/peugeot/partner',
        t: '/sk/findcar?makesModels=Peugeot%2CPartner&cmsid=Peugeot_Partner_Text',
    },
    {
        f: '/sk/znacky-aut/peugeot',
        t: '/sk/findcar?makesModels=Peugeot&cmsid=Seo_Peugeot',
    },
    {
        f: '/sk/znacky-aut/piaggio',
        t: '/sk/findcar?makesModels=Piaggio&cmsid=SEO_Piaggio',
    },
    {
        f: '/sk/znacky-aut/renault/clio',
        t: '/sk/findcar?makesModels=Renault%2CClio&cmsid=Renault_Clio_Text',
    },
    {
        f: '/sk/znacky-aut/renault/espace',
        t: '/sk/findcar?makesModels=Renault%2CEspace&cmsid=Renault_Espace_Text',
    },
    {
        f: '/sk/znacky-aut/renault/kangoo',
        t: '/sk/findcar?makesModels=Renault%2CKangoo&cmsid=Renault_Kangoo_Text',
    },
    {
        f: '/sk/znacky-aut/renault/megane',
        t: '/sk/findcar?makesModels=Renault%2CMegane&cmsid=Renault_Megane_Text',
    },
    {
        f: '/sk/znacky-aut/renault/scenic',
        t: '/sk/findcar?makesModels=Renault%2CScenic&cmsid=Renault_Scenic_Text',
    },
    {
        f: '/sk/znacky-aut/renault',
        t: '/sk/findcar?makesModels=Renault&cmsid=SEO_Renault',
    },
    {
        f: '/sk/znacky-aut/skoda/fabia',
        t: '/sk/findcar?makesModels=Skoda%2CFabia&cmsid=Skoda_Fabia_Text',
    },
    {
        f: '/sk/znacky-aut/skoda/octavia',
        t: '/sk/findcar?makesModels=Skoda%2COctavia&cmsid=Skoda_Octavia_Text',
    },
    {
        f: '/sk/znacky-aut/skoda/rapid',
        t: '/sk/findcar?makesModels=Skoda%2CRapid&cmsid=Skoda_Rapid_Text',
    },
    {
        f: '/sk/znacky-aut/skoda/superb',
        t: '/sk/findcar?makesModels=Skoda%2CSuperb&cmsid=Skoda_Superb_Text',
    },
    {
        f: '/sk/znacky-aut/skoda/yeti',
        t: '/sk/findcar?makesModels=Skoda%2CYeti&cmsid=Skoda_Yeti_Text',
    },
    {
        f: '/sk/znacky-aut/skoda',
        t: '/sk/findcar?makesModels=Skoda&cmsid=SEO_Skoda',
    },
    {
        f: '/sk/znacky-aut/toyota/auris',
        t: '/sk/findcar?makesModels=Toyota%2CAuris&cmsid=Toyota_Auris_Text',
    },
    {
        f: '/sk/znacky-aut/toyota/avensis',
        t: '/sk/findcar?makesModels=Toyota%2CAvensis&cmsid=Toyota_Avensis_Text',
    },
    {
        f: '/sk/znacky-aut/toyota/aygo',
        t: '/sk/findcar?makesModels=Toyota%2CAygo&cmsid=Toyota_Aygo_Text',
    },
    {
        f: '/sk/znacky-aut/toyota/prius',
        t: '/sk/findcar?makesModels=Toyota%2CPrius&cmsid=Toyota_Prius_Text',
    },
    {
        f: '/sk/znacky-aut/toyota/rav4',
        t: '/sk/findcar?makesModels=Toyota%2CRav4&cmsid=Toyota_Rav4_Text',
    },
    {
        f: '/sk/znacky-aut/toyota/yaris',
        t: '/sk/findcar?makesModels=Toyota%2CYaris&cmsid=Toyota_Yaris_Text',
    },
    {
        f: '/sk/znacky-aut/toyota',
        t: '/sk/findcar?makesModels=Toyota&cmsid=SEO_Toyota',
    },
    {
        f: '/sk/znacky-aut/volkswagen/caddy',
        t: '/sk/findcar?makesModels=Volkswagen%2CCaddy&cmsid=Volkswagen_Caddy_Text',
    },
    {
        f: '/sk/znacky-aut/volkswagen/golf',
        t: '/sk/findcar?makesModels=Volkswagen%2CGolf&cmsid=Volkswagen_Golf_Text',
    },
    {
        f: '/sk/znacky-aut/volkswagen/passat',
        t: '/sk/findcar?makesModels=Volkswagen%2CPassat&cmsid=Volkswagen_Passat_Text',
    },
    {
        f: '/sk/znacky-aut/volkswagen/polo',
        t: '/sk/findcar?makesModels=Volkswagen%2CPolo&cmsid=Volkswagen_Polo_Text',
    },
    {
        f: '/sk/znacky-aut/volkswagen/tiguan',
        t: '/sk/findcar?makesModels=Volkswagen%2CTiguan&cmsid=Volkswagen_Tiguan_Text',
    },
    {
        f: '/sk/znacky-aut/volkswagen/touareg',
        t: '/sk/findcar?makesModels=Volkswagen%2CTouareg&cmsid=Volkswagen_Touareg_Text',
    },
    {
        f: '/sk/znacky-aut/volkswagen/touran',
        t: '/sk/findcar?makesModels=Volkswagen%2CTouran&cmsid=Volkswagen_Touran_Text',
    },
    {
        f: '/sk/znacky-aut/volkswagen/transporter',
        t: '/sk/findcar?makesModels=Volkswagen%2CTransporter&cmsid=Volkswagen_Transporter_Text',
    },
    {
        f: '/sk/znacky-aut/volkswagen',
        t: '/sk/findcar?makesModels=Volkswagen&cmsid=SEO_Volkswagen',
    },
    {
        f: '/sk/znacky-aut/volvo/s60',
        t: '/sk/findcar?makesModels=Volvo%2CS60&cmsid=Volvo_S60_Text',
    },
    {
        f: '/sk/znacky-aut/volvo/s80',
        t: '/sk/findcar?makesModels=Volvo%2CS80&cmsid=Volvo_S80_Text',
    },
    {
        f: '/sk/znacky-aut/volvo/s90',
        t: '/sk/findcar?makesModels=Volvo%2CS90&cmsid=Volvo_S90_Text',
    },
    {
        f: '/sk/znacky-aut/volvo/v40',
        t: '/sk/findcar?makesModels=Volvo%2CV40&cmsid=Volvo_V40_Text',
    },
    {
        f: '/sk/znacky-aut/volvo/v60',
        t: '/sk/findcar?makesModels=Volvo%2CV60&cmsid=Volvo_V60_Text',
    },
    {
        f: '/sk/znacky-aut/volvo/v90',
        t: '/sk/findcar?makesModels=Volvo%2CV90&cmsid=Volvo_V90_Text',
    },
    {
        f: '/sk/znacky-aut/volvo/xc60',
        t: '/sk/findcar?makesModels=Volvo%2CXC60&cmsid=Volvo_XC60_Text',
    },
    {
        f: '/sk/znacky-aut/volvo/xc90',
        t: '/sk/findcar?makesModels=Volvo%2CXC90&cmsid=Volvo_XC90_Text',
    },
    {
        f: '/sk/znacky-aut/volvo',
        t: '/sk/findcar?makesModels=Volvo&cmsid=SEO_Volvo',
    },
    {
        f: '/sk/ojazdene-auta-z-belgicka',
        t: '/sk/findcar?originCountries=be&cmsid=used-cars-belgium',
    },
    {
        f: '/sk/drazba-aut-nemecko',
        t: '/sk/findcar?originCountries=de&cmsid=used-cars-germany',
    },
    {
        f: '/sk/ojazdene-auta-zo-spanielska',
        t: '/sk/findcar?originCountries=es&cmsid=used-cars-spain',
    },
    {
        f: '/sk/ojazdene-auta-z-francuzska',
        t: '/sk/findcar?originCountries=fr&cmsid=used-cars-france',
    },
    {
        f: '/sk/ojazdene-auta-z-talianska',
        t: '/sk/findcar?originCountries=it&cmsid=used-cars-italy',
    },
    {
        f: '/sk/ojazdene-auta-z-luxembursko',
        t: '/sk/findcar?originCountries=lu&cmsid=used-cars-luxembourg',
    },
    {
        f: '/sk/burza-aut-holandsko',
        t: '/sk/findcar?originCountries=nl&cmsid=used-cars-holland',
    },
    {
        f: '/sk/ojazdene-auta-z-polsko',
        t: '/sk/findcar?originCountries=pl&cmsid=used-cars-poland',
    },
    {
        f: '/sk/ojazdene-auta-z-svedsko',
        t: '/sk/findcar?originCountries=se&cmsid=used-cars-sweden',
    },
    {
        f: '/sk/ceny-ojazdenych-aut/ojazdene-auta',
        t: '/sk/findcar?prices=0%2C3000&cmsid=cheap_used_cars',
    },
    {
        f: '/sk/ceny-ojazdenych-aut/auta-strednej-triedy',
        t: '/sk/findcar?prices=10000%2C20000&cmsid=Middle_class',
    },
    {
        f: '/sk/ceny-ojazdenych-aut/luxusne-auta',
        t: '/sk/findcar?prices=20000%2C0&cmsid=luxury_cars',
    },
    {
        f: '/sk/ceny-ojazdenych-aut/auta-s-nizkou-spotrebou',
        t: '/sk/findcar?prices=3000%2C6000&cmsid=economy_cars',
    },
    {
        f: '/sk/ceny-ojazdenych-aut/kvalitne-auta',
        t: '/sk/findcar?prices=6000%2C10000&cmsid=Value_for_money',
    },
    {
        f: '/sl/body-type/tovornjak',
        t: '/sl/findcar?bodyTypes=Truck&cmsid=Truck',
    },
    {
        f: '/sl/drazba-vozil/100-dodeljeno',
        t: '/sl/findcar?assignment100pc=true&cmsid=100assignment',
    },
    {
        f: '/sl/drazba-vozil/dinamicna',
        t: '/sl/findcar?auctionTypes=1&cmsid=dynamic',
    },
    {
        f: '/sl/drazba-vozil/kupi-zdaj',
        t: '/sl/findcar?auctionTypes=2&cmsid=buy-now',
    },
    { f: '/sl/drazba-vozil/slepa', t: '/sl/findcar?auctionTypes=4&cmsid=blind' },
    {
        f: '/sl/drazba-vozil/ciljna',
        t: '/sl/findcar?auctionTypes=5&cmsid=target',
    },
    {
        f: '/sl/body-type/berline',
        t: '/sl/findcar?bodyTypes=Berline&cmsid=SEO_Berline',
    },
    {
        f: '/sl/body-type/break',
        t: '/sl/findcar?bodyTypes=Break&cmsid=SEO_Break',
    },
    {
        f: '/sl/body-type/cabrio',
        t: '/sl/findcar?bodyTypes=Cabriolet&cmsid=SEO_Cabrio',
    },
    {
        f: '/sl/body-type/compact',
        t: '/sl/findcar?bodyTypes=Compact&cmsid=SEO_Compact',
    },
    {
        f: '/sl/body-type/coupe',
        t: '/sl/findcar?bodyTypes=Coup%C3%A9&cmsid=SEO_Coup%C3%A9',
    },
    {
        f: '/sl/body-type/hatchback',
        t: '/sl/findcar?bodyTypes=Hatchback&cmsid=SEO_Hatchback',
    },
    {
        f: '/sl/body-type/lahki-tovornjak',
        t: '/sl/findcar?bodyTypes=Lighttruck&cmsid=SEO_Light-truck',
    },
    {
        f: '/sl/body-type/minibus',
        t: '/sl/findcar?bodyTypes=Minibus&cmsid=Minibus',
    },
    {
        f: '/sl/body-type/motoristicna',
        t: '/sl/findcar?bodyTypes=Motorbike&cmsid=Motorbike',
    },
    { f: '/sl/body-type/mpv', t: '/sl/findcar?bodyTypes=MPV&cmsid=SEO_MPV' },
    { f: '/sl/body-type/suv', t: '/sl/findcar?bodyTypes=SUV&cmsid=SEO_SUV' },
    {
        f: '/sl/drazba-vozil/brez-poskodb',
        t: '/sl/findcar?damageTypes=1&cmsid=no_specific-Damages',
    },
    {
        f: '/sl/drazba-vozil/karambolirana-vozila',
        t: '/sl/findcar?damageTypes=2&cmsid=Body-damages',
    },
    {
        f: '/sl/drazba-vozil/vozila-s-tehnicnimi-poskodbami',
        t: '/sl/findcar?damageTypes=3&cmsid=technical-damages',
    },
    {
        f: '/sl/drazba-vozil/vozila-tehnicnimi-poskodbami-karoserije',
        t: '/sl/findcar?damageTypes=4&cmsid=Technical-and-body-damages',
    },
    {
        f: '/sl/avto-znamka/abarth',
        t: '/sl/findcar?makesModels=Abarth&cmsid=SEO_Abarth',
    },
    {
        f: '/sl/avto-znamka/alfa-romeo',
        t: '/sl/findcar?makesModels=Alfa%20Romeo&cmsid=SEO_alfa-romeo',
    },
    {
        f: '/sl/avto-znamka/audi/a1',
        t: '/sl/findcar?makesModels=Audi%2CA1&cmsid=Audi_A1_Text',
    },
    {
        f: '/sl/avto-znamka/audi/a2',
        t: '/sl/findcar?makesModels=Audi%2CA2&cmsid=Audi_A2_Text',
    },
    {
        f: '/sl/avto-znamka/audi/a3',
        t: '/sl/findcar?makesModels=Audi%2CA3&cmsid=Audi_A3_Text',
    },
    {
        f: '/sl/avto-znamka/audi/a4',
        t: '/sl/findcar?makesModels=Audi%2CA4&cmsid=Audi_A4_Text',
    },
    {
        f: '/sl/avto-znamka/audi/a5',
        t: '/sl/findcar?makesModels=Audi%2CA5&cmsid=Audi_A5_Text',
    },
    {
        f: '/sl/avto-znamka/audi/a6',
        t: '/sl/findcar?makesModels=Audi%2CA6&cmsid=Audi_A6_Text',
    },
    {
        f: '/sl/avto-znamka/audi/q3',
        t: '/sl/findcar?makesModels=Audi%2CQ3&cmsid=Audi_Q3_Text',
    },
    {
        f: '/sl/avto-znamka/audi/q5',
        t: '/sl/findcar?makesModels=Audi%2CQ5&cmsid=Audi_Q5_Text',
    },
    {
        f: '/sl/avto-znamka/audi/q7',
        t: '/sl/findcar?makesModels=Audi%2CQ7&cmsid=Audi_Q7_Text',
    },
    {
        f: '/sl/avto-znamka/audi',
        t: '/sl/findcar?makesModels=Audi&cmsid=SEO_Audi',
    },
    {
        f: '/sl/avto-znamka/bmw/serija-1',
        t: '/sl/findcar?makesModels=BMW%2C1%20Series&cmsid=BMW_1series_Text',
    },
    {
        f: '/sl/avto-znamka/bmw/serija-2',
        t: '/sl/findcar?makesModels=BMW%2C2%20Series&cmsid=BMW_2series_Text',
    },
    {
        f: '/sl/avto-znamka/bmw/serija-3',
        t: '/sl/findcar?makesModels=BMW%2C3%20Series&cmsid=BMW_3series_Text',
    },
    {
        f: '/sl/avto-znamka/bmw/serija-4',
        t: '/sl/findcar?makesModels=BMW%2C4%20Series&cmsid=BMW_4series_Text',
    },
    {
        f: '/sl/avto-znamka/bmw/serija-5',
        t: '/sl/findcar?makesModels=BMW%2C5%20Series&cmsid=BMW_5series_Text',
    },
    {
        f: '/sl/avto-znamka/bmw/x1',
        t: '/sl/findcar?makesModels=BMW%2CX1&cmsid=BMW_X1_Text',
    },
    {
        f: '/sl/avto-znamka/bmw/x3',
        t: '/sl/findcar?makesModels=BMW%2CX3&cmsid=BMW_X3_Text',
    },
    {
        f: '/sl/avto-znamka/bmw/x5',
        t: '/sl/findcar?makesModels=BMW%2CX5&cmsid=BMW_X5_Text',
    },
    {
        f: '/sl/avto-znamka/bmw/x6',
        t: '/sl/findcar?makesModels=BMW%2CX6&cmsid=BMW_X6_Text',
    },
    { f: '/sl/avto-znamka/bmw', t: '/sl/findcar?makesModels=BMW&cmsid=SEO_BMW' },
    {
        f: '/sl/avto-znamka/citroen/berlingo',
        t: '/sl/findcar?makesModels=Citro%C3%ABn%2CBerlingo&cmsid=Citro%C3%ABn_Berlingo_text',
    },
    {
        f: '/sl/avto-znamka/citroen/c3',
        t: '/sl/findcar?makesModels=Citro%C3%ABn%2CC3&cmsid=Citro%C3%ABn_C3_text',
    },
    {
        f: '/sl/avto-znamka/citroen/c4',
        t: '/sl/findcar?makesModels=Citro%C3%ABn%2CC4&cmsid=Citro%C3%ABn_C4_text',
    },
    {
        f: '/sl/avto-znamka/citroen/c5',
        t: '/sl/findcar?makesModels=Citro%C3%ABn%2CC5&cmsid=Citro%C3%ABn_C5_text',
    },
    {
        f: '/sl/avto-znamka/citroen/ds3',
        t: '/sl/findcar?makesModels=Citro%C3%ABn%2CDS3&cmsid=Citro%C3%ABn_DS3_text',
    },
    {
        f: '/sl/avto-znamka/citroen/ds4',
        t: '/sl/findcar?makesModels=Citro%C3%ABn%2CDS4&cmsid=Citro%C3%ABn_DS4_text',
    },
    {
        f: '/sl/avto-znamka/citroen/dispatch',
        t: '/sl/findcar?makesModels=Citro%C3%ABn%2CJumpy&cmsid=Citro%C3%ABn_Dispatch_text',
    },
    {
        f: '/sl/avto-znamka/citroen',
        t: '/sl/findcar?makesModels=Citro%C3%ABn&cmsid=SEO_Citro%C3%ABn',
    },
    {
        f: '/sl/avto-znamka/fiat/500',
        t: '/sl/findcar?makesModels=Fiat%2C500&cmsid=Fiat_500_Text',
    },
    {
        f: '/sl/avto-znamka/fiat/bravo',
        t: '/sl/findcar?makesModels=Fiat%2CBravo&cmsid=Fiat_bravo_Text',
    },
    {
        f: '/sl/avto-znamka/fiat/doblo',
        t: '/sl/findcar?makesModels=Fiat%2CDoblo&cmsid=Fiat_doblo_Text',
    },
    {
        f: '/sl/avto-znamka/fiat/ducato',
        t: '/sl/findcar?makesModels=Fiat%2CDucato&cmsid=Fiat_ducato_Text',
    },
    {
        f: '/sl/avto-znamka/fiat/freemont',
        t: '/sl/findcar?makesModels=Fiat%2CFreemont&cmsid=Fiat_freemont_Text',
    },
    {
        f: '/sl/avto-znamka/fiat/panda',
        t: '/sl/findcar?makesModels=Fiat%2CPanda&cmsid=Fiat_panda_Text',
    },
    {
        f: '/sl/avto-znamka/fiat/punto',
        t: '/sl/findcar?makesModels=Fiat%2CPunto&cmsid=Fiat_punto_Text',
    },
    {
        f: '/sl/avto-znamka/fiat',
        t: '/sl/findcar?makesModels=Fiat&cmsid=SEO_Fiat',
    },
    {
        f: '/sl/avto-znamka/ford/fiesta',
        t: '/sl/findcar?makesModels=Ford%2CFiesta&cmsid=Ford_Fiesta_Text',
    },
    {
        f: '/sl/avto-znamka/ford/focus',
        t: '/sl/findcar?makesModels=Ford%2CFocus&cmsid=Ford_Focus_Text',
    },
    {
        f: '/sl/avto-znamka/ford/mondeo',
        t: '/sl/findcar?makesModels=Ford%2CMondeo&cmsid=Ford_Mondeo_Text',
    },
    {
        f: '/sl/avto-znamka/ford/transit',
        t: '/sl/findcar?makesModels=Ford%2CTransit&cmsid=Ford_Transit_Text',
    },
    {
        f: '/sl/avto-znamka/ford',
        t: '/sl/findcar?makesModels=Ford&cmsid=SEO_Ford',
    },
    {
        f: '/sl/avto-znamka/lancia',
        t: '/sl/findcar?makesModels=Lancia&cmsid=SEO_Lancia',
    },
    {
        f: '/sl/avto-znamka/mercedes/a-razred',
        t: '/sl/findcar?makesModels=Mercedes-Benz%2CA&cmsid=Mercedes_A-class_Text',
    },
    {
        f: '/sl/avto-znamka/mercedes/b-razred',
        t: '/sl/findcar?makesModels=Mercedes-Benz%2CB&cmsid=Mercedes_B-class_Text',
    },
    {
        f: '/sl/avto-znamka/mercedes/c-razred',
        t: '/sl/findcar?makesModels=Mercedes-Benz%2CC&cmsid=Mercedes_C-class_Text',
    },
    {
        f: '/sl/avto-znamka/mercedes/e-razred',
        t: '/sl/findcar?makesModels=Mercedes-Benz%2CE&cmsid=Mercedes_E-class_Text',
    },
    {
        f: '/sl/avto-znamka/mercedes/sprinter',
        t: '/sl/findcar?makesModels=Mercedes-Benz%2CSprinter&cmsid=Mercedes_Sprinter_Text',
    },
    {
        f: '/sl/avto-znamka/mercedes/vito',
        t: '/sl/findcar?makesModels=Mercedes-Benz%2CVito&cmsid=Mercedes_Vito_Text',
    },
    {
        f: '/sl/avto-znamka/mercedes',
        t: '/sl/findcar?makesModels=Mercedes-Benz&cmsid=SEO_Mercedes',
    },
    {
        f: '/sl/avto-znamka/nissan/juke',
        t: '/sl/findcar?makesModels=Nissan%2CJuke&cmsid=Nissan_Juke_Text',
    },
    {
        f: '/sl/avto-znamka/nissan/micra',
        t: '/sl/findcar?makesModels=Nissan%2CMicra&cmsid=Nissan_micra_Text',
    },
    {
        f: '/sl/avto-znamka/nissan/pathfinder',
        t: '/sl/findcar?makesModels=Nissan%2CPathfinder&cmsid=Nissan_Pathfinder_Text',
    },
    {
        f: '/sl/avto-znamka/nissan/qashqai',
        t: '/sl/findcar?makesModels=Nissan%2CQashqai&cmsid=Nissan_Qashqai_Text',
    },
    {
        f: '/sl/avto-znamka/nissan/x-trail',
        t: '/sl/findcar?makesModels=Nissan%2CX-Trail&cmsid=Nissan_x-trail_Text',
    },
    {
        f: '/sl/avto-znamka/nissan',
        t: '/sl/findcar?makesModels=Nissan&cmsid=SEO_Nissan',
    },
    {
        f: '/sl/avto-znamka/opel/astra',
        t: '/sl/findcar?makesModels=Opel%2CAstra&cmsid=Opel_Astra_Text',
    },
    {
        f: '/sl/avto-znamka/opel/combo',
        t: '/sl/findcar?makesModels=Opel%2CCombo&cmsid=Opel_Combo_Text',
    },
    {
        f: '/sl/avto-znamka/opel/corsa',
        t: '/sl/findcar?makesModels=Opel%2CCorsa&cmsid=Opel_Corsa_Text',
    },
    {
        f: '/sl/avto-znamka/opel/insignia',
        t: '/sl/findcar?makesModels=Opel%2CInsignia&cmsid=Opel_Insignia_Text',
    },
    {
        f: '/sl/avto-znamka/opel/meriva',
        t: '/sl/findcar?makesModels=Opel%2CMeriva&cmsid=Opel_Meriva_Text',
    },
    {
        f: '/sl/avto-znamka/opel/movano',
        t: '/sl/findcar?makesModels=Opel%2CMovano&cmsid=Opel_Movano_Text',
    },
    {
        f: '/sl/avto-znamka/opel/vivaro',
        t: '/sl/findcar?makesModels=Opel%2CVivaro&cmsid=Opel_Vivaro_Text',
    },
    {
        f: '/sl/avto-znamka/opel/zafira',
        t: '/sl/findcar?makesModels=Opel%2CZafira&cmsid=Opel_Zafira_Text',
    },
    {
        f: '/sl/avto-znamka/opel',
        t: '/sl/findcar?makesModels=Opel&cmsid=Seo_Opel',
    },
    {
        f: '/sl/avto-znamka/peugeot/407sw',
        t: '/sl/findcar?makesModels=Peugeot%2C40x&cmsid=Peugeot_407sw_Text',
    },
    {
        f: '/sl/avto-znamka/peugeot/5008',
        t: '/sl/findcar?makesModels=Peugeot%2C5008&cmsid=Peugeot_5008_Text',
    },
    {
        f: '/sl/avto-znamka/peugeot/expert',
        t: '/sl/findcar?makesModels=Peugeot%2CExpert&cmsid=Peugeot_Expert_Text',
    },
    {
        f: '/sl/avto-znamka/peugeot/partner',
        t: '/sl/findcar?makesModels=Peugeot%2CPartner&cmsid=Peugeot_Partner_Text',
    },
    {
        f: '/sl/avto-znamka/peugeot',
        t: '/sl/findcar?makesModels=Peugeot&cmsid=Seo_Peugeot',
    },
    {
        f: '/sl/avto-znamka/piaggio',
        t: '/sl/findcar?makesModels=Piaggio&cmsid=SEO_Piaggio',
    },
    {
        f: '/sl/avto-znamka/renault/clio',
        t: '/sl/findcar?makesModels=Renault%2CClio&cmsid=Renault_Clio_Text',
    },
    {
        f: '/sl/avto-znamka/renault/espace',
        t: '/sl/findcar?makesModels=Renault%2CEspace&cmsid=Renault_Espace_Text',
    },
    {
        f: '/sl/avto-znamka/renault/kangoo',
        t: '/sl/findcar?makesModels=Renault%2CKangoo&cmsid=Renault_Kangoo_Text',
    },
    {
        f: '/sl/avto-znamka/renault/megane',
        t: '/sl/findcar?makesModels=Renault%2CMegane&cmsid=Renault_Megane_Text',
    },
    {
        f: '/sl/avto-znamka/renault/scenic',
        t: '/sl/findcar?makesModels=Renault%2CScenic&cmsid=Renault_Scenic_Text',
    },
    {
        f: '/sl/avto-znamka/renault',
        t: '/sl/findcar?makesModels=Renault&cmsid=SEO_Renault',
    },
    {
        f: '/sl/avto-znamka/skoda/fabia',
        t: '/sl/findcar?makesModels=Skoda%2CFabia&cmsid=Skoda_Fabia_Text',
    },
    {
        f: '/sl/avto-znamka/skoda/octavia',
        t: '/sl/findcar?makesModels=Skoda%2COctavia&cmsid=Skoda_Octavia_Text',
    },
    {
        f: '/sl/avto-znamka/skoda/rapid',
        t: '/sl/findcar?makesModels=Skoda%2CRapid&cmsid=Skoda_Rapid_Text',
    },
    {
        f: '/sl/avto-znamka/skoda/superb',
        t: '/sl/findcar?makesModels=Skoda%2CSuperb&cmsid=Skoda_Superb_Text',
    },
    {
        f: '/sl/avto-znamka/skoda/yeti',
        t: '/sl/findcar?makesModels=Skoda%2CYeti&cmsid=Skoda_Yeti_Text',
    },
    {
        f: '/sl/avto-znamka/skoda',
        t: '/sl/findcar?makesModels=Skoda&cmsid=SEO_Skoda',
    },
    {
        f: '/sl/avto-znamka/toyota/auris',
        t: '/sl/findcar?makesModels=Toyota%2CAuris&cmsid=Toyota_Auris_Text',
    },
    {
        f: '/sl/avto-znamka/toyota/avensis',
        t: '/sl/findcar?makesModels=Toyota%2CAvensis&cmsid=Toyota_Avensis_Text',
    },
    {
        f: '/sl/avto-znamka/toyota/aygo',
        t: '/sl/findcar?makesModels=Toyota%2CAygo&cmsid=Toyota_Aygo_Text',
    },
    {
        f: '/sl/avto-znamka/toyota/prius',
        t: '/sl/findcar?makesModels=Toyota%2CPrius&cmsid=Toyota_Prius_Text',
    },
    {
        f: '/sl/avto-znamka/toyota/rav4',
        t: '/sl/findcar?makesModels=Toyota%2CRav4&cmsid=Toyota_Rav4_Text',
    },
    {
        f: '/sl/avto-znamka/toyota/yaris',
        t: '/sl/findcar?makesModels=Toyota%2CYaris&cmsid=Toyota_Yaris_Text',
    },
    {
        f: '/sl/avto-znamka/toyota',
        t: '/sl/findcar?makesModels=Toyota&cmsid=SEO_Toyota',
    },
    {
        f: '/sl/avto-znamka/volkswagen/caddy',
        t: '/sl/findcar?makesModels=Volkswagen%2CCaddy&cmsid=Volkswagen_Caddy_Text',
    },
    {
        f: '/sl/avto-znamka/volkswagen/golf',
        t: '/sl/findcar?makesModels=Volkswagen%2CGolf&cmsid=Volkswagen_Golf_Text',
    },
    {
        f: '/sl/avto-znamka/volkswagen/passat',
        t: '/sl/findcar?makesModels=Volkswagen%2CPassat&cmsid=Volkswagen_Passat_Text',
    },
    {
        f: '/sl/avto-znamka/volkswagen/polo',
        t: '/sl/findcar?makesModels=Volkswagen%2CPolo&cmsid=Volkswagen_Polo_Text',
    },
    {
        f: '/sl/avto-znamka/volkswagen/tiguan',
        t: '/sl/findcar?makesModels=Volkswagen%2CTiguan&cmsid=Volkswagen_Tiguan_Text',
    },
    {
        f: '/sl/avto-znamka/volkswagen/touareg',
        t: '/sl/findcar?makesModels=Volkswagen%2CTouareg&cmsid=Volkswagen_Touareg_Text',
    },
    {
        f: '/sl/avto-znamka/volkswagen/touran',
        t: '/sl/findcar?makesModels=Volkswagen%2CTouran&cmsid=Volkswagen_Touran_Text',
    },
    {
        f: '/sl/avto-znamka/volkswagen/transporter',
        t: '/sl/findcar?makesModels=Volkswagen%2CTransporter&cmsid=Volkswagen_Transporter_Text',
    },
    {
        f: '/sl/avto-znamka/volkswagen',
        t: '/sl/findcar?makesModels=Volkswagen&cmsid=SEO_Volkswagen',
    },
    {
        f: '/sl/avto-znamka/volvo/s60',
        t: '/sl/findcar?makesModels=Volvo%2CS60&cmsid=Volvo_S60_Text',
    },
    {
        f: '/sl/avto-znamka/volvo/s80',
        t: '/sl/findcar?makesModels=Volvo%2CS80&cmsid=Volvo_S80_Text',
    },
    {
        f: '/sl/avto-znamka/volvo/s90',
        t: '/sl/findcar?makesModels=Volvo%2CS90&cmsid=Volvo_S90_Text',
    },
    {
        f: '/sl/avto-znamka/volvo/v40',
        t: '/sl/findcar?makesModels=Volvo%2CV40&cmsid=Volvo_V40_Text',
    },
    {
        f: '/sl/avto-znamka/volvo/v60',
        t: '/sl/findcar?makesModels=Volvo%2CV60&cmsid=Volvo_V60_Text',
    },
    {
        f: '/sl/avto-znamka/volvo/v90',
        t: '/sl/findcar?makesModels=Volvo%2CV90&cmsid=Volvo_V90_Text',
    },
    {
        f: '/sl/avto-znamka/volvo/xc60',
        t: '/sl/findcar?makesModels=Volvo%2CXC60&cmsid=Volvo_XC60_Text',
    },
    {
        f: '/sl/avto-znamka/volvo/xc90',
        t: '/sl/findcar?makesModels=Volvo%2CXC90&cmsid=Volvo_XC90_Text',
    },
    {
        f: '/sl/avto-znamka/volvo',
        t: '/sl/findcar?makesModels=Volvo&cmsid=SEO_Volvo',
    },
    {
        f: '/sl/rabljeni-avtomobili-belgija',
        t: '/sl/findcar?originCountries=be&cmsid=used-cars-belgium',
    },
    {
        f: '/sl/rabljeni-avtomobili-nemcija',
        t: '/sl/findcar?originCountries=de&cmsid=used-cars-germany',
    },
    {
        f: '/sl/rabljeni-avtomobili-spanija',
        t: '/sl/findcar?originCountries=es&cmsid=used-cars-spain',
    },
    {
        f: '/sl/rabljeni-avtomobili-francija',
        t: '/sl/findcar?originCountries=fr&cmsid=used-cars-france',
    },
    {
        f: '/sl/rabljeni-avtomobili-italija',
        t: '/sl/findcar?originCountries=it&cmsid=used-cars-italy',
    },
    {
        f: '/sl/rabljeni-avtomobili-luksemburg',
        t: '/sl/findcar?originCountries=lu&cmsid=used-cars-luxembourg',
    },
    {
        f: '/sl/rabljeni-avtomobili-nizozemska',
        t: '/sl/findcar?originCountries=nl&cmsid=used-cars-holland',
    },
    {
        f: '/sl/rabljeni-avtomobili-poljskem',
        t: '/sl/findcar?originCountries=pl&cmsid=used-cars-poland',
    },
    {
        f: '/sl/rabljeni-avtomobili-svedska',
        t: '/sl/findcar?originCountries=se&cmsid=used-cars-sweden',
    },
    {
        f: '/sl/cene-rabljenih-vozil/poceni-rabljena-vozila',
        t: '/sl/findcar?prices=0%2C3000&cmsid=cheap_used_cars',
    },
    {
        f: '/sl/cene-rabljenih-vozil/middle-class',
        t: '/sl/findcar?prices=10000%2C20000&cmsid=Middle_class',
    },
    {
        f: '/sl/cene-rabljenih-vozil/luxury-cars',
        t: '/sl/findcar?prices=20000%2C0&cmsid=luxury_cars',
    },
    {
        f: '/sl/cene-rabljenih-vozil/economy-cars',
        t: '/sl/findcar?prices=3000%2C6000&cmsid=economy_cars',
    },
    {
        f: '/sl/cene-rabljenih-vozil/value-for-money',
        t: '/sl/findcar?prices=6000%2C10000&cmsid=Value_for_money',
    },
    { f: '/tr/body-type/kamyon', t: '/tr/findcar?bodyTypes=Truck&cmsid=Truck' },
    {
        f: '/tr/car-auctions/guaranteed-assignment',
        t: '/tr/findcar?assignment100pc=true&cmsid=100assignment',
    },
    {
        f: '/tr/car-auctions/dinamik',
        t: '/tr/findcar?auctionTypes=1&cmsid=dynamic',
    },
    {
        f: '/tr/car-auctions/hemen-al',
        t: '/tr/findcar?auctionTypes=2&cmsid=buy-now',
    },
    { f: '/tr/car-auctions/gizli', t: '/tr/findcar?auctionTypes=4&cmsid=blind' },
    {
        f: '/tr/car-auctions/hedefli',
        t: '/tr/findcar?auctionTypes=5&cmsid=target',
    },
    {
        f: '/tr/body-type/sedan',
        t: '/tr/findcar?bodyTypes=Berline&cmsid=SEO_Berline',
    },
    {
        f: '/tr/body-type/station-wagen',
        t: '/tr/findcar?bodyTypes=Break&cmsid=SEO_Break',
    },
    {
        f: '/tr/body-type/cabrio',
        t: '/tr/findcar?bodyTypes=Cabriolet&cmsid=SEO_Cabrio',
    },
    {
        f: '/tr/body-type/kompakt',
        t: '/tr/findcar?bodyTypes=Compact&cmsid=SEO_Compact',
    },
    {
        f: '/tr/body-type/coupe',
        t: '/tr/findcar?bodyTypes=Coup%C3%A9&cmsid=SEO_Coup%C3%A9',
    },
    {
        f: '/tr/body-type/hatchback',
        t: '/tr/findcar?bodyTypes=Hatchback&cmsid=SEO_Hatchback',
    },
    {
        f: '/tr/body-type/kamyonet',
        t: '/tr/findcar?bodyTypes=Lighttruck&cmsid=SEO_Light-truck',
    },
    {
        f: '/tr/body-type/minibus',
        t: '/tr/findcar?bodyTypes=Minibus&cmsid=Minibus',
    },
    {
        f: '/tr/body-type/motosiklet',
        t: '/tr/findcar?bodyTypes=Motorbike&cmsid=Motorbike',
    },
    { f: '/tr/body-type/mpv', t: '/tr/findcar?bodyTypes=MPV&cmsid=SEO_MPV' },
    { f: '/tr/body-type/suv', t: '/tr/findcar?bodyTypes=SUV&cmsid=SEO_SUV' },
    {
        f: '/tr/car-auctions/hasarsiz',
        t: '/tr/findcar?damageTypes=1&cmsid=no_specific-Damages',
    },
    {
        f: '/tr/car-auctions/kaza-yapmis-otomobiller',
        t: '/tr/findcar?damageTypes=2&cmsid=Body-damages',
    },
    {
        f: '/tr/car-auctions/teknik-hasarli-otomobiller',
        t: '/tr/findcar?damageTypes=3&cmsid=technical-damages',
    },
    {
        f: '/tr/car-auctions/teknik-ve-govde-hasarli-otomobiller',
        t: '/tr/findcar?damageTypes=4&cmsid=Technical-and-body-damages',
    },
    {
        f: '/tr/araba-markalari/abarth',
        t: '/tr/findcar?makesModels=Abarth&cmsid=SEO_Abarth',
    },
    {
        f: '/tr/araba-markalari/alfa-romeo',
        t: '/tr/findcar?makesModels=Alfa%20Romeo&cmsid=SEO_alfa-romeo',
    },
    {
        f: '/tr/araba-markalari/audi/a1',
        t: '/tr/findcar?makesModels=Audi%2CA1&cmsid=Audi_A1_Text',
    },
    {
        f: '/tr/araba-markalari/audi/a2',
        t: '/tr/findcar?makesModels=Audi%2CA2&cmsid=Audi_A2_Text',
    },
    {
        f: '/tr/araba-markalari/audi/a3',
        t: '/tr/findcar?makesModels=Audi%2CA3&cmsid=Audi_A3_Text',
    },
    {
        f: '/tr/araba-markalari/audi/a4',
        t: '/tr/findcar?makesModels=Audi%2CA4&cmsid=Audi_A4_Text',
    },
    {
        f: '/tr/araba-markalari/audi/a5',
        t: '/tr/findcar?makesModels=Audi%2CA5&cmsid=Audi_A5_Text',
    },
    {
        f: '/tr/araba-markalari/audi/a6',
        t: '/tr/findcar?makesModels=Audi%2CA6&cmsid=Audi_A6_Text',
    },
    {
        f: '/tr/araba-markalari/audi/q3',
        t: '/tr/findcar?makesModels=Audi%2CQ3&cmsid=Audi_Q3_Text',
    },
    {
        f: '/tr/araba-markalari/audi/q5',
        t: '/tr/findcar?makesModels=Audi%2CQ5&cmsid=Audi_Q5_Text',
    },
    {
        f: '/tr/araba-markalari/audi/q7',
        t: '/tr/findcar?makesModels=Audi%2CQ7&cmsid=Audi_Q7_Text',
    },
    {
        f: '/tr/araba-markalari/audi',
        t: '/tr/findcar?makesModels=Audi&cmsid=SEO_Audi',
    },
    {
        f: '/tr/araba-markalari/bmw/1-serisi',
        t: '/tr/findcar?makesModels=BMW%2C1%20Series&cmsid=BMW_1series_Text',
    },
    {
        f: '/tr/araba-markalari/bmw/2-serisi',
        t: '/tr/findcar?makesModels=BMW%2C2%20Series&cmsid=BMW_2series_Text',
    },
    {
        f: '/tr/araba-markalari/bmw/3-serisi',
        t: '/tr/findcar?makesModels=BMW%2C3%20Series&cmsid=BMW_3series_Text',
    },
    {
        f: '/tr/araba-markalari/bmw/4-serisi',
        t: '/tr/findcar?makesModels=BMW%2C4%20Series&cmsid=BMW_4series_Text',
    },
    {
        f: '/tr/araba-markalari/bmw/5-serisi',
        t: '/tr/findcar?makesModels=BMW%2C5%20Series&cmsid=BMW_5series_Text',
    },
    {
        f: '/tr/araba-markalari/bmw/x1',
        t: '/tr/findcar?makesModels=BMW%2CX1&cmsid=BMW_X1_Text',
    },
    {
        f: '/tr/araba-markalari/bmw/x3',
        t: '/tr/findcar?makesModels=BMW%2CX3&cmsid=BMW_X3_Text',
    },
    {
        f: '/tr/araba-markalari/bmw/x5',
        t: '/tr/findcar?makesModels=BMW%2CX5&cmsid=BMW_X5_Text',
    },
    {
        f: '/tr/araba-markalari/bmw/x6',
        t: '/tr/findcar?makesModels=BMW%2CX6&cmsid=BMW_X6_Text',
    },
    {
        f: '/tr/araba-markalari/bmw',
        t: '/tr/findcar?makesModels=BMW&cmsid=SEO_BMW',
    },
    {
        f: '/tr/araba-markalari/citroen/berlingo',
        t: '/tr/findcar?makesModels=Citro%C3%ABn%2CBerlingo&cmsid=Citro%C3%ABn_Berlingo_text',
    },
    {
        f: '/tr/araba-markalari/citroen/c3',
        t: '/tr/findcar?makesModels=Citro%C3%ABn%2CC3&cmsid=Citro%C3%ABn_C3_text',
    },
    {
        f: '/tr/araba-markalari/citroen/c4',
        t: '/tr/findcar?makesModels=Citro%C3%ABn%2CC4&cmsid=Citro%C3%ABn_C4_text',
    },
    {
        f: '/tr/araba-markalari/citroen/c5',
        t: '/tr/findcar?makesModels=Citro%C3%ABn%2CC5&cmsid=Citro%C3%ABn_C5_text',
    },
    {
        f: '/tr/araba-markalari/citroen/ds3',
        t: '/tr/findcar?makesModels=Citro%C3%ABn%2CDS3&cmsid=Citro%C3%ABn_DS3_text',
    },
    {
        f: '/tr/araba-markalari/citroen/ds4',
        t: '/tr/findcar?makesModels=Citro%C3%ABn%2CDS4&cmsid=Citro%C3%ABn_DS4_text',
    },
    {
        f: '/tr/araba-markalari/citroen/dispatch',
        t: '/tr/findcar?makesModels=Citro%C3%ABn%2CJumpy&cmsid=Citro%C3%ABn_Dispatch_text',
    },
    {
        f: '/tr/araba-markalari/citroen',
        t: '/tr/findcar?makesModels=Citro%C3%ABn&cmsid=SEO_Citro%C3%ABn',
    },
    {
        f: '/tr/araba-markalari/fiat/500',
        t: '/tr/findcar?makesModels=Fiat%2C500&cmsid=Fiat_500_Text',
    },
    {
        f: '/tr/araba-markalari/fiat/bravo',
        t: '/tr/findcar?makesModels=Fiat%2CBravo&cmsid=Fiat_bravo_Text',
    },
    {
        f: '/tr/araba-markalari/fiat/doblo',
        t: '/tr/findcar?makesModels=Fiat%2CDoblo&cmsid=Fiat_doblo_Text',
    },
    {
        f: '/tr/araba-markalari/fiat/ducato',
        t: '/tr/findcar?makesModels=Fiat%2CDucato&cmsid=Fiat_ducato_Text',
    },
    {
        f: '/tr/araba-markalari/fiat/freemont',
        t: '/tr/findcar?makesModels=Fiat%2CFreemont&cmsid=Fiat_freemont_Text',
    },
    {
        f: '/tr/araba-markalari/fiat/panda',
        t: '/tr/findcar?makesModels=Fiat%2CPanda&cmsid=Fiat_panda_Text',
    },
    {
        f: '/tr/araba-markalari/fiat/punto',
        t: '/tr/findcar?makesModels=Fiat%2CPunto&cmsid=Fiat_punto_Text',
    },
    {
        f: '/tr/araba-markalari/fiat',
        t: '/tr/findcar?makesModels=Fiat&cmsid=SEO_Fiat',
    },
    {
        f: '/tr/araba-markalari/ford/fiesta',
        t: '/tr/findcar?makesModels=Ford%2CFiesta&cmsid=Ford_Fiesta_Text',
    },
    {
        f: '/tr/araba-markalari/ford/focus',
        t: '/tr/findcar?makesModels=Ford%2CFocus&cmsid=Ford_Focus_Text',
    },
    {
        f: '/tr/araba-markalari/ford/mondeo',
        t: '/tr/findcar?makesModels=Ford%2CMondeo&cmsid=Ford_Mondeo_Text',
    },
    {
        f: '/tr/araba-markalari/ford/transit',
        t: '/tr/findcar?makesModels=Ford%2CTransit&cmsid=Ford_Transit_Text',
    },
    {
        f: '/tr/araba-markalari/ford',
        t: '/tr/findcar?makesModels=Ford&cmsid=SEO_Ford',
    },
    {
        f: '/tr/araba-markalari/lancia',
        t: '/tr/findcar?makesModels=Lancia&cmsid=SEO_Lancia',
    },
    {
        f: '/tr/araba-markalari/mercedes/a-serisi',
        t: '/tr/findcar?makesModels=Mercedes-Benz%2CA&cmsid=Mercedes_A-class_Text',
    },
    {
        f: '/tr/araba-markalari/mercedes/b-serisi',
        t: '/tr/findcar?makesModels=Mercedes-Benz%2CB&cmsid=Mercedes_B-class_Text',
    },
    {
        f: '/tr/araba-markalari/mercedes/c-serisi',
        t: '/tr/findcar?makesModels=Mercedes-Benz%2CC&cmsid=Mercedes_C-class_Text',
    },
    {
        f: '/tr/araba-markalari/mercedes/e-serisi',
        t: '/tr/findcar?makesModels=Mercedes-Benz%2CE&cmsid=Mercedes_E-class_Text',
    },
    {
        f: '/tr/araba-markalari/mercedes/sprinter',
        t: '/tr/findcar?makesModels=Mercedes-Benz%2CSprinter&cmsid=Mercedes_Sprinter_Text',
    },
    {
        f: '/tr/araba-markalari/mercedes/vito',
        t: '/tr/findcar?makesModels=Mercedes-Benz%2CVito&cmsid=Mercedes_Vito_Text',
    },
    {
        f: '/tr/araba-markalari/mercedes',
        t: '/tr/findcar?makesModels=Mercedes-Benz&cmsid=SEO_Mercedes',
    },
    {
        f: '/tr/araba-markalari/nissan/juke',
        t: '/tr/findcar?makesModels=Nissan%2CJuke&cmsid=Nissan_Juke_Text',
    },
    {
        f: '/tr/araba-markalari/nissan/micra',
        t: '/tr/findcar?makesModels=Nissan%2CMicra&cmsid=Nissan_micra_Text',
    },
    {
        f: '/tr/araba-markalari/nissan/pathfinder',
        t: '/tr/findcar?makesModels=Nissan%2CPathfinder&cmsid=Nissan_Pathfinder_Text',
    },
    {
        f: '/tr/araba-markalari/nissan/qashqai',
        t: '/tr/findcar?makesModels=Nissan%2CQashqai&cmsid=Nissan_Qashqai_Text',
    },
    {
        f: '/tr/araba-markalari/nissan/x-trail',
        t: '/tr/findcar?makesModels=Nissan%2CX-Trail&cmsid=Nissan_x-trail_Text',
    },
    {
        f: '/tr/araba-markalari/nissan',
        t: '/tr/findcar?makesModels=Nissan&cmsid=SEO_Nissan',
    },
    {
        f: '/tr/araba-markalari/opel/astra',
        t: '/tr/findcar?makesModels=Opel%2CAstra&cmsid=Opel_Astra_Text',
    },
    {
        f: '/tr/araba-markalari/opel/combo',
        t: '/tr/findcar?makesModels=Opel%2CCombo&cmsid=Opel_Combo_Text',
    },
    {
        f: '/tr/araba-markalari/opel/corsa',
        t: '/tr/findcar?makesModels=Opel%2CCorsa&cmsid=Opel_Corsa_Text',
    },
    {
        f: '/tr/araba-markalari/opel/insignia',
        t: '/tr/findcar?makesModels=Opel%2CInsignia&cmsid=Opel_Insignia_Text',
    },
    {
        f: '/tr/araba-markalari/opel/meriva',
        t: '/tr/findcar?makesModels=Opel%2CMeriva&cmsid=Opel_Meriva_Text',
    },
    {
        f: '/tr/araba-markalari/opel/movano',
        t: '/tr/findcar?makesModels=Opel%2CMovano&cmsid=Opel_Movano_Text',
    },
    {
        f: '/tr/araba-markalari/opel/vivaro',
        t: '/tr/findcar?makesModels=Opel%2CVivaro&cmsid=Opel_Vivaro_Text',
    },
    {
        f: '/tr/araba-markalari/opel/zafira',
        t: '/tr/findcar?makesModels=Opel%2CZafira&cmsid=Opel_Zafira_Text',
    },
    {
        f: '/tr/araba-markalari/opel',
        t: '/tr/findcar?makesModels=Opel&cmsid=Seo_Opel',
    },
    {
        f: '/tr/araba-markalari/peugeot/407sw',
        t: '/tr/findcar?makesModels=Peugeot%2C40x&cmsid=Peugeot_407sw_Text',
    },
    {
        f: '/tr/araba-markalari/peugeot/5008',
        t: '/tr/findcar?makesModels=Peugeot%2C5008&cmsid=Peugeot_5008_Text',
    },
    {
        f: '/tr/araba-markalari/peugeot/expert',
        t: '/tr/findcar?makesModels=Peugeot%2CExpert&cmsid=Peugeot_Expert_Text',
    },
    {
        f: '/tr/araba-markalari/peugeot/partner',
        t: '/tr/findcar?makesModels=Peugeot%2CPartner&cmsid=Peugeot_Partner_Text',
    },
    {
        f: '/tr/araba-markalari/peugeot',
        t: '/tr/findcar?makesModels=Peugeot&cmsid=Seo_Peugeot',
    },
    {
        f: '/tr/araba-markalari/piaggio',
        t: '/tr/findcar?makesModels=Piaggio&cmsid=SEO_Piaggio',
    },
    {
        f: '/tr/araba-markalari/renault/clio',
        t: '/tr/findcar?makesModels=Renault%2CClio&cmsid=Renault_Clio_Text',
    },
    {
        f: '/tr/araba-markalari/renault/espace',
        t: '/tr/findcar?makesModels=Renault%2CEspace&cmsid=Renault_Espace_Text',
    },
    {
        f: '/tr/araba-markalari/renault/kangoo',
        t: '/tr/findcar?makesModels=Renault%2CKangoo&cmsid=Renault_Kangoo_Text',
    },
    {
        f: '/tr/araba-markalari/renault/megane',
        t: '/tr/findcar?makesModels=Renault%2CMegane&cmsid=Renault_Megane_Text',
    },
    {
        f: '/tr/araba-markalari/renault/scenic',
        t: '/tr/findcar?makesModels=Renault%2CScenic&cmsid=Renault_Scenic_Text',
    },
    {
        f: '/tr/araba-markalari/renault',
        t: '/tr/findcar?makesModels=Renault&cmsid=SEO_Renault',
    },
    {
        f: '/tr/araba-markalari/skoda/fabia',
        t: '/tr/findcar?makesModels=Skoda%2CFabia&cmsid=Skoda_Fabia_Text',
    },
    {
        f: '/tr/araba-markalari/skoda/octavia',
        t: '/tr/findcar?makesModels=Skoda%2COctavia&cmsid=Skoda_Octavia_Text',
    },
    {
        f: '/tr/araba-markalari/skoda/rapid',
        t: '/tr/findcar?makesModels=Skoda%2CRapid&cmsid=Skoda_Rapid_Text',
    },
    {
        f: '/tr/araba-markalari/skoda/superb',
        t: '/tr/findcar?makesModels=Skoda%2CSuperb&cmsid=Skoda_Superb_Text',
    },
    {
        f: '/tr/araba-markalari/skoda/yeti',
        t: '/tr/findcar?makesModels=Skoda%2CYeti&cmsid=Skoda_Yeti_Text',
    },
    {
        f: '/tr/araba-markalari/skoda',
        t: '/tr/findcar?makesModels=Skoda&cmsid=SEO_Skoda',
    },
    {
        f: '/tr/araba-markalari/toyota/auris',
        t: '/tr/findcar?makesModels=Toyota%2CAuris&cmsid=Toyota_Auris_Text',
    },
    {
        f: '/tr/araba-markalari/toyota/avensis',
        t: '/tr/findcar?makesModels=Toyota%2CAvensis&cmsid=Toyota_Avensis_Text',
    },
    {
        f: '/tr/araba-markalari/toyota/aygo',
        t: '/tr/findcar?makesModels=Toyota%2CAygo&cmsid=Toyota_Aygo_Text',
    },
    {
        f: '/tr/araba-markalari/toyota/prius',
        t: '/tr/findcar?makesModels=Toyota%2CPrius&cmsid=Toyota_Prius_Text',
    },
    {
        f: '/tr/araba-markalari/toyota/rav4',
        t: '/tr/findcar?makesModels=Toyota%2CRav4&cmsid=Toyota_Rav4_Text',
    },
    {
        f: '/tr/araba-markalari/toyota/yaris',
        t: '/tr/findcar?makesModels=Toyota%2CYaris&cmsid=Toyota_Yaris_Text',
    },
    {
        f: '/tr/araba-markalari/toyota',
        t: '/tr/findcar?makesModels=Toyota&cmsid=SEO_Toyota',
    },
    {
        f: '/tr/araba-markalari/volkswagen/caddy',
        t: '/tr/findcar?makesModels=Volkswagen%2CCaddy&cmsid=Volkswagen_Caddy_Text',
    },
    {
        f: '/tr/araba-markalari/volkswagen/golf',
        t: '/tr/findcar?makesModels=Volkswagen%2CGolf&cmsid=Volkswagen_Golf_Text',
    },
    {
        f: '/tr/araba-markalari/volkswagen/passat',
        t: '/tr/findcar?makesModels=Volkswagen%2CPassat&cmsid=Volkswagen_Passat_Text',
    },
    {
        f: '/tr/araba-markalari/volkswagen/polo',
        t: '/tr/findcar?makesModels=Volkswagen%2CPolo&cmsid=Volkswagen_Polo_Text',
    },
    {
        f: '/tr/araba-markalari/volkswagen/tiguan',
        t: '/tr/findcar?makesModels=Volkswagen%2CTiguan&cmsid=Volkswagen_Tiguan_Text',
    },
    {
        f: '/tr/araba-markalari/volkswagen/touareg',
        t: '/tr/findcar?makesModels=Volkswagen%2CTouareg&cmsid=Volkswagen_Touareg_Text',
    },
    {
        f: '/tr/araba-markalari/volkswagen/touran',
        t: '/tr/findcar?makesModels=Volkswagen%2CTouran&cmsid=Volkswagen_Touran_Text',
    },
    {
        f: '/tr/araba-markalari/volkswagen/transporter',
        t: '/tr/findcar?makesModels=Volkswagen%2CTransporter&cmsid=Volkswagen_Transporter_Text',
    },
    {
        f: '/tr/araba-markalari/volkswagen',
        t: '/tr/findcar?makesModels=Volkswagen&cmsid=SEO_Volkswagen',
    },
    {
        f: '/tr/araba-markalari/volvo/s60',
        t: '/tr/findcar?makesModels=Volvo%2CS60&cmsid=Volvo_S60_Text',
    },
    {
        f: '/tr/araba-markalari/volvo/s80',
        t: '/tr/findcar?makesModels=Volvo%2CS80&cmsid=Volvo_S80_Text',
    },
    {
        f: '/tr/araba-markalari/volvo/s90',
        t: '/tr/findcar?makesModels=Volvo%2CS90&cmsid=Volvo_S90_Text',
    },
    {
        f: '/tr/araba-markalari/volvo/v40',
        t: '/tr/findcar?makesModels=Volvo%2CV40&cmsid=Volvo_V40_Text',
    },
    {
        f: '/tr/araba-markalari/volvo/v60',
        t: '/tr/findcar?makesModels=Volvo%2CV60&cmsid=Volvo_V60_Text',
    },
    {
        f: '/tr/araba-markalari/volvo/v90',
        t: '/tr/findcar?makesModels=Volvo%2CV90&cmsid=Volvo_V90_Text',
    },
    {
        f: '/tr/araba-markalari/volvo/xc60',
        t: '/tr/findcar?makesModels=Volvo%2CXC60&cmsid=Volvo_XC60_Text',
    },
    {
        f: '/tr/araba-markalari/volvo/xc90',
        t: '/tr/findcar?makesModels=Volvo%2CXC90&cmsid=Volvo_XC90_Text',
    },
    {
        f: '/tr/araba-markalari/volvo',
        t: '/tr/findcar?makesModels=Volvo&cmsid=SEO_Volvo',
    },
    {
        f: '/tr/kullanilmis-otomobiller-belcika',
        t: '/tr/findcar?originCountries=be&cmsid=used-cars-belgium',
    },
    {
        f: '/tr/kullanilmis-otomobiller-almanya',
        t: '/tr/findcar?originCountries=de&cmsid=used-cars-germany',
    },
    {
        f: '/tr/kullanilmis-otomobiller-ispanya',
        t: '/tr/findcar?originCountries=es&cmsid=used-cars-spain',
    },
    {
        f: '/tr/kullanilmis-otomobiller-fransa',
        t: '/tr/findcar?originCountries=fr&cmsid=used-cars-france',
    },
    {
        f: '/tr/kullanilmis-otomobiller-italya',
        t: '/tr/findcar?originCountries=it&cmsid=used-cars-italy',
    },
    {
        f: '/tr/kullanilmis-otomobiller-luksemburg',
        t: '/tr/findcar?originCountries=lu&cmsid=used-cars-luxembourg',
    },
    {
        f: '/tr/kullanilmis-otomobiller-hollanda',
        t: '/tr/findcar?originCountries=nl&cmsid=used-cars-holland',
    },
    {
        f: '/tr/kullanilmis-otomobiller-polonya',
        t: '/tr/findcar?originCountries=pl&cmsid=used-cars-poland',
    },
    {
        f: '/tr/kullanilmis-otomobiller-isvec',
        t: '/tr/findcar?originCountries=se&cmsid=used-cars-sweden',
    },
    {
        f: '/tr/kullanilmis-otomobil-fiyatlari/ucuz-kullanilmis-otomobiller',
        t: '/tr/findcar?prices=0%2C3000&cmsid=cheap_used_cars',
    },
    {
        f: '/tr/kullanilmis-otomobil-fiyatlari/middle-class',
        t: '/tr/findcar?prices=10000%2C20000&cmsid=Middle_class',
    },
    {
        f: '/tr/kullanilmis-otomobil-fiyatlari/luxury-cars',
        t: '/tr/findcar?prices=20000%2C0&cmsid=luxury_cars',
    },
    {
        f: '/tr/kullanilmis-otomobil-fiyatlari/economy-cars',
        t: '/tr/findcar?prices=3000%2C6000&cmsid=economy_cars',
    },
    {
        f: '/tr/kullanilmis-otomobil-fiyatlari/value-for-money',
        t: '/tr/findcar?prices=6000%2C10000&cmsid=Value_for_money',
    },
    {
        f: '/bg/avtomobili-marka/mini',
        t: '/bg/findcar?makesModels=Mini&cmsid=SEO_Mini',
    },
    {
        f: '/cs/znacka-auta/mini',
        t: '/cs/findcar?makesModels=Mini&cmsid=SEO_Mini',
    },
    {
        f: '/de/automarke/mini',
        t: '/de/findcar?makesModels=Mini&cmsid=SEO_Mini',
    },
    {
        f: '/en/car-makes/mini',
        t: '/en/findcar?makesModels=Mini&cmsid=SEO_Mini',
    },
    {
        f: '/es/marca-coche/mini',
        t: '/es/findcar?makesModels=Mini&cmsid=SEO_Mini',
    },
    {
        f: '/fr-be/voiture-marque/mini',
        t: '/fr-be/findcar?makesModels=Mini&cmsid=SEO_Mini',
    },
    {
        f: '/fr-fr/voiture-marque/mini',
        t: '/fr-fr/findcar?makesModels=Mini&cmsid=SEO_Mini',
    },
    {
        f: '/nl-be/auto-merk/mini',
        t: '/nl-be/findcar?makesModels=Mini&cmsid=SEO_Mini',
    },
    {
        f: '/nl-nl/auto-merk/mini',
        t: '/nl-nl/findcar?makesModels=Mini&cmsid=SEO_Mini',
    },
    {
        f: '/hr/proizvodac-automobila/mini',
        t: '/hr/findcar?makesModels=Mini&cmsid=SEO_Mini',
    },
    {
        f: '/hu/auto-marka/mini',
        t: '/hu/findcar?makesModels=Mini&cmsid=SEO_Mini',
    },
    {
        f: '/it/marche-auto/mini',
        t: '/it/findcar?makesModels=Mini&cmsid=SEO_Mini',
    },
    {
        f: '/lt/automobiliu-markes/mini',
        t: '/lt/findcar?makesModels=Mini&cmsid=SEO_Mini',
    },
    {
        f: '/pl/marka-samochodu/mini',
        t: '/pl/findcar?makesModels=Mini&cmsid=SEO_Mini',
    },
    {
        f: '/pt/marcas-carros/mini',
        t: '/pt/findcar?makesModels=Mini&cmsid=SEO_Mini',
    },
    {
        f: '/ro/model-auto/mini',
        t: '/ro/findcar?makesModels=Mini&cmsid=SEO_Mini',
    },
    {
        f: '/ru/marka/mini',
        t: '/ru/findcar?makesModels=Mini&cmsid=SEO_Mini',
    },
    {
        f: '/sl/avto-znamka/mini',
        t: '/sl/findcar?makesModels=Mini&cmsid=SEO_Mini',
    },
    {
        f: '/sk/znacky-aut/mini',
        t: '/sk/findcar?makesModels=Mini&cmsid=SEO_Mini',
    },
    {
        f: '/tr/araba-markalari/mini',
        t: '/tr/findcar?makesModels=Mini&cmsid=SEO_Mini',
    },
    {
        f: '/el/car-makes/mini',
        t: '/el/findcar?makesModels=Mini&cmsid=SEO_Mini',
    },
    {
        f: '/bg/avtomobili-marka/hyundai',
        t: '/bg/findcar?makesModels=Hyundai&cmsid=SEO_Hyundai',
    },
    {
        f: '/cs/znacka-auta/hyundai',
        t: '/cs/findcar?makesModels=Hyundai&cmsid=SEO_Hyundai',
    },
    {
        f: '/de/automarke/hyundai',
        t: '/de/findcar?makesModels=Hyundai&cmsid=SEO_Hyundai',
    },
    {
        f: '/en/car-makes/hyundai',
        t: '/en/findcar?makesModels=Hyundai&cmsid=SEO_Hyundai',
    },
    {
        f: '/es/marca-coche/hyundai',
        t: '/es/findcar?makesModels=Hyundai&cmsid=SEO_Hyundai',
    },
    {
        f: '/fr-be/voiture-marque/hyundai',
        t: '/fr-be/findcar?makesModels=Hyundai&cmsid=SEO_Hyundai',
    },
    {
        f: '/fr-fr/voiture-marque/hyundai',
        t: '/fr-fr/findcar?makesModels=Hyundai&cmsid=SEO_Hyundai',
    },
    {
        f: '/nl-be/auto-merk/hyundai',
        t: '/nl-be/findcar?makesModels=Hyundai&cmsid=SEO_Hyundai',
    },
    {
        f: '/nl-nl/auto-merk/hyundai',
        t: '/nl-nl/findcar?makesModels=Hyundai&cmsid=SEO_Hyundai',
    },
    {
        f: '/hr/proizvodac-automobila/hyundai',
        t: '/hr/findcar?makesModels=Hyundai&cmsid=SEO_Hyundai',
    },
    {
        f: '/hu/auto-marka/hyundai',
        t: '/hu/findcar?makesModels=Hyundai&cmsid=SEO_Hyundai',
    },
    {
        f: '/it/marche-auto/hyundai',
        t: '/it/findcar?makesModels=Hyundai&cmsid=SEO_Hyundai',
    },
    {
        f: '/lt/automobiliu-markes/hyundai',
        t: '/lt/findcar?makesModels=Hyundai&cmsid=SEO_Hyundai',
    },
    {
        f: '/pl/marka-samochodu/hyundai',
        t: '/pl/findcar?makesModels=Hyundai&cmsid=SEO_Hyundai',
    },
    {
        f: '/pt/marcas-carros/hyundai',
        t: '/pt/findcar?makesModels=Hyundai&cmsid=SEO_Hyundai',
    },
    {
        f: '/ro/model-auto/hyundai',
        t: '/ro/findcar?makesModels=Hyundai&cmsid=SEO_Hyundai',
    },
    {
        f: '/ru/marka/hyundai',
        t: '/ru/findcar?makesModels=Hyundai&cmsid=SEO_Hyundai',
    },
    {
        f: '/sl/avto-znamka/hyundai',
        t: '/sl/findcar?makesModels=Hyundai&cmsid=SEO_Hyundai',
    },
    {
        f: '/sk/znacky-aut/hyundai',
        t: '/sk/findcar?makesModels=Hyundai&cmsid=SEO_Hyundai',
    },
    {
        f: '/tr/araba-markalari/hyundai',
        t: '/tr/findcar?makesModels=Hyundai&cmsid=SEO_Hyundai',
    },
    {
        f: '/el/car-makes/hyundai',
        t: '/el/findcar?makesModels=Hyundai&cmsid=SEO_Hyundai',
    },
    {
        f: '/bg/avtomobili-marka/tesla',
        t: '/bg/findcar?makesModels=Tesla&cmsid=SEO_Tesla',
    },
    {
        f: '/cs/znacka-auta/tesla',
        t: '/cs/findcar?makesModels=Tesla&cmsid=SEO_Tesla',
    },
    {
        f: '/de/automarke/tesla',
        t: '/de/findcar?makesModels=Tesla&cmsid=SEO_Tesla',
    },
    {
        f: '/en/car-makes/tesla',
        t: '/en/findcar?makesModels=Tesla&cmsid=SEO_Tesla',
    },
    {
        f: '/es/marca-coche/tesla',
        t: '/es/findcar?makesModels=Tesla&cmsid=SEO_Tesla',
    },
    {
        f: '/fr-be/voiture-marque/tesla',
        t: '/fr-be/findcar?makesModels=Tesla&cmsid=SEO_Tesla',
    },
    {
        f: '/fr-fr/voiture-marque/tesla',
        t: '/fr-fr/findcar?makesModels=Tesla&cmsid=SEO_Tesla',
    },
    {
        f: '/nl-be/auto-merk/tesla',
        t: '/nl-be/findcar?makesModels=Tesla&cmsid=SEO_Tesla',
    },
    {
        f: '/nl-nl/auto-merk/tesla',
        t: '/nl-nl/findcar?makesModels=Tesla&cmsid=SEO_Tesla',
    },
    {
        f: '/hr/proizvodac-automobila/tesla',
        t: '/hr/findcar?makesModels=Tesla&cmsid=SEO_Tesla',
    },
    {
        f: '/hu/auto-marka/tesla',
        t: '/hu/findcar?makesModels=Tesla&cmsid=SEO_Tesla',
    },
    {
        f: '/it/marche-auto/tesla',
        t: '/it/findcar?makesModels=Tesla&cmsid=SEO_Tesla',
    },
    {
        f: '/lt/automobiliu-markes/tesla',
        t: '/lt/findcar?makesModels=Tesla&cmsid=SEO_Tesla',
    },
    {
        f: '/pl/marka-samochodu/tesla',
        t: '/pl/findcar?makesModels=Tesla&cmsid=SEO_Tesla',
    },
    {
        f: '/pt/marcas-carros/tesla',
        t: '/pt/findcar?makesModels=Tesla&cmsid=SEO_Tesla',
    },
    {
        f: '/ro/model-auto/tesla',
        t: '/ro/findcar?makesModels=Tesla&cmsid=SEO_Tesla',
    },
    {
        f: '/ru/marka/tesla',
        t: '/ru/findcar?makesModels=Tesla&cmsid=SEO_Tesla',
    },
    {
        f: '/sl/avto-znamka/tesla',
        t: '/sl/findcar?makesModels=Tesla&cmsid=SEO_Tesla',
    },
    {
        f: '/sk/znacky-aut/tesla',
        t: '/sk/findcar?makesModels=Tesla&cmsid=SEO_Tesla',
    },
    {
        f: '/tr/araba-markalari/tesla',
        t: '/tr/findcar?makesModels=Tesla&cmsid=SEO_Tesla',
    },
    {
        f: '/el/car-makes/tesla',
        t: '/el/findcar?makesModels=Tesla&cmsid=SEO_Tesla',
    },
    {
        f: '/bg/avtomobili-marka/land-rover',
        t: '/bg/findcar?makesModels=Land%20Rover&cmsid=SEO_LandRover',
    },
    {
        f: '/cs/znacka-auta/land-rover',
        t: '/cs/findcar?makesModels=Land%20Rover&cmsid=SEO_LandRover',
    },
    {
        f: '/de/automarke/land-rover',
        t: '/de/findcar?makesModels=Land%20Rover&cmsid=SEO_LandRover',
    },
    {
        f: '/en/car-makes/land-rover',
        t: '/en/findcar?makesModels=Land%20Rover&cmsid=SEO_LandRover',
    },
    {
        f: '/es/marca-coche/land-rover',
        t: '/es/findcar?makesModels=Land%20Rover&cmsid=SEO_LandRover',
    },
    {
        f: '/fr-be/voiture-marque/land-rover',
        t: '/fr-be/findcar?makesModels=Land%20Rover&cmsid=SEO_LandRover',
    },
    {
        f: '/fr-fr/voiture-marque/land-rover',
        t: '/fr-fr/findcar?makesModels=Land%20Rover&cmsid=SEO_LandRover',
    },
    {
        f: '/nl-be/auto-merk/land-rover',
        t: '/nl-be/findcar?makesModels=Land%20Rover&cmsid=SEO_LandRover',
    },
    {
        f: '/nl-nl/auto-merk/land-rover',
        t: '/nl-nl/findcar?makesModels=Land%20Rover&cmsid=SEO_LandRover',
    },
    {
        f: '/hr/proizvodac-automobila/land-rover',
        t: '/hr/findcar?makesModels=Land%20Rover&cmsid=SEO_LandRover',
    },
    {
        f: '/hu/auto-marka/land-rover',
        t: '/hu/findcar?makesModels=Land%20Rover&cmsid=SEO_LandRover',
    },
    {
        f: '/it/marche-auto/land-rover',
        t: '/it/findcar?makesModels=Land%20Rover&cmsid=SEO_LandRover',
    },
    {
        f: '/lt/automobiliu-markes/land-rover',
        t: '/lt/findcar?makesModels=Land%20Rover&cmsid=SEO_LandRover',
    },
    {
        f: '/pl/marka-samochodu/land-rover',
        t: '/pl/findcar?makesModels=Land%20Rover&cmsid=SEO_LandRover',
    },
    {
        f: '/pt/marcas-carros/land-rover',
        t: '/pt/findcar?makesModels=Land%20Rover&cmsid=SEO_LandRover',
    },
    {
        f: '/ro/model-auto/land-rover',
        t: '/ro/findcar?makesModels=Land%20Rover&cmsid=SEO_LandRover',
    },
    {
        f: '/ru/marka/land-rover',
        t: '/ru/findcar?makesModels=Land%20Rover&cmsid=SEO_LandRover',
    },
    {
        f: '/sl/avto-znamka/land-rover',
        t: '/sl/findcar?makesModels=Land%20Rover&cmsid=SEO_LandRover',
    },
    {
        f: '/sk/znacky-aut/land-rover',
        t: '/sk/findcar?makesModels=Land%20Rover&cmsid=SEO_LandRover',
    },
    {
        f: '/tr/araba-markalari/land-rover',
        t: '/tr/findcar?makesModels=Land%20Rover&cmsid=SEO_LandRover',
    },
    {
        f: '/el/car-makes/land-rover',
        t: '/el/findcar?makesModels=Land%20Rover&cmsid=SEO_LandRover',
    },
    {
        f: '/bg/avtomobili-marka/jeep',
        t: '/bg/findcar?makesModels=Jeep&cmsid=SEO_Jeep',
    },
    {
        f: '/cs/znacka-auta/jeep',
        t: '/cs/findcar?makesModels=Jeep&cmsid=SEO_Jeep',
    },
    {
        f: '/de/automarke/jeep',
        t: '/de/findcar?makesModels=Jeep&cmsid=SEO_Jeep',
    },
    {
        f: '/en/car-makes/jeep',
        t: '/en/findcar?makesModels=Jeep&cmsid=SEO_Jeep',
    },
    {
        f: '/es/marca-coche/jeep',
        t: '/es/findcar?makesModels=Jeep&cmsid=SEO_Jeep',
    },
    {
        f: '/fr-be/voiture-marque/jeep',
        t: '/fr-be/findcar?makesModels=Jeep&cmsid=SEO_Jeep',
    },
    {
        f: '/fr-fr/voiture-marque/jeep',
        t: '/fr-fr/findcar?makesModels=Jeep&cmsid=SEO_Jeep',
    },
    {
        f: '/nl-be/auto-merk/jeep',
        t: '/nl-be/findcar?makesModels=Jeep&cmsid=SEO_Jeep',
    },
    {
        f: '/nl-nl/auto-merk/jeep',
        t: '/nl-nl/findcar?makesModels=Jeep&cmsid=SEO_Jeep',
    },
    {
        f: '/hr/proizvodac-automobila/jeep',
        t: '/hr/findcar?makesModels=Jeep&cmsid=SEO_Jeep',
    },
    {
        f: '/hu/auto-marka/jeep',
        t: '/hu/findcar?makesModels=Jeep&cmsid=SEO_Jeep',
    },
    {
        f: '/it/marche-auto/jeep',
        t: '/it/findcar?makesModels=Jeep&cmsid=SEO_Jeep',
    },
    {
        f: '/lt/automobiliu-markes/jeep',
        t: '/lt/findcar?makesModels=Jeep&cmsid=SEO_Jeep',
    },
    {
        f: '/pl/marka-samochodu/jeep',
        t: '/pl/findcar?makesModels=Jeep&cmsid=SEO_Jeep',
    },
    {
        f: '/pt/marcas-carros/jeep',
        t: '/pt/findcar?makesModels=Jeep&cmsid=SEO_Jeep',
    },
    {
        f: '/ro/model-auto/jeep',
        t: '/ro/findcar?makesModels=Jeep&cmsid=SEO_Jeep',
    },
    {
        f: '/ru/marka/jeep',
        t: '/ru/findcar?makesModels=Jeep&cmsid=SEO_Jeep',
    },
    {
        f: '/sl/avto-znamka/jeep',
        t: '/sl/findcar?makesModels=Jeep&cmsid=SEO_Jeep',
    },
    {
        f: '/sk/znacky-aut/jeep',
        t: '/sk/findcar?makesModels=Jeep&cmsid=SEO_Jeep',
    },
    {
        f: '/tr/araba-markalari/jeep',
        t: '/tr/findcar?makesModels=Jeep&cmsid=SEO_Jeep',
    },
    {
        f: '/el/car-makes/jeep',
        t: '/el/findcar?makesModels=Jeep&cmsid=SEO_Jeep',
    },
    {
        f: '/bg/avtomobili-marka/seat',
        t: '/bg/findcar?makesModels=Seat&cmsid=SEO_Seat',
    },
    {
        f: '/cs/znacka-auta/seat',
        t: '/cs/findcar?makesModels=Seat&cmsid=SEO_Seat',
    },
    {
        f: '/de/automarke/seat',
        t: '/de/findcar?makesModels=Seat&cmsid=SEO_Seat',
    },
    {
        f: '/en/car-makes/seat',
        t: '/en/findcar?makesModels=Seat&cmsid=SEO_Seat',
    },
    {
        f: '/es/marca-coche/seat',
        t: '/es/findcar?makesModels=Seat&cmsid=SEO_Seat',
    },
    {
        f: '/fr-be/voiture-marque/seat',
        t: '/fr-be/findcar?makesModels=Seat&cmsid=SEO_Seat',
    },
    {
        f: '/fr-fr/voiture-marque/seat',
        t: '/fr-fr/findcar?makesModels=Seat&cmsid=SEO_Seat',
    },
    {
        f: '/nl-be/auto-merk/seat',
        t: '/nl-be/findcar?makesModels=Seat&cmsid=SEO_Seat',
    },
    {
        f: '/nl-nl/auto-merk/seat',
        t: '/nl-nl/findcar?makesModels=Seat&cmsid=SEO_Seat',
    },
    {
        f: '/hr/proizvodac-automobila/seat',
        t: '/hr/findcar?makesModels=Seat&cmsid=SEO_Seat',
    },
    {
        f: '/hu/auto-marka/seat',
        t: '/hu/findcar?makesModels=Seat&cmsid=SEO_Seat',
    },
    {
        f: '/it/marche-auto/seat',
        t: '/it/findcar?makesModels=Seat&cmsid=SEO_Seat',
    },
    {
        f: '/lt/automobiliu-markes/seat',
        t: '/lt/findcar?makesModels=Seat&cmsid=SEO_Seat',
    },
    {
        f: '/pl/marka-samochodu/seat',
        t: '/pl/findcar?makesModels=Seat&cmsid=SEO_Seat',
    },
    {
        f: '/pt/marcas-carros/seat',
        t: '/pt/findcar?makesModels=Seat&cmsid=SEO_Seat',
    },
    {
        f: '/ro/model-auto/seat',
        t: '/ro/findcar?makesModels=Seat&cmsid=SEO_Seat',
    },
    {
        f: '/ru/marka/seat',
        t: '/ru/findcar?makesModels=Seat&cmsid=SEO_Seat',
    },
    {
        f: '/sl/avto-znamka/seat',
        t: '/sl/findcar?makesModels=Seat&cmsid=SEO_Seat',
    },
    {
        f: '/sk/znacky-aut/seat',
        t: '/sk/findcar?makesModels=Seat&cmsid=SEO_Seat',
    },
    {
        f: '/tr/araba-markalari/seat',
        t: '/tr/findcar?makesModels=Seat&cmsid=SEO_Seat',
    },
    {
        f: '/el/car-makes/seat',
        t: '/el/findcar?makesModels=Seat&cmsid=SEO_Seat',
    },

    //------

    {
        f: '/pl/ostatnie-samochody',
        t: '/pl/findcar?registrationYears=2020%2C0&cmsid=recent_cars',
    },
    {
        f: '/ro/masini-recente',
        t: '/ro/findcar?registrationYears=2020%2C0&cmsid=recent_cars',
    },
    {
        f: '/sl/nedavni-avtomobili',
        t: '/sl/findcar?registrationYears=2020%2C0&cmsid=recent_cars',
    },
    {
        f: '/nl-nl/recente-autos',
        t: '/nl-nl/findcar?registrationYears=2020%2C0&cmsid=recent_cars',
    },
    {
        f: '/sk/naposledy-pridane-auta',
        t: '/sk/findcar?registrationYears=2020%2C0&cmsid=recent_cars',
    },
    {
        f: '/de/aktuelle-autos',
        t: '/de/findcar?registrationYears=2020%2C0&cmsid=recent_cars',
    },
    {
        f: '/pt/carros-recentes',
        t: '/pt/findcar?registrationYears=2020%2C0&cmsid=recent_cars',
    },
    {
        f: '/nl-be/recente-autos',
        t: '/nl-be/findcar?registrationYears=2020%2C0&cmsid=recent_cars',
    },
    {
        f: '/en/recent-cars',
        t: '/en/findcar?registrationYears=2020%2C0&cmsid=recent_cars',
    },
    {
        f: '/lt/naujausi-automobiliai',
        t: '/lt/findcar?registrationYears=2020%2C0&cmsid=recent_cars',
    },
    {
        f: '/fr-be/voitures-recentes',
        t: '/fr-be/findcar?registrationYears=2020%2C0&cmsid=recent_cars',
    },
    {
        f: '/es/coches-recientes',
        t: '/es/findcar?registrationYears=2020%2C0&cmsid=recent_cars',
    },
    {
        f: '/fr-fr/voitures-recentes',
        t: '/fr-fr/findcar?registrationYears=2020%2C0&cmsid=recent_cars',
    },
    {
        f: '/it/auto-recenti',
        t: '/it/findcar?registrationYears=2020%2C0&cmsid=recent_cars',
    },
    {
        f: '/cs/nedavne-vozy',
        t: '/cs/findcar?registrationYears=2020%2C0&cmsid=recent_cars',
    },
    {
        f: '/ru/nedavno-dobavlennye',
        t: '/ru/findcar?registrationYears=2020%2C0&cmsid=recent_cars',
    },
    {
        f: '/hr/aktualni-automobili',
        t: '/hr/findcar?registrationYears=2020%2C0&cmsid=recent_cars',
    },
    {
        f: '/el/recent-cars',
        t: '/el/findcar?registrationYears=2020%2C0&cmsid=recent_cars',
    },
    {
        f: '/hu/legujabb-autok',
        t: '/hu/findcar?registrationYears=2020%2C0&cmsid=recent_cars',
    },
    {
        f: '/tr/son-arabalar',
        t: '/tr/findcar?registrationYears=2020%2C0&cmsid=recent_cars',
    },
    {
        f: '/bg/posledni-koli',
        t: '/bg/findcar?registrationYears=2020%2C0&cmsid=recent_cars',
    },

    {
        f: '/de/neue-autos',
        t: '/de/findcar?mileages=0%2C50&cmsid=zerokm_cars',
    },
    {
        f: '/sk/nove-auta',
        t: '/sk/findcar?mileages=0%2C50&cmsid=zerokm_cars',
    },
    {
        f: '/nl-nl/nieuwe-autos',
        t: '/nl-nl/findcar?mileages=0%2C50&cmsid=zerokm_cars',
    },
    {
        f: '/sl/novi-avtomobili',
        t: '/sl/findcar?mileages=0%2C50&cmsid=zerokm_cars',
    },
    {
        f: '/ro/masini-noi',
        t: '/ro/findcar?mileages=0%2C50&cmsid=zerokm_cars',
    },
    {
        f: '/pl/nowe-samochody',
        t: '/pl/findcar?mileages=0%2C50&cmsid=zerokm_cars',
    },
    {
        f: '/bg/novi-koli',
        t: '/bg/findcar?mileages=0%2C50&cmsid=zerokm_cars',
    },
    {
        f: '/tr/﻿yeni-arabalar',
        t: '/tr/findcar?mileages=0%2C50&cmsid=zerokm_cars',
    },
    {
        f: '/hu/uj-autok',
        t: '/hu/findcar?mileages=0%2C50&cmsid=zerokm_cars',
    },
    {
        f: '/el/new-cars',
        t: '/el/findcar?mileages=0%2C50&cmsid=zerokm_cars',
    },
    {
        f: '/ru/novye-avtomobili',
        t: '/ru/findcar?mileages=0%2C50&cmsid=zerokm_cars',
    },
    {
        f: '/hr/novi-automobili',
        t: '/hr/findcar?mileages=0%2C50&cmsid=zerokm_cars',
    },
    {
        f: '/cs/nove-vozy',
        t: '/cs/findcar?mileages=0%2C50&cmsid=zerokm_cars',
    },
    {
        f: '/it/auto-nuove',
        t: '/it/findcar?mileages=0%2C50&cmsid=zerokm_cars',
    },
    {
        f: '/fr-fr/voitures-neuves',
        t: '/fr-fr/findcar?mileages=0%2C50&cmsid=zerokm_cars',
    },
    {
        f: '/es/coches-nuevos',
        t: '/es/findcar?mileages=0%2C50&cmsid=zerokm_cars',
    },
    {
        f: '/fr-be/voitures-neuves',
        t: '/fr-be/findcar?mileages=0%2C50&cmsid=zerokm_cars',
    },
    {
        f: '/lt/nauji-automobiliai',
        t: '/lt/findcar?mileages=0%2C50&cmsid=zerokm_cars',
    },
    {
        f: '/en/new-cars',
        t: '/en/findcar?mileages=0%2C50&cmsid=zerokm_cars',
    },
    {
        f: '/pt/carros-novos',
        t: '/pt/findcar?mileages=0%2C50&cmsid=zerokm_cars',
    },
    {
        f: '/nl-be/nieuwe-autos',
        t: '/nl-be/findcar?mileages=0%2C50&cmsid=zerokm_cars',
    },

    {
        f: '/nl-be/elektrische-autos',
        t: '/nl-be/findcar?fuelTypes=Electric&cmsid=Electric_cars',
    },
    {
        f: '/pt/carros-eletricos',
        t: '/pt/findcar?fuelTypes=Electric&cmsid=Electric_cars',
    },
    {
        f: '/lt/elektriniai-automobiliai',
        t: '/lt/findcar?fuelTypes=Electric&cmsid=Electric_cars',
    },
    {
        f: '/en/electric-cars',
        t: '/en/findcar?fuelTypes=Electric&cmsid=Electric_cars',
    },
    {
        f: '/es/coches-electricos',
        t: '/es/findcar?fuelTypes=Electric&cmsid=Electric_cars',
    },
    {
        f: '/fr-be/voitures-electriques',
        t: '/fr-be/findcar?fuelTypes=Electric&cmsid=Electric_cars',
    },
    {
        f: '/it/auto-elettriche',
        t: '/it/findcar?fuelTypes=Electric&cmsid=Electric_cars',
    },
    {
        f: '/cs/elektricke-vozy',
        t: '/cs/findcar?fuelTypes=Electric&cmsid=Electric_cars',
    },
    {
        f: '/fr-fr/voitures-electriques',
        t: '/fr-fr/findcar?fuelTypes=Electric&cmsid=Electric_cars',
    },
    {
        f: '/ru/elektromobili',
        t: '/ru/findcar?fuelTypes=Electric&cmsid=Electric_cars',
    },
    {
        f: '/hr/elektricni-automobili',
        t: '/hr/findcar?fuelTypes=Electric&cmsid=Electric_cars',
    },
    {
        f: '/hu/elektromos-autok',
        t: '/hu/findcar?fuelTypes=Electric&cmsid=Electric_cars',
    },
    {
        f: '/el/electric-cars',
        t: '/el/findcar?fuelTypes=Electric&cmsid=Electric_cars',
    },
    {
        f: '/tr/elektrikli-arabalar',
        t: '/tr/findcar?fuelTypes=Electric&cmsid=Electric_cars',
    },
    {
        f: '/bg/elektricheski-koli',
        t: '/bg/findcar?fuelTypes=Electric&cmsid=Electric_cars',
    },
    {
        f: '/pl/samochody-elektryczne',
        t: '/pl/findcar?fuelTypes=Electric&cmsid=Electric_cars',
    },
    {
        f: '/ro/masini-electrice',
        t: '/ro/findcar?fuelTypes=Electric&cmsid=Electric_cars',
    },
    {
        f: '/nl-nl/elektrische-autos',
        t: '/nl-nl/findcar?fuelTypes=Electric&cmsid=Electric_cars',
    },
    {
        f: '/sl/elektricni-avtomobili',
        t: '/sl/findcar?fuelTypes=Electric&cmsid=Electric_cars',
    },
    {
        f: '/sk/elektricke-auta',
        t: '/sk/findcar?fuelTypes=Electric&cmsid=Electric_cars',
    },
    {
        f: '/de/elektroautos',
        t: '/de/findcar?fuelTypes=Electric&cmsid=Electric_cars',
    },

    {
        f: '/sk/aukcie-aut/kupit-teraz-a-vyzdvihnut',
        t: '/sk/findcar?auctionTypes=3&cmsid=buy-now_pick-up',
    },
    {
        f: '/nl-nl/autoveilingen/koop-nu-en-haal-op',
        t: '/nl-nl/findcar?auctionTypes=3&cmsid=buy-now_pick-up',
    },
    {
        f: '/sl/drazba-vozil/kupi-zdaj-in-prevzemi',
        t: '/sl/findcar?auctionTypes=3&cmsid=buy-now_pick-up',
    },
    {
        f: '/pl/aukcje-samochodow/kup-teraz-i-odbierz',
        t: '/pl/findcar?auctionTypes=3&cmsid=buy-now_pick-up',
    },
    {
        f: '/ro/licitatie-auto/cumpar-acum-si-castig',
        t: '/ro/findcar?auctionTypes=3&cmsid=buy-now_pick-up',
    },
    {
        f: '/de/gebrauchtwagen-auktion/jetzt-kaufen-und-abholen',
        t: '/de/findcar?auctionTypes=3&cmsid=buy-now_pick-up',
    },
    {
        f: '/lt/car-auction/pirkite-dabar-ir-atsiimkite',
        t: '/lt/findcar?auctionTypes=3&cmsid=buy-now_pick-up',
    },
    {
        f: '/en/car-auction/buy-now-and-pick-up',
        t: '/en/findcar?auctionTypes=3&cmsid=buy-now_pick-up',
    },
    {
        f: '/cs/aukce-ojetech-aut/kupte-nyni-a-vyzvednete',
        t: '/cs/findcar?auctionTypes=3&cmsid=buy-now_pick-up',
    },
    {
        f: '/it/asta-auto-usate/acquista-e-ritira',
        t: '/it/findcar?auctionTypes=3&cmsid=buy-now_pick-up',
    },
    {
        f: '/fr-fr/encheres-voitures/acheter-maintenant-et-recuperer',
        t: '/fr-fr/findcar?auctionTypes=3&cmsid=buy-now_pick-up',
    },
    {
        f: '/fr-be/encheres-voitures/acheter-maintenant-et-recuperer',
        t: '/fr-be/findcar?auctionTypes=3&cmsid=buy-now_pick-up',
    },
    {
        f: '/es/subasta-automoviles/compra-ahora-y-recoge',
        t: '/es/findcar?auctionTypes=3&cmsid=buy-now_pick-up',
    },
    {
        f: '/nl-be/autoveilingen/koop-nu-en-haal-op',
        t: '/nl-be/findcar?auctionTypes=3&cmsid=buy-now_pick-up',
    },
    {
        f: '/pt/leilao/compre-agora-e-levante',
        t: '/pt/findcar?auctionTypes=3&cmsid=buy-now_pick-up',
    },
    {
        f: '/tr/araba-markalari/simdi-satin-al-ve-teslim-al',
        t: '/tr/findcar?auctionTypes=3&cmsid=buy-now_pick-up',
    },
    {
        f: '/bg/car-auction/kupi-sega-i-vzemi',
        t: '/bg/findcar?auctionTypes=3&cmsid=buy-now_pick-up',
    },
    {
        f: '/ru/car-auction/kupit-srazu-i-zabrat',
        t: '/ru/findcar?auctionTypes=3&cmsid=buy-now_pick-up',
    },
    {
        f: '/hr/car-auction/kupite-sada-i-preuzmite',
        t: '/hr/findcar?auctionTypes=3&cmsid=buy-now_pick-up',
    },
    {
        f: '/hu/hasznaltauto-arveres/megveszem-most-es-atveszem',
        t: '/hu/findcar?auctionTypes=3&cmsid=buy-now_pick-up',
    },
    {
        f: '/el/car-auction/buy-now-and-pick-up',
        t: '/el/findcar?auctionTypes=3&cmsid=buy-now_pick-up',
    },

    // ---

    {
        f: '/bg/avtomobili-marka/honda',
        t: '/bg/findcar?makesModels=Honda&cmsid=SEO_Honda',
    },
    {
        f: '/cs/znacka-auta/honda',
        t: '/cs/findcar?makesModels=Honda&cmsid=SEO_Honda',
    },
    {
        f: '/de/automarke/honda',
        t: '/de/findcar?makesModels=Honda&cmsid=SEO_Honda',
    },
    {
        f: '/en/car-makes/honda',
        t: '/en/findcar?makesModels=Honda&cmsid=SEO_Honda',
    },
    {
        f: '/es/marca-coche/honda',
        t: '/es/findcar?makesModels=Honda&cmsid=SEO_Honda',
    },
    {
        f: '/fr-be/voiture-marque/honda',
        t: '/fr-be/findcar?makesModels=Honda&cmsid=SEO_Honda',
    },
    {
        f: '/fr-fr/voiture-marque/honda',
        t: '/fr-fr/findcar?makesModels=Honda&cmsid=SEO_Honda',
    },
    {
        f: '/nl-be/auto-merk/honda',
        t: '/nl-be/findcar?makesModels=Honda&cmsid=SEO_Honda',
    },
    {
        f: '/nl-nl/auto-merk/honda',
        t: '/nl-nl/findcar?makesModels=Honda&cmsid=SEO_Honda',
    },
    {
        f: '/hr/proizvodac-automobila/honda',
        t: '/hr/findcar?makesModels=Honda&cmsid=SEO_Honda',
    },
    {
        f: '/hu/auto-marka/honda',
        t: '/hu/findcar?makesModels=Honda&cmsid=SEO_Honda',
    },
    {
        f: '/it/marche-auto/honda',
        t: '/it/findcar?makesModels=Honda&cmsid=SEO_Honda',
    },
    {
        f: '/lt/automobiliu-markes/honda',
        t: '/lt/findcar?makesModels=Honda&cmsid=SEO_Honda',
    },
    {
        f: '/pl/marka-samochodu/honda',
        t: '/pl/findcar?makesModels=Honda&cmsid=SEO_Honda',
    },
    {
        f: '/pt/marcas-carros/honda',
        t: '/pt/findcar?makesModels=Honda&cmsid=SEO_Honda',
    },
    {
        f: '/ro/model-auto/honda',
        t: '/ro/findcar?makesModels=Honda&cmsid=SEO_Honda',
    },
    {
        f: '/ru/marka/honda',
        t: '/ru/findcar?makesModels=Honda&cmsid=SEO_Honda',
    },
    {
        f: '/sl/avto-znamka/honda',
        t: '/sl/findcar?makesModels=Honda&cmsid=SEO_Honda',
    },
    {
        f: '/sk/znacky-aut/honda',
        t: '/sk/findcar?makesModels=Honda&cmsid=SEO_Honda',
    },
    {
        f: '/tr/araba-markalari/honda',
        t: '/tr/findcar?makesModels=Honda&cmsid=SEO_Honda',
    },
    {
        f: '/el/car-makes/honda',
        t: '/el/findcar?makesModels=Honda&cmsid=SEO_Honda',
    },
    {
        f: '/bg/avtomobili-marka/kia',
        t: '/bg/findcar?makesModels=Kia&cmsid=SEO_Kia',
    },
    {
        f: '/cs/znacka-auta/kia',
        t: '/cs/findcar?makesModels=Kia&cmsid=SEO_Kia',
    },
    {
        f: '/de/automarke/kia',
        t: '/de/findcar?makesModels=Kia&cmsid=SEO_Kia',
    },
    {
        f: '/en/car-makes/kia',
        t: '/en/findcar?makesModels=Kia&cmsid=SEO_Kia',
    },
    {
        f: '/es/marca-coche/kia',
        t: '/es/findcar?makesModels=Kia&cmsid=SEO_Kia',
    },
    {
        f: '/fr-be/voiture-marque/kia',
        t: '/fr-be/findcar?makesModels=Kia&cmsid=SEO_Kia',
    },
    {
        f: '/fr-fr/voiture-marque/kia',
        t: '/fr-fr/findcar?makesModels=Kia&cmsid=SEO_Kia',
    },
    {
        f: '/nl-be/auto-merk/kia',
        t: '/nl-be/findcar?makesModels=Kia&cmsid=SEO_Kia',
    },
    {
        f: '/nl-nl/auto-merk/kia',
        t: '/nl-nl/findcar?makesModels=Kia&cmsid=SEO_Kia',
    },
    {
        f: '/hr/proizvodac-automobila/kia',
        t: '/hr/findcar?makesModels=Kia&cmsid=SEO_Kia',
    },
    {
        f: '/hu/auto-marka/kia',
        t: '/hu/findcar?makesModels=Kia&cmsid=SEO_Kia',
    },
    {
        f: '/it/marche-auto/kia',
        t: '/it/findcar?makesModels=Kia&cmsid=SEO_Kia',
    },
    {
        f: '/lt/automobiliu-markes/kia',
        t: '/lt/findcar?makesModels=Kia&cmsid=SEO_Kia',
    },
    {
        f: '/pl/marka-samochodu/kia',
        t: '/pl/findcar?makesModels=Kia&cmsid=SEO_Kia',
    },
    {
        f: '/pt/marcas-carros/kia',
        t: '/pt/findcar?makesModels=Kia&cmsid=SEO_Kia',
    },
    {
        f: '/ro/model-auto/kia',
        t: '/ro/findcar?makesModels=Kia&cmsid=SEO_Kia',
    },
    {
        f: '/ru/marka/kia',
        t: '/ru/findcar?makesModels=Kia&cmsid=SEO_Kia',
    },
    {
        f: '/sl/avto-znamka/kia',
        t: '/sl/findcar?makesModels=Kia&cmsid=SEO_Kia',
    },
    {
        f: '/sk/znacky-aut/kia',
        t: '/sk/findcar?makesModels=Kia&cmsid=SEO_Kia',
    },
    {
        f: '/tr/araba-markalari/kia',
        t: '/tr/findcar?makesModels=Kia&cmsid=SEO_Kia',
    },
    {
        f: '/el/car-makes/kia',
        t: '/el/findcar?makesModels=Kia&cmsid=SEO_Kia',
    },
    {
        f: '/bg/avtomobili-marka/porsche',
        t: '/bg/findcar?makesModels=Porsche&cmsid=SEO_Porsche',
    },
    {
        f: '/cs/znacka-auta/porsche',
        t: '/cs/findcar?makesModels=Porsche&cmsid=SEO_Porsche',
    },
    {
        f: '/de/automarke/porsche',
        t: '/de/findcar?makesModels=Porsche&cmsid=SEO_Porsche',
    },
    {
        f: '/en/car-makes/porsche',
        t: '/en/findcar?makesModels=Porsche&cmsid=SEO_Porsche',
    },
    {
        f: '/es/marca-coche/porsche',
        t: '/es/findcar?makesModels=Porsche&cmsid=SEO_Porsche',
    },
    {
        f: '/fr-be/voiture-marque/porsche',
        t: '/fr-be/findcar?makesModels=Porsche&cmsid=SEO_Porsche',
    },
    {
        f: '/fr-fr/voiture-marque/porsche',
        t: '/fr-fr/findcar?makesModels=Porsche&cmsid=SEO_Porsche',
    },
    {
        f: '/nl-be/auto-merk/porsche',
        t: '/nl-be/findcar?makesModels=Porsche&cmsid=SEO_Porsche',
    },
    {
        f: '/nl-nl/auto-merk/porsche',
        t: '/nl-nl/findcar?makesModels=Porsche&cmsid=SEO_Porsche',
    },
    {
        f: '/hr/proizvodac-automobila/porsche',
        t: '/hr/findcar?makesModels=Porsche&cmsid=SEO_Porsche',
    },
    {
        f: '/hu/auto-marka/porsche',
        t: '/hu/findcar?makesModels=Porsche&cmsid=SEO_Porsche',
    },
    {
        f: '/it/marche-auto/porsche',
        t: '/it/findcar?makesModels=Porsche&cmsid=SEO_Porsche',
    },
    {
        f: '/lt/automobiliu-markes/porsche',
        t: '/lt/findcar?makesModels=Porsche&cmsid=SEO_Porsche',
    },
    {
        f: '/pl/marka-samochodu/porsche',
        t: '/pl/findcar?makesModels=Porsche&cmsid=SEO_Porsche',
    },
    {
        f: '/pt/marcas-carros/porsche',
        t: '/pt/findcar?makesModels=Porsche&cmsid=SEO_Porsche',
    },
    {
        f: '/ro/model-auto/porsche',
        t: '/ro/findcar?makesModels=Porsche&cmsid=SEO_Porsche',
    },
    {
        f: '/ru/marka/porsche',
        t: '/ru/findcar?makesModels=Porsche&cmsid=SEO_Porsche',
    },
    {
        f: '/sl/avto-znamka/porsche',
        t: '/sl/findcar?makesModels=Porsche&cmsid=SEO_Porsche',
    },
    {
        f: '/sk/znacky-aut/porsche',
        t: '/sk/findcar?makesModels=Porsche&cmsid=SEO_Porsche',
    },
    {
        f: '/tr/araba-markalari/porsche',
        t: '/tr/findcar?makesModels=Porsche&cmsid=SEO_Porsche',
    },
    {
        f: '/el/car-makes/porsche',
        t: '/el/findcar?makesModels=Porsche&cmsid=SEO_Porsche',
    },
    {
        f: '/bg/avtomobili-marka/chevrolet',
        t: '/bg/findcar?makesModels=Chevrolet&cmsid=SEO_Chevrolet',
    },
    {
        f: '/cs/znacka-auta/chevrolet',
        t: '/cs/findcar?makesModels=Chevrolet&cmsid=SEO_Chevrolet',
    },
    {
        f: '/de/automarke/chevrolet',
        t: '/de/findcar?makesModels=Chevrolet&cmsid=SEO_Chevrolet',
    },
    {
        f: '/en/car-makes/chevrolet',
        t: '/en/findcar?makesModels=Chevrolet&cmsid=SEO_Chevrolet',
    },
    {
        f: '/es/marca-coche/chevrolet',
        t: '/es/findcar?makesModels=Chevrolet&cmsid=SEO_Chevrolet',
    },
    {
        f: '/fr-be/voiture-marque/chevrolet',
        t: '/fr-be/findcar?makesModels=Chevrolet&cmsid=SEO_Chevrolet',
    },
    {
        f: '/fr-fr/voiture-marque/chevrolet',
        t: '/fr-fr/findcar?makesModels=Chevrolet&cmsid=SEO_Chevrolet',
    },
    {
        f: '/nl-be/auto-merk/chevrolet',
        t: '/nl-be/findcar?makesModels=Chevrolet&cmsid=SEO_Chevrolet',
    },
    {
        f: '/nl-nl/auto-merk/chevrolet',
        t: '/nl-nl/findcar?makesModels=Chevrolet&cmsid=SEO_Chevrolet',
    },
    {
        f: '/hr/proizvodac-automobila/chevrolet',
        t: '/hr/findcar?makesModels=Chevrolet&cmsid=SEO_Chevrolet',
    },
    {
        f: '/hu/auto-marka/chevrolet',
        t: '/hu/findcar?makesModels=Chevrolet&cmsid=SEO_Chevrolet',
    },
    {
        f: '/it/marche-auto/chevrolet',
        t: '/it/findcar?makesModels=Chevrolet&cmsid=SEO_Chevrolet',
    },
    {
        f: '/lt/automobiliu-markes/chevrolet',
        t: '/lt/findcar?makesModels=Chevrolet&cmsid=SEO_Chevrolet',
    },
    {
        f: '/pl/marka-samochodu/chevrolet',
        t: '/pl/findcar?makesModels=Chevrolet&cmsid=SEO_Chevrolet',
    },
    {
        f: '/pt/marcas-carros/chevrolet',
        t: '/pt/findcar?makesModels=Chevrolet&cmsid=SEO_Chevrolet',
    },
    {
        f: '/ro/model-auto/chevrolet',
        t: '/ro/findcar?makesModels=Chevrolet&cmsid=SEO_Chevrolet',
    },
    {
        f: '/ru/marka/chevrolet',
        t: '/ru/findcar?makesModels=Chevrolet&cmsid=SEO_Chevrolet',
    },
    {
        f: '/sl/avto-znamka/chevrolet',
        t: '/sl/findcar?makesModels=Chevrolet&cmsid=SEO_Chevrolet',
    },
    {
        f: '/sk/znacky-aut/chevrolet',
        t: '/sk/findcar?makesModels=Chevrolet&cmsid=SEO_Chevrolet',
    },
    {
        f: '/tr/araba-markalari/chevrolet',
        t: '/tr/findcar?makesModels=Chevrolet&cmsid=SEO_Chevrolet',
    },
    {
        f: '/el/car-makes/chevrolet',
        t: '/el/findcar?makesModels=Chevrolet&cmsid=SEO_Chevrolet',
    },
    {
        f: '/bg/avtomobili-marka/jaguar',
        t: '/bg/findcar?makesModels=Jaguar&cmsid=SEO_Jaguar',
    },
    {
        f: '/cs/znacka-auta/jaguar',
        t: '/cs/findcar?makesModels=Jaguar&cmsid=SEO_Jaguar',
    },
    {
        f: '/de/automarke/jaguar',
        t: '/de/findcar?makesModels=Jaguar&cmsid=SEO_Jaguar',
    },
    {
        f: '/en/car-makes/jaguar',
        t: '/en/findcar?makesModels=Jaguar&cmsid=SEO_Jaguar',
    },
    {
        f: '/es/marca-coche/jaguar',
        t: '/es/findcar?makesModels=Jaguar&cmsid=SEO_Jaguar',
    },
    {
        f: '/fr-be/voiture-marque/jaguar',
        t: '/fr-be/findcar?makesModels=Jaguar&cmsid=SEO_Jaguar',
    },
    {
        f: '/fr-fr/voiture-marque/jaguar',
        t: '/fr-fr/findcar?makesModels=Jaguar&cmsid=SEO_Jaguar',
    },
    {
        f: '/nl-be/auto-merk/jaguar',
        t: '/nl-be/findcar?makesModels=Jaguar&cmsid=SEO_Jaguar',
    },
    {
        f: '/nl-nl/auto-merk/jaguar',
        t: '/nl-nl/findcar?makesModels=Jaguar&cmsid=SEO_Jaguar',
    },
    {
        f: '/hr/proizvodac-automobila/jaguar',
        t: '/hr/findcar?makesModels=Jaguar&cmsid=SEO_Jaguar',
    },
    {
        f: '/hu/auto-marka/jaguar',
        t: '/hu/findcar?makesModels=Jaguar&cmsid=SEO_Jaguar',
    },
    {
        f: '/it/marche-auto/jaguar',
        t: '/it/findcar?makesModels=Jaguar&cmsid=SEO_Jaguar',
    },
    {
        f: '/lt/automobiliu-markes/jaguar',
        t: '/lt/findcar?makesModels=Jaguar&cmsid=SEO_Jaguar',
    },
    {
        f: '/pl/marka-samochodu/jaguar',
        t: '/pl/findcar?makesModels=Jaguar&cmsid=SEO_Jaguar',
    },
    {
        f: '/pt/marcas-carros/jaguar',
        t: '/pt/findcar?makesModels=Jaguar&cmsid=SEO_Jaguar',
    },
    {
        f: '/ro/model-auto/jaguar',
        t: '/ro/findcar?makesModels=Jaguar&cmsid=SEO_Jaguar',
    },
    {
        f: '/ru/marka/jaguar',
        t: '/ru/findcar?makesModels=Jaguar&cmsid=SEO_Jaguar',
    },
    {
        f: '/sl/avto-znamka/jaguar',
        t: '/sl/findcar?makesModels=Jaguar&cmsid=SEO_Jaguar',
    },
    {
        f: '/sk/znacky-aut/jaguar',
        t: '/sk/findcar?makesModels=Jaguar&cmsid=SEO_Jaguar',
    },
    {
        f: '/tr/araba-markalari/jaguar',
        t: '/tr/findcar?makesModels=Jaguar&cmsid=SEO_Jaguar',
    },
    {
        f: '/el/car-makes/jaguar',
        t: '/el/findcar?makesModels=Jaguar&cmsid=SEO_Jaguar',
    },
    {
        f: '/bg/avtomobili-marka/mazda',
        t: '/bg/findcar?makesModels=Mazda&cmsid=SEO_Mazda',
    },
    {
        f: '/cs/znacka-auta/mazda',
        t: '/cs/findcar?makesModels=Mazda&cmsid=SEO_Mazda',
    },
    {
        f: '/de/automarke/mazda',
        t: '/de/findcar?makesModels=Mazda&cmsid=SEO_Mazda',
    },
    {
        f: '/en/car-makes/mazda',
        t: '/en/findcar?makesModels=Mazda&cmsid=SEO_Mazda',
    },
    {
        f: '/es/marca-coche/mazda',
        t: '/es/findcar?makesModels=Mazda&cmsid=SEO_Mazda',
    },
    {
        f: '/fr-be/voiture-marque/mazda',
        t: '/fr-be/findcar?makesModels=Mazda&cmsid=SEO_Mazda',
    },
    {
        f: '/fr-fr/voiture-marque/mazda',
        t: '/fr-fr/findcar?makesModels=Mazda&cmsid=SEO_Mazda',
    },
    {
        f: '/nl-be/auto-merk/mazda',
        t: '/nl-be/findcar?makesModels=Mazda&cmsid=SEO_Mazda',
    },
    {
        f: '/nl-nl/auto-merk/mazda',
        t: '/nl-nl/findcar?makesModels=Mazda&cmsid=SEO_Mazda',
    },
    {
        f: '/hr/proizvodac-automobila/mazda',
        t: '/hr/findcar?makesModels=Mazda&cmsid=SEO_Mazda',
    },
    {
        f: '/hu/auto-marka/mazda',
        t: '/hu/findcar?makesModels=Mazda&cmsid=SEO_Mazda',
    },
    {
        f: '/it/marche-auto/mazda',
        t: '/it/findcar?makesModels=Mazda&cmsid=SEO_Mazda',
    },
    {
        f: '/lt/automobiliu-markes/mazda',
        t: '/lt/findcar?makesModels=Mazda&cmsid=SEO_Mazda',
    },
    {
        f: '/pl/marka-samochodu/mazda',
        t: '/pl/findcar?makesModels=Mazda&cmsid=SEO_Mazda',
    },
    {
        f: '/pt/marcas-carros/mazda',
        t: '/pt/findcar?makesModels=Mazda&cmsid=SEO_Mazda',
    },
    {
        f: '/ro/model-auto/mazda',
        t: '/ro/findcar?makesModels=Mazda&cmsid=SEO_Mazda',
    },
    {
        f: '/ru/marka/mazda',
        t: '/ru/findcar?makesModels=Mazda&cmsid=SEO_Mazda',
    },
    {
        f: '/sl/avto-znamka/mazda',
        t: '/sl/findcar?makesModels=Mazda&cmsid=SEO_Mazda',
    },
    {
        f: '/sk/znacky-aut/mazda',
        t: '/sk/findcar?makesModels=Mazda&cmsid=SEO_Mazda',
    },
    {
        f: '/tr/araba-markalari/mazda',
        t: '/tr/findcar?makesModels=Mazda&cmsid=SEO_Mazda',
    },
    {
        f: '/el/car-makes/mazda',
        t: '/el/findcar?makesModels=Mazda&cmsid=SEO_Mazda',
    },
    {
        f: '/bg/avtomobili-marka/mitsubishi',
        t: '/bg/findcar?makesModels=Mitsubishi&cmsid=SEO_Mitsubishi',
    },
    {
        f: '/cs/znacka-auta/mitsubishi',
        t: '/cs/findcar?makesModels=Mitsubishi&cmsid=SEO_Mitsubishi',
    },
    {
        f: '/de/automarke/mitsubishi',
        t: '/de/findcar?makesModels=Mitsubishi&cmsid=SEO_Mitsubishi',
    },
    {
        f: '/en/car-makes/mitsubishi',
        t: '/en/findcar?makesModels=Mitsubishi&cmsid=SEO_Mitsubishi',
    },
    {
        f: '/es/marca-coche/mitsubishi',
        t: '/es/findcar?makesModels=Mitsubishi&cmsid=SEO_Mitsubishi',
    },
    {
        f: '/fr-be/voiture-marque/mitsubishi',
        t: '/fr-be/findcar?makesModels=Mitsubishi&cmsid=SEO_Mitsubishi',
    },
    {
        f: '/fr-fr/voiture-marque/mitsubishi',
        t: '/fr-fr/findcar?makesModels=Mitsubishi&cmsid=SEO_Mitsubishi',
    },
    {
        f: '/nl-be/auto-merk/mitsubishi',
        t: '/nl-be/findcar?makesModels=Mitsubishi&cmsid=SEO_Mitsubishi',
    },
    {
        f: '/nl-nl/auto-merk/mitsubishi',
        t: '/nl-nl/findcar?makesModels=Mitsubishi&cmsid=SEO_Mitsubishi',
    },
    {
        f: '/hr/proizvodac-automobila/mitsubishi',
        t: '/hr/findcar?makesModels=Mitsubishi&cmsid=SEO_Mitsubishi',
    },
    {
        f: '/hu/auto-marka/mitsubishi',
        t: '/hu/findcar?makesModels=Mitsubishi&cmsid=SEO_Mitsubishi',
    },
    {
        f: '/it/marche-auto/mitsubishi',
        t: '/it/findcar?makesModels=Mitsubishi&cmsid=SEO_Mitsubishi',
    },
    {
        f: '/lt/automobiliu-markes/mitsubishi',
        t: '/lt/findcar?makesModels=Mitsubishi&cmsid=SEO_Mitsubishi',
    },
    {
        f: '/pl/marka-samochodu/mitsubishi',
        t: '/pl/findcar?makesModels=Mitsubishi&cmsid=SEO_Mitsubishi',
    },
    {
        f: '/pt/marcas-carros/mitsubishi',
        t: '/pt/findcar?makesModels=Mitsubishi&cmsid=SEO_Mitsubishi',
    },
    {
        f: '/ro/model-auto/mitsubishi',
        t: '/ro/findcar?makesModels=Mitsubishi&cmsid=SEO_Mitsubishi',
    },
    {
        f: '/ru/marka/mitsubishi',
        t: '/ru/findcar?makesModels=Mitsubishi&cmsid=SEO_Mitsubishi',
    },
    {
        f: '/sl/avto-znamka/mitsubishi',
        t: '/sl/findcar?makesModels=Mitsubishi&cmsid=SEO_Mitsubishi',
    },
    {
        f: '/sk/znacky-aut/mitsubishi',
        t: '/sk/findcar?makesModels=Mitsubishi&cmsid=SEO_Mitsubishi',
    },
    {
        f: '/tr/araba-markalari/mitsubishi',
        t: '/tr/findcar?makesModels=Mitsubishi&cmsid=SEO_Mitsubishi',
    },
    {
        f: '/el/car-makes/mitsubishi',
        t: '/el/findcar?makesModels=Mitsubishi&cmsid=SEO_Mitsubishi',
    },
    {
        f: '/bg/avtomobili-marka/suzuki',
        t: '/bg/findcar?makesModels=Suzuki&cmsid=SEO_Suzuki',
    },
    {
        f: '/cs/znacka-auta/suzuki',
        t: '/cs/findcar?makesModels=Suzuki&cmsid=SEO_Suzuki',
    },
    {
        f: '/de/automarke/suzuki',
        t: '/de/findcar?makesModels=Suzuki&cmsid=SEO_Suzuki',
    },
    {
        f: '/en/car-makes/suzuki',
        t: '/en/findcar?makesModels=Suzuki&cmsid=SEO_Suzuki',
    },
    {
        f: '/es/marca-coche/suzuki',
        t: '/es/findcar?makesModels=Suzuki&cmsid=SEO_Suzuki',
    },
    {
        f: '/fr-be/voiture-marque/suzuki',
        t: '/fr-be/findcar?makesModels=Suzuki&cmsid=SEO_Suzuki',
    },
    {
        f: '/fr-fr/voiture-marque/suzuki',
        t: '/fr-fr/findcar?makesModels=Suzuki&cmsid=SEO_Suzuki',
    },
    {
        f: '/nl-be/auto-merk/suzuki',
        t: '/nl-be/findcar?makesModels=Suzuki&cmsid=SEO_Suzuki',
    },
    {
        f: '/nl-nl/auto-merk/suzuki',
        t: '/nl-nl/findcar?makesModels=Suzuki&cmsid=SEO_Suzuki',
    },
    {
        f: '/hr/proizvodac-automobila/suzuki',
        t: '/hr/findcar?makesModels=Suzuki&cmsid=SEO_Suzuki',
    },
    {
        f: '/hu/auto-marka/suzuki',
        t: '/hu/findcar?makesModels=Suzuki&cmsid=SEO_Suzuki',
    },
    {
        f: '/it/marche-auto/suzuki',
        t: '/it/findcar?makesModels=Suzuki&cmsid=SEO_Suzuki',
    },
    {
        f: '/lt/automobiliu-markes/suzuki',
        t: '/lt/findcar?makesModels=Suzuki&cmsid=SEO_Suzuki',
    },
    {
        f: '/pl/marka-samochodu/suzuki',
        t: '/pl/findcar?makesModels=Suzuki&cmsid=SEO_Suzuki',
    },
    {
        f: '/pt/marcas-carros/suzuki',
        t: '/pt/findcar?makesModels=Suzuki&cmsid=SEO_Suzuki',
    },
    {
        f: '/ro/model-auto/suzuki',
        t: '/ro/findcar?makesModels=Suzuki&cmsid=SEO_Suzuki',
    },
    {
        f: '/ru/marka/suzuki',
        t: '/ru/findcar?makesModels=Suzuki&cmsid=SEO_Suzuki',
    },
    {
        f: '/sl/avto-znamka/suzuki',
        t: '/sl/findcar?makesModels=Suzuki&cmsid=SEO_Suzuki',
    },
    {
        f: '/sk/znacky-aut/suzuki',
        t: '/sk/findcar?makesModels=Suzuki&cmsid=SEO_Suzuki',
    },
    {
        f: '/tr/araba-markalari/suzuki',
        t: '/tr/findcar?makesModels=Suzuki&cmsid=SEO_Suzuki',
    },
    {
        f: '/el/car-makes/suzuki',
        t: '/el/findcar?makesModels=Suzuki&cmsid=SEO_Suzuki',
    },
    {
        f: '/bg/avtomobili-marka/infiniti',
        t: '/bg/findcar?makesModels=Infiniti&cmsid=SEO_Infiniti',
    },
    {
        f: '/cs/znacka-auta/infiniti',
        t: '/cs/findcar?makesModels=Infiniti&cmsid=SEO_Infiniti',
    },
    {
        f: '/de/automarke/infiniti',
        t: '/de/findcar?makesModels=Infiniti&cmsid=SEO_Infiniti',
    },
    {
        f: '/en/car-makes/infiniti',
        t: '/en/findcar?makesModels=Infiniti&cmsid=SEO_Infiniti',
    },
    {
        f: '/es/marca-coche/infiniti',
        t: '/es/findcar?makesModels=Infiniti&cmsid=SEO_Infiniti',
    },
    {
        f: '/fr-be/voiture-marque/infiniti',
        t: '/fr-be/findcar?makesModels=Infiniti&cmsid=SEO_Infiniti',
    },
    {
        f: '/fr-fr/voiture-marque/infiniti',
        t: '/fr-fr/findcar?makesModels=Infiniti&cmsid=SEO_Infiniti',
    },
    {
        f: '/nl-be/auto-merk/infiniti',
        t: '/nl-be/findcar?makesModels=Infiniti&cmsid=SEO_Infiniti',
    },
    {
        f: '/nl-nl/auto-merk/infiniti',
        t: '/nl-nl/findcar?makesModels=Infiniti&cmsid=SEO_Infiniti',
    },
    {
        f: '/hr/proizvodac-automobila/infiniti',
        t: '/hr/findcar?makesModels=Infiniti&cmsid=SEO_Infiniti',
    },
    {
        f: '/hu/auto-marka/infiniti',
        t: '/hu/findcar?makesModels=Infiniti&cmsid=SEO_Infiniti',
    },
    {
        f: '/it/marche-auto/infiniti',
        t: '/it/findcar?makesModels=Infiniti&cmsid=SEO_Infiniti',
    },
    {
        f: '/lt/automobiliu-markes/infiniti',
        t: '/lt/findcar?makesModels=Infiniti&cmsid=SEO_Infiniti',
    },
    {
        f: '/pl/marka-samochodu/infiniti',
        t: '/pl/findcar?makesModels=Infiniti&cmsid=SEO_Infiniti',
    },
    {
        f: '/pt/marcas-carros/infiniti',
        t: '/pt/findcar?makesModels=Infiniti&cmsid=SEO_Infiniti',
    },
    {
        f: '/ro/model-auto/infiniti',
        t: '/ro/findcar?makesModels=Infiniti&cmsid=SEO_Infiniti',
    },
    {
        f: '/ru/marka/infiniti',
        t: '/ru/findcar?makesModels=Infiniti&cmsid=SEO_Infiniti',
    },
    {
        f: '/sl/avto-znamka/infiniti',
        t: '/sl/findcar?makesModels=Infiniti&cmsid=SEO_Infiniti',
    },
    {
        f: '/sk/znacky-aut/infiniti',
        t: '/sk/findcar?makesModels=Infiniti&cmsid=SEO_Infiniti',
    },
    {
        f: '/tr/araba-markalari/infiniti',
        t: '/tr/findcar?makesModels=Infiniti&cmsid=SEO_Infiniti',
    },
    {
        f: '/el/car-makes/infiniti',
        t: '/el/findcar?makesModels=Infiniti&cmsid=SEO_Infiniti',
    },
    {
        f: '/bg/avtomobili-marka/lexus',
        t: '/bg/findcar?makesModels=Lexus&cmsid=SEO_Lexus',
    },
    {
        f: '/cs/znacka-auta/lexus',
        t: '/cs/findcar?makesModels=Lexus&cmsid=SEO_Lexus',
    },
    {
        f: '/de/automarke/lexus',
        t: '/de/findcar?makesModels=Lexus&cmsid=SEO_Lexus',
    },
    {
        f: '/en/car-makes/lexus',
        t: '/en/findcar?makesModels=Lexus&cmsid=SEO_Lexus',
    },
    {
        f: '/es/marca-coche/lexus',
        t: '/es/findcar?makesModels=Lexus&cmsid=SEO_Lexus',
    },
    {
        f: '/fr-be/voiture-marque/lexus',
        t: '/fr-be/findcar?makesModels=Lexus&cmsid=SEO_Lexus',
    },
    {
        f: '/fr-fr/voiture-marque/lexus',
        t: '/fr-fr/findcar?makesModels=Lexus&cmsid=SEO_Lexus',
    },
    {
        f: '/nl-be/auto-merk/lexus',
        t: '/nl-be/findcar?makesModels=Lexus&cmsid=SEO_Lexus',
    },
    {
        f: '/nl-nl/auto-merk/lexus',
        t: '/nl-nl/findcar?makesModels=Lexus&cmsid=SEO_Lexus',
    },
    {
        f: '/hr/proizvodac-automobila/lexus',
        t: '/hr/findcar?makesModels=Lexus&cmsid=SEO_Lexus',
    },
    {
        f: '/hu/auto-marka/lexus',
        t: '/hu/findcar?makesModels=Lexus&cmsid=SEO_Lexus',
    },
    {
        f: '/it/marche-auto/lexus',
        t: '/it/findcar?makesModels=Lexus&cmsid=SEO_Lexus',
    },
    {
        f: '/lt/automobiliu-markes/lexus',
        t: '/lt/findcar?makesModels=Lexus&cmsid=SEO_Lexus',
    },
    {
        f: '/pl/marka-samochodu/lexus',
        t: '/pl/findcar?makesModels=Lexus&cmsid=SEO_Lexus',
    },
    {
        f: '/pt/marcas-carros/lexus',
        t: '/pt/findcar?makesModels=Lexus&cmsid=SEO_Lexus',
    },
    {
        f: '/ro/model-auto/lexus',
        t: '/ro/findcar?makesModels=Lexus&cmsid=SEO_Lexus',
    },
    {
        f: '/ru/marka/lexus',
        t: '/ru/findcar?makesModels=Lexus&cmsid=SEO_Lexus',
    },
    {
        f: '/sl/avto-znamka/lexus',
        t: '/sl/findcar?makesModels=Lexus&cmsid=SEO_Lexus',
    },
    {
        f: '/sk/znacky-aut/lexus',
        t: '/sk/findcar?makesModels=Lexus&cmsid=SEO_Lexus',
    },
    {
        f: '/tr/araba-markalari/lexus',
        t: '/tr/findcar?makesModels=Lexus&cmsid=SEO_Lexus',
    },
    {
        f: '/el/car-makes/lexus',
        t: '/el/findcar?makesModels=Lexus&cmsid=SEO_Lexus',
    },
    {
        f: '/bg/avtomobili-marka/maserati',
        t: '/bg/findcar?makesModels=Maserati&cmsid=SEO_Maserati',
    },
    {
        f: '/cs/znacka-auta/maserati',
        t: '/cs/findcar?makesModels=Maserati&cmsid=SEO_Maserati',
    },
    {
        f: '/de/automarke/maserati',
        t: '/de/findcar?makesModels=Maserati&cmsid=SEO_Maserati',
    },
    {
        f: '/en/car-makes/maserati',
        t: '/en/findcar?makesModels=Maserati&cmsid=SEO_Maserati',
    },
    {
        f: '/es/marca-coche/maserati',
        t: '/es/findcar?makesModels=Maserati&cmsid=SEO_Maserati',
    },
    {
        f: '/fr-be/voiture-marque/maserati',
        t: '/fr-be/findcar?makesModels=Maserati&cmsid=SEO_Maserati',
    },
    {
        f: '/fr-fr/voiture-marque/maserati',
        t: '/fr-fr/findcar?makesModels=Maserati&cmsid=SEO_Maserati',
    },
    {
        f: '/nl-be/auto-merk/maserati',
        t: '/nl-be/findcar?makesModels=Maserati&cmsid=SEO_Maserati',
    },
    {
        f: '/nl-nl/auto-merk/maserati',
        t: '/nl-nl/findcar?makesModels=Maserati&cmsid=SEO_Maserati',
    },
    {
        f: '/hr/proizvodac-automobila/maserati',
        t: '/hr/findcar?makesModels=Maserati&cmsid=SEO_Maserati',
    },
    {
        f: '/hu/auto-marka/maserati',
        t: '/hu/findcar?makesModels=Maserati&cmsid=SEO_Maserati',
    },
    {
        f: '/it/marche-auto/maserati',
        t: '/it/findcar?makesModels=Maserati&cmsid=SEO_Maserati',
    },
    {
        f: '/lt/automobiliu-markes/maserati',
        t: '/lt/findcar?makesModels=Maserati&cmsid=SEO_Maserati',
    },
    {
        f: '/pl/marka-samochodu/maserati',
        t: '/pl/findcar?makesModels=Maserati&cmsid=SEO_Maserati',
    },
    {
        f: '/pt/marcas-carros/maserati',
        t: '/pt/findcar?makesModels=Maserati&cmsid=SEO_Maserati',
    },
    {
        f: '/ro/model-auto/maserati',
        t: '/ro/findcar?makesModels=Maserati&cmsid=SEO_Maserati',
    },
    {
        f: '/ru/marka/maserati',
        t: '/ru/findcar?makesModels=Maserati&cmsid=SEO_Maserati',
    },
    {
        f: '/sl/avto-znamka/maserati',
        t: '/sl/findcar?makesModels=Maserati&cmsid=SEO_Maserati',
    },
    {
        f: '/sk/znacky-aut/maserati',
        t: '/sk/findcar?makesModels=Maserati&cmsid=SEO_Maserati',
    },
    {
        f: '/tr/araba-markalari/maserati',
        t: '/tr/findcar?makesModels=Maserati&cmsid=SEO_Maserati',
    },
    {
        f: '/el/car-makes/maserati',
        t: '/el/findcar?makesModels=Maserati&cmsid=SEO_Maserati',
    },
    {
        f: '/bg/avtomobili-marka/dodge',
        t: '/bg/findcar?makesModels=Dodge&cmsid=SEO_Dodge',
    },
    {
        f: '/cs/znacka-auta/dodge',
        t: '/cs/findcar?makesModels=Dodge&cmsid=SEO_Dodge',
    },
    {
        f: '/de/automarke/dodge',
        t: '/de/findcar?makesModels=Dodge&cmsid=SEO_Dodge',
    },
    {
        f: '/en/car-makes/dodge',
        t: '/en/findcar?makesModels=Dodge&cmsid=SEO_Dodge',
    },
    {
        f: '/es/marca-coche/dodge',
        t: '/es/findcar?makesModels=Dodge&cmsid=SEO_Dodge',
    },
    {
        f: '/fr-be/voiture-marque/dodge',
        t: '/fr-be/findcar?makesModels=Dodge&cmsid=SEO_Dodge',
    },
    {
        f: '/fr-fr/voiture-marque/dodge',
        t: '/fr-fr/findcar?makesModels=Dodge&cmsid=SEO_Dodge',
    },
    {
        f: '/nl-be/auto-merk/dodge',
        t: '/nl-be/findcar?makesModels=Dodge&cmsid=SEO_Dodge',
    },
    {
        f: '/nl-nl/auto-merk/dodge',
        t: '/nl-nl/findcar?makesModels=Dodge&cmsid=SEO_Dodge',
    },
    {
        f: '/hr/proizvodac-automobila/dodge',
        t: '/hr/findcar?makesModels=Dodge&cmsid=SEO_Dodge',
    },
    {
        f: '/hu/auto-marka/dodge',
        t: '/hu/findcar?makesModels=Dodge&cmsid=SEO_Dodge',
    },
    {
        f: '/it/marche-auto/dodge',
        t: '/it/findcar?makesModels=Dodge&cmsid=SEO_Dodge',
    },
    {
        f: '/lt/automobiliu-markes/dodge',
        t: '/lt/findcar?makesModels=Dodge&cmsid=SEO_Dodge',
    },
    {
        f: '/pl/marka-samochodu/dodge',
        t: '/pl/findcar?makesModels=Dodge&cmsid=SEO_Dodge',
    },
    {
        f: '/pt/marcas-carros/dodge',
        t: '/pt/findcar?makesModels=Dodge&cmsid=SEO_Dodge',
    },
    {
        f: '/ro/model-auto/dodge',
        t: '/ro/findcar?makesModels=Dodge&cmsid=SEO_Dodge',
    },
    {
        f: '/ru/marka/dodge',
        t: '/ru/findcar?makesModels=Dodge&cmsid=SEO_Dodge',
    },
    {
        f: '/sl/avto-znamka/dodge',
        t: '/sl/findcar?makesModels=Dodge&cmsid=SEO_Dodge',
    },
    {
        f: '/sk/znacky-aut/dodge',
        t: '/sk/findcar?makesModels=Dodge&cmsid=SEO_Dodge',
    },
    {
        f: '/tr/araba-markalari/dodge',
        t: '/tr/findcar?makesModels=Dodge&cmsid=SEO_Dodge',
    },
    {
        f: '/el/car-makes/dodge',
        t: '/el/findcar?makesModels=Dodge&cmsid=SEO_Dodge',
    },
    {
        f: '/bg/avtomobili-marka/smart',
        t: '/bg/findcar?makesModels=Smart&cmsid=SEO_Smart',
    },
    {
        f: '/cs/znacka-auta/smart',
        t: '/cs/findcar?makesModels=Smart&cmsid=SEO_Smart',
    },
    {
        f: '/de/automarke/smart',
        t: '/de/findcar?makesModels=Smart&cmsid=SEO_Smart',
    },
    {
        f: '/en/car-makes/smart',
        t: '/en/findcar?makesModels=Smart&cmsid=SEO_Smart',
    },
    {
        f: '/es/marca-coche/smart',
        t: '/es/findcar?makesModels=Smart&cmsid=SEO_Smart',
    },
    {
        f: '/fr-be/voiture-marque/smart',
        t: '/fr-be/findcar?makesModels=Smart&cmsid=SEO_Smart',
    },
    {
        f: '/fr-fr/voiture-marque/smart',
        t: '/fr-fr/findcar?makesModels=Smart&cmsid=SEO_Smart',
    },
    {
        f: '/nl-be/auto-merk/smart',
        t: '/nl-be/findcar?makesModels=Smart&cmsid=SEO_Smart',
    },
    {
        f: '/nl-nl/auto-merk/smart',
        t: '/nl-nl/findcar?makesModels=Smart&cmsid=SEO_Smart',
    },
    {
        f: '/hr/proizvodac-automobila/smart',
        t: '/hr/findcar?makesModels=Smart&cmsid=SEO_Smart',
    },
    {
        f: '/hu/auto-marka/smart',
        t: '/hu/findcar?makesModels=Smart&cmsid=SEO_Smart',
    },
    {
        f: '/it/marche-auto/smart',
        t: '/it/findcar?makesModels=Smart&cmsid=SEO_Smart',
    },
    {
        f: '/lt/automobiliu-markes/smart',
        t: '/lt/findcar?makesModels=Smart&cmsid=SEO_Smart',
    },
    {
        f: '/pl/marka-samochodu/smart',
        t: '/pl/findcar?makesModels=Smart&cmsid=SEO_Smart',
    },
    {
        f: '/pt/marcas-carros/smart',
        t: '/pt/findcar?makesModels=Smart&cmsid=SEO_Smart',
    },
    {
        f: '/ro/model-auto/smart',
        t: '/ro/findcar?makesModels=Smart&cmsid=SEO_Smart',
    },
    {
        f: '/ru/marka/smart',
        t: '/ru/findcar?makesModels=Smart&cmsid=SEO_Smart',
    },
    {
        f: '/sl/avto-znamka/smart',
        t: '/sl/findcar?makesModels=Smart&cmsid=SEO_Smart',
    },
    {
        f: '/sk/znacky-aut/smart',
        t: '/sk/findcar?makesModels=Smart&cmsid=SEO_Smart',
    },
    {
        f: '/tr/araba-markalari/smart',
        t: '/tr/findcar?makesModels=Smart&cmsid=SEO_Smart',
    },
    {
        f: '/el/car-makes/smart',
        t: '/el/findcar?makesModels=Smart&cmsid=SEO_Smart',
    },
    {
        f: '/bg/avtomobili-marka/dacia',
        t: '/bg/findcar?makesModels=Dacia&cmsid=SEO_Dacia',
    },
    {
        f: '/cs/znacka-auta/dacia',
        t: '/cs/findcar?makesModels=Dacia&cmsid=SEO_Dacia',
    },
    {
        f: '/de/automarke/dacia',
        t: '/de/findcar?makesModels=Dacia&cmsid=SEO_Dacia',
    },
    {
        f: '/en/car-makes/dacia',
        t: '/en/findcar?makesModels=Dacia&cmsid=SEO_Dacia',
    },
    {
        f: '/es/marca-coche/dacia',
        t: '/es/findcar?makesModels=Dacia&cmsid=SEO_Dacia',
    },
    {
        f: '/fr-be/voiture-marque/dacia',
        t: '/fr-be/findcar?makesModels=Dacia&cmsid=SEO_Dacia',
    },
    {
        f: '/fr-fr/voiture-marque/dacia',
        t: '/fr-fr/findcar?makesModels=Dacia&cmsid=SEO_Dacia',
    },
    {
        f: '/nl-be/auto-merk/dacia',
        t: '/nl-be/findcar?makesModels=Dacia&cmsid=SEO_Dacia',
    },
    {
        f: '/nl-nl/auto-merk/dacia',
        t: '/nl-nl/findcar?makesModels=Dacia&cmsid=SEO_Dacia',
    },
    {
        f: '/hr/proizvodac-automobila/dacia',
        t: '/hr/findcar?makesModels=Dacia&cmsid=SEO_Dacia',
    },
    {
        f: '/hu/auto-marka/dacia',
        t: '/hu/findcar?makesModels=Dacia&cmsid=SEO_Dacia',
    },
    {
        f: '/it/marche-auto/dacia',
        t: '/it/findcar?makesModels=Dacia&cmsid=SEO_Dacia',
    },
    {
        f: '/lt/automobiliu-markes/dacia',
        t: '/lt/findcar?makesModels=Dacia&cmsid=SEO_Dacia',
    },
    {
        f: '/pl/marka-samochodu/dacia',
        t: '/pl/findcar?makesModels=Dacia&cmsid=SEO_Dacia',
    },
    {
        f: '/pt/marcas-carros/dacia',
        t: '/pt/findcar?makesModels=Dacia&cmsid=SEO_Dacia',
    },
    {
        f: '/ro/model-auto/dacia',
        t: '/ro/findcar?makesModels=Dacia&cmsid=SEO_Dacia',
    },
    {
        f: '/ru/marka/dacia',
        t: '/ru/findcar?makesModels=Dacia&cmsid=SEO_Dacia',
    },
    {
        f: '/sl/avto-znamka/dacia',
        t: '/sl/findcar?makesModels=Dacia&cmsid=SEO_Dacia',
    },
    {
        f: '/sk/znacky-aut/dacia',
        t: '/sk/findcar?makesModels=Dacia&cmsid=SEO_Dacia',
    },
    {
        f: '/tr/araba-markalari/dacia',
        t: '/tr/findcar?makesModels=Dacia&cmsid=SEO_Dacia',
    },
    {
        f: '/el/car-makes/dacia',
        t: '/el/findcar?makesModels=Dacia&cmsid=SEO_Dacia',
    },
    {
        f: '/bg/avtomobili-marka/chrysler',
        t: '/bg/findcar?makesModels=Chrysler&cmsid=SEO_Chrysler',
    },
    {
        f: '/cs/znacka-auta/chrysler',
        t: '/cs/findcar?makesModels=Chrysler&cmsid=SEO_Chrysler',
    },
    {
        f: '/de/automarke/chrysler',
        t: '/de/findcar?makesModels=Chrysler&cmsid=SEO_Chrysler',
    },
    {
        f: '/en/car-makes/chrysler',
        t: '/en/findcar?makesModels=Chrysler&cmsid=SEO_Chrysler',
    },
    {
        f: '/es/marca-coche/chrysler',
        t: '/es/findcar?makesModels=Chrysler&cmsid=SEO_Chrysler',
    },
    {
        f: '/fr-be/voiture-marque/chrysler',
        t: '/fr-be/findcar?makesModels=Chrysler&cmsid=SEO_Chrysler',
    },
    {
        f: '/fr-fr/voiture-marque/chrysler',
        t: '/fr-fr/findcar?makesModels=Chrysler&cmsid=SEO_Chrysler',
    },
    {
        f: '/nl-be/auto-merk/chrysler',
        t: '/nl-be/findcar?makesModels=Chrysler&cmsid=SEO_Chrysler',
    },
    {
        f: '/nl-nl/auto-merk/chrysler',
        t: '/nl-nl/findcar?makesModels=Chrysler&cmsid=SEO_Chrysler',
    },
    {
        f: '/hr/proizvodac-automobila/chrysler',
        t: '/hr/findcar?makesModels=Chrysler&cmsid=SEO_Chrysler',
    },
    {
        f: '/hu/auto-marka/chrysler',
        t: '/hu/findcar?makesModels=Chrysler&cmsid=SEO_Chrysler',
    },
    {
        f: '/it/marche-auto/chrysler',
        t: '/it/findcar?makesModels=Chrysler&cmsid=SEO_Chrysler',
    },
    {
        f: '/lt/automobiliu-markes/chrysler',
        t: '/lt/findcar?makesModels=Chrysler&cmsid=SEO_Chrysler',
    },
    {
        f: '/pl/marka-samochodu/chrysler',
        t: '/pl/findcar?makesModels=Chrysler&cmsid=SEO_Chrysler',
    },
    {
        f: '/pt/marcas-carros/chrysler',
        t: '/pt/findcar?makesModels=Chrysler&cmsid=SEO_Chrysler',
    },
    {
        f: '/ro/model-auto/chrysler',
        t: '/ro/findcar?makesModels=Chrysler&cmsid=SEO_Chrysler',
    },
    {
        f: '/ru/marka/chrysler',
        t: '/ru/findcar?makesModels=Chrysler&cmsid=SEO_Chrysler',
    },
    {
        f: '/sl/avto-znamka/chrysler',
        t: '/sl/findcar?makesModels=Chrysler&cmsid=SEO_Chrysler',
    },
    {
        f: '/sk/znacky-aut/chrysler',
        t: '/sk/findcar?makesModels=Chrysler&cmsid=SEO_Chrysler',
    },
    {
        f: '/tr/araba-markalari/chrysler',
        t: '/tr/findcar?makesModels=Chrysler&cmsid=SEO_Chrysler',
    },
    {
        f: '/el/car-makes/chrysler',
        t: '/el/findcar?makesModels=Chrysler&cmsid=SEO_Chrysler',
    },
    {
        f: '/bg/avtomobili-marka/sangyong',
        t: '/bg/findcar?makesModels=Sangyong&cmsid=SEO_Sangyong',
    },
    {
        f: '/cs/znacka-auta/sangyong',
        t: '/cs/findcar?makesModels=Sangyong&cmsid=SEO_Sangyong',
    },
    {
        f: '/de/automarke/sangyong',
        t: '/de/findcar?makesModels=Sangyong&cmsid=SEO_Sangyong',
    },
    {
        f: '/en/car-makes/sangyong',
        t: '/en/findcar?makesModels=Sangyong&cmsid=SEO_Sangyong',
    },
    {
        f: '/es/marca-coche/sangyong',
        t: '/es/findcar?makesModels=Sangyong&cmsid=SEO_Sangyong',
    },
    {
        f: '/fr-be/voiture-marque/sangyong',
        t: '/fr-be/findcar?makesModels=Sangyong&cmsid=SEO_Sangyong',
    },
    {
        f: '/fr-fr/voiture-marque/sangyong',
        t: '/fr-fr/findcar?makesModels=Sangyong&cmsid=SEO_Sangyong',
    },
    {
        f: '/nl-be/auto-merk/sangyong',
        t: '/nl-be/findcar?makesModels=Sangyong&cmsid=SEO_Sangyong',
    },
    {
        f: '/nl-nl/auto-merk/sangyong',
        t: '/nl-nl/findcar?makesModels=Sangyong&cmsid=SEO_Sangyong',
    },
    {
        f: '/hr/proizvodac-automobila/sangyong',
        t: '/hr/findcar?makesModels=Sangyong&cmsid=SEO_Sangyong',
    },
    {
        f: '/hu/auto-marka/sangyong',
        t: '/hu/findcar?makesModels=Sangyong&cmsid=SEO_Sangyong',
    },
    {
        f: '/it/marche-auto/sangyong',
        t: '/it/findcar?makesModels=Sangyong&cmsid=SEO_Sangyong',
    },
    {
        f: '/lt/automobiliu-markes/sangyong',
        t: '/lt/findcar?makesModels=Sangyong&cmsid=SEO_Sangyong',
    },
    {
        f: '/pl/marka-samochodu/sangyong',
        t: '/pl/findcar?makesModels=Sangyong&cmsid=SEO_Sangyong',
    },
    {
        f: '/pt/marcas-carros/sangyong',
        t: '/pt/findcar?makesModels=Sangyong&cmsid=SEO_Sangyong',
    },
    {
        f: '/ro/model-auto/sangyong',
        t: '/ro/findcar?makesModels=Sangyong&cmsid=SEO_Sangyong',
    },
    {
        f: '/ru/marka/sangyong',
        t: '/ru/findcar?makesModels=Sangyong&cmsid=SEO_Sangyong',
    },
    {
        f: '/sl/avto-znamka/sangyong',
        t: '/sl/findcar?makesModels=Sangyong&cmsid=SEO_Sangyong',
    },
    {
        f: '/sk/znacky-aut/sangyong',
        t: '/sk/findcar?makesModels=Sangyong&cmsid=SEO_Sangyong',
    },
    {
        f: '/tr/araba-markalari/sangyong',
        t: '/tr/findcar?makesModels=Sangyong&cmsid=SEO_Sangyong',
    },
    {
        f: '/el/car-makes/sangyong',
        t: '/el/findcar?makesModels=Sangyong&cmsid=SEO_Sangyong',
    },
    {
        f: '/bg/avtomobili-marka/saab',
        t: '/bg/findcar?makesModels=Saab&cmsid=SEO_Saab',
    },
    {
        f: '/cs/znacka-auta/saab',
        t: '/cs/findcar?makesModels=Saab&cmsid=SEO_Saab',
    },
    {
        f: '/de/automarke/saab',
        t: '/de/findcar?makesModels=Saab&cmsid=SEO_Saab',
    },
    {
        f: '/en/car-makes/saab',
        t: '/en/findcar?makesModels=Saab&cmsid=SEO_Saab',
    },
    {
        f: '/es/marca-coche/saab',
        t: '/es/findcar?makesModels=Saab&cmsid=SEO_Saab',
    },
    {
        f: '/fr-be/voiture-marque/saab',
        t: '/fr-be/findcar?makesModels=Saab&cmsid=SEO_Saab',
    },
    {
        f: '/fr-fr/voiture-marque/saab',
        t: '/fr-fr/findcar?makesModels=Saab&cmsid=SEO_Saab',
    },
    {
        f: '/nl-be/auto-merk/saab',
        t: '/nl-be/findcar?makesModels=Saab&cmsid=SEO_Saab',
    },
    {
        f: '/nl-nl/auto-merk/saab',
        t: '/nl-nl/findcar?makesModels=Saab&cmsid=SEO_Saab',
    },
    {
        f: '/hr/proizvodac-automobila/saab',
        t: '/hr/findcar?makesModels=Saab&cmsid=SEO_Saab',
    },
    {
        f: '/hu/auto-marka/saab',
        t: '/hu/findcar?makesModels=Saab&cmsid=SEO_Saab',
    },
    {
        f: '/it/marche-auto/saab',
        t: '/it/findcar?makesModels=Saab&cmsid=SEO_Saab',
    },
    {
        f: '/lt/automobiliu-markes/saab',
        t: '/lt/findcar?makesModels=Saab&cmsid=SEO_Saab',
    },
    {
        f: '/pl/marka-samochodu/saab',
        t: '/pl/findcar?makesModels=Saab&cmsid=SEO_Saab',
    },
    {
        f: '/pt/marcas-carros/saab',
        t: '/pt/findcar?makesModels=Saab&cmsid=SEO_Saab',
    },
    {
        f: '/ro/model-auto/saab',
        t: '/ro/findcar?makesModels=Saab&cmsid=SEO_Saab',
    },
    {
        f: '/ru/marka/saab',
        t: '/ru/findcar?makesModels=Saab&cmsid=SEO_Saab',
    },
    {
        f: '/sl/avto-znamka/saab',
        t: '/sl/findcar?makesModels=Saab&cmsid=SEO_Saab',
    },
    {
        f: '/sk/znacky-aut/saab',
        t: '/sk/findcar?makesModels=Saab&cmsid=SEO_Saab',
    },
    {
        f: '/tr/araba-markalari/saab',
        t: '/tr/findcar?makesModels=Saab&cmsid=SEO_Saab',
    },
    {
        f: '/el/car-makes/saab',
        t: '/el/findcar?makesModels=Saab&cmsid=SEO_Saab',
    },

    {
        f: '/fi/automerkit/audi',
        t: '/fi/findcar?makesModels=Audi&cmsid=SEO_Audi',
    },
    {
        f: '/fi/automerkit/audi/a1',
        t: '/fi/findcar?makesModels=Audi%2CA1&cmsid=Audi_A1_Text',
    },
    {
        f: '/fi/automerkit/audi/a2',
        t: '/fi/findcar?makesModels=Audi%2CA2&cmsid=Audi_A2_Text',
    },
    {
        f: '/fi/automerkit/audi/a3',
        t: '/fi/findcar?makesModels=Audi%2CA3&cmsid=Audi_A3_Text',
    },
    {
        f: '/fi/automerkit/audi/a4',
        t: '/fi/findcar?makesModels=Audi%2CA4&cmsid=Audi_A4_Text',
    },
    {
        f: '/fi/automerkit/audi/a5',
        t: '/fi/findcar?makesModels=Audi%2CA5&cmsid=Audi_A5_Text',
    },
    {
        f: '/fi/automerkit/audi/a6',
        t: '/fi/findcar?makesModels=Audi%2CA6&cmsid=Audi_A6_Text',
    },
    {
        f: '/fi/automerkit/audi/q3',
        t: '/fi/findcar?makesModels=Audi%2CQ3&cmsid=Audi_Q3_Text',
    },
    {
        f: '/fi/automerkit/audi/q5',
        t: '/fi/findcar?makesModels=Audi%2CQ5&cmsid=Audi_Q5_Text',
    },
    {
        f: '/fi/automerkit/audi/q7',
        t: '/fi/findcar?makesModels=Audi%2CQ7&cmsid=Audi_Q7_Text',
    },
    {
        f: '/fi/automerkit/bmw',
        t: '/fi/findcar?makesModels=BMW&cmsid=SEO_BMW',
    },
    {
        f: '/fi/automerkit/bmw/1-sarja',
        t: '/fi/findcar?makesModels=BMW%2C1%20series&cmsid=BMW_1series_Text',
    },
    {
        f: '/fi/automerkit/bmw/2-sarja',
        t: '/fi/findcar?makesModels=BMW%2C2%20series&cmsid=BMW_2series_Text',
    },
    {
        f: '/fi/automerkit/bmw/3-sarja',
        t: '/fi/findcar?makesModels=BMW%2C3%20series&cmsid=BMW_3series_Text',
    },
    {
        f: '/fi/automerkit/bmw/4-sarja',
        t: '/fi/findcar?makesModels=BMW%2C4%20series&cmsid=BMW_4series_Text',
    },
    {
        f: '/fi/automerkit/bmw/5-sarja',
        t: '/fi/findcar?makesModels=BMW%2C5%20series&cmsid=BMW_5series_Text',
    },
    {
        f: '/fi/automerkit/bmw/x1',
        t: '/fi/findcar?makesModels=BMW%2CX1&cmsid=BMW_X1_Text',
    },
    {
        f: '/fi/automerkit/bmw/x3',
        t: '/fi/findcar?makesModels=BMW%2CX3&cmsid=BMW_X3_Text',
    },
    {
        f: '/fi/automerkit/bmw/x5',
        t: '/fi/findcar?makesModels=BMW%2CX5&cmsid=BMW_X5_Text',
    },
    {
        f: '/fi/automerkit/bmw/x6',
        t: '/fi/findcar?makesModels=BMW%2CX6&cmsid=BMW_X6_Text',
    },
    {
        f: '/fi/automerkit/citroen',
        t: '/fi/findcar?makesModels=Citro%C3%ABn&cmsid=SEO_Citro%C3%ABn',
    },
    {
        f: '/fi/automerkit/citroen/c3',
        t: '/fi/findcar?makesModels=Citro%C3%ABn%2CC3&cmsid=Citro%C3%ABn_C3_text',
    },
    {
        f: '/fi/automerkit/citroen/c4',
        t: '/fi/findcar?makesModels=Citro%C3%ABn%2CC4&cmsid=Citro%C3%ABn_C4_text',
    },
    {
        f: '/fi/automerkit/citroen/c5',
        t: '/fi/findcar?makesModels=Citro%C3%ABn%2CC5&cmsid=Citro%C3%ABn_C5_text',
    },
    {
        f: '/fi/automerkit/citroen/ds3',
        t: '/fi/findcar?makesModels=Citro%C3%ABn%2CDS3&cmsid=Citro%C3%ABn_DS3_text',
    },
    {
        f: '/fi/automerkit/citroen/ds4',
        t: '/fi/findcar?makesModels=Citro%C3%ABn%2CDS4&cmsid=Citro%C3%ABn_DS4_text',
    },
    {
        f: '/fi/automerkit/citroen/berlingo',
        t: '/fi/findcar?makesModels=Citro%C3%ABn%2CBerlingo&cmsid=Citro%C3%ABn_Berlingo_text',
    },
    {
        f: '/fi/automerkit/citroen/jumpy',
        t: '/fi/findcar?makesModels=Citro%C3%ABn%2CJumpy&cmsid=Citro%C3%ABn_Dispatch_text',
    },
    {
        f: '/fi/automerkit/fiat',
        t: '/fi/findcar?makesModels=Fiat&cmsid=SEO_Fiat',
    },
    {
        f: '/fi/automerkit/fiat/500',
        t: '/fi/findcar?makesModels=Fiat%2C500&cmsid=Fiat_500_Text',
    },
    {
        f: '/fi/automerkit/fiat/panda',
        t: '/fi/findcar?makesModels=Fiat%2Cpanda&cmsid=Fiat_panda_Text',
    },
    {
        f: '/fi/automerkit/fiat/punto',
        t: '/fi/findcar?makesModels=Fiat%2Cpunto&cmsid=Fiat_punto_Text',
    },
    {
        f: '/fi/automerkit/fiat/bravo',
        t: '/fi/findcar?makesModels=Fiat%2Cbravo&cmsid=Fiat_bravo_Text',
    },
    {
        f: '/fi/automerkit/fiat/freemont',
        t: '/fi/findcar?makesModels=Fiat%2Cfreemont&cmsid=Fiat_freemont_Text',
    },
    {
        f: '/fi/automerkit/fiat/doblo',
        t: '/fi/findcar?makesModels=Fiat%2Cdoblo&cmsid=Fiat_doblo_Text',
    },
    {
        f: '/fi/automerkit/fiat/ducato',
        t: '/fi/findcar?makesModels=Fiat%2Cducato&cmsid=Fiat_ducato_Text',
    },
    {
        f: '/fi/automerkit/ford',
        t: '/fi/findcar?makesModels=Ford&cmsid=SEO_Ford',
    },
    {
        f: '/fi/automerkit/ford/fiesta',
        t: '/fi/findcar?makesModels=Ford%2CFiesta&cmsid=Ford_Fiesta_Text',
    },
    {
        f: '/fi/automerkit/ford/focus',
        t: '/fi/findcar?makesModels=Ford%2CFocus&cmsid=Ford_Focus_Text',
    },
    {
        f: '/fi/automerkit/ford/mondeo',
        t: '/fi/findcar?makesModels=Ford%2CMondeo&cmsid=Ford_Mondeo_Text',
    },
    {
        f: '/fi/automerkit/ford/transit',
        t: '/fi/findcar?makesModels=Ford%2CTransit&cmsid=Ford_Transit_Text',
    },
    {
        f: '/fi/automerkit/mercedes',
        t: '/fi/findcar?makesModels=Mercedes-Benz&cmsid=SEO_Mercedes',
    },
    {
        f: '/fi/automerkit/mercedes/a-sarja',
        t: '/fi/findcar?makesModels=Mercedes-Benz%2CA&cmsid=Mercedes_A-class_Text',
    },
    {
        f: '/fi/automerkit/mercedes/b-sarja',
        t: '/fi/findcar?makesModels=Mercedes-Benz%2CB&cmsid=Mercedes_B-class_Text',
    },
    {
        f: '/fi/automerkit/mercedes/c-sarja',
        t: '/fi/findcar?makesModels=Mercedes-Benz%2CC&cmsid=Mercedes_C-class_Text',
    },
    {
        f: '/fi/automerkit/mercedes/e-sarja',
        t: '/fi/findcar?makesModels=Mercedes-Benz%2CE&cmsid=Mercedes_E-class_Text',
    },
    {
        f: '/fi/automerkit/mercedes/vito',
        t: '/fi/findcar?makesModels=Mercedes-Benz%2CVito&cmsid=Mercedes_Vito_Text',
    },
    {
        f: '/fi/automerkit/mercedes/sprinter',
        t: '/fi/findcar?makesModels=Mercedes-Benz%2CSprinter&cmsid=Mercedes_Sprinter_Text',
    },
    {
        f: '/fi/automerkit/nissan',
        t: '/fi/findcar?makesModels=Nissan&cmsid=SEO_Nissan',
    },
    {
        f: '/fi/automerkit/nissan/juke',
        t: '/fi/findcar?makesModels=Nissan%2CJuke&cmsid=Nissan_Juke_Text',
    },
    {
        f: '/fi/automerkit/nissan/pathfinder',
        t: '/fi/findcar?makesModels=Nissan%2CPathfinder&cmsid=Nissan_Pathfinder_Text',
    },
    {
        f: '/fi/automerkit/nissan/qashqai',
        t: '/fi/findcar?makesModels=Nissan%2CQashqai&cmsid=Nissan_Qashqai_Text',
    },
    {
        f: '/fi/automerkit/nissan/x-trail',
        t: '/fi/findcar?makesModels=Nissan%2CX-Trail&cmsid=Nissan_x-trail_Text',
    },
    {
        f: '/fi/automerkit/nissan/micra',
        t: '/fi/findcar?makesModels=Nissan%2CMicra&cmsid=Nissan_micra_Text',
    },
    {
        f: '/fi/automerkit/opel',
        t: '/fi/findcar?makesModels=Opel&cmsid=Seo_Opel',
    },
    {
        f: '/fi/automerkit/opel/astra',
        t: '/fi/findcar?makesModels=Opel%2CAstra&cmsid=Opel_Astra_Text',
    },
    {
        f: '/fi/automerkit/opel/corsa',
        t: '/fi/findcar?makesModels=Opel%2CCorsa&cmsid=Opel_Corsa_Text',
    },
    {
        f: '/fi/automerkit/opel/insignia',
        t: '/fi/findcar?makesModels=Opel%2CInsignia&cmsid=Opel_Insignia_Text',
    },
    {
        f: '/fi/automerkit/opel/meriva',
        t: '/fi/findcar?makesModels=Opel%2CMeriva&cmsid=Opel_Meriva_Text',
    },
    {
        f: '/fi/automerkit/opel/zafira',
        t: '/fi/findcar?makesModels=Opel%2CZafira&cmsid=Opel_Zafira_Text',
    },
    {
        f: '/fi/automerkit/opel/combo',
        t: '/fi/findcar?makesModels=Opel%2CCombo&cmsid=Opel_Combo_Text',
    },
    {
        f: '/fi/automerkit/opel/vivaro',
        t: '/fi/findcar?makesModels=Opel%2CVivaro&cmsid=Opel_Vivaro_Text',
    },
    {
        f: '/fi/automerkit/opel/movano',
        t: '/fi/findcar?makesModels=Opel%2CMovano&cmsid=Opel_Movano_Text',
    },
    {
        f: '/fi/automerkit/peugeot',
        t: '/fi/findcar?makesModels=Peugeot&cmsid=Seo_Peugeot',
    },
    {
        f: '/fi/automerkit/peugeot/5008',
        t: '/fi/findcar?makesModels=Peugeot%2C5008&cmsid=Peugeot_5008_Text',
    },
    {
        f: '/fi/automerkit/peugeot/407sw',
        t: '/fi/findcar?makesModels=Peugeot%2C40x&cmsid=Peugeot_407sw_Text',
    },
    {
        f: '/fi/automerkit/peugeot/partner',
        t: '/fi/findcar?makesModels=Peugeot%2CPartner&cmsid=Peugeot_Partner_Text',
    },
    {
        f: '/fi/automerkit/peugeot/expert ',
        t: '/fi/findcar?makesModels=Peugeot%2CExpert&cmsid=Peugeot_Expert_Text',
    },
    {
        f: '/fi/automerkit/renault',
        t: '/fi/findcar?makesModels=Renault&cmsid=SEO_Renault',
    },
    {
        f: '/fi/automerkit/renault/clio',
        t: '/fi/findcar?makesModels=Renault%2CClio&cmsid=Renault_Clio_Text',
    },
    {
        f: '/fi/automerkit/renault/espace',
        t: '/fi/findcar?makesModels=Renault%2CEspace&cmsid=Renault_Espace_Text',
    },
    {
        f: '/fi/automerkit/renault/scenic',
        t: '/fi/findcar?makesModels=Renault%2CScenic&cmsid=Renault_Scenic_Text',
    },
    {
        f: '/fi/automerkit/renault/kangoo',
        t: '/fi/findcar?makesModels=Renault%2CKangoo&cmsid=Renault_Kangoo_Text',
    },
    {
        f: '/fi/automerkit/renault/megane',
        t: '/fi/findcar?makesModels=Renault%2CMegane&cmsid=Renault_Megane_Text',
    },
    {
        f: '/fi/automerkit/skoda',
        t: '/fi/findcar?makesModels=Skoda&cmsid=SEO_Skoda',
    },
    {
        f: '/fi/automerkit/skoda/superb',
        t: '/fi/findcar?makesModels=Skoda%2CSuperb&cmsid=Skoda_Superb_Text',
    },
    {
        f: '/fi/automerkit/skoda/octavia',
        t: '/fi/findcar?makesModels=Skoda%2COctavia&cmsid=Skoda_Octavia_Text',
    },
    {
        f: '/fi/automerkit/skoda/rapid',
        t: '/fi/findcar?makesModels=Skoda%2CRapid&cmsid=Skoda_Rapid_Text',
    },
    {
        f: '/fi/automerkit/skoda/yeti',
        t: '/fi/findcar?makesModels=Skoda%2CYeti&cmsid=Skoda_Yeti_Text',
    },
    {
        f: '/fi/automerkit/skoda/fabia',
        t: '/fi/findcar?makesModels=Skoda%2CFabia&cmsid=Skoda_Fabia_Text',
    },
    {
        f: '/fi/automerkit/toyota',
        t: '/fi/findcar?makesModels=Toyota&cmsid=SEO_Toyota',
    },
    {
        f: '/fi/automerkit/toyota/auris',
        t: '/fi/findcar?makesModels=Toyota%2CAuris&cmsid=Toyota_Auris_Text',
    },
    {
        f: '/fi/automerkit/toyota/avensis',
        t: '/fi/findcar?makesModels=Toyota%2CAvensis&cmsid=Toyota_Avensis_Text',
    },
    {
        f: '/fi/automerkit/toyota/aygo',
        t: '/fi/findcar?makesModels=Toyota%2CAygo&cmsid=Toyota_Aygo_Text',
    },
    {
        f: '/fi/automerkit/toyota/prius',
        t: '/fi/findcar?makesModels=Toyota%2CPrius&cmsid=Toyota_Prius_Text',
    },
    {
        f: '/fi/automerkit/toyota/rav4',
        t: '/fi/findcar?makesModels=Toyota%2CRav4&cmsid=Toyota_Rav4_Text',
    },
    {
        f: '/fi/automerkit/toyota/yaris',
        t: '/fi/findcar?makesModels=Toyota%2CYaris&cmsid=Toyota_Yaris_Text',
    },
    {
        f: '/fi/automerkit/volvo',
        t: '/fi/findcar?makesModels=Volvo&cmsid=SEO_Volvo',
    },
    {
        f: '/fi/automerkit/volvo/v40',
        t: '/fi/findcar?makesModels=Volvo%2CV40&cmsid=Volvo_V40_Text',
    },
    {
        f: '/fi/automerkit/volvo/s60',
        t: '/fi/findcar?makesModels=Volvo%2CS60&cmsid=Volvo_S60_Text',
    },
    {
        f: '/fi/automerkit/volvo/xc60',
        t: '/fi/findcar?makesModels=Volvo%2CXC60&cmsid=Volvo_XC60_Text',
    },
    {
        f: '/fi/automerkit/volvo/xc90',
        t: '/fi/findcar?makesModels=Volvo%2CXC90&cmsid=Volvo_XC90_Text',
    },
    {
        f: '/fi/automerkit/volvo/s80',
        t: '/fi/findcar?makesModels=Volvo%2CS80&cmsid=Volvo_S80_Text',
    },
    {
        f: '/fi/automerkit/volvo/s90',
        t: '/fi/findcar?makesModels=Volvo%2CS90&cmsid=Volvo_S90_Text',
    },
    {
        f: '/fi/automerkit/volvo/v60',
        t: '/fi/findcar?makesModels=Volvo%2CV60&cmsid=Volvo_V60_Text',
    },
    {
        f: '/fi/automerkit/volvo/v90',
        t: '/fi/findcar?makesModels=Volvo%2CV90&cmsid=Volvo_V90_Text',
    },
    {
        f: '/fi/automerkit/volkswagen',
        t: '/fi/findcar?makesModels=Volkswagen&cmsid=SEO_Volkswagen',
    },
    {
        f: '/fi/automerkit/volkswagen/golf',
        t: '/fi/findcar?makesModels=Volkswagen%2CGolf&cmsid=Volkswagen_Golf%20_Text',
    },
    {
        f: '/fi/automerkit/volkswagen/passat',
        t: '/fi/findcar?makesModels=Volkswagen%2CPassat&cmsid=Volkswagen_Passat%20_Text',
    },
    {
        f: '/fi/automerkit/volkswagen/polo',
        t: '/fi/findcar?makesModels=Volkswagen%2CPolo&cmsid=Volkswagen_Polo%20_Text',
    },
    {
        f: '/fi/automerkit/volkswagen/tiguan',
        t: '/fi/findcar?makesModels=Volkswagen%2CTiguan&cmsid=Volkswagen_Tiguan%20_Text',
    },
    {
        f: '/fi/automerkit/volkswagen/touareg',
        t: '/fi/findcar?makesModels=Volkswagen%2CTouareg&cmsid=Volkswagen_Touareg%20_Text',
    },
    {
        f: '/fi/automerkit/volkswagen/transporter',
        t: '/fi/findcar?makesModels=Volkswagen%2CTransporter&cmsid=Volkswagen_Transporter_Text',
    },
    {
        f: '/fi/automerkit/volkswagen/caddy',
        t: '/fi/findcar?makesModels=Volkswagen%2CCaddy&cmsid=Volkswagen_Caddy_Text',
    },
    {
        f: '/fi/automerkit/volkswagen/touran',
        t: '/fi/findcar?makesModels=Volkswagen%2CTouran&cmsid=Volkswagen_Touran_Text',
    },
    {
        f: '/fi/automerkit/piaggio',
        t: '/fi/findcar?makesModels=Piaggio&cmsid=SEO_Piaggio',
    },
    {
        f: '/fi/automerkit/alfa-romeo',
        t: '/fi/findcar?makesModels=Alfa%20Romeo&cmsid=SEO_alfa-romeo',
    },
    {
        f: '/fi/automerkit/abarth',
        t: '/fi/findcar?makesModels=Abarth&cmsid=SEO_Abarth',
    },
    {
        f: '/fi/automerkit/lancia',
        t: '/fi/findcar?makesModels=Lancia&cmsid=SEO_Lancia',
    },
    {
        f: '/fi/korimalli/sedan',
        t: '/fi/findcar?bodyTypes=Berline&cmsid=SEO_Berline',
    },
    {
        f: '/fi/korimalli/tauko',
        t: '/fi/findcar?bodyTypes=Break&cmsid=SEO_Break',
    },
    {
        f: '/fi/korimalli/avoauto',
        t: '/fi/findcar?bodyTypes=Cabriolet&cmsid=SEO_Cabrio',
    },
    {
        f: '/fi/korimalli/kompakti',
        t: '/fi/findcar?bodyTypes=Compact&cmsid=SEO_Compact',
    },
    {
        f: '/fi/korimalli/coupe',
        t: '/fi/findcar?bodyTypes=Coup%C3%A9&cmsid=SEO_Coup%C3%A9',
    },
    {
        f: '/fi/korimalli/monikaytto',
        t: '/fi/findcar?bodyTypes=MPV&cmsid=SEO_MPV',
    },
    {
        f: '/fi/korimalli/maastoauto',
        t: '/fi/findcar?bodyTypes=SUV&cmsid=SEO_SUV',
    },
    {
        f: '/fi/korimalli/kevyet-kuorma-autot',
        t: '/fi/findcar?bodyTypes=Lighttruck&cmsid=SEO_Light-truck',
    },
    {
        f: '/fi/korimalli/viistopera',
        t: '/fi/findcar?bodyTypes=Hatchback&cmsid=SEO_Hatchback',
    },
    {
        f: '/fi/korimalli/kuorma-auto',
        t: '/fi/findcar/advanced?bodyTypes=Truck&cmsid=SEO_Truck',
    },
    {
        f: '/fi/korimalli/minibussi',
        t: '/fi/findcar?bodyTypes=Minibus&cmsid=Minibus',
    },
    {
        f: '/fi/korimalli/moottoripyora',
        t: '/fi/findcar?bodyTypes=Motorbike&cmsid=Motorbike',
    },
    {
        f: '/fi/kaytettyjen-autojen-hinnat/halvat-kaytetyt-autot',
        t: '/fi/findcar?prices=0%2C3000&cmsid=cheap_used_cars',
    },
    {
        f: '/fi/kaytettyjen-autojen-hinnat/taloudelliset-autot',
        t: '/fi/findcar?prices=3000%2C6000&cmsid=economy_cars',
    },
    {
        f: '/fi/kaytettyjen-autojen-hinnat/edullisia-autot',
        t: '/fi/findcar?prices=6000%2C10000&cmsid=Value_for_money',
    },
    {
        f: '/fi/kaytettyjen-autojen-hinnat/keskiluokan-autot ',
        t: '/fi/findcar?prices=10000%2C20000&cmsid=Middle_class',
    },
    {
        f: '/fi/kaytettyjen-autojen-hinnat/luksusautot',
        t: '/fi/findcar?prices=20000%2C0&cmsid=luxury_cars',
    },
    {
        f: '/fi/alkupera/kaytetyt-autot-belgia',
        t: '/fi/findcar?originCountries=be&cmsid=used-cars-belgium',
    },
    {
        f: '/fi/alkupera/kaytetyt-autot-ranska',
        t: '/fi/findcar?originCountries=fr&cmsid=used-cars-france',
    },
    {
        f: '/fi/alkupera/kaytetyt-autot-italia',
        t: '/fi/findcar?originCountries=it&cmsid=used-cars-italy',
    },
    {
        f: '/fi/alkupera/kaytetyt-autot-saksa',
        t: '/fi/findcar?originCountries=de&cmsid=used-cars-germany',
    },
    {
        f: '/fi/alkupera/kaytetyt-autot-espanja',
        t: '/fi/findcar?originCountries=es&cmsid=used-cars-spain',
    },
    {
        f: '/fi/alkupera/kaytetyt-autot-alankomaat',
        t: '/fi/findcar?originCountries=nl&cmsid=used-cars-holland',
    },
    {
        f: '/fi/alkupera/kaytetyt-autot-ruotsi',
        t: '/fi/findcar?originCountries=se&cmsid=used-cars-sweden',
    },
    {
        f: '/fi/alkupera/kaytetyt-autot-puola',
        t: '/fi/findcar?originCountries=pl&cmsid=used-cars-poland',
    },
    {
        f: '/fi/alkupera/kaytetyt-autot-luxemburg',
        t: '/fi/findcar?originCountries=lu&cmsid=used-cars-luxembourg',
    },
    {
        f: '/fi/autohuutokauppa/ei-vahinkoa',
        t: '/fi/findcar?damageTypes=1&cmsid=no_specific-Damages',
    },
    {
        f: '/fi/onnettomuusautot',
        t: '/fi/findcar?damageTypes=2&cmsid=Body-damages',
    },
    {
        f: '/fi/teknisesti-vaurioituneet-autot',
        t: '/fi/findcar?damageTypes=3&cmsid=technical-damages',
    },
    {
        f: '/fi/teknisesti-ja-koriltaan-vaurioituneet-autot',
        t: '/fi/findcar?damageTypes=4&cmsid=Technical-and-body-damages',
    },
    {
        f: '/fi/autohuutokauppa/dynaaminen',
        t: '/fi/findcar?auctionTypes=1&cmsid=Dynamic',
    },
    {
        f: '/fi/autohuutokauppa/osta-nyt',
        t: '/fi/findcar?auctionTypes=2&cmsid=buy-now',
    },
    {
        f: '/fi/autohuutokauppa/sokkohuutokauppa',
        t: '/fi/findcar?auctionTypes=4&cmsid=blind',
    },
    {
        f: '/fi/autohuutokauppa/tavoitteena-huutokauppa',
        t: '/fi/findcar?auctionTypes=5&cmsid=target',
    },

    {
        f: '/fi/automerkit/honda',
        t: '/fi/findcar?makesModels=Honda&cmsid=SEO_Honda',
    },
    {
        f: '/fi/automerkit/kia',
        t: '/fi/findcar?makesModels=Kia&cmsid=SEO_Kia',
    },
    {
        f: '/fi/automerkit/porsche',
        t: '/fi/findcar?makesModels=Porsche&cmsid=SEO_Porsche',
    },
    {
        f: '/fi/automerkit/chrevrolet',
        t: '/fi/findcar?makesModels=Chevrolet&cmsid=SEO_Chevrolet',
    },
    {
        f: '/fi/automerkit/jaguar',
        t: '/fi/findcar?makesModels=Jaguar&cmsid=SEO_Jaguar',
    },
    {
        f: '/fi/automerkit/mazda',
        t: '/fi/findcar?makesModels=Mazda&cmsid=SEO_Mazda',
    },
    {
        f: '/fi/automerkit/mitsubishi',
        t: '/fi/findcar?makesModels=Mitsubishi&cmsid=SEO_Mitsubishi',
    },
    {
        f: '/fi/automerkit/suzuki',
        t: '/fi/findcar?makesModels=Suzuki&cmsid=SEO_Suzuki',
    },
    {
        f: '/fi/automerkit/infiniti',
        t: '/fi/findcar?makesModels=Infiniti&cmsid=SEO_Infiniti',
    },
    {
        f: '/fi/automerkit/lexus',
        t: '/fi/findcar?makesModels=Lexus&cmsid=SEO_Lexus',
    },
    {
        f: '/fi/automerkit/maserati',
        t: '/fi/findcar?makesModels=Maserati&cmsid=SEO_Maserati',
    },
    {
        f: '/fi/automerkit/dodge',
        t: '/fi/findcar?makesModels=Dodge&cmsid=SEO_Dodge',
    },
    {
        f: '/fi/automerkit/smart',
        t: '/fi/findcar?makesModels=Smart&cmsid=SEO_Smart',
    },
    {
        f: '/fi/automerkit/dacia',
        t: '/fi/findcar?makesModels=Dacia&cmsid=SEO_Dacia',
    },
    {
        f: '/fi/automerkit/chrysler',
        t: '/fi/findcar?makesModels=Chrysler&cmsid=SEO_Chrysler',
    },
    {
        f: '/fi/automerkit/sangyong',
        t: '/fi/findcar?makesModels=Sangyong&cmsid=SEO_Sangyong',
    },
    {
        f: '/fi/automerkit/saab',
        t: '/fi/findcar?makesModels=Saab&cmsid=SEO_Saab',
    },
    {
        f: '/fi/automerkit/mini',
        t: '/fi/findcar?makesModels=Mini&cmsid=SEO_Mini',
    },
    {
        f: '/fi/automerkit/seat',
        t: '/fi/findcar?makesModels=Seat&cmsid=SEO_Seat',
    },
    {
        f: '/fi/automerkit/jeep',
        t: '/fi/findcar?makesModels=Jeep&cmsid=SEO_Jeep',
    },
    {
        f: '/fi/automerkit/land-rover',
        t: '/fi/findcar?makesModels=Land%20Rover&cmsid=SEO_LandRover',
    },
    {
        f: '/fi/automerkit/tesla',
        t: '/fi/findcar?makesModels=Tesla&cmsid=SEO_Tesla',
    },
    {
        f: '/fi/automerkit/hyundai',
        t: '/fi/findcar?makesModels=Hyundai&cmsid=SEO_Hyundai',
    },

    {
        f: '/bg/avtomobili-marka/vauxhall',
        t: '/bg/findcar?makesModels=Vauxhall&cmsid=SEO_Vauxhall',
    },
    {
        f: '/cs/znacka-auta/vauxhall',
        t: '/cs/findcar?makesModels=Vauxhall&cmsid=SEO_Vauxhall',
    },
    {
        f: '/de/automarke/vauxhall',
        t: '/de/findcar?makesModels=Vauxhall&cmsid=SEO_Vauxhall',
    },
    {
        f: '/en/car-makes/vauxhall',
        t: '/en/findcar?makesModels=Vauxhall&cmsid=SEO_Vauxhall',
    },
    {
        f: '/es/marca-coche/vauxhall',
        t: '/es/findcar?makesModels=Vauxhall&cmsid=SEO_Vauxhall',
    },
    {
        f: '/fr-be/voiture-marque/vauxhall',
        t: '/fr-be/findcar?makesModels=Vauxhall&cmsid=SEO_Vauxhall',
    },
    {
        f: '/fr-fr/voiture-marque/vauxhall',
        t: '/fr-fr/findcar?makesModels=Vauxhall&cmsid=SEO_Vauxhall',
    },
    {
        f: '/nl-be/auto-merk/vauxhall',
        t: '/nl-be/findcar?makesModels=Vauxhall&cmsid=SEO_Vauxhall',
    },
    {
        f: '/nl-nl/auto-merk/vauxhall',
        t: '/nl-nl/findcar?makesModels=Vauxhall&cmsid=SEO_Vauxhall',
    },
    {
        f: '/hr/proizvodac-automobila/vauxhall',
        t: '/hr/findcar?makesModels=Vauxhall&cmsid=SEO_Vauxhall',
    },
    {
        f: '/hu/auto-marka/vauxhall',
        t: '/hu/findcar?makesModels=Vauxhall&cmsid=SEO_Vauxhall',
    },
    {
        f: '/it/marche-auto/vauxhall',
        t: '/it/findcar?makesModels=Vauxhall&cmsid=SEO_Vauxhall',
    },
    {
        f: '/lt/automobiliu-markes/vauxhall',
        t: '/lt/findcar?makesModels=Vauxhall&cmsid=SEO_Vauxhall',
    },
    {
        f: '/pl/marka-samochodu/vauxhall',
        t: '/pl/findcar?makesModels=Vauxhall&cmsid=SEO_Vauxhall',
    },
    {
        f: '/pt/marcas-carros/vauxhall',
        t: '/pt/findcar?makesModels=Vauxhall&cmsid=SEO_Vauxhall',
    },
    {
        f: '/ro/model-auto/vauxhall',
        t: '/ro/findcar?makesModels=Vauxhall&cmsid=SEO_Vauxhall',
    },
    {
        f: '/ru/marka/vauxhall',
        t: '/ru/findcar?makesModels=Vauxhall&cmsid=SEO_Vauxhall',
    },
    {
        f: '/sl/avto-znamka/vauxhall',
        t: '/sl/findcar?makesModels=Vauxhall&cmsid=SEO_Vauxhall',
    },
    {
        f: '/sk/znacky-aut/vauxhall',
        t: '/sk/findcar?makesModels=Vauxhall&cmsid=SEO_Vauxhall',
    },
    {
        f: '/tr/araba-markalari/vauxhall',
        t: '/tr/findcar?makesModels=Vauxhall&cmsid=SEO_Vauxhall',
    },
    {
        f: '/el/car-makes/vauxhall',
        t: '/el/findcar?makesModels=Vauxhall&cmsid=SEO_Vauxhall',
    },
    {
        f: '/fi/automerkit/vauxhall',
        t: '/fi/findcar?makesModels=Vauxhall&cmsid=SEO_Vauxhall',
    },
    //-----------UK SEO Pages links-----------
    {
        f: '/en/origin/used-cars-united-kingdom',
        t: '/en/findcar?originCountries=uk&cmsid=used-cars-uk',
    },
    {
        f: '/es/coches-segunda-mano-reino-unido',
        t: '/es/findcar?originCountries=uk&cmsid=used-cars-uk',
    },
    {
        f: '/de/gebrauchtwagen-grossbritannien',
        t: '/de/findcar?originCountries=uk&cmsid=used-cars-uk',
    },
    {
        f: '/it/auto-usate-regno-unito',
        t: '/it/findcar?originCountries=uk&cmsid=used-cars-uk',
    },
    {
        f: '/pl/samochody-uzywane-wielka-brytania',
        t: '/pl/findcar?originCountries=uk&cmsid=used-cars-uk',
    },
    {
        f: '/ru/used-cars-united-kingdom',
        t: '/ru/findcar?originCountries=uk&cmsid=used-cars-uk',
    },
    {
        f: '/cs/ojete-vozy-velka-britanie',
        t: '/cs/findcar?originCountries=uk&cmsid=used-cars-uk',
    },
    {
        f: '/ro/masini-second-hand-regatul-unit',
        t: '/ro/findcar?originCountries=uk&cmsid=used-cars-uk',
    },
    {
        f: '/sl/rabljeni-avtomobili-zdruzeno-kraljestvo',
        t: '/sl/findcar?originCountries=uk&cmsid=used-cars-uk',
    },
    {
        f: '/hu/hasznalt-autok-egyesult-kiralysag',
        t: '/hu/findcar?originCountries=uk&cmsid=used-cars-uk',
    },
    {
        f: '/nl-nl/occasions-verenigd-koninkrijk',
        t: '/nl-nl/findcar?originCountries=uk&cmsid=used-cars-uk',
    },
    {
        f: '/nl-be/occasions-verenigd-koninkrijk',
        t: '/nl-be/findcar?originCountries=uk&cmsid=used-cars-uk',
    },
    {
        f: '/fr-fr/voitures-occasion-royaume-uni',
        t: '/fr-fr/findcar?originCountries=uk&cmsid=used-cars-uk',
    },
    {
        f: '/fr-be/voitures-occasion-royaume-uni',
        t: '/fr-be/findcar?originCountries=uk&cmsid=used-cars-uk',
    },
    {
        f: '/lt/naudoti-automobiliai-jungtine-karalyste',
        t: '/lt/findcar?originCountries=uk&cmsid=used-cars-uk',
    },
    {
        f: '/tr/kullanilmis-otomobiller-birlesik-krallik',
        t: '/tr/findcar?originCountries=uk&cmsid=used-cars-uk',
    },
    {
        f: '/bg/used-cars-united-kingdom',
        t: '/bg/findcar?originCountries=uk&cmsid=used-cars-uk',
    },
    {
        f: '/hr/rabljeni-automobili-velika-britanija',
        t: '/hr/findcar?originCountries=uk&cmsid=used-cars-uk',
    },
    {
        f: '/pt/carros-usados-reino-unido',
        t: '/pt/findcar?originCountries=uk&cmsid=used-cars-uk',
    },
    {
        f: '/sk/ojazdene-auta-z-velka-britania',
        t: '/sk/findcar?originCountries=uk&cmsid=used-cars-uk',
    },
    {
        f: '/el/origin/used-cars-united-kingdom',
        t: '/el/findcar?originCountries=uk&cmsid=used-cars-uk',
    },
    {
        f: '/fi/alkupera/kaytetyt-autot-yhdistynyt-kuningaskunta',
        t: '/fi/findcar?originCountries=uk&cmsid=used-cars-uk',
    },

    //-----------24/7 stock sale search links-----------

    {
        f: '/en/car-auction/stock-sale',
        t: '/en/findcar?auctionTypes=6&cmsid=247StockSale',
    },
    {
        f: '/es/subasta-automoviles/venta-de-stock',
        t: '/es/findcar?auctionTypes=6&cmsid=247StockSale',
    },
    {
        f: '/de/gebrauchtwagen-auktion/lagerverkauf',
        t: '/de/findcar?auctionTypes=6&cmsid=247StockSale',
    },
    {
        f: '/it/asta-auto-usate/vendita-di-stock',
        t: '/it/findcar?auctionTypes=6&cmsid=247StockSale',
    },
    {
        f: '/pl/aukcje-samochodow/sprzedaz-asortymentu',
        t: '/pl/findcar?auctionTypes=6&cmsid=247StockSale',
    },
    {
        f: '/ru/car-auction/prodaza-akcij',
        t: '/ru/findcar?auctionTypes=6&cmsid=247StockSale',
    },
    {
        f: '/cs/aukce-ojetech-aut/prodej-zasob',
        t: '/cs/findcar?auctionTypes=6&cmsid=247StockSale',
    },
    {
        f: '/ro/licitatie-auto/stock-sale',
        t: '/ro/findcar?auctionTypes=6&cmsid=247StockSale',
    },
    {
        f: '/sl/drazba-vozil/prodaja-zalog',
        t: '/sl/findcar?auctionTypes=6&cmsid=247StockSale',
    },
    {
        f: '/hu/hasznaltauto-arveres/keszletertekesites',
        t: '/hu/findcar?auctionTypes=6&cmsid=247StockSale',
    },

    {
        f: '/nl-nl/autoveilingen/stockverkoop',
        t: '/nl-nl/findcar?auctionTypes=6&cmsid=247StockSale',
    },
    {
        f: '/nl-be/autoveilingen/stockverkoop',
        t: '/nl-be/findcar?auctionTypes=6&cmsid=247StockSale',
    },
    {
        f: '/fr-fr/encheres-voitures/vehicules-de-stock',
        t: '/fr-fr/findcar?auctionTypes=6&cmsid=247StockSale',
    },
    {
        f: '/fr-be/encheres-voitures/vehicules-de-stock',
        t: '/fr-be/findcar?auctionTypes=6&cmsid=247StockSale',
    },

    {
        f: '/lt/car-auction/akciju-pardavimas',
        t: '/lt/findcar?auctionTypes=6&cmsid=247StockSale',
    },
    {
        f: '/tr/car-auctions/stok-satisi',
        t: '/tr/findcar?auctionTypes=6&cmsid=247StockSale',
    },
    {
        f: '/bg/car-auction/prodazhba-na-aktsii',
        t: '/bg/findcar?auctionTypes=6&cmsid=247StockSale',
    },
    {
        f: '/hr/car-auction/akcija-rasprodaja',
        t: '/hr/findcar?auctionTypes=6&cmsid=247StockSale',
    },

    {
        f: '/pt/leilao/venda-em-stock',
        t: '/pt/findcar?auctionTypes=6&cmsid=247StockSale',
    },
    {
        f: '/sk/aukcie-aut/predaj-zasob',
        t: '/sk/findcar?auctionTypes=6&cmsid=247StockSale',
    },
    {
        f: '/el/car-auction/stock-sale',
        t: '/el/findcar?auctionTypes=6&cmsid=247StockSale',
    },
    {
        f: '/fi/autohuutokauppa/varastomyynti',
        t: '/fi/findcar?auctionTypes=6&cmsid=247StockSale',
    },
];
