exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3St8inHwYksCfB7qxXfxf{margin-top:auto}._3St8inHwYksCfB7qxXfxf ._1WvHAnbTd04o_bO5rfTnCu a{color:var(--shades-0) !important;text-decoration:none;padding:var(--spacing-2xs) var(--spacing-sm) !important;font-size:var(--text-lg) !important}._3St8inHwYksCfB7qxXfxf ._1WvHAnbTd04o_bO5rfTnCu a:hover,._3St8inHwYksCfB7qxXfxf ._1WvHAnbTd04o_bO5rfTnCu a:focus{color:var(--shades-0) !important}._3St8inHwYksCfB7qxXfxf ._1WvHAnbTd04o_bO5rfTnCu a._1Hi3ODLZkP8jzl0_9a3xjG:hover,._3St8inHwYksCfB7qxXfxf ._1WvHAnbTd04o_bO5rfTnCu a._1Hi3ODLZkP8jzl0_9a3xjG:focus{color:var(--primary-500) !important}._3St8inHwYksCfB7qxXfxf ._3sbcYPY2W7HbYyyX3job2c{width:200px}._3St8inHwYksCfB7qxXfxf ._3DhczOSlTMRYNpfadHJTQo{background:rgba(0,0,0,0) !important;border:0 !important;padding:0 !important;color:inherit !important;font-size:inherit !important;transition:color .3s linear !important}._3St8inHwYksCfB7qxXfxf ._3DhczOSlTMRYNpfadHJTQo:hover,._3St8inHwYksCfB7qxXfxf ._3DhczOSlTMRYNpfadHJTQo:focus{cursor:pointer !important;color:var(--primary-500) !important;outline:0 !important}@media(min-width: 992px){._3St8inHwYksCfB7qxXfxf ._1p4uJgVBjJue_mGaDZQaAk{padding-left:calc((100dvw - 960px)/2);padding-right:calc((100dvw - 960px)/2)}}@media(min-width: 1200px){._3St8inHwYksCfB7qxXfxf ._1p4uJgVBjJue_mGaDZQaAk{padding-left:calc((100dvw - 1140px)/2);padding-right:calc((100dvw - 1140px)/2)}}._3St8inHwYksCfB7qxXfxf ._1dxIf9b6bYImZJwVA49i3W{display:none}", ""]);

// exports
exports.locals = {
	"mainFooter": "_3St8inHwYksCfB7qxXfxf",
	"content": "_1WvHAnbTd04o_bO5rfTnCu",
	"listItem": "_1Hi3ODLZkP8jzl0_9a3xjG",
	"logo": "_3sbcYPY2W7HbYyyX3job2c",
	"onetrustButton": "_3DhczOSlTMRYNpfadHJTQo",
	"container": "_1p4uJgVBjJue_mGaDZQaAk",
	"hidden": "_1dxIf9b6bYImZJwVA49i3W"
};