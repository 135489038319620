import { RefObject, useEffect, useState } from 'react';

export default function useContentSize(elementRef: RefObject<HTMLElement>) {
    const [size, setSize] = useState({ width: 0, height: 0 });

    useEffect(() => {
        if (!elementRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            const elem = elementRef.current!;

            const wrapperComputedStyle = window.getComputedStyle(elem, null);
            let width = elem.clientWidth;
            width -= parseFloat(wrapperComputedStyle.paddingLeft) + parseFloat(wrapperComputedStyle.paddingRight);
            //width -= parseFloat(wrapperComputedStyle.marginLeft) + parseFloat(wrapperComputedStyle.marginRight);
            let height = elem.clientHeight;
            height -= parseFloat(wrapperComputedStyle.paddingTop) + parseFloat(wrapperComputedStyle.paddingBottom);
            //height -= parseFloat(wrapperComputedStyle.marginTop) + parseFloat(wrapperComputedStyle.marginBottom);
            setSize({ width, height });
        });
        resizeObserver.observe(elementRef.current);
        return () => resizeObserver.disconnect(); // clean up
    }, []);

    return size;
}
