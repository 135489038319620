import React from 'react';
import { InfoBanner } from '@ambiorix/adesa-web-kit';

import { formatMoney, extrapolate } from '../../utils';
import { addDays, formatDatewithTranslation } from '../../utils/date';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift } from '@fortawesome/free-solid-svg-icons';
import styles from './PromoBanner.module.scss';
import useBanner, { BannerSessionStorageKeys } from '../../hooks/useBanner';

const goToCmsDiscountPage = currentLanguage => {
    window.open(`/${currentLanguage}/cms/discount`, '_blank');
};

const PromoBanner = ({ t, promoDetails, currentLanguage }) => {
    const { showBanner, closeBanner } = useBanner({
        dependencyData: promoDetails,
        nameInSessionStorage: BannerSessionStorageKeys.Promo,
    });

    const daysLeft = promoDetails && promoDetails.DaysLeft ? addDays(new Date(), promoDetails.DaysLeft) : new Date();
    const promoExpireDate = daysLeft && formatDatewithTranslation(daysLeft, t);
    const promoDiscount = promoDetails && formatMoney(promoDetails.PromotionAmount, 0, promoDetails.CurrencyCodeId);
    const promoDiscountTitle = extrapolate(t('common.banner.promoBanner.title'), promoDiscount);

    //function to just changing the color of a amount
    function highlightPromoDiscount(text: string, promoDiscount: string) {
        return (
            <>
                {text
                    .split(' ')
                    .map(word => {
                        // Split before the dot, to handle cases where the translation includes a dot next to the amount.
                        const [base, dot] = word.split(/(?=\.)/);
                        return base === promoDiscount ? (
                            <span key={word}>
                                <span className={styles.promoDiscount}>{base}</span>
                                {dot && dot}
                            </span>
                        ) : (
                            word
                        );
                    })
                    .reduce<React.ReactNode[]>((prev, curr, i) => (i === 0 ? [curr] : [...prev, ' ', curr]), [])}
            </>
        );
    }

    return (
        <>
            {showBanner ? (
                <div className={styles.rcPromoBanner}>
                    <InfoBanner
                        variant="secondary"
                        style="ghost"
                        title={
                            <>
                                <p className={styles.promoBannerTitle}>
                                    <span>{highlightPromoDiscount(promoDiscountTitle, promoDiscount)} </span>
                                </p>
                            </>
                        }
                        actionProps={{ onClick: () => goToCmsDiscountPage(currentLanguage) }}
                        actionText={t('common.banner.promoBanner.detailsButton')}
                        description={<span>{extrapolate(t('common.banner.promoBanner.description'), promoExpireDate)}</span>}
                        showCloseIcon={true}
                        onClose={closeBanner}
                        titleIcon={<FontAwesomeIcon icon={faGift} />}
                    />
                </div>
            ) : null}
        </>
    );
};

export default PromoBanner;
