import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Container, Row, Col } from 'reactstrap';
import { csrfToken, csrfTokenName } from '../../../utils';
import { Spinner } from '../../ui/common-ui';
import { getSEOPage } from '../../../routing';
import { AdesaButton, AdesaTextInput, AdesaModal } from '../../Common/ui-components';

import './LoginButton.less';
import { BannerSessionStorageKeys } from '../../../hooks/useBanner';

class ForgotPasswordDialog extends Component {
    constructor(props) {
        super(props);
        this.state = { open: false, errorMsg: '', busy: false, done: false };
    }
    toggle() {
        this.setState({ open: !this.state.open, done: false });
    }
    send() {
        const body = new FormData();
        body.append('Username', this.username.value);

        this.setState({ errorMsg: '', busy: true });
        fetch(`/${this.props.currentLanguage}/Login/ForgotPasswordNew`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                Accept: 'application/json',
                [csrfTokenName]: csrfToken(),
            },
            body: body,
        })
            .then(r => r.json())
            .catch(e => this.setState({ errorMsg: 'Error', busy: false }))
            .then(resp => {
                if (!resp.Message) {
                    this.setState({ busy: false, done: true });
                    //this.toggle();
                } else {
                    this.setState({ errorMsg: resp.Message, busy: false });
                }
            });
    }
    onKeyPress(evt) {
        if (evt.key === 'Enter') {
            this.send();
        }
    }
    render() {
        const { t } = this.props;
        return (
            <div className="forgot-password-form-container">
                <Modal isOpen={this.state.open} toggle={this.toggle.bind(this)} className="login-form">
                    <ModalHeader toggle={this.toggle.bind(this)}>
                        {this.state.done ? t('passwordSent.headline') : t('forgotPassword.headline')}
                    </ModalHeader>
                    <ModalBody>
                        <Container>
                            {this.state.done ? (
                                <>
                                    <Line>
                                        <p>{t('passwordSent.description')}</p>
                                    </Line>
                                    <Line>
                                        <AdesaButton
                                            id="adesacloseButton"
                                            size="secondary"
                                            color="ghost-blue"
                                            onClick={this.toggle.bind(this)}
                                            className="">
                                            {t('common.action.close')}
                                        </AdesaButton>
                                    </Line>
                                </>
                            ) : (
                                <>
                                    <Line>
                                        <p>{t('forgotPassword.description')}</p>
                                    </Line>
                                    <Line>
                                        <AdesaTextInput
                                            label={t('forgotPassword.username')}
                                            ref={ref => (this.username = ref)}
                                            onKeyPress={this.onKeyPress.bind(this)}
                                        />
                                    </Line>
                                    <Line>
                                        <AdesaButton id="forgotpassButton11" size="secondary" color="positive" onClick={this.send.bind(this)}>
                                            {this.state.busy ? <Spinner visible={true} /> : t('forgotPassword.button.sendPassword')}
                                        </AdesaButton>
                                    </Line>
                                    <Line>
                                        <span className="errorMsg">{this.state.errorMsg} </span>
                                    </Line>
                                </>
                            )}
                        </Container>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const Line = ({ children }) => (
    <Row className="line">
        <Col xs={{ size: 10, offset: 1 }}>{children}</Col>
    </Row>
);

export default class LoginButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginDialogOpen: false,
            errorMsg: '',
            busy: false,
            rememberMe: false,
        };
    }

    toggle() {
        this.setState({
            loginDialogOpen: !this.state.loginDialogOpen,
        });
    }

    login() {
        // Login to carsite and redirect to the same page if successful.
        const body = new FormData();
        body.append('Email', this.email.value);
        body.append('Password', this.pswd.value);
        body.append('IsRememberMe', this.state.rememberMe);
        //body.append(csrfTokenName, csrfToken());

        this.setState({ errorMsg: '', busy: true });
        fetch(`/${this.props.currentLanguage}/Login/Login`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                Accept: 'application/json',
                [csrfTokenName]: csrfToken(),
            },
            body: body,
        })
            .then(r => r.json())
            .catch(e => console.error('LOGIN ERROR', e))
            .then(resp => {
                if (!resp.ErrorMsg) {
                    // remove banners visibiliy session storage
                    for (const key in BannerSessionStorageKeys) {
                        window.sessionStorage.removeItem(`OPENLANE:bannerVisibility:${BannerSessionStorageKeys[key]}`);
                    }

                    if (resp.Redirect_URL) {
                        // redirect after login (following not valid anti forgery token).
                        var url = resp.Redirect_URL;
                        var oldLanguage = '/' + url.split('/')[1] + '/';
                        if (oldLanguage === '//') {
                            oldLanguage = '/' + url.split('/')[3] + '/';
                        }
                        var newLanguage = '/' + resp.lang + '/';
                        var seoPage = getSEOPage();
                        if (seoPage !== undefined) {
                            window.location.href = seoPage.t.replace(oldLanguage, newLanguage).replace('#', '');
                        } else {
                            window.location.href = url.replace(oldLanguage, newLanguage).replace('#', '');
                        }
                    } else if (resp.ErrorCode && resp.ErrorCode === 'AntiForgeryTokenIsNotValid') {
                        // refresh anti forgery token & force login on the home page.
                        window.location.href = '/' + this.props.currentLanguage + '/home/Login';
                    } else {
                        // success: refresh the page.
                        window.location.href = window.location.pathname + window.location.search;
                    }
                } else {
                    console.error('Login error:', resp.Message);
                    this.setState({ errorMsg: this.props.t('login.errorMessage'), busy: false });
                }
            });
    }

    onKeyPress(evt) {
        if (evt.key === 'Enter') {
            this.login();
        }
    }

    render() {
        const { t, currentLanguage, showFooter = true } = this.props;
        return (
            <div className="rc-login-button">
                <AdesaButton
                    id="loginButton1"
                    size="secondary"
                    color="ghost-blue"
                    onClick={this.toggle.bind(this)}
                    className="login-button top-btn-style">
                    {t('navigation.top.login')}
                </AdesaButton>
                <ForgotPasswordDialog t={t} currentLanguage={currentLanguage} ref={ref => (this.forgotPasswordDialog = ref)} />
                <AdesaModal is-open={this.state.loginDialogOpen} onClose={this.toggle.bind(this)}>
                    <div slot="header">{t('login.headline')}</div>
                    <div slot="body" className="login-form">
                        <Container>
                            <Line>
                                <AdesaTextInput
                                    label={t('login.username')}
                                    name="Email"
                                    ref={ref => (this.email = ref)}
                                    onKeyPress={this.onKeyPress.bind(this)}
                                />
                            </Line>
                            <Line>
                                <AdesaTextInput
                                    label={t('login.password')}
                                    type="password"
                                    name="Password"
                                    ref={ref => (this.pswd = ref)}
                                    onKeyPress={this.onKeyPress.bind(this)}
                                />
                            </Line>
                            <Line>
                                <AdesaButton
                                    id="loginButton2"
                                    size="secondary"
                                    color="positive"
                                    onClick={this.login.bind(this)}
                                    disabled={this.state.busy}
                                    className="">
                                    {!this.state.busy ? t('navigation.top.login') : <Spinner visible={this.state.busy} />}
                                </AdesaButton>
                            </Line>

                            <Line>
                                <a
                                    href="#"
                                    onClick={() => {
                                        this.toggle();
                                        this.forgotPasswordDialog.toggle();
                                    }}>
                                    {t('login.link.forgotPassword')}
                                </a>
                            </Line>
                            {showFooter && (
                                <>
                                    <Line>
                                        <hr />
                                    </Line>

                                    <Line>
                                        <label>{t('login.noAccountYet')}</label>{' '}
                                        <a href={`/${currentLanguage}/register`}>{t('login.button.register')}</a>
                                    </Line>
                                    <Line>
                                        <span className="errorMsg">{this.state.errorMsg}</span>
                                    </Line>
                                </>
                            )}
                        </Container>
                    </div>
                </AdesaModal>
            </div>
        );
    }
}
