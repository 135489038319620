export const damageKind = {
    /* 1..5,9 = Normal damage, 6..8 = body damages */
    1: 'damagekind.chip',
    2: 'damagekind.Scratch',
    3: 'damagekind.Dent',
    4: 'damagekind.Broken',
    5: 'damagekind.Other',
    9: 'damagekind.Corrosion',

    6: 'cardamage.littledam',
    7: 'cardamage.middledam',
    8: 'cardamage.bigdam',
};

export const damageKindClassNames = {
    1: 'highlighted',
    2: 'highlighted',
    3: 'highlighted',
    4: 'highlighted',
    9: 'highlighted',
    5: 'highlighted',

    6: 'highlighted-small',
    7: 'highlighted-medium',
    8: 'highlighted-big',
};

export const damageCategories = {
    1: 'A',
    2: 'B',
    3: 'C',
    4: 'D',
    5: 'S',
    6: 'N',
};
/*

    Body damage: 1..24 + 63..66

*/
export const damageLocationTranslationKey = {
    1: 'vehicleDetails.damages.bumperFront',
    2: 'vehicleDetails.damages.grille',
    3: 'vehicleDetails.damages.hood',
    4: 'vehicleDetails.damages.windshield',
    5: 'vehicleDetails.damages.headLight.left',
    6: 'vehicleDetails.damages.side.left', // +  vehicleDetails.damages.fender",
    7: 'vehicleDetails.damages.aPillar.left',
    8: 'vehicleDetails.damages.door.front.left',
    9: 'vehicleDetails.damages.skirt.left',
    10: 'vehicleDetails.damages.door.rear.left',
    11: 'vehicleDetails.damages.quarterPanel.left',
    12: 'vehicleDetails.damages.tailLightLeft',
    13: 'vehicleDetails.damages.bumperRear',
    14: 'vehicleDetails.damages.trunk',
    15: 'vehicleDetails.damages.windowRear',
    16: 'vehicleDetails.damages.roof',
    17: 'vehicleDetails.damages.tailLightRight',
    18: 'vehicleDetails.damages.quarterPanel.right',
    19: 'vehicleDetails.damages.door.rear.right',
    20: 'vehicleDetails.damages.skirt.right',
    21: 'vehicleDetails.damages.door.front.right',
    22: 'vehicleDetails.damages.aPillar.right',
    23: 'vehicleDetails.damages.side.right', // + vehicleDetails.damages.fender",
    24: 'vehicleDetails.damages.headLight.right',

    36: 'carbroken.damint1',
    37: 'carbroken.damint2',
    38: 'carbroken.damint3',
    39: 'carbroken.damint4',
    40: 'carbroken.damint5',
    41: 'carbroken.damint6',
    42: 'carbroken.damint7',
    43: 'carbroken.damint8',
    44: 'carbroken.damint9',
    45: 'carbroken.damint10',
    46: 'carbroken.damint11',
    47: 'carbroken.damint12',
    //48: "carbroken.damint13",
    //49: "carbroken.damint14",
    //50: "carbroken.damint15",

    51: 'TechDmg.51',
    52: 'TechDmg.52',
    53: 'TechDmg.53',
    54: 'TechDmg.54',
    55: 'TechDmg.55',
    56: 'TechDmg.56',
    57: 'TechDmg.57',
    58: 'TechDmg.58',
    62: 'TechDmg.62', // other

    // cfr damageModel.js (in damage module)
    59: 'carbroken.damint13',
    60: 'carbroken.damint14',
    61: 'carbroken.damint15',

    63: 'vehicleDetails.damages.rimFrontLeft',
    64: 'vehicleDetails.damages.rimFrontRight',
    65: 'vehicleDetails.damages.rimRearLeft',
    66: 'vehicleDetails.damages.rimRearRight',

    71: 'vehicleDetails.damages.roofArcadeLeft',
    72: 'vehicleDetails.damages.roofArcadeRight',
    73: 'vehicleDetails.damages.mirrorLeft',
    74: 'vehicleDetails.damages.mirrorRight',

    67: 'vehicleDetails.damages.rear',
    68: 'vehicleDetails.damages.underside',

    107: 'vehicleDetails.damages.evHybrid',
    108: 'vehicleDetails.damages.transmission',
    109: 'vehicleDetails.damages.battery',
};

export const damageCategory = Object.freeze({
    exterior: 1,
    accidental: 2,
    technical: 3,
    special: 4,
});
