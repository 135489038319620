import React from 'react';
import { replace, push } from 'connected-react-router';
import NotificationSystem, { notify, removeNotifications } from 'reapop';
import settings from '../appsettings';
import { queryString, extrapolate, fetchJson, logAnalytics, formatMoney, getJson } from '../utils';
import { formatDate, utcDateTimeToLocal } from '../utils/date';
import { followNotificationMessage } from './cotw.js';
import LogRocket from 'logrocket';

function url(urlSuffix, currentLanguage) {
    return settings.carsiteBaseUrl + '/' + currentLanguage + '/' + urlSuffix;
}

// ===== REDUX ACTIONS ====================================
const INIT = 'COMMON:INIT';
const INIT_CONTACT = 'COMMON:INIT_CONTACT';
const INIT_COMPANY_DETAILS = 'COMMON:INIT_COMPANY_DETAILS';
const INIT_SERVER_DATA = 'COMMON:INIT_SERVER_DATA';
const NEW_MESSAGE = 'COMMON:NEW_MESSAGE';
const DISMISS_MESSAGE = 'COMMON:DISMISS_MESSAGE';
const INIT_SIGNUP = 'COMMON:INIT_SIGNUP';
const INIT_WATCHES = 'COMMON:INIT_WATCHES';
const TOGGLE_FOLLOWING_CAR = 'COMMON:TOGGLE_FOLLOWING_CAR';
const DISABLE_BID_CONFIRMATION = 'COMMON:DISABLE_BID_CONFIRMATION';
const SET_BID_CONFIRMATION = 'COMMON:SET_BID_CONFIRMATION';
const NEW_SERVER_TIME = 'COMMON:NEW_SERVER_TIME';
const COMMUNITIES_FETCHED = 'COMMON:COMMUNITIES_FETCHED';
const SELLER_GROUPS_FETCHED = 'COMMON:SELLER_GROUPS_FETCHED';
const SET_WEBSOCKET_IN_ERROR = 'COMMON:SET_WEBSOCKET_IN_ERROR';
const COUNTRIES_FETCHED = 'COMMON:COUNTRIES_FETCHED';
const OPTIONS_FETCHED = 'COMMON:OPTIONS_FETCHED';
const SET_PROMO_DETAILS = 'COMMON:SET_PROMO_DETAILS';

const initialServerDate = {
    remote: formatDate(new Date(), 'iso'),
    local: formatDate(new Date(), 'iso'),
};

const initialState = {
    isUserLoggedIn: null,
    currentLanguage: 'en',
    currentUser: { contactId: 0, allowedCatalogues: [], visitingUserCountry: '' },
    serverDate: initialServerDate,
    isWebSocketInError: false,

    watches: [],

    messages: [], // messages displayed on top of the page.
    promoDetails: null,
    auctionStats: {}, // stats for header.
    signUpStatus: {},
    confirmationEnabled: true,
    communities: [],
    sellerGroups: {},
    countries: [],
    allOptions: [],
};

/***************************************************************************************************************
 *  REDUX REDUCER
 */
export function commonReducer(state = initialState, action) {
    switch (action.type) {
        case INIT:
            return {
                ...state,
                isUserLoggedIn: action.isUserLoggedIn || false,
                currentUser: {
                    contactId: action.contactId || 0,
                    allowedCatalogues: [],
                    visitingUserCountry: state.currentUser.visitingUserCountry,
                },
                currentLanguage: action.currentLanguage,
            };

        case INIT_CONTACT:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    companyId: action.user.CompanyId,
                    companyName: action.user.CompanyName,
                    firstName: action.user.CurrentUserFirstName || '?',
                    lastName: action.user.CurrentUserLastName || '?',
                    username: action.user.CurrentUserUsername || '',
                    email: action.user.CurrentUserEmail || '',
                    phoneNr: action.user.CurrentUserPhoneNr || '',
                    isOrderCompletionAllowed: action.user.OrderCompletionAllowed || false, // cfr https://karauctionservices.visualstudio.com/International/_workitems/edit/109737
                    hasSearchPreferences: action.user.HasSearchPreference || false, // cfr https://karauctionservices.visualstudio.com/International/_workitems/edit/79040,
                    isMainContact: action.user.isMainContact,
                    country: action.user.BuyerCountry || '??',
                    mainAddressZipCode: action.user.MainAddressZipCode,
                    allowedCatalogues: action.user.AllowedCatalogues || [],
                    visitingUserCountry: action.user.VisitingUserCountry,
                    batchRestrictionGroupsUk: action.user.BatchRestrictionGroupsUk,
                    isImpersonated: action.user.IsImpersonated || false,
                    vbaStatus: action.user.VBAStatus, // "NEW_BUYER" or "EXISTING_BUYER"
                },
                confirmationEnabled: action.user.ModalConfirmationEnabled,
            };

        case INIT_SERVER_DATA:
            return {
                ...state,
                serverDate: { remote: action.serverDateTime, local: formatDate(new Date(), 'iso') },
            };

        case NEW_SERVER_TIME:
            return { ...state, serverDate: { remote: action.serverDateTime, local: formatDate(new Date(), 'iso') } };

        case NEW_MESSAGE: {
            return { ...state, messages: [...state.messages, action.message] };
        }
        case SET_PROMO_DETAILS: {
            return { ...state, promoDetails: action.promoDetails };
        }
        case DISMISS_MESSAGE: {
            const newMessages = [...state.messages];
            newMessages.splice(action.messageIdx, 1);
            return { ...state, messages: newMessages };
        }
        case INIT_SIGNUP: {
            return {
                ...state,
                signUpStatus: {
                    ...action.resp,
                    hasFullAccess: action.resp.SignUpLevel === 3,
                    isBiddingAllowed: action.resp.isBiddingAllowed && action.resp.IsBiddingAllowedForContact,
                    isBiddingAllowedForCompany: action.resp.isBiddingAllowed,
                    isBiddingAllowedForContact: action.resp.IsBiddingAllowedForContact,
                },
            };
        }

        case INIT_WATCHES: {
            return {
                ...state,
                watches: action.watches,
            };
        }
        case TOGGLE_FOLLOWING_CAR: {
            const watches = action.following ? [...state.watches, action.auctionId] : state.watches.filter(a => a !== action.auctionId);
            return {
                ...state,
                watches,
            };
        }

        case DISABLE_BID_CONFIRMATION:
            return {
                ...state,
                confirmationEnabled: false,
            };

        case SET_BID_CONFIRMATION:
            return {
                ...state,
                confirmationEnabled: action.confirmationEnabled,
            };

        case COMMUNITIES_FETCHED: {
            return {
                ...state,
                communities: action.communities,
            };
        }

        case SELLER_GROUPS_FETCHED: {
            return {
                ...state,
                sellerGroups: action.sellerGroups.reduce((res, val) => ({ ...res, [val.CompanyName]: val.CompanyIds }), {}),
            };
        }
        case SET_WEBSOCKET_IN_ERROR: {
            return {
                ...state,
                isWebSocketInError: true,
            };
        }

        case COUNTRIES_FETCHED: {
            return {
                ...state,
                countries: action.countries,
            };
        }
        case INIT_COMPANY_DETAILS: {
            return {
                ...state,
                companyDetails: {
                    name: action.resp.Name,
                    vatNumber: action.resp.VatNumber,
                    mobilePhone: action.resp.MobilePhone,
                    website: action.resp.Website,
                    addressLine1: action.resp.AddressLine1,
                    addressLine2: action.resp.AddressLine2,
                    zipCode: action.resp.ZipCode,
                    city: action.resp.City,
                    country: action.resp.Country,
                    blockedFromBidding: action.resp.BlockedFromBidding,
                    registrationDate: action.resp.RegistrationDate,
                    isBuyer: action.resp.IsBuyer,
                    isSeller: action.resp.IsSeller,
                    hasFullAccess: action.resp.HasFullAccess,
                    hasPendingSellerRequest: action.resp.HasPendingSellerRequest,
                    isSecondaryActivity: action.resp.IsSecondaryActivity,
                    businessTypeId: action.resp.BusinessTypeId,
                    isChatbotEnabled: action.resp.IsChatbotEnabled,
                    isLivechatEnabled: action.resp.IsLivechatEnabled,
                    isPhoneNativeEnabled: action.resp.IsPhoneNativeEnabled,
                },
            };
        }
        case OPTIONS_FETCHED: {
            return {
                ...state,
                allOptions: action.allOptions,
            };
        }

        default:
            return { ...state };
    }
}

/***************************************************************************************************************
 *  REACT-REDUX state to properties map.
 */

const hasUkCatalogueAccess = state => !!state.common.currentUser.allowedCatalogues.includes('ADESA UK');
const hasEuCatalogueAccess = state => !!state.common.currentUser.allowedCatalogues.includes('ADESA EU');

export const commonMapStateToProps = globalState => ({
    isUserLoggedIn: globalState.common.isUserLoggedIn,
    isWebSocketInError: globalState.common.isWebSocketInError || false,
    currentLanguage: globalState.common.currentLanguage || 'en',
    currentUser: globalState.common.currentUser,
    auctionStats: globalState.common.auctionStats,
    messages: globalState.common.messages,
    signUpStatus: globalState.common.signUpStatus,
    confirmationEnabled: globalState.common.confirmationEnabled,
    communities: globalState.common.communities,
    sellerGroups: globalState.common.sellerGroups,
    currentUserAllowedCatalogues: globalState.common.currentUser.allowedCatalogues,
    currentUserHasUkCatalogueAccess: hasUkCatalogueAccess(globalState),
    currentUserHasEuCatalogueAccess: hasEuCatalogueAccess(globalState),
    currentBuyerCountry: globalState.common.currentUser.country || '',
    countries: globalState.common.countries,
    promoDetails: globalState.common.promoDetails,
    allOptions: globalState.common.allOptions,
    showBusinessTypeBanner:
        !!settings.featureFlags['IsSegaEnabled'] &&
        !!globalState.common.currentUser &&
        globalState.common.currentUser.isMainContact &&
        globalState.common.currentUser.country.toLowerCase() !== 'uk' &&
        !!globalState.common.companyDetails &&
        (!globalState.common.companyDetails.businessTypeId ||
            ([5, 9, 11].includes(globalState.common.companyDetails.businessTypeId) &&
                globalState.common.companyDetails.isSecondaryActivity === null)),
});

/***************************************************************************************************************
 *  REACT-REDUX action creators (using redux-thunk: Redux Thunk middleware allows you to write action creators that return a function instead of an action)
 */

function notifyError(dispatch, message) {
    dispatch(notify({ message: message, status: 'error' }));
}

const logCommonAnalytics = (action, state, message) => logAnalytics('Common', action, state.common.currentLanguage, message);

export const commonActionsCreator = {
    onInit: (isUserLoggedIn, currentLanguage, contactId) => (dispatch, getState) =>
        initializeState(isUserLoggedIn, currentLanguage, contactId, dispatch, getState),
    onRefreshUserDetails: () => (dispatch, getState) => refreshUserDetails(dispatch, getState()),
    onDismissMessage: messageIdx => dispatch => dispatch({ type: DISMISS_MESSAGE, messageIdx }),
    onFollowThisCar: auctionId => (dispatch, getState) => followThisCar(auctionId, dispatch, getState()),
    onDisableConfirmation: () => (dispatch, getState) => {
        disableConfirmation(dispatch, getState());
        logCommonAnalytics('DISABLE_CONFIRMATION', getState());
    },
    onServerTimeSync: dateTime => dispatch => dispatch({ type: NEW_SERVER_TIME, serverDateTime: utcDateTimeToLocal(dateTime) }),
    onGoToPage: page => (dispatch, getState) => dispatch(push(`/${getState().common.currentLanguage}/${page}`)),
    onNotify: notification => (dispatch, getState) => {
        let { notifications } = getState();
        // limit number of displayed notifications to 3 max.
        while (notifications.length > 2) {
            dispatch({ type: 'REMOVE_NOTIFICATION', payload: notifications[0].id });
            notifications = getState().notifications;
        }
        dispatch(notify(notification)); // notification: {message: string, status: string} // status: "error" "success" "warning"
    },
    onSetWebSocketInError: () => dispatch => dispatch({ type: SET_WEBSOCKET_IN_ERROR }),
};

function initializeState(isUserLoggedIn, currentLanguage, contactId, dispatch, getState) {
    dispatch({ type: INIT, isUserLoggedIn, currentLanguage, contactId });
    fetchJson(url('homev6/serverData', currentLanguage)).then(resp => {
        dispatch({ type: INIT_SERVER_DATA, serverDateTime: utcDateTimeToLocal(resp.ServerDateUtc) });
    });
    if (isUserLoggedIn) {
        fetchJson(url('homev6/CurrentUserDetails', currentLanguage)).then(resp => {
            dispatch({ type: INIT_CONTACT, user: resp });
            loadExpiringPromo(resp.CompanyId, dispatch, currentLanguage);

            if (settings.useLogrocket) {
                LogRocket.init('s5r4lf/adesaeu-carsite');
                LogRocket.identify(contactId.toString(), { name: resp.CurrentUserUsername });
                console.log('Logrocket started!');
            }

            fetchJson(url('homev6/signupstatus', currentLanguage)).then(statusResp => {
                if (statusResp) {
                    dispatch({ type: INIT_SIGNUP, resp: statusResp });
                    whatfixConfig(contactId, resp, statusResp);
                    if (statusResp.SignUpLevel === 0 && hasUkCatalogueAccess(getState())) {
                        const message = t => {
                            return {
                                type: 'warning',
                                text: t('ukintegration.messages.levelzero'),
                                dismissible: true,
                            };
                        };
                        dispatch({ type: NEW_MESSAGE, message });
                    }
                }
            });
        });

        // get current watches for connected user.
        const getWatchesUrl = url('homev6/watches', currentLanguage);
        fetchJson(getWatchesUrl).then(watches => {
            if (watches) dispatch({ type: INIT_WATCHES, watches });
        });

        fetchJson(url('myaccount/mysettingsv6/companydetails', currentLanguage)).then(resp => {
            dispatch({ type: INIT_COMPANY_DETAILS, resp });
        });

        // fetch list of countries
        loadCountries(dispatch);
    } else {
        fetchJson(url('homev6/CurrentUserDetails', currentLanguage)).then(resp => {
            // to get VisitingUserCountry (deduced from x-country header)
            dispatch({ type: INIT_CONTACT, user: resp });
            whatfixConfig(0, { BuyerCountry: resp.VisitingUserCountry });
        });
    }
    loadOptions(dispatch);
    return loadSellerGroups(dispatch);
}

//                    whatfixConfig(contactId, resp.BuyerCountry, statusResp.SignUpLevel, statusResp.isBiddingAllowed, resp.BatchRestrictionGroupsUk);
//function whatfixConfig(contactId, buyerCountry, companyLevel, biddingAllowed, leascoGroups) {
function whatfixConfig(contactId, userDetails, signupStatus) {
    localStorage.removeItem('WP_Country');
    localStorage.removeItem('WP_User');
    localStorage.removeItem('WP_CompanyLevel');
    localStorage.removeItem('WP_BiddingAllowed');
    localStorage.removeItem('WP_LEASECO_Groups');
    localStorage.removeItem('WP_FirstAssignment');
    localStorage.removeItem('WP_FirstBid');
    localStorage.removeItem('WP_FirstLogin');
    localStorage.removeItem('WP_ExistingBuyerWithVBA');

    if (contactId) {
        const user = contactId;
        localStorage.setItem('WP_User', user);
    }
    if (userDetails) {
        const country = userDetails.BuyerCountry === 'gb' ? 'uk' : userDetails.BuyerCountry;
        localStorage.setItem('WP_Country', country);
    }
    if (signupStatus && signupStatus.SignUpLevel !== undefined) {
        localStorage.setItem('WP_CompanyLevel', signupStatus.SignUpLevel);
    }
    if (signupStatus && signupStatus.isBiddingAllowed !== undefined) {
        localStorage.setItem('WP_BiddingAllowed', signupStatus.isBiddingAllowed);
    }
    if (userDetails && userDetails.BatchRestrictionGroupsUk) {
        localStorage.setItem('WP_LEASECO_Groups', userDetails.BatchRestrictionGroupsUk);
    }
    if (userDetails && userDetails.CompanyId && !userDetails.DateFirstAssignment) {
        localStorage.setItem('WP_FirstAssignment', 'true');
    }
    if (userDetails && userDetails.CompanyId && !userDetails.DateFirstBid) {
        localStorage.setItem('WP_FirstBid', 'true');
    }
    if (userDetails && userDetails.CompanyId && !userDetails.DateFirstLoginCarsite) {
        localStorage.setItem('WP_FirstLogin', 'true');
    }
    if (userDetails) {
        localStorage.setItem('WP_ExistingBuyerWithVBA', userDetails.VBAStatus === 'EXISTING_BUYER' ? 'true' : 'false');
    }
}

function loadSellerGroups(dispatch) {
    return getJson(`${settings.metadataServiceUrl}/api/metadata/seller-groups`)
        .then(response => {
            if (response) dispatch({ type: SELLER_GROUPS_FETCHED, sellerGroups: response });
        })
        .catch(err => {
            console.log(err);
        });
}

async function loadCountries(dispatch) {
    const countries = await getJson(`${settings.metadataServiceUrl}/api/metadata/countries`, {
        headers: { 'Content-Type': 'application/json' },
    });
    if (countries) dispatch({ type: 'COMMON:COUNTRIES_FETCHED', countries });
}

async function loadOptions(dispatch) {
    const allOptions = await getJson(`${settings.metadataServiceUrl}/api/metadata/options`, {
        headers: { 'Content-Type': 'application/json' },
    });
    if (allOptions) dispatch({ type: 'COMMON:OPTIONS_FETCHED', allOptions });
}

function refreshUserDetails(dispatch, state) {
    const currentLanguage = state.common.currentLanguage;
    fetchJson(url('homev6/CurrentUserDetails', currentLanguage)).then(resp => {
        dispatch({ type: INIT_CONTACT, user: resp });
    });
}

function disableConfirmation(dispatch, state) {
    const setBidConfirmation = url('mybidsv6/SetBidConfirmation', state.common.currentLanguage);
    fetchJson(setBidConfirmation, {
        method: 'PUT',
        body: JSON.stringify({ enabled: false }),
    }).then(resp => {
        dispatch({ type: DISABLE_BID_CONFIRMATION });
    });
}

function followThisCar(auctionId, dispatch, state) {
    const followCarRequest = { auctionId };
    fetchJson(url('Car/FollowThisCar', state.common.currentLanguage) + '?' + queryString(followCarRequest)).then(resp => {
        const following = resp.WatchedLabelVisible === 'True'; // !state.carDetail.auctionInfo.followingCar;
        dispatch(removeNotifications());
        dispatch(notify(followNotificationMessage(following)));
        dispatch({ type: TOGGLE_FOLLOWING_CAR, auctionId, following });
    });
}
//fetch promo banner details
function loadExpiringPromo(companyId, dispatch, currentLanguage) {
    if (!companyId) return;
    fetchJson(url(`PromotionsService/GetExpiringByCompanyId?${queryString({ companyId })}`, currentLanguage)).then(resp => {
        if (resp && resp.DaysLeft > -1) {
            dispatch({ type: SET_PROMO_DETAILS, promoDetails: resp });

            const message = t => {
                let link = t('promotions.promo1.CmsLink');
                return {
                    type: 'warning',
                    text: (
                        <span>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: extrapolate(
                                        t('promotions.BuyNextGet150.Notification'),
                                        resp.DaysLeft,
                                        formatMoney(resp.PromotionAmount, 0, resp.CurrencyCodeId),
                                    ),
                                }}
                            />
                            <span> </span>
                            {link && <span dangerouslySetInnerHTML={{ __html: link }} />}
                        </span>
                    ),
                    dismissible: true,
                    isPromo: true,
                };
            };
            dispatch({ type: NEW_MESSAGE, message });
        }
    });
}

function statsToState(model) {
    return {
        auctionsCount: model.CarAuctionCount,
        auctionBatchesCount: model.AuctionBatchCount,
        inLast5minCount: model.InLast5Mins || undefined,
        needActionCarsCount: model.CarNeedAction || undefined,
        mybidsActiveCount: model.MyBidsActiveCount || undefined,
    };
}
