exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1iic5vNg8khZtKbNlHGo0N{font-weight:bold}", ""]);

// exports
exports.locals = {
	"header": "_1iic5vNg8khZtKbNlHGo0N"
};