import React, { useState } from 'react';
import FormText from '../components/Common/FormFields/FormText';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { AdesaButton, AdesaCheckbox } from '../components/Common/ui-components';
import ForgotPasswordDialog from '../components/Common/ForgotPasswordDialog';
import Footer from '../components/Layout/Footer';
import { Container } from 'reactstrap';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import './AccountConfirmation.less';
import { Spinner } from '@ambiorix/adesa-web-kit';

const accountConfirmationValidation = t => {
    return {
        username: yup
            .string()
            .max(40, t('registerForm.validation.maxLength40.error'))
            .required(t('registerForm.personalDetailsValidation.username.error.required')),
        password: yup
            .string()
            .required(t('registerForm.personalDetailsValidation.password.error.required'))
            .max(50, t('registerForm.companyDetailsValidation.maxLength50.error'))
            .min(4, t('registerForm.input.label.helpPassword5')),
    };
};

const AccountConfirmationScreen = ({ t, currentLanguage, onLogin, currentUser }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [remeberUsername, setremeberUsername] = useState(false);
    const [isOpen, setOpen] = useState(false);

    const resolverSchema = yup.object().shape(accountConfirmationValidation(t));
    const formMethods = useForm({
        // defaultValues,
        mode: 'onBlur',
        criteriaMode: 'all',
        resolver: yupResolver(resolverSchema),
    });
    const {
        handleSubmit,
        getValues,
        clearErrors,
        formState: { errors },
    } = formMethods;

    const formData = getValues();

    // check if input field is valid
    function isFieldValid(inputName) {
        return formData && inputName && inputName.length !== 0 && formData[inputName] && !errors[inputName];
    }
    const showPasswordHandler = () => {
        setShowPassword(!showPassword);
    };
    const onFormSubmit = data => {
        if (data) {
            const username = data.username;
            const password = data.password;
            onLogin(username, password, remeberUsername);
        }
    };
    const handleSubmitClick = () => handleSubmit(onFormSubmit)();
    const ukCountryIds = ['uk', 'gb'];
    const visitingFromUk = ukCountryIds.includes(currentUser.visitingUserCountry.toLowerCase());

    return (
        <div>
            <div className="image-logo-div">
                <a href="/">
                    <img className="openlane-logo" src="/v6/images/openlane-logo.svg" title="Openlane" alt="Openlane" width="300px" />
                </a>
            </div>
            <Container className="rc-account-confirmation">
                <h3 className="account-title">{t('registerForm.confirmation.H1')}</h3>
                <p className="spacing-xl">
                    {visitingFromUk ? t('registerForm.confirmation.accountConfirmedUK') : t('registerForm.confirmation.accountConfirmed')}
                </p>
                {!visitingFromUk ? (
                    <>
                        <FormProvider {...formMethods}>
                            <form onSubmit={handleSubmit(onFormSubmit)}>
                                <FormText name="username" label={t('registerForm.input.label.username')} showSuccess={isFieldValid('username')} />
                                <FormText
                                    name="password"
                                    label={t('registerForm.input.label.password')}
                                    type={showPassword ? 'text' : 'password'}
                                    showPasswordHandler={showPasswordHandler}
                                    showPassword={showPassword}
                                />
                                <AdesaButton size="primary" color="positive" onClick={handleSubmitClick}>
                                    {t('navigation.top.login')}
                                </AdesaButton>
                                <div className="bottom-content">
                                    <AdesaCheckbox
                                        id="rememberUsername"
                                        checked={remeberUsername}
                                        onChange={() => setremeberUsername(!remeberUsername)}>
                                        {t('login.rememberme')}
                                    </AdesaCheckbox>
                                    <p className="forgot-password-link" onClick={() => setOpen(!isOpen)}>
                                        {t('login.link.forgotPassword')}
                                    </p>
                                </div>
                            </form>
                        </FormProvider>
                        <ForgotPasswordDialog t={t} isOpen={isOpen} setOpen={setOpen} currentLanguage={currentLanguage} />
                    </>
                ) : null}
                <hr />
                <label>{t('login.noAccountYet')}</label> <a href={`/${currentLanguage}/register`}>{t('login.button.register')}</a>
            </Container>
            <Footer currentLanguage={currentLanguage} t={t} />
        </div>
    );
};

export default AccountConfirmationScreen;
