import React, { ChangeEventHandler, ComponentProps } from 'react';
import { Override, Select } from '@ambiorix/adesa-web-kit';
import { cx } from 'class-variance-authority';
import styles from './Select.module.scss';

export type AdesaSelectProps = Override<
    ComponentProps<typeof Select>,
    {
        disabled?: boolean | string;
        onChange?: (event: CustomEvent<{ value: string }>) => void;
    }
>;

// map ui-components select props to web kit select
export const AdesaSelect = ({ className = '', disabled, onChange, value, ...props }: AdesaSelectProps) => {
    const mappedDisabled = disabled !== 'false' && Boolean(disabled);

    const mappedOnChange: ChangeEventHandler<HTMLSelectElement> = event => {
        const customEvent = new CustomEvent('changed', {
            bubbles: true,
            composed: true,
            detail: { value: event.target.value },
        });
        onChange?.(customEvent);
    };

    const mappedValue = value === null ? '' : value;

    return <Select className={cx(styles.select, className)} disabled={mappedDisabled} onChange={mappedOnChange} value={mappedValue} {...props} />;
};
