import { formatDate } from '../../utils/date';
import React, { useEffect, useRef, useState } from 'react';
import ResizedIFrame from '../../components/ui/ResizedIFrame';
import { Container, Row, Col } from 'reactstrap';
import { goToHome, goToLogin } from '../../utils';
import { PageTitle } from '@ambiorix/adesa-web-kit';
import './MyAccount.less';
import { Redirect } from 'react-router-dom';
import useTimeout from '../../hooks/useTimeout';
import { Spinner } from '../../components/ui/common-ui';
import { useAppSettings } from '../../hooks/useAppSettings';
import { useMyAccountV2Bundle } from '../../hooks/useMyAccountV2Bundle';

// cfr https://louisbarranqueiro.github.io/reapop/
const messageStatusMapping = {
    success: 'success',
    removalSuccess: 'info',
    businessError: 'error',
    internalError: 'error',
    //"": "warning",
    //"": "none",
};

const legacyMyaccountUrlMapping = () => {
    const list = [
        // [<regex for checking current path>, <url to load in iframe>, <translation key for title>, <blockedForNonEu]
        [/\/my-account\/orders$/i, 'myaccount/purchases?frameless', 'myAccount.orders.title', false],
        [/\/my-account\/documents$/i, 'myaccount/documents?frameless', 'myAccount.documents.title', true],
        [/\/my-account\/claims$/i, 'myaccount/claims?frameless', 'myAccount.claims.title', true],
        [/\/my-account\/archive$/i, 'myaccount/reports?frameless', 'myAccount.archive.title', false],
    ];
    return list;
};

export default function MyAccount({ t, currentLanguage, onNotify, currentUserAllowedCatalogues, currentUserHasEuCatalogueAccess }) {
    const myAccountElementContainer = useRef(null);
    const myAccountElement = useRef(null);

    const appSettings = useAppSettings();

    const messageHandler = evt => {
        const message = evt.detail;
        onNotify({
            message: `${message.message}`,
            status: messageStatusMapping[message.messageType] || 'none',
        });
        if (message.httpErrorCode == 401) {
            // not authorized ==> redirect to home (show login screen too?)
            goToHome();
        }
    };

    const unAuthorizedExceptionHandler = evt => {
        // not authorized ==> redirect to home (show login screen too)
        goToLogin();
    };

    useMyAccountV2Bundle(myAccountElement, myAccountElementContainer, messageHandler, unAuthorizedExceptionHandler);

    //#546709 Adding useTimeout because currentUserHasEuCatalogueAccess takes time to update from server
    // So that we can prevent redirect on refresh of the claims page
    const [delayed, setDelayed] = useState(false);
    useTimeout(() => setDelayed(true), 5000);

    if (appSettings.isLoading) return <Spinner />;

    // check if we need to display the old myaccount in an iframe.
    let legacyMyAccountUrl = null,
        titleTranslationKey = null,
        isBlockedForNonEu = false;
    legacyMyaccountUrlMapping().forEach(([re, url, key, blockedForNonEu]) => {
        if (re.test(window.location.pathname)) {
            isBlockedForNonEu = !currentUserHasEuCatalogueAccess && blockedForNonEu;
            legacyMyAccountUrl = url;
            titleTranslationKey = key;
        }
    });

    //only Sending the featureFlags keys which is true-----
    const featureFlags = Object.keys(appSettings.featureFlags).filter(k => appSettings.featureFlags[k]);

    return (
        <Container fluid={false}>
            <Row>
                <Col className="rc-my-account">
                    <div ref={myAccountElementContainer}>
                        {delayed && isBlockedForNonEu ? (
                            <Redirect to={`/${currentLanguage}/home`} />
                        ) : legacyMyAccountUrl ? (
                            /\/my-account\/orders$/.test(location.pathname) ? (
                                <Redirect to={`/${currentLanguage}/my-account/my-orders`} />
                            ) : (
                                <>
                                    <PageTitle>{t(titleTranslationKey)}</PageTitle>
                                    <ResizedIFrame url={`/${currentLanguage}/${legacyMyAccountUrl}`} />
                                </>
                            )
                        ) : (
                            <my-account
                                ref={myAccountElement}
                                metadata-service-url={appSettings.metadataServiceUrl}
                                thumbnail-service-url-prefix={appSettings.thumbnailServiceUrlPrefix}
                                damage-base-url={appSettings.damageBaseUrl}
                                community-images-url-prefix={appSettings.communityImagesBaseUrl}
                                allowed-catalogues={currentUserAllowedCatalogues.join('|')}
                                feature-flags={featureFlags.join('|')}></my-account>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
