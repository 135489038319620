import React, { ComponentProps, forwardRef } from 'react';
import { cx } from 'class-variance-authority';
import { NumberInput } from '@ambiorix/adesa-web-kit';

import { getNumberSeparators } from '../../utils';
import styles from './Input.module.scss';

type AdesaNumberInputProps = ComponentProps<typeof NumberInput>;

export const AdesaNumberInput = forwardRef<HTMLInputElement, AdesaNumberInputProps>(({ className, ...props }, ref) => {
    return <NumberInput ref={ref} className={cx(styles.input, className)} {...getNumberSeparators()} {...props} />;
});
