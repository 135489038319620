import React from 'react';
import { InfoBanner } from '@ambiorix/adesa-web-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTimes, faGhost } from '@fortawesome/free-solid-svg-icons';
import './ImpersonateBanner.less';

const ImpersonateBanner = ({ t, currentUser, onLogout, className = '' }) => {
    return (
        <div className={`rc-ImpersonateBanner ${className}`}>
            <InfoBanner
                variant="error"
                style="ghost"
                title={t('login.impersonationActive')}
                actionText={t('login.button.stopImpersonate')}
                actionProps={{ onClick: e => onLogout(e), suffix: <FontAwesomeIcon icon={faUserTimes} /> }}
                description={
                    <p>
                        {currentUser.firstName} {currentUser.lastName}, {currentUser.companyName}, {t('country.' + currentUser.country)} (
                        {currentUser.phoneNr} / {currentUser.email})
                    </p>
                }
                titleIcon={<FontAwesomeIcon icon={faGhost} />}
                showCloseIcon={false}
            />
        </div>
    );
};

export default ImpersonateBanner;
