import React, { useEffect } from 'react';
import CarDamages from '../components/CarDetail/CarDamages';

import './DamageReport.less';

const DamageReport = ({ t, auctionInfo, carDamages, carDetails, picturesList }) => {
    const originCountry = carDetails.originCountryOfCarCountryId ? carDetails.originCountryOfCarCountryId.toUpperCase() : '';
    const showFooter = false;
    const conditions = [];
    if (carDetails.isDamagedCar) conditions.push(t('vehicleDetails.vehicleCondition.damaged').toUpperCase());
    if (carDetails.isDrivable === 'no') conditions.push(t('vehicleDetails.vehicleCondition.nonDrivable').toUpperCase());

    useEffect(() => {
        document.body.style.backgroundColor = 'white';
    }, []);

    return carDetails && carDamages ? (
        <div className="rc-damage-report">
            <header>
                <h1>{t('reports.damage.title')}</h1>
                <div className="ref">{`${t('vehicleDetails.referenceNumber')} ${auctionInfo.auctionId} - ${originCountry}${auctionInfo.carId}`}</div>
            </header>
            <div className="car-detail">
                {(picturesList && picturesList[0] && <img className="picture" src={picturesList[0].Url} alt="Car" />) || (
                    <img src="/v6/images/car-placeholder.png" className="picture" alt="Car" />
                )}
                <div className="details">
                    <h2>{carDetails.name}</h2>
                    <p>
                        {t('reports.damage.vehicleDetails.licencePlate')}: {carDetails.licensePlace}
                        {' - '}
                        {t('vehicleDetails.profile.chassisNumber')}: {carDetails.chassis}
                    </p>
                    <p>{conditions.join(' - ')}</p>
                </div>
            </div>
            <div className="conditions">{carDetails.isDrivable === 'no' ? <p>{t('reports.damage.nonDrivableDescription')}</p> : null}</div>
            <CarDamages t={t} carDamages={carDamages} carDetails={carDetails} picturesList={picturesList} />
            {showFooter ? (
                <footer>
                    <div className="fill-in">
                        <div className="comment">{t('reports.damage.comment')}</div>
                        <div className="stamp">{t('reports.damage.stamp')}</div>
                    </div>
                    <div className="bottom">
                        <div className="adesa-address">
                            <p>ADDRESS LINE 1</p>
                            <p>ADDRESS LINE 2</p>
                            <p>ADDRESS LINE 3</p>
                        </div>
                        <img src="/v6/images/openlane-logo.svg" alt="openlane logo" className="openlane-logo" />
                    </div>
                </footer>
            ) : null}
        </div>
    ) : null;
};

export default DamageReport;
