import React, { ComponentProps, MouseEventHandler } from 'react';
import { Button, Override } from '@ambiorix/adesa-web-kit';

type BaseProps = ComponentProps<typeof Button>;
export type AdesaButtonProps = Override<
    BaseProps,
    {
        color?: 'positive' | 'ghost-blue' | 'link-blue' | 'ghost-white';
        disabled?: boolean | string;
        onClicked?: BaseProps['onClick'];
        size?: 'primary' | 'secondary' | 'tertiary' | BaseProps['size'];
    }
>;

const SIZE_MAP: Record<Exclude<AdesaButtonProps['size'], BaseProps['size']>, BaseProps['size']> = {
    primary: 'md',
    secondary: 'sm',
    tertiary: 'xs',
};

const VARIANT_MAP: Record<NonNullable<AdesaButtonProps['color']>, BaseProps['variant']> = {
    'ghost-blue': 'outlined',
    'ghost-white': 'solid',
    'link-blue': 'ghost',
    positive: 'solid',
};

// map ui-components button props to web kit button
export const AdesaButton = ({
    className = '',
    color = 'positive',
    disabled,
    fullWidth = true,
    onClick,
    onClicked,
    size = 'primary',
    variant,
    ...props
}: AdesaButtonProps) => {
    const mappedDisabled = disabled !== 'false' && Boolean(disabled);
    const mappedSize: BaseProps['size'] = SIZE_MAP[size] ?? size;
    const mappedVariant: BaseProps['variant'] = variant ?? VARIANT_MAP[color];

    const handleClick: MouseEventHandler<HTMLButtonElement> = event => {
        onClick?.(event);
        onClicked?.(event);
    };

    return (
        <Button
            className={className}
            disabled={mappedDisabled}
            size={mappedSize}
            variant={mappedVariant}
            fullWidth={fullWidth}
            onClick={handleClick}
            {...props}
        />
    );
};
