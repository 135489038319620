import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { InfoIcon } from '../ui/InfoIcon';
import { Stars } from '../ui/common-ui';
import { formatNumber } from '../../utils';
import { vehicleGradesMetadata, mechanicalIndicatorsMetadata, inventoryTypeMapping, spareTyres } from '../../state/cotw';
import { AdesaModal } from '../Common/ui-components';

// Inline styles because the Popover component is not located inside the rc-CarProfile div.
export const styles = {
    scoreNumber: {
        fontSize: '110%',
        display: 'inline-block',
        padding: '0 10px',
    },
    bold: {
        fontWeight: 'bold',
    },
    focus: {
        fontWeight: 'bold',
        fontSize: '110%',
    },
};

// Formatter Function for tyres
export function translateFrontRear(value, t) {
    if (value) {
        return value
            .replace('FRONT', t('vehicleDetails.profile.front'))
            .replace('REAR', t('vehicleDetails.profile.rear'))
            .replace(new RegExp('INCH', 'g'), t('common.units.inch'))
            .replace(new RegExp('MM', 'g'), t('common.units.mm'));
    } else {
        return value;
    }
}

//Display grade text on the bases of grade spareTireId
export const displaySpareTyres = (id, t) => {
    if (id) return t(spareTyres[id]);
    return '';
};

//Display grade text on the bases of grade id
export const displayGradeText = (gradeId, t) => {
    if (gradeId) {
        let vGradeText = vehicleGradesMetadata.filter(vdetails => vdetails.id == gradeId && vdetails.label);
        return (vGradeText && t(vGradeText[0].label)) || '';
    }
    return '';
};

export const displayGradeSubtext = (gradeId, t) => {
    if (gradeId) {
        let vGradeText = vehicleGradesMetadata.filter(vdetails => vdetails.id == gradeId && vdetails.subHeading);
        return (vGradeText && t(vGradeText[0].subHeading)) || '';
    }
    return '';
};
//Display grade text on the bases of grade id
export const displayGradeIconColor = gradeId => {
    if (gradeId && vehicleGradesMetadata) {
        let vGradeColor = vehicleGradesMetadata.filter(vdetails => vdetails.id == gradeId && vdetails.iconColor);
        return (vGradeColor && vGradeColor[0].iconColor) || '';
    }
    return '';
};

export const displayMechanicalIndText = (MId, t) => {
    if (MId) {
        let miText = mechanicalIndicatorsMetadata.filter(midetails => midetails.id == MId && midetails.label);
        return (miText && t(miText[0].label)) || '';
    }
    return '';
};

export const displayMIIconColor = MId => {
    if (MId && mechanicalIndicatorsMetadata) {
        let miColor = mechanicalIndicatorsMetadata.filter(midetails => midetails.id == MId && midetails.iconColor);
        return (miColor && miColor[0].iconColor) || '';
    }
    return '';
};

// Display Inventory Type
export const displayInventoryType = (inventoryId, t) => {
    if (inventoryId && inventoryTypeMapping) return t(inventoryTypeMapping[inventoryId]);
    return '';
};

// UI Common elements
export const Title = ({ label, showColor }) => (
    <div className={showColor ? 'title title-bg-color' : 'title'}>
        <Row>
            <Col>
                <h3 className={showColor ? 'title-sub-heading' : ''}>{label}</h3>
            </Col>
        </Row>
    </div>
);

export const DetailLine = ({
    label,
    value,
    children,
    info,
    id,
    dataAttr,
    className,
    showIcon,
    subText,
    iconColor,
    isUkCar,
    showModel,
    setShowModal,
    isReportRequested,
}) => (
    <div className="line">
        <Row>
            <Col xs={6}>
                {label}
                {info ? <InfoIcon id={id} text={info} /> : undefined}
            </Col>
            <Col className={className} data-attr={dataAttr} xs={6}>
                {isUkCar ? (
                    <>
                        {showIcon && <i className={`fa fa-circle ${iconColor} mr-1`} aria-hidden="true" />}
                        {showIcon && !isReportRequested ? (
                            <span id="gradeLink" onClick={() => setShowModal(!showModel)}>
                                {value}
                            </span>
                        ) : (
                            <> {value}</>
                        )}
                        {/* {subText && <p className="subtext-style">{subText}</p>} */}
                    </>
                ) : (
                    <>{value ? value : children}</>
                )}
            </Col>
        </Row>
    </div>
);

export const LinesGroup = ({ children }) => <div className="lines-group">{children}</div>;

export const Score = ({ label, value, focus, decimal = false }) => (
    <Row style={focus ? styles.focus : { marginBottom: '3px' }}>
        <Col sm={5}>{label}</Col>
        <Col sm={7}>
            <div className="seller-score-main">
                <Stars value={value} />
                <span style={styles.scoreNumber}>{formatNumber(value, decimal ? 1 : 0)}/5</span>
            </div>
        </Col>
    </Row>
);

//Grade Modal MI and VG
export const GradesMIModal = ({ className, children, title, showModel, setShowModal }) => {
    return (
        <>
            <AdesaModal is-open={showModel} onChanged={() => setShowModal(!showModel)} className={className}>
                <h3 slot="header">
                    <b>{title}</b>
                </h3>
                <div slot="body">{children}</div>
            </AdesaModal>
        </>
    );
};

export const DisplayList = ({ gradeList, t, subTitle, showDisclaimerText = false }) => {
    return (
        <div className="garde-Body-style">
            <p>{subTitle}</p>
            <hr />
            {gradeList &&
                gradeList.map((item, ind) => {
                    return (
                        <div key={`grademodal-id${item.id}key${ind}`}>
                            <p className="mb-1" style={{ fontWeight: 'bold' }}>
                                <i className={`fa fa-circle ${item.iconColor} mr-1`} aria-hidden="true" />
                                {t(item.label)}
                            </p>
                            <p className="mb-1">{t(item.description)}</p>
                            <hr />
                        </div>
                    );
                })}
            {showDisclaimerText ? <p>{t('vehicleDetails.vehicleGrades.MIDisclaimerText')}</p> : null}
        </div>
    );
};
