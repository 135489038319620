import React, { useState } from 'react';
import FormText from './FormFields/FormText';
import { FormProvider, useForm } from 'react-hook-form';
import { csrfToken, csrfTokenName } from '../../utils';
import { useAtleastMDMediaQuery } from '../../hooks/useAtleastMDMediaQuery';
import { Row, Col } from 'reactstrap';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AdesaButton, AdesaModal } from './ui-components';
import './ForgotPassword.less';
import { Spinner } from '@ambiorix/adesa-web-kit';
const Line = ({ children }) => (
    <Row className="line">
        <Col xs>{children}</Col>
    </Row>
);
const ForgotPasswordDialog = ({ t, isOpen, setOpen, currentLanguage }) => {
    const [reqstSuccess, setReqstSuccess] = useState(false);
    const [reqstPending, setReqstPending] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    //using to verify md screen
    const isMDScreen = useAtleastMDMediaQuery();
    const defaultValues = {};
    const validationSchema = yup.object().shape({
        username: yup.string().required(t('registerForm.personalDetailsValidation.username.error.required')),
    });
    const formMethods = useForm({ mode: 'onBlur', defaultValues, resolver: yupResolver(validationSchema) });
    const { handleSubmit } = formMethods;

    const onSubmit = async data => {
        if (data) {
            const requestBody = new FormData();
            requestBody.append('Username', data.username);
            setErrorMsg('');
            setReqstPending(true);
            const response = await fetch(`/${currentLanguage}/Login/ForgotPasswordNew`, {
                method: 'POST',
                mode: 'cors',
                credentials: 'include',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    Accept: 'application/json',
                    [csrfTokenName]: csrfToken(),
                },
                body: requestBody,
            });
            if (response && response.ok) {
                setReqstSuccess(true);
                setReqstPending(false);
            }
            if (response && !response.ok) {
                setErrorMsg('Error');
                setReqstPending(false);
            }
        }
    };
    const handleSubmitClick = () => formMethods.handleSubmit(onSubmit)();

    return (
        <>
            <div className="forgot-password-form-container">
                <AdesaModal is-open={isOpen} width={isMDScreen ? '35' : '90'} onChanged={() => setOpen(!isOpen)}>
                    <h3 slot="header">
                        <b>{reqstSuccess ? t('passwordSent.headline') : t('forgotPassword.headline')}</b>
                    </h3>
                    <div slot="body">
                        <>
                            {reqstSuccess ? (
                                <>
                                    <Line>
                                        <p>{t('passwordSent.description')}</p>
                                    </Line>
                                    <Line>
                                        <AdesaButton id="closeButton1" size="secondary" color="ghost-blue" onClick={() => setOpen(!isOpen)}>
                                            {t('common.action.close')}
                                        </AdesaButton>
                                    </Line>
                                </>
                            ) : (
                                <>
                                    <Line>
                                        <p>{t('forgotPassword.description')}</p>
                                    </Line>
                                    <div className="forgotpass-div">
                                        <FormProvider {...formMethods}>
                                            <form onSubmit={handleSubmit(handleSubmitClick)}>
                                                <FormText name="username" label={t('forgotPassword.username')} required />
                                                <AdesaButton id="forgotPasswordBtn11" size="secondary" color="positive" type="submit">
                                                    {reqstPending ? <Spinner /> : t('forgotPassword.button.sendPassword')}
                                                </AdesaButton>
                                            </form>
                                        </FormProvider>
                                    </div>

                                    <Line>
                                        <span className="errorMsg">{errorMsg} </span>
                                    </Line>
                                </>
                            )}
                        </>
                    </div>
                </AdesaModal>
            </div>
        </>
    );
};

export default ForgotPasswordDialog;
