exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2Wa1jzk3i9UQwHJ7nQMb_I ._3Za3UQ08KuWICkKqjuIZt3{margin-bottom:0px}._2Wa1jzk3i9UQwHJ7nQMb_I ._3Za3UQ08KuWICkKqjuIZt3 ._1nXRVG_8r0KiHqo-1anm1o{color:var(--error-500)}", ""]);

// exports
exports.locals = {
	"rcPromoBanner": "_2Wa1jzk3i9UQwHJ7nQMb_I",
	"promoBannerTitle": "_3Za3UQ08KuWICkKqjuIZt3",
	"promoDiscount": "_1nXRVG_8r0KiHqo-1anm1o"
};