import React, { ComponentType, Suspense } from 'react';
import { Spinner } from '@ambiorix/adesa-web-kit';

export const createLazyRoute = <TProps extends {}>(loader: () => Promise<{ default: ComponentType<TProps> }>) => {
    const LazyComponent = React.lazy(loader);

    function LazyRoute(props: TProps) {
        return (
            <Suspense fallback={<Spinner className="router-spinner" />}>
                <LazyComponent {...props} />
            </Suspense>
        );
    }
    LazyRoute.load = loader;

    return LazyRoute;
};

export const AuctionBatches = createLazyRoute(() => import(/* webpackPrefetch: true */ './components/AuctionBatches'));
export const CarDetail = createLazyRoute(() => import(/* webpackPrefetch: true */ './components/CarDetail'));
export const CarSearch = createLazyRoute(() => import(/* webpackPrefetch: true */ './components/CarSearch'));
export const Communities = createLazyRoute(() => import(/* webpackPrefetch: true */ './views/Communities'));
export const Home = createLazyRoute(() => import(/* webpackPrefetch: true */ './components/Home'));
export const LoginOkta = createLazyRoute(() => import(/* webpackPrefetch: true */ './components/Home/LoginOkta'));
export const LoginOktaCallback = createLazyRoute(() => import(/* webpackPrefetch: true */ './components/Home/LoginOktaCallback'));
export const MyBids = createLazyRoute(() => import(/* webpackPrefetch: true */ './components/MyBids'));
export const MySelectionView = createLazyRoute(() => import(/* webpackPrefetch: true */ './views/MySelectionView'));
export const OrderCompletion = createLazyRoute(() => import(/* webpackPrefetch: true */ './components/OrderCompletion'));
export const RegisterPage = createLazyRoute(() => import(/* webpackPrefetch: true */ './components/Register/RegisterPage'));
export const ResetOktaPassword = createLazyRoute(() => import(/* webpackPrefetch: true */ './views/ResetOktaPassword'));
export const XTimeDashboard = createLazyRoute(() => import(/* webpackPrefetch: true */ './components/XTimeDashboard'));
