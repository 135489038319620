import React, { Component } from 'react';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';

import './InfoIcon.less';

export class InfoIcon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popoverOpen: false,
        };
    }

    toggle() {
        this.setState({ popoverOpen: !this.state.popoverOpen });
    }

    render() {
        const { id, text, placement, className } = this.props;
        return (
            <div className={'rc-InfoIcon ' + className}>
                <Popover placement={placement || 'top'} isOpen={this.state.popoverOpen} toggle={this.toggle.bind(this)} target={id}>
                    {/* <PopoverHeader>Popover Title</PopoverHeader> */}
                    <PopoverBody>{text}</PopoverBody>
                </Popover>
                <span id={id} onClick={this.toggle.bind(this)} className="info-icon">
                    i
                </span>
            </div>
        );
    }
}
