import {
    COMPANY_DETAILS_VALIDATED,
    SUBMITTED,
    VAT_VALIDATED,
    COUNTRIES_LOADED,
    INVOICING_OFFICES_LOADED,
    PHONE_COUNTRY_CODES_LOADED,
    LANGUAGES_LOADED,
    SAVE_FORM_DATA,
    ACCOUNT_TYPE_VALIDATED,
    SELLER_TYPES_LOADED,
    SAVE_BUSINESS_TYPE_DETAILS,
    EDIT_BUSINESS_TYPE_DETAILS,
} from './actions';
import { getDefaultInvoicingOfficeId, getPhonePrefix } from '../../components/Register/RegisterHelper';
import settings from '../../appsettings';

const registerInfoInitialState = {
    vat: '',
    companyName: '',
    companyAddressLine1: '',
    companyAddressLine2: '',
    postalCode: '',
    stateProvince: '',
    city: '',
    invoicingOffice: '',
    postaElettronicaCertificata: '',
    codiceDestinatario: '',
    country: '',
    language: '',
    subscribeNewsletter: false,
    termsAndConditions: false,
    professionalTrader: false,
    wantsToSell: false,
    sellerType: '',
    phone: '',
    //TODO NOT SUBMIT
    countries: [],
    invoicingOffices: [],
    phoneCountryCodes: [],
    languages: [],
    vatCountry: '',
    sellerTypes: [],
    businessTypeId: null,
    isSecondaryActivity: null,
};

const initialState = {
    step: 0,
    registerInfo: registerInfoInitialState,
};

export function registerReducer(state = initialState, action) {
    switch (action.type) {
        case VAT_VALIDATED: {
            const country = action.response.Country ? action.response.Country.toLowerCase() : state.registerInfo.country;
            const vatCountry = action.response.Country ? action.response.Country.toLowerCase() : '';
            const registerInfo = {
                ...state.registerInfo,
                vat: action.response.VatNumber,
                companyName: action.response.Name,
                companyAddressLine1: action.response.Street,
                postalCode: action.response.PostalCode,
                city: action.response.City,
                country,
                vatCountry,
            };
            return { ...state, registerInfo: registerInfo, step: 1 };
        }
        case COUNTRIES_LOADED: {
            const registerInfo = {
                ...state.registerInfo,
                countries: action.countries.filter(c => c.IsRegistrationAllowed),
            };
            return { ...state, registerInfo: registerInfo };
        }
        case INVOICING_OFFICES_LOADED: {
            const defaultInvoicingOffice = getDefaultInvoicingOfficeId(state.registerInfo.country, action.invoicingOffices);

            const registerInfo = {
                ...state.registerInfo,
                invoicingOffices: action.invoicingOffices,
                invoicingOffice: defaultInvoicingOffice,
            };
            return { ...state, registerInfo: registerInfo };
        }
        case PHONE_COUNTRY_CODES_LOADED: {
            const allowedCountries = state.registerInfo.countries.map(c => c.id);
            // restrict list to the list of allowed countries.
            const phoneCountryCodes = action.phoneCountryCodes.filter(c => allowedCountries.includes(c.id));
            const registerInfo = {
                ...state.registerInfo,
                phoneCountryCodes,
                phone: getPhonePrefix(state.registerInfo.country, phoneCountryCodes),
            };
            return { ...state, registerInfo: registerInfo };
        }
        case LANGUAGES_LOADED: {
            const registerInfo = {
                ...state.registerInfo,
                languages: action.languages,
                language: action.currentLanguage,
            };
            return { ...state, registerInfo: registerInfo };
        }
        case SELLER_TYPES_LOADED: {
            const registerInfo = {
                ...state.registerInfo,
                sellerTypes: action.sellerTypes,
            };
            return { ...state, registerInfo };
        }
        case COMPANY_DETAILS_VALIDATED: {
            const registerInfo = {
                ...state.registerInfo,
                ...action.data,
            };

            const isUk = registerInfo.country.toLowerCase() === 'uk';

            const isSegaEnabled = !!settings.featureFlags['IsSegaEnabled'];
            let nextStep = 3;
            if (isSegaEnabled) {
                nextStep = !isUk && registerInfo.businessTypeId ? 3 : 2;
            } else {
                nextStep = registerInfo.isSellerAllowed ? 2 : 3;
            }
            return { ...state, registerInfo: registerInfo, step: nextStep };
        }
        case ACCOUNT_TYPE_VALIDATED: {
            const registerInfo = {
                ...state.registerInfo,
                ...action.data,
            };

            return { ...state, registerInfo, step: registerInfo.businessTypeId ? 3 : 2 };
        }
        //On save Business type
        case SAVE_BUSINESS_TYPE_DETAILS: {
            const registerInfo = {
                ...state.registerInfo,
                ...action.data,
            };

            return { ...state, step: registerInfo.businessTypeId ? 3 : 2 };
        }

        case EDIT_BUSINESS_TYPE_DETAILS: {
            return { ...state, step: 2 };
        }

        case SAVE_FORM_DATA: {
            const registerInfo = {
                ...state.registerInfo,
                ...action.data,
            };
            return { ...state, registerInfo: registerInfo };
        }
        case SUBMITTED: {
            return { ...state, step: 4 };
        }
        default:
            return { ...state };
    }
}
