import { push } from 'connected-react-router';
import { parseDate } from '../utils/date';
import { notify } from 'reapop';
import settings from '../appsettings';
import { fetchJson } from '../utils';

function url(urlSuffix, currentLanguage) {
    return settings.carsiteBaseUrl + '/' + currentLanguage + '/' + urlSuffix;
}

// ===== REDUX ACTIONS ====================================
const INIT = 'MYACCOUNT:INIT';
const START_FETCH_ORDERS = 'MYACCOUNT:START_FETCH_ORDERS';
const ORDERS_FETCHED = 'MYACCOUNT:ORDERS_FETCHED';

const initialState = {
    loadingCommunityOrder: false,
    communityOrders: [],
};

/***************************************************************************************************************
 *  REDUX REDUCER
 */
export function myAccountReducer(state = initialState, action) {
    switch (action.type) {
        case INIT: {
            return {
                ...state,
            };
        }

        case START_FETCH_ORDERS: {
            return {
                ...state,
                loadingCommunityOrder: true,
            };
        }

        case ORDERS_FETCHED: {
            // add thumbnails in list of orders.

            const orders = action.orders.map(o => ({
                ...o,
                ThumbnailUrl: action.thumbnails[o.carId.toString()],
                seller: action.sellersByCommunityId[o.communityId.toString()],
            }));

            return {
                ...state,
                loadingCommunityOrder: false,
                communityOrders: mapCommunityOrders(orders),
            };
        }
        default:
            return { ...state };
    }
}

/***************************************************************************************************************
 *  REACT-REDUX state to properties map.
 */
export const myAccountMapStateToProps = globalState => ({
    isUserLoggedIn: globalState.common.isUserLoggedIn || false,
    currentLanguage: globalState.common.currentLanguage || 'en',
    currentUser: globalState.common.currentUser,
    communityOrders: globalState.myAccount.communityOrders,
    loadingCommunityOrder: globalState.myAccount.loadingCommunityOrder,
});

/***************************************************************************************************************
 *  REACT-REDUX action creators (using redux-thunk: Redux Thunk middleware allows you to write action creators that return a function instead of an action)
 */

function notifyError(dispatch, message) {
    dispatch(notify({ message: message, status: 'error' }));
}

export const myAccountActionsCreator = {
    onFetchCommunityOrders: () => (dispatch, getState) => fetchCommunityOrders(dispatch, getState),
};

function fetchCommunityOrders(dispatch, getState) {
    const state = getState();
    dispatch({ type: START_FETCH_ORDERS });
    fetchJson(url('myaccount/myaccountv6/CommunityOrders', state.common.currentLanguage))
        .then(orders => {
            if (orders) {
                const carIds = [...new Set(orders.map(o => o.carId))];
                const communityIds = [...new Set(orders.map(o => o.communityId))];
                Promise.all([getCarThumbnails(carIds, state), getSellersByCommunityId(communityIds, state)]).then(
                    ([thumbnails, sellersByCommunityId]) => {
                        dispatch({ type: ORDERS_FETCHED, orders, thumbnails, sellersByCommunityId });
                    },
                );
            }
        })
        .catch(e => {
            console.error(e);
            dispatch(notify({ message: 'Error while loading communities ', status: 'error' }));
        });
}

function getSellersByCommunityId(communityIds, state) {
    return Promise.all(
        communityIds.map(communityId => {
            return fetchJson(url(`communitiesv6/CommunitySeller/${communityId}`, state.common.currentLanguage));
        }),
    ).then(sellers => {
        return communityIds.reduce((hash, val, i) => ({ ...hash, [val]: sellers[i] }), {});
    });
}

function getCarThumbnails(carIds, state) {
    if (carIds.length > 0) {
        return fetchJson(url('carv6/Thumbnails', state.common.currentLanguage), {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(carIds),
        });
    } else {
        return Promise.resolve({});
    }
}

/***************************************************************************************************************
 *  DATA - REDUX STATE MAPPINGS.
 */

/*

  {
    "CarId": 3211717,
    "AuctionId": 5607462,
    "CarName": "Opel Corsa 1.2i ",
    "Fuel": "Benzine",
    "Gearbox": "Manual",
    "Mileage": 63937,
    "CommunityId": 31,
    "CommunityName": "FUNCTIONAL TEST",
    "CommunityContactName": "Dirk DE WIJNGAERT",
    "Price": 5300.0
  },

  */

function mapCommunityOrders(orders) {
    return orders.map(o => ({
        auctionId: o.auctionId,
        carTitle: o.carName,
        fuel: (o.fuel || '').toLowerCase(),
        mileage: o.mileage,
        gearbox: (o.gearbox || '').toLowerCase(),
        price: o.price,
        thumbnailUrl: o.thumbnailUrl && /\.jpe?g\s*$/.test(o.thumbnailUrl) ? o.thumbnailUrl : '/v6/images/car-placeholder.png',
        communityId: o.communityId,
        communityName: o.communityName,
        communityContactName: o.communityContactName,
        communityContactId: o.communityContactId,
        licensePlateNr: o.licensePlateNr,
        vin: o.vin,
        assignedOn: parseDate(o.assignedOn),
        currencyCode: o.currencyCodeId,
        seller: o.seller,
    }));
}
