import { useEffect, useState } from 'react';

export enum BannerSessionStorageKeys {
    BlockedFromBidding = 'blockedFromBidding',
    Promo = 'promo',
    HideUploadDocsBanner = 'hideUploadDocsBanner',
}

type UseBannerProps = {
    dependencyData: unknown;
    nameInSessionStorage: BannerSessionStorageKeys;
};

const useBanner = ({ dependencyData, nameInSessionStorage }: UseBannerProps) => {
    const [showBanner, setShowBanner] = useState<'true' | 'false'>('false');

    useEffect(() => {
        const sessionStorageValue = sessionStorage.getItem(`OPENLANE:bannerVisibility:${nameInSessionStorage}`);

        if (!sessionStorageValue) {
            sessionStorage.setItem(`OPENLANE:bannerVisibility:${nameInSessionStorage}`, 'true');
            setShowBanner('true');
        } else if (sessionStorageValue === 'true') {
            setShowBanner('true');
        }
    }, [dependencyData]);

    const handleCloseBanner = () => {
        setShowBanner('false');
        sessionStorage.setItem(`OPENLANE:bannerVisibility:${nameInSessionStorage}`, 'false');
    };

    return {
        showBanner: showBanner === 'true',
        closeBanner: handleCloseBanner,
    };
};

export default useBanner;
