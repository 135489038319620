import { fetchJson, parsedQueryString } from '../utils';

// cfr https://karauctionservices.visualstudio.com/International/_workitems/edit/540212
type UtmData = {
    contactId: number;
    trackedOn: string;
    url: string;

    campaignId: string;
    source: string;
    medium: string;
    name: string;
    term: string;
    content: string;
};

type UtmUrl = {
    url: string;
    query: string;
    trackedOn: string;
};

const utmStorageKey = 'ADESA:UTM:urls';
const maxItemsToSave = 100;

export function trackUtmCodes(contactId: number) {
    const queryNames = Object.keys(parsedQueryString(location.search));
    const urlContainsUtmCodes = queryNames.includes('utm_source') && queryNames.includes('utm_medium'); // check for 2 mandatory fields.
    if (contactId > 0) {
        // logged in => can be sent
        const savedList: UtmUrl[] = JSON.parse(localStorage.getItem(utmStorageKey) || '[]'); // fetch previously saved data.
        if (urlContainsUtmCodes) {
            savedList.push({ url: location.toString(), query: location.search, trackedOn: new Date().toISOString() });
        }
        if (savedList.length > 0) {
            sendUtmCodes(savedList.map(c => toUtmData(contactId, c))).then(() => {
                localStorage.setItem(utmStorageKey, '[]');
            });
        }
    } else {
        // not logged in => store and send when logged in.
        if (urlContainsUtmCodes) {
            const savedList: UtmUrl[] = JSON.parse(localStorage.getItem(utmStorageKey) || '[]');
            savedList.push({ url: location.toString(), query: location.search, trackedOn: new Date().toISOString() });
            if (savedList.length > maxItemsToSave) savedList.shift(); // remove oldest one
            localStorage.setItem(utmStorageKey, JSON.stringify(savedList));
        }
    }
}

function toUtmData(contactId: number, data: UtmUrl): UtmData {
    const query = parsedQueryString(data.query);
    return {
        contactId: contactId,
        url: data.url,
        trackedOn: data.trackedOn,

        campaignId: query['utm_id'] ?? '',
        source: query['utm_source'] ?? '',
        medium: query['utm_medium'] ?? '',
        name: query['utm_campaign'] ?? '',
        term: query['utm_term'] ?? '',
        content: query['utm_content'] ?? '',
    };
}

// log UTM codes events (in table: UtmCampaign, DB: MyCotwAnalytics via analyticsservice)
function sendUtmCodes(data: UtmData[]): Promise<void> {
    const analyticsUrl = '/en/homev6/LogUtmCodes';
    return fetchJson(analyticsUrl, {
        method: 'POST',
        body: JSON.stringify(data),
    }).catch(e => console.error('Cannot LogUtmCodes: ', e));
}
