import React, { useState } from 'react';
import { cx } from 'class-variance-authority';
import { AdesaButton, AdesaModal } from '../Common/ui-components';
import styles from './LocationDialog.module.scss';
import { Map } from '@ambiorix/adesa-web-kit';

export const LocationDialogAndLink = ({ children, t, linkText, address }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const onClickLink = evt => {
        evt.preventDefault();
        toggle();
    };

    return (
        <>
            <AdesaModal is-open={isOpen} onChanged={toggle} className={cx(styles.locationDialog, 'location-dialog')} closeOnOutsideClick>
                <div slot="header">
                    <b> {t('common.pickupLocation')} </b>
                </div>
                <div slot="body">
                    <p>{address}</p>
                    <Map location={address.replace(/[()]/g, ' ')} className={cx(styles.mapStyle, 'mapStyle')} />
                    {children}
                </div>
            </AdesaModal>
            <a className="uitest-address-link" href="#" onClick={onClickLink}>
                {linkText}
            </a>
        </>
    );
};
