import React, { ComponentProps, forwardRef, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { cx } from 'class-variance-authority';
import { TextInput } from '@ambiorix/adesa-web-kit';

import styles from './Input.module.scss';

type InputProps = ComponentProps<typeof TextInput> & {
    inputname?: string;
    readOnly?: boolean;
    showPasswordHandler?: () => void;
    showPassword?: boolean;
    showSuccess?: boolean;
};

const Input = forwardRef<HTMLInputElement, InputProps>(
    ({ className, disabled, inputname, prefix, readOnly, showPassword, showPasswordHandler, showSuccess, type = 'text', ...props }, ref) => {
        const suffix = useMemo(() => {
            if (['password', 'confirmPassword'].includes(`${inputname}`)) {
                return (
                    <FontAwesomeIcon
                        className={cx(styles.icon, showPassword && styles.iconActive)}
                        icon={showPassword ? faEye : faEyeSlash}
                        fixedWidth
                    />
                );
            }
            if (showSuccess) {
                return <FontAwesomeIcon className={styles.iconCheck} icon={faCheck} />;
            }
            return null;
        }, [inputname, showPassword, showSuccess]);

        return (
            <TextInput
                ref={ref}
                type={type}
                className={cx(styles.input, className)}
                disabled={disabled || readOnly}
                prefix={prefix && <span>{prefix}</span>}
                suffix={suffix}
                onSuffixClick={showPasswordHandler}
                {...props}
            />
        );
    },
);

export default Input;
