exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LQ3scFiUAy33Sl59Nb9Sx a{color:var(--neutral-900) !important}.LQ3scFiUAy33Sl59Nb9Sx a:hover,.LQ3scFiUAy33Sl59Nb9Sx a:focus{color:var(--primary-500) !important}", ""]);

// exports
exports.locals = {
	"footer": "LQ3scFiUAy33Sl59Nb9Sx"
};