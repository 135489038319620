import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';
import Fetch from 'i18next-fetch-backend';
import base64 from 'base-64';
import { queryString } from './utils';
import { WindowScroller } from 'react-virtualized';

const phraseAppProjectId = 'f82506b9c0d6116509e49c14a0f32b49';
const username = 'giobos';
//const password = "hJTWI9Nf2oSwjJEJcI4t";
const password = '';
const translationFromPhraseApp = false;

const langToLocaleId = {
    en: 'd16db34bc8ec20200ddd1bd8c20f9023',
    bg: '9da59730f73cc9ae7c0bb389f44b4ed1',
    cs: '97068534068d70b2ead063701dd598fe',
    de: '7e3e37055b3433bdeef1f48d8df0df6f',
    el: '1e645750c432d696d83e342320dfee58',
    es: 'b3c13f0f28ee241cf325655453f0f5ce',
    'fr-be': '2cea526bda23b76d76f9533a993957f0',
    'fr-fr': 'b67149ba2d368fe0954ed72f2126f157',
    hr: '9f351a22ca9b9d4e55958620ed0ba3c9',
    hu: '8896c0b564b544b8672ebb0db6856f96',
    it: '01cea70e49261464b911e4c4a2970522',
    lt: '455b4b3ef75f5ae0d68636454e5c4cb5',
    'nl-be': '3acadbec8b06e4a44b66797b7f47f6a4',
    'nl-nl': '7121676698b5e32bdce8212a924229ec',
    pl: 'ff5f817ee96476b0a48f883fdc16e33a',
    pt: '32bff7d90cd12985b18ccc1dd215dd0e',
    ro: '4d8617f72a707e18f8219e90678438a9',
    ru: 'dcaedfcb7cfb409a3f5afaca44b7a2ed',
    sk: '65f95c6052045623ee737abca05e347a',
    sl: '0b91e43e697319efb75bb405a47e4350',
    tr: '4f0efea7553f40304cd3fc9cb91e9046',
};

const languagePrefixesToLoad = [
    'IsCocAvailable',
    'IsRegDocAvailable',
    'ExtraDeliveryInfo.Spain',
    'PickUP.italia',
    'DeliveryDelay.7',
    'DocDeliveryDelay.Spain',
    'LPG.Netherlands',
    'CNG.',
    'IsDocDeliveryWithCar',

    'SpecDmg',
    'carbroken.',
    'damagekind.',
    'cardamage.',
    'TechDmg.',
    'SpecDmg.',

    'common.',
    'footer.',
    'modal.',
    'popup.',
    'navigation.',
    'similarCars.',
    'toast.',
    'vehicleAlreadySold.',
    'vehicleAuctionClosed.',
    'vehicleDetails.',
    'auctionprocess.CountryCostBPMHint',

    'login.',
    'forgotPassword.',
    'passwordSent.',
    'homepage.',
    'carSearch',
    'carSearchV2.',
    'searchcars.',
    'promotions.BuyNextGet150',
    'promotions.promo1',
    'promotions.promo2',
    'recommendation.',

    'makeModel.',
    'fuelType.',
    'transmission.',
    'mileage.',
    'registrationYear.',
    'engineSize.',
    'power.',
    'bodyType.',
    'price.',
    'equipment.',
    'carColor.',
    'places',
    'originCountry.',
    'country.',
    'seller.',
    'sellertype.',
    'damage.',
    'emissionStandard.',
    'auctionType.',
    'xtime.',
    'highAssignment.',
    'vatRegime.',
    'results.',
    'cardetail.',
    'co2.',
    'headline.',
    'combo.',
    'savedSearches.',
    'advancedSearch.',
    'auctions.',
    'signup.',
    'browsecars.title',
    'mybids.',
    'meta.',
    'bidModal.',
    'xtimedashboard.',
    'premiumOffer.',
    'tour.', // guided tour for premium offer
    'orderCompletion.',
    'meta.', // SEO meta data (description, keywords in html head) for search pages.
    'myAccount.',
    'reports.',
    'dealer.',
    'facet.',
    'localDealerCar.',
    'communities.',
    'communityInvite.',
    'ukintegration.',
    'rptPickUp.',
    'registerForm.',
    'vehicleGrades.',
    'mechanicalIndicators.',
    'registerForm.',
    'vehicleGrades.',
    'mechanicalIndicators.',
    'okta.',
    'mySelection.',
    'carrousel.',
    'locationSearch.',
    'blockedBanner.',
    'sellingOffice.',
    'dp_option.',
    'dp_optionGroup.',
    'banner.',
];

function translationsDownloadPath(langs, namespaces) {
    //console.log(">>>>>>>>>>> Translation path", langs, namespaces);
    if (translationFromPhraseApp) {
        return `https://api.phraseapp.com/api/v2/projects/${phraseAppProjectId}/locales/${
            langToLocaleId[langs[0].toLowerCase()]
        }/download?file_format=i18next`;
    } else {
        const request = {
            language: langs[0].toLowerCase(),
            prefixes: languagePrefixesToLoad.join(','),
        };
        const url = '/en/homev6/labels?' + queryString(request);
        return url;
    }
}

const inTheBrowser = typeof window.Headers === 'function';
if (inTheBrowser) {
    // not running in a browser!  skip next for unit tests with jest.

    const headers = new Headers();
    if (translationFromPhraseApp) headers.append('Authorization', 'Basic ' + base64.encode(username + ':' + password));
    headers.append('X-Requested-With', 'XMLHttpRequest');

    i18n
        //.use(XHR)
        .use(LanguageDetector)
        .use(Fetch)
        .use(reactI18nextModule) // if not using I18nextProvider
        .init({
            fallbackLng: 'en',
            debug: false,
            defaultNS: 'translation',
            fallbackNS: 'translation',

            // https://github.com/i18next/i18next-browser-languageDetector
            detection: {
                order: ['path', 'querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'subdomain'],
                lookupQuerystring: 'lng',
                lookupFromPathIndex: 0,
            },

            interpolation: {
                escapeValue: false, // not needed for react!!
            },

            // https://www.npmjs.com/package/i18next-fetch-backend
            backend: {
                loadPath: translationsDownloadPath,
                init: {
                    mode: 'cors',
                    credentials: 'include',
                    headers,
                },
            },
            initImmediate: false,

            // react i18next special options (optional)
            // https://react.i18next.com/components/i18next-instance
            react: {
                wait: true, //     https://github.com/i18next/i18next-xhr-backend/issues/266
                bindI18n: 'languageChanged loaded',
                bindStore: 'added removed',
                nsMode: 'default',
            },
        });
}

export default inTheBrowser ? i18n : { t() {} }; // return mock if not running in the browser (jest unit tests)
