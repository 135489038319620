import settings from '../appsettings';
import { fetchJson } from '../utils';
import i18n from '../i18n';
import { addMinutes, addSeconds, parseDate, differenceInMilliseconds, differenceInSeconds, formatDate, isAfter } from '../utils/date';
import { getCurrentDate } from '../utils/date';

function url(urlSuffix, currentLanguage) {
    return settings.carsiteBaseUrl + '/' + currentLanguage + '/' + urlSuffix;
}

/**
 * Principle:
 *
 * each time a request is done to the server using fetchJson those variables are saved in window global variable.
 *
 *     idleTimerLastActivity (with current time  Date.now() )
 *
 *
 */

const initialState = {
    origTitle: '',
    dialogTitle: '',
    isDialogOpen: false,
};

const idleTimeLimit = 8 * 3600; // 'No activity' time limit in seconds. 8h

/***************************************************************************************************************
 *  REDUX REDUCER
 */
export function sessionReducer(state = initialState, action) {
    switch (action.type) {
        case 'SESSION:INIT':
            return {
                ...state,
                origTitle: action.origTitle,
                dialogTitle: action.dialogTitle,
            };
        case 'SESSION:DIALOGOPEN':
            return {
                ...state,
                isDialogOpen: true,
            };
        case 'SESSION:KEEPALIVE':
            return {
                ...state,
                isDialogOpen: false,
            };

        default:
            return { ...state };
    }
}
/***************************************************************************************************************
 *  REACT-REDUX state to properties map.
 */
export const sessionMapStateToProps = globalState => ({
    isUserLoggedIn: globalState.common.isUserLoggedIn || false,
    currentLanguage: globalState.common.currentLanguage || 'en',
    currentUser: globalState.common.currentUser,
    isSessionDialogOpen: globalState.session.isDialogOpen,
});

export const sessionExpirationActionsCreator = {
    onInit: () => (dispatch, getState) => initializeSessionExpiration(dispatch, getState),
    onKeepAlive: () => (dispatch, getState) => keepAlive(dispatch, getState),
    onLogout: () => (dispatch, getState) => logoutUser(dispatch, getState),
};

function initializeSessionExpiration(dispatch, getState) {
    const state = getState();
    const isUserLoggedIn = state.common.isUserLoggedIn;
    const dialogTitle = i18n.t('modal.timeout.header1');
    dispatch({ type: 'SESSION:INIT', dialogTitle: dialogTitle, origTitle: document.title });

    if (isUserLoggedIn) {
        keepAlive(dispatch, getState);
    }
}

function keepAlive(dispatch, getState) {
    keepSessionAlive(dispatch, getState).then(checkTimeout(dispatch, getState));
}

function checkTimeout(dispatch, getState) {
    const lastActivity = parseDate(window.idleTimerLastActivityTime);
    const now = getCurrentDate();
    const limit = addMinutes(addSeconds(lastActivity, idleTimeLimit), -4); // display dialog 4 min before limit time (30 min)
    debug(`now: ${formatDate(now, 'time')} limit: ${formatDate(limit, 'time')}    [${formatDate(lastActivity, 'time')}]`);
    if (isAfter(now, limit)) {
        debug(`open dialog`);
        dispatch({ type: 'SESSION:DIALOGOPEN' });
    } else {
        debug(`setTimeout for ${differenceInSeconds(limit, now)} s`);
        setTimeout(() => checkTimeout(dispatch, getState), differenceInMilliseconds(limit, now));
    }
}

function keepSessionAlive(dispatch, getState) {
    debug('keepSessionAlive');
    const state = getState();
    dispatch({ type: 'SESSION:KEEPALIVE' });
    return fetchJson(url('login/keepalive', state.common.currentLanguage));
}

function logoutUser(dispatch, getState) {
    const state = getState();
    // Send a logout request to the API
    fetchJson(url('login/logout', state.common.currentLanguage)).then(() => {
        window.location.assign(url('home', state.common.currentLanguage));
    });
}

function debug(t) {
    //console.log('----- checkTimeout ----', t);
}
