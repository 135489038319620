import { formatDate, isBefore, parseDate } from '../utils/date';
import { notify, removeNotifications } from 'reapop';
import settings from '../appsettings';
import { fetchJson, scrollToTop, toFormData, parsedQueryString, logAnalytics, extrapolate, formatMoney } from '../utils';
import { belgiumDateTimeToLocal, getCurrentDate } from '../utils/date';
import { replace, push } from 'connected-react-router';
import {
    batchTypeFromBatchAttributeId,
    tooHighBidAmount,
    isLeasePlanClosed,
    isInProvisionalState,
    EXTENDED_PHASE_STATUS,
    AUCTION_STATUS,
    PROVISIONAL_STATES,
} from './cotw.js';
import i18n from '../i18n';

function url(urlSuffix, currentLanguage) {
    return settings.carsiteBaseUrl + '/' + currentLanguage + '/' + urlSuffix;
}

let signalrClient = null;

// ===== REDUX ACTIONS ====================================
const INIT = 'MYBIDS:INIT';
const GOT_ACTIVE_AUCTIONS = 'MYBIDS:GOT_ACTIVE_AUCTIONS';
const GOT_PENDING_AUCTIONS = 'MYBIDS:GOT_PENDING_AUCTIONS';
const GOT_PENDING_AUCTIONS_COUNT = 'MYBIDS:GOT_PENDING_AUCTIONS_COUNT';
const GOT_ACTIVE_PENDING_AUCTIONS = 'MYBIDS:GOT_ACTIVE_PENDING_AUCTIONS';
const GOT_CLOSED_AUCTIONS = 'MYBIDS:GOT_CLOSED_AUCTIONS';
const GOT_CLOSED_AUCTIONS_COUNT = 'MYBIDS:GOT_CLOSED_AUCTIONS_COUNT';
const LOADING = 'MYBIDS:LOADING';
const FILTER_ACTIVE_AUCTIONS = 'MYBIDS:FILTER_ACTIVE_AUCTIONS';
const FILTER_CLOSED_AUCTIONS = 'MYBIDS:FILTER_CLOSED_AUCTIONS';
const FILTER_PENDING_AUCTIONS = 'MYBIDS:FILTER_PENDING_AUCTIONS';
const CHANGE_ACTIVE_TAB = 'MYBIDS:CHANGE_ACTIVE_TAB';
const SUBMITTING_BID = 'MYBIDS:SUBMITTING_BID';
const SUBMIT_BID = 'MYBIDS:SUBMIT_BID';
const SUBMITTING_BUY_NOW_BID = 'MYBIDS:SUBMITTING_BUY_NOW_BID';
const SUBMIT_BUYNOW_BID = 'MYBIDS:SUBMIT_BUYNOW_BID';
const NEW_PRICE_INFO = 'MYBIDS:NEW_PRICE_INFO';
const NEW_BID_AMOUNT = 'MYBIDS:NEW_BID_AMOUNT';
const SIGNALR_BID = 'MYBIDS:SIGNALR_BID';
const AUCTION_XTIME_EXTEND = 'MYBIDS:AUCTION_XTIME_EXTEND';
const AUCTION_XTIME_MESSAGE = 'MYBIDS:XTIME_MESSAGE';
const AUCTION_XTIME_CLOSE = 'MYBIDS:AUCTION_XTIME_CLOSE';
const AUCTION_XTIME_QUEUE = 'MYBIDS:AUCTION_XTIME_QUEUE';
const AUCTION_HIDE = 'MYBIDS:AUCTION_HIDE';
const SORT_ACTIVE = 'MYBIDS:SORT_ACTIVE';
const SORT_CLOSED = 'MYBIDS:SORT_CLOSED';
const AUCTION_CLOSE = 'MYBIDS:AUCTION_CLOSE';
const CHANGE_PAGE_SIZE = 'MYBIDS:CHANGE_PAGE_SIZE';
const CHANGE_PAGE_INDEX = 'MYBIDS:CHANGE_PAGE_INDEX';
const CHANGE_VIEW_MODE = 'MYBIDS:CHANGE_VIEW_MODE';
const SORT_PENDING = 'MYBIDS:SORT_PENDING';
const UPDATE_PROVISIONAL_STATE = 'MYBIDS:UPDATE_PROVISIONAL_STATE';
const UPDATE_ACTIVE_PENDING_AUCTIONS = 'MYBIDS:UPDATE_ACTIVE_PENDING_AUCTIONS';
const UPDATE_PROVISIONAL_ITEM_STATE = 'MYBIDS:UPDATE_PROVISIONAL_ITEM_STATE';

const desktopViewModeStorageKey = 'ADESA:MyBids:desktopViewMode';

let desktopViewMode = 0;
const val = localStorage.getItem(desktopViewModeStorageKey);
if (val && val === '1') {
    desktopViewMode = 1;
}

const ACTIVE_FILTERS = Object.freeze(['ALL', 'HIGHEST', 'BLIND', 'OUTBID', 'FAVORITES']);
const CLOSE_FILTERS = Object.freeze(['ALL', 'WON', 'LOST', 'NOBIDS']);
const PENDING_FILTERS = Object.freeze(['ALL', 'AWAITING_SELLER', 'COUNTER_OFFER']);

const ACTIVE_AUCTIONS_TAB = 0;
const PENDING_AUCTIONS_TAB = 1;
const CLOSED_AUCTIONS_TAB = 2;

const initialState = {
    loading: true,
    initialized: false,
    activeTabIndex: 0, // 0: Active, 1:Pending, 2: Closed

    activeFilter: 0, // 0: all, 1: highest, 2: Blind Auctions 3: outbid, 4: favorites
    closedFilter: 0, // 0: all, 1: won, 2: lost, 3: no bid
    pendingFilter: 0, //0: all, 1: Awaiting seller, 2: counter offer

    activeAuctions: [],
    activeAuctionsSortFieldName: 'sortingEndDate|asc',

    closedAuctions: [],
    closedAuctionsSortFieldName: 'BatchEndDate|asc',
    closedAuctionsTotal: 0, // total
    closedAuctionsCounts: [0, 0, 0, 0], // per filter (first = total)

    pendingAuctions: [],
    pendingAuctionsCount: 0,
    pendingAuctionsSortFieldName: 'sortingEndDate|asc',

    closedAuctionsPaging: {
        pageSize: 20,
        pageIndex: 0, // 0 = first page.
    },

    desktopViewMode, // 0: tabular, 1: card.
};

// for buyNow when currentPrice reaches the buyNowPrice => sold.
const isCarSold = (auction, currentPrice) => {
    let result = auction.isSold;
    const price = currentPrice || auction.currentPrice;
    if (!auction.isSold && auction.isBuyNow && auction.buyNowPrice > 0) {
        result = price >= auction.buyNowPrice;
    }
    return result;
};

/***************************************************************************************************************
 *  REDUX REDUCER
 */
export function myBidsReducer(state = initialState, action) {
    switch (action.type) {
        case INIT: {
            return {
                ...state,
                activeAuctions: action.list,
                loading: false,
                initialized: true,
            };
        }

        case GOT_ACTIVE_AUCTIONS:
            return {
                ...state,
                activeAuctions: action.list,
                activeFilter: action.currentUrlHash === '#following' ? 3 : state.activeFilter,
            };
        case GOT_PENDING_AUCTIONS:
            return {
                ...state,
                pendingAuctions: action.list,
                pendingAuctionsCount: action.list.length,
            };
        case GOT_PENDING_AUCTIONS_COUNT:
            return {
                ...state,
                pendingAuctionsCount: action.count,
            };

        case GOT_ACTIVE_PENDING_AUCTIONS:
            return {
                ...state,
                loading: false,
                initialized: true,
            };

        case UPDATE_ACTIVE_PENDING_AUCTIONS: {
            const pendingAuctions = state.pendingAuctions.map(a => (action.auctions[a.auctionId] ? action.auctions[a.auctionId] : a));
            const activeAuctions = state.activeAuctions.map(a => (action.auctions[a.auctionId] ? action.auctions[a.auctionId] : a));
            return {
                ...state,
                loading: false,
                pendingAuctions,
                activeAuctions,
            };
        }

        case UPDATE_PROVISIONAL_STATE: {
            const pendingAuctions = state.pendingAuctions.map(a =>
                action.auctionData[a.auctionId.toString()] ? { ...a, provisionalState: action.auctionData[a.auctionId.toString()] } : a,
            );
            const activeAuctions = state.activeAuctions.map(a =>
                action.auctionData[a.auctionId.toString()] ? { ...a, provisionalState: action.auctionData[a.auctionId.toString()] } : a,
            );
            return {
                ...state,
                loading: false,
                pendingAuctions,
                activeAuctions,
            };
        }

        case UPDATE_PROVISIONAL_ITEM_STATE: {
            const pendingAuctions = state.pendingAuctions.map(a =>
                action.auctionData[a.auctionId.toString()]
                    ? {
                          ...a,
                          auctionStatus: action.auctionData[a.auctionId.toString()].auctionStatus,
                          provisionalState: action.auctionData[a.auctionId.toString()].provisionalState,
                      }
                    : a,
            );
            return {
                ...state,
                loading: false,
                pendingAuctions,
            };
        }

        case GOT_CLOSED_AUCTIONS: {
            // only keep "all" filter results.
            const closedAuctionsTotal = state.closedFilter === 0 ? action.total : state.closedAuctionsTotal;
            const closedAuctionsCounts = state.closedFilter === 0 && action.counts ? action.counts : state.closedAuctionsCounts;
            return {
                ...state,
                closedAuctions: action.auctions.map(a => ({
                    ...a,
                    isBuyNowWon: a.isBuyNow && a.currentPrice >= a.buyNowPrice && a.hasUserPlacedABid && a.isUserHighestBidder, //recalculate for closed auctions
                })),
                closedAuctionsTotal,
                closedAuctionsCounts,
                loading: false,
            };
        }

        case GOT_CLOSED_AUCTIONS_COUNT: {
            const closedAuctionsTotal = action.total;
            const closedAuctionsCounts = action.counts;
            return {
                ...state,
                closedAuctionsTotal,
                closedAuctionsCounts,
            };
        }

        case LOADING:
            return {
                ...state,
                loading: true,
            };

        case FILTER_ACTIVE_AUCTIONS:
            return { ...state, activeFilter: action.activeFilter };

        case FILTER_CLOSED_AUCTIONS:
            return {
                ...state,
                closedFilter: action.closedFilter,
                closedAuctionsPaging: { ...state.closedAuctionsPaging, pageIndex: 0 },
            };
        case FILTER_PENDING_AUCTIONS:
            return { ...state, pendingFilter: action.pendingFilter };

        case CHANGE_ACTIVE_TAB:
            return {
                ...state,
                activeTabIndex: action.selectedTabIndex,
            };

        case NEW_BID_AMOUNT: {
            const auction = state.activeAuctions.filter(a => a.auctionId === action.auctionId)[0];
            // message (translation key) to be displayed in case of invalid value.
            let inputPriceInvalidMessage = '';

            // input price must be an increment of the initial price (if initialPrice = 16010, valid=16110, 16210, ...)
            let validInputPrice = (action.inputPrice - auction.inputMinimumPrice) % auction.increment === 0;

            if (validInputPrice) {
                if (!auction.isBuyNow) {
                    validInputPrice = !tooHighBidAmount(action.inputPrice, auction.maximumBid, auction.minimumBidForRspCheck);

                    if (!validInputPrice) {
                        inputPriceInvalidMessage = 'vehicleDetails.biddingBox.errorBidTooHigh';
                    }

                    // don't allow to bid below the current user max price in blind auctions
                    if (inputPriceInvalidMessage === '' && auction.isBlind) {
                        validInputPrice = action.inputPrice === null || action.inputPrice > auction.currentUserMaxPrice;
                        if (!validInputPrice) {
                            inputPriceInvalidMessage = 'vehicleDetails.biddingBox.errorBidTooLow';
                        }
                    }
                } else {
                    var inputPriceGtBuyNowprice = action.inputPrice > auction.buyNowPrice;
                    validInputPrice = !inputPriceGtBuyNowprice;
                    if (!validInputPrice) {
                        inputPriceInvalidMessage = 'vehicleDetails.biddingBox.errorBidTooHigh';
                    }
                }
            } else {
                inputPriceInvalidMessage = 'vehicleDetails.biddingBox.errorUseIncrement';
            }
            return {
                ...state,
                activeAuctions: state.activeAuctions.map(a =>
                    a.auctionId !== action.auctionId
                        ? { ...a }
                        : {
                              ...a,
                              inputPrice: action.inputPrice,
                              validInputPrice,
                              inputPriceInvalidMessage,
                          },
                ),
            };
        }

        case SUBMITTING_BID:
            return { ...state };

        case SUBMITTING_BUY_NOW_BID:
            return { ...state };

        case SUBMIT_BID: {
            const isBuyNowWon = a => isCarSold(a) && a.hasUserPlacedABid && a.isUserHighestBidder;
            return {
                ...state,
                activeAuctions: state.activeAuctions.map(a =>
                    a.auctionId !== action.auctionId
                        ? { ...a }
                        : {
                              ...a,
                              currentUserMaxPrice: action.submittedAmount,
                              isClosed: a.isUltimo && a.isInUltimoPhase ? true : a.isClosed, // only 1 bid allowed in ultimo phase.
                              isInUltimoPhase: a.isUltimo && a.isInUltimoPhase ? false : a.isInUltimoPhase,
                              following: false,
                              isSold: isCarSold(a),
                              isBuyNowWon: isBuyNowWon(a),
                              isBuyNowWonAfterBid: isBuyNowWon(a), // !! Activate buynow finalization dialog.
                              auctionStatus: isCarSold(a) && a.hasUserPlacedABid && a.isUserHighestBidder ? AUCTION_STATUS.BUY_NOW : a.auctionStatus,
                          },
                ),
            };
        }

        case SUBMIT_BUYNOW_BID: {
            const isSuccessful = action.bidResponse === '';
            const isBuyNowWon = a => isSuccessful && isCarSold(a) && a.hasUserPlacedABid && a.isUserHighestBidder;
            return {
                ...state,
                activeAuctions: state.activeAuctions.map(a =>
                    a.auctionId !== action.auctionId
                        ? { ...a }
                        : {
                              ...a,
                              isClosed: a.isUltimo && a.isInUltimoPhase ? true : a.isClosed, // only 1 bid allowed in ultimo phase.
                              following: false,
                              isSold: isSuccessful && isCarSold(a),
                              isBuyNowWon: isBuyNowWon(a),
                              isBuyNowWonAfterBid: isBuyNowWon(a), // !! Activate buynow finalization dialog.
                              auctionStatus:
                                  isSuccessful && isCarSold(a) && a.hasUserPlacedABid && a.isUserHighestBidder
                                      ? AUCTION_STATUS.BUY_NOW
                                      : a.auctionStatus,
                          },
                ),
            };
        }

        case NEW_PRICE_INFO: {
            // action: auctionId, newPriceInfo, fromSignalr
            const auction = state.activeAuctions.filter(a => a.auctionId === action.auctionId)[0];

            // Only update inputPrice if new amount is more than entered one (cfr https://gemini.carsontheweb.com/workspace/407/item/24856)
            // skip when price entered manually.
            // avoid updating inputPrice if data from signalr https://dev.azure.com/CarsOnTheWeb/CarsOnTheWeb/_workitems/edit/1304
            const inputPrice =
                !action.fromSignalr && auction.inputPrice && action.newPriceInfo.inputPrice > auction.inputPrice
                    ? action.newPriceInfo.inputPrice
                    : auction.inputPrice;

            return {
                ...state,
                activeAuctions: state.activeAuctions.map(a =>
                    a.auctionId !== action.auctionId
                        ? { ...a }
                        : {
                              ...a,
                              ...action.newPriceInfo,
                              isSold: isCarSold(a, action.newPriceInfo.currentPrice),
                              inputPrice,
                          },
                ),
            };
        }

        case SIGNALR_BID:
            return {
                ...state,
                activeAuctions: state.activeAuctions.map(a => (a.auctionId === action.auctionId ? { ...a, ...action.auctionDataUpdate } : { ...a })),
            };

        case AUCTION_XTIME_QUEUE:
            return {
                ...state,
                activeAuctions: state.activeAuctions.map(a =>
                    a.auctionId === action.auctionId
                        ? {
                              ...a,
                              isInExtendedPhase: true,
                              extendedPhaseStatus: a.isAlphabetLikeXTime ? EXTENDED_PHASE_STATUS.PREPARING : null,
                              endDate: parseDate(action.endDate),
                          }
                        : { ...a },
                ),
            };

        case AUCTION_XTIME_EXTEND: {
            // called by signalr
            return {
                ...state,
                activeAuctions: state.activeAuctions.map(a =>
                    a.auctionId === action.auctionId
                        ? {
                              ...a,
                              isClosed: false,
                              isInExtendedPhase: a.isAlphabetLikeXTime,
                              extendedPhaseStatus: a.isAlphabetLikeXTime ? EXTENDED_PHASE_STATUS.INXTIME : null,
                              endDate: action.endDate,
                              isInUltimoPhase: a.isUltimo && a.isUserHighestBidder,
                          }
                        : {
                              ...a,
                          },
                ),
            };
        }

        case AUCTION_XTIME_MESSAGE: {
            // called by signalr (Alphabet xtime extend)
            const extend = auctionId =>
                action.auctions[auctionId]
                    ? {
                          extendedPhaseOrder: action.auctions[auctionId].order,
                          extendedPhaseStatus: action.auctions[auctionId].status,
                      }
                    : {};
            return {
                ...state,
                activeAuctions: state.activeAuctions.map(a =>
                    a.auctionId === action.auctionId
                        ? {
                              ...a,
                              ...extend(a.auctionId),
                              isClosed: false,
                              isInExtendedPhase: true,
                              extendedPhaseStatus: a.isAlphabetLikeXTime ? EXTENDED_PHASE_STATUS.INXTIME : null,
                              endDate: action.endDate,
                              extendedPhaseBidOptions: action.bidOptions,
                          }
                        : {
                              ...a,
                              ...extend(a.auctionId),
                          },
                ),
            };
        }

        case AUCTION_XTIME_CLOSE: {
            // called by signalr
            const extend = auctionId =>
                action.auctions[auctionId]
                    ? {
                          extendedPhaseOrder: action.auctions[auctionId].order,
                          extendedPhaseStatus: action.auctions[auctionId].status,
                      }
                    : {};
            return {
                ...state,
                activeAuctions: state.activeAuctions.map(a =>
                    a.auctionId === action.auctionId
                        ? {
                              ...a,
                              isClosed: true,
                              isExtendedPhaseFinished: true,
                              isInUltimoPhase: false,
                              extendedPhaseStatus: EXTENDED_PHASE_STATUS.CLOSED,
                          }
                        : { ...a, ...extend(a.auctionId) },
                ),
            };
        }

        case AUCTION_CLOSE: {
            return {
                ...state,
                activeAuctions: state.activeAuctions.map(a =>
                    a.auctionId === action.auctionId
                        ? {
                              ...a,
                              isClosed: a.isAlphabetLikeXTime
                                  ? a.extendedPhaseStatusId === EXTENDED_PHASE_STATUS.CLOSED
                                  : a.isLeasePlanLikeXTime
                                  ? isLeasePlanClosed(a.endDate)
                                  : true,
                              isInUltimoPhase: a.isInUltimoPhase ? false : a.isUltimo && a.isUserHighestBidder,
                              auctionStatus: AUCTION_STATUS.WAITING_FOR_ANSWER,
                          }
                        : { ...a },
                ),
            };
        }

        case AUCTION_HIDE:
            return {
                ...state,
                activeAuctions: state.activeAuctions.filter(a => a.auctionId !== action.auctionId),
                closedAuctions: state.closedAuctions.filter(a => a.auctionId !== action.auctionId),
            };

        case SORT_ACTIVE:
            return {
                ...state,
                activeAuctionsSortFieldName: action.sortFieldName,
            };

        case SORT_PENDING: {
            return {
                ...state,
                pendingAuctionsSortFieldName: action.sortFieldName,
            };
        }
        case SORT_CLOSED:
            return {
                ...state,
                closedAuctionsSortFieldName: action.sortFieldName,
            };

        case CHANGE_PAGE_SIZE:
            return {
                ...state,
                closedAuctionsPaging: {
                    ...state.closedAuctionsPaging,
                    pageIndex: 0,
                    pageSize: action.pageSize,
                },
            };

        case CHANGE_PAGE_INDEX:
            return {
                ...state,
                closedAuctionsPaging: {
                    ...state.closedAuctionsPaging,
                    pageIndex: action.pageIndex,
                },
            };

        case CHANGE_VIEW_MODE:
            return { ...state, desktopViewMode: action.newMode };

        default:
            return { ...state };
    }
}

/***************************************************************************************************************
 *  REACT-REDUX state to properties map.
 */
export const myBidsMapStateToProps = globalState => ({
    serverDate: globalState.common.serverDate,
    isOrderCompletionAllowed: globalState.common.currentUser.isOrderCompletionAllowed, // IdDoc should be uploaded to be able to confirm.
    ...globalState.myBids,

    activeAuctions: filteredActiveAuctions(globalState.myBids.activeAuctions, globalState.myBids.activeFilter).sort(
        sortActiveBy(globalState.myBids.activeAuctionsSortFieldName),
    ),
    pendingAuctions: filteredPendingAuctions(globalState.myBids.pendingAuctions, globalState.myBids.pendingFilter).sort(
        sortActiveBy(globalState.myBids.pendingAuctionsSortFieldName),
    ),
    activeAuctionsCounts: activeAuctionsCount(globalState.myBids.activeAuctions),
    pendingAuctionsCounts:
        globalState.myBids.pendingAuctions.length > 0
            ? pendingAuctionsCount(globalState.myBids.pendingAuctions)
            : [globalState.myBids.pendingAuctionsCount, 0],
});

const winning = a => a.hasUserPlacedABid && a.isUserHighestBidder && a.auctionStatus !== AUCTION_STATUS.SELLER_NOT_AGREE && !a.isBlind;
const losing = a => a.hasUserPlacedABid && (!a.isUserHighestBidder || a.auctionStatus === AUCTION_STATUS.SELLER_NOT_AGREE) && !a.isBlind;

const blindAuctions = a => a.hasUserPlacedABid && a.isBlind && a.auctionStatus !== AUCTION_STATUS.SELLER_NOT_AGREE;

const waitingForSellerStates = ['Proposal', 'CounterOfferBuyer', 'StandOnBuyer'];
const pendingWaitingForSeller = a => (a.provisionalState && waitingForSellerStates.includes(a.provisionalState.state)) || a.auctionStatus == 4;

const waitingForBuyerStates = ['CounterOfferSeller', 'StandOnSeller'];
const pendingWaitingForBuyer = a => a.provisionalState && waitingForBuyerStates.includes(a.provisionalState.state);

function filteredActiveAuctions(list, filter) {
    if (filter === 0) {
        return list;
    }
    return list.filter(
        a => (filter === 1 && winning(a)) || (filter === 2 && blindAuctions(a)) || (filter === 3 && losing(a)) || (filter === 4 && a.following),
    );
}
function filteredPendingAuctions(list, filter) {
    if (filter === 0) {
        return list;
    }
    return list.filter(a => (filter === 1 && pendingWaitingForSeller(a)) || (filter === 2 && pendingWaitingForBuyer(a)));
}
function activeAuctionsCount(list) {
    return [
        list.length,
        list.filter(a => winning(a)).length,
        list.filter(a => blindAuctions(a)).length,
        list.filter(a => losing(a)).length,
        list.filter(a => a.following).length,
    ];
}
function pendingAuctionsCount(list) {
    return [list.length, list.filter(a => pendingWaitingForSeller(a)).length, list.filter(a => pendingWaitingForBuyer(a)).length]; // ALL count, "waiting seller" count, "offer received" count.
}

/***************************************************************************************************************
 *  REACT-REDUX action creators (using redux-thunk: Redux Thunk middleware allows you to write action creators that return a function instead of an action)
 */

const logMyBidsAnalytics = (action, state, message) => logAnalytics('MyBids', action, state.common.currentLanguage, message);

function notifyError(dispatch, message) {
    dispatch(notify({ message: message, status: 'error' }));
}

const byAuctionId = auctions =>
    auctions.reduce((hash, auction) => {
        hash[auction.AuctionId] = { order: auction.OrderId, status: auction.ExtendedPhaseStatusId };
        return hash;
    }, {});

export const myBidsActionsCreator = {
    onInit: signalr => (dispatch, getState) => {
        signalrClient = signalr;
        // initializeState(dispatch, getState);
        // logMyBidsAnalytics("IN_MYBIDS", getState());
    },

    onShow: () => (dispatch, getState) => {
        initializeState(dispatch, getState);
        logMyBidsAnalytics('IN_MYBIDS', getState());
    },

    onRefreshState: () => (dispatch, getState) => {
        fetchActiveAuctions(dispatch, getState);
        fetchPendingAuctions(dispatch, getState);
    },

    onRefreshPendingAuctions: (updatedAuctionId, newState) => (dispatch, getState) =>
        refreshActivePendingAuctions(updatedAuctionId, newState, dispatch, getState),

    onAddAuctionWatchForClient: auctionData => (dispatch, getState) => {
        // called by Signalr
        const state = getState();
        const auctionId = auctionData.AuctionId;
        const auction = state.myBids.activeAuctions.filter(a => a.auctionId === auctionId)[0];
        if (!auction && auctionData.ContactId === state.common.currentUser.contactId && state.myBids.initialized) {
            // probably a watch added in another window => refresh data in mybids.
            initializeState(dispatch, getState);
        }
    },
    onRemoveBidWatchForClient: auctionData => (dispatch, getState) => {
        // called by Signalr
        const state = getState();
        const auctionId = auctionData.AuctionId;
        const auction = state.myBids.activeAuctions.filter(a => a.auctionId === auctionId)[0];
        if (auction && auctionData.ContactId === state.common.currentUser.contactId && state.myBids.initialized) {
            // probably a watch removed in another window => refresh data in mybids
            initializeState(dispatch, getState);
        }
    },

    onAddAuctionBidForClient: auctionData => (dispatch, getState) => otherBidReceived(auctionData, getState, dispatch),

    onXTimePendingMessage: auctionData => (dispatch, getState) =>
        dispatch({
            type: AUCTION_XTIME_QUEUE,
            auctionId: auctionData.AuctionId,
            endDate: belgiumDateTimeToLocal(auctionData.EndDateExtendedPhase),
        }),
    onAuctionExtended: auctionData => (dispatch, getState) =>
        dispatch({
            type: AUCTION_XTIME_EXTEND,
            auctionId: auctionData.AuctionId,
            endDate: belgiumDateTimeToLocal(auctionData.BatchEndDate),
        }),
    onXTimeAuctionFinished: auctionData => (dispatch, getState) =>
        dispatch({
            type: AUCTION_XTIME_CLOSE,
            auctionId: auctionData.AuctionId,
            auctions: byAuctionId(auctionData.Auctions || []),
        }),
    onXTimeMessage: auctionData => dispatch =>
        dispatch({
            type: AUCTION_XTIME_MESSAGE,
            auctionId: auctionData.AuctionId,
            endDate: belgiumDateTimeToLocal(auctionData.EndDateExtendedPhase),
            auctions: byAuctionId(auctionData.Auctions || []),
            bidOptions: auctionData.BidOptions,
        }),

    onFilterActiveAuction: activeFilter => (dispatch, getState) => {
        dispatch({ type: FILTER_ACTIVE_AUCTIONS, activeFilter });
        logMyBidsAnalytics('CHANGED_FILTER_FOR_ACTIVE', getState(), `active filter: [${ACTIVE_FILTERS[activeFilter]}]`);
    },
    onFilterClosedAuction: closedFilter => (dispatch, getState) => {
        dispatch({ type: FILTER_CLOSED_AUCTIONS, closedFilter });
        fetchClosedAuctions(dispatch, getState);
        logMyBidsAnalytics('CHANGED_FILTER_FOR_CLOSED', getState(), `active filter: [${CLOSE_FILTERS[closedFilter]}]`);
    },
    //-------pending tab action
    onFilterPendingAuction: pendingFilter => (dispatch, getState) => {
        dispatch({ type: FILTER_PENDING_AUCTIONS, pendingFilter });
    },

    onChangeActiveTab: selectedTabIndex => (dispatch, getState) => {
        dispatch({ type: CHANGE_ACTIVE_TAB, selectedTabIndex });
        switch (selectedTabIndex) {
            case PENDING_AUCTIONS_TAB:
                fetchPendingAuctions(dispatch, getState);
                break;
            case CLOSED_AUCTIONS_TAB:
                // reload closed auction on tab selection...
                fetchClosedAuctions(dispatch, getState);
                break;
        }
    },
    onCloseAuction: auctionId => (dispatch, getState) => {
        dispatch({ type: AUCTION_CLOSE, auctionId });
    },

    onNewInputPrice: (auctionId, amount) => dispatch => {
        dispatch({ type: NEW_BID_AMOUNT, inputPrice: amount, auctionId });
    },

    onShowAuction: auctionId => (dispatch, getState) => {
        dispatch(push(`/${getState().common.currentLanguage}/car/info?auctionId=${auctionId}`));
        scrollToTop();
        logMyBidsAnalytics('SHOW_AUCTION', getState(), `${auctionId}`);
    },
    onBidSubmit: (auctionId, amount) => (dispatch, getState) => {
        submitBid(auctionId, amount, getState(), dispatch);
        logMyBidsAnalytics('BID', getState(), `${auctionId}`);
    },
    onBuyNow: auctionId => (dispatch, getState) => {
        submitBuyNowBid(auctionId, getState(), dispatch);
        logMyBidsAnalytics('BUY_NOW', getState(), `${auctionId}`);
    },

    onHideAuction: auctionId => (dispatch, getState) => {
        hideAuction(auctionId, getState(), dispatch);
        logMyBidsAnalytics('REMOVE_AUCTION', getState(), `${auctionId}`);
    },
    onSortActive: sortFieldName => (dispatch, getState) => {
        dispatch({ type: SORT_ACTIVE, sortFieldName });
        logMyBidsAnalytics('SORT_ACTIVE', getState(), `sortFieldName: [${sortFieldName}]`);
    },
    onSortClosed: sortFieldName => (dispatch, getState) => {
        dispatch({ type: SORT_CLOSED, sortFieldName });
        fetchClosedAuctions(dispatch, getState);
        scrollToTop();
        logMyBidsAnalytics('SORT_CLOSED', getState(), `sortFieldName: [${sortFieldName}]`);
    },
    onSortPending: sortFieldName => (dispatch, getState) => {
        dispatch({ type: SORT_PENDING, sortFieldName });
        // fetchPendingAuctions(dispatch, getState);
        scrollToTop();
        //logMyBidsAnalytics('SORT_PENDING', getState(), `sortFieldName: [${sortFieldName}]`);
    },
    onClosedAuctionsPageLoad: pageIndex => (dispatch, getState) => {
        dispatch({ type: CHANGE_PAGE_INDEX, pageIndex });
        fetchClosedAuctions(dispatch, getState);
        scrollToTop();
        logMyBidsAnalytics('CLOSED_AUCTIONS_CHANGE_PAGE', getState(), `pageIndex: [${pageIndex}]`);
    },
    onClosedAuctionsPageSize: pageSize => (dispatch, getState) => {
        dispatch({ type: CHANGE_PAGE_SIZE, pageSize });
        fetchClosedAuctions(dispatch, getState);
        scrollToTop();
        logMyBidsAnalytics('CLOSED_AUCTIONS_SET_PAGE_SIZE', getState(), `pageSize: [${pageSize}]`);
    },
    onChangeViewMode: newMode => (dispatch, getState) => {
        dispatch({ type: CHANGE_VIEW_MODE, newMode });
        localStorage.setItem(desktopViewModeStorageKey, newMode);
        logMyBidsAnalytics('CHANGE_VIEW', getState(), `newMode: [${newMode === 1 ? 'GRID' : 'TABLE'}]`);
    },
    onProvisionalAction: (actionName, auctionId, offer) => (dispatch, getState) =>
        provisionalAction(actionName, auctionId, offer, dispatch, getState),
};

function initializeState(dispatch, getState) {
    const state = getState();
    let selectedTabIndex = ACTIVE_AUCTIONS_TAB;

    // select tab and filters based on URL parameters (tab & filter)
    if (window.location.search) {
        const query = parsedQueryString(window.location.search);
        if (query.tab) {
            // "pending" | "closed"
            switch (query.tab.toLowerCase()) {
                case 'pending':
                    selectedTabIndex = PENDING_AUCTIONS_TAB;
                    break;
                case 'closed':
                    selectedTabIndex = CLOSED_AUCTIONS_TAB;
                    break;
            }
            dispatch({ type: CHANGE_ACTIVE_TAB, selectedTabIndex });
        }
        if (query.filter) {
            if (/^(0|1|2|3)$/.test(query.filter)) {
                const filter = parseInt(query.filter, 10);
                switch (selectedTabIndex) {
                    case ACTIVE_AUCTIONS_TAB:
                        dispatch({ type: FILTER_ACTIVE_AUCTIONS, activeFilter: filter });
                        break;
                    case PENDING_AUCTIONS_TAB:
                        dispatch({ type: FILTER_PENDING_AUCTIONS, pendingFilter: filter });
                        break;
                    case CLOSED_AUCTIONS_TAB:
                        dispatch({ type: FILTER_CLOSED_AUCTIONS, closedFilter: filter });
                        break;
                }
            }
        }
    }
    // Fetch auctions data to be displayed.
    fetchAuctions(dispatch, getState).then(() => {
        switch (selectedTabIndex) {
            case PENDING_AUCTIONS_TAB:
                fetchPendingAuctions(dispatch, getState);
                break;
            case CLOSED_AUCTIONS_TAB:
                fetchCountForClosedAuctions(dispatch, getState).then(() => fetchClosedAuctions(dispatch, getState));
                break;
        }
    });
}

// fetch "active" & "pending" auctions.
function fetchAuctions(dispatch, getState) {
    const state = getState();
    dispatch({ type: LOADING });
    return Promise.all([fetchActiveAuctions(dispatch, getState), fetchCountForPendingAuctions(dispatch, getState)]).then(
        ([hasActiveAuctions, hasPendingAuctions]) => {
            if (!hasActiveAuctions) {
                if (hasPendingAuctions) {
                    // no active auctions to show ==> fetch pending ones.
                    fetchPendingAuctions(dispatch, getState);
                    fetchCountForClosedAuctions(dispatch, getState);
                } else {
                    fetchClosedAuctions(dispatch, getState);
                }
            } else {
                fetchCountForClosedAuctions(dispatch, getState);
            }
            dispatch({ type: GOT_ACTIVE_PENDING_AUCTIONS });
        },
    );
}

function fetchActiveAuctions(dispatch, getState) {
    const state = getState();

    // unregister previous ones
    state.myBids.activeAuctions.forEach(a => signalrClient.unregisterForAuctionId(a.auctionId));
    //const activeAuctionsUrl = `/api/cotw/GetCurrentBids?id=${state.common.currentUser.contactId}&lang=${state.common.currentLanguage}`;
    const activeAuctionsUrl = url('mybidsv6/activeauctions', state.common.currentLanguage);
    return fetchJson(activeAuctionsUrl).then(resp => {
        const auctions = resp.auctions || []; // resp.auctions = null when no auction found!
        dispatch({
            type: GOT_ACTIVE_AUCTIONS,
            list: auctions.map(a => auctionToState(a, state.common.currentLanguage)),
            currentUrlHash: location.hash,
        });
        //dispatch({type: 'COMMON:SET_BID_CONFIRMATION', confirmationEnabled: resp.modalConfirmationEnabled});

        // register signalr for new auctions.
        const auctionIds = getState().myBids.activeAuctions.map(a => a.auctionId);
        signalrClient.registerForAuctions(auctionIds).catch(e => console.error('SIGNALR Error while registering auctions from search result.', e));

        return Promise.resolve(auctions.length > 0);
    });
}

function fetchPendingAuctions(dispatch, getState) {
    const state = getState();

    // unregister previous ones
    state.myBids.pendingAuctions.forEach(a => signalrClient.unregisterForAuctionId(a.auctionId));
    const pendingAuctionsUrl = url('mybidsv6/pendingauctions', state.common.currentLanguage);
    return fetchJson(pendingAuctionsUrl).then(resp => {
        const auctions = resp || [];
        dispatch({
            type: GOT_PENDING_AUCTIONS,
            list: auctions.map(a => auctionToState(a, state.common.currentLanguage)),
            currentUrlHash: location.hash,
        });

        // register signalr for new auctions.
        const auctionIds = getState().myBids.pendingAuctions.map(a => a.auctionId);
        signalrClient.registerForAuctions(auctionIds).catch(e => console.error('SIGNALR Error while registering auctions from search result.', e));

        return Promise.resolve(auctions.length > 0);
    });
}

function fetchCountForPendingAuctions(dispatch, getState) {
    const state = getState();

    const pendingAuctionsCountUrl = url('mybidsv6/pendingauctionsCount', state.common.currentLanguage);
    return fetchJson(pendingAuctionsCountUrl).then(resp => {
        const count = resp || 0;
        dispatch({
            type: GOT_PENDING_AUCTIONS_COUNT,
            count,
        });
        return count > 0;
    });
}

// fetch total count for closed to be displayed on tab.
function fetchCountForClosedAuctions(dispatch, getState) {
    const state = getState();

    const closedAuctionsUrl = url('mybidsv6/closedauctions', state.common.currentLanguage);
    return fetchJson(closedAuctionsUrl, {
        method: 'POST',
        body: JSON.stringify({ Paging: { PageNumber: 0, PageSize: 0 } }),
    }).then(resp => {
        if (resp.totals === null) resp.totals = []; // null returned when no auctions found.
        const c = resp.totals.reduce((res, v) => {
            res[v.filterId] = v.count;
            return res;
        }, {});
        const total = resp.totals.reduce((tot, v) => tot + v.count, 0);
        const counts = [total, c[0], c[1], c[2]]; // counts per filter.
        dispatch({ type: GOT_CLOSED_AUCTIONS_COUNT, total, counts, auctions: [] });
    });
}

function fetchClosedAuctions(dispatch, getState) {
    const state = getState();

    dispatch({ type: LOADING });
    const filters = [];
    if (state.myBids.closedFilter === 0) filters.push(0, 1, 2);
    if (state.myBids.closedFilter === 1) filters.push(0);
    if (state.myBids.closedFilter === 2) filters.push(1);
    if (state.myBids.closedFilter === 3) filters.push(2);

    const [sortFieldName, sortDirection] = state.myBids.closedAuctionsSortFieldName.split('|');

    const request = {
        Filter: filters,
        Paging: {
            PageNumber: state.myBids.closedAuctionsPaging.pageIndex + 1,
            PageSize: state.myBids.closedAuctionsPaging.pageSize,
        },
        Sort: {
            Field: sortFieldName,
            Direction: sortDirection,
        },
    };
    const closedAuctionsUrl = url('mybidsv6/closedauctions', state.common.currentLanguage);
    return fetchJson(closedAuctionsUrl, {
        method: 'POST',
        body: JSON.stringify(request),
    }).then(resp => {
        if (resp.auctions === null) resp.auctions = []; // null returned when no auctions found.
        if (resp.totals === null) resp.totals = []; // null returned when no auctions found.

        const c = resp.totals.reduce((res, v) => {
            res[v.filterId] = v.count;
            return res;
        }, {});
        const total = resp.totals.reduce((tot, v) => tot + v.count, 0);
        const validCount = state.myBids.closedFilter === 0;
        const counts = validCount ? [total, c[0] || 0, c[1] || 0, c[2] || 0] : null; // counts per filter.

        dispatch({
            type: GOT_CLOSED_AUCTIONS,
            total,
            counts,
            auctions: resp.auctions.map(a => ({
                ...auctionToState(a, state.common.currentLanguage),
                isClosed: true,
            })),
        });
    });
}

function submitBid(auctionId, amount, state, dispatch) {
    const auction = state.myBids.activeAuctions.filter(a => a.auctionId === auctionId)[0];
    const isInUltimoPhase = auction.isInUltimoPhase;
    const showSuccessToast = !state.common.confirmationEnabled;
    const submittedAmount = amount || auction.inputPrice;
    const bidRequest = {
        auctionId: auction.auctionId,
        price: amount || auction.inputPrice,
        minPrice: auction.inputMinimumPrice || 0, //state.carDetail.priceInfo.inputMinimumPrice,
        directBidType: true,
    };
    dispatch({ type: SUBMITTING_BID });
    fetchJson(url(isInUltimoPhase ? 'carv6/placelastbid' : 'carv6/placebid', state.common.currentLanguage), {
        method: 'POST',
        body: toFormData(bidRequest),
    })
        .then(resp => {
            if (typeof resp === 'string') {
                // placelastbid returns a string instead of an object => convert to object.
                resp = resp === '' ? { status: 'success' } : { status: 'error', message: resp };
            }

            switch (resp.status) {
                case 'success': {
                    updatePriceInfo(auction.auctionId, state.common.currentLanguage, dispatch).then(() => {
                        // update priceInfo first as we need the new currentPrice in reducer...
                        dispatch({
                            type: SUBMIT_BID,
                            bidResponse: resp,
                            submittedAmount,
                            auctionId: auction.auctionId,
                        });
                    });
                    if (showSuccessToast) {
                        dispatch(removeNotifications());
                        dispatch(
                            notify({
                                message: i18n.t('toast.flashing.yourBidWasPlacedSuccessfully'),
                                status: 'success',
                            }),
                        );
                    }
                    dispatch({ type: 'CARSEARCH:USER_PLACED_A_BID', auctionId: auction.auctionId }); // to update search list with icons
                    break;
                }
                case 'warning':
                    dispatch({
                        type: SUBMIT_BID,
                        bidResponse: resp,
                        submittedAmount,
                        auctionId: auction.auctionId,
                    });
                    dispatch(removeNotifications());
                    dispatch(notify({ message: resp.message, status: 'warning' }));
                    break;
                case 'error':
                    dispatch({
                        type: SUBMIT_BID,
                        bidResponse: resp,
                        submittedAmount,
                        auctionId: auction.auctionId,
                    });
                    dispatch(removeNotifications());
                    dispatch(
                        notify({
                            message: `${i18n.t('toast.flashing.yourBidCouldNotBePlaced')} ${resp.message}`,
                            status: 'error',
                        }),
                    );
                    break;
                default:
                    dispatch({
                        type: SUBMIT_BID,
                        bidResponse: resp,
                        submittedAmount,
                        auctionId: auction.auctionId,
                    });
                    dispatch(removeNotifications());
                    dispatch(
                        notify({
                            message: `${i18n.t('toast.flashing.yourBidCouldNotBePlaced')} ${resp.message}`,
                            status: 'error',
                        }),
                    );
                    break;
            }
        })
        .catch(e => {
            console.error('!!!!!! placebid ERROR', e);
            dispatch(removeNotifications());
            dispatch(notify({ message: i18n.t('toast.flashing.yourBidCouldNotBePlaced'), status: 'error' }));
        });
}

function submitBuyNowBid(auctionId, state, dispatch) {
    const showSuccessToast = !state.common.confirmationEnabled;
    const auction = state.myBids.activeAuctions.filter(a => a.auctionId === auctionId)[0];
    const bidRequest = {
        auctionId: auction.auctionId,
        amount: auction.buyNowPrice,
        contactId: state.common.currentUser.contactId,
    };
    dispatch({ type: SUBMITTING_BUY_NOW_BID });
    fetchJson(url('BidService/DoBuyNowBid', state.common.currentLanguage), {
        method: 'POST',
        body: toFormData(bidRequest),
    })
        .then(resp => {
            if (resp) {
                dispatch(
                    notify({
                        message: `${i18n.t('toast.flashing.yourBidCouldNotBePlaced')} ${resp}`,
                        status: 'error',
                    }),
                );
            } else {
                updatePriceInfo(auction.auctionId, state.common.currentLanguage, dispatch).then(() => {
                    // update priceInfo first as we need the new currentPrice in reducer...
                    if (showSuccessToast) {
                        dispatch(removeNotifications());
                        dispatch(
                            notify({
                                message: i18n.t('toast.flashing.yourBidWasPlacedSuccessfully'),
                                status: 'success',
                            }),
                        );
                    }
                    dispatch({ type: 'CARSEARCH:USER_PLACED_A_BID', auctionId: auction.auctionId }); // to update search list with icons
                    dispatch({ type: SUBMIT_BUYNOW_BID, auctionId: auction.auctionId, bidResponse: resp });
                });
            }
        })
        .catch(e => {
            console.error('!!!!!! placeBuyNowBid ERROR', e);
            dispatch(removeNotifications());
            dispatch(notify({ message: i18n.t('toast.flashing.yourBidCouldNotBePlaced'), status: 'error' }));
        });
}

function updatePriceInfo(auctionId, currentLanguage, dispatch, fromSignalr) {
    return fetchJson(url(`carv6/bidpriceinfo?auctionId=${auctionId}&bidAmount=0`, currentLanguage)).then(resp => {
        const newPriceInfo = priceInfoToState(resp);
        dispatch({ type: NEW_PRICE_INFO, newPriceInfo, auctionId, fromSignalr });
        return Promise.resolve();
    });
}

/**
 * Called by signalr event.
 *
 */
function otherBidReceived(auctionData, getState, dispatch) {
    const state = getState();
    const auctionId = auctionData.AuctionId;
    const auction = state.myBids.activeAuctions.filter(a => a.auctionId === auctionId)[0];
    if (auction) {
        const auctionDataUpdate = signalrAuctionDataToState(auctionData, state);
        dispatch({ type: SIGNALR_BID, auctionDataUpdate, auctionId });
    } else {
        if (auctionData.ContactId === state.common.currentUser.contactId && state.myBids.initialized) {
            // probably bid put on a new auction from another window.
            // cfr https://dev.azure.com/CarsOnTheWeb/CarsOnTheWeb/_workitems/edit/5172
            initializeState(dispatch, getState);
        }
    }
}

function hideAuction(auctionId, state, dispatch) {
    const activeAuctionsUrl = url('mybidsv6/hideauction', state.common.currentLanguage);
    fetchJson(activeAuctionsUrl, {
        method: 'PUT',
        body: JSON.stringify({ auctionId, Visible: false }),
    }).then(resp => {
        dispatch({ type: AUCTION_HIDE, auctionId });
    });
}

function provisionalAction(actionName, auctionId, offer, dispatch, getState) {
    const state = getState();
    const provisionalActionUrl = url('mybidsv6/DoProvisionalAction', state.common.currentLanguage);
    const auction = state.myBids.pendingAuctions.find(a => a.auctionId == auctionId);
    const currency = auction ? auction.currencyCode : 'EUR';
    return fetchJson(provisionalActionUrl, {
        method: 'POST',
        body: JSON.stringify({ AuctionId: auctionId, ActionName: actionName, Offer: offer }),
        headers: { 'Content-Type': 'application/json' },
    })
        .then(resp => {
            dispatch({
                type: UPDATE_PROVISIONAL_ITEM_STATE,
                auctionData: { [auctionId.toString()]: auctionToState(resp, state.common.currentLanguage) },
            });
            dispatch(
                notify({
                    message: extrapolate(i18n.t('toast.flashing.provisionalSuccess.' + actionName), formatMoney(offer, 0, currency)),
                    status: 'success',
                }),
            );
        })
        .catch(e => {
            console.error('!!!!!! DoProvisionalAction ERROR', e);
            dispatch(removeNotifications());
            dispatch(notify({ message: i18n.t('toast.flashing.provisionalError'), status: 'error' }));
        });
}

function refreshActivePendingAuctions(updatedAuctionId, newState, dispatch, getState) {
    const state = getState();
    if (newState.startsWith('CounterOffer') || newState.startsWith('StandOnOffer')) {
        // refresh only provisional state.
        const provisionalUrl = url('mybidsv6/ProvisionalAuctions', state.common.currentLanguage);

        fetchJson(provisionalUrl)
            .then(resp => {
                // resp = {<auctionId1>: provisionalState1, <auctionId2>: provisionalState2}
                dispatch({ type: UPDATE_PROVISIONAL_STATE, auctionData: resp });
            })
            .catch(e => {
                console.error('!!!!!! ProvisionalAuctions ERROR', e);
                dispatch(removeNotifications());
                dispatch(notify({ message: i18n.t('toast.flashing.provisionalError'), status: 'error' }));
            });
    } else {
        // full refresh of the auction to get last status: won/lost.
        const auctionsUrl = url('mybidsv6/Auctions', state.common.currentLanguage);
        return fetchJson(auctionsUrl, {
            method: 'POST',
            body: JSON.stringify([updatedAuctionId]),
            headers: { 'Content-Type': 'application/json' },
        })
            .then(resp => {
                // resp: {auctions: [{<auction data>}, {<auction data>}]}
                const auctions = resp.auctions.reduce((hash, a) => ({ ...hash, [a.auctionId]: auctionToState(a) }), {});
                dispatch({ type: UPDATE_ACTIVE_PENDING_AUCTIONS, auctions });
            })
            .catch(e => {
                console.error('!!!!!! Auctions ERROR', e);
                dispatch(removeNotifications());
                dispatch(notify({ message: i18n.t('toast.flashing.provisionalError'), status: 'error' }));
            });
    }
}

/***************************************************************************************************************
 *  DATA - REDUX STATE MAPPINGS.
 */

function auctionToState(auction, currentLanguage) {
    const isXTime = auction.extendedPhaseType !== null && auction.extendedPhaseType > -1;
    const isAlphabetLikeXTime = auction.extendedPhaseType === 2;
    const endDate =
        isXTime && auction.endDateExtendedPhase && !auction.endDateExtendedPhase.startsWith('0001-01-01T')
            ? parseDate(belgiumDateTimeToLocal(auction.endDateExtendedPhase.replace(/\+\d\d:\d\d$/, '')))
            : parseDate(belgiumDateTimeToLocal(auction.batchEndDate.replace(/\+\d\d:\d\d$/, '')));
    const isClosed = isAlphabetLikeXTime ? auction.extendedPhaseStatusId === EXTENDED_PHASE_STATUS.CLOSED : isBefore(endDate, getCurrentDate());

    const isLeasePlanLikeXTime = auction.extendedPhaseType === 0;
    const isProvisional =
        auction.provisionalState &&
        (auction.statusId === AUCTION_STATUS.PROVISIONAL ||
            auction.statusId === AUCTION_STATUS.PROVISIONAL_PENDING_BUYER ||
            auction.statusId === AUCTION_STATUS.PROVISIONAL_PENDING_SELLER);
    const isElectricCar = auction.carIdentification.fuelGroup === 'Electric';
    const co2IsUndefined = !auction.co2 || auction.co2 === 0;
    return {
        auctionId: auction.auctionId,
        title:
            auction.carTitleMobileList && auction.carTitleMobileList[currentLanguage]
                ? auction.carTitleMobileList[currentLanguage]
                : auction.carTitleMobileList && auction.carTitleMobileList['en'],
        //thumbnailUrl: auction.thumbnailUrl || "/v6/images/car-placeholder.png",
        thumbnailUrl: auction.thumbnailUrl ? auction.thumbnailUrl.replace(/\\/g, '/') : '/v6/images/car-placeholder.png',
        //thumbnailUrl: 'https://images.adesa.eu/carimgs/ZFA3120000J495709/general/8.jpg?t=637068239795654080',

        dateFirstRegistration: auction.dateFirstRegistration ? formatDate(auction.dateFirstRegistration, 'short') : '-',
        effluentStandard: auction.effluentStandardGroupSearch || '-',
        co2: isElectricCar ? (co2IsUndefined ? 0 : auction.co2) : co2IsUndefined ? '-' : auction.co2,
        bodyType: auction.size,
        kw: auction.kw,
        hp: auction.hp,

        isMargin: auction.isMargin,
        sellingOfficeCountryId: auction.carCountryExtended ? auction.carCountryExtended.toLowerCase() : auction.carCountryExtended,

        hasUserPlacedABid: auction.hasUserPlacedABid,
        isUserHighestBidder: auction.isUserHighestBidder,
        isHighestBidder: auction.isCompanyHighestBidder || auction.isUserHighestBidder,
        currentPrice: auction.currentPrice || 0,
        buyNowPrice: auction.buyNowPrice,
        requestedSalesPrice: auction.requestedSalesPrice,
        inputMinimumPrice: auction.startPrice,
        increment: auction.increment || 100,
        currentUserMaxPrice: auction.personalMaxPrice,

        inputPrice: 0,
        validInputPrice: true,

        isClosed,
        isSold: auction.hasBuyNowBidPlaced, // auction.buyNowPrice > 0 && auction.isUserHighestBidder,
        isBuyNowWon: false, // activation of the buy now finalization modal (after placing a winning bid)
        isUltimo: auction.batchSupportsLastBid,
        isInUltimoPhase: auction.inLastBidPhase,
        isInExtendedPhase: auction.isInExtendedPhase,
        isExtendedPhaseFinished: false,

        /* missing: barometer prices: list + indexes , isInUltimo, xtime, isClosed, isSold */

        hasTechnicalDamage: auction.hasTechnicalDamage,
        hasBodyDamage: auction.isBroken,

        endDate,

        following: !auction.hasUserPlacedABid && auction.isCarWatch, // outbid & highest should remove the 'following'.

        ...batchTypeFromBatchAttributeId(auction.isBuyNow, auction.batchAttributeId),

        barometerPrices: auction.barometerPrices || null,
        barometerRecommendedPrice: auction.barometerRecommendedPrice || null,

        isXTime,
        isAlphabetLikeXTime,
        isLeasePlanLikeXTime,

        extendedPhaseStatus: auction.extendedPhaseStatusId,
        /*
            Preparing = 1,
            InQueue = 2,
            InXtime = 3,
            Closed = 4,
        */
        extendedPhaseOrder: auction.orderId,

        extendedPhaseBidOptions: [
            auction.currentPrice + auction.increment,
            auction.currentPrice + 2 * auction.increment,
            auction.currentPrice + 3 * auction.increment,
        ],

        extendedPhaseType: auction.extendedPhaseType,

        auctionStatus: auction.statusId || AUCTION_STATUS.APPROVED,

        isSellerAgree: !!auction.isSellerAgree,
        isUserConfirmed: !!auction.isUserConfirmed,

        newAuctionId: auction.newAuctionId, // closed auction has been re-auctionned and this is the Id of the new auction.
        dealerDiscount: auction.dealerDiscount,
        isCommunityAuction: !!auction.communityId,
        communityId: auction.communityId,
        communityName: auction.communityName,
        currencyCode: auction.currencyCodeId, //for my bids account page
        provisionalState: adaptedProvisionalState(auction.provisionalState, auction.statusId),
        isUkVehicle: auction.batchEcadisCountryId && auction.batchEcadisCountryId === 9,
        isProvisional: isProvisional,
        sortingEndDate: isInProvisionalState(auction.statusId)
            ? auction.provisionalState && parseDate(belgiumDateTimeToLocal(auction.provisionalState.provisionalEndDate))
            : endDate,
        is247StockSale: auction.batchAttributeId && auction.batchAttributeId === 6, // stock247Check
        auctionFeeDiscount: auction.applyAuctionFeeDiscount ? auction.auctionFeeDiscount : null,
        isNonTransactionalAuction: auction.isNonTransactional,
        isStock: auction.isStock,
        maximumBid: auction.maximumBid,
        minimumBidForRspCheck: auction.minimumBidForRspCheck,
        isAggregated: auction.isAggregated,
    };
}

function adaptedProvisionalState(provisionalState, auctionStatus) {
    if (!provisionalState) return provisionalState;
    let result = {
        ...provisionalState,
        provisionalEndDate: belgiumDateTimeToLocal(provisionalState.provisionalEndDate),
        history: provisionalState.history.map(h => ({ ...h, date: belgiumDateTimeToLocal(h.date) })),
    };
    if (
        provisionalState.state === PROVISIONAL_STATES.ACCEPTED_BUYER ||
        provisionalState.state === PROVISIONAL_STATES.ACCEPTED_SELLER ||
        (auctionStatus === AUCTION_STATUS.SELLER_NOT_AGREE &&
            provisionalState.state !== PROVISIONAL_STATES.REJECTED_BUYER &&
            provisionalState.state !== PROVISIONAL_STATES.REJECTED_SELLER)
    ) {
        result = {
            ...result,
            state: PROVISIONAL_STATES.COMPLETED,
        };
    }
    return result;
}

/**
 * Sort function for active auctions.
 *
 * @param {*} s  0: enddate, 1: Status, 2: Make, 3: xtime first, 4: price
 */
const toLowerCase = s => (typeof s === 'string' ? s.toLowerCase() : s);
function sortActiveBy(fieldNameAndDirection) {
    //let direction = "asc";
    const [fieldName, direction] = fieldNameAndDirection.split('|');
    return function (x, y) {
        const [A, B] = direction === 'asc' ? [x, y] : [y, x];
        let a = toLowerCase(A[fieldName]);
        let b = toLowerCase(B[fieldName]);
        if (a instanceof Date && b instanceof Date) {
            a = formatDate(a, 'yyyyMMddHHmmss');
            b = formatDate(b, 'yyyyMMddHHmmss');
        }
        if (a < b) {
            return -1;
        }
        if (a > b) {
            return 1;
        }
        return 0;
    };
}

function priceInfoToState(data) {
    return {
        targetPrice: data.TargetPrice,
        currentPrice: data.CurrentPrice,

        inputPrice: data.NextMaxPrice,
        validInputPrice: true, // to be recalculated based on new inputPrice & inputMinimumPrice & inputIncrement.
        showCountryCostDiscount: data.ShowCountryCostDiscount,
        countryCostDiscount: data.CountryCostDiscount ? data.CountryCostDiscount : 0,
        hasUserPlacedABid: data.HasUserPlacedABid,
        isUserHighestBidder: data.IsUserHighestBidder,
        isHighestBidder: data.IsUserHighestBidder,
        currentUserMaxPrice: data.BidderHigherBidAmount,
    };
}

export function signalrAuctionDataToState(data, state) {
    /* data=
        AuctionBatchId : 0 
        AuctionId : 2915340
        BatchEndDate : "0001-01-01T00:00:00"
        CarId : 1919263
        ContactId : 19866
        CurrentPrice : 15100
        HighestBidderId : 19866
        LastUpdated : "2018-08-31T14:14:59.037+02:00"
        MaxPersonalPrice : 15100                            // !! this is not the price of current user.
        NextMaxPrice : 15200
    */

    const auction = state.myBids.activeAuctions.filter(a => a.auctionId === data.AuctionId)[0];
    const isBlind = auction.isBlind;
    const currentUserContactId = state.common.currentUser.contactId;

    const hasUserPlacedABid = data.ContactId === currentUserContactId || (auction && auction.hasUserPlacedABid);
    const result = {
        isUserHighestBidder: data.HighestBidderId === currentUserContactId,
        isHighestBidder: data.HighestBidderId === currentUserContactId,
        hasUserPlacedABid,
    };

    if (isBlind) {
        if (data.ContactId === currentUserContactId) {
            result.currentPrice = data.MaxPersonalPrice; // !! data.CurrentPrice = 0 for blind
        }
    } else {
        result.currentPrice = data.CurrentPrice;
        if (auction.isBuyNow) {
            result.isSold = data.CurrentPrice >= auction.buyNowPrice;
        }
    }
    return result;
}
