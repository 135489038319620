import { formatDate, parseDate } from '../utils/date';
import { notify, removeNotifications } from 'reapop';
import settings from '../appsettings';
import { fetchJson, scrollToTop, toFormData, parsedQueryString, logAnalytics } from '../utils';
import { belgiumDateTimeToLocal } from '../utils/date';
import { replace, push } from 'connected-react-router';
import { batchTypeFromBatchAttributeId, tooHighBidAmount, EXTENDED_PHASE_STATUS, AUCTION_STATUS } from './cotw.js';
import i18n from '../i18n';

function url(urlSuffix, currentLanguage) {
    return settings.carsiteBaseUrl + '/' + currentLanguage + '/' + urlSuffix;
}

let signalrClient = null;

// ===== REDUX ACTIONS ====================================
const INIT = 'XTIMEDASHBOARD:INIT';
const LOADING = 'XTIMEDASHBOARD:LOADING';
const CHANGE_ACTIVE_TAB = 'XTIMEDASHBOARD:CHANGE_ACTIVE_TAB';
const SUBMITTING_BID = 'XTIMEDASHBOARD:SUBMITTING_BID';
const SUBMIT_BID = 'XTIMEDASHBOARD:SUBMIT_BID';
const SUBMITTING_BUY_NOW_BID = 'XTIMEDASHBOARD:SUBMITTING_BUY_NOW_BID';
const SUBMIT_BUYNOW_BID = 'XTIMEDASHBOARD:SUBMIT_BUYNOW_BID';
const NEW_PRICE_INFO = 'XTIMEDASHBOARD:NEW_PRICE_INFO';
const NEW_BID_AMOUNT = 'XTIMEDASHBOARD:NEW_BID_AMOUNT';
const SIGNALR_BID = 'XTIMEDASHBOARD:SIGNALR_BID';
const AUCTION_XTIME_EXTEND = 'XTIMEDASHBOARD:AUCTION_XTIME_EXTEND';
const AUCTION_XTIME_CLOSE = 'XTIMEDASHBOARD:AUCTION_XTIME_CLOSE';
const AUCTION_XTIME_QUEUE = 'XTIMEDASHBOARD:AUCTION_XTIME_QUEUE';
const AUCTION_CLOSE = 'XTIMEDASHBOARD:AUCTION_CLOSE';
const GOT_AUCTIONS = 'XTIMEDASHBOARD:GOT_AUCTIONS';
const XTIME_MESSAGE = 'XTIMEDASHBOARD:XTIME_MESSAGE';

const initialState = {
    loading: false,
    activeTabIndex: 0, // 0: Active, 1: Upcoming, 2: Closed

    auctions: [], // all auctions.
    isBatchEnded: false,
    topMessage: {
        translationKey: 'noCarsOfInterest', //
        carsNb: 0, //
    },
};

const batchEnded = auctions =>
    auctions.length && auctions.filter(a => !a.isSold).filter(a => a.extendedPhaseStatus !== EXTENDED_PHASE_STATUS.CLOSED).length === 0;

/*
    extendedPhaseStatus:
        Preparing = 1,
        InQueue = 2,
        InXtime = 3,
        Closed = 4,
*/
const sortedByOrder = (a, b) => a.extendedPhaseOrder - b.extendedPhaseOrder;
const activeAuctions = auctions => auctions.filter(a => a.extendedPhaseStatus === EXTENDED_PHASE_STATUS.INXTIME).sort(sortedByOrder);
const upcomingAuctions = auctions =>
    auctions
        .filter(a => a.extendedPhaseStatus === EXTENDED_PHASE_STATUS.PREPARING || a.extendedPhaseStatus === EXTENDED_PHASE_STATUS.INQUEUE)
        .sort(sortedByOrder);
const closedAuctions = auctions => auctions.filter(a => a.extendedPhaseStatus === EXTENDED_PHASE_STATUS.CLOSED).sort(sortedByOrder);

const auctionsWithWatches = (auctions, watches) => auctions.map(a => ({ ...a, following: watches.indexOf(a.auctionId) >= 0 }));

const topMessage = auctions => {
    const ofInterest = auction => auction.hasUserPlacedABid || auction.following;

    let first = activeAuctions(auctions).findIndex(ofInterest);
    if (first > -1) {
        return { translationKey: 'carsOfInterestActive', carsNb: 0 };
    }

    first = upcomingAuctions(auctions).findIndex(ofInterest);
    if (first > -1) {
        return { translationKey: 'carsToGo', carsNb: first + activeAuctions(auctions).length };
    }

    first = closedAuctions(auctions).findIndex(ofInterest);
    if (first > -1) {
        return { translationKey: 'noMoreCarsOfInterest', carsNb: 0 };
    }

    return { translationKey: 'noCarsOfInterest', carsNb: 0 };
};

/***************************************************************************************************************
 *  REDUX REDUCER
 */
export function xTimeDashboardReducer(state = initialState, action) {
    switch (action.type) {
        case INIT: {
            return {
                ...state,
                auctions: [],
                loading: false,
            };
        }

        case GOT_AUCTIONS: {
            // !skip sold ones in first phase of the xtime.
            const isBatchEnded = batchEnded(action.list);
            return {
                ...state,
                auctions: action.list,
                loading: false,
                isBatchEnded,
            };
        }

        case LOADING:
            return {
                ...state,
                loading: true,
            };

        case CHANGE_ACTIVE_TAB:
            return {
                ...state,
                activeTabIndex: action.selectedTabIndex,
            };

        case NEW_BID_AMOUNT: {
            const auction = state.auctions.filter(a => a.auctionId === action.auctionId)[0];
            // message (translation key) to be displayed in case of invalid value.
            let inputPriceInvalidMessage = '';

            // input price must be an increment of the initial price (if initialPrice = 16010, valid=16110, 16210, ...)
            let validInputPrice = (action.inputPrice - auction.inputMinimumPrice) % auction.increment === 0;

            // https://dev.azure.com/CarsOnTheWeb/CarsOnTheWeb/_workitems/edit/608
            if (validInputPrice) {
                if (!auction.isBuyNow) {
                    validInputPrice = !tooHighBidAmount(auction.inputPrice, auction.maximumBid, auction.minimumBidForRspCheck);
                    if (!validInputPrice) {
                        inputPriceInvalidMessage = 'vehicleDetails.biddingBox.errorBidTooHigh';
                    }
                }
            } else {
                inputPriceInvalidMessage = 'vehicleDetails.biddingBox.errorUseIncrement';
            }
            return {
                ...state,
                auctions: state.auctions.map(a =>
                    a.auctionId !== action.auctionId
                        ? { ...a }
                        : {
                              ...a,
                              inputPrice: action.inputPrice,
                              validInputPrice,
                              inputPriceInvalidMessage,
                          },
                ),
            };
        }

        case SUBMITTING_BID:
            return { ...state };

        case SUBMITTING_BUY_NOW_BID:
            return { ...state };

        case SUBMIT_BID: {
            return {
                ...state,
                /*auctionInfo: {
                        ...state.auctionInfo, 
                        submittingBid: false,
                        isClosed: state.auctionInfo.isInUltimoPhase ? true : state.auctionInfo.isClosed,  // only 1 bid allowed while in Ultimo phase.
                        showBuyNowFinalizationDialog: state.auctionInfo.isBuyNow ? state.priceInfo.currentPrice >= state.priceInfo.buyNowPrice ? true : false : false,
                    },*/
            };
        }

        case SUBMIT_BUYNOW_BID: {
            const isSuccessful = action.bidResponse === '';
            return {
                ...state,
                /*auctionInfo: {
                        ...state.auctionInfo, 
                        submittingBuyNowBid: false, 
                        isSold: isSuccessful,
                        showSoldAnyway: isSuccessful,
                        showBuyNowFinalizationDialog: isSuccessful,
                    }*/
            };
        }

        case NEW_PRICE_INFO: {
            // action: auctionId, newPriceInfo, fromSignalr
            const auction = state.auctions.filter(a => a.auctionId === action.auctionId)[0];

            // for buyNow when currentPrice reaches the buyNowPrice => sold.
            let isSold = auction.isSold;
            if (auction.isBuyNow && !isSold && auction.buyNowPrice > 0) {
                isSold = action.newPriceInfo.currentPrice >= auction.buyNowPrice;
            }

            // Only update inputPrice if new amount is more than entered one (cfr https://gemini.carsontheweb.com/workspace/407/item/24856)
            // skip when price entered manually.
            // avoid updating inputPrice if data from signalr https://dev.azure.com/CarsOnTheWeb/CarsOnTheWeb/_workitems/edit/1304
            const inputPrice =
                !action.fromSignalr && auction.inputPrice && action.newPriceInfo.inputPrice > auction.inputPrice
                    ? action.newPriceInfo.inputPrice
                    : auction.inputPrice;

            return {
                ...state,
                auctions: state.auctions.map(a =>
                    a.auctionId !== action.auctionId
                        ? { ...a }
                        : {
                              ...a,
                              ...action.newPriceInfo,
                              isSold,
                              inputPrice,
                          },
                ),
            };
        }

        case SIGNALR_BID:
            return {
                ...state,
                auctions: state.auctions.map(a => (a.auctionId === action.auctionId ? { ...a, ...action.auctionDataUpdate } : { ...a })),
            };

        case AUCTION_XTIME_QUEUE:
            return {
                ...state,
                auctions: state.auctions.map(a =>
                    a.auctionId === action.auctionId
                        ? {
                              ...a,
                              isInExtendedPhase: true,
                              extendedPhaseStatus: EXTENDED_PHASE_STATUS.PREPARING,
                              endDate: parseDate(action.endDate),
                          }
                        : { ...a },
                ),
            };
        case AUCTION_XTIME_EXTEND: // called by signalr
            return {
                ...state,
                auctions: state.auctions.map(a =>
                    a.auctionId === action.auctionId
                        ? {
                              ...a,
                              isInExtendedPhase: true,
                              extendedPhaseStatus: EXTENDED_PHASE_STATUS.INXTIME,
                              endDate: parseDate(action.endDate),
                          }
                        : { ...a },
                ),
            };

        case AUCTION_XTIME_CLOSE: {
            // called by signalr
            const auctions = state.auctions.map(a =>
                a.auctionId === action.auctionId
                    ? {
                          ...a,
                          isClosed: true,
                          isExtendedPhaseFinished: true,
                          extendedPhaseStatus: EXTENDED_PHASE_STATUS.CLOSED,
                      }
                    : { ...a },
            );
            const isBatchEnded = batchEnded(auctions);
            return { ...state, auctions, isBatchEnded };
        }

        case AUCTION_CLOSE:
            return {
                ...state,
                auctions: state.auctions.map(a => (a.auctionId === action.auctionId ? { ...a, isClosed: true } : { ...a })),
            };

        case XTIME_MESSAGE: {
            const extend = auctionId =>
                action.auctions[auctionId]
                    ? {
                          extendedPhaseOrder: action.auctions[auctionId].order,
                          extendedPhaseStatus: action.auctions[auctionId].status,
                      }
                    : {};
            const auctions = state.auctions.map(a =>
                a.auctionId === action.auctionId
                    ? {
                          ...a,
                          ...extend(a.auctionId),
                          isInExtendedPhase: true,
                          isClosed: false,
                          endDate: parseDate(action.endDate),
                          extendedPhaseStatus: a.isAlphabetLikeXTime ? EXTENDED_PHASE_STATUS.INXTIME : null,
                          extendedPhaseBidOptions: action.bidOptions,
                      }
                    : {
                          ...a,
                          ...extend(a.auctionId),
                      },
            );
            return { ...state, auctions };
        }

        default:
            return { ...state };
    }
}

/***************************************************************************************************************
 *  REACT-REDUX state to properties map.
 */

const sortedActive = auctions => [
    // 0: Active, 1: Upcoming, 2: Closed
    activeAuctions(auctions),
    upcomingAuctions(auctions),
    closedAuctions(auctions),
];

const sortedClosed = auctions => [
    // 0: Won, 1: Lost, 2: No bid
    auctions.filter(a => a.hasUserPlacedABid && a.isUserHighestBidder),
    auctions.filter(a => a.hasUserPlacedABid && !a.isUserHighestBidder),
    auctions.filter(a => !a.hasUserPlacedABid),
];

export const xTimeDashboardMapStateToProps = globalState => ({
    serverDate: globalState.common.serverDate,
    ...globalState.xTimeDashboard,

    sortedAuctions: globalState.xTimeDashboard.isBatchEnded
        ? sortedClosed(auctionsWithWatches(globalState.xTimeDashboard.auctions, globalState.common.watches))
        : sortedActive(auctionsWithWatches(globalState.xTimeDashboard.auctions, globalState.common.watches)),

    topMessage: topMessage(auctionsWithWatches(globalState.xTimeDashboard.auctions, globalState.common.watches)),
});

/***************************************************************************************************************
 *  REACT-REDUX action creators (using redux-thunk: Redux Thunk middleware allows you to write action creators that return a function instead of an action)
 */

function notifyError(dispatch, message) {
    dispatch(notify({ message: message, status: 'error' }));
}

const byAuctionId = auctions =>
    auctions.reduce((hash, auction) => {
        hash[auction.AuctionId] = { order: auction.OrderId, status: auction.ExtendedPhaseStatusId };
        return hash;
    }, {});

const logXTimeDashboardAnalytics = (action, state, message) => logAnalytics('XTimeDashboard', action, state.common.currentLanguage, message);

export const xTimeDashboardActionsCreator = {
    onInit: signalr => (dispatch, getState) => {
        signalrClient = signalr;
        initializeState(dispatch, getState);
        logXTimeDashboardAnalytics('IN_XTIME_DASHBOARD', getState());
    },

    onAddAuctionBidForClient: auctionData => (dispatch, getState) => otherBidReceived(auctionData, getState(), dispatch),
    onAuctionExtended: auctionData => (dispatch, getState) =>
        dispatch({
            type: AUCTION_XTIME_EXTEND,
            auctionId: auctionData.AuctionId,
            endDate: belgiumDateTimeToLocal(auctionData.BatchEndDate),
            auctions: byAuctionId(auctionData.Auctions || []),
        }),
    onXTimePendingMessage: auctionData => (dispatch, getState) =>
        dispatch({
            type: AUCTION_XTIME_QUEUE,
            auctionId: auctionData.AuctionId,
            endDate: belgiumDateTimeToLocal(auctionData.EndDateExtendedPhase),
        }),
    onXTimeMessage: auctionData => dispatch =>
        dispatch({
            type: XTIME_MESSAGE,
            auctionId: auctionData.AuctionId,
            endDate: belgiumDateTimeToLocal(auctionData.EndDateExtendedPhase),
            auctions: byAuctionId(auctionData.Auctions || []),
            bidOptions: auctionData.BidOptions,
        }),
    onXTimeAuctionFinished: auctionData => (dispatch, getState) => dispatch({ type: AUCTION_XTIME_CLOSE, auctionId: auctionData.AuctionId }),

    onChangeActiveTab: selectedTabIndex => (dispatch, getState) => {
        dispatch({ type: CHANGE_ACTIVE_TAB, selectedTabIndex });
        logXTimeDashboardAnalytics('CHANGE_TAB', getState(), `tabIndex: [${selectedTabIndex}]`);
    },
    onCloseAuction: auctionId => dispatch => dispatch({ type: AUCTION_CLOSE, auctionId }), //console.log("onCloseAuction"),

    onNewInputPrice: (auctionId, amount) => dispatch => {
        dispatch({ type: NEW_BID_AMOUNT, inputPrice: amount, auctionId });
    },

    onShowAuction: auctionId => (dispatch, getState) => {
        dispatch(push(`/${getState().common.currentLanguage}/car/info?auctionId=${auctionId}`));
        scrollToTop();
        logXTimeDashboardAnalytics('SHOW_AUCTION', getState(), `${auctionId}`);
    },
    onBidSubmit: (auctionId, amount) => (dispatch, getState) => {
        submitBid(auctionId, amount, getState(), dispatch);
        logXTimeDashboardAnalytics('BID', getState(), `${auctionId}`);
    },
    onBuyNow: auctionId => (dispatch, getState) => {
        submitBuyNowBid(auctionId, getState(), dispatch);
        logXTimeDashboardAnalytics('BUY_NOW', getState(), `${auctionId}`);
    },
};

function initializeState(dispatch, getState) {
    const state = getState();

    // unregister previous ones
    state.xTimeDashboard.auctions.forEach(a => signalrClient.unregisterForAuctionId(a.auctionId));

    dispatch({ type: LOADING });

    const match = /extendedbatch\/(\d+)\/(\d+)/i.exec(location.pathname);
    if (match) {
        const auctionBatchId = parseInt(match[1], 10);
        const size = parseInt(match[2], 10);
        const searchUrl = url('findcarv6/search', state.common.currentLanguage);
        const searchRequest = {
            Query: { AuctionBatchId: auctionBatchId },
            Paging: { PageNumber: 0, ItemsPerPage: 9999 },
            ResultType: 'RedisCachedXTimeCardItem',
        };
        return fetchJson(searchUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(searchRequest),
        }).then(resp => {
            const list = (resp.Auctions || []).map(a => auctionToState(a, state.common.currentLanguage));

            // register signalr for new auctions.
            signalrClient
                .registerForAuctions(list.map(a => a.auctionId))
                .catch(e => console.error('SIGNALR Error while registering auctions from search result.', e));

            dispatch({ type: GOT_AUCTIONS, list });
        });
    } else {
        console.error('auctionbatchid missing in URL.');
    }
}

function submitBid(auctionId, amount, state, dispatch) {
    const auction = state.xTimeDashboard.auctions.filter(a => a.auctionId === auctionId)[0];
    const isInUltimoPhase = auction.isInUltimoPhase;
    const showSuccessToast = !state.common.confirmationEnabled;
    const bidRequest = {
        auctionId: auction.auctionId,
        price: amount || auction.inputPrice,
        minPrice: auction.inputMinimumPrice || 0, //state.carDetail.priceInfo.inputMinimumPrice,
        directBidType: true,
    };
    dispatch({ type: SUBMITTING_BID });
    fetchJson(url(isInUltimoPhase ? 'carv6/placelastbid' : 'carv6/placebid', state.common.currentLanguage), {
        method: 'POST',
        body: toFormData(bidRequest),
    })
        .then(resp => {
            switch (resp.status) {
                case 'success': {
                    updatePriceInfo(auction.auctionId, state.common.currentLanguage, dispatch).then(() => {
                        // update priceInfo first as we need the new currentPrice in reducer...
                        dispatch({ type: SUBMIT_BID, bidResponse: resp });
                    });
                    if (showSuccessToast) {
                        dispatch(removeNotifications());
                        dispatch(
                            notify({
                                message: i18n.t('toast.flashing.yourBidWasPlacedSuccessfully'),
                                status: 'success',
                            }),
                        );
                    }
                    dispatch({ type: 'CARSEARCH:USER_PLACED_A_BID', auctionId: auction.auctionId }); // to update search list with icons
                    break;
                }
                case 'warning':
                    dispatch({ type: SUBMIT_BID, bidResponse: resp });
                    if (showSuccessToast) {
                        dispatch(removeNotifications());
                        dispatch(notify({ message: resp.message, status: 'warning' }));
                    }
                    break;
                case 'error':
                    dispatch({ type: SUBMIT_BID, bidResponse: resp });
                    if (showSuccessToast) {
                        dispatch(removeNotifications());
                        dispatch(
                            notify({
                                message: `${i18n.t('toast.flashing.yourBidCouldNotBePlaced')} ${resp.message}`,
                                status: 'error',
                            }),
                        );
                    }
                    break;
            }
        })
        .catch(e => {
            console.error('!!!!!! placebid ERROR', e);
            dispatch(removeNotifications());
            dispatch(notify({ message: i18n.t('toast.flashing.yourBidCouldNotBePlaced'), status: 'error' }));
        });
}

function submitBuyNowBid(auctionId, state, dispatch) {
    const auction = state.xTimeDashboard.auctions.filter(a => a.auctionId === auctionId)[0];
    const showSuccessToast = !state.common.confirmationEnabled;
    const bidRequest = {
        auctionId: auction.auctionId,
        amount: auction.buyNowPrice,
        contactId: state.common.currentUser.contactId,
    };
    dispatch({ type: SUBMITTING_BUY_NOW_BID });
    fetchJson(url('BidService/DoBuyNowBid', state.common.currentLanguage), {
        method: 'POST',
        body: toFormData(bidRequest),
    })
        .then(resp => {
            if (resp) {
                if (showSuccessToast)
                    dispatch(
                        notify({
                            message: `${i18n.t('toast.flashing.yourBidCouldNotBePlaced')} ${resp}`,
                            status: 'error',
                        }),
                    );
            } else {
                updatePriceInfo(auction.auctionId, state.common.currentLanguage, dispatch).then(() => {
                    // update priceInfo first as we need the new currentPrice in reducer...
                    if (showSuccessToast)
                        dispatch(
                            notify({
                                message: i18n.t('toast.flashing.yourBidWasPlacedSuccessfully'),
                                status: 'success',
                            }),
                        );
                    dispatch({ type: 'CARSEARCH:USER_PLACED_A_BID', auctionId: auction.auctionId }); // to update search list with icons
                    dispatch({ type: SUBMIT_BUYNOW_BID, bidResponse: resp });
                });
            }
        })
        .catch(e => {
            console.error('!!!!!! placeBuyNowBid ERROR', e);
        });
}

function updatePriceInfo(auctionId, currentLanguage, dispatch, fromSignalr) {
    return fetchJson(url(`carv6/bidpriceinfo?auctionId=${auctionId}&bidAmount=0`, currentLanguage)).then(resp => {
        const newPriceInfo = priceInfoToState(resp);
        dispatch({ type: NEW_PRICE_INFO, newPriceInfo, auctionId, fromSignalr });
        return Promise.resolve();
    });
}

/**
 * Called by signalr event.
 *
 */
function otherBidReceived(auctionData, state, dispatch) {
    const auctionId = auctionData.AuctionId;
    const auction = state.xTimeDashboard.auctions.filter(a => a.auctionId === auctionId)[0];
    if (!auction) return;

    const auctionDataUpdate = signalrAuctionDataToState(auctionData, state);
    dispatch({ type: SIGNALR_BID, auctionDataUpdate, auctionId });
}

/***************************************************************************************************************
 *  DATA - REDUX STATE MAPPINGS.
 */

function auctionToState(auction, currentLanguage) {
    const isXTime = auction.ExtendedPhaseType > -1;
    const isAlphabetLikeXTime = auction.ExtendedPhaseType === 2;
    const isLeasePlanLikeXTime = auction.ExtendedPhaseType === 0;
    const endDate =
        isXTime && auction.EndDateExtendedPhase !== '0001-01-01T00:00:00'
            ? parseDate(belgiumDateTimeToLocal(auction.EndDateExtendedPhase))
            : parseDate(belgiumDateTimeToLocal(auction.BatchEndDate));
    const isClosed = isAlphabetLikeXTime ? auction.extendedPhaseStatus === EXTENDED_PHASE_STATUS.CLOSED : false;

    const extendedPhaseBidOptions =
        auction.BidOptions && auction.BidOptions.length > 0
            ? auction.BidOptions
            : [auction.CurrentPrice + auction.Increment, auction.CurrentPrice + 2 * auction.Increment, auction.CurrentPrice + 3 * auction.Increment];

    return {
        auctionId: auction.AuctionId,
        title: auction.CarTitleMobileList[currentLanguage] || auction.CarNameEn,
        //thumbnailUrl: auction.ThumbnailUrl || "/v6/images/car-placeholder.png",
        thumbnailUrl: auction.ThumbnailUrl ? auction.ThumbnailUrl.replace(/\\/g, '/') : '/v6/images/car-placeholder.png',
        //thumbnailUrl: 'https://images.adesa.eu/carimgs/ZFA3120000J495709/general/8.jpg?t=637068239795654080', // auction.ThumbnailUrl,

        dateFirstRegistration: auction.DateFirstRegistration ? formatDate(auction.DateFirstRegistration, 'short') : '-',
        effluentStandard: auction.EffluentStandardGroupSearch,
        co2: auction.Co2,
        bodyType: auction.Size,
        kw: auction.Kw,
        hp: auction.Hp,

        isMargin: auction.IsMargin,
        sellingOfficeCountryId: auction.CarCountryExtended ? auction.CarCountryExtended.toLowerCase() : auction.CarCountryExtended,

        hasUserPlacedABid: auction.HasUserPlacedABid,
        isUserHighestBidder: auction.IsUserHighestBidder,
        isHighestBidder: auction.IsUserHighestBidder,

        currentPrice: auction.CurrentPrice || 0,
        buyNowPrice: auction.BuyNowPrice,
        requestedSalesPrice: auction.RequestedSalesPrice,
        inputMinimumPrice: auction.CurrentPrice,
        increment: auction.Increment || 100,
        currentUserMaxPrice: auction.PersonalMaxPrice,

        inputPrice: 0,
        validInputPrice: true,

        isClosed,
        isSold: auction.HasBuyNowBidPlaced,
        isInUltimoPhase: false,
        isInExtendedPhase: auction.IsInExtendedPhase,
        isExtendedPhaseFinished: auction.ExtendedPhaseStatusId === 4,

        hasTechnicalDamage: auction.HasTechnicalDamage,
        hasBodyDamage: auction.IsBroken,

        endDate,

        following: auction.IsCarWatch,

        ...batchTypeFromBatchAttributeId(auction.IsBuyNow, auction.BatchAttributeId),

        // barometer data not displayed in xtime dashboard.
        barometerPrices: null,
        barometerRecommendedPrice: null,

        isXTime,
        isAlphabetLikeXTime,
        isLeasePlanLikeXTime,

        extendedPhaseStatus: auction.ExtendedPhaseStatusId,
        /*
            Preparing = 1,
            InQueue = 2,
            InXtime = 3,
            Closed = 4,
        */
        extendedPhaseOrder: auction.OrderId,

        extendedPhaseBidOptions,

        auctionStatus: auction.StatusId || AUCTION_STATUS.APPROVED,
        currencyCode: auction.CurrencyCodeId,
    };
}

function priceInfoToState(data) {
    return {
        targetPrice: data.TargetPrice,
        currentPrice: data.CurrentPrice,

        inputPrice: data.NextMaxPrice,
        validInputPrice: true, // to be recalculated based on new inputPrice & inputMinimumPrice & inputIncrement.
        showCountryCostDiscount: data.showCountryCostDiscount,
        countryCostDiscount: data.CountryCostDiscount ? data.CountryCostDiscount : 0,
        hasUserPlacedABid: data.HasUserPlacedABid,
        isUserHighestBidder: data.IsUserHighestBidder,
        currentUserMaxPrice: data.BidderHigherBidAmount,
    };
}

export function signalrAuctionDataToState(data, state) {
    /* data=
        AuctionBatchId : 0 
        AuctionId : 2915340
        BatchEndDate : "0001-01-01T00:00:00"
        CarId : 1919263
        ContactId : 19866
        CurrentPrice : 15100
        HighestBidderId : 19866
        LastUpdated : "2018-08-31T14:14:59.037+02:00"
        MaxPersonalPrice : 15100                            // !! this is not the price of current user.
        NextMaxPrice : 15200
    */

    const auction = state.xTimeDashboard.auctions.filter(a => a.auctionId === data.AuctionId)[0];
    const isBlind = auction.isBlind;
    const currentUserContactId = state.common.currentUser.contactId;

    const hasUserPlacedABid = data.ContactId === currentUserContactId || (auction && auction.hasUserPlacedABid);
    const result = {
        isUserHighestBidder: data.HighestBidderId === currentUserContactId,
        hasUserPlacedABid,
    };

    if (isBlind) {
        if (data.ContactId === currentUserContactId) {
            result.currentPrice = data.MaxPersonalPrice; // !! data.CurrentPrice = 0 for blind
        }
    } else {
        result.currentPrice = data.CurrentPrice;
        if (auction.isBuyNow) {
            result.isSold = data.CurrentPrice >= auction.buyNowPrice;
        }
    }
    return result;
}
