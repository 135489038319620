import React from 'react';

import { Spinner } from '@ambiorix/adesa-web-kit';

import styles from './FullScreenSpinner.module.scss';

export const FullScreenSpinner = () => {
    return (
        <div className={styles.fullScreenSpinner}>
            <Spinner />
        </div>
    );
};
