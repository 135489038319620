import settings from '../appsettings';
//import $ from "jquery";

// ugly jquery hack for signalr
(typeof global !== 'undefined' ? global : window).$ = (typeof global !== 'undefined' ? global : window).jQuery = require('jquery');
require('signalr');

// -----------------------------------------------------------------------------------------------------
//  SIGNALR config

// Actions sent to the dispatch (redux) in signalr event handlers.
export const actions = {
    EVENT_EXTENDED: 'SIGNALR:EVENT_EXTENDED',
    EVENT_WATCHED: 'SIGNALR:EVENT_WATCHED',
    EVENT_BID: 'SIGNALR:EVENT_BID',
    EVENT_REMOVE_WATCH: 'SIGNALR:EVENT_REMOVE_WATCH',
    EVENT_WITHDRAWN: 'SIGNALR:EVENT_WITHDRAWN',
    EVENT_BATCH_EXTENDED: 'SIGNALR:EVENT_BATCH_EXTENDED',
    EVENT_XTIME_MESSAGE: 'SIGNALR:EVENT_XTIME_MESSAGE',
    EVENT_XTIME_FINISHED: 'SIGNALR:EVENT_XTIME_FINISHED',
    EVENT_XTIME_BATCH_END: 'SIGNALR:EVENT_XTIME_BATCH_END',
    EVENT_BROADCAST_SERVER_TIME: 'SIGNALR:EVENT_BROADCAST_SERVER_TIME',
};

export class SignalrClientOld {
    constructor(onDisconnected) {
        this.connection = window.jQuery.hubConnection(`${settings.signalrOld.url}/signalr`, {
            useDefaultPath: false,
            qs: { SignalRToken: settings.signalrOld.secret },
            logging: true,
        });
        this.proxy = this.connection.createHubProxy('cotwTicker');
        this.connection.disconnected(() => {
            if (onDisconnected) onDisconnected();
            console.warn('SIGNALR client disconnected - reconnect...');
            this.start();
        });
    }

    // !!!! start should happen after handleEvents (here below)
    // https://stackoverflow.com/questions/16018321/signalr-empty-connection-data-on-my-second-hub
    async start() {
        return new Promise((resolve, reject) => {
            this.connection
                .start()
                .done(() => {
                    console.log('signalr connected');
                    resolve(this.proxy);
                })
                .fail(error => {
                    console.log(error);
                    reject(error);
                });
        });
    }

    async registerUser() {
        return new Promise((resolve, reject) => {
            this.proxy
                .invoke('registerPowerUser')
                .done(() => resolve())
                .fail(() => reject());
        });
    }

    async registerForAuctionBatchs(auctionBatchIds) {
        return new Promise((resolve, reject) => {
            this.proxy
                .invoke('registerForAuctionBatchIds', auctionBatchIds)
                .done(() => resolve())
                .fail(() => reject());
        });
    }

    async registerForAuctions(auctionIds) {
        return new Promise((resolve, reject) => {
            this.proxy
                .invoke('registerForAuctionIds', auctionIds)
                .done(() => resolve())
                .fail(() => reject());
        });
    }

    async registerForContact(contactId) {
        return new Promise((resolve, reject) => {
            this.proxy
                .invoke('registerForContactId', contactId)
                .done(() => resolve())
                .fail(() => reject());
        });
    }

    async registerForSession(sessionId) {
        return new Promise((resolve, reject) => {
            this.proxy
                .invoke('registerForSessionId', sessionId)
                .done(() => resolve())
                .fail(() => reject());
        });
    }

    async unregisterForAuctionId(auctionId) {
        return new Promise((resolve, reject) => {
            this.proxy
                .invoke('unregisterForAuctionId', auctionId)
                .done(() => resolve())
                .fail(() => reject());
        });
    }

    /**
     * Method which listens to signalR events and calls
     * action creators which will change state
     */
    handleEvents(callbacks) {
        this.proxy.on('auctionExtended', auctionData => {
            console.log('signalr:auctionExtended', auctionData);
            if (callbacks.auctionExtended) callbacks.auctionExtended(auctionData);
        });
        this.proxy.on('auctionBatchExtended', auctionBatchData => {
            console.log('signalr:auctionBatchExtended', auctionBatchData);
            if (callbacks.auctionBatchExtended) callbacks.auctionBatchExtended(auctionBatchData);
        });
        this.proxy.on('addAuctionBidForClient', auctionData => {
            console.log('signalr:addAuctionBidForClient', auctionData);
            if (callbacks.addAuctionBidForClient) callbacks.addAuctionBidForClient(auctionData);
        });
        this.proxy.on('addAuctionWatchForClient', auctionData => {
            console.log('signalr:addAuctionWatchForClient', auctionData);
            if (callbacks.addAuctionWatchForClient) callbacks.addAuctionWatchForClient(auctionData);
        });
        this.proxy.on('removeBidWatchForClient', auctionData => {
            console.log('signalr:removeBidWatchForClient', auctionData);
            if (callbacks.removeBidWatchForClient) callbacks.removeBidWatchForClient(auctionData);
        });
        this.proxy.on('auctionWithdrawn', auctionData => {
            console.log('signalr:auctionWithdrawn', auctionData);
            if (callbacks.auctionWithdrawn) callbacks.auctionWithdrawn(auctionData);
        });
        this.proxy.on('xTimeAuctionBatchEnd', auctionBatchData => {
            console.log('signalr:xTimeAuctionBatchEnd', auctionBatchData);
            if (callbacks.xTimeAuctionBatchEnd) callbacks.xTimeAuctionBatchEnd(auctionBatchData);
        });
        this.proxy.on('xTimeMessage', xTimeData => {
            console.log('signalr:xTimeMessage', xTimeData);
            if (callbacks.xTimeMessage) callbacks.xTimeMessage(xTimeData);
        });
        this.proxy.on('xTimeAuctionFinished', xTimeData => {
            console.log('signalr:xTimeAuctionFinished', xTimeData);
            if (callbacks.xTimeAuctionFinished) callbacks.xTimeAuctionFinished(xTimeData);
        });
        this.proxy.on('broadcastServerTime', serverDate => {
            console.log('signalr:broadcastServerTime', serverDate);
            if (callbacks.broadcastServerTime) callbacks.broadcastServerTime(serverDate);
        });
        this.proxy.on('XTimePendingMessage', auctionData => {
            console.log('signalr:xTimePendingMessage', auctionData);
            if (callbacks.xTimePendingMessage) callbacks.xTimePendingMessage(auctionData);
        });
        this.proxy.on('auctionsInLast5Mins', auctionData => {
            console.log('signalr:auctionsInLast5Mins', auctionData);
            if (callbacks.auctionsInLast5Mins) callbacks.auctionsInLast5Mins(auctionData);
        });
        this.proxy.on('auctionsInLast5MinsAddOrRemove', auctionData => {
            console.log('signalr:auctionsInLast5MinsAddOrRemove', auctionData);
            if (callbacks.auctionsInLast5MinsAddOrRemove) callbacks.auctionsInLast5MinsAddOrRemove(auctionData);
        });
        this.proxy.on('ultimoStarted', auctionData => {
            console.log('signalr:ultimoStarted', auctionData);
            if (callbacks.ultimoStarted) callbacks.ultimoStarted(auctionData);
        });
        this.proxy.on('provisionalStateChanged', (auctionId, newState, amount) => {
            console.log('signalr:provisionalStateChanged', auctionId, newState, amount);
            if (callbacks.provisionalStateChanged) callbacks.provisionalStateChanged(auctionId, newState, amount);
        });
    }
}
