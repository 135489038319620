import { useEffect, useState } from 'react';

export const useHistory = () => {
    const [historyState, setHistoryState] = useState({
        pathname: window.location.pathname,
    });

    useEffect(() => {
        // popstate event listener doesn't get triggered with my account routing changes, use history API instead
        const pushState = history.pushState;
        const cleanup = () => {
            history.pushState = pushState;
        };

        history.pushState = function () {
            pushState.apply(history, arguments as any);
            const pathname = arguments[2] as string;
            setHistoryState(value => ({ ...value, pathname }));
        };

        return () => cleanup();
    }, []);

    return historyState;
};
