import React from 'react';
import { InfoBanner } from '@ambiorix/adesa-web-kit';
import { CompanyOrContactBlocked } from '../../state/Login';
import useBanner, { BannerSessionStorageKeys } from '../../hooks/useBanner';

const goToCmsBlockedPage = (currentLanguage: string) => {
    window.open(`/${currentLanguage}/cms/frequently-asked-questions/login-account/why-is-my-account-blocked`, '_blank');
};

const goToCmsAwaitingPayment = (currentLanguage: string) => {
    window.open(`/${currentLanguage}/my-account/my-orders?order-statuses=awaitingPayment`, '_blank');
};

export const BIDDING_NOT_ALLOWED = 'biddingNotAllowed';

export type BannerBlockedType = CompanyOrContactBlocked | typeof BIDDING_NOT_ALLOWED;

type BlockedBannerProps = {
    t: (key: string) => string;
    currentLanguage: string;
    blockedType?: BannerBlockedType;
    companyBlockedReasonId?: number;
    isCommunityMember?: boolean;
    dismissable?: boolean;
};

const BlockedBanner = ({
    t,
    currentLanguage,
    blockedType,
    companyBlockedReasonId,
    isCommunityMember = false,
    dismissable = false,
}: BlockedBannerProps) => {
    const { showBanner, closeBanner } = useBanner({
        dependencyData: blockedType === BIDDING_NOT_ALLOWED ? blockedType : null,
        nameInSessionStorage: BannerSessionStorageKeys.BlockedFromBidding,
    });

    const title =
        blockedType === BIDDING_NOT_ALLOWED ? t(`blockedBanner.title.blockedFromBidding`) : t(`blockedBanner.title.companyOrContactBlocked`);
    const descriptionText = companyBlockedReasonId ? t(`blockedBanner.reason.${companyBlockedReasonId}`) : t(`blockedBanner.reason.other`);
    const additionalDescription =
        blockedType === BIDDING_NOT_ALLOWED && isCommunityMember ? ` ${t(`blockedBanner.description.canStillBidOnCommunityAuctions`)}` : '';

    const isAwaitingPayment = companyBlockedReasonId === 18;
    const action = isAwaitingPayment
        ? { title: t(`blockedBanner.action.payYourVehicle`), onClick: () => goToCmsAwaitingPayment(currentLanguage) }
        : { title: t(`blockedBanner.action.moreInformation`), onClick: () => goToCmsBlockedPage(currentLanguage) };

    const Description = () => (
        <>
            {descriptionText}
            {additionalDescription && (
                <>
                    <br />
                    {additionalDescription}
                </>
            )}
        </>
    );

    return showBanner ? (
        <InfoBanner
            variant="error"
            style="ghost"
            title={title}
            actionProps={{ onClick: () => action.onClick() }}
            actionText={action.title}
            description={<Description />}
            showCloseIcon={dismissable}
            onClose={closeBanner}
        />
    ) : null;
};

export default BlockedBanner;
