import React, { Component, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet';
import { getSEOPage } from '../../routing';
import { seoRoutes } from '../../routelist';
import { parsedQueryString } from '../../utils';

const languages = [
    'bg',
    'cs',
    'de',
    'en',
    'es',
    'fr-be',
    'fr-fr',
    'hr',
    'hu',
    'it',
    'lt',
    'nl-be',
    'nl-nl',
    'pl',
    'pt',
    'ro',
    'ru',
    'sl',
    'tr',
    'el',
    'sk',
];

const translationkeysForOtherPage = {
    home: /\/home\b/i,
    findcarSaved: /\/findcar\/saved/i,
    findcar: /\/findcar\b/i,
    auctions: /\/auctions\b/i,
    orderCompletion: /auctionprocess\/index/i, // regex on page url.
    myDashboard: /my-account\/dashboard/i,
    myOrders: /my-account\/orders/i,
    myBiddingDashboard: /myaccount\/biddingdashboard/i,
    myFin: /my-account\/fin\/my-authorized-fin/i,
    myDocuments: /my-account\/documents/i,
    bidderAccount: /my-account\/bidders/i, // Manage bidder accounts
    myAccountSettings: /my-account\/settings/i,
    myClaims: /my-account\/claims/i,
    myArchive: /my-account\/archive/i,
    myLogistics: /my-account\/logistics/i,
    register: /\/register\b/i,
};

/**
 * deduce the translation key to be used from the cmsid in the search URL
 * rule agreed with marketing: translation key = cmsId
 */

const seoTranslationKeySuffix = searchUrl => {
    const query = parsedQueryString(searchUrl.substr(searchUrl.indexOf('?') + 1));
    // const suffix = query["cmsid"].replace(/SEO_/i, '').replace(/_Text/i, '').replace('_', '.').replace(/[éèë]/i, 'e');
    // return suffix.toLowerCase();
    return decodeURI(query['cmsid'].replace(/_Text/i, ''));
};

/*
    Look up for a SEO search Url matching the current Url.

    if current url = "/en/findcar?bodyTypes=Truck"  ==> returns "/en/body-type/truck"
    based on all SEO routes defined in routelist.js

*/
const relatedSeoSearchUrl = () => {
    const isSearchUrl = /\/findcar$/i.test(location.pathname);
    if (!isSearchUrl) return null;
    const currentUrl = (location.pathname + location.search).toLowerCase() + '&cmsid';
    const found = seoRoutes.find(i => i.t.toLowerCase().includes(currentUrl));
    return found ? found.f : null;
};

const translationKeyForOtherPage = () => {
    for (const [translationKey, locationPathRegex] of Object.entries(translationkeysForOtherPage)) {
        if (locationPathRegex.test(location.pathname)) {
            return translationKey;
        }
    }
    return 'home'; // default key
};

const currentLanguage = location => {
    const match = /^\/([^/]+)/.exec(location.pathname);
    return match ? match[1] : 'en';
};

const alternateLinksForOtherLanguages = location =>
    languages.map(l => (
        <link
            rel="alternate"
            href={location.origin + location.pathname.replace('/' + currentLanguage + '/', '/' + l + '/') + location.search}
            hrefLang={l}
            key={l}
        />
    ));

const removeMetaTags = () => {
    // remove existing tags as new ones will be added here below...
    // if not removed, Helmet doesn't change it (bug?)
    ['description', 'keywords'].forEach(n => {
        const elem = document.querySelector(`meta[name="${n}"]`);
        if (elem) {
            elem.parentNode.removeChild(elem);
        }
    });
};

function CotwHead({ location, t }) {
    const [translationKeySuffix, setTranslationKeySuffix] = useState(null);

    const canonicalLink = relatedSeoSearchUrl();

    useEffect(() => {
        // texts to be used in meta tags come from phraseapp (translation key to use depends on the current search criteria).
        const seoPage = getSEOPage();
        const searchUrl = seoPage ? seoPage.t : null;
        removeMetaTags();
        setTranslationKeySuffix(searchUrl ? seoTranslationKeySuffix(searchUrl) : translationKeyForOtherPage());
    }, [location.pathname]);

    return (
        <Helmet>
            {alternateLinksForOtherLanguages(window.location, currentLanguage(location))}
            <title>{t('meta.title.' + translationKeySuffix)}</title>
            <meta name="description" content={t('meta.description.' + translationKeySuffix)} />
            <meta name="keywords" content={t('meta.keywords.' + translationKeySuffix)} />
            {canonicalLink ? <link rel="canonical" href={canonicalLink} /> : null}
        </Helmet>
    );
}

export default withRouter(CotwHead);
