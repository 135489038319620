import settings from '../../appsettings';
import { optionCategoriesReducer, tooHighBidAmount, hasEletricCables, isLeasePlanClosed, MAXIMUM_BID_METHOD, AUCTION_STATUS } from '../cotw.js';

import {
    SHOW_AUCTION,
    REFRESH_AUCTION,
    LOADING,
    NEW_EQUIPMENTS,
    SELECT_DETAIL_TAB,
    NEW_BID_AMOUNT,
    NEW_BID_METHOD,
    DISMISS_MESSAGE,
    SUBMITTING_BID,
    SUBMIT_BID,
    SUBMITTING_BUY_NOW_BID,
    SUBMIT_BUYNOW_BID,
    REFRESHING_PRICE_INFO,
    NEW_PRICE_INFO,
    NEW_BID_HISTORY,
    NEW_SELLER_INFO,
    NEW_CAR_DAMAGES,
    NEW_SIMILAR_CARS,
    NEW_COUNTRY_MESSAGES,
    NEW_SELLER_MESSAGES,
    WITHDRAW_BID,
    NEW_TRANSPORT_OPTIONS,
    NEW_SELLER_SCORES,
    SIGNALR_BID,
    NEW_BID_WITHDRAW_INFO,
    CLOSE_AUCTION,
    AUCTION_EXTENDED,
    AUCTION_CLOSE_EXTENDED,
    NEW_CAR_DAMAGE_REPORTS,
    SHOW_SOLD_CAR_DETAILS,
    CAR_SAVED_AS_QUERY,
    DISMIS_BUYNOW_FINALIZATION_DIALOG,
    SHOW_CLOSED_AUCTION_DETAILS,
    NEW_SERVER_TIME,
    CHANGE_CAR_AS_SEARCH_CRITERIA,
    XTIME_MESSAGE,
    LOADING_TRANSPORT_OPTIONS,
} from './actions';

import {
    auctionInfoToState,
    biddingAdvisorToState,
    carDetailsToState,
    priceInfoToState,
    similarCarsToState,
    favouriteCarToState,
    transportOptionsToState,
    carAsSearchCriteriaToState,
    isNonFrenchMarginCarForFrenchBuyer,
    isNonItalianMarginCarForItalianBuyer,
} from './mappings';

const initialMessages = [
    /*(t) => ({
        type: "information",
        text: <span>{t('toast.inPage.viewingNewVersionOfCarDetails')} <a className="link" href="#" onClick={() => goBackToOldUI()}>{t('toast.inPage.viewingNewVersionOfCarDetailsShowMeOld')}</a> </span>,
        dismissible:  true,
    }),*/
];

const initialState = {
    serverDate: new Date(),
    loading: true,

    selectedDetailTab: 0, // 0: carprofile tab, 1: Equipment tab, 2: Damages tab, 3: Delivery tab
    pageNavigation: {},

    carDetails: {},
    sellerScores: {},
    picturesList: [],
    bidHistory: [],
    carEquipments: null,
    carDamages: {
        damages: [],
        specialDamages: [],
        reports: [],
        hasReportsButLoggedOut: false,
        damagesFetched: false,
        reportsFetched: false,
        onlyShowReports: false,
    }, //  {damages: [{  kind: "Small",  kindId: 6,  location: "2",  locationId: 2,  isForBrokenCars: null }, ... ], specialDamages: ["xyz", ...] }
    auctionInfo: {},
    priceInfo: {},
    transportOptions: null,
    transportOptionsLoading: true,
    biddingAdvisor: {},
    favouriteCar: {},
    messages: [],
    importantMessages: {
        forCarDocsAndHistory: [],
        forDeliveryOptions: [],
        forCarOrigin: [],
        forCarGeneral: [],
        forFiscalRegime: [],
    },
    carAsSearchCriteria: {}, // this allows to save the current car as search criteria (cfr savedSearch in CarSearch.js)
    currencyCode: '',
    selectedOptionList: [],
    accessoryList: [],
    optionComment: [],
};

/***************************************************************************************************************
 *  REDUX REDUCER
 */
export function carDetailReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_AUCTION: {
            const carDetails = carDetailsToState(action.data, action.currentLanguage);
            const priceInfo = priceInfoToState(action.data);
            const auctionInfo = auctionInfoToState(action.data, action.currentLanguage, action.watches);
            const biddingAdvisor = biddingAdvisorToState(action.data);
            const pageNavigation = action.pageNavigation;
            const favouriteCar = favouriteCarToState(action.data);
            const picturesList = action.data.PicturesList || [];
            const carAsSearchCriteria = carAsSearchCriteriaToState(action.data);
            const selectedOptionList = action.data.EtgOptionList || [];
            const accessoryList = action.data.CachesCarAccessories || [];
            const optionComment = action.data.OptionComment || [];

            // add message for eurotax CO2 displayed
            const forCarGeneral = [];
            if (!carDetails.isCo2FromSeller && carDetails.co2) {
                forCarGeneral.push('vehicleDetails.co2.eurotaxMessage');
            }

            const forFiscalRegime = [];
            // do not show margin notification message for uk
            if (action.data.IsMargin && !carDetails.isUkCountryCar) {
                forCarGeneral.push('vehicleDetails.messages.marginCarForBuyer');
            }

            // if in ultimo phase, force DIRECT bid method.
            const bidMethod = auctionInfo.isInUltimoPhase ? MAXIMUM_BID_METHOD : priceInfo.bidMethod;
            return {
                ...initialState, // yes initial state here as old data from previous car should be cleaned up.
                loading: false,
                carDetails,
                priceInfo: { ...priceInfo, bidMethod },
                auctionInfo,
                picturesList:
                    picturesList &&
                    picturesList.map(pictures => {
                        return { ...pictures, Url: pictures.Url.replace(/https:\/\/[^/]+\/carimgs\//, settings.imagesBaseUrl) };
                    }),
                biddingAdvisor,
                pageNavigation,
                favouriteCar,
                messages: initialMessages,
                importantMessages: {
                    forCarDocsAndHistory: [],
                    forDeliveryOptions: [],
                    forCarOrigin: [],
                    forCarGeneral,
                    forFiscalRegime,
                },
                carAsSearchCriteria,
                currencyCode: action.data.CurrencyCodeId,
                selectedOptionList,
                accessoryList,
                optionComment,
            };
        }

        case REFRESH_AUCTION: {
            const carDetails = carDetailsToState(action.data, action.currentLanguage);
            const watches = [];
            if (state.auctionInfo.followingCar) watches.push(state.auctionInfo.auctionId);
            const auctionInfo = auctionInfoToState(action.data, action.currentLanguage, watches);
            return {
                ...state,
                carDetails,
                auctionInfo,
            };
        }

        case LOADING:
            return { ...state, loading: true };

        case NEW_SERVER_TIME:
            return { ...state, serverDate: action.serverDateTime };

        case NEW_EQUIPMENTS:
            return {
                ...state,
                carDetails: {
                    ...state.carDetails,
                    isElectricCarWithoutCables: state.carDetails.isElectricCar && !hasEletricCables(action.accessories),
                },
                carEquipments: {
                    top: action.importantOptionsList.map(a => a.Value),
                    all: [...action.importantOptionsList, ...action.otherOptionsList].reduce(optionCategoriesReducer, {}), // {'Others': [...action.importantOptionsList, ...action.otherOptionsList]},
                    accessories: action.accessories,
                    comment: action.comment,
                },
            };
        case NEW_SIMILAR_CARS:
            return { ...state, similarCars: similarCarsToState(action.similarCars) };

        case NEW_BID_AMOUNT:
            return { ...state, priceInfo: { ...state.priceInfo, inputPrice: action.inputPrice } };

        case REFRESHING_PRICE_INFO:
            return { ...state, priceInfo: { ...state.priceInfo, refreshingPrice: true } };

        case NEW_PRICE_INFO: {
            // for buyNow when currentPrice reaches the buyNowPrice => sold.
            let sold = state.auctionInfo.isSold;
            if (action.currentValues && state.auctionInfo.isBuyNow && !sold && state.priceInfo.buyNowPrice > 0) {
                // buyNowPrice = 0 in viewModel when auction closed.
                sold = action.newPriceInfo.currentPrice >= state.priceInfo.buyNowPrice;
            }

            // message (translation key) to be displayed in case of invalid value.
            let inputPriceInvalidMessage = '';

            // input price must be an increment of the initial price (if initialPrice = 16010, valid=16110, 16210, ...)
            let validInputPrice =
                state.priceInfo.inputPrice === 0 ||
                (state.priceInfo.inputPrice - state.priceInfo.inputMinimumPrice) % state.priceInfo.inputIncrement === 0;

            // https://dev.azure.com/CarsOnTheWeb/CarsOnTheWeb/_workitems/edit/608
            if (validInputPrice) {
                if (!state.auctionInfo.isBuyNow) {
                    validInputPrice = !tooHighBidAmount(
                        state.priceInfo.inputPrice,
                        state.auctionInfo.maximumBid,
                        state.auctionInfo.minimumBidForRspCheck,
                    );
                    if (!validInputPrice) {
                        inputPriceInvalidMessage = 'vehicleDetails.biddingBox.errorBidTooHigh';
                    }
                } else {
                    validInputPrice = state.priceInfo.inputPrice <= state.priceInfo.buyNowPrice;
                    if (!validInputPrice) {
                        inputPriceInvalidMessage = 'vehicleDetails.biddingBox.errorBuyNowReached';
                    }
                }
            } else {
                inputPriceInvalidMessage = 'vehicleDetails.biddingBox.errorUseIncrement';
            }

            // Only update inputPrice if new amount is more than entered amount (cfr https://gemini.carsontheweb.com/workspace/407/item/24856)
            // skip when price entered manually.
            const inputPrice =
                action.newPriceInfo.inputPrice && action.newPriceInfo.inputPrice > state.priceInfo.inputPrice && state.priceInfo.inputPrice !== 0
                    ? action.newPriceInfo.inputPrice
                    : state.priceInfo.inputPrice;

            return {
                ...state,
                priceInfo: {
                    ...state.priceInfo,
                    ...action.newPriceInfo,
                    validInputPrice,
                    inputPriceInvalidMessage,
                    inputPrice: action.fromSignalr ? state.priceInfo.inputPrice : inputPrice,
                    // avoid updating inputPrice if data from signalr https://dev.azure.com/CarsOnTheWeb/CarsOnTheWeb/_workitems/edit/1304
                },
                auctionInfo: {
                    ...state.auctionInfo,
                    isSold: sold,
                },
            };
        }

        case NEW_BID_METHOD:
            return {
                ...state,
                priceInfo: {
                    ...state.priceInfo,
                    bidMethod: state.auctionInfo.isInUltimoPhase ? MAXIMUM_BID_METHOD : action.method, // Max bid only during ultimo phase.
                },
            };

        case DISMISS_MESSAGE: {
            const newMessages = [...state.messages];
            newMessages.splice(action.messageIdx, 1);
            return { ...state, messages: newMessages };
        }

        case CAR_SAVED_AS_QUERY:
            return { ...state, auctionInfo: { ...state.auctionInfo, carSavedAsQuery: true } };

        case SUBMITTING_BID:
            return { ...state, auctionInfo: { ...state.auctionInfo, submittingBid: true } };

        case SUBMITTING_BUY_NOW_BID:
            return { ...state, auctionInfo: { ...state.auctionInfo, submittingBuyNowBid: true } };

        case SUBMIT_BID: {
            /*const newMessage = {dismissible: true, text: action.bidResponse.message};
            switch (action.bidResponse.status) {
                case "success": newMessage.type = "successful"; newMessage.text = "Bid placed successfully!"; break;
                case "warning": newMessage.type = "unsuccessful"; break;
                case "error":   newMessage.type = "error"; break;
            }
            return ({...state, messages: [ ...state.messages, newMessage]});
            */
            return {
                ...state,
                auctionInfo: {
                    ...state.auctionInfo,
                    submittingBid: false,
                    isClosed: state.auctionInfo.isInUltimoPhase ? true : state.auctionInfo.isClosed, // only 1 bid allowed while in Ultimo phase.
                    showBuyNowFinalizationDialog: state.auctionInfo.isBuyNow
                        ? state.priceInfo.currentPrice >= state.priceInfo.buyNowPrice
                            ? true
                            : false
                        : false,
                },
            };
        }

        case SUBMIT_BUYNOW_BID: {
            //const newMessage = {dismissible: true, text: action.bidResponse, type: "information"};
            const isSuccessful = action.bidResponse === '';
            return {
                ...state,
                /*messages: [ ...state.messages, newMessage],*/
                auctionInfo: {
                    ...state.auctionInfo,
                    submittingBuyNowBid: false,
                    isSold: isSuccessful,
                    showSoldAnyway: isSuccessful,
                    showBuyNowFinalizationDialog: isSuccessful,
                    isBuyNowWon: isSuccessful,
                    statusId: isSuccessful ? AUCTION_STATUS.BUY_NOW : state.auctionInfo.statusId,
                },
            };
        }

        case DISMIS_BUYNOW_FINALIZATION_DIALOG:
            return {
                ...state,
                auctionInfo: {
                    ...state.auctionInfo,
                    showBuyNowFinalizationDialog: false,
                    isBuyNowWon: false,
                },
            };

        case WITHDRAW_BID: {
            // const newMessage = {dismissible: true, text: action.withdrawBidResponse};
            // if (action.withdrawBidResponse === "") {
            //     newMessage.type = "successful";
            //     newMessage.text = "Bid withdrawend successfully!";
            // } else {
            //     newMessage.type = "error";
            // }
            // return ({...state, messages: [ ...state.messages, newMessage]});
            return { ...state };
        }

        case NEW_BID_HISTORY:
            return { ...state, bidHistory: action.biddingHistory };
        case NEW_SELLER_INFO:
            return { ...state, carDetails: { ...state.carDetails, ...action.sellerInfo } };
        case NEW_CAR_DAMAGES:
            return {
                ...state,
                carDamages: { ...state.carDamages, ...action.carDamages, damagesFetched: true },
            };
        case NEW_CAR_DAMAGE_REPORTS:
            return {
                ...state,
                carDamages: {
                    ...state.carDamages,
                    reports: action.damageInfo ? action.damageInfo.ReportItems : [],
                    hasReportsButLoggedOut: action.hasReportsButLoggedOut || false,
                    reportsFetched: true,
                    onlyShowReports: action.damageInfo ? action.damageInfo.OnlyShowReports : false,
                },
            };

        case NEW_TRANSPORT_OPTIONS:
            return { ...state, transportOptions: transportOptionsToState(action.transportOptions) };
        case LOADING_TRANSPORT_OPTIONS:
            return { ...state, transportOptionsLoading: action.transportOptionsLoading };
        case NEW_SELLER_SCORES:
            return { ...state, sellerScores: action.sellerScores };
        case NEW_BID_WITHDRAW_INFO:
            return { ...state, auctionInfo: { ...state.auctionInfo, ...action.bidWithdrawInfo } };
        case NEW_COUNTRY_MESSAGES:
            //return ({...state, importantMessages: action.messages });
            return {
                ...state,
                importantMessages: {
                    ...state.importantMessages,
                    forDeliveryOptions: [...state.importantMessages.forDeliveryOptions, ...action.messages.forDeliveryOptions],
                    forCarDocsAndHistory: [...state.importantMessages.forCarDocsAndHistory, ...action.messages.forCarDocsAndHistory],
                },
            };

        case NEW_SELLER_MESSAGES:
            return {
                ...state,
                importantMessages: {
                    ...state.importantMessages,
                    forCarOrigin: [...state.importantMessages.forCarOrigin, ...action.messages],
                },
            };

        case SELECT_DETAIL_TAB:
            return { ...state, selectedDetailTab: action.tabIdx };

        case CLOSE_AUCTION: {
            // Don't close the auction when first phase time is elapsed for Alphabet extended auction
            // see https://dev.azure.com/CarsOnTheWeb/CarsOnTheWeb/_workitems/edit/1893
            const isAlphabetLikeExtendedAuction = state.auctionInfo.extendedPhaseType === 2;

            // see https://karauctionservices.visualstudio.com/International/_workitems/edit/214201
            const isLeasePlanLikeExtendedAuction = state.auctionInfo.extendedPhaseType === 0;

            const isClosed = isAlphabetLikeExtendedAuction
                ? state.auctionInfo.isExtendedPhaseFinished
                : isLeasePlanLikeExtendedAuction
                ? isLeasePlanClosed(state.auctionInfo.endDate)
                : true;
            const isInExtendedPhase = isAlphabetLikeExtendedAuction;

            return {
                ...state,
                auctionInfo: {
                    ...state.auctionInfo,
                    isClosed,
                    showClosedAnyway: isAlphabetLikeExtendedAuction,
                    isInExtendedPhase,
                },
            };
        }
        case AUCTION_CLOSE_EXTENDED: // called by signalr
            return {
                ...state,
                auctionInfo: { ...state.auctionInfo, isClosed: true, isExtendedPhaseFinished: true },
            };

        case AUCTION_EXTENDED: {
            // called by signalr
            const isExtendedPhase = state.auctionInfo.extendedPhaseType >= 0;
            // Ultimo only if highest bidder
            const isUltimo = state.auctionInfo.isUltimo && state.priceInfo.isUserHighestBidder;
            const endDate = isUltimo || isExtendedPhase ? action.endDate : state.auctionInfo.endDate;
            const isClosed = isUltimo || isExtendedPhase ? false : state.auctionInfo.isClosed;

            return {
                ...state,
                serverDate: new Date(),
                auctionInfo: {
                    ...state.auctionInfo,
                    endDate: endDate,
                    isInExtendedPhase: isExtendedPhase,
                    isInUltimoPhase: isUltimo,
                    isClosed: isClosed,
                },
                priceInfo: {
                    ...state.priceInfo,
                    bidMethod: isUltimo ? MAXIMUM_BID_METHOD : state.priceInfo.bidMethod, // force method to DIRECT BID.
                },
            };
        }

        case XTIME_MESSAGE:
            return {
                ...state,
                auctionInfo: {
                    ...state.auctionInfo,
                    endDate: state.auctionInfo.auctionId == action.auctionId ? action.endDate : state.auctionInfo.endDate,
                },
                priceInfo: {
                    ...state.priceInfo,
                    currentPrice: state.auctionInfo.auctionId == action.auctionId ? action.currentPrice : state.auctionInfo.endDate,
                },
            };

        case SHOW_SOLD_CAR_DETAILS:
            return {
                ...state,
                auctionInfo: {
                    ...state.auctionInfo,
                    showSoldAnyway: true,
                },
            };

        case SHOW_CLOSED_AUCTION_DETAILS:
            return {
                ...state,
                auctionInfo: {
                    ...state.auctionInfo,
                    showClosedAnyway: true,
                },
            };

        // signalr actions
        case SIGNALR_BID: {
            // Only update inputPrice if new amount (signalr) is more than entered amount (cfr https://gemini.carsontheweb.com/workspace/407/item/24856)
            // skip when price entered manually.
            const inputPrice =
                action.priceInfo.inputPrice && action.priceInfo.inputPrice > state.priceInfo.inputPrice
                    ? action.priceInfo.inputPrice
                    : state.priceInfo.inputPrice;

            return {
                ...state,
                priceInfo: {
                    ...state.priceInfo,
                    ...action.priceInfo,
                    //inputPrice,
                    inputPrice: state.priceInfo.inputPrice, // avoid refresh of input price   https://dev.azure.com/CarsOnTheWeb/CarsOnTheWeb/_workitems/edit/1304
                },
            };
        }

        case CHANGE_CAR_AS_SEARCH_CRITERIA: {
            return {
                ...state,
                carAsSearchCriteria: {
                    ...state.carAsSearchCriteria,
                    [action.criteriaName]: action.newSelection,
                },
            };
        }

        default:
            return { ...state };
    }
}
