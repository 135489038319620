//import { searchBySellersMetadata } from './cotw.js';
import { fetchJson, uuidv4, parsedQueryString, scrollToTop, queryString, pad } from '../utils';
import { belgiumDateTimeToLocal } from '../utils/date';
import i18n from '../i18n';
import settings from '../appsettings';
import { parseDate } from '../utils/date';
import { notify } from 'reapop';
import { replace, push, goBack } from 'connected-react-router';

const sortingPreferenceStorageKey = 'ADESA:Search:AuctionBatches:SortingPrefs';

//-----------------------------------------------------------------------------------------------------------------
// CONFIG
//-----------------------------------------------------------------------------------------------------------------

const checkboxSearchQueryNameMapping = {
    auctionTypes: 'AuctionTypes',
    originCountries: 'OriginCountryIds',
    sellers: 'SellerIds',
    sellerGroups: 'SellerGroups',
    xTimes: 'ExtendedPhaseType',
};

// maps internal name for criteria to facets names returned by the search-service.
const facetsMapping = {
    auctionTypes: 'AuctionBatchAttributeId',
    originCountries: 'CountryId',
    sellers: 'BatchCompanyGroup',
    sellerGroups: 'BatchCompanyGroup',
    xTimes: 'WillBeExtended',
};

// const metadataFields = {
//     searchCriteriaNamesForCheckboxeCriteria: Object.keys(checkboxSearchQueryNameMapping),
// };

const xTimesMetadata = [
    //{id: false, name: 'No x-time'},
    { id: true, name: 'xtime.only' },
];

const auctionTypesMetadata = [
    { id: 1, name: 'auctionType.dynamic' },
    { id: 2, name: 'auctionType.buyNow' },
    { id: 3, name: 'auctionType.buyNowPickUp' },
    { id: 4, name: 'auctionType.blind' },
    { id: 5, name: 'auctionType.target' },
];

//-----------------------------------------------------------------------------------------------------------------

function url(urlSuffix, currentLanguage) {
    return settings.carsiteBaseUrl + '/' + currentLanguage + '/' + urlSuffix;
}

// Actions
const INIT_METADATA = 'AUCTIONBATCHES:INIT_METADATA';
const START_SEARCH = 'AUCTIONBATCHES:START_SEARCH';
const NEW_SEARCH_RESULT = 'AUCTIONBATCHES:NEW_SEARCH_RESULT';
const NEXT_SEARCH_RESULT = 'AUCTIONBATCHES:NEXT_SEARCH_RESULT';
const NEW_SEARCH_COUNT = 'AUCTIONBATCHES:NEW_SEARCH_COUNT';
const CHANGE_PAGE_SIZE = 'AUCTIONBATCHES:CHANGE_PAGE_SIZE';
const CHANGE_SORTING = 'AUCTIONBATCHES:CHANGE_SORTING';
const CLEAR_SEARCH_CRITERIA = 'AUCTIONBATCHES:CLEAR_SEARCH_CRITERIA';
const CHANGE_SEARCH_CRITERIA = 'AUCTIONBATCHES:CHANGE_SEARCH_CRITERIA';
const ADD_247_STOCK_BATCH = 'AUCTIONBATCHES:ADD_247_STOCK_BATCH';

const fake247StockBatchId = 999999999999; // fake ID (cfr id loaded by auctionservice in redis cache)
const fake247StockBatch = auctionsCount => ({
    id: fake247StockBatchId,
    name: '???',
    namesByLanguage: {},
    countryId: null,
    startDate: null,
    endDate: null,
    auctionsCount,
    batchAttributeId: 6,
    //batchAttributeName: 'auctionType.247stock',
    batchTypeId: 0,
    showSellerLogo: false,
    sellerLogoUrl: '07_Multibrand_cars.png',
    genericLogoClassName: 'batchlogo-large-07',
    extendedPhaseType: -1,
    isXTime: false,
    communityId: null,
    communityName: '',
});

const emptySearchCriteria = {
    originCountries: [],
    auctionTypes: [],
    sellers: [],
    sellerGroups: [],
    xTimes: [],
};

const defaultSorting = {
    byUserPreference: false,
    field: 'EndDateForBatches',
    direction: 'ascending',
};
const sorting = localStorage.getItem(sortingPreferenceStorageKey) ? JSON.parse(localStorage.getItem(sortingPreferenceStorageKey)) : defaultSorting;

const initialState = {
    searching: false,

    metadata: {
        //...metadataFields,

        auctionTypes: [],
        originCountries: [],
        sellers: [],
        sellerGroups: [],
        xTimes: [],
    },

    searchCriteria: {
        ...emptySearchCriteria,
    },

    paging: {
        pageSize: 20,
        pageIndex: 0, // 0 = first page.
    },
    sorting,

    searchGuid: uuidv4(), // used for stats in backend. identify a search. each time a criteria is changed, this guid is updated.
    auctionBatchesCount: 0,
    auctionBatchesList: [],

    dummy: 'INIT',
};

// converts list [{Key: "aa", Value: 1}, ...] to {aa: 1 ,...}
function toMap(list) {
    return list.reduce((res, v) => {
        res[v.Key] = v.Value;
        if (typeof v.Key === 'string') res[v.Key.toLowerCase()] = v.Value; // save key to lowercase too (as facets are all sent in lowercase)
        return res;
    }, {});
}

export function auctionBatchesReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_SEARCH_CRITERIA: {
            let newSelection = action.newSelection;

            // skip invalid criteria.
            const checkboxKeys = Object.keys(checkboxSearchQueryNameMapping);
            if (checkboxKeys.indexOf(action.criteriaName) > -1) {
                const ids = state.metadata[action.criteriaName].map(a => a.id);
                newSelection = newSelection.filter(a => ids.indexOf(a) > -1);
            }

            return {
                ...state,
                dummy: action.type,
                searchGuid: action.searchGuid,
                searchCriteria: {
                    ...state.searchCriteria,
                    [action.criteriaName]: newSelection,
                },
            };
        }

        case INIT_METADATA:
            return {
                ...state,
                dummy: action.type,
                metadata: {
                    ...state.metadata,
                    originCountries: action.metadata.OriginCountries.map(a => ({ id: a, name: 'country.' + a })),
                    auctionTypes: auctionTypesMetadata,
                    sellers: Object.keys(action.sellerGroups).map(sg => ({ name: sg, id: action.sellerGroups[sg] })), // {name: 'name of seller group', id: [list of seller id]}
                    sellerGroups: Object.keys(action.sellerGroups).map(sg => ({ name: sg, id: sg })), // {name: 'name of seller group', id: 'name of seller group'}
                    xTimes: xTimesMetadata,
                },
            };

        case START_SEARCH:
            return {
                ...state,
                dummy: action.type,
                searching: true,
            };

        case CLEAR_SEARCH_CRITERIA:
            return {
                ...state,
                dummy: action.type,
                searchCriteria: { ...emptySearchCriteria },
                auctionBatchesList: [],
                auctionBatchesCount: 0,
            };

        case CHANGE_PAGE_SIZE:
            return {
                ...state,
                paging: {
                    ...state.paging,
                    pageIndex: 0,
                    pageSize: action.pageSize,
                },
                dummy: action.type,
            };

        case CHANGE_SORTING: {
            const [field, direction] = action.sorting.split('|');
            return {
                ...state,
                sorting: {
                    ...state.sorting,
                    field,
                    direction,
                },
                dummy: action.type,
            };
        }

        case NEW_SEARCH_RESULT: {
            // Apply facets to the metadata structure by adding the corresponding counts.
            const toLowerCase = a => (typeof a === 'string' ? a.toLowerCase() : a);
            const metadata = { ...state.metadata };
            Object.keys(facetsMapping).forEach(k => {
                const counts = toMap(action.result.Facets[facetsMapping[k]]);
                if (k === 'xTimes') {
                    counts[true] = counts['1'];
                    counts[false] = counts['0'];
                }
                metadata[k] = state.metadata[k].map(a => ({ ...a, count: counts[toLowerCase(k === 'sellers' ? a.name : a.id)] || 0 }));
            });

            // FOR TEST
            //console.dir(metadata);
            //Object.keys(metadata).forEach (m => metadata[m].forEach(c => c.count = 1));

            return {
                ...state,
                metadata,
                paging: {
                    ...state.paging,
                    pageIndex: 0,
                },
                searching: false,
                auctionBatchesCount: action.result.Count,
                auctionBatchesList: action.result.AuctionBatches.map(ab => auctionBatchToState(ab)),
                dummy: action.type,
            };
        }

        case NEXT_SEARCH_RESULT: {
            return {
                ...state,
                paging: {
                    ...state.paging,
                    pageIndex: action.pageIndex,
                },
                searching: false,
                auctionBatchesList: action.result.AuctionBatches.map(ab => auctionBatchToState(ab)),
                dummy: action.type,
            };
        }

        case NEW_SEARCH_COUNT:
            return {
                ...state,
                auctionBatchesCount: action.result.Count,
                dummy: action.type,
            };

        case ADD_247_STOCK_BATCH:
            return {
                ...state,
                auctionBatchesList: [fake247StockBatch(action.auctionsCount), ...state.auctionBatchesList],
            };

        default:
            return { ...state };
    }
}

export const auctionBatchesMapStateToProps = state => ({
    serverDate: state.common.serverDate,
    metadata: state.auctionBatches.metadata,
    searchCriteria: state.auctionBatches.searchCriteria,
    searching: state.auctionBatches.searching,
    auctionBatchesCount: state.auctionBatches.auctionBatchesCount,
    auctionBatchesList: state.auctionBatches.auctionBatchesList,
    paging: state.auctionBatches.paging,
    sorting: state.auctionBatches.sorting,
    dummy: state.auctionBatches.dummy,
});

export const auctionBatchesActionsCreator = /*(dispatch, getState) =>*/ {
    onInit: () => (dispatch, getState) => initializeSearch(dispatch, getState),
    onClearSearchSelection: () => (dispatch, getState) => {
        dispatch({ type: CLEAR_SEARCH_CRITERIA });
        const state = getState();
        //setUrlQueryStringFromSearchCriteria (state.carSearch.searchCriteria, dispatch);
        search(dispatch, getState);
    },
    onChangeSearchSelection: (criteriaName, newSelection) => (dispatch, getState) => {
        dispatch({ type: CHANGE_SEARCH_CRITERIA, criteriaName, newSelection, searchGuid: uuidv4() });
        const state = getState();
        //setUrlQueryStringFromSearchCriteria (state.carSearch.searchCriteria, dispatch);
        search(dispatch, getState);
    },

    //onSearch: () => (dispatch, getState) => goToCarsiteResultPage(dispatch, getState()),
    onSearch: () => (dispatch, getState) => search(dispatch, getState(), 1),
    onPageLoad: pageIndex => (dispatch, getState) => {
        search(dispatch, getState, pageIndex);
        scrollToTop();
    },
    onPageSize: pageSize => (dispatch, getState) => {
        dispatch({ type: CHANGE_PAGE_SIZE, pageSize });
        search(dispatch, getState, 0);
        scrollToTop();
    },
    onSort: sorting => (dispatch, getState) => {
        dispatch({ type: CHANGE_SORTING, sorting });
        search(dispatch, getState, 0);
        localStorage.setItem(sortingPreferenceStorageKey, JSON.stringify(getState().auctionBatches.sorting));
        scrollToTop();
    },

    onShowFilterScreen: () => (dispatch, getState) => dispatch(push(`/${getState().common.currentLanguage}/auctions/filter`)),
    onShowResultsList: () => (dispatch, getState) => {
        if (/\/filter\b/.test(location.pathname)) {
            dispatch(goBack());
        } else {
            dispatch(push(`/${getState().common.currentLanguage}/auctions`));
            scrollToTop();
        }
    },

    //onBackToSearchPage: () => (dispatch, getState) => setUrlQueryStringFromSearchCriteria (getState().carSearch.searchCriteria, dispatch),
};

function initializeSearch(dispatch, getState) {
    const state = getState();
    const searchMetadataUrl = url('findcarv6/metadata', state.common.currentLanguage);
    fetchJson(searchMetadataUrl).then(metadata => {
        dispatch({
            type: INIT_METADATA,
            metadata,
            currentLanguage: state.common.currentLanguage,
            sellerGroups: state.common.sellerGroups,
            allowedCatalogues: state.common.currentUser.allowedCatalogues,
            isUserLoggedIn: state.common.isUserLoggedIn,
        });
        search(dispatch, getState);
    });
}

function parsedValue(str) {
    if (/^(true|false)$/i.test(str)) {
        return str === 'true';
    }
    const possibleNumber = parseInt(str, 10);
    return isNaN(possibleNumber) ? str : possibleNumber;
}

/**
 * Check the query string of the current URL to prefill the search criteria.
 */
function setSearchCriteriaFromUrlQueryString(dispatch) {
    if (window.location.search) {
        const query = parsedQueryString(window.location.search);
        Object.keys(checkboxSearchQueryNameMapping).forEach(n => {
            if (query[n]) {
                dispatch({ type: CHANGE_SEARCH_CRITERIA, criteriaName: n, newSelection: query[n].split(',').map(parsedValue) });
            }
        });
    }
}

/**
 * Adapt the querystring in URL bar based on currently selected criteria.
 */
function setUrlQueryStringFromSearchCriteria(criteria, dispatch) {
    const result = {};
    Object.keys(checkboxSearchQueryNameMapping).forEach(n => {
        if (criteria[n] && criteria[n].length) {
            result[n] = criteria[n].join(',');
        }
    });
    //const baseUrl = `/${currentLanguage}/findcar`;
    const newQueryString = queryString(result);
    dispatch(replace(location.pathname + (newQueryString ? `?${newQueryString}` : '')));
}

/**
 * Builds the search query to be sent to SearchService based on currently selected criteria.
 * cfr https://dev-searchservice.cotw.eu/swagger/index.html for query format.
 *
 */
function searchQuery(searchCriteria, sorting, pageSize, pageIndex) {
    const result = {
        query: {},
        Paging: {
            PageNumber: pageIndex || 0,
            ItemsPerPage: pageSize || 0,
        },
        Sort: {
            Field: sorting.field || 'EndDateForBatches',
            Direction: sorting.direction || 'ascending',
            SortType: 'Field',
        },
        FacetRequest: ['All'],
    };

    Object.keys(checkboxSearchQueryNameMapping).forEach(k => {
        if (searchCriteria[k].length > 0) {
            result.query[checkboxSearchQueryNameMapping[k]] = searchCriteria[k];
        }
    });

    delete result.query.ExtendedPhaseType;
    if (searchCriteria.xTimes.length === 1) {
        result.query.ExtendedPhaseType = searchCriteria.xTimes[0]; // criteria is a boolean.
    }
    // delete result.query.AssignmentType;
    // if (searchCriteria.assignment100pc.length === 1) {
    //     result.query.AssignmentType = searchCriteria.assignment100pc[0]; // criteria is a boolean.
    // }

    if (searchCriteria.sellers.length) {
        result.query.SellerIds = searchCriteria.sellers.flat(); // flat the array of array of sellerIds (cfr regroupment of sellerid)
    }
    return result;
}

/*function count (dispatch, state) {
    const searchUrl = url('findcarv6/search', state.common.currentLanguage);
    fetchJson(searchUrl, {
        method: 'POST',
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(searchQuery(state.carSearch.searchCriteria)),
    })
    .then(resp => {
        dispatch({type: NEW_SEARCH_COUNT, result: resp});
    }).catch(e => dispatch(notify({message: "Error while searching " + e.toString(), status: "error"})));
}*/

function search(dispatch, getState, pageIndex = 0) {
    dispatch({ type: START_SEARCH });
    let state = getState();
    const pageSize = state.auctionBatches.paging.pageSize;
    const sorting = state.auctionBatches.sorting;
    const searchCriteria = state.auctionBatches.searchCriteria;

    //console.log(JSON.stringify({searchCriteria: state.carSearch.searchCriteria, sorting: state.carSearch.sorting, paging: state.carSearch.paging}));

    const searchUrl = url('findcarv6/AuctionBatchesSearch', state.common.currentLanguage);

    const headers = {
        //     "x-cotw-token": "Carsite-Anonymous",
        //     "x-cotw-contact": "0",
    };

    const query = {
        ...searchQuery(searchCriteria, sorting, pageSize, pageIndex + 1),
        // Additional fields necessary for stats.
        // UniqueSearchLogId: state.carSearch.searchGuid,
        // SavedSearchId: state.carSearch.selectedSavedSearch ? state.carSearch.selectedSavedSearch.id : null,
        // PageUrl: location.toString(),
    };

    const requests = Promise.all([
        fetchJson(searchUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', ...headers },
            body: JSON.stringify(query),
        }),
        get247StockAuctionsCount(state),
    ]);
    requests
        .then(([resp, count247StockAuctions]) => {
            dispatch({
                type: pageIndex === 0 ? NEW_SEARCH_RESULT : NEXT_SEARCH_RESULT,
                result: resp,
                currentLanguage: state.common.currentLanguage,
                contactId: state.common.currentUser.contactId,
                pageIndex,
            });
            if (count247StockAuctions > 0) {
                dispatch({ type: ADD_247_STOCK_BATCH, auctionsCount: count247StockAuctions });
            }
        })
        .catch(e => dispatch(notify({ message: 'Error while searching ' + e.toString(), status: 'error' })));
}

function get247StockAuctionsCount(state) {
    const searchUrl = url('findcarv6/search', state.common.currentLanguage);
    const query = { Query: { AuctionBatchId: fake247StockBatchId } };
    return fetchJson(searchUrl, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(query) }).then(
        resp => resp.Count,
    );
}

const batchAttributeLabelMapping = {
    1: 'auctionType.dynamic',
    2: 'auctionType.buyNow',
    3: 'auctionType.buyNowPickUp',
    4: 'auctionType.blind',
    5: 'auctionType.target',
};

function auctionBatchToState(auctionBatch) {
    return {
        id: auctionBatch.AuctionBatchId,
        name: auctionBatch.AuctionBatchNameLabel || auctionBatch.AuctionBatchName,
        namesByLanguage: auctionBatch.AuctionBatchNameList,
        type: auctionBatch.AuctionBatchTypeLabel,
        countryId: auctionBatch.CountryId,
        startDate: parseDate(belgiumDateTimeToLocal(auctionBatch.StartDate)),
        endDate: parseDate(belgiumDateTimeToLocal(auctionBatch.EndDate)),
        auctionsCount: auctionBatch.NbrAuctions,

        batchAttributeId: auctionBatch.AuctionBatchAttributeId,
        /*
            1	No Special Option
            2	Buy Now
            3	Buy Now and Pick Up
            4	Blind
            5	Target
        */
        batchAttributeName: batchAttributeLabelMapping[auctionBatch.AuctionBatchAttributeId] || '-',

        batchTypeId: auctionBatch.AuctionBatchTypeId,
        /*
            0	normal
            1	exclusivecars
            2	lighttrucks
            3	trucks
            4	wrecks
            5	stocksale
        */

        // logo to display (either seller Logo or Generic logo)
        showSellerLogo: auctionBatch.LogoIsSellerLogo,
        sellerLogoUrl: auctionBatch.LogoUrl,
        genericLogoClassName: 'batchlogo-large-' + pad(auctionBatch.AuctionBatchLogoId, 2, '0'), // cfr batchlogo.sprite.png,  45x45px

        extendedPhaseType: auctionBatch.ExtendedPhaseType,
        isXTime: typeof auctionBatch.ExtendedPhaseType === 'number' && auctionBatch.ExtendedPhaseType >= 0,
        // WillBeExtended is not part of the response!

        communityId: auctionBatch.CommunityId,
        communityName: auctionBatch.CommunityName,
    };
}
