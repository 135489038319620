import {
    addHours as _addHours,
    addMilliseconds as _addMilliseconds,
    addMinutes as _addMinutes,
    addSeconds as _addSeconds,
    differenceInDays as _differenceInDays,
    differenceInHours as _differenceInHours,
    differenceInMilliseconds as _differenceInMilliseconds,
    differenceInMinutes as _differenceInMinutes,
    differenceInSeconds as _differenceInSeconds,
    format as _formatDate,
    getYear as _getYear,
    intervalToDuration,
    isAfter as _isAfter,
    isBefore as _isBefore,
    isToday as _isToday,
    parseISO,
    parse as _parseDate,
    addDays as _addDays,
} from 'date-fns';
import { zonedTimeToUtc, formatInTimeZone } from 'date-fns-tz';
import { LiteralUnion } from './types';
import { getTimeZone } from './locale';

const DATE_FORMATS = {
    dateTime: 'dd MMM yyyy HH:mm',
    iso: "yyyy-MM-dd'T'HH:mm:ss",
    short: 'dd/MM/yyyy',
    shortDateTime: 'dd/MM/yyyy HH:mm',
    time: 'HH:mm:ss',
};

export type DateType = Date | string | number;
export type DateFormat = keyof typeof DATE_FORMATS;

export const getFormat = (format: LiteralUnion<DateFormat, string>) => DATE_FORMATS[format] ?? format;

export const getCurrentDate = () => new Date(Date.now());

export const parseDate = (date: DateType, format?: LiteralUnion<DateFormat, string>) => {
    if (!date) return new Date(0); // default to unix epoch
    if (typeof date === 'number') return new Date(date);
    if (typeof date !== 'string') return date;
    if (format) {
        const formatString = DATE_FORMATS[format] ?? format;
        return _parseDate(date, formatString, new Date());
    }
    return parseISO(date);
};

export const formatDate = (date: DateType, format: LiteralUnion<DateFormat, string> = 'short') => {
    const formatString = DATE_FORMATS[format] ?? format;
    return _formatDate(parseDate(date), formatString);
};

export const formatDateTime = (date: DateType, t: (key: string) => string, options = { showYear: true }) => {
    const formattedDate = formatDate(date, 'dd MM yyyy HH:mm');
    const tokens = formattedDate.split(' ');
    if (!options.showYear) tokens.splice(2, 1);
    return tokens.map((token, index) => (index === 1 ? t(`common.month${token}Short`) : token)).join(' ');
};

export const toTimeZone = (dateTime: string, sourceTimeZone: string, targetTimeZone: string) => {
    // issue in finland: date in pageViewModel (HdnServerDateValue) has this format: YYYY-MM-DDTHH.mm.ss
    const date = dateTime && dateTime.replace(/T(\d\d).(\d\d).(\d\d)/, (_, a, b, c) => `T${a}:${b}:${c}`);
    const parsedDate = zonedTimeToUtc(parseISO(date), sourceTimeZone);
    return formatInTimeZone(parsedDate, targetTimeZone, getFormat('iso'));
};

// =================================
// input format: YYYY-MM-DDTHH:mm:ss (UTC time)
// output format: YYYY-MM-DDTHH:mm:ss (local time).
export const utcDateTimeToLocal = (utcDateTime: string) => {
    const dateTime = utcDateTime.replace('Z', '');
    return toTimeZone(dateTime, 'UTC', getTimeZone());
};

// =================================
// input format: YYYY-MM-DDTHH:mm:ss (Belgium time)
// output format: YYYY-MM-DDTHH:mm:ss (local time).
export const belgiumDateTimeToLocal = (dateTime: string) => {
    if (!dateTime) return dateTime;
    return toTimeZone(dateTime, 'Europe/Brussels', getTimeZone());
};

export const getYear = (date: DateType) => {
    const parsedDate = parseDate(date);
    return _getYear(parsedDate);
};

export const addHours = (date: DateType, amount: number) => {
    const parsedDate = parseDate(date);
    return _addHours(parsedDate, amount);
};

export const addMilliseconds = (date: DateType, amount: number) => {
    const parsedDate = parseDate(date);
    return _addMilliseconds(parsedDate, amount);
};

export const addMinutes = (date: DateType, amount: number) => {
    const parsedDate = parseDate(date);
    return _addMinutes(parsedDate, amount);
};

export const addSeconds = (date: DateType, amount: number) => {
    const parsedDate = parseDate(date);
    return _addSeconds(parsedDate, amount);
};

export const addDays = (date: DateType, amount: number) => {
    const parsedDate = parseDate(date);
    return _addDays(parsedDate, amount);
};

export const isAfter = (date: DateType, dateToCompare: DateType) => {
    const parsedDate = parseDate(date);
    const parsedDateToCompare = parseDate(dateToCompare);
    return _isAfter(parsedDate, parsedDateToCompare);
};

export const isBefore = (date: DateType, dateToCompare: DateType) => {
    const parsedDate = parseDate(date);
    const parsedDateToCompare = parseDate(dateToCompare);
    return _isBefore(parsedDate, parsedDateToCompare);
};

export const isToday = (date: DateType) => {
    const parsedDate = parseDate(date);
    return _isToday(parsedDate);
};

export const differenceInDays = (dateLeft: DateType, dateRight: DateType) => {
    const parsedDateLeft = parseDate(dateLeft);
    const parsedDateRight = parseDate(dateRight);
    return _differenceInDays(parsedDateLeft, parsedDateRight);
};
export const differenceInHours = (dateLeft: DateType, dateRight: DateType) => {
    const parsedDateLeft = parseDate(dateLeft);
    const parsedDateRight = parseDate(dateRight);
    return _differenceInHours(parsedDateLeft, parsedDateRight);
};
export const differenceInMilliseconds = (dateLeft: DateType, dateRight: DateType) => {
    const parsedDateLeft = parseDate(dateLeft);
    const parsedDateRight = parseDate(dateRight);
    return _differenceInMilliseconds(parsedDateLeft, parsedDateRight);
};
export const differenceInMinutes = (dateLeft: DateType, dateRight: DateType) => {
    const parsedDateLeft = parseDate(dateLeft);
    const parsedDateRight = parseDate(dateRight);
    return _differenceInMinutes(parsedDateLeft, parsedDateRight);
};
export const differenceInSeconds = (dateLeft: DateType, dateRight: DateType) => {
    const parsedDateLeft = parseDate(dateLeft);
    const parsedDateRight = parseDate(dateRight);
    return _differenceInSeconds(parsedDateLeft, parsedDateRight);
};

export const getDuration = (startDate: DateType, endDate: DateType) => {
    const start = parseDate(startDate);
    const end = parseDate(endDate);
    return intervalToDuration({ start, end });
};

export const formatDatewithTranslation = (date: DateType, t: (key: string) => string) => {
    const weekDay = formatDate(date, 'EEEE');
    const day = formatDate(date, 'dd');
    const month = formatDate(date, 'MM');
    const year = formatDate(date, 'yyyy');
    const translatedDate = `${t(`common.day.short.${weekDay.toLowerCase()}`)} ${day} ${t(`common.month${month}Short`)}, ${year}`;
    return translatedDate;
};
