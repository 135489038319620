import { push } from 'connected-react-router';
import { notify } from 'reapop';
import settings from '../appsettings';
import { fetchJson, parsedQueryString } from '../utils';
import i18n from '../i18n';

function url(urlSuffix, currentLanguage) {
    return settings.carsiteBaseUrl + '/' + currentLanguage + '/' + urlSuffix;
}

// ===== REDUX ACTIONS ====================================
const INIT = 'COMMUNITIES:INIT';
const COMMUNITIES_FETCHED = 'COMMUNITIES:COMMUNITIES_FETCHED';
const COMMUNITIES_SELLERS_FETCHED = 'COMMUNITIES:COMMUNITIES_SELLERS_FETCHED';
const ACCEPT = 'COMMUNITIES:ACCEPT';
const SET_INVITE = 'COMMUNITIES:INVITE';

const initialState = {
    loadingCommunities: true,
    communities: [],
    communityInvite: null,
};

/***************************************************************************************************************
 *  REDUX REDUCER
 */
export function communitiesReducer(state = initialState, action) {
    switch (action.type) {
        case INIT: {
            return {
                ...state,
                loadingCommunities: true,
            };
        }

        case COMMUNITIES_FETCHED: {
            return {
                ...state,
                loadingCommunities: false,
                // only take joined community
                communities: action.communities.map(c => toCommunity(c)),
            };
        }
        case COMMUNITIES_SELLERS_FETCHED: {
            return {
                ...state,
                communities: action.communities.map(c => ({ ...c, seller: action.sellersByCommunityId[c.id] })),
            };
        }
        case SET_INVITE: {
            return {
                ...state,
                loadingCommunities: false,
                communityInvite: action.payload,
            };
        }
        case ACCEPT: {
            return {
                ...state,
                communities: [...state.communities, state.communityInvite],
                communityInvite: null,
            };
        }

        default:
            return { ...state };
    }
}

/***************************************************************************************************************
 *  REACT-REDUX state to properties map.
 */
export const communitiesMapStateToProps = globalState => ({
    isUserLoggedIn: globalState.common.isUserLoggedIn || false,
    currentLanguage: globalState.common.currentLanguage || 'en',
    currentUser: globalState.common.currentUser,
    communities: globalState.communities.communities,
    loadingCommunities: globalState.communities.loadingCommunities,
    communityInvite: globalState.communities.communityInvite,
});

/***************************************************************************************************************
 *  REACT-REDUX action creators (using redux-thunk: Redux Thunk middleware allows you to write action creators that return a function instead of an action)
 */

function notifyError(dispatch, message) {
    dispatch(notify({ message: message, status: 'error' }));
}

export const communitiesActionsCreator = {
    onInit: () => (dispatch, getState) => initializeState(dispatch, getState),
    acceptInvite: () => (dispatch, getState) => acceptInvite(dispatch, getState),
    fetchCommunityInvite: () => (dispatch, getState) => fetchCommunityInvite(dispatch, getState),
    goToHome: () => (dispatch, getState) => goToHome(dispatch, getState),
};

function initializeState(dispatch, getState) {
    const state = getState();
    dispatch({ type: INIT });
    fetchJson(url('communitiesv6/JoinedCommunities', state.common.currentLanguage))
        .then(communities => {
            if (communities) {
                dispatch({ type: COMMUNITIES_FETCHED, communities });
                // fetch seller details.
                const communityIds = communities.map(a => a.id);
                Promise.all(
                    communityIds.map(communityId => {
                        return fetchJson(url(`communitiesv6/CommunitySeller/${communityId}`, state.common.currentLanguage));
                    }),
                ).then(sellers => {
                    const sellersByCommunityId = communityIds.reduce((hash, val, i) => ({ ...hash, [val]: sellers[i] }), {});
                    dispatch({ type: COMMUNITIES_SELLERS_FETCHED, sellersByCommunityId });
                });
            }
        })
        .catch(e => {
            dispatch({ type: COMMUNITIES_FETCHED, communities: [] });
            console.error(e);
            dispatch(notify({ message: i18n.t('communities.notAllowed'), status: 'error' }));
        });
}

/***************************************************************************************************************
 *  DATA - REDUX STATE MAPPINGS.
 */

const fetchCommunityInvite = (dispatch, getState) => {
    dispatch({ type: INIT });
    const queryString = window.location.search;
    const { guid } = new parsedQueryString(queryString);
    const state = getState();
    const currentLanguage = state.common.currentLanguage;
    fetchJson(url(`CommunitiesV6/CommunitiesByGuid${guid && `?guid=${guid}`}`, currentLanguage))
        .then(resp => {
            dispatch({ type: SET_INVITE, payload: resp });
        })
        .catch(e => {
            console.error(e);
            dispatch(notify({ message: i18n.t('communities.errorLoadingInvite'), status: 'error' }));
        });
};

const acceptInvite = (dispatch, getState) => {
    const queryString = window.location.search;
    const { guid } = new parsedQueryString(queryString);
    const state = getState();
    const currentLanguage = state.common.currentLanguage;
    fetchJson(url(`CommunitiesV6/AcceptInvite${guid && `?guid=${guid}`}`, currentLanguage), {}, 'POST')
        .then(() => {
            dispatch({ type: ACCEPT });
            dispatch(push(`/${currentLanguage}/communities`));
        })
        .catch(e => {
            console.error(e);
            if (e.status == 400) {
                dispatch(notify({ message: i18n.t('communities.alreadyMember'), status: 'error' }));
            } else {
                dispatch(notify({ message: i18n.t('communities.errorAcceptingInvite'), status: 'error' }));
            }
        });
};

const goToHome = (dispatch, getState) => {
    const state = getState();
    const currentLanguage = state.common.currentLanguage;
    dispatch(push(`/${currentLanguage}/home`));
};

function toCommunity(c) {
    return {
        id: c.id,
        name: c.name,
        role: c.role,
        canAccess: c.role === 'FULL_ACCESS' || c.role === 'BROWSE_ONLY',
        coverPictureUrl: c.coverPictureUrl,
        profilePictureUrl: c.profilePictureUrl,
        vehiclesCount: c.vehiclesCount,
        auctionsCount: c.auctionsCount,
        biddingStatus: {
            // TODO !! (not for MVP)
            winning: 99,
            overbid: 99,
            followed: 99,
        },
        seller: null,
    };
}
