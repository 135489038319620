exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._18B6vx3aHOTuZ4qYyLc6LP{display:flex;flex-direction:column;min-height:100dvh;background-color:var(--neutral-50)}", ""]);

// exports
exports.locals = {
	"appRoot": "_18B6vx3aHOTuZ4qYyLc6LP"
};