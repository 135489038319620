exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2M2JMcpZpMEjXQOZwgC-m_ label{margin-bottom:0}._2M2JMcpZpMEjXQOZwgC-m_ select{background-image:none}", ""]);

// exports
exports.locals = {
	"select": "_2M2JMcpZpMEjXQOZwgC-m_"
};