export const VAT_VALIDATED = 'REGISTER:VAT_VALIDATED';
export const INVOICING_OFFICES_LOADED = 'REGISTER:INVOICING_OFFICES_LOADED';
export const COUNTRIES_LOADED = 'REGISTER:COUNTRIES_LOADED';
export const COMPANY_DETAILS_VALIDATED = 'REGISTER:COMPANY_DETAILS_VALIDATED';
export const ACCOUNT_TYPE_VALIDATED = 'REGISTER:ACCOUNT_TYPE_VALIDATED';
export const SUBMITTED = 'REGISTER:SUBMITTED';
export const PHONE_COUNTRY_CODES_LOADED = 'REGISTER:PHONE_COUNTRY_CODES_LOADED';
export const LANGUAGES_LOADED = 'REGISTER:LANGUAGES_LOADED';
export const SAVE_FORM_DATA = 'REGISTER:SAVE_FORM_DATA';
export const SELLER_TYPES_LOADED = 'REGISTER:SELLER_TYPES_LOADED';
export const SAVE_BUSINESS_TYPE_DETAILS = 'REGISTER:SAVE_BUSINESS_TYPE_DETAILS';
export const EDIT_BUSINESS_TYPE_DETAILS = 'REGISTER:EDIT_BUSINESS_TYPE_DETAILS';
