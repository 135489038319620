import React, { useState, useRef } from 'react';
import { useAtleastMDMediaQuery } from '../../hooks/useAtleastMDMediaQuery';
import { Form } from 'reactstrap';
import { extrapolate, useInterval, pad } from '../../utils';
import { AdesaButton, AdesaModal } from '../Common/ui-components';
import { addMinutes, getCurrentDate, getDuration, isAfter } from '../../utils/date';

const TimerDialog = ({ t, onKeepAlive, onLogout, onExpired }) => {
    const expiredTime = useRef(addMinutes(new Date(), 3));
    const [isOpen, setOpen] = useState(true);

    const [countdown, setCountdown] = useState('');
    const isMDScreen = useAtleastMDMediaQuery();

    useInterval(() => {
        const now = getCurrentDate();

        if (isAfter(now, expiredTime.current)) {
            onExpired();
        } else {
            const duration = getDuration(expiredTime.current, now);
            setCountdown(`${duration.minutes} ${t('common.units.minuteShort')} ${pad(duration.seconds, 2, '0')} ${t('common.units.secondShort')} `);
        }
    }, 1000);

    return (
        <AdesaModal is-open={isOpen} width={isMDScreen ? '35' : '90'} onChanged={() => setOpen(!isOpen)}>
            <h3 slot="header">
                <b> {t('modal.timeout.header1')} </b>
            </h3>
            <div slot="body">
                <Form>
                    <h4>{extrapolate(t('modal.timeout.sessionExpires'), countdown)}</h4>
                    <p>
                        {t('modal.timeout.inactive')}
                        <br />
                        {t('modal.timeout.autoLogOut')}
                        <br />
                        {t('modal.timeout.clickToStay')}
                    </p>
                </Form>
            </div>
            <div className="modal-footer" slot="footer">
                <AdesaButton id="stayLoinBtn1" size="secondary" color="positive" onClick={onKeepAlive} className="mb-2">
                    {t('modal.timeout.cta.stayLoggedIn')}
                </AdesaButton>

                <AdesaButton id="logoutCtaBtn1" size="secondary" color="link-blue" onClick={onLogout}>
                    {t('modal.timeout.cta.logOut')}
                </AdesaButton>
            </div>
        </AdesaModal>
    );
};

const LogoutDialog = ({ t, onLogout }) => {
    const [isOpen, setOpen] = useState(true);
    const isMDScreen = useAtleastMDMediaQuery();
    return (
        <AdesaModal is-open={isOpen} width={isMDScreen ? '35' : '90'} onChanged={() => setOpen(!isOpen)}>
            <h3 slot="header">
                <b>{t('modal.timeout.header2')} </b>
            </h3>
            <div slot="body">
                <Form>
                    <p>{t('modal.timeout.willBeRedirected')}</p>
                </Form>
            </div>
            <div className="modal-footer" slot="footer">
                <AdesaButton id="timeoutOkBtn1" size="secondary" color="positive" onClick={onLogout} className="mb-2">
                    {t('modal.timeout.ctaOK')}
                </AdesaButton>
            </div>
        </AdesaModal>
    );
};

export default ({
    isSessionDialogOpen,
    t,

    onKeepAlive,
    onLogout,
}) => {
    const [isExpired, setExpired] = useState(false);
    return isSessionDialogOpen ? (
        isExpired ? (
            <LogoutDialog t={t} onLogout={onLogout} />
        ) : (
            <TimerDialog t={t} onLogout={onLogout} onKeepAlive={onKeepAlive} onExpired={() => setExpired(true)} />
        )
    ) : null;
};
