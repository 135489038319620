import React, { useState } from 'react';
import { vehicleGradesMetadata, mechanicalIndicatorsMetadata } from '../../state/cotw';
import { formatDate } from '../../utils/date';
import {
    Title,
    DetailLine,
    LinesGroup,
    displayGradeSubtext,
    displayGradeIconColor,
    displayGradeText,
    displayMIIconColor,
    displayMechanicalIndText,
    GradesMIModal,
    DisplayList,
} from './Common';
import { Information } from '../ui/common-ui';
import { damageCategories } from '../../components/CarDetail/CarDamageConstants';

const VehicalCondition = ({ carDetails, t, isReportRequested }) => {
    //show modal for Vehical and MI
    const [showVechicalGrade, setShowVehicalGrade] = useState(false);
    const [showMiGrade, setShowMiGrade] = useState(false);

    const isDrivableLabel = {
        yes: 'vehicleDetails.damages.drivableYes',
        no: 'vehicleDetails.damages.drivableNo',
        unknown: 'vehicleDetails.damages.drivableUndefined',
    };

    let showEuConditionfields =
        carDetails.hadAccident !== null ||
        carDetails.isDrivable == 'no' ||
        carDetails.lastMaintenance ||
        carDetails.lastMaintenanceDate ||
        carDetails.technicalInspectionValidityDate;

    let showUkConditionFields = carDetails.vehicleGradeId || carDetails.mechanicalIndicatorId || carDetails.technicalInspectionValidityDate;

    return (
        <div>
            {/* show damage category if present only for UK cars */}
            {carDetails && carDetails.isUkCountryCar && carDetails.damageCategoryId ? (
                <Information>{t('vehicleDetails.messages.damageCategoryMessage') + damageCategories[carDetails.damageCategoryId]}</Information>
            ) : undefined}
            {carDetails && carDetails.isUkCountryCar && showUkConditionFields ? (
                <>
                    <section>
                        <Title label={t('vehicleDetails.profile.condition')} />
                        <hr className="mt-0" />
                        <LinesGroup>
                            {carDetails.vehicleGradeId && (
                                <DetailLine
                                    label={t('vehicleDetails.vehicleGrade')}
                                    value={displayGradeSubtext(carDetails.vehicleGradeId, t)}
                                    showIcon
                                    iconColor={displayGradeIconColor(carDetails.vehicleGradeId)}
                                    className="grade-styles"
                                    // subText={displayGradeText(carDetails.vehicleGradeId, t)}
                                    isUkCar={carDetails.isUkCountryCar}
                                    setShowModal={setShowVehicalGrade}
                                    showModel={showVechicalGrade}
                                    isReportRequested={isReportRequested}
                                />
                            )}

                            {carDetails.mechanicalIndicatorId && (
                                <DetailLine
                                    label={t('vehicleDetails.mechanicalIndicator')}
                                    value={displayMechanicalIndText(carDetails.mechanicalIndicatorId, t)}
                                    showIcon
                                    iconColor={displayMIIconColor(carDetails.mechanicalIndicatorId)}
                                    className="grade-styles"
                                    // subText={displayMechanicalIndText(carDetails.mechanicalIndicatorId, t)}
                                    isUkCar={carDetails.isUkCountryCar}
                                    setShowModal={setShowMiGrade}
                                    showModel={showMiGrade}
                                    isReportRequested={isReportRequested}
                                />
                            )}

                            {carDetails.technicalInspectionValidityDate ? (
                                <DetailLine
                                    label={t('vehicleDetails.profile.ukTechnicalInspectionDate')}
                                    value={
                                        carDetails.technicalInspectionValidityDate
                                            ? formatDate(carDetails.technicalInspectionValidityDate, 'short')
                                            : '-'
                                    }
                                />
                            ) : null}
                        </LinesGroup>
                        <GradesMIModal
                            t={t}
                            showModel={showVechicalGrade}
                            title={t('vehicleDetails.vehicleGrade')}
                            setShowModal={setShowVehicalGrade}>
                            <DisplayList gradeList={vehicleGradesMetadata} t={t} subTitle={t('vehicleDetails.profile.vehicleGradesubText')} />
                        </GradesMIModal>
                        <GradesMIModal t={t} showModel={showMiGrade} title={t('vehicleDetails.mechanicalIndicator')} setShowModal={setShowMiGrade}>
                            <DisplayList
                                gradeList={mechanicalIndicatorsMetadata}
                                t={t}
                                subTitle={t('vehicleDetails.profile.vehicleMIsubText')}
                                showDisclaimerText={true}
                            />
                        </GradesMIModal>
                    </section>
                </>
            ) : (
                <>
                    {showEuConditionfields ? (
                        <>
                            <section>
                                <Title label={t('vehicleDetails.profile.condition')} />
                                <LinesGroup>
                                    {carDetails.hadAccident !== null ? (
                                        <DetailLine
                                            label={t('vehicleDetails.damages.accident')}
                                            value={t(carDetails.hadAccident ? 'common.value.yes' : 'common.value.no')}
                                        />
                                    ) : undefined}
                                    {carDetails.isDrivable == 'no' ? (
                                        <DetailLine
                                            label={t('vehicleDetails.profile.drivable')}
                                            value={t('common.value.no') + '. ' + t(isDrivableLabel[carDetails.isDrivable])}
                                        />
                                    ) : undefined}
                                    {carDetails.lastMaintenance || carDetails.lastMaintenanceDate ? (
                                        <DetailLine
                                            label={t('vehicleDetails.profile.lastMaintenance')}
                                            value={
                                                carDetails.lastMaintenance
                                                    ? carDetails.lastMaintenance +
                                                      ' ' +
                                                      (carDetails.lastMaintenanceDate ? '- ' + carDetails.lastMaintenanceDate : '')
                                                    : carDetails.lastMaintenanceDate
                                            }
                                        />
                                    ) : undefined}
                                    {carDetails.technicalInspectionValidityDate ? (
                                        <DetailLine
                                            label={t('vehicleDetails.profile.inspectionDate')}
                                            value={
                                                carDetails.technicalInspectionValidityDate
                                                    ? formatDate(carDetails.technicalInspectionValidityDate, 'short')
                                                    : '-'
                                            }
                                        />
                                    ) : undefined}
                                </LinesGroup>
                            </section>
                        </>
                    ) : undefined}
                </>
            )}
        </div>
    );
};

export default VehicalCondition;
