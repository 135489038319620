type Observer<T> = (value: T) => void;

export const createObservable = <T extends object>(target: T) => {
    let observers: Array<Observer<T>> = [];

    const notify = (value: T) => observers.forEach(observer => observer(value));

    const subscribe = (callback: Observer<T>) => {
        observers.push(callback);
    };

    const unsubscribe = (callback: Observer<T>) => {
        observers = observers.filter(observer => observer !== callback);
    };

    const observable = new Proxy(target, {
        get(target, prop) {
            return target[prop];
        },
        set(target, prop, value) {
            target[prop] = value;
            notify({ ...target, [prop]: value });
            return true;
        },
    });

    return { observable, subscribe, unsubscribe };
};
