import { parsedQueryString } from './utils';
import { seoRoutes } from './routelist';

export const seoPaths = seoRoutes.map(a => a.f);

export function isSearchPage() {
    const urlroute = seoRoutes.find(i => i.f === location.pathname);
    return /(\/findcar\/?$)|(\/findcar\/advanced\/?$)/i.test(location.pathname) || urlroute !== undefined;
}

export function getSEOPage() {
    const urlroute = seoRoutes.find(i => i.f === location.pathname);
    return urlroute;
}

export const seoTranslationKeySuffix = () => {
    const query = getParsedQueryString();
    return decodeURI(query['cmsid'].replace(/_Text/i, ''));
};

export function getParsedQueryString() {
    const result = getQueryString();
    return result ? parsedQueryString(result) : {};
}
export function getQueryString() {
    const urlroute = seoRoutes.find(i => i.f === location.pathname);
    if (urlroute) {
        return urlroute.t.split('?')[1];
    }
    if (window.location.search) {
        return window.location.search;
    }
    return '';
}
